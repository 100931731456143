import axios from "axios";
import API from "../../Config/API";
import Auth from "../../Service/Auth";

export const driveApiCall = axios.create({
  baseURL: API.url,
  timeout: 0,
});
export const ec2DriveApiCall = axios.create({
  baseURL: API.ec2Url,
  timeout: 0,
});

export const API_ROUTES = {
  folders: {
    treeFolders: "/shared-folders/drive/folders/list.json",
    foldersDetail: "/shared-folders/drive/folders/content.json",
    complete_folder: "/shared-folders/drive/files/complete.json",
    createFolder: "/shared-folders/drive/folders/create.json",
    deleteFolder: "/shared-folders/drive/folders/delete.json",
    moveFolder: "/shared-folders/drive/folders/move.json",
    copyFolder: '/shared-folders/drive/folders/copy.json',
    updateUploadedFolder: '/shared-folders/drive/folders/upload.json',
    downloadCompleteFolder: '/shared-folders/drive/folders/export-complete-folder.json',
    copyCompleteFolder: '/shared-folders/drive/folders/complete/copy.json',
    getCompanyFoldersContent: '/shared-folders/drive/item/get-company-items.json',
    getCompanyFolders: '/shared-folders/drive/folders/get-company-folders.json',
    moveItem: '/item/move',
    copyItem: '/item/copy',
    searchItem: '/shared-folders/drive/item/search-item.json'
  },
  files: {
    deleteFile: "/shared-folders/drive/files/delete.json",
    createFile: "/shared-folders/drive/files/create.json",
    moveFile: '/shared-folders/drive/files/move.json',
    copyFiles: '/shared-folders/drive/files/copy.json',
    getFilesPreview: '/shared-folders/drive/files/preview.json'
  },
  updateFileOrFolder: "/shared-folders/drive/item/update.json",
  updateDocument: '/shared-folders/drive/files/update-document-additional-info.json',
  s3: {
    uploadPresigned: "/shared-folders/drive/s3/get-put-object-presigned-url.json",
    downloadPresigned: "/shared-folders/drive/s3/generate-presigned-url.json",
    presignedUrlsForPreview: "/common/get-object-presigned-url.json",
    generateMultiplePresignedPut: '/shared-folders/drive/s3/generate-multiple-put-object-presigned-url.json'
  },
  exportFolder: '/shared-folders/drive/export.json',
  downloadItem: '/file/zip',
  delivery: {
    list: '/shared-folders/delivery/list.json',
    searchUser: '/shared-folders/delivery/search-user.json',
    sendPackage: '/shared-folders/delivery/send.json',
    cancelDelivery: '/shared-folders/delivery/cancel.json',
    deleteDelivery: '/shared-folders/delivery/delete.json',
    deliveryDetailGuest: '/shared-folders/delivery/guest/detail.json',
    deliveryDetailHost: '/shared-folders/delivery/detail.json',
    guestDownload: '/shared-folders/delivery/guest/export.json',
    ec2GuestDownload: '/guest/zip',
    updateDelivery: '/shared-folders/delivery/update.json'
  },
  trash: {
    move: '/shared-folders/drive/item/move-to-trash.json',
    delete: '/shared-folders/drive/item/delete.json',
    recycle: '/shared-folders/drive/item/recycle-from-trash.json',
    list: '/shared-folders/drive/item/get-trash-items.json'
  },
  tenant: {
    tenantCheck: '/tenants/check-ip.json'
  },
  document: {
    signHost: '/document-sign/host',
    signGuest: '/document-sign/guest',
    reissueGuest: '/document-sign/guest/reissue-url',
    signGuestStatus: '/document-sign/guest/status',
    detailDocument: '/document/detail',
    signatureInfo: '/document-signatureinfo.json'
  },
  business_number: {
    search: '/business/search.json',
    searchCsv: '/business/csv-search.json'
  }
};

driveApiCall.interceptors.request.use(
  async function (config) {
    const tokenID = await Auth.GetTokenID();
    // Do something before request is sent
    // loading.set((v) => true);
    //   const currentUserToken = getCurrentUserAccessToken();
    if (tokenID) config.headers.Authorization = `${tokenID}`;
    return config;
  },
  function (error) {
    // loading.set((v) => false);
    // Do something with request error
    return Promise.reject(error);
  }
);


ec2DriveApiCall.interceptors.request.use(
  async function (config) {
    const tokenID = await Auth.GetTokenID();
    // Do something before request is sent
    // loading.set((v) => true);
    //   const currentUserToken = getCurrentUserAccessToken();
    if (tokenID) config.headers.Authorization = `${tokenID}`;
    return config;
  },
  function (error) {
    // loading.set((v) => false);
    // Do something with request error
    return Promise.reject(error);
  }
);
