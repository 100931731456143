import Loading from '../Loading';
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import React from "react";
import Button from '@mui/material/Button';
import VerificationFileUploadComponent
	from "../Components/VerificationFileUploadComponent/VerificationFileUploadComponent";
import LoadingList from "../LoadingList";
import {StateFragment} from "@hookstate/core";
import {shedOnScroll} from "../../data-access/app.state";
import '../Assets/css/settings.css';
import '../../index.css';
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import { Modal } from 'react-bootstrap';

export default class TemplateVerificationData {

	static get key() {
		return "template-verification-data";
	}
	static get name() {
		return "TemplateVerificationData";
	}
	static get code() {
		return TemplateVerificationData.name.toLowerCase();
	}

	constructor(objMain) {
		this.objMain = objMain;
		this.key = TemplateVerificationData.key;
		this.name = TemplateVerificationData.name;
		this.code = TemplateVerificationData.code;

		this.formData = {
			param_info: "param-info"
		};

		this.objMain.setPageObject(this);
	}

	formSubmitValidation = (ev, PDFFiles) => {
		ev.preventDefault();

		let { pageState } = this.objMain.state;
		pageState[this.name].completed = true;
		this.objMain.updateState({ pageState: pageState, PDFFiles });
		this.objMain.nextPageStep(ev, this.constructor);
	}

	ValidateTotalDataStatus = (ev) => {
		const {csvVerificationList} = this.objMain.state;

		return csvVerificationList.filter(item => item.row_data_status === 0).length > 0 ? 0 : 1;
	}

	formSubmitCallback = (ev) => {
		if (this.ValidateTotalDataStatus() === 0) {
			return;
		}
		console.log(ev);
		console.log(this.constructor);
		let { pageState } = this.objMain.state;
		pageState[this.name].completed = true;
		this.objMain.setFormData(this.formData);
		this.objMain.updateState({ pageState });
		this.objMain.nextPageStep(ev, this.constructor);
	}

	ShowErrorMessage = (errReponse, fnCallback) => {
		console.log(errReponse);
		let { t } = this.props;
		let { modal } = this.objMain.state;

		let errMessage = errReponse.data.message;
		if (errReponse.data.hasOwnProperty("error")) {
			errMessage = errReponse.data.error;
		}

		modal.body = errMessage;
		modal.action = [(
			<Button key="ok" variant="primary" onClick={(ev) => {
				modal.body = Loading;
				modal.action = [];
				this.objMain.updateState({ modal }, fnCallback);
			}}>
				<span>{t("common:general.try-again")}</span>
			</Button>
		)];
		this.objMain.updateState({ modal });
	}

	ValidateRequiredField = (row) => {
		const fields = ['document_type', 'preservation_require', 'doc_title', 'received_date', 'counter_party_name', 'amount', 'currency', 'pdf_name'];

		return !fields.some(field => !row[field]);
	}

	ListCsvTable = () => {
		let { t } = this.props;

		let { csvVerificationList } = this.objMain.state;

		console.log("csvVerificationList", csvVerificationList)

		let datalistLoading = false;

		if (datalistLoading || csvVerificationList === null) {
			return <LoadingList />;
		}

		let rows = [];

		let rowStatus = (status) => {
			switch (status) {
				case 0:
					return (
						<ErrorIcon style={{
							color: "#e1b03e"
						}} />
					);
				case 1:
					return (
						<CheckCircleIcon color={"success"}/>
					);
				default:
					return (
						<></>
					);
			}
		}

		const removeRow = (rowIndex) => {
			csvVerificationList.splice(rowIndex, 1);
			this.objMain.updateState({
				csvVerificationList: csvVerificationList,
			})
		}

		for (let i = 0; i < csvVerificationList.length; i++) {
			let data = csvVerificationList[i];

			rows.push(
				<tr
					key={i}
					className={`document-row-list clickable ${this.ValidateRequiredField(data) ? "" : "verification-row-error"}`}
					onClick={(ev) => {

					}}
				>
					<td className="creator-info">
						<div className="inner-container">
							{rowStatus(data?.row_data_status)}
						</div>
					</td>
					<td className="creator-info">
						<div className="inner-container">
							{data?.document_type_text}
						</div>
					</td>
					<td className="creator-info">
						<div className="inner-container preservation_require_text">
							{data?.preservation_require_text}
						</div>
					</td>
					<td className="creator-info">
						<div className="inner-container doc_title">
							{data?.doc_title}
						</div>
					</td>
					<td className="creator-info">
						<div className="inner-container transaction_date">
							{data?.received_date}
						</div>
					</td>
					<td>
						<div className="text-ellipsis">
							{data?.counter_party_name}
						</div>
					</td>
					<td>
						<div className="text-ellipsis">
							{data?.amount}
						</div>
					</td>
					<td>
						<div className="text-ellipsis">
							{data?.currency}
						</div>
					</td>
					<td>
						<div className="text-ellipsis">
							{data?.invoice_number}
						</div>
					</td>
					<td>
						<div className="text-ellipsis">
							{data?.product}
						</div>
					</td>
					<td>
						<div className="text-ellipsis">
							{data?.reference}
						</div>
					</td>
					<td>
						<div className="text-ellipsis">
							{data?.filebox_shortcut}
						</div>
					</td>
					<td>
						<div className="text-ellipsis">
							{data?.doc_attributes_text}
						</div>
					</td>
					<td>
						<div className="text-ellipsis">
							{data?.pdf_name}
						</div>
					</td>
					<td className="verification-trash-icon">
						<div className="inner-container">
							<DeleteIcon onClick={() => {
								removeRow(i)
							}}/>
						</div>
					</td>
				</tr>
			)

		}

		if (rows.length === 0) {
			rows.push(
				<tr key="empty">
					<td colSpan="4">
						<div className="lz-txt-c lz-p-40">
							<span>{t('common:settings.doocument.empty')}</span>
						</div>
					</td>
				</tr>
			);
		}

		return (
			<table className={"verification-table"}>
				<thead>
					<StateFragment state={shedOnScroll}>
						{s => (
							<tr className={`${s.get({noproxy: true}) && "shed"}`}>
								<th className='verification-doc-type'>
									<div>{rowStatus(this.ValidateTotalDataStatus())}</div>
								</th>
								<th className='verification-doc-type'>
									<div>{t('common:document.verification.doc-type')}</div>
								</th>
								<th className='verification-preservation-require'>
									<div>{t('common:document.verification.preservation-require')}</div>
								</th>
								<th className='verification-doc-title'>
									<div>{t('common:document.verification.doc-title')}</div>
								</th>
								<th className='verification-received-date'>
									<div>{t('common:document.verification.received-date')}</div>
								</th>
								<th className='verification-counterparty'>
									<div>{t('common:document.verification.counter-party-name')}</div>
								</th>
								<th className='verification-amount'>
									<div>{t('common:document.verification.amount')}</div>
								</th>
								<th className='verification-currency'>
									<div>{t('common:document.verification.currency')}</div>
								</th>
								<th className='verification-invoice'>
									<div>{t('common:document.verification.invoice-number')}</div>
								</th>
								<th className='verification-product'>
									<div>{t('common:document.verification.product')}</div>
								</th>
								<th className='verification-reference'>
									<div>{t('common:document.verification.reference')}</div>
								</th>
								<th className='verification-shared-folders-path'>
									<div>{t('common:document.verification.shared-folders-path')}</div>
								</th>
								<th className='verification-doc-attributes'>
									<div>{t('common:document.verification.doc-attributes')}</div>
								</th>
								<th className='verification-pdf-name'>
									<div>{t('common:document.verification.pdf-name')}</div>
								</th>
								<th className='verification-trash'>
									{/*<div>{t('common:document.trash')}</div>*/}
								</th>
							</tr>
						)}
					</StateFragment>
				</thead>
				<tbody>
				{rows}
				</tbody>
			</table>
		)
	}

	handleProcessFile = (files) => {
		this.objMain.setState({files})
		const {csvVerificationList, PDFFiles} = this.objMain.state;
		Object.keys(files).forEach((key) => {
			let pdfObject = files[key];
			let csvIndex = csvVerificationList.findIndex((item) => {
				const isAnyExtFile = /\.[^.]+$/.test(item.pdf_name);
				if(isAnyExtFile){
					return item.pdf_name === pdfObject.originalName
				}else{
					const fileNameWithoutExt = pdfObject.originalName.substring(0, pdfObject.originalName.lastIndexOf("."));
					return item.pdf_name === fileNameWithoutExt
				}
				
			})
			if (csvIndex >= 0 && this.ValidateRequiredField(csvVerificationList[csvIndex])) {
				csvVerificationList[csvIndex] = {
					...csvVerificationList[csvIndex],
					row_data_status: 1
				}
				PDFFiles[key] = {
					...files[key],
					formData: {
						...csvVerificationList[csvIndex],
					},
					validity: true,
					initialDone: true,
				}
			}
		})

		this.objMain.updateState({
			files: files,
			csvVerificationList,
			PDFFiles,
			PDFFile: PDFFiles[Object.keys(PDFFiles)[0]],
			formData: PDFFiles[Object.keys(PDFFiles)[0]].formData,
			PDFLoadSuccess: true,
			selectedFile: Object.keys(PDFFiles)[0],
			filesSort: [],
			isFileLoading: false,
		})
	}

	handleRemoveFile = (pdfObject) => {
		const {PDFFiles, csvVerificationList} = this.objMain.state;

		let csvIndex = csvVerificationList.findIndex(item => item.pdf_name === pdfObject.originalName)
		if (csvIndex >= 0) {
			csvVerificationList[csvIndex] = {
				...csvVerificationList[csvIndex],
				row_data_status: 0
			}
			delete(PDFFiles[pdfObject.hash])
		}

		this.objMain.updateState({
			PDFFiles,
			csvVerificationList
		})
	}

	// IsFileNameExistsWithExt = (files, fileName) => {
    //     let check = false
    //     Object.keys(files).forEach((key) => {
    //         let file = files[key];
    //         if (file.name === fileName) {
    //             check = true;
    //         }
    //     })
    //     return check;
    // }

    IsExistExtension = (fileName) => {
        let check = false;
        this.objMain.state.csvVerificationList.map((item) => {
            const isAnyExtFile = /\.[^.]+$/.test(item.pdf_name);
            if(!isAnyExtFile){
                const fileNameWithoutExt = fileName.substring(0, fileName.lastIndexOf("."));
                if (item.pdf_name === fileNameWithoutExt) check = true
            }
        })
        return check
    }


	handleReplaceFile = () => {
		const { files, csvVerificationList, PDFFiles, selectedFile } = this.objMain.state;
		const keys = Object.keys(files || {});
		if (keys.length === 0) return;
		const fileKey = keys[keys.length - 1];
		const fileName = files[fileKey].name.split('.').slice(0, -1).join('.');
		Object.keys(files).forEach((key) => {
			const pdfObject = files[key];
			const fileNameWithoutExt = pdfObject.name.split('.').slice(0, -1).join('.');
			if(this.IsExistExtension(files[fileKey].name)){
				if(key !== fileKey && fileNameWithoutExt === fileName ){
					console.log(pdfObject);
					let csvIndex = csvVerificationList.findIndex(item => item.pdf_name === pdfObject.originalName)
					if (csvIndex >= 0) {
						csvVerificationList[csvIndex] = {
							...csvVerificationList[csvIndex],
							row_data_status: 0
						}
					}
					delete PDFFiles[pdfObject.hash]
					delete files[key]
				}
			}else{
				if(key !== fileKey && pdfObject.name === files[fileKey].name ){
					console.log(pdfObject);
					let csvIndex = csvVerificationList.findIndex(item => item.pdf_name === pdfObject.originalName)
					if (csvIndex >= 0) {
						csvVerificationList[csvIndex] = {
							...csvVerificationList[csvIndex],
							row_data_status: 0
						}
					}
					delete PDFFiles[pdfObject.hash]
					delete files[key]
				}
			}
			
		})
		this.objMain.setState({
			files,
			showAlertDuplicateUpload: false,
			PDFFiles,
			csvVerificationList
		})
	}

	handleCancelReplaceFile = () => {
		const { files } = this.objMain.state;
		const keys = Object.keys(files);
		if (keys.length === 0) return;
		const fileKey = keys[keys.length - 1];
		this.handleRemoveFile(files[fileKey])
		delete files[fileKey]
		this.objMain.setState({
			files,
			showAlertDuplicateUpload: false
		})
	}


	render() {
		let { t } = this.props;
		let { ListCsvTable } = this;

		console.log("this.objMain.state", this.objMain.state)

		let viewerProps = {
			"files": this.objMain.state.files || {},
			"file": {},
			"upload": true,
			"showAll": true,
			"fit": true,
			"controller": true,
			"multiple": true,
			"full": false,
			"list": true,
			"info": false,
			"objMain": this.objMain,
			"isFileLoading": false,
			"fileChangeCB": (files) => this.handleProcessFile(files),
			"fileRemoveCB": (file) => this.handleRemoveFile(file),
			"submitCB": () => {},
			"filesSort": [],
			"csvVerificationListComponent": this.objMain.state.csvVerificationList
		}

		return (
			<div className={"verification-data-container form-wrapper confirmation-info"}>
				<div className={'verification-data full-form'}>
					<div className={'verification-data-table'}>
						<div className={'verification-table-outer'}>
							<ListCsvTable/>
						</div>
            <div className={'verification-upload-outer'}>
							<VerificationFileUploadComponent
								key={this.objMain.state.keyFileUpload}
								{...viewerProps}
							/>
						</div>

						<div className="form-foot verification-data-foot">
							<div className="page-action-bottom-relative">
								<div className="wrapper-button-cancel">
									<Button
										variant="contained"
										color="info"
										size='large'
										startIcon={<ChevronLeftIcon/>}
										onClick={(ev) => this.objMain.prevPageStep(ev, this.constructor)}
									>
										<span className="btn-label">{t('common:documents.general.back')}</span>
									</Button>
								</div>
								<div className="wrapper-button-submit">
									<Button
										variant="contained"
										disabled={this.ValidateTotalDataStatus()===0}
										onClick={(ev) => this.formSubmitCallback(ev)}
										size='large'
										endIcon={<ChevronRightIcon/>}
									>
										<span className="btn-label">{t('common:documents.general.next')}</span>
									</Button>
								</div>
							</div>
						</div>

					</div>
				</div>

				<Modal
					show={this.objMain.state.showAlertDuplicateUpload}
					centered
				>
					<Modal.Body>
						<p>{t("common:verification.batch.duplicate-pdf-name-replace")}</p>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="outlined" type="submit" onClick={this.handleCancelReplaceFile}>
						{t("common:general.cancel")}
						</Button>
						<Button variant="contained" type="submit" onClick={this.handleReplaceFile}>
						{t("common:general.ok")}
						</Button>
					</Modal.Footer>
				</Modal>
			</div>
		);

	}

}
