// @ts-nocheck
import {useHookstate} from "@hookstate/core";
import {ArrowBack} from "@mui/icons-material";
import Save from "@mui/icons-material/Save";
import {LoadingButton} from "@mui/lab";
import logo from '../../Assets/img/logo_figure.svg';
import {
    Alert,
    Button,
    Divider,
    FormControl,
    IconButton,
    Snackbar,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import React, {useEffect} from "react";
import {Controller, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import BaseCard from "../../../Components/base-card/base-card";
import SwitchCustomize from "../../../Components/switch-customize/switch-customize";
import {GetUserInfoNew} from "../../../data-access/api";
import {notificationAlertState, userInfoState,} from "../../../drive-feature/data-access/state";
import Service from "../../../Service";
import GroupMemberChip, {groupMembersState} from "../Users/GroupMemberChip";
import styles from "./guest-detail.module.css";
import { debounce } from "../../../drive-feature/utils/hooks";

export default function GuestDetailPages(props) {
  const { t } = useTranslation("common");
  // const { tenantId } = useParams();
  const methods = useForm({
    mode: 'all',
    defaultValues: {
      first_name: "",
      first_name_kana: "",
      family_name: "",
      family_name_kana: "",
      email: "",
      mobile_number: "",
      company_name: "",
      groups: [],
      status: true,
    },
  });
  const loadingBtnResend = useHookstate(false);
  const loadingBtn = useHookstate(false);
  const showAlert = useHookstate(notificationAlertState);
  const userInfo = useHookstate(userInfoState);
  const services = useHookstate([]);
  // const location = useLocation();
  // const history = useHistory();
  const {
    handleSubmit,
    formState: { errors, dirtyFields },
    control,
    reset,
    setValue,
    watch,
  } = methods;
  const isOpenSelectApprover = useHookstate(false);
  const isExistEmail = useHookstate(false);

  useEffect(() => {
    if (props.guestDetail && props.showDetail) {
      reset({ ...props.guestDetail, company_name: props?.guestDetail?.additional_info?.company_name || ''  });
    } else {
      reset({
        first_name: "",
        first_name_kana: "",
        family_name: "",
        family_name_kana: "",
        email: "",
        company_name: "",
        groups: [],
        status: true,
      });
    }
  }, [props.showDetail, props.guestDetail]);
  // console.log(props.guestDetail);
  //   console.log(props.approver_id);
  const submitSave = (data) => {
    // console.log(data);
    const user_info = userInfo.get({ noproxy: true });
    // console.log(!isOpenSelectApprover.get({stealth:true}), props["approver_id"].get())
    if (
      user_info?.administrator_role == "0" &&
      !isOpenSelectApprover.get({ stealth: true })
    ) {
      if (props.guestDetail?.user_id != null) {
      } else {
        let { modal } = props.main.state;
        isOpenSelectApprover.set(true);
        modal = {
          props: {
            show: true,
            dialogClassName: "profile-dialog",
            centered: true,
          },
          title: "common:settings.guest.approver-settings",
          body: props.main.ApproverForm,
          action: [
            <Button
              sx={{ ml: 1 }}
              key="ok"
              variant="contained"
              onClick={() => {
                modal.props.show = false;
                props.main.setState({ modal });
                setTimeout(() => {
                  handleSubmit(submitSave)();
                });
              }}
            >
              <span>{t("common:general.ok")}</span>
            </Button>,
          ],
        };

        props.main.setState({ modal });
        return;
        // props.main.handleOpenApproverDialog();
      }
    }
    // else if (user_info?.administrator_role == "1" && !props.approver_id) {
    // }

    /* Translate function */
    // let { t } = this.props;

    /* */
    // let { datalist, ModalBody, modalProps, ModalAction, UserInfo } = this.state;

    /* */
    // let form = document.querySelector("#settings-user-form");
    // let fields = form.elements;
    // let email = fields["email"]["value"];
    // console.log(fields);

    /* Preprae RequestBody */
    let body = {
      user_id: props?.guestDetail?.user_id?.toString(),
      status: `${!!(data.status === true || data.status === 0 || data.status === 'checked') ? 0 : 1}`,
      organizations: groupMembersState
        .get({ stealth: true })
        .reduce((arr, a) => {
          if (a.isActive) {
            arr.push(a.user_id.toString());
          }
          return arr;
        }, []),
      family_name: data.family_name,
      first_name: data.first_name,
      family_name_kana: data.family_name_kana,
      first_name_kana: data.first_name_kana,
      mobile_number: data.mobile_number,
      company_name: data.company_name,
    };
    if (user_info.administrator_role == "0") {
      body.approver_id = props["approver_id"].get() ?? undefined;
    }

    loadingBtn.set(true);
    /* */
    if (props.fromCreate) {
      body.email = data.email;

      const createUserGuestFunction = () => {
        isOpenSelectApprover.set(false);
        loadingBtn.set(false);
        let { modal } = props.main.state;
        modal.props = {
          show: true,
          centered: true,
          backdrop: true,
        };
        modal.action = [];
        modal.close = true;

        modal.body = t(`common:settings.guest.dialog.registration.body`);
        // console.log(props.activeTab, body.status)

        props.main.setState(
            {
              modal,
              pagination: {
                page_number: 1,
                page_size: 100,
              },
              guestDetail: null,
              showGuestDetail: false,
              datalist: null,
            },
            () => props.main.handleGetGuestList(props.main.state.user_info)
        );
        // console.log(newList, data.tenant_id);
      }

      Service.setUserGuest(body)
        .then(async (response) => {
          createUserGuestFunction();
        })
        .catch((error) => {
          isOpenSelectApprover.set(false);

          if (error?.response?.data?.payload?.['duplicated_guest']?.user_id) {
            const duplicatedGuest = error?.response?.data?.payload?.['duplicated_guest'];
            let { modal } = props.main.state;
            let SendMessage = () => {
              let messageArr = [];
              messageArr.push(
                  <div>
                    <p>{t('common:settings.guest.guest-exist')}</p>
                  </div>
              );
              messageArr.push(
                  <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-start",
                      }}
                  >
                    <div>
                      <p>{duplicatedGuest.additional_info.company_name} {duplicatedGuest.family_name} {duplicatedGuest.first_name}</p>
                      <p>{duplicatedGuest.email}</p>
                    </div>
                  </div>
              );
              messageArr.push(
                  <div>
                    <p>{t('common:settings.guest.register-confirm')}</p>
                  </div>
              );
              return messageArr;
            }

            modal.props = {
              show: true,
              centered: true,
              backdrop: true,
            };

            modal.body = () => {
              return (
                  <div className="">
                    <SendMessage />
                  </div>
              );
            };

            modal.action = [(
                <Button
                    sx={{ml: 1}}
                    variant="contained"
                    key="ok"
                    onClick={(ev) => {

                      loadingBtnResend.set(true);
                      loadingBtn.set(true);
                      modal.props = {
                        show: false,
                        centered: true,
                        backdrop: true,
                      };
                      modal.body = null;
                      modal.close = false;
                      modal.action = [];
                      props.main.setState({ modal });

                      Service.setUserGuest({
                        ...body,
                        ignore_duplicate: true,
                      })
                          .then(async (response) => {
                            createUserGuestFunction();
                          })
                          .catch((error) => {
                            isOpenSelectApprover.set(false);

                            let msgErr = error?.response?.data?.message;

                            if (
                                error?.code === "ECONNABORTED" ||
                                (error?.response?.status === 504 &&
                                    error?.response?.data?.message === "Endpoint request timed out")
                            ) {
                              msgErr = t(
                                  "message.error.request.timed_out",
                                  `サーバーが混雑しております。\n しばらく待ってアクセスしてください。`
                              );
                              // return;
                            }
                            /* Update DialogBody */
                            let { modal } = props.main.state;
                            modal.props = {
                              show: true,
                              dialogClassName: "loading-dialog",
                              centered: true,
                            };
                            modal.body = t(`common:${msgErr || 'message.error.server_unknown_error'}`);
                            modal.close = true;

                            props.main.setState({ modal });
                            loadingBtnResend.set(false);
                            loadingBtn.set(false);
                          })
                          .finally(() => loadingBtn.set(false));
                    }}>
                  <span>{t("common:general.ok")}</span>
                </Button>
            )];

            modal.close = true;

            props.main.setState({ modal });

            loadingBtnResend.set(false);
            loadingBtn.set(false);
            return;
          }

          let msgErr = error?.response?.data?.message;

          if (
            error?.code === "ECONNABORTED" ||
            (error?.response?.status === 504 &&
              error?.response?.data?.message === "Endpoint request timed out")
          ) {
            msgErr = t(
              "message.error.request.timed_out",
              `サーバーが混雑しております。\n しばらく待ってアクセスしてください。`
            );
            // return;
          }
          /* Update DialogBody */
          let { modal } = props.main.state;
          modal.props = {
            show: true,
            dialogClassName: "loading-dialog",
            centered: true,
          };
          modal.body = t(`common:${msgErr || 'message.error.server_unknown_error'}`);
          modal.close = true;

          props.main.setState({ modal });
          loadingBtnResend.set(false);
          loadingBtn.set(false);
        })
        .finally(() => loadingBtn.set(false));
    }
    if (!props.fromCreate) {
      body.email = data.email;
      Service.setUserGuest(body)
        .then(async (response) => {
          loadingBtn.set(false);
          /* */
          let { modal } = props.main.state;
          modal.props = {
            show: true,
            centered: true,
            backdrop: true,
          };
          modal.close = true;
          modal.body = t(`common:settings.guest.dialog.update.body`);
          // console.log(newList, data.tenant_id);

          props.main.setState(
            {
              modal,
              pagination: {
                page_number: 1,
                page_size: 100,
              },
              modalClose: true,
              guestDetail: null,
              showGuestDetail: false,
              datalist: null,
            },
            () => props.main.handleGetGuestList(props.main.state.user_info)
          );
          // }).catch((error) => {
          // 	//console.log("CATCH", error);
          // });
          GetUserInfoNew();
          // await this.context.getUserInfo();
        })
        .catch((error) => {
          let msgErr = error?.response?.data?.message;

          loadingBtn.set(false);
          if (
            error?.code === "ECONNABORTED" ||
            (error?.response?.status === 504 &&
              error?.response?.data?.message === "Endpoint request timed out")
          ) {
            msgErr = t(
              "message.error.request.timed_out",
              `サーバーが混雑しております。\n しばらく待ってアクセスしてください。`
            );
            // return;
          }
          /* Update DialogBody */
          let { modal } = props.main.state;
          modal.props = {
            show: true,
            dialogClassName: "loading-dialog",
            centered: true,
          };
          modal.body = t(`common:${msgErr || 'message.error.server_unknown_error'}`);
          modal.close = true;

          props.main.setState({ modal });
        });
    }
    /* */
    // this.updateState({ modalClose: false, ModalBody });
  };

  if (!props.showDetail)
    return (
      <>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={showAlert.show.get()}
          autoHideDuration={3500}
          onClose={handleClose}
        >
          <Alert severity={showAlert.type.get()}>
            {showAlert.message.get()}
          </Alert>
        </Snackbar>
      </>
    );

  function handleClose(event, reason) {
    if (reason === "clickaway") {
      return;
    }

    showAlert.show.set(false);
  }
  function resendApprove() {
    loadingBtnResend.set(true);
    Service.ResendGuestApprovalEmail({
      guest_id: props.guestDetail?.user_id,
    })
      .then((resp) => {
        const { ok, payload } = resp;
        if (ok) {
          let { modal } = props.main.state;
          const { sent_result } = payload;
          modal.body = sent_result.ok
            ? t("common:settings.guest.resend-approval-mail-success")
            : t("common:settings.guest.resend-approval-mail-fail");
          modal.close = true;
          props.main.setState({ modal });
        }
        loadingBtnResend.set(false);
      })
      .catch((err) => {
        console.log("error", err.response);
        let errMessage = err?.response?.data?.payload?.message;
        if (err?.response?.data?.payload?.hasOwnProperty("error")) {
          errMessage = err.response.data.payload.error;
        }
        let { modal } = props.main.state;
        modal.props = {
          show: true,
          dialogClassName: "loading-dialog",
          centered: true,
        };
        modal.body = t(`common:${errMessage || 'message.error.server_unknown_error'}`);
        modal.close = true;

        props.main.setState({ modal });
        loadingBtnResend.set(false);
      });
  }

    function debounce(func, wait) {
        let timeout;

        return function executedFunction(...args) {
            const context = this;
            const later = () => {
                clearTimeout(timeout);
                func.apply(context, args);
            };

            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
        };
    }

    function handleInputChange(event) {
        const email = event.target.value;
        const regexEmail = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/;
        if (regexEmail.test(email)) {
            Service.GuestCollaborationEmailVerify({
                email: email,
            }).then((resp) => {
                if (resp.ok && resp.payload.email_verify) {
                    console.log("hadfas")
                    isExistEmail.set(true);
                } else {
                    isExistEmail.set(false);
                }
            }).catch((err) => {
                isExistEmail.set(false);
            });
        } else {
            isExistEmail.set(false);
        }
    }

    let debouncedInputChange = debounce(handleInputChange, 1000);

  // console.log(location, history, match)
  // console.log('breadcrumbs', breadcrumbs)
  return (
    <div className="p-3">
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={showAlert.show.get()}
        autoHideDuration={3500}
        onClose={handleClose}
      >
        <Alert severity={showAlert.type.get()}>{showAlert.message.get()}</Alert>
      </Snackbar>
      <div className="go_back_wrapper">
        <Tooltip
          placement="top"
          disableInteractive
          title={t("common:guest.detail.pages.go_back", "ゲスト")}
        >
          <IconButton
            onClick={(e) => {
              props.main.setState({
                guestDetail: null,
                showGuestDetail: false,
              });
            }}
          >
            <ArrowBack className="go_back_icon" />
          </IconButton>
        </Tooltip>
        <span>{t("common:guest.detail.pages.go_back", "ゲスト")}</span>
      </div>
      {/* <Divider /> */}
      <BaseCard
        className={styles["base_tenant_card"]}
        content={
          <div className="base_content_grid">
            <div className={`${styles["button_action"]} button-right-shoulder`}>
              {props.guestDetail?.status === 3 && (
                <LoadingButton
                  loading={loadingBtnResend.get()}
                  color="secondary"
                  size="small"
                  aria-label="SaveIcon"
                  key="ec-save"
                  //   startIcon={<Save />}
                  variant="contained"
                  sx={{ ml: 1 }}
                  onClick={resendApprove}
                >
                  <span>{t("common:general.resend-guest-approval-email")}</span>
                </LoadingButton>
              )}
              <LoadingButton
                loading={loadingBtn.get()}
                color="secondary"
                size="small"
                aria-label="SaveIcon"
                key="ec-save"
                startIcon={<Save />}
                variant="contained"
                onClick={handleSubmit(submitSave)}
              >
                {t("general.save")}
              </LoadingButton>
            </div>
            <div className="field_wrapper">
              <Typography
                color={"#062b60"}
                className={styles["form_text"]}
                variant="subtitle2"
                component="p"
              >
                {t("common:settings.guest.enable_disable", "有効 / 無効")}
              </Typography>
              <div className="col-span-4">
                <FormControl fullWidth>
                  <Controller
                    name={"status"}
                    control={control}
                    // rules={rules}
                    render={({ field: { onChange, value }, formState }) => (
                      <SwitchCustomize
                        setVal={setValue}
                        name={"status"}
                        // disabled={props.guestDetail?.status === 2}
                        active={value === 0 || value === true}
                        onChange={onChange}
                      />
                    )}
                  />
                </FormControl>
              </div>
            </div>
            <div className="field_wrapper">
              <Typography
                color={"#062b60"}
                className={styles["form_text"]}
                variant="subtitle2"
                component="p"
              >
                {/* 携帯電話番号 */}
                {t("common:settings.guest.company_name")}
              </Typography>
              <div className="col-span-3">
                <FormControl fullWidth>
                  <Controller
                    name={"company_name"}
                    control={control}
                    // rules={rules}
                    render={({ field: { onChange, value }, formState }) => (
                      <TextField
                        className="bg-white"
                        onChange={onChange}
                        size="small"
                        inputProps={{ maxLength: 30 }}
                        value={value}
                        InputLabelProps={{ style: { fontSize: 14 } }}
                        // value={textInputValue}
                        // placeholder={textInputPlaceHolder}
                        // label={textFieldLabel || 'Filled'}
                        variant="outlined"
                      />
                    )}
                  />
                </FormControl>
              </div>
            </div>
            <div className="field_wrapper">
              <Typography
                color={"#062b60"}
                className={styles["form_text"]}
                variant="subtitle2"
                component="p"
              >
                {/* 姓名 */}
                {t("common:settings.guest.first_last_name")}
              </Typography>
              <div className="flex items-center col-span-2">
                <Typography
                  color={"#062b60"}
                  variant="subtitle2"
                  className="pr-4 w-14 text-end"
                >
                  {/* 姓 */}
                  {t("common:settings.guest.family_name")}
                </Typography>
                <FormControl fullWidth>
                  <Controller
                    name={"family_name"}
                    control={control}
                    // rules={rules}
                    rules={{
                      required: t("common:settings.validate.guest.family_name"),
                    }}
                    render={({ field: { onChange, value }, formState }) => {
                      // console.log(formState);
                      return (
                        <TextField
                          className={
                            "bg-white required-form" +
                            ` ${
                              formState.dirtyFields["family_name"] && value
                                ? "valid-form"
                                : ""
                            } ${
                              formState.errors["family_name"]
                                ? "errors-form"
                                : ""
                            }`
                          }
                          onChange={onChange}
                          size="small"
                          value={value}
                          error={formState.errors["family_name"]}
                          helperText={formState.errors["family_name"]?.message}
                          InputLabelProps={{ style: { fontSize: 14 } }}
                          // value={textInputValue}
                          // placeholder={textInputPlaceHolder}
                          // label={textFieldLabel || 'Filled'}
                          variant="outlined"
                        />
                      );
                    }}
                  />
                </FormControl>
              </div>
              <div className="flex items-center col-span-2">
                <Typography
                  color={"#062b60"}
                  variant="subtitle2"
                  className="pr-4 w-14 text-end"
                >
                  {/* 名 */}
                  {t("common:settings.guest.first_name")}
                </Typography>
                <FormControl fullWidth>
                  <Controller
                    name={"first_name"}
                    control={control}
                    // rules={rules}
                    rules={{
                      required: t("common:settings.validate.guest.first_name"),
                    }}
                    render={({ field: { onChange, value }, formState }) => (
                      <TextField
                        className={
                          "bg-white required-form" +
                          ` ${
                            formState.dirtyFields["first_name"] && value
                              ? "valid-form"
                              : ""
                          } ${
                            formState.errors["first_name"] ? "errors-form" : ""
                          }`
                        }
                        error={formState.errors["first_name"]}
                        helperText={formState.errors["first_name"]?.message}
                        onChange={onChange}
                        size="small"
                        value={value}
                        InputLabelProps={{ style: { fontSize: 14 } }}
                        // value={textInputValue}
                        // placeholder={textInputPlaceHolder}
                        // label={textFieldLabel || 'Filled'}
                        variant="outlined"
                      />
                    )}
                  />
                </FormControl>
              </div>
            </div>
            <div className="field_wrapper">
              <Typography
                color={"#062b60"}
                className={styles["form_text"]}
                variant="subtitle2"
                component="p"
              >
                {/* カナ */}
                {t("common:settings.guest.family_last_kana", "カナ")}
              </Typography>
              <div className="flex items-center col-span-2">
                <Typography
                  color={"#062b60"}
                  variant="subtitle2"
                  className="pr-4 w-14 text-end"
                >
                  {t("common:settings.guest.family_name_kana")}
                </Typography>
                <FormControl fullWidth>
                  <Controller
                    name={"family_name_kana"}
                    control={control}
                    // rules={rules}
                    render={({ field: { onChange, value }, formState }) => (
                      <TextField
                        className="bg-white"
                        onChange={onChange}
                        size="small"
                        value={value}
                        InputLabelProps={{ style: { fontSize: 14 } }}
                        // value={textInputValue}
                        // placeholder={textInputPlaceHolder}
                        // label={textFieldLabel || 'Filled'}
                        variant="outlined"
                      />
                    )}
                  />
                </FormControl>
              </div>
              <div className="flex items-center col-span-2">
                <Typography
                  color={"#062b60"}
                  variant="subtitle2"
                  className="pr-4 w-14 text-end"
                >
                  {/* メイ */}
                  {t("common:settings.guest.first_name_kana")}
                </Typography>
                <FormControl fullWidth>
                  <Controller
                    name={"first_name_kana"}
                    control={control}
                    // rules={rules}
                    render={({ field: { onChange, value }, formState }) => (
                      <TextField
                        className="bg-white"
                        onChange={onChange}
                        size="small"
                        value={value}
                        InputLabelProps={{ style: { fontSize: 14 } }}
                        // value={textInputValue}
                        // placeholder={textInputPlaceHolder}
                        // label={textFieldLabel || 'Filled'}
                        variant="outlined"
                      />
                    )}
                  />
                </FormControl>
              </div>
            </div>
            <div className="field_wrapper">
              <Typography
                color={"#062b60"}
                className={styles["form_text"]}
                variant="subtitle2"
                component="p"
              >
                {t("common:settings.guest.email")}
                {/* メールアドレス */}
              </Typography>
              <div className="col-span-4 col-span-6-sm">
                <FormControl fullWidth>
                  <Controller
                    name={"email"}
                    control={control}
                    rules={{
                      required: t("common:settings.validate.guest.email"),
                      pattern: {
                        value: /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/,
                        message: t("common:settings.validate.user.email_pattern"),
                      },
                    }}
                    render={({ field: { onChange, value }, formState }) => (
                      <div className="flex" style={{ width : 'calc(100% + 60px)'}}>
                        <TextField
                          className={
                            "bg-white required-form mypage-email-input" +
                            ` ${
                              !formState.errors["email"] && value ? "valid-form" : ""
                            } ${formState.errors["email"] ? "errors-form" : ""}`
                          }
                          error={formState.errors["email"]}
                          helperText={formState.errors["email"]?.message}
                          onChange={ onChange }
                          onInput={debouncedInputChange}
                          size="small"
                          disabled={!!props.guestDetail}
                          value={value}
                          InputLabelProps={{ style: { fontSize: 14 } }}
                          // value={textInputValue}
                          // placeholder={textInputPlaceHolder}
                          // label={textFieldLabel || 'Filled'}
                          variant="outlined"
                        />
                        <img className="logo-exist-email" src={logo} alt="icon" style={isExistEmail.get({noproxy: true}) ? {opacity: 1}: {opacity: 0}}></img>
                      </div>
                    )}
                  />
                </FormControl>
              </div>
            </div>
            {!!props.guestDetail && !!props.guestDetail.collaboration_tenant_name ? <div className="field_wrapper">
              <Typography
                color={"#062b60"}
                className={styles["form_text"]}
                variant="subtitle2"
                component="p"
              >
                {t("common:settings.guest.collaboration-tenant")}
                {/* メールアドレス */}
              </Typography>
              <div className="col-span-6 col-span-6-sm">
                <FormControl fullWidth>
                  <Controller
                    name={"collaboration_tenant_name"}
                    control={control}
                    render={({ field: { onChange, value }, formState }) => (
                        <TextField
                          className={
                            "bg-white required-form mypage-email-input valid-form"
                          }
                          onChange={ onChange }
                          onInput={debouncedInputChange}
                          size="small"
                          disabled={!!props.guestDetail}
                          value={value}
                          InputLabelProps={{ style: { fontSize: 14 } }}
                          variant="outlined"
                        />
                    )}
                  />
                </FormControl>
              </div>
            </div> : <></>}
            <div className="field_wrapper">
              <Typography
                color={"#062b60"}
                className={styles["form_text"]}
                variant="subtitle2"
                component="p"
              >
                {/* 携帯電話番号 */}
                {t("common:settings.guest.mobile")}
              </Typography>
              <div className="col-span-3">
                <FormControl fullWidth>
                  <Controller
                    name={"mobile_number"}
                    control={control}
                    // rules={rules}
                    render={({ field: { onChange, value }, formState }) => (
                      <TextField
                        className="bg-white"
                        onChange={onChange}
                        size="small"
                        inputProps={{ maxLength: 15 }}
                        value={value}
                        InputLabelProps={{ style: { fontSize: 14 } }}
                        // value={textInputValue}
                        // placeholder={textInputPlaceHolder}
                        // label={textFieldLabel || 'Filled'}
                        variant="outlined"
                      />
                    )}
                  />
                </FormControl>
              </div>
            </div>
            <Divider />
            <div className="field_wrapper">
              <Typography
                color={"#062b60"}
                className={styles["form_text"] + " h-full align-top"}
                variant="subtitle2"
                component="p"
              >
                {t("common:settings.guest.groups", "所属グループ")}
              </Typography>
              <div className="col-span-4">
                <GroupMemberChip />
              </div>
            </div>
            {/* <Divider /> */}
          </div>
        }
      />

      {/* <BaseCard
        className={styles["base_tenant_card"]}
        content={<GuestDetailEmailSettings />}
      /> */}
    </div>
  );
}
