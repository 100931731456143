// @ts-nocheck
import axios from "axios";
import API from "../../Config/API";
import { API_ROUTES, driveApiCall } from "../../drive-feature/config/api.config";
import { uploadToS3 } from "../../drive-feature/data-access/api";
import Service from "../../Service";

/**
 * @param {any} invoice_number_list
 */
export function searchBusinessNumber(invoice_number_list) {
    if (!invoice_number_list) return null;
    return driveApiCall.get(API_ROUTES.business_number.search, {
        params: {
            invoice_number_list
        }
    })
}

/**
 * @param {any} key
 */
export function searchBusinessNumberCsv(key) {
    if (!key) return null;
    return driveApiCall.get(API_ROUTES.business_number.searchCsv, {
        params: {
            key
        }
    })
}

export function csvUploadAndSearch(selectedFile) {
    return new Promise((resolve, reject) => {
        Service.getMultiPresignedUrl({
            files: [{
                file_name: selectedFile.name,
                file_type: selectedFile.type,
            }]
        })
            .then((resp) => {
                const { ok, payload } = resp;
                if (ok) {
                    const { key, url } = payload[0];
                    uploadToS3(
                        url,
                        selectedFile,
                        selectedFile.type,
                        (progressEvent) => {
                            const percentCompleted = Math.round(
                                (progressEvent.loaded * 100) / progressEvent.total
                            );
                            if (percentCompleted === 100) {
                                searchBusinessNumberCsv(key).then(res => {
                                    resolve(res.data)
                                }).catch(e => reject(e))
                            }
                        }
                    );
                }
            }).catch(e => reject(e))
    })
}