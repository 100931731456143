// @ts-nocheck
import React from "react";
import { useEffect } from 'react';

/**
 * Observes a given element using IntersectionObserver.
 *
 * @param {HTMLElement} [element] Element to attach IntersectionObserver to
 * @param {IntersectionObserverInit} [options] IntersectionObserver options. WARNING! If you define
 *   the object in component body, make sure to memoize it.
 * @param {Function} observerCallback IntersectionObserver callback. WARNING! If you define the
 *   function in component body, make sure to memoize it.
 * @returns {void}
 */
export function useIntersectionObserver(
    element,
    options,
    observerCallback,
) {
    useEffect(() => {
        if (!element || !('IntersectionObserver' in window)) {
            return undefined;
        }

        console.log(options, element, options)
        const observer = new IntersectionObserver(observerCallback, options);

        observer.observe(element);

        return () => {
            observer.disconnect();
        };
    }, [element, options, observerCallback]);
}
// @ts-nocheck
export default function initObserver(currentRangePage) {
    return new IntersectionObserver(entries => {
        const currentRange = entries.reduce((arr, item) => {
            if (item.isIntersecting) {
                arr.push(+item.target.dataset.pageNumber);
            }
            return arr;
        }, [])
        // console.log("Items in view have changed:", currentRange)
        // entries.forEach(({ target, isIntersecting }) => {
        // if(isIntersecting){
        if (currentRange.length > 0) currentRangePage.set(currentRange[currentRange.length - 1])
        // currentRangePage.set([currentRange[0], currentRange[currentRange.length - 1]].join('-'))
        // }
        // //   console.log(`I'm ${isIntersecting ? 'in view' : 'out of view'}: ${target?.dataset?.pageNumber}`, target);
        // });
    }, {
        // threshold: 0.3,
        root: document.querySelector('.pdf-full-preview')?.querySelector('.pdf-preview-panel') || document.querySelector('.pdf-preview-panel') || document.querySelector('.control-preview') || document
    })
}

export function PDFPreviewScrollHandler(ev, self, currentRangePage) {
    let { observer } = self.state;
    // console.log(observer);
    if (!observer) {
        self.setState({ observer: initObserver(currentRangePage) })
    }
    let listPageElement = document.querySelectorAll('.pdf-page-container');
    // let currentPage = 1;
    // const offsetBottomList = ev.target.scrollHeight
    if (!listPageElement?.length) {
        listPageElement = document?.querySelector('.pdf-preview-panel')?.querySelectorAll('.pdf-page-panel') || [];
    }
    // console.log(listPageElement)
    observer.disconnect();
    listPageElement.forEach(element => observer.observe(element.querySelector('[data-page-number]')));
}


export const replacerFunc = () => {
    const visited = new WeakSet();
    return (key, value) => {
        if (typeof value === "object" && value !== null) {
            if (visited.has(value)) {
                return;
            }
            visited.add(value);
        }
        return value;
    };
};