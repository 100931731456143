import '../Assets/css/settings.css';
import React from 'react';
import Layout from '../../Layout';
import Menu from './Menu';
import Loading from '../Loading';
import LoadingList from '../LoadingList';
import Service from '../../Service';
import { withTranslation } from "react-i18next";
import { Form, } from 'react-bootstrap';
import UploadIcon from '@mui/icons-material/Upload';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import IconButton from '@mui/material/IconButton';
import {StateFragment} from "@hookstate/core";
import {shedOnScroll} from "../../data-access/app.state";

/** */
class CounterParty extends Layout {

	/** Constructor */
	constructor(props) {
		super(props);
		this.state.page = "counter-party";
		this.state.activeTab = 0;
		this.state.tabs = [
			{ "status": 0, "label": "common:settings.guest.active" },
			{ "status": 1, "label": "common:settings.guest.inactive" },
		];

		/* For Panigation */
		this.state.pagination = {
			"page": 1,
			"take": 100,
			"total": 0,
		};

		/* For SearchBox */
		this.state.search = "";
		this.state.datalist = null;
		this.state.organizations = null;

		this.state.fields = {};
		this.state.keyFields = {
			"counter_party_name": "counter_party_name",
			"counter_party_id": "counter_party_id",
		}

	}

	setStateFields = (cb) => {
		let { t } = this.props;
		let { keyFields, fields, formData } = this.state;
		// this.state.fields = {};
		for (let key in keyFields) {
			let label = keyFields[key];
			fields[key] = {
				name: key,
				value: "",
				label: t(`common:settings.counter-party.${label}`),
			};
			if (formData[key]) {
				fields[key].value = formData[key];
			}
		}

		this.setState({ fields }, cb);
	}

	/** [Layout] Update LayoutMenu */
	Menu = () => {

		/* Load Menu from Pages/Settings/Menu.jsx */
		return (<Menu {...this.props} page={this.state.page} />);

	}

	/** [Init] HTTP-Request API */
	componentDidMountExtends = () => {

		this.setStateFields();
		//
	}

	componentDidUpdate = () => {

		/* */
		if (this.state.datalist === null) {

			/* */
			let tab = this.state.activeTab;
			let page = this.state.pagination.page;
			let take = this.state.pagination.take;
			let skip = (page - 1) * take;

			/* */
			let data = {
				"tenant_id": null,
				"status": this.state.tabs[tab]['status'],
				"search": this.state.search,
				"skip": skip,
				"take": take,
			}

			/* */
			Service.getCounterPartyList(data).then((response) => {

				/* */
				let { datalist, pagination } = this.state;

				/* */
				datalist = response.records;

				/* */
				pagination.total = response.total;

				/* */
				this.setState({ datalist, pagination });

			}).catch((error) => {
				console.log(error.response);
			});

		}

	}

	/** [Render] */
	render() {

		/* Search Function */
		let { t } = this.props;

		/* */
		const { Main, CsvFileUpload } = this;

		/* */
		const searchAttr = {
			"placeholder": t('common:settings.counter-party.search'),
			"onKeyPress": (e) => {
				if (e.code === "Enter") {

					/* */
					let { search, datalist } = this.state;

					/* */
					search = e.target.value;
					datalist = null;

					/* */
					this.setState({ search, datalist });

					// console.log("enter", e.target.value);

				}
			},
		}

		/* */
		return (
			<Main>

				<div id="page-settings-guest" className="page-settings">

					{/* FilterTabs */}
					<div className="lz-flex">

						<div className="lz-flex-1">
							<div className="lz-m-10 lz-mb-0">
								<Button
									key="list-download"
									variant="outlined"
									size='small'
									disabled = { !this.state.datalist }
									startIcon={<UploadIcon />}
									onClick={(ev) => {
									CsvFileUpload();
								}} >
									<span className="label">{t('common:settings.general.list-upload')}</span>
								</Button>
							</div>
						</div>

						<div className="lz-flex-0">

							<div className="lz-m-10 lz-mb-0">
								<this.FilterTabs />
							</div>

						</div>

					</div>

					{/* SearchForm & Paginate */}
					<div className="lz-flex stickyHeader">

						<div className="lz-flex-1">

							<div className="lz-m-10">

								<div className="lz-flex lz-flex-column-sp">

									{/* Add-Guest Button */}
					
									<Button
										sx={{ borderRadius: 19 }}
										title="Action"
										variant="contained"
                    className='add-button'
										startIcon={<AddIcon />}
										disabled = { !this.state.datalist }
										onClick={() => { this.openCounterPartyFormDialog() }}>
										<span>{t('common:settings.counter-party.add-counter-party')}</span>
									</Button>


									{/* Search-TextBox */}
									<div className="input-textbox">
										<input type="search" {...searchAttr} />
									</div>

								</div>

							</div>

						</div>

						<div className="ki-flex-0">
							<div className="lz-m-10">
								<this.Pagination />
							</div>
						</div>

					</div>

					{/* DataTable */}
					<div className="">
						<this.IndexTable />
					</div>

					{/* BottomPagination */}
					<div className="lz-flex" hidden>

						<div className="lz-flex-1"></div>

						<div className="lz-flex-0">
							<div className="lz-m-10">
								<this.Pagination />
							</div>
						</div>

					</div>

				</div>

			</Main>
		);

	}

	/** */
	CsvFileUpload = (validateMessage = null) => {

		/* Prepare Modal */
		let { modal } = this.state;

		/* Translate function */
		let { t } = this.props;

		/* Show Modal */
		modal.props = {
			"show": true,
			"dialogClassName": "counter-party-csv-dialog",
			"centered": true,
		}

		/* */
		modal.title = "common:settings.general.csv-upload";

		let ValidateMessage = () => {
			if (validateMessage) {
				let messageArr = [];
				let messageKeys = Object.keys(validateMessage);
				let messageKey = messageKeys[0];
				let headerErrKey = messageKeys[1];
				let itemMap = validateMessage[messageKey];

				if (validateMessage[headerErrKey] !== '') {
					messageArr.push(
						<div key={headerErrKey}>
							<span>
								{t(`common:${validateMessage[headerErrKey]}`)}
							</span>
						</div>
					);
				} else {
					for (let [itemKey, itemLines] of Object.entries(itemMap)) {
						if (itemLines.length !== 0) {
							messageArr.push(
								<div key={itemKey}>
									<span>
										{t(`common:${messageKey}`, { item_name: t(`common:${itemKey}`), line: itemLines.join(',') })}
									</span>
								</div>
							);
						}
					}
				}
				return messageArr;
			} else {
				return null;
			}
		}

		/* Update body */
		modal.body = () => {
			return (
				<div>
					{/* */}
					<div className="">
						<Form.Group className="">
							<Form.Label>{t("common:settings.general.csv-file-select")}</Form.Label>
							<Form.Control type="file" id="csv-upload" name="upload" accept=".csv" />
						</Form.Group>
					</div>
					<div>

						<ValidateMessage />
					</div>
				</div>
			);
		}

		modal.form = {};
		modal.close = true;

		/* Add SubmitButton */
		modal.action = [(
			<Button key="ok" sx={{ml:1}} variant="contained" onClick={(ev) => {
				this.CounterPartyCsvFileUpload();
			}}>
				<span>{t("common:general.ok")}</span>
			</Button>
		)];

		/* */
		this.setState({ modal });
	}

	/** [Element] */
	FilterTabs = () => {

		/* Translate Function */
		let { t } = this.props;

		/* */
		let tabs = [];

		/* */
		for (let i = 0; i < this.state.tabs.length; i++) {

			/* */
			let c = "select-tab-item";

			/* */
			if (i === this.state.activeTab) {
				c = "select-tab-item active";
			}

			/* */
			let clickEvent = () => {

				/* */
				let { activeTab, pagination, datalist } = this.state;

				/* */
				activeTab = i
				pagination.page = 1;
				datalist = null;

				/* */
				this.setState({ activeTab, pagination, datalist });

			};

			/* */
			tabs.push(
				<div key={i} className={c} onClick={clickEvent}>
					<span>{t(this.state.tabs[i]['label'])}</span>
				</div>
			);
		}

		/* */
		return (
			<div className="select-tab">{tabs}</div>
		);

	}

	/** [Element] */
	Pagination = () => {

		let { pagination, datalist } = this.state;

		/* clear */
		datalist = null;

		/* Calculate MaxPage */
		let max = Math.ceil(pagination.total / pagination.take);

		/* */
		let prev = {
			"type": "button",
			"className": "paginate-prev",
			"onClick": () => {
				pagination.page = (pagination.page - 1);
				this.setState({ pagination, datalist });
			},
		};

		/* */
		let next = {
			"type": "button",
			"className": "paginate-next",
			"onClick": () => {
				pagination.page = (pagination.page + 1);
				this.setState({ pagination, datalist });
			},
		}

		/* */
		if (pagination.page === 1) {
			prev["disabled"] =true;
		}

		/* */
		if (pagination.page === max || max === 0) {
			next["disabled"] =true;
		}
		
		/* */
		let start_list = 0;
		let last_list = 0;
		if(pagination.total > 0){
			start_list = 1 + ( ( pagination.page - 1 ) * pagination.take );
			if(pagination.page === max){
				last_list = pagination.total
			}else{
				last_list = start_list + (pagination.take -1);
			}
		}

		/* */
		return (
			<div className="paginate">
				<div className="paginate-info">
					<span>{start_list}</span>
					<span>{last_list}</span>
					<span>{pagination.total}</span>
				</div>
				<div className="paginate-nav">
					<IconButton key="prev" {...prev}>
						<ChevronLeftIcon />
					</IconButton>
					<IconButton key="next" {...next}>
						<ChevronRightIcon />
					</IconButton>
				</div>
			</div>
		);

	}

	/** [Element] */
	IndexTable = () => {

		/* Search Function */
		let { t } = this.props;

		/* */
		let { datalist } = this.state;

		/* */
		if (datalist === null) {
			return <LoadingList />;
		}

		/* Prepare TableRows */
		let rows = [];

		/* Update TableRows with data from state */
		for (let i = 0; i < datalist.length; i++) {

			/* */
			let counterParty = datalist[i];

			/* */
			rows.push(
				<tr className="item document-row-list clickable" key={i} onClick={() => { this.openCounterPartyFormDialog(counterParty) }}>
					<td>
						<div>{counterParty['counter_party_name']}</div>
					</td>
				</tr>
			)

		}

		/* */
		if (rows.length < 1) {
			rows.push(
				<tr key="empty">
					<td colSpan="4">
						<div className="lz-txt-c lz-p-40">
							<span>{t('common:settings.counter-party.empty')}</span>
						</div>
					</td>
				</tr>
			);
		}

		/* */
		return (
			<table id="datalist" className="counter-party-setting-list">
				<thead>
					<StateFragment state={shedOnScroll}>
						{(s) => (
							<tr className={`${s.get({noproxy: true}) && "shed"}`}>
								<th>
									<div>{t('common:settings.counter-party.counter_party_name')}</div>
								</th>
							</tr>
						)}
					</StateFragment>

				</thead>
				<tbody>
					{rows}
				</tbody>
			</table>
		)

	}

	/** [Action] */
	openCounterPartyFormDialog = (counterParty = null) => {
		// let { t } = this.props;
		let { modal } = this.state;

		modal = {
			props: {
				show: true,
				dialogClassName: "counter-party-dialog",
				centered: true,
			},
			title: "common:settings.counter-party.add-counter-party",
			body: this.CounterPartyForm,
			action: this.CounterPartyModalAction,
			form: {
				id: "settings-counter-party-form",
				onSubmit: (ev) => { this.formSubmitHandler(ev, null, this.formSubmitCallback) },
				noValidate: true,
			},
		};

		/* Default structure */
		let { keyFields } = this.state;
		let formData = {};
		formData["counter_party_id"] = null;
		formData["counter_party_name"] = null;
		formData["status"] = 0;

		for (let key in keyFields) {
			formData[key] = "";
		}

		if (counterParty != null) {
			formData["counter_party_id"] = counterParty["counter_party_id"];
			formData["counter_party_name"] = counterParty["counter_party_name"];
			formData["status"] = counterParty["status"];

			for (let key in keyFields) {
				formData[key] = counterParty[key];
			}
			modal.title = "common:settings.counter-party.update-counter-party";
		}

		this.setState({ formData }, this.setStateFields(() => {
			this.setState({ modal, formValidate: false });
		}));

	}

	formSubmitCallback = (ev) => {
		this.submitCounterPartyForm();
	}

	CounterPartyModalAction = () => {
		let { t } = this.props;

		return [
			(
				<Button key="ok" type="submit"sx={{ml:1}} variant="contained" >
					<span>{t("common:general.ok")}</span>
				</Button>
			)
		];
	}

	CounterPartyModalActionTryAgain = () => {
		let { t } = this.props;

		return [
			(
				<Button key="ok" sx={{ml:1}} variant="contained" onClick={(ev) => {
					let { modal } = this.state;
					modal.body = this.CounterPartyForm;
					modal.action = this.CounterPartyModalAction;
					this.setState({ modal });
				}}>
					<span>{t("common:general.try-again")}</span>
				</Button>
			)
		];
	}

	CounterPartyForm = () => {
		let { t } = this.props;
		let { formData } = this.state;
		let { FieldControl } = this;

		return (
			<div className="form-container">
				<Form.Control type="hidden" name="counter_party_id" />

				<Form.Row>
					<FieldControl name="counter_party_name" type="counter_party_name" required validate="true" />
				</Form.Row>

				<Form.Row>
					<Form.Group className="status" controlId="status">
						<div className="status">
							<Form.Check
								type="switch"
								name='status'
								value='status'
								onChange={this.onChangeHandler}
								label={t('common:settings.guest.active')}
								checked={[true, "true", 0, "0"].includes(formData["status"])}
							/>
						</div>
					</Form.Group>
				</Form.Row>

			</div>
		);
	}

	/** [Event] */
	submitCounterPartyForm = () => {
		let { t } = this.props;
		let { formData, datalist, modal } = this.state;

		/* Preprae RequestBody */
		let body = {
			"counter_party_id": formData["counter_party_id"],
			"counter_party_name": formData["counter_party_name"],
			"status": '1',
		};

		/* */
		if ([true, "true", 0, "0"].includes(formData["status"])) {
			body["status"] = '0';
		}

		modal.body = Loading;
		modal.action = null;
		modal.close = false;

		/* */
		this.setState({ modal }, () => {
			let { modal } = this.state;
			Service.setCounterParty(body).then(resp => {
				datalist = null;
				modal.props.show = false;
				// modal.close = true;
				this.setState({ datalist, modal });
			}).catch(err => {
				if (err?.code === 'ECONNABORTED' || (err?.response?.status === 504 && err?.response?.data?.message === 'Endpoint request timed out')) {
					err.response.data.message = t('message.error.request.timed_out',  `サーバーが混雑しております。\n しばらく待ってアクセスしてください。`) ;
					// return;
				} 
				modal.body = () => {
					return (
						<div className="lz-m-20">
							<div className="lz-m-20">
								{t(`common:${err.response.data.message}`)}
							</div>
							<div className="lz-m-20">
								{err.response.data.detail}
							</div>
						</div>
					);
				};

				modal.action = this.CounterPartyModalActionTryAgain;
				modal.close = true;

				this.setState({ modal });
			});
		});

	}

	/* */
	CounterPartyCsvFileUpload = async () => {
		/* */
		let { modal, datalist } = this.state;
		/* */
		let form = document.querySelector("#csv-upload");

		/* File required */
		if (form['files']['length'] === 0) {
			return false;
		}

		/* */
		modal.body = () => {
			return (
				<div className="lz-m-20">
					<Loading />
				</div>
			);
		};

		modal.action = [];
		modal.close = false;

		/* */
		this.setState({ modal });
		/* */
		let reader = new FileReader();

		new Promise((resolve) => {
			/* */
			reader.onload = (event) => {
				resolve((event.target.result).split(',')[1]);
			};

			/* */
			reader.readAsDataURL(form.files[0]);

		}).then((result) => {

			/* */
			let upload_data = { "upload_file": result };
			/* */
			Service.UploadCsvCounterParty(upload_data).then((response) => {

				if ('error' in response) {
					/* */
					this.CsvFileUpload(response.error);
				} else {
					/* */
					datalist = null;
					/* */
					modal.props = { "show": false };
					/* */
					this.setState({ modal, datalist });
				}

			}).catch((error) => {
				/* */
				console.log("error", error);
				modal.close = true;
				/* */
				this.setState({ modal });
			});
		});
	}
}

export default withTranslation()(CounterParty);
