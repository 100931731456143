import styles from "./batch-download-detail.module.css";
import React from "react";
import {Alert, Divider, IconButton, Snackbar, Tooltip, Typography} from "@mui/material";
import {useHookstate} from "@hookstate/core";
import {notificationAlertState, userInfoState} from "../../../drive-feature/data-access/state";
import {ArrowBack} from "@mui/icons-material";
import BaseCard from "../../../Components/base-card/base-card";
import {LoadingButton} from "@mui/lab";
import {useTranslation} from "react-i18next";
import formatDateIntlApi from "../../../Utils/format_date_intl";
import Service from "../../../Service";
import DownloadIcon from "@mui/icons-material/Download";
import downloadFileUrl from "../../../Utils/download-file";

/**
 * @param {any} props
 */
export function BatchDownloadDetail(
    props,
) {
    const {
        showDetail,
        batchDownloadDetail,
        main
    } = props;

    const { t } = useTranslation("common");

    const showAlert = useHookstate(notificationAlertState);

    const loadingBtnDownload = useHookstate(false);

    const handleClose = (/** @type {any} */ event, /** @type {string} */ reason) => {
        if (reason === "clickaway") {
            return;
        }

        showAlert.show.set(false);
    }

    function handleDownload() {
        loadingBtnDownload.set(true)
        Service.getDownloadBatchUrl({
            batch_id: batchDownloadDetail.batch_id
        }).then(async (resp) => {
            if (resp.ok) {
                // let aLink = document.createElement("a");
                // aLink.href = resp.payload.download_url;
                // aLink.click();
                downloadFileUrl(resp.payload.download_url, 'zip').finally(()=>{
                    main.setState((prevState) => ({
                        batchDownloadDetail: {
                            ...prevState.batchDownloadDetail,
                            is_downloaded: 1,
                        },
                        datalist: prevState.datalist.map((item) => {
                            if (item.batch_id === batchDownloadDetail.batch_id) {
                                return {...item, is_downloaded: 1}
                            }
                            return {...item}
                        }),
                    }))

                    showAlert.set({
                        show: true,
                        type: 'success',
                        message: t("common:settings.batch-download.ready-to-download"),
                    });
                });

            }
        }).catch((error) => {
            showAlert.set({
                show: true,
                type: 'error',
                message: error.response?.data?.message,
            });
        }).finally(() => {
            loadingBtnDownload.set(false)
        })
    }

    const convertDate = (/** @type {string | number | Date} */ date) => {
        return formatDateIntlApi(new Date(date), {
            day: "2-digit",
            hour: "2-digit",
            hourCycle: "h23",
            minute: "2-digit",
            month: "2-digit",
            second: "2-digit",
            timeZone: "UTC",
            year: "numeric",
        }) ;
    }

    const downloadRange = () => {
        let startDate = convertDate(batchDownloadDetail['start_date']),
            endDate = convertDate(batchDownloadDetail['end_date']);
        return `${startDate.year}.${startDate.month}.${startDate.day} - ${endDate.year}.${endDate.month}.${endDate.day}`;
    }

    const downloadDate = () => {
        let date = convertDate(batchDownloadDetail['download_date']);
        return `${date.year}.${date.month}.${date.day}`
    }

    if (!showDetail)
        return (
            <>
                <Snackbar
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    open={showAlert.show.get()}
                    autoHideDuration={3500}
                    onClose={handleClose}
                >
                    <Alert severity={showAlert.type.get()}>
                        {showAlert.message.get()}
                    </Alert>
                </Snackbar>
            </>
        );

    return (
        <div className="p-3">
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                open={showAlert.show.get()}
                autoHideDuration={3500}
                onClose={handleClose}
            >
                <Alert
                    severity={showAlert.type.get()}
                >
                    {showAlert.message.get()}
                </Alert>
            </Snackbar>
            <div className="go_back_wrapper">
                <Tooltip
                    placement="top"
                    disableInteractive
                    title={t("common:guest.detail.pages.go_back", "ゲスト")}
                >
                    <IconButton
                        onClick={(e) => {
                            main.setState({
                                batchDownloadDetail: null,
                                showDetail: false,
                            });
                        }}
                    >
                        <ArrowBack className="go_back_icon" />
                    </IconButton>
                </Tooltip>
                <span>{t("common:settings.batch-download.batch-download-list")}</span>
            </div>
            {/* <Divider /> */}
            <BaseCard
                className={styles["base_tenant_card"]}
                content={
                    <div className="base_content_grid">
                        <div className={`${styles["button_action"]} button-right-shoulder`}>
                            <LoadingButton
                                loading={loadingBtnDownload.get()}
                                color="secondary"
                                size="small"
                                aria-label="SaveIcon"
                                key="ec-save"
                                variant="contained"
                                sx={{ ml: 1 }}
                                onClick={() => handleDownload()}
                                disabled={batchDownloadDetail.status !== 3 || batchDownloadDetail.is_downloaded === 1 || batchDownloadDetail.user_id !== userInfoState.value?.user_id}
                                startIcon={<DownloadIcon/>}
                            >
                                <span>{t("common:settings.batch-download.download")}</span>
                            </LoadingButton>
                        </div>
                        <div className="field_wrapper">
                            <Typography
                                color={"#062b60"}
                                className={styles["form_text"]}
                                variant="subtitle2"
                                component="p"
                            >
                                {t(`common:settings.batch-download.user-name`)}
                            </Typography>
                            <div className="col-span-4">
                                <Typography
                                    color={"#062b60"}
                                    className={styles["form_text"]}
                                    variant="subtitle2"
                                    component="p"
                                >
                                    {batchDownloadDetail['user_family_name']} {batchDownloadDetail['user_first_name']}
                                </Typography>
                            </div>
                        </div>
                        <div className="field_wrapper">
                            <Typography
                                color={"#062b60"}
                                className={styles["form_text"]}
                                variant="subtitle2"
                                component="p"
                            >
                                {t(`common:settings.batch-download.download-date`)}
                            </Typography>
                            <div className="col-span-4">
                                <Typography
                                    color={"#062b60"}
                                    className={styles["form_text"]}
                                    variant="subtitle2"
                                    component="p"
                                >
                                    {downloadDate()}
                                </Typography>
                            </div>
                        </div>
                        <div className="field_wrapper">
                            <Typography
                                color={"#062b60"}
                                className={styles["form_text"]}
                                variant="subtitle2"
                                component="p"
                            >
                                {t(`common:settings.batch-download.download-range`)}
                            </Typography>
                            <div className="col-span-4">
                                <Typography
                                    color={"#062b60"}
                                    className={styles["form_text"]}
                                    variant="subtitle2"
                                    component="p"
                                >

                                    {downloadRange()}
                                </Typography>
                            </div>
                        </div>
                        <div className="field_wrapper">
                            <Typography
                                color={"#062b60"}
                                className={styles["form_text"]}
                                variant="subtitle2"
                                component="p"
                            >
                                {t(`common:settings.batch-download.download-status`)}
                            </Typography>
                            <div className="col-span-4">
                                {main.downloadStatus(batchDownloadDetail['status'], batchDownloadDetail['is_downloaded'])}
                            </div>
                        </div>
                    </div>
                }
            />
        </div>
    );

}

BatchDownloadDetail.defaultProps = {
    //
    isDraggable: false,
};

export default BatchDownloadDetail;