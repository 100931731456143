// @ts-nocheck
import axios from "axios";
import i18next from "i18next";
import { API_ROUTES, driveApiCall, ec2DriveApiCall } from "../config/api.config";
import { driveLoading, notificationAlertState, notificationInfoState } from "./state";
import { closeSnackbar, enqueueSnackbar } from "notistack";

export function getFoldersTree(data, signal) {
  return driveApiCall
    .get(API_ROUTES.folders.treeFolders, {
      params: data
        ? {
          ...data,
        }
        : {},
      signal,
    })
    .catch((e) => {
      console.log(e);
    })
    .finally(() => driveLoading.set(false));
}

export function getFoldersDetail(
  drive_folder_id,
  { non_files, order_by, page_number, page_size, search,
    offset_folder_number,
    offset_file_number, }
) {
  return driveApiCall
    .get(API_ROUTES.folders.foldersDetail, {
      params: {
        non_files,
        drive_folder_id: drive_folder_id || 'null',
        order_by,
        page_number,
        page_size,
        offset_folder_number,
        offset_file_number,
        search,
      },
    })
    .catch((e) => {
      console.log(e);
    });
}

export function createFolder({
  title,
  name,
  size,
  drive_folder_id,
  type,
  status,
}) {
  return driveApiCall.post(API_ROUTES.folders.createFolder, {
    title,
    name,
    size,
    parent_folder_id: drive_folder_id,
    type,
    status,
  }).catch(e => {
    if (e?.response?.status === 400 && e?.response?.data?.message_code === 'message.shared-folder.exist-folder-name') {
      notificationAlertState.set({
        show: true,
        type: "error",
        message: `${i18next.t(
          "common:message.shared-folder.exist-folder-name",
          e?.response?.data?.message
        )} `,
      });
      return;
    }
    notificationAlertState.set({
      show: true,
      type: "error",
      message: `${i18next.t(
        "common:drive.message.error.create_folder",
        "Create folder error please try again!"
      )} ${e?.response?.data?.message || e
        }`,
    });
  });
}

export function deleteFolder(folder_ids) {
  return driveApiCall.patch(API_ROUTES.folders.deleteFolder, {
    folder_ids,
  }).catch(e => {
    notificationAlertState.set({
      show: true,
      type: "error",
      message: `${i18next.t(
        "common:drive.message.error.delete_folder",
        "Delete folder error please try again!"
      )} ${e?.response?.data?.message || e
        }`,
    });
  });
}

export function moveFolder({ folder_dest_id, folder_ids }) {
  return driveApiCall.patch(API_ROUTES.folders.moveFolder, {
    folder_dest_id,
    folder_ids,
  }).then(r => {
    if (r.data?.payload?.changed_folder_name?.length > 0) {
      r.data.payload.changed_folder_name.forEach((a) => {
        enqueueSnackbar(
          `同じ名前「${a.old}」のファイルがこの場所にすでに存在します。\n ファイル名は${a.new}に変更されます。`,
          {
            variant: "warning",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center",
            },
            autoHideDuration: 4000,
            style: {
              width: 'calc(100vw - 150px)  !important'
            },
            // onClose: closeSnackbar,
          }
        );
      });
      // notificationInfoState.message.set(
      //   <div>
      //     {r.data.payload.changed_folder_name.map((a) => {
      //       return (
      //         <div>
      //           {`同じ名前「${a.old}」のファイルがこの場所にすでに存在します。\n ファイル名は${a.new}に変更されます。`}
      //         </div>
      //       );
      //     })}
      //   </div>
      // );
      // notificationInfoState.show.set(true);
    }
    return r;
  }).catch(e => {
    notificationAlertState.set({
      show: true,
      type: "error",
      message: `${i18next.t(
        "common:drive.message.error.move_folder",
        "Move folder error please try again!"
      )} ${e?.response?.data?.message || e
        }`,
    });
  });
}
export function copyFolder({ drive_folder_id, parent_id }) {
  return driveApiCall.post(API_ROUTES.folders.copyFolder, null, {
    params: {
      drive_folder_id,
      parent_id,
    },
  }).then(r => {
    if (r.data?.payload?.changed_folder_name?.length > 0) {
      r.data.payload.changed_folder_name.forEach((a) => {
        enqueueSnackbar(
          `同じ名前「${a.old}」のファイルがこの場所にすでに存在します。\n ファイル名は${a.new}に変更されます。`,
          {
            variant: "warning",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center",
            },
            autoHideDuration: 4000,
            style: {
              width: 'calc(100vw - 150px)  !important'
            },
            // onClose: closeSnackbar,
          }
        );
      });
      // notificationInfoState.message.set(
      //   <div>
      //     {r.data.payload.changed_folder_name.map((a) => {
      //       return (
      //         <div>
      //           {`同じ名前「${a.old}」のファイルがこの場所にすでに存在します。\n ファイル名は${a.new}に変更されます。`}
      //         </div>
      //       );
      //     })}
      //   </div>
      // );
      // notificationInfoState.show.set(true);
    }
    return r;
  }).catch(e => {

    notificationAlertState.set({
      show: true,
      type: "error",
      message: `${i18next.t(
        "common:drive.message.error.copy_folder",
        "Copy folder error please try again!"
      )} ${e?.response?.data?.message || e
        }`,
    });
  });
}

// export function copyCompleteFolder({ document_id_list, parent_id }) {
//   return driveApiCall.post(API_ROUTES.folders.copyCompleteFolder, {
//     document_id_list,
//     parent_id,
//   }).then(r => {
//     if (r.data?.payload?.changed_folder_name?.length > 0) {
//       notificationInfoState.message.set(
//         <div>
//           {r.data.payload.changed_folder_name.map((a) => {
//             return (
//               <div>
//                 {`同じ名前「${a.old}」のファイルがこの場所にすでに存在します。\n ファイル名は${a.new}に変更されます。`}
//               </div>
//             );
//           })}
//         </div>
//       );
//       notificationInfoState.show.set(true);
//     }
//     return r;
//   }).catch(e => {
//     notificationAlertState.set({
//       show: true,
//       type: "error",
//       message: `${i18next.t(
//         parent_id === "trash"
//           ? "common:drive.message.error.copy_to_trash"
//           : "common:drive.message.error.copy_folder",
//         parent_id === "trash"
//           ? "Cannot copy to trash folder !"
//           : `Copy folder error! ${e?.response?.data?.message}`
//       )}`,
//     });
//   });
// }
export function copyFiles({ drive_file_id_list, drive_folder_id }) {
  return driveApiCall.post(API_ROUTES.files.copyFiles, {
    drive_file_id_list,
    drive_folder_id,
  }).then(r => {
    if (r.data?.payload?.changed_folder_name?.length > 0) {
      r.data.payload.changed_folder_name.forEach((a) => {
        enqueueSnackbar(
          `同じ名前「${a.old}」のファイルがこの場所にすでに存在します。\n ファイル名は${a.new}に変更されます。`,
          {
            variant: "warning",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center",
            },
            autoHideDuration: 4000,
            style: {
              width: 'calc(100vw - 150px)  !important'
            },
            // onClose: closeSnackbar,
          }
        );
      });
      // notificationInfoState.message.set(
      //   <div>
      //     {r.data.payload.changed_folder_name.map((a) => {
      //       return (
      //         <div>
      //           {`同じ名前「${a.old}」のファイルがこの場所にすでに存在します。\n ファイル名は${a.new}に変更されます。`}
      //         </div>
      //       );
      //     })}
      //   </div>
      // );
      // notificationInfoState.show.set(true);
    }
    return r;
  }).catch(e => {

    notificationAlertState.set({
      show: true,
      type: "error",
      message: `${i18next.t(
        "common:drive.message.error.copy_file",
        "Copy file error please try again!"
      )} ${e?.response?.data?.message || e
        }`,
    });
  });
}

export function moveFile({ drive_folder_id, drive_file_id }) {
  return driveApiCall.patch(API_ROUTES.files.moveFile, null, {
    params: {
      drive_folder_id,
      drive_file_id,
    },
  }).then(r => {
    if (r.data?.payload?.changed_folder_name?.length > 0) {
      r.data.payload.changed_folder_name.forEach((a) => {
        enqueueSnackbar(
          `同じ名前「${a.old}」のファイルがこの場所にすでに存在します。\n ファイル名は${a.new}に変更されます。`,
          {
            variant: "warning",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center",
            },
            autoHideDuration: 4000,
            style: {
              width: 'calc(100vw - 150px)  !important'
            },
            // onClose: closeSnackbar,
          }
        );
      });
      // notificationInfoState.message.set(
      //   <div>
      //     {r.data.payload.changed_folder_name.map((a) => {
      //       return (
      //         <div>
      //           {`同じ名前「${a.old}」のファイルがこの場所にすでに存在します。\n ファイル名は${a.new}に変更されます。`}
      //         </div>
      //       );
      //     })}
      //   </div>
      // );
      // notificationInfoState.show.set(true);
    }
    return r;
  }).catch(e => {

    notificationAlertState.set({
      show: true,
      type: "error",
      message: `${i18next.t(
        "common:drive.message.error.move_file",
        "Move file error please try again!"
      )} ${e?.response?.data?.message || e
        }`,
    });
  });
}

export function createFile({
  title,
  name,
  url,
  size,
  drive_folder_id,
  extension,
  type,
  status,
}) {
  return driveApiCall.post(API_ROUTES.files.createFile, {
    title,
    name,
    url,
    size,
    drive_folder_id,
    type,
    extension,
    status,
  }).then(r => {
    if (r.data?.payload?.changed_file_name?.new) {
      [r.data.payload.changed_file_name].forEach((a) => {
        enqueueSnackbar(
          `同じ名前「${a.old}」のファイルがこの場所にすでに存在します。\n ファイル名は${a.new}に変更されます。`,
          {
            variant: "warning",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center",
            },
            autoHideDuration: 4000,
            style: {
              width: 'calc(100vw - 150px)  !important'
            },
            // onClose: closeSnackbar,
          }
        );
      });
      // notificationInfoState.message.set(
      //   <div>
      //     {[r.data.payload.changed_file_name].map((a) => {
      //       return (
      //         <div>
      //           {`同じ名前「${a.old}」のファイルがこの場所にすでに存在します。\n ファイル名は${a.new}に変更されます。`}
      //         </div>
      //       );
      //     })}
      //   </div>
      // );
      // notificationInfoState.show.set(true);
    }
    return r;
  }).catch(e => {
    if (e?.response?.status === 400 && e?.response?.data?.message_code === 'message.shared-folder.exist-file-name') {
      notificationAlertState.set({
        show: true,
        type: "error",
        message: `${i18next.t(
          "common:message.shared-folder.exist-file-name",
          e?.response?.data?.message
        )}`,
      });
      return;
    }
    notificationAlertState.set({
      show: true,
      type: "error",
      message: `${i18next.t(
        "common:drive.message.error.create_file",
        "Create file error please try again!"
      )} ${e?.response?.data?.message || e
        }`,
    });
  });
}

export function deleteFile(file_ids) {
  return driveApiCall.patch(API_ROUTES.files.deleteFile, {
    file_ids,
  }).catch(e => {
    notificationAlertState.set({
      show: true,
      type: "error",
      message: `${i18next.t(
        "common:drive.message.error.delete_file",
        "Delete file error please try again!"
      )} ${e?.response?.data?.message || e
        }`,
    });
  });
}
export function generateUploadPresignedUrl(file_type, file_tail) {
  return driveApiCall.get(API_ROUTES.s3.uploadPresigned, {
    params: {
      file_type,
      file_tail,
    },
  });
}

export function generatDownloadPresignedUrl(file_keys) {
  return driveApiCall.post(API_ROUTES.s3.downloadPresigned, {
    file_keys
  });
}

export function generatePresignedUrlsForPreview(keys) {
  return driveApiCall.post(API_ROUTES.s3.presignedUrlsForPreview, {
    keys,
  });
}

export function uploadToS3(url, file, file_type, progessCb) {
  return axios.put(url, file, {
    headers: {
      "Content-Type": file_type,
    },
    onUploadProgress: progessCb,
  });
}

export function generatePresignedAndUploadToS3(file, progessCb) {
  return new Promise((resolve, reject) => {
    generateUploadPresignedUrl(file.type, file.name.split(".").pop())
      .then(async (r) => {
        const upload = await uploadToS3(
          r.data.payload,
          file,
          file.type,
          progessCb
        ).catch((e) => {
          reject(e);
        });
        if (upload)
          resolve({
            upload,
            url: r.data.payload,
          });
      })
      .catch((e) => {
        reject(e);
      });
  });
}
export function updateDocument(data) {
  const { document_id, memo, transaction_date, amount,
    currency,
    counterparty_name,
    name,
    title, } = data;
  return driveApiCall.patch(
    API_ROUTES.updateDocument,
    {
      memo,
      // transaction_date,
      amount: amount || 0,
      // name,
      // title,
      // currency,
      // counterparty_name
    },
    {
      params: {
        document_id,
      },
    }
  ).catch(e => {
    if (e?.response?.status === 400 && (e?.response?.data?.message_code === 'message.shared-folder.exist-folder-name'
      || e?.response?.data?.message_code === 'message.shared-folder.exist-file-name')) {
      notificationAlertState.set({
        show: true,
        type: "error",
        message: `${i18next.t(
          `common:${e?.response?.data?.message_code}`,
          e?.response?.data?.message
        )}`,
      });
      // return e;
    }
    return e;
  });
}
export function updateFileOrFolder(data) {
  const { type, name, title, id, memo, transaction_date, amount,
    currency,
    counterparty_name } = data;
  return driveApiCall.patch(
    API_ROUTES.updateFileOrFolder,
    {
      name,
      title,
      memo,
      transaction_date,
      amount: amount || 0,
      currency,
      counterparty_name
    },
    {
      params: {
        id,
        type,
      },
    }
  ).catch(e => {
    if (e?.response?.status === 400 && (e?.response?.data?.message_code === 'message.shared-folder.exist-folder-name'
      || e?.response?.data?.message_code === 'message.shared-folder.exist-file-name')) {
      notificationAlertState.set({
        show: true,
        type: "error",
        message: `${i18next.t(
          `common:${e?.response?.data?.message_code}`,
          e?.response?.data?.message
        )}`,
      });
      return;
      // return e;
    }
    return e;
  });
}
/**
 * data: array flatten tree folder
 * parent_id: parent_id of folder drag
 */
export function updateUploadedFolder(data, parent_id) {
  return driveApiCall.post(
    API_ROUTES.folders.updateUploadedFolder,
    { data },
    {
      params: {
        parent_id,
      },
    }
  ).then(r => {
    if (r.data?.payload?.changed_folder_name?.length > 0) {
      r.data.payload.changed_folder_name.forEach((a) => {
        enqueueSnackbar(
          `同じ名前「${a.old}」のファイルがこの場所にすでに存在します。\n ファイル名は${a.new}に変更されます。`,
          {
            variant: "warning",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center",
            },
            autoHideDuration: 4000,
            style: {
              width: 'calc(100vw - 150px)  !important'
            },
            // onClose: closeSnackbar,
          }
        );
      });
    }
  });
}

/**
 * files: array flatten tree folder
 */
export function generateMultiplePresignedUpload(files) {
  return driveApiCall.post(API_ROUTES.s3.generateMultiplePresignedPut, {
    files,
  });
}

export function downloadFolderAndFiles({ drive_file_id_list,
  drive_folder_id_list }) {
  return driveApiCall.post(API_ROUTES.exportFolder, {
    drive_file_id_list,
    drive_folder_id_list
  })
}
export function downloadItems({
  drive_file_id_list,
  drive_folder_id_list,
  document_id_list
}) {
  return ec2DriveApiCall.post(API_ROUTES.downloadItem, {
    drive_file_id_list,
    drive_folder_id_list,
    document_id_list
  });
}

export function moveToTrash({ drive_file_id_list,
  drive_folder_id_list, document_id_list }) {
  return driveApiCall.patch(API_ROUTES.trash.move, {
    drive_file_id_list,
    drive_folder_id_list,
    document_id_list
  })
}

export function deleteFromTrash({ drive_file_id_list,
  drive_folder_id_list, document_id_list }) {
  return driveApiCall.patch(API_ROUTES.trash.delete, {
    drive_file_id_list,
    drive_folder_id_list,
    document_id_list
  })
}

export function recycleTrash({ drive_file_id_list,
  drive_folder_id_list, document_id_list, overwrite }) {
  return driveApiCall.patch(API_ROUTES.trash.recycle, {
    drive_file_id_list,
    drive_folder_id_list,
    document_id_list,
    overwrite
  }).then(r => {
    if (r.data?.payload?.changed_folder_name?.length > 0) {
      r.data.payload.changed_folder_name.forEach((a) => {
        enqueueSnackbar(
          `同じ名前「${a.old}」のファイルがこの場所にすでに存在します。\n ファイル名は${a.new}に変更されます。`,
          {
            variant: "warning",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center",
            },
            autoHideDuration: 4000,
            style: {
              width: 'calc(100vw - 150px)  !important'
            },
            // onClose: closeSnackbar,
          }
        );
      });
      // notificationInfoState.message.set(
      //   <div>
      //     {r.data.payload.changed_folder_name.map((a) => {
      //       return (
      //         <div>
      //           {`同じ名前「${a.old}」のファイルがこの場所にすでに存在します。\n ファイル名は${a.new}に変更されます。`}
      //         </div>
      //       );
      //     })}
      //   </div>
      // );
      // notificationInfoState.show.set(true);
    }
    return r;
  })
}
export function getTrashDetail(
  { non_files, order_by, page_number, page_size,
    offset_folder_number,
    offset_file_number,
    offset_document_number, search }
) {
  return driveApiCall
    .get(API_ROUTES.trash.list, {
      params: {
        non_files,
        order_by,
        // page_number,

        offset_folder_number,
        offset_file_number,
        offset_document_number,
        page_size,
        search,
      },
    })
    .catch((e) => {
      console.log(e);
    });
}

export function moveItem(data, dest_id) {
  const { document_id_list,
    drive_file_id_list,
    drive_folder_id_list } = data
  return ec2DriveApiCall.post(API_ROUTES.folders.moveItem, {
    document_id_list,
    drive_file_id_list,
    drive_folder_id_list
  }, {
    params: {
      parent_id: dest_id
    }
  }).then(r => {
    if (r.data?.payload?.changed_folder_name?.length > 0) {
      r.data.payload.changed_folder_name.forEach((a) => {
        enqueueSnackbar(
          `同じ名前「${a.old}」のファイルがこの場所にすでに存在します。\n ファイル名は${a.new}に変更されます。`,
          {
            variant: "warning",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center",
            },
            autoHideDuration: 4000,
            style: {
              width: 'calc(100vw - 150px)  !important'
            },
            // onClose: closeSnackbar,
          }
        );
      });
      // notificationInfoState.message.set(
      //   <div>
      //     {r.data.payload.changed_folder_name.map((a) => {
      //       return (
      //         <div>
      //           {`同じ名前「${a.old}」のファイルがこの場所にすでに存在します。\n ファイル名は${a.new}に変更されます。`}
      //         </div>
      //       );
      //     })}
      //   </div>
      // );
      // notificationInfoState.show.set(true);
    }
    return r;
  }).catch(e => {
    notificationAlertState.set({
      show: true,
      type: "error",
      message: `${i18next.t(
        e?.response?.data?.message_code ? e?.response?.data?.message_code : "common:drive.message.error.move_item",
        "Move item error please try again!"
      )} ${e?.response?.data?.message || e
        }`,
    });
    return null;
  })
}


export function copyItem(data, dest_id) {
  const { document_id_list,
    drive_file_id_list,
    drive_folder_id_list } = data
  return ec2DriveApiCall.post(API_ROUTES.folders.copyItem, {
    document_id_list,
    drive_file_id_list,
    drive_folder_id_list
  }, {
    params: {
      parent_id: dest_id
    }
  }).then(r => {
    if (r.data?.payload?.changed_folder_name?.length > 0) {
      r.data.payload.changed_folder_name.forEach((a) => {
        enqueueSnackbar(
          `同じ名前「${a.old}」のファイルがこの場所にすでに存在します。\n ファイル名は${a.new}に変更されます。`,
          {
            variant: "warning",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center",
            },
            autoHideDuration: 4000,
            style: {
              width: 'calc(100vw - 150px)  !important'
            },
            // onClose: closeSnackbar,
          }
        );
      });
      // notificationInfoState.message.set(
      //   <div>
      //     {r.data.payload.changed_folder_name.map((a) => {
      //       return (
      //         <div>
      //           {`同じ名前「${a.old}」のファイルがこの場所にすでに存在します。\n ファイル名は${a.new}に変更されます。`}
      //         </div>
      //       );
      //     })}
      //   </div>
      // );
      // notificationInfoState.show.set(true);
    }
    return r;
  }).catch(e => {
    notificationAlertState.set({
      show: true,
      type: "error",
      message: `${i18next.t(
        "common:drive.message.error.move_item",
        "Move item error please try again!"
      )} ${e?.response?.data?.message || e
        }`,
    });
  })
}

export function searchItemFolder(data) {
  const {
    search,
    parent_id,
    order_by,
    page_size,
    offset_folder_number,
    offset_file_number,
    offset_document_number,
    folder_only,
  } = data
  return driveApiCall.get(API_ROUTES.folders.searchItem, {
    params: {
      search,
      parent_id,
      order_by,
      page_size,
      offset_folder_number,
      offset_file_number,
      offset_document_number,
      folder_only
    }
  })
}

export function getFilesPreview(data) {
  return driveApiCall.get(API_ROUTES.files.getFilesPreview, {
    params: {
      document_id: data?.document_id ?? undefined,
      drive_file_id: data?.drive_file_id ?? undefined,
    },
  });
}
