// @ts-nocheck

function is(t, T) {
    return t?.constructor === T
}

function* find(t, func) {
    switch (true) {
        case is(t, Array):
            for (const child of t)
                yield* find(child, func)
            break
        case is(t, Object):
            if (Boolean(func(t)))
                yield [t]
            else
                for (const path of find(t.children, func))
                    yield [t, ...path]
            break
        default:
            return
    }
}
function findById(t, id, key) {
    for (const path of find(t, node => node[key] === id))
        return path
    return []
}

export function findAncestryById(t, id, key) {
    return findById(t, id, key).slice(0, -1);
}
export function findAncestors(parentId, directory) {
    if (!parentId) return [];

    const parent = directory.find(i => i.id === parentId)?.parent;
    if (!parent) return [];
    return [
        parent,
        ...findAncestors(parent, directory),
    ];
}