// @ts-nocheck
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import TypeIcon from "./type-icon";

export function DriveDragPreview({ monitorProps }) {
  const { t } = useTranslation();
  return (
    <div ref={monitorProps.ref} className={`tree-node root_node drag_preview_root`}>
      <div>
        <TypeIcon
          {...monitorProps.item}
          fileType={monitorProps.item?.fileType || monitorProps.item.extension || "folder"}
        />
      </div>
      <div className={"labelGridItem"}>
        <Typography variant="body2">{t(monitorProps.item.name)}</Typography>
      </div>
    </div>
  );
}

export function DriveDragPreviewMultiple(props) {
  const { t } = useTranslation();
  return (
    <>
      {props.dragSources.map((node, i) => (
        <div key={node.id} className={`tree-node root_node drag_preview_root`}>
          <div>
            <TypeIcon {...node} fileType={node?.fileType || node?.extension || "folder"} />
          </div>
          <div className={"labelGridItem"}>
            <Typography variant="body2">{t(node.name)}</Typography>
          </div>
        </div>
      ))}
    </>
  );
}
