// @ts-nocheck
import { useHookstate } from "@hookstate/core";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import {
  Box,
  Button,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import { SnackbarProvider } from "notistack";
import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import ProfileAvatar from "../../../Layout/ProfileAvatar";
import { notification as IconNotification } from "../../../Pages/Assets/SVGIcons";
import Service from "../../../Service";
// import { Box, Divider, InputAdornment, ListItemIcon, Menu, MenuItem, Select, TextField } from "@mui/material";
// import { UserInfoContext } from "../Context/UserInfoContext";
import LogoutIcon from "@mui/icons-material/Logout";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import { useTranslation } from "react-i18next";
import { GetUserInfoNew } from "../../../data-access/api";
import { appLoadingShow, shedOnScroll } from "../../../data-access/app.state";
import { userInfoState } from "../../../drive-feature/data-access/state";
import LayoutLeftHook from "./layout-left-hook";
/**
 * @param {any} props
 */
// @ts-ignore
// @ts-ignore
function TenantSwitch({ userInfo }) {
  // let { t } = this.props;
  const navigate = useHistory();
  if (!userInfo) {
    return null;
  }

  if (userInfo.tenants.length === 1) {
    return (
      <div className="icon tenant-switch">
        <span>{userInfo.tenant_name}</span>
      </div>
    );
  }

  let TenantList = [];
  for (let tenant of userInfo.tenants) {
    // console.log(tenant);
    TenantList.push(
      <MenuItem value={tenant.id} key={tenant.id}>
        {tenant.name}
      </MenuItem>
    );
  }

  return (
    <div className="icon tenant-switch">
      <Box py={1} px={2}>
        <Select
          value={userInfo.tenant_id}
          style={{ color: "#007BFF" }}
          onChange={(ev) => {
            appLoadingShow.set(true);
            let tenant_id = parseInt(ev.target.value);
            if('serviceWorker' in navigator){
              navigator.serviceWorker.ready.then((registration) => {
                if(registration?.active){
                    // registration.dispatchEvent
                    registration.active.postMessage(
                        "cache-clear-logout"
                    );
                }
              }); 
            }
            Service.TenantSwitch({ tenant_id })
              .then((resp) => {
                // Service.deleteUserControl()
                //   .then((response) => {
                // window.location.reload();
                // GetUserInfoNew().finally(() => {
                //   appLoadingShow.set(false);
                //   navigate.go(0);
                // });
                const apiPromises = [
                  GetUserInfoNew(),
                ];
                Promise.all(apiPromises)
                .finally(() => {
                  localStorage.removeItem('prev_host_signer_list');
                  localStorage.removeItem('prev_viewer_list');
                  localStorage.removeItem('prev_verification_viewer_list');
                  localStorage.removeItem('prev_use_digital_certificate');
                  appLoadingShow.set(false);
                  navigate.go(0);
                });
                // const user = localStorage.getItem('user-info');
                // if (user){
                // }
                // })
                // .catch((error) => {
                //   //console.log("CATCH", error);
                // });
              })
              .catch((err) => {
                appLoadingShow.set(false);
                //console.log("error", err.response);
              });
          }}
          renderValue={() => userInfo.tenant_name}
          variant={"standard"}
        >
          {TenantList}
        </Select>
      </Box>
    </div>
  );
}
// @ts-ignore
function UserInfoMenu({ userInfo }) {
  const { t } = useTranslation("common");
  let [userInfoMenuAnchorEl, setUserInfoMenuAnchorEl] = useState();
  // let { ProfileAvatar } = this;
  let UserFullname = "";
  let TenantName = "";
  let loading = " data-loading";
  let iconProps = {};

  if (userInfo) {
    UserFullname = `${userInfo.family_name} ${userInfo.first_name}`;
    TenantName = `${userInfo.company_name}`;
    loading = "";
    // iconProps.avatar = UserInfo.profile_image;
    iconProps.avatar = localStorage.getItem("profile_image");
  }
  const open = Boolean(userInfoMenuAnchorEl);
  const handleClick = (
    /** @type {{ currentTarget: import("react").SetStateAction<undefined>; }} */ event
  ) => {
    setUserInfoMenuAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    // @ts-ignore
    setUserInfoMenuAnchorEl(null);
  };
  return (
    <div>
      <Button
        id="menu-button"
        aria-controls={open ? "menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        className={"profile-menu" + loading + " profile"}
      >
        <div className="profile-icon">
          <ProfileAvatar {...iconProps} />
        </div>
        <div className="profile-info">
          <div className="info-context">
            <span className="name">{UserFullname}</span>
            <span className="tenant">{TenantName}</span>
          </div>
        </div>
      </Button>
      <Menu
        id="menu"
        anchorEl={userInfoMenuAnchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "menu-button",
        }}
        className={"profile-menu" + loading}
        color="gray"
      >
        <Link to={"/settings/profile"}>
          <MenuItem onClick={handleClose}>
            <ListItemIcon>
              <ManageAccountsIcon color="primary" />
            </ListItemIcon>
            {t("common:main.general.user-setting")}
          </MenuItem>
        </Link>
        <Divider />
        <Link to={"/logout"}>
          <MenuItem onClick={handleClose}>
            <ListItemIcon>
              <LogoutIcon color="primary" />
            </ListItemIcon>
            {t("common:auth.general.logout")}
          </MenuItem>
        </Link>
      </Menu>
    </div>
  );
}

export default function LayoutHookVer({ children, onClick }) {
  // const [userInfo, setUserInfo] = useState(null);
  const userInfo = useHookstate(userInfoState);

  // useEffect(() => {
  //   Service.GetUserInfo()
  //     .then((resp) => {
  //       // console.log("resp", resp);
  //       // setUserInfo(resp);
  //       userInfo.set(resp);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, []);
  // const { enqueueSnackbar } = useSnackbar();
  // useEffect(()=>{
  //   if (showInfoNoti.value.open){
  //     enqueueSnackbar(showInfoNoti.value.message,)
  //   }
  // }, [showInfoNoti])

  function handleScroll(event) {
    const scrollPosition = event.target.scrollTop;
    if (scrollPosition > 50) {
      shedOnScroll.set(true);
    } else {
      shedOnScroll.set(false);
    }
  }
  return (
    <SnackbarProvider maxSnack={3}>
      <div className={`main contrainer-wrapper `} onClick={onClick}>
        {/* <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={showInfoNoti.show.get()}
          autoHideDuration={4000}
          onClose={(e, reason) => {
            if (reason === "clickaway") {
              return;
            }

            showInfoNoti.show.set(false);
          }}
        >
          <Alert severity={"warning"}>
            {showInfoNoti.message.get({ noproxy: true })}
          </Alert>
        </Snackbar> */}
        <div className="layout">
          <LayoutLeftHook />

          <div className="layout-right">
            <div className="layout-right-top">
              <div className="top-middle">
                <div className="system-notification">
                  {
                    // @ts-ignore
                    userInfo.get({ noproxy: true })?.content
                  }
                </div>
              </div>

              <div className="top-right">
                <div className="icon notification" style={{ display: "none" }}>
                  <IconNotification />
                </div>
                <Tooltip title="WEBマニュアル - サポートサイトへ">
                  <IconButton
                    aria-label="FAQ"
                    href={window.location.pathname.includes('guest/document/')? "https://support.paperlogic.jp/manual/manual-24301/":"https://support.paperlogic.jp/"}
                    
                    target="_blank"
                  >
                    <HelpOutlineOutlinedIcon />
                  </IconButton>
                </Tooltip>
                <TenantSwitch userInfo={userInfo.get({ noproxy: true })} />
                <UserInfoMenu userInfo={userInfo.get({ noproxy: true })} />
              </div>
            </div>

            <div className="right-body" onScroll={handleScroll}>{children ? children : null}</div>
            {/* <Body {...props} /> */}

            <div className="right-bottom"></div>
          </div>
        </div>
      </div>
    </SnackbarProvider>
  );
}
