import React from 'react';
import logo from './Assets/img/logo_h.svg';
import './Assets/css/terms-of-service.css';
import { withTranslation } from "react-i18next";
import Button from '@mui/material/Button';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import Stack from '@mui/material/Stack';

class TermsOfService extends React.Component {

    constructor(props) {
		super(props);

		this.state = {
		};
	}

    initialTempFormData() {
    }

    componentDidMount() {
        // document.title = this.props.t("common:auth.registration.agreement.title");
    }

    componentDidUpdate(prevProps) {
        // document.title = this.props.t("common:auth.register.title");
        // console.log("componentDidUpdate", prevProps);
    }

    render() {

        let { t } = this.props;

        return (
            <div className="terms-of-service contrainer-wrapper">
                <div className="terms-of-service contrainer-wrapper horizontal">
                    
                    <div className="contrainer-block">
                    <div className="logo">
                        <img src={logo} className="App-logo" alt="paperlogic" />
                    </div>
                        <div className="title">
                            {t('common:terms-of-service.title')}
                        </div>

                        <div className="form-wrapper">
                            <span className="caution-title">{t('common:terms-of-service.caution.title')}</span>
                            <p>{t('common:terms-of-service.caution.content-1')}</p>
                            <p>{t('common:terms-of-service.caution.content-2')}</p>
                            <p>{t('common:terms-of-service.caution.content-3')}</p>
                            <p>{t('common:terms-of-service.caution.content-4')}</p>
                        </div>

                        <div className="link">
                            <Stack spacing={3}>
                            <Button
                                variant="outlined"
                                startIcon={<InsertDriveFileOutlinedIcon/>}
                                style={{textTransform: 'none'}}
                                rel="noopener noreferrer"
                                target="_blank"
                                href="https://support.paperlogic.jp/wp-content/uploads/2024/06/paperlogicサービス利用約款.pdf">
                                paperlogicサービス約款　{t('common:menu.terms-of-service')}
                            </Button>
                            <Button
                                variant="outlined"
                                startIcon={<InsertDriveFileOutlinedIcon/>}
                                style={{textTransform: 'none'}}
                                rel="noopener noreferrer"
                                target="_blank"
                                href="https://support.paperlogic.jp/wp-content/uploads/2024/06/paperlogicサービス仕様書.pdf">
                                    paperlogicサービス仕様書　{t('common:menu.terms-of-service')}</Button>
                            <Button
                                variant="outlined"
                                startIcon={<InsertDriveFileOutlinedIcon/>}
                                style={{textTransform: 'none'}}
                                rel="noopener noreferrer"
                                target="_blank"
                                href="https://support.paperlogic.jp/wp-content/uploads/2024/06/タイムスタンプサービスご利用条件.pdf">
                                    タイムスタンプサービスご利用条件　{t('common:menu.terms-of-service')}</Button>
                            <Button
                                variant="outlined"
                                startIcon={<InsertDriveFileOutlinedIcon/>}
                                style={{textTransform: 'none'}}
                                rel="noopener noreferrer"
                                target="_blank"
                                href="https://support.paperlogic.jp/wp-content/uploads/2024/06/JCAN証明書サービスご利用条件.pdf">
                                    JCAN証明書サービスご利用条件　{t('common:menu.terms-of-service')}</Button>
                            <Button
                                variant="outlined"
                                startIcon={<InsertDriveFileOutlinedIcon/>}
                                style={{textTransform: 'none'}}
                                rel="noopener noreferrer"
                                target="_blank"
                                href="https://support.paperlogic.jp/wp-content/uploads/2024/06/NRA-PKI証明書サービスご利用条件.pdf">
                                    NRA-PKI証明書サービスご利用条件　{t('common:menu.terms-of-service')}</Button>
                            </Stack>
                        </div>

                    </div>

                    <div className="footer">
                        <div>© {new Date().getFullYear()} paperlogic.co.jp</div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(TermsOfService);
