// @ts-nocheck
import { BusinessDetailField } from "../../Main/BusinessNumberSearch";

export default function InvoiceNumberFields({ data }) {
  //   console.log('data',data)
  if (!data || Object.keys(data || {}).length === 0 || !data?.exist) return <></>;
  return (
    <div className="business_info_data_wrapper">
      {Object.keys(data).map((a, i) =>
        data[a] !== null ? (
          <BusinessDetailField
            key={i}
            className={`business_info_data_field_verification business_field_verification_${a}`}
            field_name={a}
            type={a}
            value={data[a]}
          />
        ) : null
      )}
    </div>
  );
}
