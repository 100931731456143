// @ts-nocheck
import React from "react";
import { createRoot } from "react-dom/client";
// import Languages from "./Languages";
import i18next from "i18next";
import Router, { hideLoader, showLoader } from "./Router";
import AuthRouter from "./Router/Auth";
// import Login from './Pages/Auth/Login';
// import reportWebVitals from './reportWebVitals';
import "./index.css";
import { MultiBackend, getBackendOptions } from "@minoru/react-dnd-treeview";
import "./Pages/Assets/css/main.css";
// import i18n from "./lang.init";
// import 'bootstrap/dist/css/bootstrap.min.css';
// import i18next from "i18next";

// import Amplify, { Auth } from "aws-amplify";
// import awsCognito from "./Config/Cognito";
// import Main from './Main';
import AuthService from "./Service/Auth";
import SystemService from "./Service/System";
import Cookies from "js-cookie";
// import Service from './Service';
import Error from "./Pages/Error";
import Service from "./Service";
import { ThemeProvider } from "@mui/material/styles";
import { MuiCustomTheme } from "./Pages/Assets/CustomTheme/MuiCustomTheme.ts";
import CssBaseline from "@mui/material/CssBaseline";
import { UserInfoProvider } from "./Context/ContextProvider/UserInfoProvider";
import { I18nextProvider } from "react-i18next";
import { userInfoState } from "./drive-feature/data-access/state";
import {
  currentLang,
  errProps,
  isSystemMaintain,
  systemMaintainData,
} from "./data-access/app.state";
import { GetUserInfoNew, tenantCheck } from "./data-access/api";
import axios from "axios";
import { DndProvider } from "react-dnd";
import i18n from "./lang.init";
import { ReloadPrompt } from "./Components/reload-prompt/reload-prompt"
import { alertIdle } from "./Router/IdleTimer"

axios.interceptors.response.use(
  async function (config) {
    return config;
  },
  function (e) {
    // loading.set((v) => false);
    // Do something with request error
    if (
      e?.response?.status === 401 
      && e?.response?.data?.message_code === "message.user_disabled"
    ) {
      errProps.set({
        title: i18next.t("common:message.user_disabled"),
        msg: [""],
        code: 401,
        show: true,
      });
      localStorage.clear();
      hideLoader();
      return Promise.reject(e);
    }

    if (
      e?.response?.status === 403 
      && e?.response?.data?.message_code === "message.error.session_timeout"
    ) {      
      alertIdle.show.set(true);
      alertIdle.message.set(i18next.t("general.alert.user-session-timed-out"));

      if ("serviceWorker" in navigator) {
        navigator.serviceWorker.ready.then((registration) => {
          if (registration?.active) {
            // registration.dispatchEvent
            registration.active.postMessage("cache-clear-logout");
          }
        });
      }
      localStorage.clear();
      
      return Promise.reject(e);
    }

    if (
      e?.response?.status === 403 &&
      e?.response?.data?.message_code === "message.ip_restrict.deny"
    ) {
      errProps.set({
        title: i18next.t("common:message.ip_restrict.deny", "IP制限中です"),
        msg: [
          "許可されたIPからアクセスしてください",
          "詳しくは管理者にお問合せください",
        ],
        code: 401,
        show: true,
      });
      localStorage.clear();
      hideLoader();
      return Promise.reject(e);
    }
    if (
      e?.response?.status === 503 &&
      e?.response?.data?.message_code === "message.is_under_maintenance"
    ) {
      isSystemMaintain.set(true);

      const dateOptions = {
        timeZone: "UTC",
        month: "numeric",
        day: "numeric",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hourCycle: "h23",
      };

      let start_time = new Date(e?.response?.data?.payload?.start_time);
      let end_time = new Date(e?.response?.data?.payload?.end_time);
      const dateFormatter = new Intl.DateTimeFormat("en-US", dateOptions);
      const pStart = dateFormatter
        .formatToParts(start_time)
        .reduce((acc, part) => {
          acc[part.type] = part.value;
          return acc;
        }, {});
      const pEnd = dateFormatter.formatToParts(end_time).reduce((acc, part) => {
        acc[part.type] = part.value;
        return acc;
      }, {});
      let accessDateStart = `${pStart.month}/${pStart.day}   ${pStart.hour}:${pStart.minute}`;
      let accessDateEnd = `${pEnd.month}/${pEnd.day}   ${pEnd.hour}:${pEnd.minute}`;
      systemMaintainData.set({
        start_time: accessDateStart,
        end_time: accessDateEnd,
      });
      hideLoader();
      return Promise.reject(false);
    }
    return Promise.reject(e);
  }
);
var langProps = {
  lang: Cookies.get("lang"),
};

if (!langProps.lang) {
  langProps.lang = "ja";
  Cookies.set("lang", langProps.lang);
}

if (
  process.env.NODE_ENV === "production" &&
  ["STG", "PROD"].includes(process.env.REACT_APP_ENV_FILE)
) {
  console.log = function (val) {};
}

window.onload = (ev) => {
  console.log(ev);
  localStorage.removeItem("user-info");
};
// Amplify.configure(awsCognito);
const userCache = localStorage.getItem("user-info");
if (userCache && userCache !== "undefined" && userCache !== "null") {
  const userInfo = JSON.parse(userCache || null);
  // console.log("userInfo", userInfo);
  userInfoState.set(userInfo);
  currentLang.set(userInfo.language);
  i18next.changeLanguage(userInfo.language);
  // async function getOk() {
  //   const email = await AuthService.GetUserAttributes("email");
  //   const tokenID = await AuthService.GetTokenID();
  //   Service.getUserControl(email, tokenID)
  //     .then((userControlData) => {
  //       console.log("userControlData", userControlData);
  //       if (userControlData && userControlData.data.mail !== "" && userCache) {
  //         return JSON.parse(decodeURIComponent(userControlData.data.body));
  //       }
  //     })
  //     .catch((err) => {
  //       // force = true;
  //     });
  // }
  // getOk();
}

if (
  userCache &&
  !window.location.href.includes("/guest/document") &&
  !window.location.href.includes("/shared-folder/delivery")
) {
  GetUserInfoNew();
  // return;
} else {
  if (
    !window.location.href.includes("/guest/document") &&
    !window.location.href.includes("/shared-folder/delivery")
  ) {
    showLoader();
    GetUserInfoNew().then((userInfo) => {
      hideLoader();
    });
  } else {
    hideLoader();
  }
}
if (
  !window.location.href.includes("/guest/document") &&
  !window.location.href.includes("/shared-folder/delivery")
) {
  AuthService.GetCurrentLogin().catch(async (err) => {
    //   console.log("rrr", err.code);
    if (err === "The user is not authenticated") {
      hideLoader();
      // errProps.set({
      //   msg: err,
      //   code: 400,
      //   show: true,
      // });
      localStorage.removeItem("user-info");
      userInfoState.set(null);
      AuthService.Logout();
    } else {
      // errProps.set({
      //   msg: "Connection error, please try to reload.",
      //   code: 400,
      //   show: true,
      // });
    }
    // localStorage.removeItem('user-info');
    // userInfoState.set(null);
    //console.log(err);
  });
} else {
  hideLoader();
}

async function Index() {
  let MainRouter = Router;
  // MainRouter = Router;
  let errProps = null;
  let userInfo = null;

  const container = document.getElementById("root");
  const root = createRoot(container);
  // root.render(<App />);
  // console.log(errProps)
  root.render(
    <React.StrictMode>
      <ThemeProvider theme={MuiCustomTheme}>
        <CssBaseline />
        <I18nextProvider i18n={i18n}>
          {/* <UserInfoProvider userInfoContent={userInfo}> */}

          <DndProvider backend={MultiBackend} options={getBackendOptions()}>
            {/* <ReloadPrompt/> */}
            <MainRouter userInfo={userInfo} />
          </DndProvider>
          {/* <MainRouter /> */}
          {/* </UserInfoProvider> */}
        </I18nextProvider>
        {/* <Languages {...langProps}>
				</Languages> */}
      </ThemeProvider>
    </React.StrictMode>
  );
}

Index();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
