// @ts-nocheck
import { searchItemFolder } from "../data-access/api";
import { folderDetailSearchState } from "../data-access/state";
import mappedDataToTreeView from "./mappedDataToTreeView";

export default function searchContentFolderUtils(page, searchObject, folderId, scrollLoading, driveDetailLoad, breabCrumbState, fromDocumentSelect) {
    // searchObject.isSearching.set(true);
    // if (page) {
    //   searchObject.page_number.set(page);
    // } else {
    //   searchObject.page_number.set(1);
    //   searchObject.isStop.set(0);
    // }
    if (!searchObject.isStop.get({ stealth: true })) {
        if (page) {
            scrollLoading.set(true);
        } else {
            driveDetailLoad.set(true);
        }
        const breab = breabCrumbState.get({ stealth: true });
        searchItemFolder({
            ...searchObject.get({ stealth: true, noproxy: true }),
            parent_id: fromDocumentSelect
                ? breab[breab.length - 1]?.drive_folder_id
                : folderId || "null",
            folder_only: fromDocumentSelect ? 1 : null,
        })
            .then((r) => {
                if (
                    r.data.payload.folders.length > 0 ||
                    r.data.payload.files.length > 0 ||
                    r.data.payload.documents.length > 0
                ) {
                    if (
                        !page
                    ) {
                        folderDetailSearchState.set([
                            ...r.data.payload.folders.map(mappedDataToTreeView),
                            ...r.data.payload.files.map(mappedDataToTreeView),
                            ...r.data.payload.documents.map(mappedDataToTreeView),
                        ]);
                    } else {
                        folderDetailSearchState.set((v) =>
                            [
                                ...v,
                                ...r.data.payload.folders.map(mappedDataToTreeView),
                                ...r.data.payload.files.map(mappedDataToTreeView),
                                ...r.data.payload.documents.map(mappedDataToTreeView),
                            ].reduce((arr, item) => {
                                if (!arr.find((a) => a.id == item.id)) {
                                    arr.push(item);
                                }
                                return arr;
                            }, [])
                        );
                        // console.log(folderDetailSearchState.get())
                    }
                    searchObject.total.set((v) => v + 10);
                }

                if (
                    r.data.payload.folders.length === 0 &&
                    r.data.payload.files.length === 0 &&
                    r.data.payload.documents.length === 0
                ) {
                    // if (page === 1) {
                    //   folderDetailSearchList.set([]);
                    // }
                    searchObject.isStop.set(1);
                    searchObject.total.set(
                        folderDetailSearchState.get({ stealth: true }).length
                    );
                }
                searchObject.offset_document_number.set(
                    (v) => v + r.data.payload.documents.length
                );
                searchObject.offset_folder_number.set(
                    (v) => v + r.data.payload.folders.length
                );
                searchObject.offset_file_number.set(
                    (v) => v + r.data.payload.files.length
                );
            })
            .finally(() =>
                page ? scrollLoading.set(false) : driveDetailLoad.set(false)
            );
    }
}