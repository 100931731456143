import { useHookstate } from "@hookstate/core";
import { Alert, Button, CircularProgress, Snackbar } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import RefreshIcon from "@mui/icons-material/Refresh";
import CancelRounded from "@mui/icons-material/CancelRounded";
import {
  progessCopyState,
  progressDownloadingState,
  progessMoveState,
  progessUploadFileGlobal,
  progessZippingState,
  warningAlertState,
} from "../../data-access/state";

export default function DriveUploadProgess() {
  const { t } = useTranslation();
  const progess = useHookstate(progessUploadFileGlobal);
  const progessCopy = useHookstate(progessCopyState);
  const progessMove = useHookstate(progessMoveState);
  const progessZipping = useHookstate(progessZippingState);
  const progressDownloading = useHookstate(progressDownloadingState);
  const isUploadSuccess = useHookstate(false);
  const showAlert = useHookstate(warningAlertState);

  function handleClose() {
    isUploadSuccess.set(false);
    progess.complete.set(0);
    progess.totalFile.set(0);
    progess.progess.set(0);
  }
  function handleCloseAlert() {
    showAlert.show.set(false)
  }

  function reloadPage() {
    window.location.reload();
  }
  useEffect(() => {
    if (
      progess.progess.value === 100 &&
      progess.complete.value === progess.totalFile.value
    ) {
      isUploadSuccess.set(true);
      progess.complete.set(0);
      progess.totalFile.set(0);
      progess.progess.set(0);
    }
  }, [progess]);
  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={showAlert.show.get()}
        onClose={handleCloseAlert}
      >
        <Alert severity={showAlert.type.get()}>
        {t(showAlert.message.get())}
          <Button onClick={reloadPage}> <RefreshIcon  /> {t(`common:drive.message.refresh`)} </Button>
          <CancelRounded onClick={() => {showAlert.show.set(false)}} className="close-alert"/>
        </Alert>
        
      </Snackbar>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={progess.totalFile.get() > 0}
        message={
          <div className="upload_progess_message">
            <CircularProgress />
            <div>
              {t(`common:drive.message.progess`)}:{" "}
              {progess.totalFile.get() > 1
                ? Math.round(
                    ((progess.complete.get() || 1) /
                      (progess.totalFile.get() || 1)) *
                      100
                  )
                : progess.progess.get()}
              %
            </div>
            {/* <div>File upload percent: {progess.progess.get()}%</div> */}
          </div>
        }
      />
      <Snackbar
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={isUploadSuccess.get()}
        message={t(`common:drive.message.upload_success`)}
      />
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={!!progessCopy.total.get()}
        message={`${t(
          `common:drive.message.copying`
        )} ${progessCopy.complete.get()}/${progessCopy.total.get()} ${t(
          `common:drive.message.copying_items`
        )} !!`}
      />
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={!!progessMove.total.get()}
        message={`${t(
          `common:drive.message.moving`
        )} ${progessMove.total.get()} ${t(
          `common:drive.message.copying_items`
        )} !!`}
      />
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={!!progessZipping.open.get()}
        message={t(`common:${progessZipping.message.get()}`, "Zipping")}
      />
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={!!progressDownloading.open.get()}
        message={progressDownloading.message.get()}
      />
    </>
  );
}
