import "../Assets/css/settings.css";
import Layout from "../../Layout";
import Menu from "./Menu";
import LoadingList from "../LoadingList";
import Service from "../../Service";
import { withTranslation } from "react-i18next";
import { Form, Table } from "react-bootstrap";

import {
  Box,
  MenuItem,
  Select,
  Button,
  IconButton,
  CircularProgress,
  Tooltip,
} from "@mui/material";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import moment from "moment";
import AuditLogFilterDropdown from "../Components/FilterDropdown/AuditLogFilterDropdown";

import Error from "../Error";

import {ResizableTableWithCache} from "../../Components/resizeable-table-with-cache/ResizableTableWithCache";

class AuditLog extends Layout {
  /** Constructor */
  constructor(props) {
    super(props);

    this.state.page = "audit-log";

    this.state.audit_logs = null;

    this.state.searchOption = {
      current: 1,
      page: 1,
      total: 0,
      page_size: 100,
      page_number: 1,

      create_start_date: "",
      create_end_date: "",

      create_datetime: "",
      create_user: "",
      ip_address: "",
      operation_details: "",
      document_id: "",
      url: "",
    };
    this.state.create_datetime_selection = [];
    this.state.create_user_selection = [];
    this.state.ip_address_selection = [];
    this.state.operation_details_selection = [];
    this.state.document_id_selection = [];
    this.state.url_selection = [];
  }

  /** [Layout] Update LayoutMenu */
  Menu = () => {
    /* Load Menu from Pages/Settings/Menu.jsx */
    return <Menu {...this.props} page={this.state.page} />;
  };

  PageCalculation = (searchOption) => {
    searchOption.current = searchOption.page_number;
    searchOption.page = Math.ceil(searchOption.total / searchOption.page_size);
    return searchOption;
  };

  GetAuditLogListData = (searchOption) => {
    searchOption = this.PageCalculation(searchOption);
    this.setState({ audit_logs: null, searchOption });

    Service.getAuditLogList(searchOption)
      .then((resp) => {
        let { audit_logs } = this.state;
        audit_logs = resp.payload;
        searchOption.total = resp.total;
        searchOption = this.PageCalculation(searchOption);

        this.setState({ audit_logs, searchOption });
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  handleSetFilterOption = (filterOption) => {
    let { searchOption } = this.state;
    const newFilter = {
      ...searchOption,
      ...filterOption,
    };
    this.setState(
      {
        searchOption: newFilter,
        create_datetime_selection: [],
        create_user_selection: [],
        ip_address_selection: [],
        operation_details_selection: [],
        document_id_selection: [],
        url_selection: [],
      },
      () => {
        this.GetAuditLogListData(this.state.searchOption);
      }
    );
  };

  handleSetFilterSelection = (column, value) => {
    this.setState({
      [`${column}_selection`]: value,
    });
  };

  handleClearAllFilter = () => {
    let { searchOption } = this.state;
    const newFilter = {
      ...searchOption,
      create_start_date: "",
      create_end_date: "",
      create_datetime: "",
      create_user: "",
      ip_address: "",
      operation_details: "",
      document_id: "",
      url: "",
    };
    this.setState(
      {
        searchOption: newFilter,
        create_user_selection: [],
        signature_method_selection: [],
        document_type_selection: [],
        document_info_selection: [],
        document_status_selection: [],
        counter_party_selection: [],
        amount_selection: [],
        transaction_date_selection: [],
        action_selection: [],
        preservation_require_selection: [],
        function_type_list_selection: [],
      },
      () => {
        this.GetAuditLogListData(this.state.searchOption);
      }
    );
  };

  handleIsFiltered = () => {
    let {
      create_start_date,
      create_end_date,
      create_datetime,
      create_user,
      ip_address,
      operation_details,
      document_id,
      url,
    } = this.state.searchOption;

    let check = {
      create_start_date,
      create_end_date,
      create_datetime,
      create_user,
      ip_address,
      operation_details,
      document_id,
      url,
    };

    let result = true;

    Object.keys(check).map((key) => {
      if (check[key].length > 0) {
        result = false;
      }
    });
    return result;
  };

  ItemsList = () => {
    let { t } = this.props;
    let { audit_logs } = this.state;

    let HeadList = (
      <tr key="listheader">
        <th key={6} className="th-audit-log-create_datetime" id="create_datetime">
          <AuditLogFilterDropdown
            sort_type={this.state.searchOption.create_datetime_sort}
            filter_selection={this.state.searchOption.create_datetime}
            column={"create_datetime"}
            t={t}
            onFilter={this.handleSetFilterOption}
            label={t("common:audit-log.create_datetime")}
            search_option={this.state.searchOption}
            filter_detail_selection={this.state.create_datetime_selection}
            onFilterDetail={this.handleSetFilterSelection}
          />
        </th>
        <th key={7} className="th-audit-log-create_user" id="create_user">
          <AuditLogFilterDropdown
            sort_type={this.state.searchOption.create_user_sort}
            filter_selection={this.state.searchOption.create_user}
            column={"create_user"}
            t={t}
            onFilter={this.handleSetFilterOption}
            label={t("common:audit-log.create_user")}
            search_option={this.state.searchOption}
            filter_detail_selection={this.state.create_user_selection}
            onFilterDetail={this.handleSetFilterSelection}
          />
        </th>
        <th key={8} className="th-audit-log-ip_address" id="ip_address">
          <AuditLogFilterDropdown
            sort_type={this.state.searchOption.ip_address_sort}
            filter_selection={this.state.searchOption.ip_address}
            column={"ip_address"}
            t={t}
            onFilter={this.handleSetFilterOption}
            label={t("common:audit-log.ip_address")}
            search_option={this.state.searchOption}
            filter_detail_selection={this.state.ip_address_selection}
            onFilterDetail={this.handleSetFilterSelection}
          />
        </th>
        <th key={9} className="th-audit-log-operation_details" id="operation_details">
          <AuditLogFilterDropdown
            sort_type={this.state.searchOption.operation_details_sort}
            filter_selection={this.state.searchOption.operation_details}
            column={"operation_details"}
            t={t}
            onFilter={this.handleSetFilterOption}
            label={t("common:audit-log.operation_details")}
            search_option={this.state.searchOption}
            filter_detail_selection={this.state.operation_details_selection}
            onFilterDetail={this.handleSetFilterSelection}
          />
        </th>
        <th key={10} className="th-audit-log-document_id" id="document_id">
          <AuditLogFilterDropdown
            sort_type={this.state.searchOption.document_id_sort}
            filter_selection={this.state.searchOption.document_id}
            column={"document_id"}
            t={t}
            onFilter={this.handleSetFilterOption}
            label={t("common:audit-log.document_id")}
            search_option={this.state.searchOption}
            filter_detail_selection={this.state.document_id_selection}
            onFilterDetail={this.handleSetFilterSelection}
          />
        </th>
        <th key={11} className="th-audit-log-url" id="url">
          <AuditLogFilterDropdown
            sort_type={this.state.searchOption.url_sort}
            filter_selection={this.state.searchOption.url}
            column={"url"}
            t={t}
            onFilter={this.handleSetFilterOption}
            label={"URL"}
            search_option={this.state.searchOption}
            filter_detail_selection={this.state.url_selection}
            onFilterDetail={this.handleSetFilterSelection}
          />
        </th>
      </tr>
    );

    if (audit_logs === null) {
      return <LoadingList />;
    }

    if (audit_logs.length === 0) {
      return (
        <ResizableTableWithCache storageKey={`colWidths_AuditLog`}>
          <tbody>
            <tr className="document-row-list error-tr">
              <thead key={0}>{HeadList}</thead>
              <td className="record-not-found">
                <Error msg={t("common:message.record.not-found")} />
              </td>
            </tr>
          </tbody>
        </ResizableTableWithCache>
      );
    }

    let ItemsList = [];
    for (let log of audit_logs) {
      ItemsList.push(
        <tr>
          <td key={0} className="document-created-date clickable">
            <div className="inner-container">
              <span>
                {log.create_datetime &&
                  moment(log.create_datetime)
                    .utcOffset(0)
                    .format("YYYY-MM-DD HH:mm:ss")}
              </span>
            </div>
          </td>

          <td key={1} className="creator-info clickable">
            <div className="inner-container">
              <span>{log.create_user}</span>
            </div>
          </td>

          <td key={2} className="creator-info clickable overflow-hidden">
            <div className="inner-container">
              <Tooltip title={log.ip_address}>
                <span>{log.ip_address}</span>
              </Tooltip>
            </div>
          </td>

          <td key={3} className="creator-info clickable">
            <div className="inner-container">
              {/* <span>{log.operation_details}</span> */}
              <span>
                {t(
                  `common:audit-log.operation-details.${log.operation_details}`
                )}
              </span>
            </div>
          </td>

          <td key={4} className="creator-info clickable">
            <div className="inner-container">
              <span>{log.document_id}</span>
            </div>
          </td>

          <td key={5} className="creator-info clickable">
            <div className="inner-container">
              <a href={log.url} target="_blank" className="url-nomarl">
                {log.url}
              </a>
            </div>
          </td>
        </tr>
      );
    }

    return (
      <ResizableTableWithCache storageKey={`colWidths_AuditLog`}>
        <thead key={0}>{HeadList}</thead>
        <tbody key={1}>{ItemsList}</tbody>
      </ResizableTableWithCache>
    );
  };

  GetAllAuditLogList = () => {
    let { searchOption } = this.state;
    this.GetAuditLogListData(searchOption);
  };

  componentDidMountExtends = async () => {
    if (this.componentDidMountOptional) {
      if (typeof this.componentDidMountOptional === "function") {
        this.componentDidMountOptional(this.GetAllAuditLogList);
      }
    } else {
      this.GetAllAuditLogList();
    }
  };

  onPageChangeHandler = (ev) => {
    let page = parseInt(ev.target.value);
    let { searchOption } = this.state;
    let nextPage = page;
    if (nextPage !== searchOption.page_number) {
      searchOption.page_number = nextPage;
      this.GetAuditLogListData(searchOption);
    }
  };

  onRecordLimitChangeHandler = (ev) => {
    let limit = parseInt(ev.target.value);
    let { searchOption } = this.state;
    if (limit !== searchOption.page_size) {
      let cursor = (searchOption.current - 1) * searchOption.page_size;
      searchOption.page_number =
        Math.floor(cursor / limit) === 0 ? 1 : Math.floor(cursor / limit);
      searchOption.page_size = limit;
      this.GetAuditLogListData(searchOption);
    }
  };

  onSortingHandler = (ev) => {
    let sort = parseInt(ev.target.value);
    let { searchOption } = this.state;
    if (sort !== searchOption.sorting_code) {
      searchOption.sorting_code = sort;
      this.GetAuditLogListData(searchOption);
    }
  };

  prevPageData = (ev) => {
    let { searchOption, PossiblePrevPage } = this.state;
    let nextPage = searchOption.page_number - 1;
    if (nextPage >= 0) {
      searchOption.page_number = nextPage;
      this.GetAuditLogListData(searchOption);
      PossiblePrevPage = true;
    } else {
      PossiblePrevPage = false;
    }
    this.setState({ PossiblePrevPage });
  };

  nextPageData = (ev) => {
    let { searchOption, PossibleNextPage } = this.state;
    let nextPage = searchOption.page_number + 1;
    if (nextPage <= searchOption.page) {
      searchOption.page_number = nextPage;
      this.GetAuditLogListData(searchOption);
      PossibleNextPage = true;
    } else {
      PossibleNextPage = false;
    }
    this.setState({ PossibleNextPage });
  };

  PageListNavigator = () => {
    let { searchOption } = this.state;
    let navItems = [];
    let currentFrom = 0;
    let pageTo = 0;
    let pageFrom = 0;
    let currentTo = 0;
    let total = searchOption.total;
    let page_number = searchOption.page_number;
    let page_size = searchOption.page_size;
    let current = searchOption.current;
    if (!searchOption.total) {
      return <CircularProgress size="1rem" />;
    }
    for (let i = 0; i < searchOption.page; i++) {
      let page = i + 1;
      if (total === 0) {
        pageFrom = 0;
      } else {
        pageFrom = (page - 1) * page_size + 1;
      }
      if (total < page * page_size) {
        pageTo = total;
      } else {
        pageTo = page * page_size;
      }
      navItems.push(
        <MenuItem value={page} key={page}>{`${pageFrom}-${pageTo}`}</MenuItem>
      );
    }
    if (total === 0) {
      currentFrom = 0;
    } else {
      currentFrom = (current - 1) * page_size + 1;
    }
    if (total < current * page_size) {
      currentTo = total;
    } else {
      currentTo = current * page_size;
    }

    return (
      <Box py={1} px={2}>
        <Select
          value={current}
          onChange={this.onPageChangeHandler}
          renderValue={() =>
            `${currentFrom} - ${currentTo} / ${searchOption.total}`
          }
          variant={"standard"}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 250,
                minWidth: 130,
              },
            },
          }}
          style={{ color: "#007BFF" }}
        >
          {navItems}
        </Select>
      </Box>
    );
  };

  handleSearchDate = (event) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      searchOption: {
        ...prevState.searchOption,
        [name]: value,
      },
    }));
  };

  HeaderRightNav = () => {
    let { t } = this.props;
    let { searchOption } = this.state;
    const { PageListNavigator } = this;
    let checkFiltered = this.handleIsFiltered();

    return (
      <div className="header-right">
        {!checkFiltered && (
          <span
            className={"clear-all-filter"}
            onClick={this.handleClearAllFilter}
          >
            {t("common:documents.filter.clear-all")}
          </span>
        )}

        <Box py={1} px={2}>
          <Select
            value={searchOption.page_size}
            onChange={this.onRecordLimitChangeHandler}
            renderValue={() =>
              searchOption.page_size +
              " " +
              t("common:search.view.record-per-page")
            }
            variant={"standard"}
            style={{ color: "#007BFF" }}
          >
            {[100, 200, 500, 1000].map((count, index) => (
              <MenuItem value={count} key={index}>
                {count + " " + t("common:search.view.record-per-page")}
              </MenuItem>
            ))}
          </Select>
        </Box>

        <div className="pagination">
          <PageListNavigator />

          <IconButton
            onClick={this.prevPageData}
            disabled={searchOption.current === 1 ? true : false}
          >
            <ChevronLeftIcon />
          </IconButton>

          <IconButton
            onClick={this.nextPageData}
            disabled={searchOption.current === searchOption.page ? true : false}
          >
            <ChevronRightIcon />
          </IconButton>
        </div>
      </div>
    );
  };

  HeaderLeftNav = () => {
    let { t } = this.props;

    return (
      <div className="header-left">
        <div className="pagination">
          <div className="startdate">
            <Form.Control
              type="date"
              name="create_start_date"
              placeholder="年/月/日"
              value={this.state.searchOption.create_start_date}
              onChange={this.handleSearchDate}
            />
          </div>
          <div className="tilde">~</div>
          <div className="enddate">
            <Form.Control
              type="date"
              name="create_end_date"
              placeholder="年/月/日"
              value={this.state.searchOption.create_end_date}
              onChange={this.handleSearchDate}
            />
          </div>

          <div className="search-btn">
            <Button
              title="Action"
              variant="contained"
              onClick={() => {
                this.SearchByDate();
              }}
            >
              <span>{t("common:settings.audit-log.search")}</span>
            </Button>
          </div>
        </div>
      </div>
    );
  };

  SearchByDate = () => {
    let { searchOption } = this.state;
    this.GetAuditLogListData(searchOption);
  };

  render() {
    const { Main, ItemsList, HeaderRightNav, HeaderLeftNav } = this;

    return (
      <>
        <div>
          <Main>
            <div className="audit-log-container">
              <div className="header-wrapper above">
                <div className="header-left"></div>
                <div className="header-right"></div>
              </div>
              <div className="header-wrapper">
                <HeaderLeftNav />
                <HeaderRightNav />
              </div>

              <div className="body-wrapper body-wrapper-scroll">
                <ItemsList />
              </div>
            </div>
          </Main>
        </div>
      </>
    );
  }
}

export default withTranslation()(AuditLog);
