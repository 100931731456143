import React from 'react';
import Moment from 'moment';
import sign from '../Assets/img/sign.png';

export const SignatureInfoItem = (props) => {
    let { signature, translator, key } = props;

    const [isClicked, setIsClicked] = React.useState(false);

    const handleClick = () => {
        setIsClicked(!isClicked);
    };


    const dateTime = Moment(signature.DateTime?.substring(0, 19)).format('YYYY-MM-DD HH:mm:ss');

    if (isClicked) {
        return (
            <div key={key} className="signature-info-item clicked" onClick={handleClick}>
                <div className={'signature-info'}>
                    <div className="signature-info-left">
                        <span>{translator(`common:documents.signature-info.date-time`)}</span>
                    </div>
                    <div className="signature-info-right">
                        <span>{dateTime}</span>
                    </div>
                </div>
                <div className={'signature-info'}>
                    <div className="signature-info-left">
                        <span>{translator(`common:documents.signature-info.signer`)}</span>
                    </div>
                    <div className="signature-info-right">
                        <span>{signature.SignerName}</span>
                    </div>
                </div>
                <div className={'signature-info'}>
                    <div className="signature-info-left">
                        <span>{translator(`common:documents.signature-info.company-name`)}</span>
                    </div>
                    <div className="signature-info-right">
                        <span>{signature.CompanyName}</span>
                    </div>
                </div>
                <div className={'signature-info'}>
                    <div className="signature-info-left">
                        <span>{translator(`common:documents.signature-info.email`)}</span>
                    </div>
                    <div className="signature-info-right">
                        <span>{signature.Email}</span>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div key={key} className="signature-item-container" onClick={handleClick}>
            <div className="signature-icon">
                <img
                    src={sign} alt="Forbidden."
                    style={{ width: '45px' }}
                />
            </div>
            <div key={signature.fieldName} className="signature-info-item" onClick={handleClick}>
                <div className="signature-info-left">
                    <span>{signature.certificateName}</span>
                </div>
                <div className="signature-info-right">
                    <span>{dateTime}</span>
                </div>
            </div>
        </div>
        
    )
}