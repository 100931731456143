// @ts-nocheck
import React from "react";
import Layout from "../../Layout";
import MainMenu from "./Menu";
import { withTranslation } from "react-i18next";
import Service from "../../Service";
import LoadingDashboard from "../LoadingDashboard.jsx";
import SVGIcons from "../Assets/SVGIcons";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Tooltip from "@mui/material/Tooltip";
import { formatBytes } from "../../drive-feature/utils/format-file-size";
import { hookstate, StateFragment } from "@hookstate/core";
import { userInfoState } from "../../drive-feature/data-access/state";
const dashboardState = hookstate({
  info: {},
  documentCount: [],
  users: [],
  services: [],
  size: {},
  service_size: [],
  accounts: {},
});
class Dashboard extends Layout {
  constructor(props) {
    super(props);
    this.state.page = "Dashboard";
    // this.state.loading = true;
    this.state.data = null;
    this.state.info = null;
    this.state.services = null;
    this.state.size = null;
    this.state.service_size = null;
    this.state.accounts = null;
    this.state.allServiceLabel = "";
  }

  Menu = () => {
    return <MainMenu {...this.props} page={this.state.page} />;
  };

  componentDidMountExtends = () => {
    Service.getDashboardData()
        .then((resp) => {
          let data, accounts;
          let { t } = this.props;
          data = resp.payload;

          let services = [
            {
              documents_count: data.signer_number,
              function_type_id: 1,
              function_type_code: "signer",
              function_type_label: "電子契約",
            },
            {
              documents_count: data.sender_number,
              function_type_id: 2,
              function_type_code: "sender",
              function_type_label: "書類送付",
            },
            {
              documents_count: data.circle_number,
              function_type_id: 3,
              function_type_code: "circle",
              function_type_label: "社内署名",
            },
            {
              documents_count: data.verification_number,
              function_type_id: 4,
              function_type_code: "verification",
              function_type_label: "電子書庫",
            },
          ];

          accounts = {};
          for (let acc of [
            { status: "active", user_count: data.activated_user_number },
            {
              status: "inactive",
              user_count: data.disabled_user_number,
            },
          ]) {
            accounts[acc.status] = acc;
          }
          // console.log("diuu", resp);
          dashboardState.set((v) => ({
            ...v,
            info: {
              company_id: data.company_id,
              tenant_id: data.tenant_id,
              // company_name: data.name,
              contract_start_date:  data?.contract_start_date ? data?.contract_start_date?.substring(0, 10):null,
              contract_expire_date:  data?.contract_expire_date ? data?.contract_expire_date?.substring(0, 10):null,
              limit_contract_person: data.user_number,
              name: data.name,
              partner_id: 0,
              status: data.status,
              contract_capacity: formatBytes(data.contract_capacity, 2, "dashboard"),
            },
            documentCount: [...services],
            users: [
              { status: "active", user_count: data.activated_user_number },
              {
                status: "inactive",
                user_count: data.disabled_user_number,
              },
            ],
            services: data.services,
            size: {
              all: formatBytes(data.document_size, 2, "dashboard"),
              circle: formatBytes(data.circle_size, 2, "dashboard"),
              filebox: formatBytes(
                  data?.shared_folder_size || "0",
                  2,
                  "dashboard"
              ),
              sender: formatBytes(data.sender_size),
              signer: formatBytes(data.signer_size, 2, "dashboard"),
              verification: formatBytes(data.verification_size, 2, "dashboard"),
            },
            service_size: [
              {
                code: "all",
                function_type_id: "all",
                label: t(`common:ALL-SIZE`),
                size: formatBytes(data.document_size, 2, "dashboard"),
              },
              {
                code: "signer",
                function_type_id: 1,
                label: "SiGNER",
                size: formatBytes(data.signer_size, 2, "dashboard"),
              },
              {
                code: "sender",
                function_type_id: 2,
                label: "SeNDER",
                size: formatBytes(data.sender_size, 2, "dashboard"),
              },
              {
                code: "circle",
                function_type_id: 3,
                label: "CiRCLE",
                size: formatBytes(data.circle_size, 2, "dashboard"),
              },
              {
                code: "verification",
                function_type_id: 4,
                label: "VeRIFICATION",
                size: formatBytes(data.verification_size, 2, "dashboard"),
              },
              {
                code: "filebox",
                function_type_id: 5,
                label: "FiLEBOX",
                size: formatBytes(data?.shared_folder_size || 0, 2, "dashboard"),
              },
            ],
            accounts,
          }));
          // console.log("dashboardState.get()", dashboardState.get());
          // info = ;

          // info = data.info
          // this.setState({ data, info, services, size, service_size, accounts });
        })
        .catch((err) => {
          //console.log(err.response);
        });
  };

  ServiceIcon = (props) => {
    let Icon = SVGIcons[props.code] || SVGIcons.help;
    return <Icon {...props} />;
  };

  ServiceDocumentSummary = () => {
    let { t } = this.props;
    let { data } = this.state;
    let { ServiceIcon } = this;
    let serviceItems = [];

    // for (let service of data.documentCount) {
    //   serviceItems.push(
    //     <div
    //       key={`doc-summary-${service.function_type_id}`}
    //       className="list-stat-item active"
    //     >
    //       <p className="label">
    //         <ServiceIcon code={service.function_type_code} className="icon" />
    //         <span>{t(`common:${service.function_type_label}`)}</span>
    //       </p>
    //       <p className="value">{service.documents_count}</p>
    //     </div>
    //   );
    // }

    return (
        <StateFragment state={dashboardState}>
          {(s) =>
              s.documentCount.get().map((service) => (
                  <div
                      key={`doc-summary-${service.function_type_id}`}
                      className="list-stat-item active"
                  >
                    <p className="label">
                      <ServiceIcon
                          code={service.function_type_code}
                          className="icon"
                      />
                      <span>{t(`common:menu.${service.function_type_code}`)}</span>
                    </p>
                    <p className="value">{service.documents_count}</p>
                  </div>
              ))
          }
        </StateFragment>
    );
  };

  AllServiceLabel = () => {
    let { t } = this.props;
    // let { services } = this.state;
    let { ServiceIcon } = this;
    // let serviceItems = [];
    // let ServiceLabel = [];
    // for (let service of services) {
    //   ServiceLabel.push();
    // }
    // ServiceLabel = ServiceLabel.join(", ");
    // ServiceLabel;
    return (
        <StateFragment state={dashboardState}>
          {(s) =>
              s.services.get().map((service) => (
                  <p
                      key={`service-${service.function_type_id}`}
                      className="label active"
                  >
                    <ServiceIcon code={service.function_type_code} className="icon" />
                    <span>{t(`common:${service.function_type_label}`)}</span>
                  </p>
              ))
          }
        </StateFragment>
    );
  };

  NumToString = (value) => {
    if (isNaN(parseFloat(value))) {
      return value;
    }

    let numOptions = {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    };

    return Number(value).toLocaleString(undefined, numOptions);
  };

  AllServiceSize = () => {
    let { t } = this.props;
    // let { size, service_size } = this.state;
    let { ServiceIcon } = this;
    // let serviceItems = [];
    // let serviceItems = [];

    // serviceItems.push(
    //   <div key={`size-all`} className="list-stat-item active">
    //     <p className="label">
    //       <span>{t(`common:ALL-SIZE`)}</span>
    //     </p>
    //     <p className="value">
    //       {t("common:service.size.summary-unit", { size: size.all })}
    //     </p>
    //   </div>
    // );

    // for (let service of service_size) {
    //   serviceItems.push(
    //     <div
    //       key={`size-${service.function_type_id}`}
    //       className="list-stat-item active"
    //     >
    //       <p className="label">
    //         <ServiceIcon code={service.code} className="icon" />
    //         <span>{t(`common:${service.label}`)}</span>
    //       </p>
    //       <p className="value">
    //         {t("common:service.size.summary-unit", { size: service.size })}
    //       </p>
    //     </div>
    //   );
    // }
    // ServiceLabel = ServiceLabel.join(", ");
    // console.log("rin", service_size);
    return (
        <StateFragment state={dashboardState.service_size}>
          {(s) =>
              s.get().map((service) =>
                  service.code === "all" ? (
                      <div key={`size-all`} className="list-stat-item active">
                        <p className="label">
                          <span>{t(`common:ALL-SIZE`)}</span>
                        </p>
                        <p className="value">{service.size}</p>
                      </div>
                  ) : (
                      <div
                          key={`size-${service.function_type_id}`}
                          className="list-stat-item active"
                      >
                        <p className="label">
                          <ServiceIcon code={service.code} className="icon" />
                          <span>{t(`common:${service.label}`)}</span>
                        </p>
                        <p className="value">{service.size}</p>
                      </div>
                  )
              )
          }
        </StateFragment>
    );
  };

  render() {
    let { t } = this.props;
    let { data, info, services, accounts } = this.state;
    const { Main, AllServiceLabel, AllServiceSize, ServiceDocumentSummary } =
        this;

    // if (data == null || UserInfo == null) {
    //   return (
    //     <Main>
    //       <LoadingDashboard />
    //     </Main>
    //   );
    // }
      
    return (
        <StateFragment state={dashboardState}>
          {(s) => {
            return (
                <>
                  <StateFragment state={userInfoState}>
                    {(s2) =>
                        s2.get() && s.get().info?.tenant_id ? (
                            <Main>
                              <div className="dashboard-container">
                                <div className="container-fluid">
                                  <div className="item-dashboard company-info">
                                    <div className="title">
                                      <h2>
                                        <StateFragment state={userInfoState}>
                                          {(s) => <>{s.get().tenant_name}</>}
                                        </StateFragment>
                                      </h2>
                                    </div>
                                    <div className="tenant-info">
                                      <div className="tenant-info-item">
                                        <div className="label">
                                          {t("common:settings.tenant.list-tenant-id")}
                                        </div>
                                        <div className="value">
                                          <StateFragment state={userInfoState}>
                                            {(s) => <>{s.get().tenant_id}</>}
                                          </StateFragment>
                                        </div>
                                      </div>
                                      {s2.get()?.administrator_role == 1 ? <div className="tenant-info-item">
                                        <div className="label">
                                          {t("common:general.contract-start-date")}
                                        </div>
                                        <div className="value">
                                          <StateFragment state={dashboardState}>
                                            {(s) => {
                                              // console.log(s);
                                              return (
                                                  <>{`${s.info.get()?.contract_start_date || ''} ${s.info.get()?.contract_expire_date ? '~' : ''} ${s.info.get()?.contract_expire_date || ''}`}</>
                                              );
                                            }}
                                          </StateFragment>
                                        </div>
                                      </div> : null}
                                      <div className="tenant-info-item">
                                        <div className="label">
                                          {t("common:general.contract-services")}
                                        </div>
                                        <div className="value">
                                          <AllServiceLabel/>
                                        </div>
                                      </div>
                                      <div className="tenant-info-item">
                                        <div className="label">
                                          {t("common:general.contract-capacity")}
                                        </div>
                                        <div className="value">
                                          <StateFragment state={dashboardState}>
                                            {(s) => {
                                              return (
                                                  <>{s.info.get()?.contract_capacity}</>
                                              );
                                            }}
                                          </StateFragment>
                                        </div>
                                      </div>
                                      {s2.get()?.administrator_role == 1 ? <div className="tenant-info-item">
                                        <div className="label">
                                          {t("common:general.contract-limit-person")}
                                        </div>
                                        <div className="value">
                                          <div>
                                            <StateFragment state={dashboardState}>
                                              {(s) => (
                                                  <>
                                                    {s.info.get()?.limit_contract_person}
                                                  </>
                                              )}
                                            </StateFragment>
                                            {/* {info.limit_contract_person} */}
                                            <span className="text-unit">
                                      {t("common:general.person-unit")}
                                    </span>
                                          </div>
                                          <Tooltip
                                              title={t(
                                                  "common:tooltip.dashboard.active-users"
                                              )}
                                              arrow
                                          >
                                            <div>
                                      <span className="tenant-info-item-small">
                                        <AccountCircleIcon
                                            color="success"
                                            fontSize="small"
                                        />
                                      </span>
                                              <span className="tenant-info-item-small">
                                        {t("common:general.active-user")}
                                      </span>
                                              <span className="tenant-info-item-small">
                                        {/* {accounts["active"].user_count} */}
                                                <StateFragment state={dashboardState}>
                                          {(s) => (
                                              <>
                                                {
                                                  s.accounts.get()["active"]
                                                      ?.user_count
                                                }
                                              </>
                                          )}
                                        </StateFragment>
                                      </span>
                                              <span className="text-unit">
                                        {t("common:general.person-unit")}
                                      </span>
                                            </div>
                                          </Tooltip>
                                          <Tooltip
                                              title={t(
                                                  "common:tooltip.dashboard.inactive-users"
                                              )}
                                              arrow
                                          >
                                            <div>
                                      <span className="tenant-info-item-small">
                                        <AccountCircleIcon
                                            color="disabled"
                                            fontSize="small"
                                        />
                                      </span>
                                              <span className="tenant-info-item-small">
                                        {t("common:general.inactive-user")}
                                      </span>
                                              <span className="tenant-info-item-small">
                                        <StateFragment state={dashboardState}>
                                          {(s) => (
                                              <>
                                                {
                                                  s.accounts.get()["inactive"]
                                                      ?.user_count
                                                }
                                              </>
                                          )}
                                        </StateFragment>
                                                {/* {accounts["inactive"].user_count} */}
                                      </span>
                                              <span className="text-unit">
                                        {t("common:general.person-unit")}
                                      </span>
                                            </div>
                                          </Tooltip>
                                        </div>
                                      </div> : null
                                      }
                                    </div>
                                  </div>
                                </div>

                                <div className="container-fluid">
                                  <div className="item-dashboard document-info">
                                    <div className="title">
                                    <h2>{t("common:service.document.summary")}</h2>
                                    </div>
                                    <div className="list-stat">
                                      <ServiceDocumentSummary />
                                    </div>
                                  </div>
                                </div>

                                <div className="container-fluid">
                                  <div className="item-dashboard document-info">
                                    <div className="title">
                                      <h2>{t("common:service.document.size")}</h2>
                                    </div>
                                    <div className="list-stat">
                                      <AllServiceSize />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Main>
                        ) : (
                            <Main>
                              <LoadingDashboard />
                            </Main>
                        )
                    }
                  </StateFragment>
                </>
            );
          }}
        </StateFragment>
    );
    return (
        <StateFragment state={dashboardState}>
          {(s) => {
            const UserInfo = s.get({ noproxy: true });
            if (UserInfo == null) {
              return (
                  <Main>
                    <LoadingDashboard />
                  </Main>
              );
            }

            // console.log(accounts);

            //   let ServiceLabel = [];
            //   for (let service of services) {
            //     ServiceLabel.push(t(`common:${service.function_type_label}`));
            //   }
            //   ServiceLabel = ServiceLabel.join(", ");
          }}
        </StateFragment>
    );
  }
}

export default withTranslation()(Dashboard);
