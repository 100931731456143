export const DocumentFilterEnum =  {
    create_user: 'create_user',
    document_type: 'document_type',
    document_info: 'document_info',
    document_status: 'document_status',
    action: 'action',
    amount: 'amount',
    transaction_date: 'transaction_date',
    signature_method: 'signature_method',
    function_type: 'function_type',
    function_type_list: 'function_type_list',
    preservation_require: 'preservation_require',
    counter_party: 'counter_party',
    create_datetime: 'create_datetime',
    ip_address: 'ip_address',
    operation_details: 'operation_details',
    document_id: 'document_id',
    url: 'url'
}
