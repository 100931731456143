
import axios from "axios";
import { async } from "rxjs";
import API from "../Config/API";
import { API_ROUTES, driveApiCall } from "../drive-feature/config/api.config";
import { userInfoState } from "../drive-feature/data-access/state";
import i18n from "../lang.init";
import Service from "../Service";
import AuthService from "../Service/Auth";
import { currentLang } from "./app.state";

export async function tenantCheck() {
    const tokenID = await AuthService.GetCurrentLogin();
    // console.log(tokenID, tokenID?.signInUserSession?.idToken?.jwtToken)
    return axios.get(API.url + API_ROUTES.tenant.tenantCheck, {
        headers: {
            Authorization: tokenID?.signInUserSession?.idToken?.jwtToken
        }
    })
}

export async function GetUserInfoNew() {
    // const tokenS = new Date().getTime() / 1000;
    // console.log('GetUserInfoNew', );
    const uri = API.url + "/user/new-info.json";
    const email = await AuthService.GetUserAttributes("email");
    const tokenID = await AuthService.GetTokenID();
    // const tokenE = new Date().getTime() / 1000;
    // console.log('GetUserInfoNewStart', tokenE - tokenS );

    return await new Promise((resolve, reject) => {
        if (!email) {
            reject(false);
        }
        // console.log(tokenID);
        let queryParams = new URLSearchParams();
        queryParams.set("email", email);
        let url = uri + "?" + queryParams.toString();
        axios.get(url, {
            headers: {
                Authorization: tokenID,
            },
        }).then((resp) => {
            let userinfoMap = {};
            console.log(resp.data.payload.language);

            for (let [key, value] of Object.entries(resp.data.payload)) {
                if (['profile_image'].includes(key)) {
                    localStorage.setItem(key, value);
                    continue;
                }
                // @ts-ignore
                userinfoMap[key] = value;
            }
            currentLang.set(resp.data.payload.language || 'ja');
            localStorage.setItem("user-info", JSON.stringify(resp.data.payload));
            userInfoState.set(resp.data.payload);
            i18n.changeLanguage(resp.data.payload.language || 'ja')
            // console.log(userinfoMap);

            // console.log('GetUserInfoNewEnd', tokenE - (new Date().getTime() / 1000) );
            resolve(resp.data.payload);

        }).catch((error) => {
            reject(error);
        });
    });
}