import React from 'react';
import Cookies from 'js-cookie';
import Loading from '../../../../Pages/Loading'

function Index() {

    const Suspense = React.Suspense;
    const lang = Cookies.get('lang');
    const DefaultLang = React.lazy(() => {
        return import('./' + lang).catch((err) => {
            return import('./en');
        });
    });

    return (
        <Suspense fallback={<Loading />}>
            <DefaultLang />
        </Suspense>
    );
};

export default Index;