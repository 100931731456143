// @ts-nocheck
import { Menu, MenuItem, Popper } from "@mui/material";
import Loading from "../../Pages/Loading";
import { usePopper } from "react-popper";
import { useHookstate } from "@hookstate/core";
import { createElement, useEffect, useRef } from "react";


/**
 * @param {{ anchorEl: any; open: any; handleClose: any; items: any; loading: any; menuContent:any; }} props
 */
export default function BaseTooltipPopover(props) {
  const { anchorEl,className, open, handleClose, items, loading, menuContent,onClick,offsetTop } = props;
  const popperElement = useHookstate(null);
  const arrowElement = useHookstate(null);
  const popperRef = useRef();
  const arrowRef = useRef();
  const { styles, attributes } = usePopper(anchorEl, popperElement.get(), {
    placement: "right",
    strategy: 'fixed',
    modifiers: [
      { name: "arrow", options: { element: arrowElement.get() } },
      {
        name: "offset",
        options: {
          offset: [offsetTop || 0, 8],
        },
      },
    ],
  });
  useEffect(() => {
    if (popperRef.current) popperElement.set(popperRef.current);
  }, [popperRef]);

  useEffect(() => {
    if (arrowRef.current) arrowElement.set(arrowRef.current);
  }, [arrowRef]);

//   if (!(menuContent && items?.length)) {
//     return (
//       <div
//         className={`popper_suggest ${!open ? "hide_popper" : ""}`}
//         ref={popperRef}
//         style={styles.popper}
//         {...attributes.popper}
//       >
//         NO DATA
//         <div className="popper_arrow" ref={arrowRef} style={styles.arrow} />
//       </div>
//     );
//   }
  /**
   * @param {import("react").MouseEvent<HTMLLIElement, MouseEvent>} e
   * @param {any} data
   */
  function onMenuClick(e, data) {
    handleClose(e, data);
  }

  function renderMenuItem() {
    return loading ? (
      <Loading />
    ) : (
      items.map((a) => (
        <MenuItem key={a.name} onClick={(e) => onMenuClick(e, a)}>
          {a.name}
        </MenuItem>
      ))
    );
  }
  // createElement
//   const Comp = createElement(menuContent, {
//     data: items[0]
//   })
  // console.log(Comp)
  return (
    <div
      className={`popper_suggest ${!open ? "hide_popper" : ""} ${className}`}
      ref={popperRef}
      style={styles.popper}
      {...attributes.popper}
      onClick={(e)=> onClick ? onClick(e):undefined}
    >
      {menuContent}
      <div className="popper_arrow" ref={arrowRef} style={styles.arrow} />
    </div>
  );
}
