import React from "react";
import { withTranslation } from "react-i18next";

class Main extends React.Component {
    // constructor(props) {
    //     super(props);
    // }
    render() {
        return (
            <div className="test">{this.props.t("common:documents.document-info.doc-title")}</div>
        );
    }
}

export default withTranslation()(Main);