import React from 'react';
import { Link } from "react-router-dom";
import logo from '../../Assets/img/Logo2.svg';
import '../../Assets/css/auth.css';

import { withTranslation } from "react-i18next";
import AuthService from '../../../Service/Auth';
import {Button, Col, Form} from "react-bootstrap";
import Loading from "../../Loading";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {VisibilityOff} from "@mui/icons-material";

class Verification extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            params: props.match.params,
            token: props.match.params.token,
            status: props.t('common:auth.registration.verification.initial'),
            success: true,
            need_password: false,
            formData: {
                password: null,
                password_confirm: null,
            },
            passwordVisible: false,
            confirmedPasswordVisible: false,
            formValidate: false,
            loading: true,
            processing: false,
            verified: false,
            verifyPasswordStatus: null,
            failure: false,
            fieldValidate: {},
        };
    }

    componentDidMount() {
        let { t } = this.props;
        let { token, status, success } = this.state;

        status = t('common:auth.registration.verification.loading');
        document.title = this.props.t("common:auth.registration.verification.title");

        this.setState({ status });

        AuthService.RegisterVerification(token).then(resp => {
            const { payload } = resp;
            if (payload.status === "NEED_PASSWORD") {
                this.setState({
                    need_password: true,
                })
            } else {
                status = t('common:auth.registration.verification.success');
                this.setState({ status });
            }
        }).catch(err => {
            console.log(err.response);
            status = t('common:auth.registration.verification.fail');
            if (err?.response?.data) {
                status = t(`common:${err?.response?.data?.message}`);
            }
            success = false
            this.setState({ status, success });
        });

    }

    componentDidUpdate(prevProps) {}

    ResendEmail = () => {
        let { t } = this.props;
        let { success } = this.state;
        if (success) {
            return null;
        } else {
            return <Link to='/registration' style={{ width: '100%' }}>{t('common:auth.login.registration')}</Link>;
        }
    }

    passwordValidity = (form, fieldValidate, message) => {
        if (message) {
            form.password.setCustomValidity(message);
            form.password_confirm.setCustomValidity(message);
            fieldValidate['password'] = message;
            fieldValidate['password_confirm'] = message;
        } else {
            form.password.setCustomValidity("");
            form.password_confirm.setCustomValidity("");
        }
    }

    additionalValidity = (form) => {
        let { fieldValidate } = this.state;
        fieldValidate = {};

        if (form.password.value === '' || form.password_confirm.value === '') {
            this.passwordValidity(form, fieldValidate, "message.input.required");
        } else if (form.password.value !== form.password_confirm.value) {
            this.passwordValidity(form, fieldValidate, "message.errors.password.validate.not-match");
        } else {
            if (form.password.value.length < 12) {
                this.passwordValidity(form, fieldValidate, "message.errors.password.validate.length-too-short");
            } else if (form.password.value.length > 32) {
                this.passwordValidity(form, fieldValidate, "message.errors.password.validate.length-too-long");
            } else if (!(/[A-Z]/).test(form.password.value)) {
                this.passwordValidity(form, fieldValidate, "message.errors.password.validate.wrong-policy");
            } else if (!(/[a-z]/).test(form.password.value)) {
                this.passwordValidity(form, fieldValidate, "message.errors.password.validate.wrong-policy");
            } else if (!(/[0-9]/).test(form.password.value)) {
                this.passwordValidity(form, fieldValidate, "message.errors.password.validate.wrong-policy");
            } else if (!(/!|@|#|&|%|,|_|~|<|>|=|\$|\^|\*|\(|\)|\?|\.|\||\[|\]|\{|\}|-|\+|\//).test(form.password.value)) {
                this.passwordValidity(form, fieldValidate, "message.errors.password.validate.wrong-policy");
            } else if ((/`|;|:|"|'|\\/).test(form.password.value)) {
                this.passwordValidity(form, fieldValidate, "message.errors.password.validate.wrong-policy");
            }
        }
        this.setState({ fieldValidate });

        return !(Object.keys(fieldValidate).length > 0)
    }

    onChangeHandler = (event) => {
        let key = event.target.name;
        let val = event.target.value;
        let { formData } = this.state;
        formData[key] = val;
        this.setState({ formData });
    }

    formSubmitHandler = (ev) => {
        ev.preventDefault();

        let { t } = this.props;
        let { formValidate, verifyPasswordStatus } = this.state;
        formValidate = true;
        this.setState({ formValidate });

        let form = ev.target;
        form.password.setCustomValidity("");
        form.password_confirm.setCustomValidity("");

        if (ev.target.checkValidity() === false) {
            ev.stopPropagation();
        } else if (this.additionalValidity(form) === false) {
            ev.stopPropagation();
        } else {
            verifyPasswordStatus = t('common:auth.registration.verification.password-processing');
            this.setState({ processing: true, failure: false, verifyPasswordStatus }, () => {
                let { token, formData, verifyPasswordStatus } = this.state;
                AuthService.RegisterVerificationPassword({
                    token: token,
                    password: formData.password,
                    password_confirm: formData.password_confirm
                }).then(resp => {
                    verifyPasswordStatus = t('common:auth.registration.verification.success');
                    this.setState({ verifyPasswordStatus, processing: false });
                }).catch(err => {
                    verifyPasswordStatus = t('common:auth.registration.verification.password-fail');
                    this.setState({
                        failure: true,
                        processing: false,
                        success: false,
                        verifyPasswordStatus
                    });
                });
            });
        }
    };

    TryAgainButton = () => {
        let { t } = this.props;
        let { failure } = this.state;
        let {ResendEmail} = this;

        if (failure) {
            return (
                <div className="form-bottom">
                    <div className="wrapper-button-cancel">
                        <ResendEmail />
                    </div>
                    <div className="wrapper-button-submit">
                        <Button
                            variant="primary"
                            onClick={() => {
                                this.setState({
                                    verifyPasswordStatus: null,
                                    formValidate: false,
                                    failure: false,
                                    processing: false,
                                });
                            }}
                        >
                            {t('common:auth.registration.processing.try-again')}
                        </Button>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="form-bottom">
                    <div className="wrapper-button-cancel">
                        <ResendEmail />
                    </div>
                    <div className="wrapper-button-submit">
                        <Link to='/login' style={{ width: '100%' }}>{t('common:auth.registration.success.goto-login')}</Link>
                    </div>
                </div>
            );
        }

    }

    StatusMessage = () => {
        let { verifyPasswordStatus, processing } = this.state;

        if (processing) {
            return <Loading />;
        }

        return (
            <p>{verifyPasswordStatus}</p>
        );
    }

    ResultBody = () => {
        let { t } = this.props;
        let { TryAgainButton, StatusMessage } = this;

        return (
            <div className="form-container">
                <div className="fields-container">
                    <StatusMessage />
                </div>

                <TryAgainButton/>
            </div>
        );
    }

    FormBody = () => {
        let { t } = this.props;
        let { formValidate, formData } = this.state;

        let togglePasswordVisibility = (type) => {
            this.setState((prevState) => {
                switch (type) {
                    case "password":
                        return {passwordVisible: !prevState.passwordVisible}
                    case "confirmedPassword":
                        return {confirmedPasswordVisible: !prevState.confirmedPasswordVisible}
                }
            })
        };

        return (
            <div className="form-container">
                <div className="header-info">
                    <h5>{t('common:auth.registration.verification.verify-password')}</h5>
                </div>

                <Form
                    validated={formValidate}
                    id={"form_password_recovery"}
                    onSubmit={this.formSubmitHandler}
                    noValidate
                >
                    <div className="fields-container">
                        <Form.Row>
                            <Form.Group
                                as={Col}
                                controlId="password"
                                className={`custom-form-outline ${formData.password ? 'has-value' : 'empty-value'}`}
                            >
                                <Form.Label>{t("common:auth.general.password")}</Form.Label>
                                <div className={"password-input"}>
                                    <Form.Control
                                        name='password'
                                        type={this.state.passwordVisible ? 'text' : 'password'}
                                        placeholder={t("common:auth.general.password")}
                                        value={formData.password || ""}
                                        onChange={this.onChangeHandler}
                                        required
                                    />
                                    <span className="password-toggle" onClick={() => togglePasswordVisibility("password")}>
                                      {this.state.passwordVisible ? <VisibilityIcon/> : <VisibilityOff/>}
                                    </span>
                                </div>
                                <Form.Control.Feedback type="invalid">
                                    {t('common:message.input.required', { field_name: t("common:auth.general.password") })}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row>

                        <Form.Row>
                            <div controlId="password_message" className="custom-form-outline col">
                                <div className="password_message">
                                    {t("common:message.general.password-input-rule")}
                                </div>
                            </div>
                        </Form.Row>

                        <Form.Row>
                            <Form.Group
                                as={Col}
                                controlId="password_confirm"
                                className={`custom-form-outline ${formData.password_confirm ? 'has-value' : 'empty-value'}`}
                            >
                                <Form.Label>{t("common:auth.registration.user.comfirm-password")}</Form.Label>
                                <div className={"password-input"}>
                                    <Form.Control
                                        name='password_confirm'
                                        type={this.state.confirmedPasswordVisible ? 'text' : 'password'}
                                        placeholder={t("common:auth.registration.user.comfirm-password")}
                                        value={formData.password_confirm || ""}
                                        onChange={this.onChangeHandler}
                                        required
                                    />
                                    <span className="password-toggle" onClick={() => togglePasswordVisibility("confirmedPassword")}>
                                      {this.state.confirmedPasswordVisible ? <VisibilityIcon/> : <VisibilityOff/>}
                                    </span>
                                </div>
                                <Form.Control.Feedback type="invalid">
                                    {t('common:message.input.required', { field_name: t("common:auth.registration.user.comfirm-password") })}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row>
                    </div>

                    <div className="form-bottom">
                        <div className="wrapper-button-cancel">
                        </div>
                        <div className="wrapper-button-submit">
                            <Button variant="primary" type='submit'>
                                <span>{t('common:auth.general.submit')}</span>
                            </Button>
                        </div>
                    </div>

                </Form>
            </div>
        );
    }

    BodyContent = () => {
        let { verifyPasswordStatus } = this.state;
        let { FormBody, ResultBody } = this;

        if (verifyPasswordStatus) {
            return <ResultBody />;
        }

        return <FormBody />;
    }

    render() {

        let { t } = this.props;
        let { status, need_password } = this.state;

        let { ResendEmail, BodyContent } = this;

        return (
          <div className="auth-contrainer">
                <div className="auth contrainer-wrapper horizontal">
                    <div className="contrainer-block">

                        <div className="form-wrapper registration" style={{ "width": "600px" }}>

                            <div className="logo-horizontal">
                                <img src={logo} className="App-logo" alt="paperlogic" />
                            </div>

                            <div className="form-header">
                                <div className="header-info">
                                    <h3>{t('common:auth.registration.verification.header')}</h3>
                                </div>
                            </div>

                            {need_password ? (
                                <BodyContent/>
                            ) : (
                                <div className="form-container">
                                    <div className="fields-container">

                                        <p>{status}</p>

                                    </div>

                                    <div className="form-bottom">
                                        <div className="wrapper-button-cancel">
                                            <ResendEmail />
                                        </div>
                                        <div className="wrapper-button-submit">
                                            <Link to='/login' style={{ width: '100%' }}>{t('common:auth.registration.success.goto-login')}</Link>
                                        </div>
                                    </div>
                                </div>
                            )}

                        </div>

                    </div>

                    <div className="footer">
                        <div>© {new Date().getFullYear()} paperlogic.co.jp</div>
                    </div>
                </div>

            </div >
        );
    }

}
export default withTranslation()(Verification);