// @ts-nocheck
import { concatMap, from, Subject, takeUntil } from "rxjs";
import { getNewDetailAndSetState } from "../data-access/actions";
import { createFile, generateMultiplePresignedUpload, getFoldersTree, updateUploadedFolder, uploadToS3 } from "../data-access/api";
import { breabCrumbState, driveFoldersDetail, driveFoldersNav, folderDetailSearch, notificationAlertState, progessUploadFileGlobal } from "../data-access/state";
import { getAllFileEntries, supportsFileSystemAccessAPI, supportsWebkitGetAsEntry } from "./handle-file-directory";
import mappedDataToTreeView from "./mappedDataToTreeView";
const completeSubject = new Subject();
const generatePresingedSubject = new Subject();
const uploadSyncSubject = new Subject();
const destroyGeneratePresingedSubject = new Subject();
const destroyCompleteSubject = new Subject();
const destroyUploadSubject = new Subject();
const uploadSyncStream = uploadSyncSubject.asObservable().pipe(takeUntil(destroyUploadSubject));
const generatePresingedStream = generatePresingedSubject.asObservable().pipe(takeUntil(destroyGeneratePresingedSubject));
const completeStream = completeSubject.asObservable().pipe(takeUntil(destroyCompleteSubject));

//Generate presigned url and send generated url to completeStream 
function readFileAndGenPresigned(presingedGenerate, fileUpload, t, currentType, index, uploadFolderList, isOnlyFiles) {
    return new Promise((resolve, reject) => {
        generateMultiplePresignedUpload(presingedGenerate)
            .then((r) => {
                if (r?.data?.payload?.length >= 0) {
                    from(r.data.payload)
                        .pipe(
                            concatMap((b) =>
                                from(
                                    uploadToS3(
                                        b.url,
                                        fileUpload[b.id],
                                        b.file_type,
                                        // progessCallback
                                    )
                                )
                            )
                        )
                        .subscribe({
                            next: (fromData) => {
                                progessUploadFileGlobal.complete.set(
                                    (v) => v + 1
                                );
                            },
                            error: (e) => {
                                notificationAlertState.set({
                                    show: true,
                                    type: "error",
                                    message: `${t(
                                        "common:drive.message.error.upload",
                                        "Upload error please try again!"
                                    )} ${e?.response?.data?.message || e}`,
                                });
                                progessUploadFileGlobal.complete.set(0);
                                progessUploadFileGlobal.totalFile.set(0);
                                destroyGeneratePresingedSubject.next(true);
                                destroyCompleteSubject.next(true);
                                destroyUploadSubject.next(true);
                            },
                            complete: () => {
                                const updatedList = [
                                    ...uploadFolderList,
                                    ...r.data.payload,
                                ].reduce((arr, item) => {
                                    arr.push({
                                        id: item.id,
                                        name: item.name,
                                        isFolder: item.isFolder,
                                        parent_id: item?.parent_id,
                                        path: item.path,
                                        extension: item.extension,
                                        type: currentType,
                                        size: item.size || 0,
                                        url: item.url ? item.url.split("?")[0] : null,
                                    });
                                    return arr;
                                }, []);
                                if (isOnlyFiles) {
                                    //Handle files upload at root drag drop
                                    r.data.payload.forEach(element => {
                                        uploadSyncSubject.next({
                                            isOnlyFiles: true,
                                            file: element,
                                            index,
                                        });
                                    });
                                    resolve(true);
                                    return;
                                }

                                // send updated list with presigned url to complete stream (for handle update data in database)
                                completeSubject.next({
                                    filesUploaded: updatedList,
                                    index,
                                });
                                resolve(true);
                            },
                        });
                }
            })
            .catch((e) => {
                notificationAlertState.set({
                    show: true,
                    type: "error",
                    message: `${t(
                        "common:drive.message.error.upload",
                        "Upload error please try again!"
                    )} ${e?.response?.data?.message || e}`,
                });
                progessUploadFileGlobal.complete.set(0);
                progessUploadFileGlobal.totalFile.set(0);
                destroyGeneratePresingedSubject.next(true);
                destroyCompleteSubject.next(true);
                destroyUploadSubject.next(true);
                reject(e);
            });
    })

}

function folderListHandle(folderList, t, currentType) {
    for (let index = 0; index < folderList.length; index++) {
        const directories = folderList[index];
        const presingedGenerate = [];
        const fileUpload = {};
        // const fileAtRoot = [];
        const uploadFolderList = directories.reduce((arr, item) => {
            if (item.isFolder) {
                arr.push(
                    {
                        url: null,
                        id: item.id,
                        name: item.name,
                        parent_id: item.parent,
                        isFolder: true,
                        size: null,
                        type: currentType,
                        path: item.path,
                    }
                )
            }
            return arr;
        }, []);
        if (directories.length === 1 && directories[0].isFolder === true) {
            completeSubject.next({
                filesUploaded: uploadFolderList,
                index,

            });
            continue;
        }
        const lengthDirectoriesFiles = directories.filter(b => !b.isFolder && b.name);
        for (let i = 0; i < directories.length; i++) {
            const a = directories[i];
            // console.log(a.isFolder);
            if (!a.isFolder) {
                handleFileEntry(a, fileUpload, presingedGenerate, lengthDirectoriesFiles, index, uploadFolderList);
            }
        }
    }

}

function handleFileEntry(a, fileUpload, presingedGenerate, lengthDirectoriesFiles, index, uploadFolderList, isOnlyFiles) {
    a.file.file((f) => {
        if (a.name) {
            fileUpload[a.id] = f;
            presingedGenerate.push({
                id: a.id,
                isFolder: a.isFolder,
                name: a.name,
                parent_id: a.parent,
                path: a.path,
                size: f.size,
                extension: f.name.split(".").pop(),
                file_tail: f.name.split(".").pop(),
                file_type: !f.type ? "binary/octet-stream" : f.type,
            });
        }
        if (isOnlyFiles) {
            generatePresingedSubject.next({
                presingedGenerate: [{
                    id: a.id,
                    isFolder: a.isFolder,
                    name: a.name,
                    parent_id: a.parent,
                    path: a.path,
                    size: f.size,
                    extension: f.name.split(".").pop(),
                    file_tail: f.name.split(".").pop(),
                    file_type: !f.type ? "binary/octet-stream" : f.type,
                }],
                index,
                uploadFolderList: [],
                fileUpload,
                isOnlyFiles: true
            });
            return
        }
        if (presingedGenerate.length === lengthDirectoriesFiles.length) {
            // completeSubject.next({
            //     filesUploaded: presingedGenerate,
            //     index,
            //     uploadFolderList
            // });
            generatePresingedSubject.next({
                presingedGenerate,
                index,
                uploadFolderList,
                fileUpload
            });
            // readFileAndGenPresigned(presingedGenerate, fileUpload, t, currentType, index, uploadFolderList);
        }
    });
}

//call api create files
function createFilesCb(file, currentType, folderId, folderType, isComplete) {
    return new Promise((resolve, reject) => {
        createFile({
            title: "",
            name: file.name,
            url: file.url.split("?")[0],
            size: file.size,
            extension: file.extension,
            type: currentType,
            drive_folder_id: +(folderId || 0) || null,
        }).then(r => {
            if (!r) reject(false);
            if (isComplete) {
                const tree = driveFoldersNav
                    .get({ stealth: true, noproxy: true })
                    .findIndex((b) => b.id == folderId);
                // console.log(tree);
                // if (treeIdx > -1) {
                // }
                if (tree > -1) {
                    driveFoldersNav[tree].set((c) => {
                        return {
                            ...c,
                            is_empty: 0,
                            notShowDropDownIcon: 0,
                        };
                    });
                }
                folderDetailSearch.page_number.set(1);

                getFoldersTree({
                    page_number: 1,
                    page_size: 100,
                    parent_id: folderId || "null",
                }).then((r5) => {
                    if (r5.data.payload.length > 0) {
                        driveFoldersNav.set((v) => {
                            const newData = [
                                ...v,
                                ...r5.data.payload.map(
                                    mappedDataToTreeView
                                ),
                            ].reduce((arr, item) => {
                                if (!arr.find((b) => b.id == item.id)) {
                                    arr.push(item);
                                }
                                return arr;
                            }, []).sort(function (a, b) {
                                return (a.updated_date > b.updated_date) ? -1 : ((a.updated_date < b.updated_date) ? 1 : 0);
                            });
                            // console.log("okokok", newData, v);
                            return newData;
                        });
                    }
                });
                if (
                    !driveFoldersDetail.data[
                        folderId ? folderId : "root"
                    ]?.get({
                        stealth: true,
                    })?.length
                ) {
                    driveFoldersDetail.data.merge({
                        [folderId ? folderId : "root"]: [],
                    });
                }

                getNewDetailAndSetState(folderId, folderType);
                destroyUploadSubject.next(true);
                destroyGeneratePresingedSubject.next(true)
                destroyCompleteSubject.next(true);
                progessUploadFileGlobal.complete.set(0);
                progessUploadFileGlobal.totalFile.set(0);
            }
            resolve(true)
        }).catch(e => reject(e))
    })
}

//event handler for update data to database after upload to s3 is complete
function subscribeToUploadSync(folderId, t, folderType, folderList, filesList, currentType, cbNext, cbComplete) {
    // console.log(folderList, filesList)
    uploadSyncStream.pipe(
        concatMap(({ updatedList, index, isOnlyFiles, file }) => {
            // check if is update folder data to database or files data
            return !isOnlyFiles ?
                from(updateUploadedCall(updatedList, folderId, t, folderType, index === (folderList.length + filesList.length) - 1)) :
                from(createFilesCb({
                    title: "",
                    name: file.name,
                    url: file.url.split("?")[0],
                    size: file.size,
                    extension: file.file_tail,
                    type: currentType,
                    drive_folder_id: +(folderId || 0) || null,
                }, currentType, folderId, folderType, index === (folderList.length + filesList.length) - 1))
        })
    ).subscribe({
        next: (r) => {
            // update the progess
            if (r) {
                progessUploadFileGlobal.complete.set(
                    (v) => v + 1
                );
            }
            if (cbNext) cbNext();
        },
        complete: () => {
            // check if it still have files to upload. do not close snackbar
            if (!cbNext) {
                progessUploadFileGlobal.complete.set(0);
                progessUploadFileGlobal.totalFile.set(0);
            }
            if (cbComplete) cbComplete();
        }
    });
}

export default async function handleUploadOnDrop(item, t, folderId, folderType) {
    const apiSubject = new Subject();
    const checkEntry = [];
    for (let i = 0; i < item.dataTransfer.items.length; i++) {
        // Note webkitGetAsEntry a non-standard feature and may change
        // Usage is necessary for handling directories
        let item2 = item.dataTransfer.items[i];
        if (
            (supportsFileSystemAccessAPI
                ? item2.getAsFileSystemHandle()
                : supportsWebkitGetAsEntry
                    ? item2.webkitGetAsEntry()
                    : item2.getAsFile()
            ).isDirectory
        ) {
            checkEntry.push(item2);
        }
    }
    const [fileEntries, folderList, filesList] = await getAllFileEntries(
        item.dataTransfer.items
    );

    // console.log(fileEntries, folderList, filesList);
    destroyUploadSubject.next(true);
    destroyGeneratePresingedSubject.next(true);
    destroyCompleteSubject.next(true);
    const currentType =
        breabCrumbState
            .get({ stealth: true, noproxy: true })
            ?.find((a) => a.drive_folder_id === folderId)?.type || 8;
    // return;
    const totalFolderAndFiles = folderList.reduce((total, a) => total + a.length, 0)
    progessUploadFileGlobal.totalFile.set(totalFolderAndFiles + filesList.length);
    // console.log(totalFolderAndFiles + filesList.length)
    progessUploadFileGlobal.complete.set(0);
    generatePresingedStream.pipe(concatMap(({
        presingedGenerate,
        index,
        uploadFolderList,
        fileUpload,
        isOnlyFiles
    }) => from(readFileAndGenPresigned(presingedGenerate, fileUpload, t, currentType, index, uploadFolderList, isOnlyFiles)))).subscribe();
    let cbComplete = () => {
        const presingedGenerate = [];
        const fileUpload = {};
        destroyUploadSubject.next(true);
        subscribeToUploadSync(folderId, t, folderType, folderList, filesList, currentType);
        // console.log(filesList);
        for (let index = 0; index < filesList.length; index++) {
            const a = filesList[index];
            handleFileEntry(a, fileUpload, presingedGenerate, 1, folderList.length + index, undefined, true);
        }
    }
    if (filesList.length === 0) {
        cbComplete = null;
    }
    subscribeToUploadSync(folderId, t, folderType, folderList, filesList, currentType, cbComplete);
    completeStream.subscribe({
        next: ({ filesUploaded, index }) => {
            // const newFolderList = [...uploadFolderList]
            // console.log('run here', filesUploaded);
            const updateList = filesUploaded.map(a => ({
                "id": a.id,
                "name": a.name,
                "isFolder": a.isFolder,
                "parent_id": a.parent_id,
                "path": a.path,
                "extension": a.extension,
                "type": a.type,
                "size": a.size,
                "url": a.url,
            }));
            uploadSyncSubject.next({
                updatedList: updateList,
                index
            });
        }
    });

    folderListHandle(folderList, t, currentType);
    return
}

function updateUploadedCall(listUploadedChange, folderId, t, folderType, isComplete) {
    return new Promise((resolve, reject) => {
        updateUploadedFolder(
            listUploadedChange,
            folderId || "null"
        )
            .then((r) => {
                if (isComplete) {
                    const tree = driveFoldersNav
                        .get({ stealth: true, noproxy: true })
                        .findIndex((b) => b.id == folderId);
                    // console.log(tree);
                    // if (treeIdx > -1) {
                    // }
                    if (tree > -1) {
                        driveFoldersNav[tree].set((c) => {
                            return {
                                ...c,
                                is_empty: 0,
                                notShowDropDownIcon: 0,
                            };
                        });
                    }
                    folderDetailSearch.page_number.set(1);

                    getFoldersTree({
                        page_number: 1,
                        page_size: 100,
                        parent_id: folderId || "null",
                    }).then((r5) => {
                        if (r5.data.payload.length > 0) {
                            driveFoldersNav.set((v) => {
                                const newData = [
                                    ...v,
                                    ...r5.data.payload.map(
                                        mappedDataToTreeView
                                    ),
                                ].reduce((arr, item) => {
                                    if (!arr.find((b) => b.id == item.id)) {
                                        arr.push(item);
                                    }
                                    return arr;
                                }, []).sort(function (a, b) {
                                    return (a.updated_date > b.updated_date) ? -1 : ((a.updated_date < b.updated_date) ? 1 : 0);
                                });
                                // console.log("okokok", newData, v);
                                return newData;
                            });
                        }
                    });
                    if (
                        !driveFoldersDetail.data[
                            folderId ? folderId : "root"
                        ]?.get({
                            stealth: true,
                        })?.length
                    ) {
                        driveFoldersDetail.data.merge({
                            [folderId ? folderId : "root"]: [],
                        });
                    }

                    getNewDetailAndSetState(folderId, folderType);
                    destroyUploadSubject.next(true);
                    destroyGeneratePresingedSubject.next(true)
                    destroyCompleteSubject.next(true);
                }

                resolve(true);
            })
            .catch((e) => {
                notificationAlertState.set({
                    show: true,
                    type: "error",
                    message: `${t(
                        "common:drive.message.error.upload",
                        "Upload error please try again!"
                    )} ${e?.response?.data?.message || e}`,
                });
                progessUploadFileGlobal.complete.set(0);
                progessUploadFileGlobal.totalFile.set(0);
                destroyGeneratePresingedSubject.next(true);
                destroyCompleteSubject.next(true);
                destroyUploadSubject.next(true);
                reject(e);
            });
    })
}