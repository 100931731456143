// @ts-nocheck
import { useHookstate } from "@hookstate/core";
import AddIcon from "@mui/icons-material/Add";
import RecyclingRoundedIcon from "@mui/icons-material/RecyclingRounded";
import {
  Button,
  CircularProgress,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import MaterialSymbolIcon from "../../../Pages/Components/material-symbol/material-symbol";
import { currentFolderTypeState } from "../../data-access/state";
import DriveShareDialog from "../drive-dialogs/drive-share-dialog";
/**
 * @param {{ downloadButtonCallback?: functioncallback;
 * shareButtonCallback?: functioncallback;
 * deleteButtonCallback?: functioncallback;
 * recycleCallback? : functioncallback;
 * progess?: number;
 * isCompanyFolder: boolean;
 * createButtonCallback?: functioncallback; }} props
 */
export default function DriveActionsBtn(props) {
  const { t } = useTranslation("common");
  const anchorEl = useHookstate(undefined);
  const open = Boolean(anchorEl.get());
  const openShareDialog = useHookstate(false);
  const currentFolderType = useHookstate(currentFolderTypeState);
  // @ts-ignore
  const handleClick = (event) => {
    anchorEl.set(event.currentTarget || undefined);
  };
  const handleClose = () => {
    anchorEl.set(undefined);
  };
  return (
    <div className="drive_actions_btn">
      {props.progess ? (
        <CircularProgress
          sx={{ width: 24, height: 24 }}
          variant="determinate"
          value={props.progess}
        />
      ) : (
        <div></div>
      )}

      {props.downloadButtonCallback ? (
        <MaterialSymbolIcon
          tooltip={t("common:drive.button.download_selected")}
          style={
            !props.createButtonCallback
              ? {
                  gridColumn: "span 6 / span 6",
                }
              : {}
          }
          onClick={props.downloadButtonCallback}
          iconName={"download"}
        />
      ) : props.createButtonCallback ? (
        <div></div>
      ) : null}
      {props.shareButtonCallback ? (
        <MaterialSymbolIcon
          style={
            !props.createButtonCallback
              ? {
                  gridColumn: "span 6 / span 6",
                }
              : {}
          }
          tooltip={t("common:drive.button.share_selected")}
          onClick={props.shareButtonCallback}
          iconName={"send"}
        />
      ) : props.createButtonCallback ? (
        <div></div>
      ) : null}
      {props.deleteButtonCallback ? (
        <MaterialSymbolIcon
          onClick={props.deleteButtonCallback}
          style={
            !props.createButtonCallback
              ? {
                  gridColumn: "span 3 / span 3",
                  textAlign: "end",
                }
              : {}
          }
          iconName={"delete"}
          tooltip={t("common:drive.button.delete_selected")}
        />
      ) : props.createButtonCallback ? (
        <div></div>
      ) : null}

      {props.recycleCallback ? (
        <MaterialSymbolIcon
          tooltip={t("common:drive.button.recycle")}
          style={
            !props.createButtonCallback
              ? {
                  gridColumn: "span 3 / span 3",
                  textAlign: "end",
                }
              : {}
          }
          onClick={props.recycleCallback}
          iconName={"recycling"}
        />
      ) : null}
      {props.createButtonCallback ? (
        <>
          <Button
            size="small"
            onClick={handleClick}
            variant="outlined"
            endIcon={<AddIcon />}
          >
            {t("common:drive.button.create")}
            {/* 新規 */}
          </Button>
          <Menu
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl.get()}
            open={open}
            sx={{
              zIndex: 13000
            }}
            onClose={handleClose}
          >
            {props.isCompanyFolder ? null : (
              <MenuItem
                onClick={(e) => {
                  handleClose();
                  return props?.createButtonCallback(e, "uploadFile");
                }}
              >
                {/* <UploadFileIcon /> */}
                <MaterialSymbolIcon iconName={"upload_file"} />
                {t("common:drive.button.menu.upload_file")}
                {/* ファイルアップロード */}
              </MenuItem>
            )}
            {props.isCompanyFolder ? null : (
              <MenuItem
                onClick={(e) => {
                  handleClose();
                  return props?.createButtonCallback(e, "uploadFolder");
                }}
              >
                <MaterialSymbolIcon iconName={"drive_folder_upload"} />
                {t("common:drive.button.menu.upload_folder")}
                {/* フォルダアップロード */}
              </MenuItem>
            )}
            <MenuItem
              onClick={(e) => {
                handleClose();
                return props?.createButtonCallback(e, "createFolder");
              }}
            >
              <MaterialSymbolIcon iconName={"folder"} />
              {t("common:drive.button.menu.create_folder")}
              {/* フォルダ作成 */}
            </MenuItem>
          </Menu>
        </>
      ) : null}
      <DriveShareDialog
        open={openShareDialog.get()}
        onClose={() => openShareDialog.set(false)}
      />
    </div>
  );
}
