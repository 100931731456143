import PersonIcon from '@mui/icons-material/Person';
import Avatar from '@mui/material/Avatar';
export default function ProfileAvatar(props) {

    let avatar = props.avatar
    let size = props.size

    if (props.hasOwnProperty("avatar") && avatar !== "null" && avatar !== undefined) {
        //console.log(props.avatar);
        return (
            <Avatar sx={size} src={avatar} {...props} />
        )
    } else if (props.hasOwnProperty("icon")) {
        return (
            <Avatar {...props}>
                <props.icon />
            </Avatar>
        )
    } else {
        return (
            <Avatar {...props}>
                <PersonIcon />
            </Avatar>
        )
    }
}
