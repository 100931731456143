import React from "react";
import { userInfoState } from "../../drive-feature/data-access/state";
import AuthService from "../../Service/Auth";

class Logout extends React.Component {
  async componentDidMount() {
    await AuthService.Logout();
    userInfoState.set(null);
    localStorage.removeItem("user-info");
    localStorage.removeItem("defaultVerification");
    localStorage.removeItem('prev_host_signer_list');
    localStorage.removeItem('prev_viewer_list');
    localStorage.removeItem('prev_verification_viewer_list');
    localStorage.removeItem('prev_use_digital_certificate');
    // this.props.history.go("/login");
  }

  render() {
    return <></>;
  }
}

export default Logout;
