// @ts-nocheck
import { useHookstate } from "@hookstate/core";
import { LoadingButton } from "@mui/lab";
import { Button, Divider, TextField, Tooltip, Typography } from "@mui/material";
import { useCallback } from "react";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { usePapaParse } from "react-papaparse";
import BaseTooltipPopover from "../../Components/base-tooltip-customize/base-tooltip-customize";
import { shedOnScroll } from "../../data-access/app.state";
import {
  csvUploadAndSearch,
  searchBusinessNumber,
} from "../../data-access/business-number/business-number.api";
import BaseDialogDriveCrud from "../../drive-feature/components/drive-dialogs/drive-base-dialog";
import LayoutHookVer from "../Components/layout-hook/layout-hook";
export const businessDataResourceMapping = (t, field, value) => {
  const config = {
    process: {
      "01": t("common:business.number.process.config.one"),
      "02": t("common:business.number.process.config.two"),
      "03": t("common:business.number.process.config.three"),
      "04": t("common:business.number.process.config.four"),
      99: t("common:business.number.process.config.ninetynine"),
    },
    correct: {
      0: t("common:business.number.correct.config.zero"),
      1: t("common:business.number.correct.config.one"),
      null: t("common:business.number.correct.config.none"),
    },
    kind: {
      1: t("common:business.number.kind.config.one"),
      2: t("common:business.number.kind.config.two"),
    },
    country: {
      1: t("common:business.number.country.config.one"),
      2: t("common:business.number.country.config.two"),
      3: t("common:business.number.country.config.three"),
    },
    latest: {
      0: t("common:business.number.latest.config.zero"),
      1: t("common:business.number.latest.config.one"),
    },
  };

  return config[field] ? config[field][value] : value;
};
function CsvUploadDialog({
  loading,
  open,
  handleClose,
  handleSubmit,
  onFileChange,
  //   filesSelected,
}) {
  const { t } = useTranslation("common");

  return (
    <BaseDialogDriveCrud
      open={open}
      handleClose={handleClose}
      maxWidth="sm"
      className="dialog_business"
      title={t("common:business.number.dialog.title")}
      dialogContentComponent={
        <div>
          <p>{t("common:business.number.dialog.content_select_csv_header")}</p>
          <input
            type="file"
            id="avatar"
            name="avatar"
            accept=".csv"
            // value={filesSelected}
            onChange={onFileChange}
          />
        </div>
      }
      dialogActionComponent={
        <div>
          <Button variant="outlined" onClick={handleClose}>
            {t("common:general.cancel")}
          </Button>
          <LoadingButton
            loading={loading}
            variant="contained"
            onClick={handleSubmit}
          >
            {t("common:general.ok")}
          </LoadingButton>
        </div>
      }
    />
  );
}
function Field({ type, value, fromCsv, color }) {
  const { t } = useTranslation("common");
  switch (type) {
    case "status":
    case "exist":
      if (value === -1) {
        return (
          <span className="activeFlag_error">
            {t(`common:business.number.status.server_error`)}
          </span>
        );
      }
      return value ? (
        <span className="activeFlag_active">
          {t(`common:business.number.status.active`)}
        </span>
      ) : (
        <span
          className={!fromCsv ? "activeFlag_inactive" : "activeFlag_warning"}
        >
          {t(`common:business.number.status.inactive`)}
        </span>
      );
    case "error_list":
      return (
        <Typography color={color} variant="body2">
          {value && value.join(", ")}
        </Typography>
      );
    default:
      return (
        <Typography color={color} variant="body2">
          {value}
        </Typography>
      );
  }
}
export function BusinessDetailField({
  field_name,
  value,
  type,
  className,
  fromCsv,
  color,
}) {
  const { t } = useTranslation("common");
  return (
    <div className={className}>
      <Typography variant="subtitle2" fontWeight={"bold"}>
        {t(`common:business.detail.field.${field_name}`)}
        {className === "business_info_data_field" ? "" : ""}
      </Typography>
      <Field
        type={type}
        color={color}
        value={type === "detail_error" ? t(`common:${value}`) : value}
        fromCsv={fromCsv}
      />
    </div>
  );
}

export function DetailBusinessSearch(props) {
  const { data, ok, message, payloadErr } = props;
  const { t } = useTranslation("common");
  //   console.log(data);
  if (!ok) {
    return (
      <>
        <BusinessDetailField field_name={"status"} type={"status"} value={-1} />

        <BusinessDetailField
          field_name={"detail_error"}
          type={"detail_error"}
          color={"rgb(223, 90, 45)"}
          value={message}
        />
        <BusinessDetailField
          field_name={"error_list"}
          type={"error_list"}
          color={"rgb(223, 90, 45)"}
          value={payloadErr}
        />
      </>
    );
  }
  if (!data) return <></>;
  const { mainData, infoData } = data;
  return (
    <>
      <div className="business_main_field">
        {mainData && mainData.exist === 1 ? (
          Object.keys(mainData).map((a, i) => (
            <BusinessDetailField
              key={i}
              field_name={a}
              type={a}
              value={businessDataResourceMapping(t, a, mainData[a])}
            />
          ))
        ) : (
          <BusinessDetailField
            field_name={"exist"}
            type={"exist"}
            value={mainData.exist}
          />
        )}
      </div>
      {mainData.exist === 1 ? (
        <>
          {/* <Divider /> */}
          <div className="business_info_data">
            {infoData
              ? Object.keys(infoData).map((a, i) =>
                  infoData[a] !== null ? (
                    <BusinessDetailField
                      key={i}
                      className={"business_info_data_field"}
                      field_name={a}
                      type={a}
                      value={businessDataResourceMapping(t, a, infoData[a])}
                    />
                  ) : null
                )
              : null}
          </div>
        </>
      ) : null}
    </>
  );
}
function DetailCsvUpload(props) {
  const {
    mainData,
    infoData,
    isCompanyNotLikeName,
    isPartialExist,
    ok,
    message,
    payloadErr,
  } = props;
  const shed = useHookstate(shedOnScroll);
  const { t } = useTranslation("common");
  if (!ok) {
    return (
      <>
        <BusinessDetailField field_name={"status"} type={"status"} value={-1} />
        <BusinessDetailField
          field_name={"detail_error"}
          type={"detail_error"}
          color={"rgb(223, 90, 45)"}
          value={message}
        />
        <BusinessDetailField
          field_name={"error_list"}
          type={"error_list"}
          color={"rgb(223, 90, 45)"}
          value={payloadErr}
        />
      </>
    );
  }
  if (!infoData) return <></>;
  // console.log(infoData, mainData)

  return (
    <div>
      <div className="business_main_field">
        {mainData
          ? Object.keys(mainData).map((a, i) => {
              if (a === "situation") {
                return (
                  <div key={i}>
                    <BusinessDetailField
                      field_name={a}
                      fromCsv={true}
                      type={a}
                      color={isPartialExist ? "rgb(223, 90, 45)" : null}
                      value={businessDataResourceMapping(t, a, mainData[a])}
                    />
                    {isCompanyNotLikeName ? (
                      <Field
                        type={"check"}
                        className
                        value={t(
                          "common:business.number.field.situation.company_not_like_name"
                        )}
                        color={"rgb(223, 90, 45)"}
                      />
                    ) : null}
                  </div>
                );
              }
              return (
                <>
                  <BusinessDetailField
                    field_name={a}
                    fromCsv={true}
                    type={a}
                    value={businessDataResourceMapping(t, a, mainData[a])}
                  />
                </>
              );
            })
          : null}
      </div>
      {/* <Divider
        sx={{
          marginBottom: "10px",
        }}
      /> */}
      <Table className="business-search-list">
        <thead key={0}>
          <tr className={shed.get({ noproxy: true }) ? "shed" : ""}>
            {Object.keys(infoData[0]).map((a, i) => {
              return (
                <th className="th-action" key={i}>
                  <Tooltip
                    disableInteractive
                    title={t(`common:business.detail.field.${a}`)}
                  >
                    <div>
                      <span>{t(`common:business.detail.field.${a}`)}</span>
                    </div>
                  </Tooltip>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody key={1}>
          {infoData.map((a, idx) => {
            return (
              <tr key={idx}>
                {Object.keys(infoData[0]).map((b, i) => {
                  if (b === "exist" || b === "status") {
                    return (
                      <td className={`business_field_${b}`} key={i}>
                        {a[b] === 1 ? (
                          <span className="activeFlag_active">
                            {t(`common:business.number.status.active`)}
                          </span>
                        ) : (
                          <span className="activeFlag_warning">
                            {t(`common:business.number.status.inactive`)}
                          </span>
                        )}
                      </td>
                    );
                  }
                  if (
                    b === "company_name" &&
                    a[b] !== a.name &&
                    isCompanyNotLikeName &&
                    a.exist === 1
                  ) {
                    return (
                      <td className={`business_field_${b} warning_color`}>
                        <div>
                          <div className="text-ellipsis"> {a[b]}</div>
                        </div>
                      </td>
                    );
                  }

                  return (
                    <Tooltip
                      disableInteractive
                      title={businessDataResourceMapping(t, b, a[b])}
                    >
                      <td
                        className={`business_field_${b} ${
                          a.exist !== 1 &&
                          b === "registrated_number" &&
                          isPartialExist
                            ? "warning_color"
                            : ""
                        }`}
                      >
                        <div>
                          <div className="text-ellipsis">
                            {businessDataResourceMapping(t, b, a[b])}
                          </div>
                        </div>
                      </td>
                    </Tooltip>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
}
export default function BusinessNumberSearch() {
  const { t } = useTranslation("common");
  //   const searchBusiness = ()=>searchBusinessNumber()
  const inputSearch = useHookstate("");
  const businessSearch = useHookstate(null);
  const detailIsOk = useHookstate(true);
  const csvUploadDialog = useHookstate(false);
  const filesSelected = useHookstate(null);
  const errorMessage = useHookstate("");
  const payloadErr = useHookstate(null);
  const submitLoading = useHookstate(false);
  const csvLoading = useHookstate(false);
  const { readRemoteFile } = usePapaParse();

  //   const csvSearch = useHookstate(() => searchBusinessNumberCsv(null));
  const searchTNumber = useCallback(() => {
    if (!inputSearch.get({ stealth: true })) return;
    detailIsOk.set(true);
    submitLoading.set(true);
    errorMessage.set("");
    payloadErr.set("");
    searchBusinessNumber(
      inputSearch
        .get({ stealth: true })
        .split(",")
        .map((a) => `T${a.trim()}`)
        .join(",")
    )
      .then((r) => {
        // console.log(r);
        businessSearch.set(r.data.payload);
      })
      .catch((err) => {
        let errMessage = err?.response?.data?.message_code;
        if (err?.response?.data?.hasOwnProperty("error")) {
          errMessage = err?.response?.data?.error;
        }
        if (
          err?.code === "ECONNABORTED" ||
          (err?.response?.status === 504 &&
            err?.response?.data?.message === "Endpoint request timed out")
        ) {
          errMessage = t(
            "message.error.request.timed_out",
            `サーバーが混雑しております。\n しばらく待ってアクセスしてください。`
          );
          // return;
        }
        errorMessage.set(errMessage || err?.response?.message);
        detailIsOk.set(false);
        payloadErr.set(err?.response?.data?.payload);
      })
      .finally(() => submitLoading.set(false));
  }, []);

  const searchTNumberCsv = useCallback(() => {
    detailIsOk.set(true);
    csvLoading.set(true);
    errorMessage.set("");
    payloadErr.set("");
    csvUploadAndSearch(filesSelected.get({ stealth: true, noproxy: true }))
      .then((r) => {
        console.log("okokok", r);
        csvUploadDialog.set(false);
        filesSelected.set(null);
        payloadErr.set("");
        detailIsOk.set(true);
        businessSearch.set(r.payload);
      })
      .catch((err) => {
        let errMessage = err?.response?.data?.message_code;
        if (
          err?.code === "ECONNABORTED" ||
          (err?.response?.status === 504 &&
            err?.response?.data?.message === "Endpoint request timed out")
        ) {
          errMessage = t(
            "message.error.request.timed_out",
            `サーバーが混雑しております。\n しばらく待ってアクセスしてください。`
          );
          // return;
        }
        errorMessage.set(errMessage || err?.response?.message);
        filesSelected.set(null);
        payloadErr.set(err?.response?.data?.payload);
        detailIsOk.set(false);
        csvUploadDialog.set(false);
      })
      .finally(() => csvLoading.set(false));
  }, []);

  function onCsvUploadDialogClose() {
    csvUploadDialog.set(false);
    filesSelected.set(null);
  }
  function onCsvUploadDialogSubmit() {
    searchTNumberCsv();
  }
  function onFileChange(ev) {
    filesSelected.set(ev.target.files[0]);
    const file = URL.createObjectURL(ev.target.files[0]);
    readRemoteFile(file, {
      header: true,
      complete: (results) => {
        URL.revokeObjectURL(file);
        console.log("---------------------------");
        console.log("Results:", results);
        console.log("---------------------------");
      },
    });
  }
  const isAllExist =
    businessSearch.get() && businessSearch.get()?.every((a) => a?.exist === 1);
  const isCompanyNotLikeName =
    businessSearch.get() &&
    businessSearch.get()?.some((a) => a?.name !== a?.company_name);
  const openHelper = useHookstate({ open: false, anchorEl: null });
  function handleCloseHelper(ev) {
    openHelper.open.set(false);
    openHelper.anchorEl.set(null);
  }
  //   console.log(suspend(businessSearch))
  return (
    <LayoutHookVer onClick={handleCloseHelper}>
      <div className="business_number_search">
        <CsvUploadDialog
          open={csvUploadDialog.get()}
          loading={csvLoading.get()}
          handleClose={onCsvUploadDialogClose}
          handleSubmit={onCsvUploadDialogSubmit}
          onFileChange={onFileChange}
          filesSelected={filesSelected.get()}
        />
        <div className="business_number_search_header">
          <Typography className="business_number_header" variant="h6">
            {t("common:business.number.header")}
          </Typography>
          <div className="business_number_actions">
            <div className="business_search_field">
              <span>T</span>
              <TextField
                className="bg-white"
                size="small"
                fullWidth
                variant="outlined"
                onKeyDown={(ev) => {
                  if (ev.code === "Enter") {
                    searchTNumber();
                  }
                }}
                value={inputSearch.get()}
                onChange={(ev) => inputSearch.set(ev.target.value)}
                type="text"
                placeholder={"1234567890123"}
              />
            </div>
            <LoadingButton
              loading={submitLoading.get()}
              variant="contained"
              onClick={() => searchTNumber()}
            >
              {t("common:general.search")}
            </LoadingButton>
            <BaseTooltipPopover
              onClick={e=>{
                e?.stopPropagation();
              }}
              offsetTop={80}
              className={'business_tooltip_class'}
              open={openHelper.open.get()}
              anchorEl={openHelper.anchorEl.get({ noproxy: true })}
              handleClose={handleCloseHelper}
              menuContent={<div>
                <Typography fontWeight={'bold'} color={'primary'} variant="body1" className="business_tooltip_heading">{t("common:business.number.helper.header")}</Typography>
                <Typography variant="body2">{t("common:business.number.helper.body")}</Typography>
                <Typography variant="body2">{t("common:business.number.helper.body2")}</Typography>
                <Typography variant="body2" className="business_tooltip_caution">{t("common:business.number.helper.notes")}</Typography>
                <img style={{
                  width: '100%',
                  height: 'auto'
                }} src="/icons/helper_ok.png" alt="loading" />
              </div>}
            />
            {/* <Tooltip
              componentsProps={{
                tooltip: {
                  className: "business_number_tooltip_helper",
                },
              }}
              // open={true}
              className=""
              placement="right"
              title={t("common:business.number.tooltip.csv_dialog_helper")}
            > */}
            <Button
              variant="outlined"
              onMouseOver={(ev)=>{
                openHelper.anchorEl.set(ev.target);
                openHelper.open.set(true);
              }}
              className="business_action_btn_csv"
              onClick={() => csvUploadDialog.set(true)}
            >
              {t("common:business.number.button.csv_dialog")}
              {/* CSV一括確認 */}
            </Button>
            {/* </Tooltip> */}
          </div>
        </div>
        <div className="business_number_search_body">
          {businessSearch.get()?.length <= 1 ? (
            businessSearch.get().map((a, i) => {
              return (
                <DetailBusinessSearch
                  key={i}
                  isPartialExist={!isAllExist}
                  data={{
                    mainData: {
                      exist: a.exist,
                      name: a.name,
                      registration_date: a.registration_date,
                      address: a.address,
                    },
                    infoData: {
                      ...a,
                      exist: null,
                      name: null,
                      registration_date: null,
                      address: null,
                    },
                  }}
                  ok={detailIsOk.get()}
                  message={errorMessage.get()}
                  payloadErr={payloadErr.get()}
                />
              );
            })
          ) : (
            <DetailCsvUpload
              ok={detailIsOk.get()}
              message={errorMessage.get()}
              payloadErr={payloadErr.get()}
              isPartialExist={!isAllExist}
              isCompanyNotLikeName={isCompanyNotLikeName}
              mainData={{
                exist: isAllExist ? 1 : 0,
                situation: isAllExist
                  ? t("common:business.number.field.situation.all_exist")
                  : t("common:business.number.field.situation.partial_exist"),
              }}
              infoData={
                businessSearch.get()
                  ? businessSearch.get().map((a) => ({
                      exist: a.exist,
                      registrated_number: a.registrated_number,
                      company_name: a.company_name,
                      //   company_name: a.name || '',
                      name: a.name || "",
                      address: a.address,
                      registration_date: a.registration_date,
                      update_date: a.update_date,
                    }))
                  : null
              }
            />
          )}
        </div>
      </div>
    </LayoutHookVer>
  );
}
