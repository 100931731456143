
import TypeIcon from "../components/drive-nav/type-icon";

/**
 * @param {any} row
 */
export function mappedTableIconByType(row) {
    // console.log(row);
    if (!row) return <></>;
    const rowSplit = row?.fileType !== 'trash' && row?.fileType !== 'folder' ? row.name.split('.') : [];
    return <TypeIcon {...row} fileType={(row?.format_type ? row?.format_type : rowSplit[rowSplit.length - 1] ? rowSplit[rowSplit.length - 1] : 'folder') || 'folder'} droppable={row?.fileType === 'folder'} />
    // switch (fileType) {
    //     case 'folder':
    //         return <FolderIcon />
    //     case 'pdf':
    //         return <PictureAsPdfIcon color="error" />
    //     case 'zip':
    //         return <FolderZipIcon />;
    //     default:
    //         return <InsertDriveFileIcon />
    // }
}