import { hookstate } from "@hookstate/core";

export const driveFoldersCompanyDetail = hookstate({
    page_size: 50,
    page_number: 1,
    total: 0,
    data: {},
});
export const driveNavCompanyTree = hookstate([])

export const selectedFoldersCompany = hookstate(null);

export const currentSelectedCompanyFolderState = hookstate(0);