const EnvParams = {
    web: {
        domain: process.env.REACT_APP_PUBLIC_URL,
    },
    replace_s3: process.env.REACT_APP_REPLACE_PRESIGNED,
    api: {
        domain: process.env.REACT_APP_API_DOMAIN,
        domain_new: process.env.REACT_APP_API_DOMAIN_NEW,
        path: process.env.REACT_APP_API_PATH,
        media: process.env.REACT_APP_API_MEDIA,
        idp: process.env.REACT_APP_API_IDP,
        ec2Url: process.env.REACT_APP_EC2_DRIVE_URL
    },
    cognito: {
        identityPoolId: process.env.REACT_APP_COGNITO_IDENTITYPOOLID,
        region: process.env.REACT_APP_COGNITO_REGION,
        userPoolId: process.env.REACT_APP_COGNITO_USERPOOLID,
        userPoolWebClientId: process.env.REACT_APP_COGNITO_USERPOOLWEBCLIENTID,
        domain: process.env.REACT_APP_COGNITO_DOMAIN,
    }
};
export default EnvParams;
