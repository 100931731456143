// @ts-nocheck
import { withTranslation } from "react-i18next";

import Layout from '../../Layout';

import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Suspense } from 'react';
import {
	Col, Form, InputGroup
} from 'react-bootstrap';
import Loading from '../Loading';

import { hookstate, StateFragment } from "@hookstate/core";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DoDisturbOnOutlinedIcon from '@mui/icons-material/DoDisturbOnOutlined';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { Page, pdfjs } from 'react-pdf';
import DriveLayout from '../../drive-feature/components/drive-layout/drive-layout';
import { getFoldersTree } from '../../drive-feature/data-access/api';
import { currentSelectedCompanyFolderState, selectedFoldersCompany } from '../../drive-feature/data-access/company-folders/company-folders.state';
import { breabCrumbState, currentFolderTypeState, driveDetailLoading, driveFoldersNav } from '../../drive-feature/data-access/state';
import DriveFolders from '../../drive-feature/pages/drive-folders/drive-folders';
import mappedDataToTreeView from "../../drive-feature/utils/mappedDataToTreeView";
import DocumentWithObserver from "./DocumentWithObserver";
import { searchBusinessNumber } from "../../data-access/business-number/business-number.api";
import { CircularProgress } from "@mui/material";
import BaseSuggestionPopover from "../../Components/base-suggestion/base-suggestion";

const currentRangePage = hookstate('1');
class VerificationForm extends Layout {
	constructor(props){
		super(props);
		let tempSelectedFilebox = null;
		this.key = props.baseClass.key;
		this.name = props.baseClass.name;
		this.code = props.baseClass.code;
		this.baseClass = props.baseClass;
		let objMain = props.objMain
		let formData = props.formData
		this.objMain = props.objMain
		if(formData.invoice_number){
			this.checkInvoiceNumber(formData.invoice_number)
		}
        // this['state']['observer'] = initObserver(currentRangePage);

		// this.initDocTitle()
	}

	// initDocTitle(){
	// 	let { creationType, PDFFile } = this.props.objMain.state;
	// 	let { formData } = this.props;
	// 	if (creationType === "verification") {
	// 		let title = PDFFile.name.slice(0, PDFFile.name.lastIndexOf('.'));
	// 		if (formData.doc_title === "") {
	// 			formData.doc_title = title;
	// 			this.props.objMain.setState((prevState, props) => ({
	// 				formData:{...prevState.formData, doc_title: title} 
	// 			}))
	// 			// this.props.objMain.updateState({formData: {...this.props.objMain.state.formData, doc_title: title}});
	// 		}
	// 	}
	// }

	DocumentTypeItems = () => {
		let { t } = this.props;
		let { PageData } = this.props.objMain.state;
		let { doc_types } = PageData;

		let ItemElements = [];
		for (let i in doc_types) {
			let doc_type = doc_types[i];
			ItemElements.push(<option key={doc_type.id} value={doc_type.id}>{t("common:" + doc_type.label)}</option>);
		}

		return ItemElements;
	}

	CounterPartyList = () => {
		let { counterPartyNameList } = this.props.objMain.state;

		let row = [];

		for (let i = 0; i < counterPartyNameList.length; i++) {
			let counterPartyName = counterPartyNameList[i];

			row.push(
				<option key={`${counterPartyName}-${i}`} value={counterPartyName} />
			);
		}
		return (
			<datalist id="counter-party">
				{row}
			</datalist>
		);
	}

	PreservationRequireItems = () => {
		let { t } = this.props;
		let { PageData } = this.props.objMain.state;
		let { preservation_require } = PageData;

		let ItemElements = [];
		for (let i in preservation_require) {
			let preservation_require_item = preservation_require[i];
			ItemElements.push(<option key={preservation_require_item.id} value={preservation_require_item.id}>{t("common:" + preservation_require_item.label)}</option>);
		}

		return ItemElements;
	}

	CurrencyItems = () => {
		let { PageData } = this.props.objMain.state;
		let { currencies } = PageData;
		let OptionItems = [];
		for (let i in currencies) {
			let currency = currencies[i];
			OptionItems.push(<option key={i} value={currency.code}>{currency.label}</option>);
		}
		return OptionItems;
	}

	showFileboxPathSelection = async (ev) => {
		let { t } = this.props;
		let { modalTitle, ModalBody, ModalAction, modalProps } = this.props.objMain.state;
		modalProps = {
			"show": true,
			"dialogClassName": "modal-full zero-padding",
			"centered": true,
		}
		selectedFoldersCompany.set(null);
		modalTitle = "common:documents.document-info.filebox-select-path";
		ModalAction = [];
		ModalBody = Loading;
		ModalAction.push(
			<Button
				key="ok"
				sx={{ ml: 1 }}
				variant="contained" onClick={(ev) => {
					this.props.formData.filebox_shortcut = this.tempSelectedFilebox;
					
					if (!selectedFoldersCompany.get({stealth: true})){
						if(breabCrumbState.get({noproxy: true}).length > 1){
							this.props.formData.filebox_shortcut = [...breabCrumbState.get({noproxy: true}).slice(1, breabCrumbState.get().length)]
								.reduce((arr,item)=>{
									if(!arr.find(c=> c.id == item.id)) arr.push(item);
									return arr;
								},[]).map(a=> {
									if (a.id === 'company'){
										return '';
									}
									return a.name;
								}).join('/') + '/';
							this.props.formData.shared_folders_path = this.props.formData.filebox_shortcut;
							this.props.formData.drive_folder_id = breabCrumbState.get({noproxy: true})[breabCrumbState.get({noproxy: true}).length - 1]?.id || null;
						} else {
							this.props.formData.filebox_shortcut = '/';
							this.props.formData.drive_folder_id = 0;
							this.props.formData.shared_folders_path = '/';
						}
					} else {
						this.props.formData.filebox_shortcut = [...breabCrumbState.get({noproxy: true}).slice(1, breabCrumbState.get().length), 
							{...selectedFoldersCompany.get({stealth: true})}].reduce((arr,item)=>{
								if(!arr.find(c=> c.id == item.id)) arr.push(item);
							return arr;
						},[]).map(a=> {
							if (a.id === 'company'){
								return '';
							}
							return a.name;
						}).join('/') + '/';
						this.props.formData.shared_folders_path = this.props.formData.filebox_shortcut;
						this.props.formData.drive_folder_id = selectedFoldersCompany.get({stealth: true})?.id || null;
					}
					if(
						breabCrumbState.get({noporoxy: true}) 
						&& breabCrumbState.get({noporoxy: true})?.length === 1 
						&& breabCrumbState.get({noporoxy: true})[0]?.id === 'company')
					{
						this.formData.drive_folder_id = breabCrumbState.get({noporoxy: true})[0]?.drive_folder_id
					}
					breabCrumbState.set([]);
					currentFolderTypeState.set(0);
					currentSelectedCompanyFolderState.set(0);
					this.props.objMain.setFormData(this.props.formData);
					driveFoldersNav.set([]);
					this.props.objMain.modalToggle(false);
					selectedFoldersCompany.set(null);
				}}>
				<span>{t("common:general.ok")}</span>
			</Button>
		);
		this.props.objMain.updateState({ modalTitle, ModalBody, ModalAction, modalProps });
		this.fileBoxSelectionBody({
			path: "/",
			type: "D",
		});

	}

	fileBoxSelectionBody = (options) => {
		let { ModalBody } = this.props.objMain.state;
		const breabCrumbDefault = driveFoldersNav?.get({ stealth: true,noproxy: true })?.find((a) => a.type === 6);
		driveDetailLoading.set(true);
		if (!breabCrumbDefault){
			getFoldersTree({
				page_number: 1,
				page_size: 100,
				parent_id: "null",
			  }).then(r=>{
				// console.log(r.data.payload)
				breabCrumbState.set([r.data.payload.find(a=> a.type === 6)].map(mappedDataToTreeView));
				ModalBody = () => {
					return <Suspense fallback={<Loading />}><DriveLayout fromDocumentSelect={true} isCompany={true} isGuest={false} children={<DriveFolders fromRoot={false} fromDocumentSelect={true}/>} /></Suspense>;
				};
				this.props.objMain.updateState({ ModalBody });
			  });
		} else {
			breabCrumbState.set([breabCrumbDefault]);
			driveDetailLoading.set(false);	
			ModalBody = () => {
				return <Suspense fallback={<Loading />}><DriveLayout fromDocumentSelect={true} isCompany={true} isGuest={false} children={<DriveFolders fromRoot={false} fromDocumentSelect={true}/>} /></Suspense>;
			};
			this.props.objMain.updateState({ ModalBody });
		}
		
	}

	/** */
	addAttribute = (ev) => {
		this.props.formData.doc_attributes.push({ key: "", value: "" });
		this.props.objMain.setFormData(this.props.formData);
	}

	removeAttribute = (ev) => {
		/* */
		// console.log(ev.target.closest(".remove").dataset);
		let row = parseInt(ev.target.closest(".remove").dataset.row);
		this.props.formData.doc_attributes.splice(row, 1);
		this.props.objMain.setFormData(this.props.formData);
	}

	setAttibute = (ev) => {
		// console.log(ev);
		// console.log(ev.target.value);
		let row = parseInt(ev.target.dataset.row);
		let type = ev.target.dataset.type;
		if (type === "key") {
			this.props.formData.doc_attributes[row].key = ev.target.value;
		} else {
			this.props.formData.doc_attributes[row].value = ev.target.value;
		}
		this.props.objMain.setFormData(this.props.formData);
	}

	renderAttributes = (ev) => {
		let { t } = this.props;
		let { fieldValidate } = this.props.objMain.state;
		let attrRows = [];
		// console.log(this.props.formData.doc_attributes);
		for (let i in this.props.formData.doc_attributes) {
			let attr = this.props.formData.doc_attributes[i];
			attrRows.push(
				<Form.Row key={i}>
					<Form.Group xs={5} as={Col} controlId={"key[" + i + "]"} className={fieldValidate["DocumentInfo"]}>
						<Form.Control
							name={"key[" + i + "]"}
							value={attr.key}
							data-row={i}
							data-type="key"
							onChange={this.setAttibute}
							placeholder={t("common:documents.document-info.key")}
							required
						/>
						<Form.Control.Feedback type="invalid">
							{t('common:message.input.required', { field_name: t("common:documents.document-info.key") })}
						</Form.Control.Feedback>
					</Form.Group>

					<Form.Group xs={7} as={Col} controlId={"value[" + i + "]"} className={fieldValidate["DocumentInfo"]}>
						<InputGroup hasValidation>
							<Form.Control
								name={"value[" + i + "]"}
								value={attr.value}
								data-row={i}
								data-type="value"
								onChange={this.setAttibute}
								placeholder={t("common:documents.document-info.value")}
								required
							/>
							<IconButton data-row={i} className="btn-icon remove" onClick={this.removeAttribute}>
								<DoDisturbOnOutlinedIcon className="file-remove" />
							</IconButton>
							<Form.Control.Feedback type="invalid">
								{t('common:message.input.required', { field_name: t("common:documents.document-info.value") })}
							</Form.Control.Feedback>
						</InputGroup>
					</Form.Group>

				</Form.Row>

			);
		}

		return attrRows;
	}

	onDocumentLoadSuccess = async (pdfObject) => {
		let {
            PDFFile,
        } = this.props.objMain.state;

		if (PDFFile.tryPassword) {
			PDFFile.password = PDFFile.tryPassword;
			delete PDFFile.tryPassword;
		}

        this.props.objMain.setState({ PDFLoadSuccess: true, PDFFile});
    }

    onDocumentLoadError = (pdfObject) => {
        // console.log(pdfObject);
        let { t } = this.props;

        return (
            <div className="pdf-no-data">
                <span>{t("common:message.pdf-preview.load-pdf-fail")}</span>
            </div>
        );
    }

	increasePreviewSize = (ev) => {
        let { scale = 1.2,PDFFile } = this.props.objMain.state;
        scale += 0.3;
        if (scale > 10) {
            scale = 10;
        }
        scale = parseFloat(scale.toFixed(2));
        this.props.objMain.updateState({ scale, autoFit: false });
    }

    decreasePreviewSize = (ev) => {
        let { scale = 1.2, PDFFile } = this.props.objMain.state;
        scale -= 0.3;
        if (scale < 0.1) {
            scale = 0.1;
        }
        scale = parseFloat(scale.toFixed(2));
        this.props.objMain.updateState({ scale, autoFit: false });
    }

	ActionController = () => {
        let { PDFFile } = this.props.objMain.state;

        // if (!controller) {
        //     return null;
        // }

        let PageInfo = "Loading..."
        if (PDFFile.initialDone) {
            PageInfo = `${1}`;
        }

        return (
            <div className="control-container">

                {/* <ButtonPDFFileInfo /> */}

                <div className="view-size-controller">
                    <div className="btn size-decrease" onClick={this.decreasePreviewSize}>
                        <FontAwesomeIcon icon={faMinus} />
                    </div>
                    <div className="btn size-increase" onClick={this.increasePreviewSize}>
                        <FontAwesomeIcon icon={faPlus} />
                    </div>
                </div>


                <div className="page-index-info">

				<StateFragment state={currentRangePage}>{s => {
						// console.log(';diksjfolgsndối', s.get());
						return  <span className="info-label">{s.get() && PDFFile ? <>{s.get()} / {PDFFile.numPages}</> : 'Loading...'}</span>
					}}</StateFragment>
                    {/* <span className="info-label">{PageInfo}</span> */}
                </div>

                

            </div>
        );
    }

	PDFPages = () => {

        let { PDFFile, showAll = true, scale = 1.2 } = this.props.objMain.state;
		if (!PDFFile.initialDone) {
            return null;
        }

        let Pages = [];
        for (let i = 0; i < PDFFile.numPages; i++) {
            let active = "";
            if (i + 1 === parseInt(PDFFile.currentPage)) {
                active = " active";
            }
            if (showAll) {
                active = " active";
            }

            Pages.push(
                <div key={i} className={`pdf-page-panel${active}`}>
                    <Page debug={true} key={i} renderMode="canvas" pageNumber={i + 1} renderTextLayer={false} scale={scale} className={`pdf-custom-page scale-${scale}${active}`} />
                </div>
            );
        }

        return Pages;
    }
	
	resizerTrigger(e) {
		// console.log(e, this.state.startResize);
		return this.resize(e, this);
	}
	
	fullWidthToHalfWidth = (fullWidthText) =>  {
		return fullWidthText.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function(s) {
			return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
		});
	};

	checkInvoiceNumber = (value) => {
		let { t, objMain, formData } = this.props;
		let { PDFFile, isSuggestionLoading } = objMain.state;
		console.log('onBlur');
		let originalFormData = this.objMain.state.formData;
		console.log('formData before', originalFormData);
		let resultString = value;
		if(value){
			const sanitizedString = this.fullWidthToHalfWidth(value).replace(/[^\w０-９]/g, '');
			resultString = sanitizedString.charAt(0) !== 'T' ? 'T' + sanitizedString : sanitizedString;
			console.log('formData after', originalFormData);
			originalFormData.invoice_number = resultString;
			objMain.setState({formData: originalFormData})
		}
		if(resultString.length > 10){
			isSuggestionLoading[PDFFile.hash] = true;
			objMain.setState({isSuggestionLoading});
			searchBusinessNumber(
				resultString
			).then(r=>{
				let {suggestionData} = objMain.state;
				let data = r.data.payload[0];
				if(!suggestionData[PDFFile?.hash]){
					suggestionData[PDFFile?.hash] = {}
				}
				suggestionData[PDFFile?.hash]['invoice_number'] = {
					exist: data.exist,
					name: data.name || "",
					registration_date: data.registration_date,
					address: data.address,
					registrated_number: data?.registrated_number || data?.invoice_number
				};
				isSuggestionLoading[PDFFile.hash] = false;
				objMain.setState({suggestionData, isSuggestionLoading});
			}).catch(e=>{
				let {fieldApplySuggestion, suggestionData} = objMain.state;
				if(suggestionData[PDFFile?.hash]){
					suggestionData[PDFFile?.hash]['invoice_number'] = {};
				}
				isSuggestionLoading[PDFFile.hash] = false;
				objMain.setState({fieldApplySuggestion, suggestionData, isSuggestionLoading});
			})
		}
	}

	render() {
		let { t, objMain, formData } = this.props;

		let { creationType, PDFFile, isSuggestionLoading } = objMain.state;
		let { FieldControl } = objMain;
		let { CounterPartyList } = this;

		let required = true;
		if (creationType === "verification" && [1, "1"].includes(formData.preservation_require)) {
			required = false;
		}
		let  {fieldApplySuggestion,rerenderPopper, suggestionData, menuContentVerification, hide_suggestion_field} = objMain.state;
		
        return (
			<>
			{rerenderPopper ? <BaseSuggestionPopover
				key={'invoice_number'}
				className="popper_suggest_fields_ocr"
				anchorEl={fieldApplySuggestion['invoice_number']} 
				open={(!!hide_suggestion_field[PDFFile?.hash] && !hide_suggestion_field[PDFFile?.hash]['invoice_number'])}
				onClickCb={this.onClickSelectInFields}
				type={'invoice_number'}
				offsetTop={-8}
				offsetLeft={10}
				fileKey={PDFFile?.hash}
				objMain={this.objMain}
				menuContent={menuContentVerification && menuContentVerification['invoice_number'] && suggestionData[PDFFile.hash] ? 
					menuContentVerification['invoice_number']:null}
				items={[suggestionData && suggestionData[PDFFile?.hash] && suggestionData[PDFFile?.hash]['invoice_number']]}
				handleClose={()=> this.objMain.setState({fieldApplySuggestion: {...fieldApplySuggestion, ['invoice_number']: null}})}
			/>: null}
            <div className="verification-form" 
			onClick={()=>{
				if(!hide_suggestion_field?.[PDFFile?.hash]){
					hide_suggestion_field[PDFFile?.hash] = {}
				}
				if(!suggestionData[PDFFile.hash]){
					suggestionData[PDFFile.hash] = {};
				}
				hide_suggestion_field[PDFFile?.hash].invoice_number = true;
				objMain.setState({hide_suggestion_field, rerenderPopper: false});
			}}>
				
				<div className="verification-form-col">
					<div className="verification-form-col-child-wrap">
					<div className="layout-right-title form-header" >
            <h2>{t("common:documents.document-info.header")}</h2>
					</div>
					
					<div>
						<Form.Row>
							{FieldControl({ name: "document_type", as: "select", xs: 12, md: 4, children: this.DocumentTypeItems() })}
							{FieldControl({ name: "preservation_require", as: "select", xs: 12, md: 4, children: this.PreservationRequireItems() })}
						</Form.Row>
						<Form.Row>
							{FieldControl({ name: "doc_title", xs: 12, md: 12, required, validated: "true" })}
						</Form.Row>

						{/* for verification */}
						<Form.Row>
							{FieldControl({ name: "received_date", type: "date", xs: 12, md: 6, required, validate: "true" })}
						</Form.Row>
						<Form.Row>
							{/* {FieldControl({ name: "counter_party_name", xs: 12, md: 8, required, validate: "true" })} */}
							{FieldControl({ name: "counter_party_name", list: "counter-party", autoComplete: "true", xs: 12, md: 6, required, validate: "true" })}
							<CounterPartyList />
						</Form.Row>
						<Form.Row>
							{FieldControl({ name: "amount", type: "number", xs: 8, md: 6, required, validate: "true" })}
							{FieldControl({ name: "currency", as: "select", xs: 4, md: 3, children: this.CurrencyItems() })}
						</Form.Row>

						<Form.Row className="invoice_number_form_verification">
							{FieldControl({ name: "invoice_number", 
							disabled: isSuggestionLoading[PDFFile.hash], 
							xs: 12, md: 6, 
							change: (e) => {
								console.log('change');
								console.log(e);
							},
							onBlur: (e)=>this.checkInvoiceNumber(e.target.value)})}
							<span className={!suggestionData[PDFFile.hash]?.invoice_number?.exist ? `activeFlag_inactive`:`activeFlag_active`} onMouseOver={(e)=>{
									if(!suggestionData[PDFFile.hash]?.invoice_number?.exist) return;
									fieldApplySuggestion['invoice_number'] =  e.currentTarget;
									hide_suggestion_field[PDFFile?.hash]['invoice_number'] = false;
									this.objMain.setState({fieldApplySuggestion, hide_suggestion_field, rerenderPopper: true});
								}}>
								{isSuggestionLoading[PDFFile.hash] ? <CircularProgress /> :
								!suggestionData[PDFFile.hash]?.invoice_number?.exist ? t(`common:business.number.status.inactive`):
								t(`common:business.number.status.active`)}
							</span>
						</Form.Row>

						<Form.Row>
							{FieldControl({ name: "reference", xs: 12, md: 12 })}
						</Form.Row>
						<Form.Row>
							{FieldControl({ name: "product", xs: 12, md: 12 })}
						</Form.Row>

						<Form.Row>
							{FieldControl({
								name: "filebox_shortcut", xs: 12, md: 12, readOnly: true,
								className: "filebox-shortcut-path",
								prepend: () => {
									return (
										<InputGroup.Prepend className="mb-3">
											<Button variant="outlined"
												sx={{ height: 38, mr: -1 }}
												className="input-group-child" onClick={this.showFileboxPathSelection}>
												<span>{t("common:documents.document-info.filebox-path-select-button")}</span>
											</Button>
										</InputGroup.Prepend>
									)
								}
							})}
						</Form.Row>

						<div className="attributes-wrapper">
							{this.renderAttributes()}
						</div>

						<Form.Row className="attributes-wrapper">
							<Form.Group as={Col} controlId="attributes">
								<Button variant="outlined"
									onClick={this.addAttribute} className="btn-custom attributes" >
									<FontAwesomeIcon icon={faPlus} />
									<span className="label">{t('common:documents.document-info.attributes')}</span>
								</Button>
							</Form.Group>
						</Form.Row>
					</div>

					<div className="form-foot">
						<div className="page-action-bottom-relative">
							<div className="wrapper-button-cancel">
								<Button
									variant="contained"
									color="info"
									size='large'
									startIcon={<ChevronLeftIcon />}
									onClick={(ev) => this.props.objMain.prevPageStep(ev, this.baseClass)}>
									<span className="btn-label">{t('common:documents.general.back')}</span>
								</Button>
							</div>
							<div className="wrapper-button-submit">
								<Button
									variant="contained"
									type="submit"
									size='large'
									endIcon={<ChevronRightIcon />}>
									<span className="btn-label">{t('common:documents.general.next')}</span>
								</Button>
							</div>
						</div>
					</div>
					</div>

				</div>
				
				<div  id="resizer_container" className="verification-form-col"  style={{width: this.objMain.state.resizerWidth }}>
					<div id="resizer2" onMouseDown={(e) => {
							let resizeContainer = document.getElementById('resizer_container')
							let startX = e.clientX; // horixontal cordinate
							let startY = e.clientY; // vertical cordinate
							let startWidth = parseInt(document.defaultView.getComputedStyle(resizeContainer).width, 10);
							let startHeight = parseInt(document.defaultView.getComputedStyle(resizeContainer).height, 10);   
							const resizerFunc = this.resizerTrigger.bind(this.objMain);
							// console.log(this.objMain)
							this.objMain.setState({
								startResize: true,
								startX,
								startY,
								startWidth,
								resizeContainer,
								startHeight});
							document.addEventListener("mousemove", resizerFunc, false);
							document.addEventListener(
								"mouseup",
								() => {
								document.removeEventListener("mousemove", resizerFunc, false);
								this.objMain.setState({
									startResize: false
								})
								// startResize.set(false);
								},
								false
							);
						}}
					></div>
					<div key="main-preview" className={`pdf-fragment pdf-full-preview`}>
						<div className={`pdf-preview-section`}>
							<div className="pdf-preview-panel" ref={this.objMain.containerRef}
                        onMouseUp={this.objMain.mouseUpHandle.bind(this.objMain)}
                        onMouseMove={this.objMain.mouseMoveHandle.bind(this.objMain)} 
                        onMouseDown={this.objMain.mouseDownHandle.bind(this.objMain)} >
								<DocumentWithObserver
									observerRef={this.objMain.containerRef}
									currentRangePage={currentRangePage}
									setCurrentPage={(page)=> currentRangePage.set(`${page}`)}
									file={PDFFile.file}
									options={{
                    					defaultScale: 'auto',
										cMapUrl: `https://cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`,
										cMapPacked: true,
									}}
									onLoadSuccess={this.onDocumentLoadSuccess}
									onLoadError={this.onDocumentLoadError}
								>
								{this.PDFPages()}
								</DocumentWithObserver>
							</div>

							<div className="pdf-controller-panel">
								{this.ActionController()}
							</div>

						</div>
					</div>
				</div>
				
			</div>
		</>
			
        );
    }

}

export default withTranslation()(VerificationForm);
