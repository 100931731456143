// @ts-nocheck
import { API_ROUTES, driveApiCall } from "../../config/api.config";

export function getCompanyFoldersContent({
    drive_folder_id,
    search,
    page_number,
    offset_folder_number,
    offset_file_number,
    page_size,
    order_by,
}) {
    // console.log('order_by', order_by);
    return driveApiCall
        .get(API_ROUTES.folders.getCompanyFoldersContent, {
            params: {
                drive_folder_id,
                search,
                // page_number,
                offset_folder_number,
                offset_file_number,
                page_size,
                order_by,
            },
        })
        .catch((e) => {
            console.log(e);
        });
}

export function getCompanyFolders({
    drive_folder_id,
    search,
    page_number,
    page_size,
    order_by,
}) {
    return driveApiCall
        .get(API_ROUTES.folders.getCompanyFolders, {
            params: {
                drive_folder_id,
                search,
                page_number,
                page_size,
                order_by: order_by,
            },
        })
        .catch((e) => {
            console.log(e);
        });
}