// @ts-nocheck
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHookstate } from "@hookstate/core";
import {
  DndProvider,
  getBackendOptions,
  MultiBackend,
  Tree,
} from "@minoru/react-dnd-treeview";
import AccountCircle from "@mui/icons-material/AccountCircle";
import CloseIcon from "@mui/icons-material/Close";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import OpenInFullOutlinedIcon from "@mui/icons-material/OpenInFullOutlined";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Checkbox,
  Chip,
  CircularProgress,
  Divider,
  FormControlLabel,
  SwipeableDrawer,
  Tooltip,
  Typography,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { useCallback, useEffect, useRef } from "react";
import { NativeTypes } from "react-dnd-html5-backend";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Loading from "../../../Pages/Loading";
import formatDateIntlApi from "../../../Utils/format_date_intl";
import {
  cancelDelivery,
  deleteDelivery,
  searchUserDelivery,
  sendDeliveryPackage,
  updateDelivery,
} from "../../data-access/delivery/delivery.api";
import { deliveryListState } from "../../data-access/delivery/delivery.state";
import {
  isTableDragging,
  notificationAlertState,
  selectedRowsNav,
} from "../../data-access/state";
import { throttle } from "../../utils/hooks";
import TypeIcon from "../drive-nav/type-icon";
import DeliveryConfirmDialog from "./drive-delivery-confirm";
const regex = new RegExp(
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
);

export default function DriveShareDialog({
  open,
  onClose,
  dataDetail,
  stateDialog,
  selectedItems,
  isDragging,
  loadingDetail,
}) {
  // const [open, setOpen] = React.useState(false);
  // console.log(selectedItems);
  const { t } = useTranslation("common");
  const sizeDialog = useHookstate({});
  const searchUserLoading = useHookstate(false);
  const enableCustomPassword = useHookstate(false);
  const enableAutoPassword = useHookstate(false);
  const selectListItems = useHookstate([]);
  const emailUserList = useHookstate([]);
  const loading = useHookstate(false);
  const inputValue = useHookstate(null);
  const loadingCancel = useHookstate(false);
  const methods = useForm({
    defaultValues: {
      user_id_list: [],
      email_list: [],
      drive_file_id_list: [],
      drive_folder_id_list: [],
      document_id_list: [],
      download_count_setting: 3,
      subject: "",
      content: "",
      start_date: new Date().toISOString().substring(0, 10),
      end_date: addDays(new Date(), 7).toISOString().substring(0, 10),
      type: 0, // nopass, 1 auto gen password
      password: "",
    },
  });
  const confirmDialog = useHookstate({
    open: false,
    loading: false,
    type: "delete",
    message: "",
    title: "",
  });
  const ref = useRef();
  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    watch,
    clearErrors,
    reset,
  } = methods;
  const loadingUpdate = useHookstate(false);
  const user_id_list = useWatch({ name: "user_id_list", control: control });
  const email_list = useWatch({ name: "email_list", control: control });
  const handleInput = useCallback(
    throttle(searchEmailUser, 1300, { leading: false }),
    []
  );
  useEffect(() => {
    if (open && !dataDetail) {
      searchEmailUser("");
    }
  }, [open]);
  function addDays(date, days) {
    let result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }
  function searchEmailUser(e) {
    // console.log(regex.test(e));
    searchUserDelivery(e)
      .then((r) => {
        emailUserList.set([
          ...r.data.payload,
          {
            // fullname: "test_3 test_3",
            // user_id: -1,
            fullname: "未登録ユーザー",
            email: e,
          },
        ]);
      })
      .finally(() => searchUserLoading.set(false));
  }
  function onChangeSelectUser(e, value) {
    // value;
    const data = value.reduce((arr, item) => {
      if (regex.test(item.email) && !arr.find((a) => a.email === item.email)) {
        arr.push(item);
      }
      return arr;
    }, []);
    const mapValue = data.map((a) => a.email);
    emailUserList.set((v) => {
      return [...v.filter((a) => !mapValue.includes(a.email)), ...data];
    });
    setValue("email_list", data);
  }

  function removeItems(row) {
    selectListItems.set((v) => {
      return v.filter((a) => a.id !== row.id);
    });
  }
  function deleteButtonCallback(e, isCallback, isOpen) {
    if (isCallback === "backdropClick") {
      confirmDialog.set({
        open: false,
        loading: false,
        type: "",
        message: "",
        title: "",
      });
      return;
    }
    if (isCallback) {
      confirmDialog.loading.set(true);
      const mapDeli = [dataDetail.drive_delivery_id];
      deleteDelivery(mapDeli)
        .then((r) => {
          deliveryListState.total.set((v) => v - 1);
          deliveryListState.data.set((v) => {
            return v.filter((a) => !mapDeli.includes(a.drive_delivery_id));
          });
          confirmDialog.set({
            open: false,
            loading: false,
            type: "",
            message: "",
            title: "",
          });
          onClose();
        })
        .finally(() => {
          confirmDialog.loading.set(false);
        });
      return;
    }
    if (isOpen && !isCallback) {
      confirmDialog.set({
        open: true,
        loading: false,
        type: "delete",
        message: "Are you sure to delete selected delivery ?",
        title: "Confirm",
      });
    }

    if (!isCallback && !isOpen) {
      confirmDialog.set({
        open: false,
        loading: false,
        type: "",
        message: "",
        title: "",
      });
    }
  }
  useEffect(() => {
    // console.log("12", selectListItems, selectedItems);
  }, [selectedItems]);
  useEffect(() => {
    if (!open && reset) {
      reset({
        user_id_list: [],
        email_list: [],
        drive_file_id_list: [],
        drive_folder_id_list: [],
        document_id_list: [],
        download_count_setting: 3,
        subject: "",
        content: "",
        start_date: new Date().toISOString().substring(0, 10),
        end_date: addDays(new Date(), 7).toISOString().substring(0, 10),
        type: 0, // nopass, 1 auto gen password
        password: "",
      });
      enableAutoPassword.set(false);
      enableCustomPassword.set(false);
      selectListItems.set([]);
      emailUserList.set([]);
    }
    if (open && selectedItems?.length > 0) {
      selectListItems.set([...selectedItems]);
    }
  }, [open]);
  useEffect(() => {
    if (dataDetail) {
      selectListItems.set([
        ...(dataDetail?.files || []),
        ...(dataDetail?.folders || []),
      ]);
      setValue("content", dataDetail.content);
      setValue("download_count_setting", dataDetail.download_count_setting);
      setValue("end_date", dataDetail.end_date.substring(0, 10));
      setValue("start_date", dataDetail.start_date.substring(0, 10));
      setValue("subject", dataDetail.subject);
      if (dataDetail?.users) {
        const mapValue = dataDetail?.users?.map((a) => a.email) || [];
        // console.log(dataDetail.users);
        emailUserList.set((v) => {
          return [
            ...v.filter((a) => !mapValue.includes(a.email)),
            ...dataDetail.users.map((a) => ({
              fullname: "",
              // user_id: a.user_id,
              email: a.email,
            })),
          ];
        });

        setValue(
          "email_list",
          dataDetail.users.map((a) => ({
            fullname: "",
            // user_id: a.user_id,
            email: a.email,
          }))
        );
      }

      // console.log(dataDetail);
    }
  }, [dataDetail]);
  useEffect(() => {
    setValue(
      "drive_file_id_list",
      selectListItems.value.reduce((arr, item) => {
        if (item.extension !== "folder") {
          arr.push(item.id);
        }
        return arr;
      }, [])
    );
    setValue(
      "document_id_list",
      selectListItems.value.reduce((arr, item) => {
        if (item.document_id) {
          arr.push(item.id);
        }
        return arr;
      }, [])
    );
    setValue(
      "drive_folder_id_list",
      selectListItems.value.reduce((arr, item) => {
        if (item.extension === "folder") {
          arr.push(item.id);
        }
        return arr;
      }, [])
    );
  }, [selectListItems]);

  function cancelDeliveryCallback(e, isCallback, isOpen) {
    if (isCallback === "backdropClick") {
      confirmDialog.set({
        open: false,
        loading: false,
        type: "",
        message: "",
        title: "",
      });
      return;
    }
    if (isCallback) {
      confirmDialog.loading.set(true);
      const mapDeli = [dataDetail.drive_delivery_id];
      cancelDelivery(mapDeli)
        .then((r) => {
          deliveryListState.data.set((v) => {
            return v.filter((a) => {
              if (mapDeli.includes(a.drive_delivery_id)) {
                a.status = 2;
              }
              return a;
            });
          });
          confirmDialog.set({
            open: false,
            loading: false,
            type: "",
            message: "",
            title: "",
          });
          notificationAlertState.set({
            show: true,
            message: `${t(
              "common:drive.message.success.cancel_delivery",
              "Cancel delivery success !"
            )}`,
            type: "success",
          });
        })
        .finally(() => loadingCancel.set(false))
        .catch((e) => {
          notificationAlertState.set({
            show: true,
            message: `${t(
              "common:drive.message.error.create_delivery",
              "Cancel delivery fail !"
            )} ${e?.response?.data.message || e}`,
            type: "error",
          });
        });
      return;
    }

    if (isOpen) {
      confirmDialog.set({
        open: true,
        loading: false,
        type: "cancel",
        message: t("common:drive.message.confirm.cancel_delivery"),
        title: t("common:drive.title.confirm.cancel_delivery"),
      });
    }

    if (!isCallback && !isOpen) {
      confirmDialog.set({
        open: false,
        loading: false,
        type: "",
        message: "",
        title: "",
      });
    }
  }

  function onSubmit(data) {
    if (dataDetail) {
      loadingCancel.set(true);
      cancelDelivery([dataDetail.drive_delivery_id])
        .catch((e) => {
          notificationAlertState.set({
            show: true,
            message: `${t(
              "common:drive.message.error.discontinued_delivery"
            )} ${e?.response?.data.message || e}`,
            type: "error",
          });
        })
        .finally(() => loadingCancel.set(false))
        .then((r) => {
          // selectedList.set([]);
          deliveryListState.data.set((v) => {
            return v.filter((a) => {
              if (dataDetail.drive_delivery_id === a.drive_delivery_id) {
                a.status = 2;
              }
              return a;
            });
          });
          if (stateDialog?.detail) {
            stateDialog.detail.set((v) => ({ ...v, status: 2 }));
          }
          notificationAlertState.set({
            show: true,
            message: `${t("common:drive.message.error.discontinued_delivery")}`,
            type: "success",
          });
          // confirmDialog.set({
          //   open: false,
          //   loading: false,
          //   type: "",
          //   message: "",
          //   title: "",
          // });
        });
      // .finally(() => {
      //   confirmDialog.loading.set(false);
      // });
      // onClose();
      return;
    }
    if (
      data.drive_file_id_list.length === 0 &&
      data.drive_folder_id_list.length === 0 &&
      data.document_id_list.length === 0
    ) {
      return alert(
        t(
          "common:drive.message.error.no_item_share",
          "No files to share. please select files/folders"
        )
      );
    }
    data.email_list = data.email_list.map((a) => a.email);
    loading.set(true);
    sendDeliveryPackage(data)
      .catch((e) => {
        notificationAlertState.set({
          show: true,
          message: `${t(
            "common:drive.message.error.cancel_delivery",
            "Send delivery fail !"
          )} ${e?.response?.data.message || e}`,
          type: "error",
        });
      })
      .then((r) => {
        // console.log(r);
        onClose();
      })
      .finally(() => loading.set(false));
  }

  function updateDeliveryCb(data) {
    loadingUpdate.set(true);
    updateDelivery({
      ...data,
      status: dataDetail.status,
      drive_delivery_id: dataDetail.drive_delivery_id,
    })
      .then((r) => {
        stateDialog.detail.set((v) => ({
          ...v,
          end_date: data.end_date,
          start_date: data.start_date,
          download_count_setting: data.download_count_setting,
        }));

        deliveryListState.data.set((v) => {
          return v.filter((a) => {
            if (dataDetail.drive_delivery_id === a.drive_delivery_id) {
              a.end_date = data.end_date;
            }
            return a;
          });
        });
        notificationAlertState.set({
          show: true,
          type: "success",
          message: t(
            "common:drive.message.success.update_delivery",
            "Update delivery success"
          ),
        });
        // onClose();
      })
      .catch((e) => {
        notificationAlertState.set({
          show: true,
          message: `${t(
            "common:drive.message.error.update_delivery",
            "Update delivery fail !"
          )} ${e?.response?.data.message || e}`,
          type: "error",
        });
      })
      .finally(() => loadingUpdate.set(false));
  }

  function discontinueDeliveryCallback(e, isCallback, isOpen) {
    if (isCallback === "backdropClick") {
      confirmDialog.set({
        open: false,
        loading: false,
        type: "",
        message: "",
        title: "",
      });
      return;
    }
    if (isCallback) {
      confirmDialog.loading.set(true);
      const mapDeli = [dataDetail.drive_delivery_id];
      updateDelivery({
        ...dataDetail,
        status: 3,
        drive_delivery_id: dataDetail.drive_delivery_id,
      })
        .then((r) => {
          deliveryListState.data.set((v) => {
            return v.filter((a) => {
              if (mapDeli.includes(a.drive_delivery_id)) {
                a.status = 3;
              }
              return a;
            });
          });
          if (stateDialog?.detail) {
            stateDialog.detail.set((v) => ({ ...v, status: 3 }));
          }
          confirmDialog.set({
            open: false,
            loading: false,
            type: "",
            message: "",
            title: "",
          });
          notificationAlertState.set({
            show: true,
            type: "success",
            message: t("common:drive.message.success.discontinue_delivery"),
          });
          // onClose();
        })
        .catch((e) => {
          notificationAlertState.set({
            show: true,
            message: `${t(
              "common:drive.message.error.discontinue_delivery",
              "Update delivery fail !"
            )} ${e?.response?.data?.message || e?.code}`,
            type: "error",
          });
        })
        .finally(() => confirmDialog.loading.set(false));
      return;
    }

    if (isOpen) {
      confirmDialog.set({
        open: true,
        loading: false,
        type: "discontinue",
        message: t("common:drive.message.confirm.discontinued_delivery"),
        title: t("common:drive.title.confirm.discontinued_delivery"),
      });
    }

    if (!isCallback && !isOpen) {
      confirmDialog.set({
        open: false,
        loading: false,
        type: "",
        message: "",
        title: "",
      });
    }
  }

  const isBefore = (date, control) => {
    if (!date) {
      return false;
    }
    const start = new Date(control.start_date);
    const end = new Date(control.end_date);
    start.setHours(0, 0, 0, 0);
    end.setHours(0, 0, 0, 0);
    return end >= start;
  };

  // console.log("{noproxy: true}", selectListItems.get({ noproxy: true }));
  return (
    <div>
      <DeliveryConfirmDialog
        title={confirmDialog.title.get()}
        onClose={
          confirmDialog.type.get() === "delete"
            ? deleteButtonCallback
            : confirmDialog.type.get() === "discontinue"
            ? discontinueDeliveryCallback
            : cancelDeliveryCallback
        }
        open={confirmDialog.open.get()}
        loading={confirmDialog.loading.get()}
        message={confirmDialog.message.get()}
      />
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open form dialog
      </Button> */}

      <SwipeableDrawer
        hideBackdrop={true}
        open={open}
        anchor="bottom"
        onOpen={() => {}}
        // fullWidth
        classes={{
          paper: dataDetail
            ? "drive_share_dialog full_height"
            : "drive_share_dialog full_height",
        }}
        variant="persistent"
        className={sizeDialog.get()?.width ? "drive_share_dialog_max" : ""}
        onClose={(e, reason) => {
          // console.log(reason);
          if (reason === "backdropClick") {
            e.stopPropagation();
            return false;
          }
          return onClose(e, reason);
        }}
      >
        <div className="drive_share_dialog_title">
          <Typography>
            {t("common:drive.share_dialog.title")}
            {/* ファイル配信 */}
          </Typography>
          <div className="drive_share_dialog_icon_title">
            {/* <MinimizeOutlinedIcon
              sx={{
                cursor: "pointer",
              }}
              onClick={onClose}
            /> */}
            {sizeDialog.get().width ? (
              <CloseFullscreenIcon
                sx={{
                  cursor: "pointer",
                }}
                onClick={() => sizeDialog.set({})}
              />
            ) : (
              <OpenInFullOutlinedIcon
                sx={{
                  cursor: "pointer",
                }}
                onClick={() =>
                  sizeDialog.set({
                    maxWidth: "100vw !important",
                    width: "100%",
                  })
                }
              />
            )}
            <CloseIcon
              sx={{
                cursor: "pointer",
              }}
              onClick={onClose}
            />
          </div>
        </div>
        <div className="drive_share_dialog_content">
          <div className="drive-form-control">
            {loadingDetail ? (
              <div
                style={{
                  width: "100%",
                  position: "absolute",
                  top: 0,
                  zIndex: 99999,
                  left: 0,
                  height: "100%",
                  backgroundColor: "white",
                  display: "flex",
                  justifyContent: "start",
                }}
                className="main-body-loading"
              >
                <Loading />
              </div>
            ) : null}
            <Autocomplete
              multiple
              id="tags-standard"
              options={emailUserList.get({ noproxy: true })}
              onChange={onChangeSelectUser}
              value={email_list}
              disabled={!!dataDetail}
              loading={searchUserLoading.get()}
              // value={user_id_list || []}
              // inputValue={inputValue.get()}
              onInputChange={(event, newInputValue) => {
                // console.log(newInputValue)
                searchUserLoading.set(true);
                return handleInput(newInputValue);
              }}
              getOptionLabel={(option) =>
                `${option?.email} ${option?.fullname || "未登録ユーザー"}`
              }
              renderOption={(props, option) => {
                return (
                  <div className="drive_search_mail_wrapper" key={props.id}>
                    <div
                      {...props}
                      className={`drive_search_mail ${props.className}`}
                    >
                      <div className="drive_search_mail_avatar">
                        {option?.avatar ? (
                          <img
                            loading="lazy"
                            width="20"
                            src={option?.avatar}
                            alt=""
                          />
                        ) : (
                          <AccountCircle />
                        )}
                      </div>
                      <div className="drive_search_mail_info">
                        {option?.fullname ? (
                          <Typography variant="h6">
                            {option?.fullname || "未登録ユーザー"}
                          </Typography>
                        ) : (
                          "未登録ユーザー"
                        )}
                        <Typography
                          fontSize={12}
                          color={"GrayText"}
                          variant="body2"
                        >
                          {option?.email}
                        </Typography>
                      </div>
                    </div>
                  </div>
                );
              }}
              renderTags={(value, getTagProps) => {
                // console.log(value);
                return value.map((option, index) => (
                  <div className="drive_share_email_chip" key={index}>
                    <Chip
                      avatar={
                        option.avatar ? (
                          <img
                            loading="lazy"
                            width="20"
                            src={option.avatar}
                            alt=""
                          />
                        ) : (
                          <AccountCircle />
                        )
                      }
                      variant="outlined"
                      deleteIcon={<CloseIcon />}
                      label={option.email}
                      {...getTagProps({ index })}
                    />
                  </div>
                ));
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  disabled={!!dataDetail}
                  label={t("common:drive.share_dialog.to")}
                  // 宛先
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {searchUserLoading.get() ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params?.InputProps?.endAdornment}
                      </>
                    ),
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={email_list.length === 0}
                  helperText={
                    email_list.length === 0 &&
                    t(
                      "common:drive.validate.user_delivery",
                      "配信先ユーザーは必須項目です"
                    )
                  }
                />
              )}
            />
          </div>
          <div className="drive-form-control">
            <Controller
              name="subject"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  variant="standard"
                  fullWidth
                  disabled={!!dataDetail}
                  label="件名"
                  error={errors.subject?.type === "required"}
                  helperText={
                    errors.subject?.type === "required" &&
                    t(
                      "common:drive.form.validate_subject",
                      "件名は必須フィールドです"
                    )
                  }
                  // rows={4}
                  // multiline
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          </div>
          <div className="drive-form-control">
            <Controller
              name="content"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  variant="standard"
                  fullWidth
                  disabled={!!dataDetail}
                  label="本文"
                  rows={5}
                  label="本文"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={errors.content?.type === "required"}
                  helperText={
                    errors.content?.type === "required" &&
                    t(
                      "common:drive.form.validate_content",
                      "Content is required"
                    )
                  }
                  multiline
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          </div>
          {!dataDetail ? (
            selectListItems.get({ noproxy: true }).map((r, i) => {
              let row = r.get ? r.get() : r;
              // console.log(row);
              return (
                <div className="drive_selected_files" key={row?.id || i}>
                  <div className="drive_selected_files_chip">
                    <div className="drive_selected_files_chip_content">
                      <TypeIcon
                        {...row}
                        fileType={
                          row?.format_type || row?.extension || "folder"
                        }
                        droppable={
                          row?.fileType === "folder" ||
                          row?.extension === "folder"
                        }
                      />
                      <Tooltip
                        disableInteractive={true}
                        placement="top"
                        title={row?.name}
                      >
                        <span className="text_file_chip_full">{row?.name}</span>
                      </Tooltip>
                    </div>
                    <CloseIcon onClick={() => removeItems(row)} />
                  </div>
                </div>
              );
            })
          ) : (
            <div className="drive_detail_selected_file">
              {selectListItems.get({ noproxy: true }).map((r, i) => {
                let row = r.get ? r.get() : r;
                const rowNameType = row.name.split(".");
                return (
                  <div className="drive_selected_files" key={row?.id || i}>
                    <div className="drive_selected_files_chip">
                      <div className="drive_selected_files_chip_content">
                        <TypeIcon
                          {...row}
                          fileType={
                            rowNameType[rowNameType.length - 1] || "folder"
                          }
                          droppable={false}
                        />
                        <Tooltip
                          disableInteractive={true}
                          placement="top"
                          title={row?.name}
                        >
                          <span className="text_file_chip">{row?.name}</span>
                        </Tooltip>
                      </div>
                      {/* <CloseIcon onClick={() => removeItems(row)} /> */}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          <div className="drive_download_config">
            <div className="drive_download_config_control">
              <Typography>
                {t("common:drive.share_dialog.download_period")}
                {/* ダウンロード可能期間 */}
              </Typography>
              <div className="date_input_drive">
                <Controller
                  name="start_date"
                  control={control}
                  rules={{ required: true, validate: { isBefore } }}
                  render={({ field }) => (
                    <div>
                      <input
                        {...field}
                        max="9999-12-31"
                        type="date"
                        onChange={(e) => {
                          clearErrors("end_date");
                          clearErrors("start_date");
                          return field.onChange(e);
                        }}
                        className="form-control"
                      />
                      <p
                        className="error-text"
                        style={{
                          width: "fit-content",
                          position: "absolute",
                        }}
                      >
                        {errors.start_date?.type === "required" &&
                          t(
                            "common:drive.form.validate_start_date",
                            "Start date is required"
                          )}
                        {errors.start_date?.type === "isBefore" &&
                          t(
                            "common:drive.form.validate_start_end_date",
                            "Start date must lower than end date"
                          )}
                      </p>
                    </div>
                  )}
                />
                <span className="date_input_drive_separtor">~</span>
                <Controller
                  name="end_date"
                  control={control}
                  rules={{ required: true, validate: { isBefore } }}
                  render={({ field }) => (
                    <div>
                      <input
                        {...field}
                        max="9999-12-31"
                        type="date"
                        onChange={(e) => {
                          clearErrors("end_date");
                          clearErrors("start_date");
                          return field.onChange(e);
                        }}
                        className="form-control"
                      />
                      <p className="error-text">
                        {errors.end_date?.type === "required"
                          ? t(
                              "common:drive.form.validate_end_date",
                              "End date is required"
                            )
                          : null}
                      </p>
                    </div>
                  )}
                />
              </div>
            </div>
            <div className="drive_download_config_control">
              <Typography>
                {t("common:drive.share_dialog.download_count_setting")}
                {/* ダウンロード回数設定 */}
              </Typography>
              <div className="input_drive">
                <Controller
                  name="download_count_setting"
                  control={control}
                  rules={{ required: true, min: 1 }}
                  render={({ field }) => {
                    return (
                      <>
                        <input
                          {...field}
                          type="number"
                          className={`form-control ${
                            errors.download_count_setting?.type
                              ? "error-border"
                              : ""
                          }`}
                        />
                        回
                        <br />
                        <p className="error-text">
                          {errors.download_count_setting?.type === "required"
                            ? t(
                                "common:drive.form.validate_download_count",
                                "Download count is required"
                              )
                            : null}
                          {errors.download_count_setting?.type === "min"
                            ? t(
                                "common:drive.form.validate_download_count_data",
                                "Download count must greater than 0"
                              )
                            : null}
                        </p>
                      </>
                    );
                  }}
                />
              </div>
            </div>
          </div>
          <div className="drive_share_password">
            <Typography>
              {t("common:drive.share_dialog.password")}
              {/* パスワード */}
            </Typography>
            <div className="drive_share_password_control">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={enableAutoPassword.get() || dataDetail?.type === 0}
                    disabled={!!dataDetail}
                    onChange={(e) => {
                      if (
                        !e.target.checked &&
                        !enableCustomPassword.get({ stealth: true })
                      ) {
                        setValue("type", 0);
                      }
                      if (e.target.checked) {
                        setValue("type", 1);
                      }
                      enableAutoPassword.set(e.target.checked);
                      enableCustomPassword.set(false);
                    }}
                  />
                }
                label={t("common:drive.share_dialog.automatic")}
                // 自動（12文字）
              />
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={!!dataDetail}
                    checked={
                      enableCustomPassword.get() || dataDetail?.type === 2
                    }
                    onChange={(e) => {
                      if (
                        !e.target.checked &&
                        !enableAutoPassword.get({ stealth: true })
                      ) {
                        setValue("type", 0);
                      }
                      if (e.target.checked) {
                        setValue("type", 2);
                      }
                      enableCustomPassword.set(e.target.checked);
                      enableAutoPassword.set(false);
                    }}
                  />
                }
                label={t("common:drive.share_dialog.custom")}
                // カスタム
              />
              <Controller
                name="password"
                control={control}
                render={({ field }) => {
                  return (
                    <TextField
                      {...field}
                      disabled={!enableCustomPassword.get() || !!dataDetail}
                      size="small"
                      error={!field.value && enableCustomPassword.get()}
                      helperText={
                        !field.value &&
                        enableCustomPassword.get() &&
                        t(
                          "common:drive.form.validate_password",
                          "パスワードは必須フィールドです"
                        )
                      }
                      placeholder="••••••"
                      type={"password"}
                    />
                  );
                }}
              />
            </div>
          </div>
          <Divider />
          {dataDetail ? (
            <>
              <div className="drive_share_detail_wrap">
                <Typography>
                  {t("common:drive.share_dialog.delivery_status_title")}
                  {/* 宛先ごとの配信状況 */}
                </Typography>
                {dataDetail?.users?.length > 0
                  ? dataDetail?.users?.map((a) => {
                      const formatedVal = formatDateIntlApi(
                        a.last_download_date,
                        {
                          timeZone: "UTC",
                          month: "2-digit",
                          day: "2-digit",
                          year: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                          second: "2-digit",
                          hourCycle: "h23",
                        }
                      );
                      return (
                        <>
                          <div className="drive_share_email_chip">
                            <Chip
                              avatar={<AccountCircle />}
                              variant="outlined"
                              deleteIcon={<CloseIcon />}
                              label={a.email}
                            />
                          </div>
                          <table className="drive_share_detail_table">
                            <tr>
                              <th>
                                <Typography variant="body2" fontSize="14">
                                  {t(
                                    "common:drive.share_dialog.delivery_status"
                                  )}
                                  {/* 
                                    "配信状況" */}
                                </Typography>
                              </th>
                              <td>
                                <Typography
                                  fontSize={12}
                                  color={"GrayText"}
                                  variant="body2"
                                >
                                  {dataDetail.status == 1
                                    ? new Date(dataDetail.end_date) < new Date()
                                      ? t(
                                          "common:drive.share_dialog.end_date_expired",
                                          "配信期間終了"
                                        )
                                      : t(
                                          "common:drive.share_dialog.during_delivery",
                                          "配信中"
                                        )
                                    : dataDetail.status == 2
                                    ? t(
                                        "common:drive.share_dialog.delivery_cancelled",
                                        "キャンセル済み"
                                      )
                                    : t(
                                        "common:drive.share_dialog.delivery_disconntinued",
                                        "配信中止"
                                      )}
                                </Typography>
                              </td>
                              <th>
                                <Typography variant="body2" fontSize="14">
                                  {t(
                                    "common:drive.share_dialog.download_status"
                                  )}
                                  {/* ダウンロード状況 */}
                                </Typography>
                              </th>
                              <td>
                                <Typography
                                  fontSize={12}
                                  color={"GrayText"}
                                  variant="body2"
                                >
                                  {a.downloaded_number > 0
                                    ? t(
                                        "common:drive.share_dialog.status.downloaded",
                                        "ダウンロード済み"
                                      )
                                    : t(
                                        "common:drive.share_dialog.status.undownloaded",
                                        "未ダウンロード"
                                      )}

                                  {/* 一部ダウンロード */}
                                </Typography>
                              </td>
                            </tr>

                            <tr>
                              <th>
                                <Typography variant="body2" fontSize="14">
                                  {t(
                                    "common:drive.share_dialog.download_turntable"
                                  )}
                                  {/* ダウンロード回敷 */}
                                </Typography>
                              </th>
                              <td>
                                <Typography
                                  fontSize={12}
                                  color={"GrayText"}
                                  variant="body2"
                                >
                                  {a.downloaded_number}/
                                  {dataDetail.download_count_setting}
                                </Typography>
                              </td>
                              <th>
                                <Typography variant="body2" fontSize="14">
                                  {t("common:drive.share_dialog.last_dl_date")}
                                  {/* 最終DL日時 */}
                                </Typography>
                              </th>
                              <td>
                                <Typography
                                  fontSize={12}
                                  color={"GrayText"}
                                  variant="body2"
                                >
                                  {a.downloaded_number > 0
                                    ? `${formatedVal.year}-${formatedVal.month}-${formatedVal.day} ${formatedVal.hour}:${formatedVal.minute}`
                                    : ""}
                                </Typography>
                              </td>
                            </tr>
                          </table>
                        </>
                      );
                    })
                  : null}
              </div>
            </>
          ) : null}
        </div>
        <div className="drive_share_dialog_action">
          <div className="drive_share_dialog_action_button">
            <LoadingButton
              loading={loading.get()}
              variant="contained"
              disabled={
                dataDetail &&
                (dataDetail?.status === 3 || dataDetail?.status === 2)
              }
              onClick={
                dataDetail
                  ? () =>
                      discontinueDeliveryCallback(undefined, undefined, true)
                  : handleSubmit(onSubmit)
              }
            >
              {!dataDetail
                ? t("common:drive.button.send", "送信")
                : t("common:drive.button.discontinue", "配信中止")}
            </LoadingButton>

            {/* {dataDetail && dataDetail.status !== 2 ? (
              <LoadingButton
                variant="outlined"
                onClick={
                  dataDetail
                    ? () => cancelDeliveryCallback(undefined, undefined, true)
                    : handleSubmit(onSubmit)
                }
              >
                {t("common:drive.button.cancel_share_dialog", "配信キャンセル")}
              </LoadingButton>
            ) : null} */}
            {dataDetail && dataDetail.status !== 2 ? (
              <LoadingButton
                loading={loadingUpdate.get()}
                variant="contained"
                sx={{ width: "fit-content" }}
                onClick={handleSubmit(updateDeliveryCb)}
              >
                {t("common:drive.button.update", "更新")}
              </LoadingButton>
            ) : null}
          </div>
          {/* {dataDetail ? (
            <div
              className="drive_file_icon icon material_symbol_icon_drive"
              onClick={() => deleteButtonCallback(undefined, undefined, true)}
            >
              <span className="material-symbols-outlined">delete</span>
            </div>
          ) : // <FontAwesomeIcon
          //   onClick={() => deleteButtonCallback(undefined, undefined, true)}
          //   className={`drive_file_icon icon`}
          //   icon={faTrash}
          // />
          null} */}
        </div>
        {/* <DndProvider
          backend={MultiBackend}
          options={getBackendOptions()}
          debugMode={true}
        > */}
        {isDragging ? (
          <Tree
            extraAcceptTypes={[NativeTypes.TEXT]}
            ref={ref}
            rootId={0}
            tree={[]}
            classes={{
              root: "treeRoot_drag_area_3",
            }}
            onDrop={(e, a, b) => {
              console.log(selectedItems);
              let selectedRow = selectedRowsNav.get({
                noproxy: true,
                stealth: true,
              });
              if (selectedRow.length === 0) {
                selectedRow = [...selectedItems];
              }
              selectListItems.set((v) => {
                return [...v, ...selectedRow].reduce((arr, item) => {
                  if (!arr.find((b) => b.id === item.id)) {
                    arr.push(item);
                  }
                  return arr;
                }, []);
              });
              selectedRowsNav.set([]);
              if (selectListItems) isTableDragging.set(false);
            }}
            onDragStart={(e) => {
              console.log(e);
            }}
            onDragEnd={(e) => {
              console.log(e);
            }}
          />
        ) : null}
        {/* </DndProvider> */}
      </SwipeableDrawer>
    </div>
  );
}
