// @ts-nocheck
import { API_ROUTES, driveApiCall } from "../../config/api.config";
import { driveDetailLoading, driveLoading } from "../state";
import { completeFolderSearchState } from "./complete-folders.state";

export function getCompleteFolders(
    function_type,
    { order_by, page_size, page_number, search }
) {
    // driveDetailLoading.set(true);
    return driveApiCall
        .get(API_ROUTES.folders.complete_folder, {
            params: { function_type, order_by, page_size, page_number, search },
        })
        .catch((e) => {
            console.log(e);
        })
    // .finally(() => driveDetailLoading.set(false));
}

export function downloadCompleteFolder(document_id) {
    return driveApiCall
        .get(API_ROUTES.folders.downloadCompleteFolder, {
            params: { document_id },
        })
        .catch((e) => {
            console.log(e);
        })
}