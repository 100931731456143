import { hookstate } from "@hookstate/core";

export const completeFolderSearchState = hookstate({
    function_type: 1,
    order_by: "name asc, updated_date desc, size asc, memo asc",
    page_size: 50,
    page_number: 1,
    search: ''
});


export const completeFolderListState = hookstate([]);