// @ts-nocheck
import { useHookstate } from "@hookstate/core";
import LanguageIcon from "@mui/icons-material/Language";
import { Button, Divider, Menu, MenuItem } from "@mui/material";
import { useEffect } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import logo from "../../../Pages/Assets/img/logo_figure.svg";
import Service from "../../../Service";
// import { Box, Divider, InputAdornment, ListItemIcon, Menu, MenuItem, Select, TextField } from "@mui/material";
// import { UserInfoContext } from "../Context/UserInfoContext";
import { useTranslation } from "react-i18next";
import { currentLang } from "../../../data-access/app.state";
import { userInfoState } from "../../../drive-feature/data-access/state";
import i18n from "../../../lang.init";
import MenuConf from "../../Main/Menu";
import { MenuConfig } from "../../../Config/Menu";

function LanguesChange(props) {
  const { t } = useTranslation("common");
  //   let [settinglangMenu, setState] = useState();
  const settinglangMenu = useHookstate(null);
  let loading = " data-loading";
  const navigate = useHistory();
  const openlang = settinglangMenu.get();
  const handleClick = (/** @type {{ currentTarget: any; }} */ event) => {
    // @ts-ignore
    settinglangMenu.set(event.currentTarget);
  };

  const handleClose = () => {
    // @ts-ignore
    settinglangMenu.set(null);
  };

  const setLang = (lang) => {
    // console.log(ev.target.getAttribute("lang"));
    Service.UpdateLanguage(lang)
      // @ts-ignore
      .then((resp) => {
        // localStorage.removeItem("user-info");
        // window.location.reload();
        // navigate.go(0)
      })
      .catch((err) => {
        console.log(err.response);
      });
    userInfoState.set((v) => ({ ...v, language: lang }));
    localStorage.setItem(
      "user-info",
      JSON.stringify(userInfoState.get({ noproxy: true }))
    );
    currentLang.set(lang);
    i18n.changeLanguage(lang).then(()=>{
      handleClose();
    });
  };
  return (
    <div>
      <Button
        id="menu-lang-button"
        aria-controls={openlang ? "menulang" : undefined}
        aria-haspopup="true"
        aria-expanded={openlang ? "true" : undefined}
        onClick={handleClick}
      >
        <div className="">
          <div className="info-context">
            <LanguageIcon fontSize="small" />
            <span className="name">
              {t("common:main.general.setting-lang")}
            </span>
          </div>
        </div>
      </Button>
      <Menu
        id="menulang"
        anchorEl={settinglangMenu.get()}
        open={openlang}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "menu-lang-button",
        }}
        className={"profile-menu" + loading}
        color="gray"
      >
        <MenuItem lang="ja" onClick={() => setLang("ja")}>
          {t("common:main.general.japanlang")}
        </MenuItem>
        <Divider />
        <MenuItem lang="en" onClick={() => setLang("en")}>
          {t("common:main.general.englang")}
        </MenuItem>
      </Menu>
    </div>
  );
}
export default function LayoutLeftHook() {
  const { t } = useTranslation("common");
  const width = useHookstate(null);
  const startResize = useHookstate(false);
  const navigate = useHistory();
  const match = useRouteMatch();
  // console.log(t('common:menu.filebox'))

  /**
   * @param {{ x: any; }} e
   */
  function resize(e) {
    const size = `${e.x}px`;
    // @ts-ignore
    if (e.x < 256) {
      width.set("256px");
    } else {
      width.set(size);
    }
  }
  useEffect(() => {
    if (startResize.value) {
      document.addEventListener("mousemove", resize, false);
      document.addEventListener(
        "mouseup",
        () => {
          document.removeEventListener("mousemove", resize, false);
          startResize.set(false);
        },
        false
      );
    }
    return () => {
      document.removeEventListener("mousemove", resize, false);
      document.removeEventListener("mouseup", resize, false);
      startResize.set(false);
    };
  }, [startResize]);

  return (
    <div
      className="layout-left"
      style={{
        // @ts-ignore
        width: width.get(),
      }}
    >
      <div
        id="resizer"
        onMouseDown={() => {
          document.addEventListener("mousemove", resize, false);
          document.addEventListener(
            "mouseup",
            () => {
              document.removeEventListener("mousemove", resize, false);
              startResize.set(false);
            },
            false
          );
        }}
      ></div>
      <div
        className="left-fixed-box"
        style={{
          // @ts-ignore
          width: width.get(),
        }}
      >
        <div className="left-head-box"></div>
        <a
          className="logo-horizontal"
          onClick={
            window.location.href.includes("/shared-folder/delivery")
              ? undefined
              : () => navigate.push("/")
          }
        >
          <div className="logo-wrapper">
            <img src={logo} className="App-logo" alt="paperlogic" />
          </div>
        </a>
        <div className="menu-container">
          <div className="menu-box">
            <MenuConf
              page={
                MenuConfig[
                  Object.keys(MenuConfig).find(
                    (a) => MenuConfig[a].link === match.path
                  )
                ].name
              }
            />
          </div>

          <div className="menu-bottom-container">
            <div className="menu-bottom">
              <div className="footerInfo">
                <LanguesChange />
                <a
                  href="/terms-of-service"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("common:menu.terms-of-service")}
                </a>
                {/* <a href="https://paperlogic.co.jp/contact/" */}
                <a
                  href="https://support.paperlogic.jp/contact/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("common:menu.contact-us")}
                </a>
              </div>
              <div className="copylight">
                <span>© {new Date().getFullYear()} paperlogic co., ltd.</span>
              </div>
              {/* <div className="copylight">
                <span>{t(`common:menu.version`)}: {localStorage.getItem("current-version")}</span>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
