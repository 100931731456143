// @ts-nocheck
import { useHookstate } from "@hookstate/core";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { IconButton, Menu, MenuItem, Typography } from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation, useParams } from "react-router-dom";
import MaterialSymbolIcon from "../../../Pages/Components/material-symbol/material-symbol";
import { getCompanyFolders } from "../../data-access/company-folders/company-folders.api";
import { currentSelectedCompanyFolderState } from "../../data-access/company-folders/company-folders.state";
import {
  breabCrumbState,
  currentFolderTypeState,
  driveDetailLoading,
  driveFoldersDetail,
  driveFoldersNav,
  expandNodeState,
  folderDetailSearch,
} from "../../data-access/state";
import mappedDataToTreeView from "../../utils/mappedDataToTreeView";
import { mappedTypeToQuery } from "../../utils/mappedTypeToQuery";
import parseSearchQuery from "../../utils/parse-search-query";
export default function DriveBreadcrumb({
  noLink,
  breadCrumbCustom,
  fromDocumentSelect,
  isCompleteFolder,
  typeHover,
}) {
  const { t } = useTranslation("common");
  const { folderId, type } = useParams();
  // console.log(type);
  const openMenuBreadcrumb = useHookstate(false);
  const anchorEl = useHookstate(null);
  const expandedNodes = useHookstate(expandNodeState);
  const breadcrumbNodes = useHookstate(breabCrumbState);
  // const navigate = useHistory();
  const { search } = useLocation();
  const treeData = useHookstate(driveFoldersNav);
  function handleClick(event) {
    anchorEl.set(event.currentTarget);
    openMenuBreadcrumb.set(true);
  }
  function handleClose() {
    anchorEl.set(null);
    openMenuBreadcrumb.set(false);
  }

  useEffect(() => {
    if (folderId) {
      // breadcrumbNodes.set((v) => {
      //   const breabData = [
      //     ...treeData.get({ stealth: true }).filter((a) =>
      //       findAncestors(
      //         folderId ? (isNaN(+folderId) ? folderId : +folderId) : "drive",
      //         treeData.get({
      //           stealth: true,
      //           noproxy: true,
      //         })
      //       ).includes(a.id)
      //     ),
      //   ];
      //   return breabData;
      // });
    } else {
      if (window.location.href.includes("/drive")) {
        // console.log(expandedNodes.get({ stealth: true }));
        breadcrumbNodes.set(
          treeData
            .get({ noproxy: true, stealth: true })
            .filter((a) => a.id == "drive")
        );
      }
    }
  }, [folderId, treeData, expandedNodes]);
  let breadCrumbRender =
    breadCrumbCustom || breadcrumbNodes.get({ noproxy: true });
  let newArray = [];

  for (let i = breadCrumbRender.length - 1; i >= 0; i--) {
    newArray.push(breadCrumbRender[i]);
  }
  let firstParent = newArray[0];
  let lastParent = newArray[breadCrumbRender?.length - 1];
  // console.log(
  //   newArray,
  //   breadCrumbRender,
  //   newArray.slice(1, newArray.length - 1)
  // );
  return (
    <div
      role="presentation"
      className={"crumb_wrapper"}
      onClick={(e) => e.stopPropagation()}
    >
      {breadCrumbCustom ? (
        <Breadcrumbs
          aria-label="breadcrumb"
          separator={<NavigateNextIcon fontSize="small" />}
          className={"crumb_list"}
        >
          {breadCrumbRender.length < 4
            ? newArray.map((a) => (
                <Link
                  sx={{
                    pointerEvents: !!fromDocumentSelect ? "none" : "auto",

                    cursor: !!fromDocumentSelect ? "default" : "pointer",
                    color: !!fromDocumentSelect ? "#062b60" : "",
                  }}
                  component={NavLink}
                  to={`${
                    a.drive_folder_id === "drive"
                      ? `/drive`
                      : a.drive_folder_id === "delivery"
                      ? "/delivery"
                      : a.drive_folder_id === "delivery/list"
                      ? "/delivery"
                      : `/folders/${a.drive_folder_id}?type=${
                          isCompleteFolder
                            ? "complete"
                            : mappedTypeToQuery(a.type)
                        }${
                          ["1", "2", "3", "4", 1, 2, 3, 4].includes(
                            parseSearchQuery(search)?.function_type
                          )
                            ? `&function_type=${
                                parseSearchQuery(search)?.function_type
                              }`
                            : ""
                        }`
                  }`}
                  color="secondary"
                  className="crumb_item"
                >
                  <MaterialSymbolIcon iconName={"folder_open"} />
                  &nbsp;
                  {t(a.name)}
                </Link>
              ))
            : null}

          {breadCrumbRender.length > 3 ? (
            <Link
              sx={{
                pointerEvents: !!fromDocumentSelect ? "none" : "auto",

                cursor: !!fromDocumentSelect ? "default" : "pointer",
                color: !!fromDocumentSelect ? "#062b60" : "",
              }}
              component={NavLink}
              to={`${
                firstParent.drive_folder_id === "drive"
                  ? `/drive`
                  : firstParent.drive_folder_id === "delivery"
                  ? "/delivery"
                  : firstParent.drive_folder_id === "delivery/list"
                  ? "/delivery"
                  : `/folders/${firstParent.drive_folder_id}?type=${
                      isCompleteFolder
                        ? "complete"
                        : mappedTypeToQuery(firstParent.type)
                    }${
                      ["1", "2", "3", "4", 1, 2, 3, 4].includes(
                        parseSearchQuery(search)?.function_type
                      )
                        ? `&function_type=${
                            parseSearchQuery(search)?.function_type
                          }`
                        : ""
                    }`
              }`}
              color="secondary"
              className="crumb_item"
            >
              <MaterialSymbolIcon iconName={"folder_open"} />
              &nbsp;
              {t(firstParent.name)}
            </Link>
          ) : null}
          {breadCrumbRender.length > 3 ? (
            <div>
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={
                  openMenuBreadcrumb.get() ? "long-menu" : undefined
                }
                aria-expanded={openMenuBreadcrumb.get() ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleClick}
              >
                <MoreHorizIcon />
              </IconButton>
              <Menu
                id="long-menu"
                MenuListProps={{
                  "aria-labelledby": "long-button",
                }}
                anchorEl={anchorEl.get()}
                open={openMenuBreadcrumb.get()}
                onClose={handleClose}
                sx={{
                  cursor: !!fromDocumentSelect ? "default" : "pointer",
                  color: !!fromDocumentSelect ? "#062b60" : "",
                }}
                PaperProps={{
                  style: {
                    maxHeight: 48 * 4.5,
                    width: "13ch",
                  },
                }}
                className="crumb_item_long"
              >
                {newArray.slice(1, newArray.length - 1).map((a, i) => (
                  <MenuItem
                    key={i}
                    onClick={handleClose}
                    sx={{
                      cursor: !!fromDocumentSelect ? "default" : "pointer",
                    }}
                  >
                    <Link
                      sx={{
                        pointerEvents: !!fromDocumentSelect ? "none" : "auto",

                        cursor: !!fromDocumentSelect ? "default" : "pointer",
                        color: !!fromDocumentSelect ? "#062b60" : "",
                      }}
                      component={NavLink}
                      to={`${
                        a.drive_folder_id === "drive"
                          ? `/drive`
                          : a.drive_folder_id === "delivery"
                          ? "/delivery"
                          : a.drive_folder_id === "delivery/list"
                          ? "/delivery"
                          : `/folders/${a.drive_folder_id}?type=${
                              isCompleteFolder
                                ? "complete"
                                : mappedTypeToQuery(a.type)
                            }${
                              ["1", "2", "3", "4", 1, 2, 3, 4].includes(
                                parseSearchQuery(search)?.function_type
                              )
                                ? `&function_type=${
                                    parseSearchQuery(search)?.function_type
                                  }`
                                : ""
                            }`
                      }`}
                      underline="none"
                      className="crumb_item"
                    >
                      <MaterialSymbolIcon iconName={"folder_open"} />
                      &nbsp;
                      {t(a.name)}
                    </Link>
                  </MenuItem>
                ))}
              </Menu>
            </div>
          ) : null}

          {breadCrumbRender.length > 3 ? (
            <Link
              sx={{
                pointerEvents: !!fromDocumentSelect ? "none" : "auto",

                cursor: !!fromDocumentSelect ? "default" : "pointer",
                color: !!fromDocumentSelect ? "#062b60" : "",
              }}
              component={NavLink}
              to={`${
                lastParent.drive_folder_id === "drive"
                  ? `/drive`
                  : lastParent.drive_folder_id === "delivery"
                  ? "/delivery"
                  : lastParent.drive_folder_id === "delivery/list"
                  ? "/delivery"
                  : `/folders/${lastParent.drive_folder_id}?type=${
                      isCompleteFolder
                        ? "complete"
                        : mappedTypeToQuery(firstParent.type)
                    }${
                      ["1", "2", "3", "4", 1, 2, 3, 4].includes(
                        parseSearchQuery(search)?.function_type
                      )
                        ? `&function_type=${
                            parseSearchQuery(search)?.function_type
                          }`
                        : ""
                    }`
              }`}
              color="secondary"
              className="crumb_item"
            >
              <MaterialSymbolIcon iconName={"folder_open"} />
              &nbsp;
              {t(lastParent.name)}
            </Link>
          ) : null}
        </Breadcrumbs>
      ) : (
        <Breadcrumbs
          aria-label="breadcrumb"
          separator={<NavigateNextIcon fontSize="small" />}
          className={"crumb_list"}
        >
          {(breadCrumbRender.length <= 3 &&
            breadCrumbRender.find((a) => a.id === "drive")) ||
          (breadCrumbRender.length <= 4 &&
            !breadCrumbRender.find((a) => a.id === "drive")) ? (
            breadCrumbRender.map((a, i) =>
              noLink ? (
                <Typography className="crumb_item" key={a.id}>
                  <MaterialSymbolIcon iconName={"folder_open"} />
                  &nbsp;
                  {t(a.name)}
                </Typography>
              ) : a?.isCompany ? (
                <IconButton
                  key={a.id}
                  className="crumb_item icon_button_drive"
                  disabled={i === breadCrumbRender.length - 1}
                  onClick={() => {
                    breadcrumbNodes.set((v) => {
                      const newV = v.slice(0, i + 1);
                      return [...newV.map((b) => ({ ...b, isCompany: true }))];
                    });
                    driveDetailLoading.set(true);

                    // const defaultId = driveFoldersNav
                    //   .get({ stealth: true })
                    //   .find((a) => a.type === 6)?.id;
                    currentFolderTypeState.set(a.type || 6);
                    // console.log(a,driveFoldersDetail?.data.get());
                    currentSelectedCompanyFolderState.set(a.id);
                    if (i < breadcrumbNodes.get({ stealth: true }).length - 1) {
                      breadcrumbNodes.set((v) => {
                        v.splice(i, 1);
                        return v;
                      });
                    }
                    getCompanyFolders({
                      drive_folder_id: a.drive_folder_id || a?.id,
                      ...folderDetailSearch.get({
                        stealth: true,
                        noproxy: true,
                      }),
                    })
                      .then((r) => {
                        const data = r?.data?.payload.map(mappedDataToTreeView);
                        // console.log("datâ,", data);
                        driveFoldersDetail?.data?.merge({
                          company: data,
                          // [a.drive_folder_id || a?.id]: data,
                        });
                      })
                      .finally(() => driveDetailLoading.set(false));
                  }}
                >
                  <MaterialSymbolIcon iconName={"folder_open"} />
                  &nbsp;
                  {t(a.name)}
                </IconButton>
              ) : (
                <div key={a.id}>
                  {i === breadCrumbRender.length - 1 ? (
                    <Typography className="crumb_item">
                      <MaterialSymbolIcon iconName={"folder_open"} />
                      &nbsp;
                      {t(a.name)}
                    </Typography>
                  ) : (
                    <Link
                      component={NavLink}
                      to={`${
                        a.id === "drive"
                          ? `/drive`
                          : a.id === "delivery"
                          ? "/delivery"
                          : a.id === "delivery/list"
                          ? "/delivery"
                          : `/folders/${a.id}?type=${
                              isCompleteFolder
                                ? "complete"
                                : mappedTypeToQuery(a.type)
                            }${
                              ["1", "2", "3", "4", 1, 2, 3, 4].includes(
                                parseSearchQuery(search)?.function_type
                              )
                                ? `&function_type=${
                                    parseSearchQuery(search)?.function_type
                                  }`
                                : ""
                            }`
                      }`}
                      color="secondary"
                      className="crumb_item"
                    >
                      <MaterialSymbolIcon iconName={"folder_open"} />
                      &nbsp;
                      {t(a.name)}
                    </Link>
                  )}
                </div>
              )
            )
          ) : (
            <div>
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={
                  openMenuBreadcrumb.get() ? "long-menu" : undefined
                }
                aria-expanded={openMenuBreadcrumb.get() ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleClick}
              >
                <MoreHorizIcon />
              </IconButton>
              <Menu
                id="long-menu"
                MenuListProps={{
                  "aria-labelledby": "long-button",
                }}
                anchorEl={anchorEl.get()}
                open={openMenuBreadcrumb.get()}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    maxHeight: 48 * 4.5,
                    width: "13ch",
                  },
                }}
                className="crumb_item_long"
              >
                {breadcrumbNodes
                  .get()
                  .slice(0, breadCrumbRender.length - 1)
                  .map((a, i) =>
                    !a?.isCompany ? (
                      noLink ? (
                        <MenuItem key={i} onClick={handleClose}>
                          <Typography className="crumb_item" key={a.id}>
                            <MaterialSymbolIcon iconName={"folder_open"} />
                            &nbsp;
                            {t(a.name)}
                          </Typography>
                        </MenuItem>
                      ) : (
                        <MenuItem key={i} onClick={handleClose}>
                          <Link
                            component={NavLink}
                            to={`${
                              a.id === "drive"
                                ? `/drive`
                                : a.id === "delivery"
                                ? "/delivery"
                                : a.id === "delivery/list"
                                ? "/delivery"
                                : `/folders/${a.id}?type=${
                                    isCompleteFolder
                                      ? "complete"
                                      : mappedTypeToQuery(a.type)
                                  }${
                                    ["1", "2", "3", "4", 1, 2, 3, 4].includes(
                                      parseSearchQuery(search)?.function_type
                                    )
                                      ? `&function_type=${
                                          parseSearchQuery(search)
                                            ?.function_type
                                        }`
                                      : ""
                                  }`
                            }`}
                            underline="none"
                            className="crumb_item"
                          >
                            <MaterialSymbolIcon iconName={"folder_open"} />
                            &nbsp;
                            {t(a.name)}
                          </Link>
                        </MenuItem>
                      )
                    ) : (
                      <IconButton
                        key={a.id}
                        className="crumb_item icon_button_drive"
                        disabled={i === breadCrumbRender.length - 1}
                        onClick={() => {
                          breadcrumbNodes.set((v) => {
                            const newV = v.slice(0, i + 1);
                            return [
                              ...newV.map((b) => ({ ...b, isCompany: true })),
                            ];
                          });
                          driveDetailLoading.set(true);

                          // const defaultId = driveFoldersNav
                          //   .get({ stealth: true })
                          //   .find((a) => a.type === 6)?.id;
                          currentFolderTypeState.set(a.type || 6);
                          // console.log(a,driveFoldersDetail?.data.get());
                          currentSelectedCompanyFolderState.set(a.id);
                          if (
                            i <
                            breadcrumbNodes.get({ stealth: true }).length - 1
                          ) {
                            breadcrumbNodes.set((v) => {
                              v.splice(i, 1);
                              return v;
                            });
                          }
                          getCompanyFolders({
                            drive_folder_id: a.drive_folder_id || a?.id,
                            ...folderDetailSearch.get({
                              stealth: true,
                              noproxy: true,
                            }),
                          })
                            .then((r) => {
                              openMenuBreadcrumb.set(false);
                              const data =
                                r?.data?.payload.map(mappedDataToTreeView);
                              // console.log("datâ,", data);
                              driveFoldersDetail?.data?.merge({
                                company: data,
                                // [a.drive_folder_id || a?.id]: data,
                              });
                            })
                            .finally(() => driveDetailLoading.set(false));
                        }}
                      >
                        <MaterialSymbolIcon iconName={"folder_open"} />
                        &nbsp;
                        {t(a.name)}
                      </IconButton>
                    )
                  )}
              </Menu>
            </div>
          )}
          {(breadCrumbRender.length > 3 &&
            breadCrumbRender.find((a) => a.id === "drive")) ||
          (breadCrumbRender.length > 4 &&
            !breadCrumbRender.find((a) => a.id === "drive")) ? (
            noLink ? (
              <Typography
                key={breadCrumbRender[breadcrumbNodes.value.length - 1].id}
              >
                {t(breadCrumbRender[breadcrumbNodes.value.length - 1].name)}
              </Typography>
            ) : breadCrumbRender[breadcrumbNodes.value.length - 1]
                ?.isCompany ? (
              <Typography
                key={breadCrumbRender[breadcrumbNodes.value.length - 1].id}
              >
                {t(breadCrumbRender[breadcrumbNodes.value.length - 1].name)}
              </Typography>
            ) : (
              <Typography
                key={breadCrumbRender[breadcrumbNodes.value.length - 1].id}
              >
                {t(breadCrumbRender[breadcrumbNodes.value.length - 1].name)}
              </Typography>
            )
          ) : null}

          {/* {(!type && folderId) || !breadCrumbRender.length ? (
          <Typography>
    <div className="material_symbol_icon_drive"><span className="material-symbols-outlined">folder_open</span></div>
            {folderId
              ? t(
                  treeData
                    .get()
                    .find(
                      (a) => a.id === (isNaN(+folderId) ? folderId : +folderId)
                    )?.name
                ) || folderId
              : t("common:drive.tree_nav.root_folder", "ファイル")}
          </Typography>
        ) : null} */}
        </Breadcrumbs>
      )}

      {/* { breadCrumbRender.length} */}
    </div>
  );
}
