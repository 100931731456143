// @ts-nocheck
import {useHookstate} from "@hookstate/core";
import {LoadingButton} from "@mui/lab";
import {Button, Tooltip} from "@mui/material";
import React, {useEffect, useRef} from "react";
import {useForm, useWatch} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {combineLatest, concatMap, from, of} from "rxjs";
import {uploadFileAction} from "../../data-access/actions";
import {
  generateMultiplePresignedUpload,
  getFoldersDetail,
  getFoldersTree,
  updateUploadedFolder,
  uploadToS3,
} from "../../data-access/api";
import {
  driveFoldersDetail,
  driveFoldersNav,
  folderDetailSearch,
  notificationAlertState,
  progessUploadFileGlobal,
  userInfoState,
} from "../../data-access/state";
import parse from "../../utils/handle-file-directory";
import mappedDataToTreeView from "../../utils/mappedDataToTreeView";
import {progessCallback} from "../../utils/progess-callback";
import BaseDialogDriveCrud from "./drive-base-dialog";
import styles from "./drive-dialog.module.css";

export default function DriveUploadDialog({
  open,
  onClose,
  type,
  currentFolderId,
  folderType,
}) {
  //   const open = useHookstate(false);
  const { t } = useTranslation("common");
  const pathSelect = useHookstate("");
  const multiplePathSelect = useHookstate([]);
  const loading = useHookstate(false);
  const uploadProgess = useHookstate(0);
  const totalFile = useHookstate(0);
  const fileUploaded = useHookstate(0);
  const methods = useForm({
    defaultValues: { files: [], filesMap: [], filesObject: {} },
  });
  const errorsMessage = useHookstate("");
  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    reset,
    watch,
  } = methods;
  const file_map_control = useWatch({ name: "filesMap", control: control });
  const files_control = useWatch({ name: "files", control: control });

  function checkContractCapacity(callback) {
    loading.set(true);
    if (userInfoState.get({ noproxy: true })['over_contract_capacity']) {
      notificationAlertState.set({
        show: true,
        type: "warning",
        message: `${t('common:tenant.over-contract-capacity')}`,
      });
    }
    callback();
  }

  function onSubmit(data) {
    if (type === "uploadFile") {
      if (data.files.length === 0) {
        errorsMessage.set(
          t(
            "common:drive.upload.form.file_required",
            "Please select a file to upload"
          )
        );
        return;
      }
      loading.set(true);
      // const file = data.files[0];
      uploadFileAction(
        data.files,
        currentFolderId || "null",
        progessCallback,
        loading,
        folderType,

        onClose
      );
      return;
    }
    if (type === "uploadFolder") {
      if (data.filesMap.length === 0) {
        errorsMessage.set(
          t(
            "common:drive.upload.form.folder_required",
            "Please select a folder to upload"
          )
        );
        return;
      }
      loading.set(true);
      const presingedGenerate = data.filesMap.reduce(
        (arr, item) => {
          if (!item.isFolder) {
            arr.files.push({
              ...item,
              type:
                !item.type || item.type === "application/x-x509-ca-cert"
                  ? "binary/octet-stream"
                  : item.type,
              file_type:
                !item.type || item.type === "application/x-x509-ca-cert"
                  ? "binary/octet-stream"
                  : item.type,
            });
          } else {
            arr.folders.push(item);
          }
          return arr;
        },
        { folders: [], files: [] }
      );
      progessUploadFileGlobal.totalFile.set(data.filesMap.length);
      progessUploadFileGlobal.complete.set(0);
      const updatedList = [...presingedGenerate.folders];
      if (presingedGenerate.files.length === 0) {
        updateUploadedFolder(
          presingedGenerate.folders.map((item) => {
            return {
              id: item.id,
              name: item.name,
              isFolder: item.isFolder,
              parent_id: item.parent_id,
              path: item.path,
              // type: !item.type ? "binary/octet-stream" : item.type,
              // file_type: item.file_type,
              // file_tail: item.file_tail,
              extension: "folder",
              // url: item.url,
              size: 0,
            };
          }),
          currentFolderId || "null"
        ).then((r) => {
          progessUploadFileGlobal.progess.set(100);
          progessUploadFileGlobal.complete.set((v) => 0);
          progessUploadFileGlobal.totalFile.set(0);
          loading.set(false);
          getFoldersTree({
            page_number: 1,
            page_size: 100,
            parent_id: currentFolderId || "null",
          }).then((r5) => {
            if (r5.data.payload?.length > 0)
              driveFoldersNav.set((v) => {
                const newData = [
                  ...v,
                  r5.data.payload.map(mappedDataToTreeView),
                ].reduce((arr, item) => {
                  if (!arr.find((b) => b.id == item.id)) {
                    arr.push(item);
                  }
                  return arr;
                }, []);
                return newData;
              });
          });
          if (
            !driveFoldersDetail.data[
              currentFolderId ? currentFolderId : "root"
            ]?.get({
              stealth: true,
            })
          ) {
            driveFoldersDetail.data.merge({
              [currentFolderId ? currentFolderId : "root"]: [],
            });
          }
          getFoldersDetail(
            currentFolderId,
            folderDetailSearch.get({
              stealth: true,
              noproxy: true,
            })
          ).then((r) =>
            driveFoldersDetail.data[
              currentFolderId ? currentFolderId : "root"
            ].set((v) =>
              [
                ...(r?.data?.payload?.child?.folders.map(
                  mappedDataToTreeView
                ) || []),
                ...v,
                ...(r?.data?.payload?.child?.files.map(mappedDataToTreeView) ||
                  []),
              ].reduce((arr, item) => {
                if (!arr.find((a) => a.id === item.id)) {
                  arr.push(item);
                }
                return arr;
              }, [])
            )
          );
          onClose();
        });
      } else {
        generateMultiplePresignedUpload(presingedGenerate.files)
          .then((r) => {
            from(r.data.payload)
              .pipe(
                concatMap((a) => {
                  // console.log(
                  //   data.filesObject,
                  //   data.filesObject["/" + a.path],
                  //   data.filesObject["/certificates/key.pem"]
                  // );
                  return combineLatest([
                    from(
                      uploadToS3(
                        a.url,
                        data.filesObject[a.path.slice(1, a.path.length)],
                        a.file_type
                        // progessCallback
                      )
                    ),
                    of(a),
                  ]);
                })
              )
              .subscribe({
                next: ([r, a]) => {
                  updatedList.push(a);
                  progessUploadFileGlobal.complete.set((v) => v + 1);
                },
                complete: () => {
                  const listUploadedChange = updatedList
                    .reduce((arr, item) => {
                      arr.push({
                        id: item.id,
                        name: item.name,
                        isFolder: item.isFolder,
                        parent_id: item.parent_id,
                        path: item.path,
                        type: !item.type ? "binary/octet-stream" : item.type,
                        file_type: item.file_type,
                        file_tail: item.file_tail,
                        extension: item?.file_tail || "folder",
                        url: item?.url?.split('?')[0] || item.url,
                        size: item.size || 0,
                      });
                      return arr;
                    }, [])
                    // .sort((a, b) => a.id - b.id);
                  if (listUploadedChange.length > 0) {
                    updateUploadedFolder(
                      listUploadedChange,
                      currentFolderId || "null"
                    ).then((r) => {
                      progessUploadFileGlobal.progess.set(100);
                      progessUploadFileGlobal.complete.set((v) => 0);
                      progessUploadFileGlobal.totalFile.set(0);
                      loading.set(false);
                      getFoldersTree({
                        page_number: 1,
                        page_size: 100,
                        parent_id: currentFolderId || "null",
                      }).then((r5) => {
                        if (r5.data.payload?.length > 0)
                          driveFoldersNav.set((v) => {
                            const newData = [
                              ...v,
                              ...r5.data.payload.map(mappedDataToTreeView),
                            ].reduce((arr, item) => {
                              if (!arr.find((b) => b.id == item.id)) {
                                arr.push(item);
                              }
                              return arr;
                            }, []);
                            return newData.sort((a, b) => {
                              if (a.type === 7) {
                                return -1;
                              } else if (![1, 2, 3, 4, 5].includes(a.type)) {
                                return 0;
                              } else {
                                return a.type - b.type;
                              }
                            });
                          })
                      });
                      if (
                        !driveFoldersDetail.data[
                          currentFolderId ? currentFolderId : "root"
                        ]?.get({
                          stealth: true,
                        })
                      ) {
                        driveFoldersDetail.data.merge({
                          [currentFolderId ? currentFolderId : "root"]: [],
                        });
                      }
                      folderDetailSearch.offset_file_number.set(0);
                      folderDetailSearch.offset_document_number.set(0);
                      folderDetailSearch.offset_folder_number.set(0);
                      getFoldersDetail(
                        currentFolderId,
                        folderDetailSearch.get({
                          stealth: true,
                          noproxy: true,
                        })
                      ).then((r) =>
                        driveFoldersDetail.data[
                          currentFolderId ? currentFolderId : "root"
                        ].set((v) =>
                          [
                            ...(r?.data?.payload?.child?.folders.map(
                              mappedDataToTreeView
                            ) || []),
                            ...v,
                            ...(r?.data?.payload?.child?.files.map(
                              mappedDataToTreeView
                            ) || []),
                          ].reduce((arr, item) => {
                            if (!arr.find((a) => a.id === item.id)) {
                              arr.push(item);
                            }
                            return arr;
                          }, [])
                        )
                      );
                      onClose();
                    });
                  }
                },
              });
          })
          .catch((e) => {
            notificationAlertState.set({
              show: true,
              type: "error",
              message: `${t(
                "common:drive.message.error.upload_folder",
                "Upload folder error please try again!"
              )} ${e?.response?.data?.message || e}`,
            });
            loading.set(false);
          });
      }
      //   if (r.data.payload.length >= 0) {
      //   }
      // });
    }
  }
  const inputRef = useRef(null);
  useEffect(() => {
    if (open) {
      pathSelect.set("");
      multiplePathSelect.set([]);
      reset({
        files: [],
        filesMap: [],
        filesObject: {},
      });
    }
  }, [open]);
  return (
    <div>
      <BaseDialogDriveCrud
        handleClose={(e) => {
          pathSelect.set("");
          return onClose(e);
        }}
        open={open}
        title={
          type === "uploadFile"
            ? t(
                "common:drive.dialog_title.upload_file",
                "ファイル をアップロード"
              )
            : t(
                "common:drive.dialog_title.upload_folder",
                "フォルダ をアップロード"
              )
        }
        dialogContentComponent={
          <>
            <Button
              variant="outlined"
              component="label"
              className={styles["drive_upload_dialog_upload"]}
              endIcon={
                <Button
                  onClick={() => {
                    inputRef?.current?.click();
                  }}
                  variant="contained"
                  color="info"
                >
                  {t("common:drive.button.select_file")}
                  {/* 参照 */}
                </Button>
              }
            >
              {multiplePathSelect.get().length > 1 ? (
                <Tooltip
                  disableInteractive={true}
                  placement="top"
                  title={
                    <div className={styles["drive_upload_dialog_inputfile"]}>
                      {multiplePathSelect.get().join("\n")}
                    </div>
                  }
                >
                  <div className={styles["drive_upload_dialog_inputfile"]}>
                    {multiplePathSelect.get().join("\n")}
                  </div>
                </Tooltip>
              ) : (
                <div>{pathSelect.get()}</div>
              )}

              {type === "uploadFile" ? (
                <input
                  type="file"
                  hidden
                  multiple
                  ref={inputRef}
                  placeholder="File upload"
                  onChange={async (ev) => {
                    const files = ev.target.files || [];
                    errorsMessage.set("");
                    const filesList = [];
                    for (let index = 0; index < files.length; index++) {
                      const element = files[index];
                      filesList.push(element.name);
                    }
                    setValue("files", files);
                    // const files_map =
                    multiplePathSelect.set(filesList);
                    pathSelect.set(files[0]?.name || "");
                    // pathSelect.set(ev.target.value);
                  }}
                />
              ) : (
                <input
                  type="file"
                  hidden
                  webkitdirectory="true"
                  directory="true"
                  multiple
                  mozdirectory="true"
                  ref={inputRef}
                  placeholder="Folder upload"
                  onChange={async (ev) => {
                    errorsMessage.set("");
                    const files = ev.target.files || [];
                    const filesMapObject = {};
                    const filesMap = [];
                    console.log(ev.target);
                    for (let index = 0; index < files.length; index++) {
                      const a = files[index];
                      filesMapObject[a.webkitRelativePath] = a;
                      filesMap.push({
                        name: a.name,
                        path: a.webkitRelativePath,
                        type: a.type,
                        size: a.size,
                      });
                    }
                    const flatTree = (
                        (id) =>
                        (parent) =>
                        ({ children = [], ...object }) => {
                          const pathSplit = object.path.split("/");
                          const result = {
                            id: ++id,
                            ...object,
                            parent,
                            children: null,
                          };
                          if (object.name !== pathSplit[pathSplit.length - 1]) {
                            const indexOfPath = parent ? pathSplit.lastIndexOf(object.name) : pathSplit.indexOf(object.name);
                            result.isFolder = true;
                            result.path =
                              "/" +
                              pathSplit.slice(0, indexOfPath + 1).join("/");
                            result.type = "";
                            result.size = 0;
                          } else {
                            result.isFolder = false;
                            result.path = "/" + result.path;
                            result.file_type = result.type;
                            result.file_tail = result.name.split(".").pop();
                          }
                          result.parent_id = result.parent;
                          return [
                            { ...result },
                            ...children.flatMap(flatTree(id)),
                          ];
                        }
                      )(0),
                      tree = parse(filesMap),
                      flat = tree.flatMap(flatTree(null));
                    // flat[0].path = flat[0].name;
                    // console.log("files", filesMap, flat, filesMapObject);

                    pathSelect.set(
                      files[0]?.webkitRelativePath?.split("/")[0] || ""
                    );
                    if (files.length > 0) {
                      setValue("filesObject", filesMapObject);
                      setValue("filesMap", flat);
                      totalFile.set(files.length);
                      //   const fileHandlesPromises = await getAllFileEntries(
                      //     files
                      //   );
                      //   // console.log();
                      //   fileHandlesPromises.map((a) =>
                      //     a.file((f) => {
                      //       console.log(f);
                      //     })
                      //   );
                    } else {
                      setValue("filesObject", []);
                      setValue("filesMap", []);
                      totalFile.set(files.length);
                    }
                    inputRef.current.value = "";
                  }}
                />
              )}
            </Button>
            <p className="error-text">{errorsMessage.get()}</p>
          </>
        }
        dialogActionComponent={
          <>
            {/* <div>
              {totalFile.get() ? (
                <span>
                  File:&nbsp;{fileUploaded.get()}/{totalFile.get()}
                </span>
              ) : null}
              &nbsp;&nbsp;&nbsp;
              {uploadProgess.get() ? (
                <span>
                  Progess: &nbsp;
                  {uploadProgess.get()}%
                </span>
              ) : null}
            </div> */}

            <Button
              variant="outlined"
              onClick={() => {
                fileUploaded.set(0);
                totalFile.set(0);
                uploadProgess.set(0);
                return onClose();
              }}
            >
              {t("common:drive.button.close")}
              {/* 閉じる */}
            </Button>
            <LoadingButton
              loading={loading.get()}
              variant="contained"
              disabled={
                type === "uploadFile"
                  ? files_control.length === 0
                  : file_map_control.length === 0
              }
              onClick={() => checkContractCapacity(handleSubmit(onSubmit))}
            >
              {t("common:drive.button.upload")}
              {/* アップロード */}
            </LoadingButton>
          </>
        }
      />
    </div>
  );
}
