// @ts-nocheck

export const supportsFileSystemAccessAPI =
    "getAsFileSystemHandle" in DataTransferItem.prototype;
export const supportsWebkitGetAsEntry =
    "webkitGetAsEntry" in DataTransferItem.prototype;


// Drop handler function to get all files
export async function getAllFileEntries(dataTransferItemList) {
    let fileEntries = [];
    // Use BFS to traverse entire directory/file structure
    let queue = [];
    let directories = [];
    // Unfortunately dataTransferItemList is not iterable i.e. no forEach
    for (let i = 0; i < dataTransferItemList.length; i++) {
        // Note webkitGetAsEntry a non-standard feature and may change
        // Usage is necessary for handling directories
        let item = dataTransferItemList[i];
        // console.log(supportsFileSystemAccessAPI, item.getAsFileSystemHandle(), item.webkitGetAsEntry(), item.getAsFile())
        queue.push(
            supportsWebkitGetAsEntry ? item.webkitGetAsEntry()
                : item.getAsFile()
                // supportsFileSystemAccessAPI
                //     ? item.getAsFileSystemHandle()
                //     : item.getAsFile()
        );
    }
    while (queue.length > 0) {
        let entry = queue.shift();
        let readerDir = {
            path: entry.fullPath,
            isFolder: entry.isDirectory,
            isFile: entry.isFile
        }
        if (entry.isFile) {
            fileEntries.push(entry);
            readerDir.file = entry;
        } else if (entry.isDirectory) {
            // console.log(entry)
            queue.push(...(await readAllDirectoryEntries(entry.createReader())));
        }
        directories.push(readerDir);
    }
    const
        flatTree = (id => parent => ({ children = [], ...object }) => [
            { id: ++id, ...object, parent, children: null },
            ...children.flatMap(flatTree(id))
        ])(0),
        tree = parse(directories);
    const folderTree = tree[0].children.filter(a => a.isFolder);

    const folderList = [];
    const filesList = tree[0].children.filter(a => a.isFile)
    folderTree.forEach(element => {
        folderList.push([element].flatMap(flatTree(null)));
    });
    return [fileEntries, folderList, filesList];
}

// Get all the entries (files or sub-directories) in a directory
// by calling readEntries until it returns empty array
export async function readAllDirectoryEntries(directoryReader) {
    let entries = [];
    let readEntries = await readEntriesPromise(directoryReader);
    while (readEntries.length > 0) {
        entries.push(...readEntries);
        readEntries = await readEntriesPromise(directoryReader);
    }
    return entries;
}

// Wrap readEntries in a promise to make working with readEntries easier
// readEntries will return only some of the entries in a directory
// e.g. Chrome returns at most 100 entries at a time
export async function readEntriesPromise(directoryReader) {
    try {
        return await new Promise((resolve, reject) => {
            directoryReader.readEntries(resolve, reject);
        });
    } catch (err) {
        console.log(err);
    }
}

function createNode(path, tree, object) {
    const name = path.shift();
    const cloneObject = { ...object };
    const idx = tree.findIndex((e) => {
        return e.name == name;
    });
    if (idx < 0) {
        tree.push({
            ...cloneObject,
            name: name,
            children: []
        });
        if (path.length !== 0) {
            createNode(path, tree[tree.length - 1].children, cloneObject)
        }
    } else {
        createNode(path, tree[idx].children, cloneObject)
    }
}

export default function parse(data) {
    const tree = [];
    for (let i = 0; i < data.length; i++) {
        const path = data[i]?.path || data[i]?.webkitRelativePath;
        const split = path.split('/');
        createNode(split, tree, data[i], data[i]?.path || data[i]?.webkitRelativePath);
    }
    return tree;
}