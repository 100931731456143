// @ts-nocheck
import { useHookstate } from "@hookstate/core";
import { LoadingButton } from "@mui/lab";
import { Button, TextField, Typography } from "@mui/material";
import { useEffect, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { updateFileOrFolder } from "../../data-access/api";
import { driveFoldersDetail, driveFoldersNav } from "../../data-access/state";
import BaseDialogDriveCrud from "./drive-base-dialog";
import styles from "./drive-dialog.module.css";

export default function DriveEditFolderDialog({ open, onClose, data }) {
  //   const open = useHookstate(false);
  const { t } = useTranslation("common");
  const pathSelect = useHookstate("");
  const loading = useHookstate(false);
  const methods = useForm({
    defaultValues: { name: "" },
  });
  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    watch,
  } = methods;
  function onSubmit(value) {
    loading.set(true);
    // console.log(data, value);
    updateFileOrFolder({
      id: data.id,
      type: data.format_type === "folder" ? "folder" : "file",
      ...value,
    })
      .then((r) => {
        // console.log(r);
        if (!r) return;
        if (
          driveFoldersDetail.data[
            data.parent === "drive" ? "root" : data.parent
          ].get({ stealth: true })
        ) {
          driveFoldersDetail.data[
            data.parent === "drive" ? "root" : data.parent
          ].set((v) => {
            return v.map((a) => {
              if (a.id === data.id) {
                a.name = value.name;
                a.title = value.name;
              }
              return a;
            });
          });

          driveFoldersNav.set((v) => {
            return v.map((a) => {
              if (a.id === data.id) {
                a.name = value.name;
                a.title = value.name;
              }
              return a;
            });
          });
          onClose();
        }
      })
      .finally(() => loading.set(false));
  }
  const inputRef = useRef(null);
  useEffect(() => {
    if (data) {
      setValue("name", data.name);
      setValue("title", data.title);
    }
  }, [data]);
  return (
    <div>
      <BaseDialogDriveCrud
        handleClose={(e) => {
          pathSelect.set("");
          return onClose(e);
        }}
        open={open}
        title={t("common:drive.dialog_title.edit")}
        // フォルダを編集
        dialogContentComponent={
          <div>
            <Typography variant="body2">
              {t("common:drive.table_header.name")}
              {/* 名前 */}
            </Typography>
            <Controller
              name="name"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  size="small"
                  fullWidth
                  error={errors.name?.type === "required"}
                  helperText={
                    errors.name?.type === "required" && "Name is required"
                  }
                  placeholder={t("common:drive.table_header.name")}
                  // 名前
                  className={styles["form_input"]}
                  {...field}
                />
              )}
            />
          </div>
        }
        dialogActionComponent={
          <>
            <Button variant="outlined" onClick={onClose}>
              {t("common:drive.button.close")}
              {/* 閉じる */}
            </Button>
            <LoadingButton
              loading={loading.get()}
              variant="contained"
              onClick={handleSubmit(onSubmit)}
            >
              {/* アップロード */}
              {t("common:drive.button.edit")}
              {/* 編集 */}
            </LoadingButton>
          </>
        }
      />
    </div>
  );
}
