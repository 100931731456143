import React from "react";
import logo from "../Assets/img/logo_h.svg";
import "../Assets/css/auth.css";
import { Auth } from "aws-amplify";
import awsCognito from "../../Config/Cognito";
import { Form, Col, Modal } from "react-bootstrap";
import { withTranslation } from "react-i18next";
// import Amplify, { Auth } from "aws-amplify";
// import awsCognito from "../../Config/Cognito";
import AuthService from "../../Service/Auth";
// import Loading from '../Loading';
//import Box from '@mui/material/Box';
//import TextField from '@mui/material/TextField';
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import CircularProgress from "@mui/material/CircularProgress";
import OtpDialog from "./OtpDialog";
import { errProps } from "../../data-access/app.state";
import Service from "../../Service";
import { userInfoState } from "../../drive-feature/data-access/state";
import { GetUserInfoNew, tenantCheck } from "../../data-access/api";
import i18next from "i18next";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {VisibilityOff} from "@mui/icons-material";
// Amplify.configure(awsCognito);

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      validated: false,
      validatedSso: false,
      formData: {
        username: "",
        password: "",
        domain: "",
      },
      form: null,
      isVerifyEmail: false,
      ssoForm: null,
      signingin: false,
      modalShow: false,
      modalMessage: "test",
      openOtpDialog: false,
      mfaUser: null,
      mfaChallengeName: "",
      showPassword: false,
    };

    // const currentConfig = Auth.configure();
    /* Temporary set fields value */
    // this.initialTempFormData();
  }

  initialTempFormData() {
    this.setState({
      formData: {
        username: "demo",
        password: "password",
      },
    });
  }
  // 	const onChange = (e) => {
  // 		setFormValue({ ...formValue, [e.target.name]: e.target.value });
  // 	};

  onChangeHandler = (ev) => {
    let key = ev.target.name;
    let val = ev.target.value;
    // console.dir(event.target);
    //console.log(key, val);
    let { formData } = this.state;
    formData[key] = val;
    // console.log(this.state.formData[key]);
    // console.log(this.state.formData);
    this.setState({ formData: formData });
  };

  togglePasswordVisibility = () => {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword
    }));
  }

  loginHandler(form, formData, isResend) {

		if('serviceWorker' in navigator){
      navigator.serviceWorker.ready.then((registration) => {
        if(registration?.active){
            // registration.dispatchEvent
            registration.active.postMessage(
                "cache-clear-logout"
            );
        }
      }); 
    }
    AuthService.Login(formData.username, formData.password)
      .then(async (user) => {
        // const attributes = await Auth.userAttributes(user);
        // console.log(user);
        // return;
        // if (attributes.find(a=> a.name === 'custom:mfa_type')?.Value === 'email'){

        // }
        switch (user.challengeName) {
          case "SOFTWARE_TOKEN_MFA":
            this.setState({
              mfaChallengeName: user.challengeName,
              mfaUser: user,
              openOtpDialog: true,
            });
            break;
          case "CUSTOM_CHALLENGE":
            this.setState({
              mfaChallengeName: user.challengeName,
            });
            this.setState({
              mfaUser: user,
            });
            if (!isResend) {
              this.setState({
                openOtpDialog: true,
              });
              this.setState({
                isVerifyEmail: true,
              });
            }
            break;
          default:
            // console.log('checkedTenant', checkedTenant)
            const userInfo = await GetUserInfoNew().catch((err) => {
              if (
                err?.response?.status === 401 
                && err?.response?.data?.message_code === "message.user_disabled"
              ) {
                localStorage.removeItem("user-info");
                AuthService.Logout();
                errProps.set({
                  title: i18next.t("common:message.user_disabled"),
                  msg: [""],
                  code: 401,
                  show: true,
                });
                return;
              }
              if (err?.response?.status === 403 &&
                  err?.response?.data?.message_code === "message.ip_restrict.deny") {
                localStorage.removeItem("user-info");
                AuthService.Logout();
                errProps.set({
                  title: i18next.t(
                    "common:message.ip_restrict.deny",
                    "IP制限中です"
                  ),
                  msg: [
                    "許可されたIPからアクセスしてください",
                    "詳しくは管理者にお問合せください",
                  ],
                  code: 401,
                  show: true,
                });
                return;
              }
              if (err.response.hasOwnProperty("data")) {
                errProps.set({
                  msg: err.response.data.message,
                  code: err.response.status,
                  show: true,
                });
              } else {
                errProps.set({
                  msg: "Connection error, please try to reload.",
                  code: 400,
                  show: true,
                });
              }
            });
            if (userInfo) {
              errProps.set({ msg: "", show: false, code: -1 });
              localStorage.setItem("user-info", JSON.stringify(userInfo));
              userInfoState.set(userInfo);
              if (
                window.location.pathname === "/login" ||
                window.location.pathname === "/logout"
              ) {
                this.props.history.push({
                  pathname: `/`,
                });
              } else {
                this.props.history.go(0);
                this.setState({ form, signingin: true });
              }
            }
            break;
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.code === "NotAuthorizedException") {
          form.username.setCustomValidity(
            "message.errors.login.username-password-incorrect"
          );
          form.password.setCustomValidity(
            "message.errors.login.username-password-incorrect"
          );
        } else if (err.code === "NetworkError") {
        }
        this.setState({ form, signingin: false });
        // console.dir(form);
      });
  }

  formSubmitHandler = async (ev) => {
    ev.preventDefault();
    localStorage.removeItem("defaultVerification");

    this.setState({ validated: true });
    let { formData } = this.state;
    let form = ev.currentTarget;
    form.username.setCustomValidity("");
    form.password.setCustomValidity("");
    this.setState({ form });
    // console.dir(form);
    // console.log(formData);
    if (form.checkValidity() === false) {
      ev.stopPropagation();
    } else {
      this.setState({ signingin: true }, () => {
        const configCog = { ...awsCognito };
        configCog.Auth.authenticationFlowType = "CUSTOM_AUTH";
        Auth.configure(configCog);
        this.loginHandler(form, formData, false);
      });
    }
  };

  ssoFormSubmitHandler = async (ev) => {
    ev.preventDefault();
    localStorage.removeItem("defaultVerification");

    // remove validation error
    this.setState({ validatedSso: true });
    let { formData } = this.state;
    let ssoForm = ev.currentTarget;
    ssoForm.domain.setCustomValidity("");
    this.setState({ ssoForm });

    // check input value
    if (ssoForm.checkValidity() === false) {
      ssoForm.domain.setCustomValidity("入力してください。");
      this.setState({ ssoForm });
      ev.stopPropagation();
    } else {
      if('serviceWorker' in navigator){
        navigator.serviceWorker.ready.then((registration) => {
          if(registration?.active){
              // registration.dispatchEvent
              registration.active.postMessage(
                  "cache-clear-logout"
              );
          }
        }); 
      }
      localStorage.setItem("sso-domain", formData.domain);
      await AuthService.SsoLogin(formData.domain);
    }
  };

  async componentDidMount() {
    errProps.set({ msg: "", show: false, code: -1 });
    // document.title = this.props.t("common:auth.registration.agreement.title");
    if (window.location.pathname === "/cognito-callback") {
      const searchParams = new URLSearchParams(window.location.search);
      if (searchParams.get("error_description") === 'Invalid SAML response received: Invalid user attributes: email: Required attribute cannot be deleted. ') {
        console.log("==> Invalid SAML, retry domain");
        const ssoDomain = localStorage.getItem("sso-domain");
        if (ssoDomain) {
          localStorage.removeItem("sso-domain");
          await AuthService.SsoLogin(ssoDomain);
        }
      } else {
        const token = await AuthService.getTokenFromCode(
          searchParams.get("code")
        );
        await AuthService.getUserInfoFromToken(token);
        window.location.href = "/";
      }
    }
  }

  componentDidUpdate(prevProps) {
    // document.title = this.props.t("common:auth.register.title");
    // console.log("componentDidUpdate", prevProps);
  }

  modalToggle = (toggle) => {
    this.setState({ modalShow: toggle });
  };

  ValidationMessage = (props) => {
    let { t } = this.props;
    let { name, field } = props;
    let { form } = this.state;
    let msgValidation = t("common:message.input.required", {
      field_name: t(`common:auth.general.${field}`),
    });
    if (
      form &&
      !form[name].validity.valueMissing &&
      form[name].validationMessage
    ) {
      msgValidation = t(`common:${form[name].validationMessage}`);
    }

    return <span>{msgValidation}</span>;
  };

  SsoDomainValidationMessage = () => {
    let { t } = this.props;
    // domain tranlate does not registerd
    const msgValidation = t("common:message.login.entry-identifier");
    return <span>{msgValidation}</span>;
  };

  ActionSubmit = () => {
    let { t } = this.props;
    let { signingin } = this.state;
    //if (signingin) {
    //    return <FontAwesomeIcon icon={faSpinner} className="signing-in-loading" pulse />
    //} else {
    //return <Button type='submit'>{t('common:auth.general.login')}</Button>;
    return (
      <Box sx={{ position: "relative" }}>
        <Button
          variant="contained"
          size="large"
          disabled={signingin}
          type="submit"
        >
          {t("common:auth.general.login")}
        </Button>
        {signingin && (
          <CircularProgress
            size={24}
            sx={{
              //color: Primary,
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-12px",
              marginLeft: "-12px",
            }}
          />
        )}
      </Box>
    );
    //}
  };

  SsoLoginModalOpen = () => {
    this.setState({ modalShow: true });
  };

  SsoModal = () => {
    const { t } = this.props;
    const { SsoDomainValidationMessage } = this;
    const { validatedSso, formData, modalShow } = this.state;

    return (
      <Modal
        show={modalShow}
        onHide={(ev) => {
          this.modalToggle(false);
        }}
        centered
      >
        <Form
          validated={validatedSso}
          onSubmit={this.ssoFormSubmitHandler}
          noValidate
        >
          <Modal.Header closeButton>
            <Modal.Title>{t("common:auth.login.sso")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Row>
              <Form.Group
                as={Col}
                controlId="domain"
                className="custom-form-outline"
              >
                <Form.Label>{t("common:auth.login.identifier")}</Form.Label>
                <Form.Control
                  name="domain"
                  type="text"
                  placeholder={t("common:auth.login.identifier")}
                  value={formData.domain}
                  onChange={this.onChangeHandler}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  <SsoDomainValidationMessage name="domain" field="domain" />
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="contained" type="submit">
              {t("common:auth.general.login")}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  };

  async handleConfirmOtpDialog(otp) {
    this.setState({
      openOtpDialog: false,
    });
    const userInfo = await GetUserInfoNew().catch((err) => {
      //(err.response);
      if (err.hasOwnProperty("data")) {
        errProps.set({
          msg: err.response.data.message,
          code: err.response.status,
          show: true,
        });
      } else {
        errProps.set({
          msg: "Connection error, please try to reload.",
          code: 400,
          show: true,
        });
      }
    });
    localStorage.setItem("user-info", JSON.stringify(userInfo));
    userInfoState.set(userInfo);
    if (
      window.location.pathname === "/login" ||
      window.location.pathname === "/logout"
    ) {
      this.props.history.push({
        pathname: `/`,
      });
    } else {
      this.props.history.go(0);
      // window.location.reload();
      // this.setState({ form, signingin: false });
    }
  }

  resendEmailCode() {
    this.loginHandler(this.state.form, this.state.formData, true);
  }

  render() {
    let { t } = this.props;
    let { validated, formData, openOtpDialog, isVerifyEmail } = this.state;
    let { ActionSubmit, ValidationMessage, SsoModal } = this;
    return (
      <div className="auth-contrainer">
        <div className="auth contrainer-wrapper horizontal mx-2 mx-sm-0">
          <div className="contrainer-block">
            <div className="logo container-sm w-100">
              <img src={logo} className="App-logo" alt="paperlogic" />
            </div>

            <div className="form-wrapper login container-sm w-100">
              <div className="form-header">
                <div className="header-info">
                  {t("common:auth.login.title")}
                </div>
                <div className="faqButton">
                  <IconButton
                    aria-label="FAQ"
                    href="https://support.paperlogic.jp/"
                    target="_blank"
                    title={t('common:tooltip.common.go-to-faq')}
                  >
                    <HelpOutlineIcon />
                  </IconButton>
                </div>

                {/* <div className="registration-link">
                                    {/* <a href="/registration">{t('common:auth.login.registration')}</a> */}
                {/* </div> */}
              </div>
              <Form
                validated={validated}
                // id={"form_" + this.name}
                onSubmit={this.formSubmitHandler}
                noValidate
              >
                <div className="fields-container">
                  <Form.Row>
                    <Form.Group
                      as={Col}
                      controlId="username"
                      className="custom-form-outline"
                    >
                      <Form.Label>{t("common:auth.general.email")}</Form.Label>
                      <Form.Control
                        name="username"
                        type="text"
                        placeholder={t("common:auth.general.email")}
                        value={formData.username}
                        onChange={this.onChangeHandler}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        <ValidationMessage name="username" field="email" />
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Form.Row>

                  {/* <TextField
                                        helperText="Please enter your name"
                                        id="outlined-basic"
                                        label={t('common:auth.general.email')}
                                        variant="outlined"
                                        fullWidth
                                        defaultValue={formData.username}
                                        onChange={this.onChangeHandler}
                                        required
                                    /> */}
                  {/* <TextField
                                        helperText="Please enter your name"
                                        id="outlined-basic"
                                        label={t("common:auth.general.password")}
                                        variant="outlined" 
                                    /> */}

                  <Form.Row>
                    <Form.Group
                      as={Col}
                      controlId="password"
                      className={`custom-form-outline ${formData.password ? 'has-value' : 'empty-value'}`}
                    >
                      <Form.Label>
                        {t("common:auth.general.password")}
                      </Form.Label>
                      <div className={"password-input"}>
                        <Form.Control
                            name="password"
                            type={this.state.showPassword ? "text" : "password"}
                            placeholder={t("common:auth.general.password")}
                            value={formData.password}
                            onChange={this.onChangeHandler}
                            required
                        />
                        <span className="password-toggle" onClick={this.togglePasswordVisibility}>
                          {this.state.showPassword ? <VisibilityIcon/> : <VisibilityOff/>}
                        </span>
                      </div>
                      <Form.Control.Feedback type="invalid">
                        <ValidationMessage name="password" field="password" />
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Form.Row>
                </div>

                <div className="form-bottom d-block d-sm-flex">
                  <div className="registration-option">
                    <div>
                      <a href="/forgot-password">
                        {t("common:auth.login.forgot-password")}
                      </a>
                    </div>
                    <div>
                      <a href="/registration/resend-email">
                        {t("common:auth.registration.resend-email.link")}
                      </a>
                    </div>
                  </div>
                  <div className="wrapper-button-submit">
                    <ActionSubmit />
                  </div>
                </div>
              </Form>
            </div>
            <div className="text-center mt-2">
              <Button onClick={this.SsoLoginModalOpen}>
                {t("common:auth.login.sso")}
              </Button>
            </div>
          </div>
          <SsoModal />

          <div className="footer">
            <div>© {new Date().getFullYear()} paperlogic.co.jp</div>
          </div>
        </div>
        <OtpDialog
          openOtpDialog={openOtpDialog}
          isVerifyEmail={isVerifyEmail}
          mfaChallengeName={this.state.mfaChallengeName}
          mfaUser={this.state.mfaUser}
          resendEmailCode={this.resendEmailCode.bind(this)}
          callback={this.handleConfirmOtpDialog.bind(this)}
        />
      </div>
    );
  }
}

export default withTranslation()(Login);
