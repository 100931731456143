// @ts-nocheck
import { hookstate, useHookstate } from "@hookstate/core";
import Chip from "@mui/material/Chip";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
// import { userRoles } from "src/app/data-access/user/user.state";
// import { BaseRoles, rolesTranslateItem } from "src/app/utils/user-role-mapping";
import styles from "./roles-chip.module.css";
export const userRoles = hookstate({
  signer_role: 0,
  sender_role: 0,
  circle_role: 0,
  verification_role: 0,
  evaluation_role: 0,
  filebox_role: 0,
  administrator_role: 0,
  certificate_role: 0,
});

export const rolesTranslateItem = {
  signer_role: "document.function-type.signer",
  sender_role: "document.function-type.sender",
  circle_role: "document.function-type.circle",
  verification_role: "document.function-type.verification",
  evaluation_role: "document.function-type.evaluation",
  filebox_role: "document.function-type.filebox",
  administrator_role: "settings.user.administrator-role",
  certificate_role: "settings.user.certificate-role",
};
export default function RolesChip() {
  const roles = useHookstate(userRoles);
  const { t } = useTranslation("common");
  const rolesGet = roles.get({ noproxy: true });
  //   useEffect(() => {
  //     return () => {
  //       userRoles.set({
  //         signer_role: 0,
  //         sender_role: 0,
  //         circle_role: 0,
  //         verification_role: 0,
  //         evaluation_role: 0,
  //         filebox_role: 0,
  //         administrator_role: 0,
  //         certificate_role: 0,
  //       });
  //     };
  //   }, []);
  return (
    <div className={`${"role_box"} ${styles["container"]}`}>
      {Object.keys(rolesGet).map((a, i) => (
        <Chip
          key={i}
          label={t(rolesTranslateItem[a])}
          color={rolesGet[a] === 1 ? "secondary" : "default"}
          className={`${rolesGet[a] === 1 ? styles["active_chip"] : ""} role_box_item`}
          onClick={() => roles[a].set(rolesGet[a] === 1 ? 0 : 1)}
        />
      ))}
    </div>
  );
}
