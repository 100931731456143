// @ts-nocheck
import { useEffect, useState } from "react";
import { useLocation } from "react-router";

export function UrlHook() {
  const location = useLocation();
  const [hash, setHash] = useState(window.location.hash);

  useEffect(() => {
    const updateHash = () => {
      setHash(window.location.hash);
    };

    // Listen for popstate event
    window.addEventListener('popstate', updateHash);

    // Override pushState and replaceState to detect hash changes
    const pushState = window.history.pushState;
    window.history.pushState = function(...args) {
      pushState.apply(this, args);
      updateHash();
    };

    const replaceState = window.history.replaceState;
    window.history.replaceState = function(...args) {
      replaceState.apply(this, args);
      updateHash();
    };

    // Cleanup
    return () => {
      window.removeEventListener('popstate', updateHash);
      window.history.pushState = pushState; // Restore original methods
      window.history.replaceState = replaceState; // Restore original methods
    };
  }, []);

  // console.log(hash)
  const updateBodyClass = () => {
    const pathname = window.location.pathname;
    const hashReplace = hash.slice(1);
    document.body.className = "";
    const paths = [
      "signer",
      "sender",
      "circle",
      "verification",
      "evaluation",
      "search",
    ];
    paths.map((v) => {
      if (pathname === `/${v}`) document.body.classList.add(v);
    });
    if (pathname.includes("/documents/detail"))
      document.body.classList.add("detail");
    if (pathname.includes("/documents/edit"))
      document.body.classList.add("edit");
    const hashs = [
      "documentInfo",
      "hostSetting",
      "guestSetting",
      "viewerSetting",
      "documentPrepare",
      "determine",
    ];
    
    hashs.map((val) => {
      if (
        pathname.includes("/documents") &&
        hashReplace?.toLowerCase() === val.toLowerCase()
      )
        document.body.classList.add(val);
    });
  };
  useEffect(() => {
    updateBodyClass();
  }, [hash]);


  useEffect(() => {
    updateBodyClass();
  }, [location]);

  return <></>;
}

export default UrlHook;
