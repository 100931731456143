import Auth from "./Auth";
import Service from ".";
import axios from "axios";
import API from "../Config/API";

const idp_url = API.idp_url;

const SsoService = class {
  /**
   * get Axios config
   */
  static axioxBaseConfig = async () => {
    const tokenID = await Auth.GetTokenID();
    return {
      headers: {
        Authorization: tokenID,
        "Content-Type": "application/json",
      },
    };
  };
  /**
   * parse tenant id
   *
   * not to be number and less than 2 characters
   */
  static getProviderName = async () => {
    const { company_id, tenant_id } = await Service.GetUserInfo();
    const providerName = company_id + "-" + tenant_id;
    return providerName;
  };
  /**
   * parse input data to api body data
   */
  static parseData = (data) => {
    let body = {
      providerName: data.providerName,
      domain: data.domain,
    };
    if (data.entity_id) {
      body.entityId = data.entity_id
    }
    if (data.public_key) {
      body.idpCert = data.public_key;
    }
    if (data.endpoint_url) {
      body.ssoEndpoint = data.endpoint_url;
    }
    if (data.logout_url) {
      body.sloEndpoint = data.logout_url;
    }
    if (data.xml_url) {
      body.metadataUrl = data.xml_url;
    }
    if (data.sso_config) {
      body.sso_config = data.sso_config;
    }
    return body;
  };
  static isNotFoundError = (e) => {
    return (
      e?.response?.status === 404 ||
      e?.response?.data?.message === "ResourceNotFoundException"
    );
  };
  static GetIdp = async () => {
    try {
      const providerName = await this.getProviderName();
      const config = await this.axioxBaseConfig();
      const url = new URL(idp_url + "/" + providerName);
      const response = await axios.get(url, config);
      const domain = response.data.idpIdentifiers.join(',')
      const data = response.data.providerDetails;
      return {
        entity_id: data.entityId,
        endpoint_url: data.ssoRedirectBindingUrl,
        public_key: data.x509Cert,
        logout_url: data.sloRedirectBindingUrl,
        xml_url: data.metadataUrl,
        domain: domain,
        sso_config: response.data.sso_config
      };
    } catch (e) {
      if (this.isNotFoundError(e)) {
        return null;
      }
      console.error(e.response.data.message);
      throw e;
    }
  };
  static SaveIdp = async (data) => {
    const providerName = await this.getProviderName();
    const config = await this.axioxBaseConfig();
    const url = new URL(idp_url);
    const response = await axios.post(
      url,
      this.parseData({
        ...data,
        providerName,
      }),
      config
    );
    return response.data;
  };
  static UpdateIdp = async (data) => {
    const providerName = await this.getProviderName();
    const config = await this.axioxBaseConfig();
    const url = new URL(idp_url);
    const response = await axios.put(
      url,
      this.parseData({
        ...data,
        providerName,
      }),
      config
    );
    return response.data;
  };
  static DeleteIdp = async () => {
    try {
      const providerName = await this.getProviderName();
      const config = await this.axioxBaseConfig();
      const url = new URL(idp_url + "/" + providerName);
      const response = await axios.delete(url, config);
      return response.data;
    } catch (e) {
      if (this.isNotFoundError(e)) {
        return null;
      }
      throw e;
    }
  };
};

export default SsoService;
