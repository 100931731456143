import React, { useRef } from 'react'
import '../../Assets/css/pdf-preview.css';


const DraggableListItem = props => {

    const itemRef = useRef(null)

    const onDragStart = (e) => {
        let ghostNode = e.target.cloneNode(true)
        ghostNode.style.position = "absolute"
        ghostNode.style.opacity = '0'
        ghostNode.style.pointerEvents = 'none'
        ghostNode.id = 'ghostNode'
        document.body.prepend(ghostNode)
        itemRef.current.classList.add('dragstart')
        if (props.onDragStart) {
            props.onDragStart(props.index)
        }
    }

    // event when dragging
    const onDrag = (e) => {
        let ghostNode = document.querySelector('#ghostNode')
        ghostNode.style.top = (e.pageY - e.target.offsetHeight /2) + 'px'
        ghostNode.style.left = (e.pageX - e.target.offsetWidth /2) + 'px'
    }

    // event when drag end
    const onDragEnd = () => {
        document.querySelector('#ghostNode').remove()
        itemRef.current.classList.remove('dragstart')
    }

    // event when drag over item
    const onDragEnter = () => {
        itemRef.current.classList.add('dragover')
    }

    // event when drag leave item
    const onDragLeave = () => {
        itemRef.current.classList.remove('dragover')
    }

    // add event for item can drop
    const onDragOver = (e) => {
        e.preventDefault();
    }

    // event when drop
    const onDrop = () => {
        itemRef.current.classList.remove('dragover')
        props.onDrop(props.index)
    }

    return (
        <li
            ref={itemRef}
            className={`file-item ${props.selected && "active"} ${props.isDisabled && !props.selected? 'disabled_click': ''} draggable-list__item`}
            draggable={props.draggable !== undefined ? props.draggable : true}
            onDragStart={onDragStart}
            onDrag={onDrag}
            onDragEnd={onDragEnd}
            onDragEnter={onDragEnter}
            onDragLeave={onDragLeave}
            onDragOver={onDragOver}
            onDrop={onDrop}
            data-file={props.dataFile}
        >
            {props.children}
        </li>
    )
}

export default DraggableListItem
