// @ts-nocheck
import React from 'react';
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { UserInfoContext } from "../../Context/UserInfoContext";
import CountSignedDoc from './CountSignedDoc';
import { StateFragment } from '@hookstate/core';
import { userInfoState } from '../../drive-feature/data-access/state';
import { Divider } from '@mui/material';
import { MenuConfig } from '../../Config/Menu';

class Menu extends React.Component {
    static contextType = UserInfoContext;

    constructor(props) {
        super(props);
        // this.state.user_info = null;
        this.state = {
            user_info: null,
        }
    }

    // componentDidMount() {
    //   const user_info = this.context.userInfoContent;
    //   this.setState({ user_info });
    // }

    render() {
        let { t, page } = this.props;
        // let { user_info } = this.state;
        return <StateFragment state={userInfoState}>{s => {
            const user_info = s.get({noproxy: true});
            // console.log('user_info', user_info)
            if (!user_info) {
                // return <Loading />;
                return null;
            }
    
            let menuKeys = Object.keys(MenuConfig);
            // let { t, page } = props;
    
            let MenuComponent = menuKeys.map((key) => {
                let Menu = MenuConfig[key];
                let IconMenu = Menu.icon;
                let classname = "menu-item-custom";
                if (page === Menu.name) {
                    classname += " active";
                }

                if (user_info.partner_id == "7" && ["signer", "circle"].includes(key)) {
                    return null;
                }

                if (user_info.archive_mode == "1" && ["signer", "sender", "circle", "drive", "business_number"].includes(key)) {
                    return null;
                }
    
                if (user_info.partner_id == "1" && ["signer", "sender", "circle"].includes(key)) {
                    return null;
                }
    
                if (key === 'drive' && user_info['filebox' + "_role"] == "0"){
                    return null
                }
                
                if (key + "_role" in user_info && user_info[key + "_role"] == "0") {
                    return null;
                }
                // console.log(Menu)    
    
                return (
                    <React.Fragment key={Menu.text}>
                         {Menu.link === '/business-search' ? <Divider sx={{marginBottom: '30px'}} />:null}
                         <li  className={classname}>
                            <Link to={Menu.link} className={`${"avatar-custom"} ${Menu.link === '/business-search' ? 'include_subtitle':''}`}>
                                <div className="icon-avatar">
                                    <IconMenu />
                                </div>
                                <div className="text-avatar" >
                                    {Menu.link === '/business-search' ? <span className="subtitle_business">{t('common:business.number.search.link.subtitle')}</span>:null}
                                    <span>{t(Menu.text)}</span>
                                </div>
                            </Link>
                            <div className="document-count" >
                                <CountSignedDoc Menu={Menu}/>
                            </div>
                        </li>
                    </React.Fragment>
                   
                );
            });
    
            return (
                <ul className="menu-custom">
                    {MenuComponent}
                </ul>
            );
        }}</StateFragment>
        
    }
}
export default withTranslation()(Menu);
