// @ts-nocheck
import { LoadingButton } from "@mui/lab";
import { Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import BaseDialogDriveCrud from "./drive-base-dialog";

export default function DeliveryConfirmDialog({
  message,
  title,
  onClose,
  open,
  loading,
}) {
  const { t } = useTranslation();
  return (
    <BaseDialogDriveCrud
      handleClose={onClose}
      open={open}
      title={title}
      zIndex={999999}
      dialogContentComponent={
        <Typography variant="body1">{message}</Typography>
      }
      dialogActionComponent={
        <>
          <Button variant="outlined" onClick={onClose}>
            {t("common:drive.button.close")}
            {/* 閉じる */}
          </Button>
          <LoadingButton
            loading={loading}
            variant="contained"
            onClick={(e) => onClose(e, true)}
          >
            OK
          </LoadingButton>
        </>
      }
    />
  );
}
