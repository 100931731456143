import Auth from "./Auth"
import Service from "."
import axios from 'axios';
import API from '../Config/API';

/** */
const OrganizationService = class {

	/** Get Organization list */
	static getList = async (data = {}) => {

		/* */
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		/* */
		if (!UserInfo || !tokenID) {
			return false;
		}

		/* */
		if(!data.hasOwnProperty("tenant_id")) {
			data["tenant_id"] = UserInfo["tenant_id"];
		}

		/* */
		const url = new URL(API.url + "/organization.json ");

		/* Convert RequestBody to URL-QueryString */
		url.searchParams.set("tenant_id", data["tenant_id"]);
		
		/* Waiting HTTP-Response before return */
		return await new Promise((resolve, reject) => {

			/* Send HTTP-Request */
			axios.get(url, {
				headers: {
					Authorization: tokenID,
				},
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});

		});

	}

	/** Save */
	static save = async (data = {}) => {

		/* */
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		/* */
		if (!UserInfo || !tokenID) {
			return false;
		}

		/* */
		if(!data.hasOwnProperty("tenant_id")) {
			data["tenant_id"] = UserInfo["tenant_id"];
		}
		
		/* */
		if(data['tenant_id'] === null) {
			data["tenant_id"] = UserInfo["tenant_id"];
		}
		
		/* */
		if(data['tenant_id'] === "") {
			data["tenant_id"] = UserInfo["tenant_id"];
		}

		/* */
		const url = new URL(API.url + "/organization.json ");
		
		/* Waiting HTTP-Response before return */
		return await new Promise((resolve, reject) => {

			/* Send HTTP-Request */
			axios.post(url, data, {
				headers: {
					Authorization: tokenID,
				},
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});

		});

	}

	static DownloadCsvOrganization = async () => {
		const uri = API.url + "/organization/csvDownload.json";
		// const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		let url = new URL(uri);
		url = url.toString();

		return await new Promise((resolve, reject) => {
			axios.get(url, {
				headers: {
					Authorization: tokenID,
				},
			}).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	/** */
	static UploadCsvOrganization = async (data) => {

		/* */
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		/* */
		if (!UserInfo || !tokenID) {
			return false;
		}

		/* */
		const url = new URL(API.url + "/organization/csvUpload.json");

		/* */
		return await new Promise((resolve, reject) => {
			axios.post(url, data, {
				headers: {
					Authorization: tokenID,
				}
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});

	}

	static delete = async (data) => {
		const uri = API.url + "/organization/delete.json";
		// const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		let url = new URL(uri);
		url = url.toString();

		return await new Promise((resolve, reject) => {
			axios.post(url, data, {
				headers: {
					Authorization: tokenID,
				}
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

}

export default OrganizationService;