import React from 'react'
import { withTranslation } from 'react-i18next';
import Layout from '../../Layout';
import Menu from "./Menu";
import Loading from "../Loading";
import { Col, Form, InputGroup } from "react-bootstrap";
import Button from "@mui/material/Button";
import Service from '../../Service';
import { userInfoState } from '../../drive-feature/data-access/state';
class ExpirationNotification extends Layout {
	constructor(props) {
		super(props);
		this.state.page = "expiration-notification";

		this.state.switchLoading = false;
		this.state.use_restrictIp = false;
		this.state.fields = {};
		let { t } = this.props;
		this.state.keyFields = {
			dateofnoti: t("common:documents.document-info.date-noti-"),
			select_datenoti: t("common:documents.document-info.select_datenoti"),
		};
	}

	componentDidMountExtends = async () => {
		let { UserInfo } = this.state;
		const data = {
			tenant_id: UserInfo.current_tenant_id
		}
		const tenantData = await Service.getTenant(data)
		const formData = {
			dateofnoti: tenantData['dateofnoti'] ?? "1",
			select_datenoti: tenantData['select_datenoti'] ?? '',
		};
		this.setState({ formData });
		this.setStateFields();
	};

	setStateFields = (cb) => {
		// let { t } = this.props;
		let { keyFields, fields, formData } = this.state;
		for (let key in keyFields) {
			let label = keyFields[key];
			fields[key] = {
				name: key,
				value: "",
				label,
				// label: t(`common:settings.sso.${label}`),
			};
			if (formData[key]) {
				fields[key].value = formData[key];
			}
		}

		this.setState({ fields },cb);
	};

	Menu = () => {
		return <Menu {...this.props} page={this.state.page} />;
	};

	updateTenant = async () => {
		let { t } = this.props;
		let { modalTitle,ModalBody,modalProps } = this.state;
		modalProps = { ...modalProps,show: true, centered: true };
		modalTitle = "";
		ModalBody = Loading;
		this.setState({ modalTitle, ModalBody, modalProps });

		let { UserInfo, formData } = this.state;
		const data = {
			tenant_id: UserInfo.current_tenant_id
		}
		const tenantData = await Service.getTenant(data)
		let body = {
			"tenant_id": tenantData["tenant_id"],
			"company_id": tenantData["company_id"],
			"dateofnoti": formData["dateofnoti"],
			"select_datenoti": formData["select_datenoti"]
		};
		UserInfo['dateofnoti'] = formData["dateofnoti"];
		UserInfo['select_datenoti'] = formData["select_datenoti"];

		await Service.UpdateTenant(body).then(() => {
			this.setState({ 
				modalTitle: t("common:common.general.successful"),
				ModalBody: () => <>{t("common:common.general.registered")}</> 
			});
			userInfoState.set(UserInfo);
			localStorage.setItem('user-info', JSON.stringify(UserInfo))
		}).catch(() => {
			this.setState({ modalTitle: t("common:common.general.error"),ModalBody: () => <>{t("common:settings.ip.error-occurred")}</> });
		})

	}

	DocdateOfNotice = () => {
		let { t } = this.props;
		let items = [1, 60, 90, 120, 0];
		let ItemElements = [];
		let { formData } = this.state;
		for (let i of items) {
			let label = t(`common:documents.document-info.date-noti-${i}`);
			if (i == 0) {
				label = t(`common:documents.document-info.select_datenoti_setting`);
			} else if (i == 1) {
				label = t(`common:document.preservation-require.not-setting`);
			}
			
			ItemElements.push(<option key={i} value={i}>{t(label)}</option>);
		}
		let radioProps = {
			"onChange": (ev) => {
				if (ev.target.value) {
					formData.select_datenoti = null;
				}
				formData.dateofnoti = parseInt(ev.target.value);
				this.setState({formData});
			},
		};
		
		return (
			<Form.Group>
				<InputGroup>
					<Form.Control
						name='dateofnoti'
						value={formData.dateofnoti}
						as='select'
						children={ItemElements}
						{...radioProps}
					/>
				</InputGroup>
			</Form.Group>
		);
	}

	SelectDateNoti = () => {
		const { formData } = this.state;
		let { t } = this.props;

		let selectDateNotiValidate = false;
		if (formData.dateofnoti == '0') {
			selectDateNotiValidate = true;
		}

		return (
			selectDateNotiValidate && 
			<Form.Group className={`px-4 col-md-4 col-8 ${formData.select_datenoti ? 'was-validated' : ''}`}>
				<InputGroup hasValidation>
					<Form.Control
						name='select_datenoti1'
						value={formData.select_datenoti}
						type='date'
						required
						onChange={(ev) => {
							formData.select_datenoti = ev.target.value;
							this.setState({formData});
						}}
						isValid={formData.select_datenoti && formData.select_datenoti >= new Date()}
						isInvalid={!formData.select_datenoti || formData.select_datenoti < new Date()}
					/>
					<Form.Control.Feedback type="invalid">
						{t('common:message.input.required')}
					</Form.Control.Feedback>
				</InputGroup>
			</Form.Group>
		);
	};

	render() {
		let { t } = this.props;
		const { formData } = this.state;
		const { Main, updateTenant, DocdateOfNotice, SelectDateNoti } = this;

		return (
			<Main>
				<div className="p-4">
					<Form.Row className="p-4">
						<span className="label">{t("common:settings.expiration-notification.initial")}</span>
					</Form.Row>
					
					<Form.Row className="px-4">
						<DocdateOfNotice />
						<SelectDateNoti />
					</Form.Row>

					<Form.Row className="p-4">
						<Button
							className="text-center"
							variant="contained"
							disabled={formData.dateofnoti == null || (formData.dateofnoti == '0' && !formData.select_datenoti)}
							onClick={() => updateTenant()}
						>
							<span className="label">{t("common:common.general.confirmed")}</span>
						</Button>
					</Form.Row>

				</div>
			</Main>
		);
	}
}

export default withTranslation()(ExpirationNotification);
