// @ts-nocheck
import axios from "axios";
import i18next from "i18next";
import { combineLatest, concatMap, from, of } from "rxjs";
import AuthService from "../../Service/Auth";
import mappedDataToTreeView from "../utils/mappedDataToTreeView";
import {
  createFile,
  generateMultiplePresignedUpload, getFoldersDetail, uploadToS3
} from "./api";
import { getCompanyFoldersContent } from "./company-folders/company-folders.api";
import { driveFoldersDetail, folderDetailSearch, notificationAlertState, progessUploadFileGlobal } from "./state";

export function uploadFileAction(files, currentFolderId, progessCb, loading, folderType, onClose) {
  //   const file = data.files[0];
  const presign = [];
  const filesMap = {};
  progessUploadFileGlobal.totalFile.set(files.length);
  for (var i = 0; i < files.length; i++) {
    const f = files[i];
    presign.push({
      file_tail: f.name.split(".").pop(),
      file_type: f.type,
      id: i,
      name: f.name,
      size: f.size,
    });
    filesMap[i] = f;
  }
  let upload;
  generateMultiplePresignedUpload(presign).catch(e => {

    notificationAlertState.set({
      show: true,
      type: "error",
      message: `${i18next.t(
        "common:drive.message.error.upload",
        "Upload fail, please try again!"
      )} ${e?.response?.data?.message || e
        }`,
    });
    progessUploadFileGlobal.complete.set(0);
    progessUploadFileGlobal.totalFile.set(0);
  }).then((r) => {
    upload = r.data.payload;
    from(r.data.payload)
      .pipe(
        concatMap((file) => {
          return combineLatest([
            from(
              uploadToS3(file.url, filesMap[file.id], file.file_type, progessCb)
            ),
            of(file),
          ]);
        })
      )
      .subscribe({
        next: ([r, file]) => {
          createFile({
            title: "",
            name: file.name,
            url: file.url.split("?")[0],
            size: file.size,
            extension: file.name.split('.').pop(),
            drive_folder_id: +currentFolderId || null,
            type: folderType
          })
            .then((res) => {
              if (file.id === upload[upload.length - 1]?.id) {
                getNewDetailAndSetState(currentFolderId, folderType);
              }
              if (loading) {
                loading.set(false);
              }
              progessUploadFileGlobal.complete.set((v) => v + 1);
              progessUploadFileGlobal.progess.set(100);
              if (onClose)
                onClose();
              // console.log(res);
            })
            .finally(() => loading ? loading.set(false) : false);
        },
        error: (e) => {
          notificationAlertState.set({
            show: true,
            type: "error",
            message: `${i18next.t(
              "common:drive.message.error.upload",
              "Upload fail please try again!"
            )} ${e?.response?.data?.message || e
              }`,
          });
          progessUploadFileGlobal.complete.set(0);
          progessUploadFileGlobal.totalFile.set(0);
        },
        complete: () => {
          // console.log('cókcoànmoa');
          progessUploadFileGlobal.complete.set(0);
          progessUploadFileGlobal.totalFile.set(0);
          // console.log(folderType)

        }
      });
  });
}
export function getNewDetailAndSetState(currentFolderId, type) {
  folderDetailSearch.offset_folder_number.set((v) => 0);
  folderDetailSearch.offset_file_number.set((v) => 0);
  folderDetailSearch.offset_document_number.set((v) => 0);
  // console.log(type)
  if (type === 'company') {
    return getCompanyFoldersContent({
      drive_folder_id: currentFolderId,
      ...folderDetailSearch.get({ stealth: true, noproxy: true }),
    })
      .then((r4) => {
        if (r4?.data?.payload?.child) {
          folderDetailSearch.offset_folder_number.set((v) => v + (r4?.data?.payload?.child?.folders?.length || 0));
          folderDetailSearch.offset_file_number.set((v) => v + (r4?.data?.payload?.child?.files?.length || 0));
          folderDetailSearch.offset_document_number.set((v) => v + (r4?.data?.payload?.child?.documents?.length || 0));
          driveFoldersDetail.data[
            currentFolderId ? currentFolderId : "root"
          ].set([
            ...(r4?.data?.payload?.child?.folders?.map(mappedDataToTreeView) ||
              []),
            ...(r4?.data?.payload?.child?.files?.map(mappedDataToTreeView) ||
              []),
            ...(r4?.data?.payload?.child?.documents?.map(mappedDataToTreeView) ||
              []),
          ].reduce((arr, item) => {
            if (!arr.find((a) => a.id === item.id)) {
              arr.push(item);
            }
            return arr;
          }, []));
        }
      })
  }
  getFoldersDetail(
    currentFolderId,
    folderDetailSearch.get({
      stealth: true,
      noproxy: true,
    })
  ).then((r4) => {
    if (r4?.data?.payload?.child) {
      folderDetailSearch.offset_folder_number.set((v) => v + (r4?.data?.payload?.child?.folders?.length || 0));
      folderDetailSearch.offset_file_number.set((v) => v + (r4?.data?.payload?.child?.files?.length || 0));
      folderDetailSearch.offset_document_number.set((v) => v + (r4?.data?.payload?.child?.documents?.length || 0));
      driveFoldersDetail.data[
        currentFolderId ? currentFolderId : "root"
      ].set([
        ...(r4?.data?.payload?.child?.folders.map(mappedDataToTreeView) ||
          []),
        ...(r4?.data?.payload?.child?.files.map(mappedDataToTreeView) ||
          []),
        ...(r4?.data?.payload?.child?.documents?.map(mappedDataToTreeView) ||
          []),
      ].reduce((arr, item) => {
        if (!arr.find((a) => a.id === item.id)) {
          arr.push(item);
        }
        return arr;
      }, []));

    }
  });
}
export async function downloadFileActions(url, name, progessCb, token) {
  return axios
    .get(url, token !== 'not' ? {
      responseType: "blob",
      headers: { Authorization: !token ? await AuthService.GetTokenID() : token },
      onUploadProgress: progessCb,
    } : {
      responseType: "blob",
      onUploadProgress: progessCb,
    })
    .then((file) => {
      // console.log(file.data);
      const a = document.createElement("a"); //Create <a>
      const blobUrl = URL.createObjectURL(file.data);
      a.href = blobUrl; //Image Base64 Goes here
      a.download = name; //File name Here
      a.click(); //Downloaded file
      URL.revokeObjectURL(blobUrl);
    });
}
