// @ts-nocheck
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { CircularProgress, Tooltip } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import { driveFoldersNav, expandNodeState } from "../../data-access/state";
import TypeIcon from "./type-icon";

export default function DriveNavNode(props) {
  const { t } = useTranslation("common");
  const { droppable, data, fileType, notShowDropDownIcon } = props.node;
  const { folderId } = useParams();
  const location = useLocation();
  const indent = props.depth * 0;

  const handleClick = (e) => {
    // console.log(
    //   props.isOpen,
    //   driveFoldersNav.get({ stealth: true }),
    //   props.node.id,
    //   driveFoldersNav
    //     .get({ stealth: true })
    //     .find((a) => a.parent == props.node.id)
    // );
    props.onClick(e, props.node);
    if (
      !driveFoldersNav
        .get({ stealth: true })
        .find((a) => a.parent == props.node.id) &&
      !props.isOpen
    ) {
      handleToggle(e);
    }
    // console.log(props.id, folderId);
  };

  const handleToggle = (e) => {
    e.stopPropagation();
    props.onToggle(e, props.node.id, props.isOpen, props.node.type);
  };

  if (props.isSelected) {
    props.containerRef.current?.classList.add("selected");
  } else {
    props.containerRef.current?.classList.remove("selected");
  }

  if (props.isDragging) {
    props.containerRef.current?.classList.add("dragging");
  } else {
    props.containerRef.current?.classList.remove("dragging");
  }
  // console.log(location.pathname.includes(props.node.id.toString()));
  return (
    <div
      itemRef={props.handleRef}
      className={`tree-node root_node ${props.isSelected ? `isSelected` : ""} ${
        props.node.id == folderId ||
        (props.node.id === "company" &&
          location.pathname.includes(props.node.id.toString()))
          ? "node_active"
          : ""
      } ${
        props?.hightLightText?.length > 0 &&
        props.node.name.includes(props.hightLightText)
          ? "highlight_search"
          : null
      } ${
        location.pathname.includes(
          props.node.id.toString() === "delivery/list"
            ? "delivery"
            : props.node.id.toString()
        )
          ? "active_route_drive"
          : ""
      }`}
      style={{
        paddingInlineStart:
          props.node.id !== "drive" && props.node.id !== "delivery/list"
            ? indent
            : 10,
      }}
      onClick={handleClick}
      onDoubleClick={handleToggle}
    >
      {props.node.id === "drive" || props.node.id === "delivery/list" ? null : (
        <div className={`expandIconWrapper ${props.isOpen ? "isOpen" : ""}`}>
          {!notShowDropDownIcon ? (
            <div onClick={handleToggle}>
              {props.loadingTreeIndex &&
              props.node.id === props.loadingTreeIndex ? (
                <CircularProgress size={15} />
              ) : (
                <ArrowRightIcon />
              )}
            </div>
          ) : null}
        </div>
      )}
      <div>
        <TypeIcon
          isOpen={props.isOpen}
          droppable={droppable || props?.fileType === "trash"}
          node={props.node}
          fileType={fileType || "folder"}
        />
      </div>
      <div className={`labelGridItem`}>
        {props.disableToolTip ? 
        <Typography variant="body2">{t(props.node.name)}</Typography> 
        :   <Tooltip
              disableInteractive={true}
              placement="top"
              title={t(props.node.name)}
            >
              <Typography variant="body2">{t(props.node.name)}</Typography>
            </Tooltip> }
      </div>
    </div>
  );
}
