// @ts-nocheck
import { useHookstate } from "@hookstate/core";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import {
  FormControl,
  MenuItem,
  Select,
  SwipeableDrawer,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  NumericFormat,
  NumberFormatBase,
  useNumericFormat,
} from "react-number-format";
import Service from "../../../Service";
import formatDateIntlApi from "../../../Utils/format_date_intl";
import { updateDocument, updateFileOrFolder } from "../../data-access/api";
import {
  currencyOptionsState,
  driveFileDrawer,
  driveFoldersDetail,
  driveFoldersNav,
  folderDetailSearchState,
  notificationAlertState,
} from "../../data-access/state";
import { fullWidthNumConvert } from "../../utils/convertFullWidthToHalf";
const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(
  props,
  ref
) {
  const { format, onChange, removeFormatting, ...other } = useNumericFormat({
    ...props,
    thousandSeparator: ",",
    decimalSeparator: ".",
  });
  const _format = (val) => {
    const _val = format(fullWidthNumConvert(val), {});
    return format(_val);
  };

  const _removeFormatting = (val) => {
    return removeFormatting(fullWidthNumConvert(val));
  };
  // const format = (numStr) => {
  //   console.log("numStr", numStr);
  //   if (numStr === "") return "";
  //   return new Intl.NumberFormat("en-US").format(numStr);
  // };
  return (
    <NumberFormatBase
      {...other}
      type="text"
      getInputRef={ref}
      format={_format}
      removeFormatting={_removeFormatting}
      onValueChange={(values) => {
        console.log("(convertFullwidthToHalfwidth(values?.value)", values);
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      valueIsNumericString
    />
  );
});
export default function DriveFileInfo({ open, dataDetail, onClose }) {
  const { t } = useTranslation("common");
  // console.log("dataDetail", dataDetail);
  const methods = useForm({
    defaultValues: useMemo(() => {
      return {
        ...dataDetail,
      };
    }, [dataDetail]),
  });
  const currencyOptions = useHookstate(currencyOptionsState);
  const enabledInput = useHookstate({});

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    setFocus,
    watch,
  } = methods;
  function onEditClick(field) {
    enabledInput.set((v) => {
      if (v[field]) v[field] = !v[field];
      return v;
    });
    // switch (field) {
    //   case "memo":
    //     break;

    //   default:
    //     break;
    // }
  }

  useEffect(() => {
    if (dataDetail) {
      // console.log(dataDetail);
      enabledInput.set(
        Object.keys(dataDetail).reduce((o, item) => {
          o[item] = true;
          return o;
        }, {})
      );
      reset(dataDetail);
    }
  }, [dataDetail]);
  useEffect(() => {
    if (open && currencyOptions?.value?.length === 0) {
      Service.GetPageData("signer");
    }
    // if (!enabledInput.value.memo) {
    // console.log(control);
    // }
  }, [open, currencyOptions]);
  function onSaveData(data, field) {
    // console.log(data,field, driveFileDrawer.get())
    if (!driveFileDrawer.get().open) return;
    enabledInput.set((v) => {
      if (field === "amount" || field === "currency") {
        v["amount"] = !v["amount"];
        v["currency"] = !v["currency"];
        return v;
      }
      v[field] = !v[field];
      return v;
    });
    if (dataDetail.document_id) {
      return updateDocument({
        ...dataDetail,
        memo: data.memo,
        // [field]: data[field],
        document_id: dataDetail.document_id,
      })
        .then((r) => {
          if (!r) return;
          if (
            driveFoldersDetail.data[
              data.parent === "drive" ? "root" : data.parent
            ].get({ stealth: true })
          ) {
            driveFoldersDetail.data[
              data.parent === "drive" ? "root" : data.parent
            ].set((v) => {
              return v.map((a) => {
                if (a.id === data.id) {
                  a = data;
                }
                return a;
              });
            });

            // onClose();
          }
          if(folderDetailSearchState.get({noproxy: true}).length){
            folderDetailSearchState.set((v) => {
              return v.map((a) => {
                if (a.id === data.id) {
                  a = data;
                }
                return a;
              });
            });
          }
        })
        .catch((e) => {
          notificationAlertState.set({
            show: true,
            message: `${t(
              "common:drive.message.error.update_item",
              "Update item fail !"
            )} ${e?.response?.data.message || e}`,
            type: "error",
          });
          reset({
            [field]: dataDetail[field],
          });
        });
    }
    updateFileOrFolder({
      ...dataDetail,
      ...data,
      // [field]: data[field],
      id: dataDetail.id,
      type: dataDetail.extension === "folder" ? "folder" : "file",
    })
      .then((r) => {
        if (!r?.response?.data?.ok && !r?.data?.payload) {
          notificationAlertState.set({
            show: true,
            message: `${t(
              "common:drive.message.error.update_item",
              "Update item fail !"
            )} ${r?.response?.data.message || r}`,
            type: "error",
          });
          reset({
            [field]: dataDetail[field],
          });
          return;
        }
        if (!r) return;
        if (
          driveFoldersDetail.data[
            data.parent === "drive" ? "root" : data.parent
          ].get({ stealth: true })
        ) {
          driveFoldersDetail.data[
            data.parent === "drive" ? "root" : data.parent
          ].set((v) => {
            return v.map((a) => {
              if (a.id === data.id) {
                a = data;
              }
              return a;
            });
          });

          // onClose();
        }
      })
      .catch((e) => {
        notificationAlertState.set({
          show: true,
          message: `${t(
            "common:drive.message.error.update_item",
            "Update item fail !"
          )} ${e?.response?.data.message || e}`,
          type: "error",
        });
        reset({
          [field]: dataDetail[field],
        });
      });
    //   .finally(() => loading.set(false));
  }
  function numberWithCommas(x) {
    let parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }
  // console.log(dataDetail.document_id);
  return (
    <SwipeableDrawer
      hideBackdrop={true}
      open={open}
      anchor="right"
      onOpen={() => {}}
      // fullWidth
      classes={{
        paper: "drive_file_infomation",
      }}
      variant="persistent"
      onClose={(e, reason) => {
        // console.log(reason);
        if (reason === "backdropClick") {
          e.stopPropagation();
          return false;
        }
        return onClose(e, reason);
      }}
    >
      {/* memo: "", transaction_date: "", amount: "", currency: "",
      counterparty_name: "", */}
      <div className="drive_file_infomation_data_wrap">
        <div className="drive_file_infomation_field">
          <div className="drive_file_infomation_header_field">
            <Typography fontWeight={"bold"}>
              {t("common:drive.table_header.note", "メモ")}
            </Typography>
            {enabledInput.get()?.memo ? (
              <BorderColorIcon
                sx={{
                  cursor: "pointer",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  return onEditClick("memo");
                }}
                fontSize="small"
              />
            ) : null}
          </div>
          <div className="drive_file_infomation_form_field">
            <FormControl fullWidth>
              {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
              <Controller
                name={"memo"}
                control={control}
                // rules={rules}
                render={({ field, formState }) =>
                  !enabledInput.get()?.memo ? (
                    <TextField
                      {...field}
                      className={`bg-white`}
                      // onChange={onChange}
                      rows={3}
                      multiline={true}
                      size="small"
                      // value={value}
                      autoFocus={true}
                      onBlur={handleSubmit((data, e) => {
                        // console.log(data);
                        return onSaveData(data, "memo");
                      })}
                      InputLabelProps={{ style: { fontSize: 14 } }}
                      // value={textInputValue}
                      // placeholder={textInputPlaceHolder}
                      // label={textFieldLabel || 'Filled'}
                      variant="outlined"
                    />
                  ) : (
                    <Typography>{field.value}</Typography>
                  )
                }
              />
            </FormControl>
          </div>
        </div>

        <div className="drive_file_infomation_field">
          <div className="drive_file_infomation_header_field">
            <Typography fontWeight={"bold"}>
              {t("common:drive.table_header.transaction_date", "取引年月日　")}
            </Typography>
            {!!dataDetail?.document_id ? null : (
              <BorderColorIcon
                sx={{
                  cursor: "pointer",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  return onEditClick("transaction_date");
                }}
                fontSize="small"
              />
            )}
          </div>
          <div className="drive_file_infomation_form_field">
            <FormControl fullWidth>
              {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
              <Controller
                name={"transaction_date"}
                control={control}
                // rules={rules}
                render={({ field: { onChange, value }, formState }) => {
                  const formatedVal = value ? formatDateIntlApi(value) : "";
                  return enabledInput.get()?.transaction_date ? (
                    <Typography>
                      {formatedVal
                        ? `${formatedVal.year}-${formatedVal.month}-${formatedVal.day}`
                        : value}
                    </Typography>
                  ) : (
                    <div className="custom-form-outline form-group col drive_form_outlined">
                      <input
                        max="9999-12-31"
                        placeholder="契約開始日"
                        type="date"
                        onBlur={handleSubmit((data, e) => {
                          // console.log(data);
                          return onSaveData(data, "transaction_date");
                        })}
                        onChange={onChange}
                        value={value}
                        className="form-control"
                      />
                    </div>
                  );
                }}
              />
            </FormControl>
          </div>
        </div>

        <div className="drive_file_infomation_field">
          <div className="drive_file_infomation_header_field">
            <Typography fontWeight={"bold"}>
              {t("common:drive.table_header.counter_party_name", "相手先名")}
            </Typography>
            {!!dataDetail?.document_id ? null :<BorderColorIcon
              sx={{
                cursor: "pointer",
              }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                return onEditClick("counterparty_name");
              }}
              fontSize="small"
            />}
          </div>
          <div className="drive_file_infomation_form_field">
            <FormControl fullWidth>
              {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
              <Controller
                name={"counterparty_name"}
                control={control}
                // rules={rules}
                render={({ field: { onChange, value }, formState }) =>
                  !enabledInput.get()?.counterparty_name ? (
                    <TextField
                      className={`bg-white`}
                      disabled={enabledInput.get()?.counterparty_name}
                      onChange={onChange}
                      rows={3}
                      multiline={true}
                      size="small"
                      value={value}
                      onBlur={handleSubmit((data, e) => {
                        // console.log(data);
                        return onSaveData(data, "counterparty_name");
                      })}
                      InputLabelProps={{ style: { fontSize: 14 } }}
                      // value={textInputValue}
                      // placeholder={textInputPlaceHolder}
                      // label={textFieldLabel || 'Filled'}
                      variant="outlined"
                    />
                  ) : (
                    <Typography>{value}</Typography>
                  )
                }
              />
            </FormControl>
          </div>
        </div>

        <div className="drive_file_infomation_field">
          <div className="drive_file_infomation_header_field">
            <Typography fontWeight={"bold"}>
              {t("common:drive.table_header.amount", "金額")}
            </Typography>
            {!!dataDetail?.document_id ? null : (
              <BorderColorIcon
                sx={{
                  cursor: "pointer",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onEditClick("currency");
                  return onEditClick("amount");
                }}
                fontSize="small"
              />
            )}
          </div>
          <div className="drive_file_infomation_form_field">
            <FormControl className="grid-col-3" fullWidth>
              {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
              <Controller
                name={"amount"}
                control={control}
                // rules={rules}
                render={({ field: { onChange, value }, formState }) =>
                  !enabledInput?.amount?.get() ? (
                    <TextField
                      className={`bg-white align-right col-span-2 `}
                      onChange={(...e) => {
                        // console.log(e.target);
                        // e.target.value = fullWidthNumConvert(e.target.value);
                        return onChange(...e);
                      }}
                      size="small"
                      onBlur={handleSubmit((data, e) => {
                        // console.log(data);
                        return onSaveData(data, "amount");
                      })}
                      value={parseFloat(value)}
                      InputLabelProps={{ style: { fontSize: 14 } }}
                      InputProps={{
                        inputComponent: NumericFormatCustom,
                      }}
                      // value={textInputValue}
                      // placeholder={textInputPlaceHolder}
                      // label={textFieldLabel || 'Filled'}
                      variant="outlined"
                    />
                  ) : (
                    <Typography className="col-span-2 align-right">
                      {value ? numberWithCommas(parseFloat(value)) : value}
                    </Typography>
                  )
                }
              />
              <Controller
                name={"currency"}
                control={control}
                // rules={rules}
                render={({ field: { onChange, value }, formState }) =>
                  !enabledInput?.currency?.get() ? (
                    <Select
                      className={`bg-white`}
                      disabled={enabledInput.get()?.currency}
                      onChange={onChange}
                      value={value || "JPY"}
                      defaultValue={"JPY"}
                      size="small"
                      MenuProps={{
                        slotProps: {
                          backdrop: {
                            className: "mui_select_backdrop",
                          },
                        },
                      }}
                      onBlur={handleSubmit((data, e) => {
                        // console.log(data);
                        return onSaveData(data, "amount");
                      })}
                      InputLabelProps={{ style: { fontSize: 14 } }}
                      variant="outlined"
                    >
                      {currencyOptions.get({ noproxy: true }).map((a) => (
                        <MenuItem key={a.code} value={a.code}>
                          {a.label}
                        </MenuItem>
                      ))}
                    </Select>
                  ) : (
                    <Typography className="align-right">{value}</Typography>
                  )
                }
              />
            </FormControl>
          </div>
        </div>
      </div>
    </SwipeableDrawer>
  );
}
