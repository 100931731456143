import React from 'react';
import { Link } from "react-router-dom";
import logo from '../../Assets/img/Logo2.svg';
import '../../Assets/css/auth.css';
import { withTranslation } from "react-i18next";
import {
    Form,
    Col,
    Button,
    // InputGroup,
    // Dropdown,
    // DropdownButton,
} from 'react-bootstrap';
import AuthService from '../../../Service/Auth';
import Loading from '../../Loading';
import Error from '../../Error';
import Service from '../../../Service';
import VisibilityIcon from "@mui/icons-material/Visibility";
import {VisibilityOff} from "@mui/icons-material";

class UserVerification extends React.Component {
    constructor(props) {
        super(props);
        this.name = "UserVerification";

        this.state = {
            params: props.match.params,
            token: props.match.params.token,
            status: null,
            formValidate: false,
            success: true,
            loading: true,
            processing: false,
            verified: false,
            fieldValidate: {},
            formData: {
                password: null,
                password_confirm: null,
            },
            passwordVisible: false,
            confirmedPasswordVisible: false,
        };
    }

    componentDidMount() {
        // let { t } = this.props;
        let { token } = this.state;

        // status = t('common:auth.registration.verification.loading');
        document.title = this.props.t("common:auth.user.verification.title");

        Service.TemporaryTokenVerification(token).then(resp => {
            // console.log(resp);
            this.setState({ verified: true, loading: false });
        }).catch(err => {
            //(err.response);
            this.setState({ loading: false });
        });
    }

    componentDidUpdate(prevProps) {
        // document.title = this.props.t("common:auth.register.title");
        // console.log("componentDidUpdate", prevProps);
    }

    passowrdValidity = (form, fieldValidate, message) => {
        if (message) {
            form.password.setCustomValidity(message);
            form.password_confirm.setCustomValidity(message);
            // fieldValidate[this.name]['password'] = message;
            // fieldValidate[this.name]['password_confirm'] = message;
            fieldValidate['password'] = message;
            fieldValidate['password_confirm'] = message;
        } else {
            form.password.setCustomValidity("");
            form.password_confirm.setCustomValidity("");
        }
    }

    additionalValidity = (form) => {
        // console.dir(form);
        let { fieldValidate } = this.state;
        fieldValidate = {};

        // form.firstname.setCustomValidity(true);
        // fieldValidate[this.name]["firstname"] = "custom error.";

        if (form.password.value === '' || form.password_confirm.value === '') {
            this.passowrdValidity(form, fieldValidate, "message.input.required");
        } else if (form.password.value !== form.password_confirm.value) {
            this.passowrdValidity(form, fieldValidate, "message.errors.password.validate.not-match");
        } else {
            if (form.password.value.length < 12) {
                this.passowrdValidity(form, fieldValidate, "message.errors.password.validate.length-too-short");
            } else if (form.password.value.length > 32) {
                this.passowrdValidity(form, fieldValidate, "message.errors.password.validate.length-too-long");
            } else if (!(/[A-Z]/).test(form.password.value)) {
                this.passowrdValidity(form, fieldValidate, "message.errors.password.validate.wrong-policy");
            } else if (!(/[a-z]/).test(form.password.value)) {
                this.passowrdValidity(form, fieldValidate, "message.errors.password.validate.wrong-policy");
            } else if (!(/[0-9]/).test(form.password.value)) {
                this.passowrdValidity(form, fieldValidate, "message.errors.password.validate.wrong-policy");
            } else if (!(/!|@|#|&|%|,|_|~|<|>|=|\$|\^|\*|\(|\)|\?|\.|\||\[|\]|\{|\}|-|\+|\//).test(form.password.value)) {
                // } else if (!(/[!@#&%,_~<>=\$\^\*\(\)\?\.\|\[\]\{\}\-\+\/]/).test(form.password.value)) {
                this.passowrdValidity(form, fieldValidate, "message.errors.password.validate.wrong-policy");
            } else if ((/`|;|:|"|'|\\/).test(form.password.value)) {
                // } else if ((/[`;:\"\'\\]/).test(form.password.value)) {
                this.passowrdValidity(form, fieldValidate, "message.errors.password.validate.wrong-policy");
            }
        }

        this.setState({ fieldValidate });

        if (Object.keys(fieldValidate).length > 0) {
            return false;
        } else {
            return true;
        }
    }

    onChangeHandler = (event) => {
        let key = event.target.name;
        let val = event.target.value;
        let { formData } = this.state;
        formData[key] = val;
        this.setState({ formData });
    }

    formSubmitHandler = (ev) => {
        ev.preventDefault();

        let { t } = this.props;
        let { formValidate, status } = this.state;
        formValidate = true;
        this.setState({ formValidate });
        // console.log(email);
        let form = ev.target;
        form.password.setCustomValidity("");
        form.password_confirm.setCustomValidity("");

        if (ev.target.checkValidity() === false) {
            ev.stopPropagation();
        } else if (this.additionalValidity(form) === false) {
            ev.stopPropagation();
        } else {
            status = t('common:auth.user.verification.processing');
            this.setState({ processing: true, failure: false, status }, () => {
                let { token, formData, status } = this.state;
                AuthService.UserVerification(token, formData.password, formData.password_confirm).then(resp => {
                    // console.log(resp);
                    status = t('common:auth.user.verification.success');
                    this.setState({ status, processing: false });
                }).catch(err => {
                    //console.log(err.response);
                    if (err.response.data.error) {
                        status = t(`common:${err.response.data.error}`);
                    } else {
                        status = err.response.data.message;
                    }
                    this.setState({ status, processing: false, failure: true });
                });
            });
        }
    };

    TryAgainHandler = (ev) => {
        this.setState({
            status: null,
            formValidate: false,
            failure: false,
            processing: false,
        });
    }

    TryAgainButton = () => {
        let { t } = this.props;
        let { failure } = this.state;

        if (failure) {
            return <Button variant="primary" onClick={this.TryAgainHandler}>{t('common:auth.registration.processing.try-again')}</Button>;
        } else {
            return null;
        }

    }

    StatusMessage = () => {
        let { status, processing } = this.state;

        if (processing) {
            return <Loading />;
        }

        return (
            <p>{status}</p>
        );
    }

    ResultBody = () => {
        let { t } = this.props;
        let { TryAgainButton, StatusMessage } = this;

        return (
            <div className="form-container">
                <div className="fields-container">
                    <StatusMessage />
                </div>

                <div className="form-bottom">
                    <div className="wrapper-button-cancel">
                        <Link to='/login' style={{ width: '100%' }}>{t('common:auth.registration.success.goto-login')}</Link>
                    </div>
                    <div className="wrapper-button-submit">
                        <TryAgainButton />
                    </div>
                </div>
            </div>
        );
    }

    FormBody = () => {
        let { t } = this.props;
        let { formValidate, formData, fieldValidate } = this.state;

        let togglePasswordVisibility = (type) => {
            this.setState((prevState) => {
                switch (type) {
                    case "password":
                        return {passwordVisible: !prevState.passwordVisible}
                    case "confirmedPassword":
                        return {confirmedPasswordVisible: !prevState.confirmedPasswordVisible}
                }
            })
        };

        return (

            <div className="form-container">
                <Form
                    validated={formValidate}
                    id={"form_password_recovery"}
                    onSubmit={this.formSubmitHandler}
                    noValidate
                >

                    <div className="fields-container">

                        <Form.Row>
                            <Form.Group
                                as={Col}
                                controlId="password"
                                className={`custom-form-outline ${formData.password ? 'has-value' : 'empty-value'}`}
                            >
                                <Form.Label>{t("common:auth.general.password")}</Form.Label>
                                <div className={"password-input"}>
                                    <Form.Control
                                        name='password'
                                        type={this.state.passwordVisible ? 'text' : 'password'}
                                        placeholder={t("common:auth.general.password")}
                                        value={formData.password || ""}
                                        onChange={this.onChangeHandler}
                                        // required
                                    />
                                    <span className="password-toggle" onClick={() => togglePasswordVisibility("password")}>
                                      {this.state.passwordVisible ? <VisibilityIcon/> : <VisibilityOff/>}
                                    </span>
                                </div>
                                <Form.Control.Feedback type="invalid">
                                    {t(`common:${fieldValidate['password']}`, { field_name: t("common:auth.general.password") })}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row>

                        <Form.Row>
                            <div controlId="password_message" className="custom-form-outline col">
                                <div className="password_message">
                                    {t("common:message.general.password-input-rule")}
                                </div>
                            </div>
                        </Form.Row>

                        <Form.Row>
                            <Form.Group
                                as={Col}
                                controlId="password_confirm"
                                className={`custom-form-outline ${formData.password_confirm ? 'has-value' : 'empty-value'}`}
                            >
                                <Form.Label>{t("common:auth.registration.user.comfirm-password")}</Form.Label>
                                <div className={"password-input"}>
                                    <Form.Control
                                        name='password_confirm'
                                        type={this.state.confirmedPasswordVisible ? 'text' : 'password'}
                                        placeholder={t("common:auth.registration.user.comfirm-password")}
                                        value={formData.password_confirm || ""}
                                        onChange={this.onChangeHandler}
                                        // required
                                    />
                                    <span className="password-toggle" onClick={() => togglePasswordVisibility("confirmedPassword")}>
                                      {this.state.confirmedPasswordVisible ? <VisibilityIcon/> : <VisibilityOff/>}
                                    </span>
                                </div>
                                <Form.Control.Feedback type="invalid">
                                    {/* {t('common:message.input.required', { field_name: t("common:auth.registration.user.comfirm-password") })} */}
                                    {t(`common:${fieldValidate['password_confirm']}`, { field_name: t("common:auth.registration.user.comfirm-password") })}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row>

                    </div>
                    <Form.Row>
                    </Form.Row>

                    <div className="form-bottom">
                        <div className="wrapper-button-cancel">
                            {/* <Link className="btn btn-secondary" to="/login">{t('common:auth.general.cancel')}</Link> */}
                            <Link to='/login' style={{ width: '100%' }}>{t('common:auth.registration.success.goto-login')}</Link>
                        </div>
                        <div className="wrapper-button-submit">
                            <Button variant="primary" type='submit'>
                                <span>{t('common:auth.general.submit')}</span>
                            </Button>
                        </div>
                    </div>

                </Form>
            </div>
        );
    }

    BodyContent = () => {
        let { status } = this.state;
        let { FormBody, ResultBody } = this;

        if (status) {
            return <ResultBody />;
        }

        return <FormBody />;
    }

    Body = () => {
        let { t } = this.props;
        let { loading } = this.state;
        let { BodyContent } = this;

        if (loading) {
            return <Loading />;
        }

        return (
            <div className="form-wrapper registration" style={{ width: "600px" }}>

                <div className="logo-horizontal">
                    <img src={logo} className="App-logo" alt="paperlogic" />
                </div>

                <div className="form-header">
                    <div className="header-info">
                        <h3>{t('common:auth.user.verification.header')}</h3>
                    </div>
                </div>

                <BodyContent />

            </div>
        );
    }

    render() {
        let { t } = this.props;
        let { verified, loading } = this.state;
        let { Body } = this;

        if (!verified && !loading) {
            return <Error code={404} msg={t('common:auth.general.expired-token-url')} />;
        }

        return (
          <div className="auth-contrainer">
                <div className="auth contrainer-wrapper horizontal">
                    <div className="contrainer-block">

                        <Body />

                    </div>

                    <div className="footer">
                        <div>© {new Date().getFullYear()} paperlogic.co.jp</div>
                    </div>
                </div>

            </div>
        );
    }

}
export default withTranslation()(UserVerification);
