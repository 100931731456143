import Layout from "../../../Layout";
import { withTranslation } from "react-i18next";
import { Button, Form, Col } from 'react-bootstrap';
import Loading from "../../Loading";
import Service from "../../../Service";


class FormDetail extends Layout {
  constructor(props) {
    super(props)

    this.state.cert_type = props.cert_type;
    this.state.doc_type = props.doc_type || [];
    this.state.as = props.as;
    this.state.token = props.token || null;

    this.state.keyFields = {
      user_id: "user-id",
      status_name: "status-name",
      status: "status",
      application_date: "date",
      application_certificate_type: "certificate",
      application_certificate_label: "certificate",
      tax_id: "tax-id",
      company_name: "company-name",
      company_name_romaji: "company-name-roma",
      name: "full-name",
      name_kana: "full-name-kana",
      name_romaji: "full-name-roma",
      branch_and_department: "branch-department",
      email: "email",
      credentials: "qualification",
      identity_verification_documents: "personal-document-type",
      file_name: "file_name",
      upload: "upload_path",
      company_id: "company_id",
      tenant_id: "tenant_id",
    };

    this.state.formData = props.data;
    this.state.show = props.show || false;
    this.state.onClose = props.onClose;
    this.state.requiredFile = props.requiredFile || false;
    this.state.formValidate = false;
    this.state.formOpen = false;
    // this.state.prepareModal = this.PrepareModal;
    // this.state.setFields = this.setStateFields;
    // if (state.as === "modal") {
    if (this.state.formData) {
      this.state.formData = props.data;
    }
    this.PrepareModal();
    // }

  }

  static getDerivedStateFromProps(props, state) {

    state.cert_type = props.cert_type;
    state.doc_type = props.doc_type || [];
    state.as = props.as;
    state.show = props.show || false;
    if (!state.formData) {
      state.formData = props.data;
      if (state.formData) {
        state.modal.props.size = "xl";
      }
    }
    state.onClose = props.onClose;
    if (state.as === "modal") {
      if (!state.formOpen && state.show) {
        state.modal.props.show = true;
        state.formOpen = true;
      }
    }
    if (state.formData) {
      let { keyFields, fields, formData } = state;
      for (let key in keyFields) {
        let label = keyFields[key];
        fields[key] = {
          name: key,
          value: "",
          label: props.t(`common:settings.appcert.application-${label}`),
        };
        if (formData[key]) {
          fields[key].value = formData[key];
        }
      }

      fields["delete-confirm"] = false;
    }

    // console.log(props);
    // console.log(state.formOpen);

    return { state };
  }

  componentDidMount() {
    // let { t } = this.props;
    let { formData } = this.state;
    // console.log(show);
    if (formData) {
      this.setStateFields();
    }
  }

  setStateFields = (cb) => {
    let { t } = this.props;
    let { keyFields, fields, formData } = this.state;
    // this.state.fields = {};
    for (let key in keyFields) {
      let label = keyFields[key];
      fields[key] = {
        name: key,
        value: "",
        label: t(`common:settings.appcert.application-${label}`),
      };
      if (formData[key]) {
        fields[key].value = formData[key];
      }
      // if (key === "status_name") {
      //     formData[key] = t(`common:${user[key]}`);
      // } else {
      //     formData[key] = user[key];
      // }
    }

    fields["delete-confirm"] = false;

    this.setState({ fields }, cb);
  }

  IdentityTypeItems = () => {
    let { t } = this.props;
    let { doc_type } = this.state;
    // console.log(doc_type);
    let OptionItems = [];

    for (let type of doc_type) {
      OptionItems.push(<option key={type.id} value={type.id}>{t(`common:${type.label}`)}</option>);
    }

    return OptionItems;
  }

  CertificateType = () => {
    let { t } = this.props;
    let { formData, cert_type } = this.state;
    let { FieldControl } = this;

    if (cert_type) {
      let OptionItems = [];
      for (let cert of cert_type) {
        OptionItems.push(<option key={cert.id} value={cert.code}>{t(`common:certificate.type.${cert.label}`)}</option>);
      }
      return (
        <FieldControl as="select" name={`application_certificate_type`}>
          {OptionItems}
        </FieldControl>
      );
    } else {
      return [(
        <input key="application_certificate_type" type="hidden" name={`application_certificate_type`} defaultValue={formData[`application_certificate_type`]} />
      ), (
        <FieldControl key="application_certificate_label" name={`application_certificate_label`} xs={12} md={4} readOnly className="readonly" />
      )];
    }
  }

  activeButton = (ev) => {
    // let { t } = this.props;
    let { modal } = this.state;

    modal.action = this.CertApplyActionButtons(!ev.target.checked);
    // modal.action = [(
    //     <Button key="ok" type="submit" variant="primary" disabled={!ev.target.checked}>
    //         <span>{t("common:general.confirm")}</span>
    //     </Button>)];
    this.setState({ modal });
  }

  setValidity = (form, item_name, fieldValidate, message) => {
    form[item_name].setCustomValidity(message);
    fieldValidate[item_name] = message;
  }

  additionalValidity = (form) => {
    // console.dir(form);
    let { fieldValidate } = this.state;

    // Required check
    // if (form['application_date']['value'] === '') {
    //     this.setValidity(form, 'application_date', fieldValidate, 'message.input.required');
    // }

    // if (form['company_name_romaji']['value'] === '') {
    //     this.setValidity(form, 'company_name_romaji', fieldValidate, "message.input.required");
    // }

    // if (form['name']['value'] === '') {
    //     this.setValidity(form, 'name', fieldValidate, "message.input.required");
    // }

    // if (form['name_kana']['value'] === '') {
    //     this.setValidity(form, 'name_kana', fieldValidate, "message.input.required");
    // }

    // if (form['name_romaji']['value'] === '') {
    //     this.setValidity(form, 'name_romaji', fieldValidate, "message.input.required");
    // }

    // if (form['branch_and_department']['value'] === '') {
    //     this.setValidity(form, 'branch_and_department', fieldValidate, "message.input.required");
    // }

    // if (form["file_name"].files.length === 0) {
    //   this.setValidity(form, "file_name", fieldValidate, "message.errors.input.validate.wrong-policy");
    // }

    if (form['company_name_romaji']['value'].match(/^[a-zA-Z0-9-+.,_()/:@\x20]*$/g) === null) {
      this.setValidity(form, 'company_name_romaji', fieldValidate, "message.errors.input.validate.wrong-policy");

    }

    if (form['name_romaji']['value'].match(/^[a-zA-Z0-9-+.,_()/:@\x20]*$/g) === null) {
      this.setValidity(form, 'name_romaji', fieldValidate, "message.errors.input.validate.wrong-policy");
    }

    if (form['credentials']['value'].match(/^[a-zA-Z0-9-+.,_()/:@\x20]*$/g) === null) {
      this.setValidity(form, 'credentials', fieldValidate, "message.errors.input.validate.wrong-policy");
    }

    this.setState({ fieldValidate });

    if (Object.keys(fieldValidate).length > 0) {
      return false;
    } else {
      return true;
    }
  }

  AppCertSaveSuccess = (resp) => {
    let { t } = this.props;
    let { modal } = this.state;
    // message.general.save-success
    modal.action = [];
    if (resp.hasOwnProperty("message")) {
      modal.body = t(`common:${resp.message}`);
    } else {
      modal.body = t("common:message.general.save-success");
    }

    this.setState({ modal });
  }

  AppCertSaveError = (error) => {
    let { t } = this.props;
    let { modal, formData } = this.state;
    //console.log(error);

    let errMessage = error?.data?.message;
    if (error.data.hasOwnProperty("error")) {
      errMessage = error.data.error;
    }

    modal.body = t(`common:${errMessage || 'message.error.server_unknown_error'}`);
    modal.action = [(
      <Button key="ok" variant="primary" onClick={(ev) => {
        // this.PrepareModal();
        let { modal } = this.state;
        modal.props.size = "xl";
        modal.body = this.FormBody;
        modal.action = this.CertApplyActionButtons;
        this.setState({ modal });
      }}>
        <span>{t("common:general.try-again")}</span>
      </Button>
    )];

    this.setState({ modal, formData });
  }

  /* */
  AppCertSave = () => {
    let { formData } = this.state;
    let data = {};
    data[formData.operation] = formData;
    if (formData.operation === "insert_data") {
      Service.SaveAppCert(data).then(resp => {
        this.AppCertSaveSuccess(resp);
      }).catch(err => {
        this.AppCertSaveError(err.response);
      });
    } else if (formData.operation === "update_data") {
      Service.UpdateAppCert(data).then(resp => {
        this.AppCertSaveSuccess(resp);
      }).catch(err => {
        this.AppCertSaveError(err.response);
      });
    } else {
      // Form opertion is missing
      // this.setState({ modal, datalist });
    }

  }

  /* */
  GuestAppCertSave = (form) => {
    let { token, formData } = this.state;
    // let data = {};
    // data[formData.operation] = [formData];
    /* */
    let insert_data = {};
    insert_data[formData.operation] = [];
    /* */

    insert_data[formData.operation].push({
      "company_id": form['company_id']['value'],
      "tenant_id": form['tenant_id']['value'],
      "application_date": form['application_date']['value'],
      "user_id": form['user_id']['value'],
      "application_certificate_type": form['application_certificate_type']['value'],
      "status": form['status']['value'],
      "company_name": form['company_name']['value'],
      "company_name_romaji": form['company_name_romaji']['value'],
      "tax_id": form['tax_id']['value'],
      "name": form['name']['value'],
      "name_kana": form['name_kana']['value'],
      "name_romaji": form['name_romaji']['value'],
      "branch_and_department": form['branch_and_department']['value'],
      "email": form['email']['value'],
      "credentials": form['credentials']['value'],
      "file_name": form['file_name']['value'],
      "identity_verification_documents": form['identity_verification_documents']['value'],
      "document_id": form['document_id']['value'],
    });

    let { t } = this.props;
    let { modal, file } = this.state; 
    if(file?.size >= 30000000){
      modal.body = t("common:documents.certs.upload.excess30");
      modal.action = [(
        <Button key="ok" type="submit" variant="primary" onClick={this.PrepareModal}>
         <span>{t("common:documents.cert.go_back_form")}</span>
        </Button>
      )];
      this.setState({ modal, formData });
      return;
    }
  
    new Promise((resolve, reject) => {
      if (file) {

        insert_data[formData.operation][0]["file_name"] = file.name;

        Service.GuestUploadCert(token, file).then(resp => {
          insert_data[formData.operation][0]["file_s3_uri"] = resp;
          resolve(insert_data);
        }).catch(err => {
          reject(err);
        });
      } else {
        resolve(insert_data);
      }

    }).then((result) => {
      if (formData.operation === "insert_data") {
        Service.GuestSaveAppCert(token, result).then(resp => {
          this.AppCertSaveSuccess(resp);
        }).catch(err => {
          if(err.response.status === 413 || err.response.message === 'Request Too Long'){
            modal.body = t("common:documents.certs.upload.excess");
            modal.action = [(
              <Button key="ok" type="submit" variant="primary" onClick={this.PrepareModal}>
              <span>{t("common:documents.cert.go_back_form")}</span>
              </Button>
            )];
            this.setState({ modal, formData });
            return
          }
          this.AppCertSaveError(err.response);
        });
      } else if (formData.operation === "update_data") {
        Service.GuestUpdateAppCert(token, result).then(resp => {
          this.AppCertSaveSuccess(resp);
        }).catch(err => {
          if(err.response.status === 413 || err.response.message === 'Request Too Long'){
            modal.body = t("common:documents.certs.upload.excess");
            modal.action = [(
              <Button key="ok" type="submit" variant="primary" onClick={this.PrepareModal}>
              <span>{t("common:documents.cert.go_back_form")}</span>
              </Button>
            )];
            this.setState({ modal, formData });
            return;
          }
          this.AppCertSaveError(err.response);
        });
      } else {
        // Form opertion is missing
        // this.setState({ modal, datalist });
      }
    })

  }


  uploadFile = (ev) => {
    let { formData } = this.state;
    let self = this;
    let files = ev.target.files;

    if (files.length < 1) {
      return false;
    }

    formData['file_name'] = files[0].name;
    
    self.setState({ formData, file: files[0] });
  }

  formSubmitCallback = (ev) => {
    const form = ev.target;

    if (this.additionalValidity(form) === false) {
      ev.stopPropagation();
    } else {
      let { modal, token, file } = this.state;
      if (file) {
        delete modal.props.size;
        modal.body = Loading;
        modal.action = [];

        // this.setState({ modal });

        if (token) {
          this.GuestAppCertSave(form);
        } else {
          this.AppCertSave(form);
        }
      }
    }
  }

  formPrepareBeforeSubmit = (ev) => {
    let form = ev.target;

    // console.log(form['file_name'].files);
    // console.log(form['file_name'].value);
    form['company_name_romaji'].setCustomValidity("");
    form['name_romaji'].setCustomValidity("");
    form['credentials'].setCustomValidity("");
    // form['file_name'].setCustomValidity("");

    this.formSubmitHandler(ev, null, this.formSubmitCallback)
  }

  CertApplyActionButtons = (disabled = true) => {
    let { t } = this.props;
    let { formData } = this.state;
    let ButtonActon = [];

    if (formData) {
      ButtonActon.push(
        <Button key="ok" type="submit" variant="primary" disabled={disabled}>
          <span>{t("common:general.confirm")}</span>
        </Button>
      );
    }

    return ButtonActon;
  }

  PrepareModal = () => {
    let { modal, formOpen, onClose } = this.state;
    // if (show) {
    modal.title = "common:settings.appcert.application-form";
    modal.body = this.FormBody;
    modal.action = this.CertApplyActionButtons;
    modal.onClose = () => {
      onClose(() => {
        this.setState({ formOpen: false, formValidate: false });
      });
    };
    modal.props = {
      // size: "sm",
      show: formOpen,
      centered: true,
      "dialogClassName": "app-cert-dialog",
    }
    modal.form = {
      id: "cert-apply-form",
      onSubmit: this.formPrepareBeforeSubmit,
      noValidate: true,
    };

    this.setState({ modal });

  }

  FormBody = () => {
    let { t } = this.props;
    let {
      formData,
      fields,
      requiredFile,
      formValidate
    } = this.state;
    let {
      FieldControl,
      CertificateType,
      IdentityTypeItems,
      ValidateMessage
    } = this;

    if (!formData) {
      return <Loading />;
    }
    // console.log(formData);

    let terms_href = "https://support.paperlogic.jp/wp-content/uploads/2024/06/JCAN%E8%A8%BC%E6%98%8E%E6%9B%B8%E3%82%B5%E3%83%BC%E3%83%93%E3%82%B9%E3%81%94%E5%88%A9%E7%94%A8%E6%9D%A1%E4%BB%B6.pdf";
    let terms_label = 'common:settings.appcert.jcan-agree-terms';

    if (formData[`application_certificate_type`] === 3) {
      terms_href = "https://support.paperlogic.jp/wp-content/uploads/2024/06/NRA-PKI%E8%A8%BC%E6%98%8E%E6%9B%B8%E3%82%B5%E3%83%BC%E3%83%93%E3%82%B9%E3%81%94%E5%88%A9%E7%94%A8%E6%9D%A1%E4%BB%B6.pdf"
      terms_label = 'common:settings.appcert.nra-agree-terms';
    }

    return (
      <div className="form-container appcert">
        <fieldset className="appcert-item-row">
          <Form.Row>
            <FieldControl name={`name`} xs={12} md={4} readOnly className="readonly" />
            <FieldControl name={`email`} xs={12} md={8} readOnly className="readonly" />
          </Form.Row>
          <Form.Row>
            <CertificateType />
          </Form.Row>
          <Form.Row>
            <FieldControl name={`company_name_romaji`} xs={12} md={4} placeholder={'paperlogic Co.,Ltd.'} />
            {/* <div className="col-md-8 appcert-caption">{t("common:appcert.caption.company_name_romaji")}<br />{t("common:appcert.caption.available_characters")}</div> */}
            <div className="col-md-8 appcert-caption">
              <p className="appcert-caption_heading">{t("common:appcert.caption.company_heading")}</p>
              <ul className="appcert-caption_list">
                <li>{t("common:appcert.caption.company_desc1")}</li>
                <li>{t("common:appcert.caption.company_desc2")}</li>
              </ul>
              <p className="appcert-caption_heading u-mt">{t("common:appcert.caption.company_name_romaji")}</p>
              {t("common:appcert.caption.available_characters")}<br />
              {t("common:appcert.caption.character")}
            </div>
          </Form.Row>
          <Form.Row>
            <FieldControl name={`name_romaji`} xs={12} md={4} required validate="true" placeholder={'John Smith'} required />
            {/* <div className="col-md-8 appcert-caption">{t("common:appcert.caption.name_romaji")}<br />{t("common:appcert.caption.available_characters")}</div> */}
            <div className="col-md-8 appcert-caption">
              <p className="appcert-caption_heading">{t("common:appcert.caption.name_romaji")}</p>
              {t("common:appcert.caption.available_characters")}<br />
              {t("common:appcert.caption.character")}
            </div>
          </Form.Row>
          <Form.Row>
            <FieldControl name={`credentials`} xs={12} md={4} placeholder={'CPA'} />
            {/* <div className="col-md-8 appcert-caption">{t("common:appcert.caption.credentials")}<br />{t("common:appcert.caption.available_characters")}</div> */}
            <div className="col-md-8 appcert-caption">
              <p className="appcert-caption_heading">{t("common:appcert.caption.qualification_heading")}</p>
              <ul className="appcert-caption_list">
                <li>{t("common:appcert.caption.qualification_desc")}</li>
              </ul>
              <p className="appcert-caption_heading u-mt">{t("common:appcert.caption.qualification")}</p>
              {t("common:appcert.caption.available_characters")}<br />
              {t("common:appcert.caption.character")}
            </div>
          </Form.Row>
          <Form.Row>
            <FieldControl as="select" name={`identity_verification_documents`} xs={12} md={4} required >
              <IdentityTypeItems />
            </FieldControl>
            {/* <div className="col-md-8 appcert-caption">{t("common:appcert.caption.identity_verification_documents")}</div> */}
            <div className="col-md-8 appcert-caption">
              <p className="appcert-caption_heading">{t("common:appcert.caption.select_identification_document")}</p>
            </div>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} xs={12} md={4} controlId={`file_name`} className={`form-control-file${requiredFile && formValidate ? " wsas-validated" : ""}`}>
              <Form.Label>{t("common:settings.appcert.application-personal-document-upload")}</Form.Label>
              {/* <Form.File
                      id={`file_name`}
                      name={`file_name`}
                      label={formData['file_name'] || t("common:settings.appcert.application-personal-document-upload")}
                      custom
                      data-browse={'参照'}
                      className={`appcert-upload-file`}
                      accept=".jpg,.png,.pdf,.zip"
                  >
                      <Form.File.Input
                          isValid={formData["file_name"] && requiredFile && formValidate}
                          isInvalid={!formData["file_name"] && requiredFile && formValidate}
                          required={!formData["upload"] && requiredFile && formValidate}
                          onChange={(ev) => {
                              this.uploadFile(ev)
                          }}
                      />
                      <Form.File.Label>
                          {formData['file_name'] || t("common:settings.appcert.application-personal-document-upload")}
                      </Form.File.Label>
                      <Form.Control.Feedback type="invalid">
                          <ValidateMessage field={fields["file_name"]} />
                      </Form.Control.Feedback>

                  </Form.File> */}
              <Form.File
                id={`file_name`}
                name={`file_name`}
                label={formData['file_name'] || t("common:settings.appcert.application-personal-document-upload")}
                data-browse={t("common:common.general.browse")}
                className={`appcert-upload-file`}
                custom

                isValid={formData["file_name"] && requiredFile && formValidate}
                isInvalid={!formData["file_name"] && requiredFile && formValidate}
                // required={!formData["upload"] && requiredFile && formValidate}
                // required={!formData['file_name']}
                // validate={`true`}
                onChange={(ev) => {
                  this.uploadFile(ev)
                }}
                accept=".jpg,.png,.pdf,.zip"
              />
            </Form.Group>
            {/* <div className="col-md-8 appcert-caption appcert-uoload">{t("common:appcert.caption.file-upload1")}<br />{t("common:appcert.caption.file-upload2")}<br />{t("common:appcert.caption.file-upload3")}</div> */}
            <div className="col-md-8 appcert-caption appcert-uoload">
              <p className="appcert-caption_heading">{t("common:appcert.caption.file-upload1")}</p>
              <ul className="appcert-caption_list">
                <li>{t("common:appcert.caption.file-upload2")}</li>
                <li>{t("common:appcert.caption.file-upload3")}</li>
                <li>{t("common:appcert.caption.file-upload4")}</li>
              </ul>
              <p className="appcert-caption-caution">{t("common:appcert.caption.file-upload5")}</p>
            </div>
          </Form.Row>
          <Form.Row className="appcert-guest-term">
            <div>
              <a
                href={terms_href}
                target="_blank"
                rel="noopener noreferrer">{t('common:menu.terms-of-service')}
              </a>
              <Form.Check
                key="nra_terms"
                name="agreement_terms"
                label={t(terms_label)}
                onChange={(ev) => { this.activeButton(ev) }}
              />
            </div>
          </Form.Row>

          {/*<input type="hidden" name={`upload`} defaultValue={formData[`upload`]} />*/}
          {/*<input type="hidden" name={`upload-file-name`} defaultValue={formData[`file_name`]} />*/}
          <input type="hidden" name={`user_id`} defaultValue={formData[`user_id`]} />
          <input type="hidden" name={`status_name`} defaultValue={formData[`status_name`]} />
          <input type="hidden" name={`application_date`} defaultValue={formData[`application_date`]} />
          <input type="hidden" name={`tax_id`} defaultValue={formData[`tax_id`]} />
          <input type="hidden" name={`company_name`} defaultValue={formData[`company_name`]} />
          <input type="hidden" name={`name_kana`} defaultValue={formData[`name_kana`]} />
          <input type="hidden" name={`branch_and_department`} defaultValue={formData[`branch_and_department`]} />
          <input type="hidden" name={`status`} defaultValue={formData[`status`]} />
          <input type="hidden" name={`company_id`} defaultValue={formData[`company_id`]} />
          <input type="hidden" name={`tenant_id`} defaultValue={formData[`tenant_id`]} />
          <input type="hidden" name={`document_id`} defaultValue={formData[`document_id`]} />
          {/*<input type="hidden" name={`credentials`} defaultValue={formData[`credentials`]} />*/}


          {this.props.children}
        </fieldset>
      </div>
    );
  }

  render() {
    // let { t } = this.props;
    let { as } = this.state;
    let { FormModal, FormBody } = this;
    // console.log(formData, fields);
    let BodyContent = () => { return null; };
    if (as !== "modal") {
      BodyContent = FormBody;
    }

    return (
      <div className="modal-container">
        <BodyContent />
        <FormModal />
      </div>
    );

  }

}


export default withTranslation()(FormDetail);
