// @ts-nocheck
import { useHookstate } from "@hookstate/core";
import { LoadingButton } from "@mui/lab";
import { TextField, Typography } from "@mui/material";
import { useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { getDetailDeliveryGuest } from "../../data-access/delivery/delivery.api";
import { deliveryDetailState } from "../../data-access/delivery/delivery.state";
import BaseDialogDriveCrud from "./drive-base-dialog";
import styles from "./drive-dialog.module.css";

export default function DrivePasswordDialog({ open, onClose }) {
  //   const open = useHookstate(false);
  const { t } = useTranslation("common");
  const pathSelect = useHookstate("");
  const loading = useHookstate(false);
  const methods = useForm({
    defaultValues: { password: "" },
  });
  const errorMessage = useHookstate("");
  const { token } = useParams();
  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    watch,
  } = methods;
  function onSubmit(value) {
    loading.set(true);
    getDetailDeliveryGuest({
      token,
      password: value.password,
    })
      .then((r) => {
        deliveryDetailState.set(r.data.payload);
        // console.log(r);
        errorMessage.set("");
        onClose();
      })
      .catch((e) => {
        errorMessage.set(
          t(
            `common:${e.response.data.message_code}`,
            e.response.data.message || e
          )
        );
      })
      .finally(() => loading.set(false));
    // console.log(value);
  }
  const inputRef = useRef(null);
  return (
    <div>
      <BaseDialogDriveCrud
        handleClose={(e, reason) => {
          pathSelect.set("");
          if (reason === "backdropClick") return;
          return onClose(e);
        }}
        open={open}
        title={t("common.drive.password_dialog.title", 'パスワードを入力してください')}
        // パスワードを入力してください。
        dialogContentComponent={
          <div>
            <Typography variant="body2">
              {t("common:drive.password_dialog.input_password")}
              {/* Password */}
            </Typography>
            <Controller
              name="password"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  size="small"
                  fullWidth
                  type="password"
                  error={
                    errors.password?.type === "required" || errorMessage.get()
                  }
                  helperText={
                    (errors.password?.type === "required" &&
                      "パスワードは必須フィールドです") ||
                    errorMessage.get()
                  }
                  placeholder={"******"}
                  className={styles["form_input"]}
                  {...field}
                />
              )}
            />
          </div>
        }
        dialogActionComponent={
          <div className="action_btn_center">
            <LoadingButton
              loading={loading.get()}
              variant="contained"
              onClick={handleSubmit(onSubmit)}
            >
              {/* アップロード */}
              OK
              {/* {t("common:drive.button.edit", "編集")} */}
            </LoadingButton>
          </div>
        }
      />
    </div>
  );
}
