// @ts-nocheck

import { Tooltip } from "@mui/material";

/**
 * @param {{ iconName: string; }} props (get icon name here https://fonts.google.com/icons?selected=Material+Symbols+Outlined:monitoring:FILL@0;wght@400;GRAD@0;opsz@48)
 */
export default function MaterialSymbolIcon(props) {
  const { iconName, ...others } = props;
  return (
    <Tooltip
      disableInteractive={props.disableInteractive}
      placement={props.placement || "bottom"}
      title={props.tooltip || iconName}
      //  "選択しているファイルを配信"
    >
      <div
        {...others}
        className={`material_symbol_icon_drive ${props?.className || ""}`}
      >
        <span className="material-symbols-outlined">{iconName}</span>
      </div>
    </Tooltip>
  );
}
