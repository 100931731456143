import { hookstate } from "@hookstate/core"

export const currentLang = hookstate('ja');

export const errProps = hookstate({
    msg: [],
    code: -1,
    show: false
})

export const appLoadingShow = hookstate(false);

export const isSystemMaintain = hookstate(false);

export const shedOnScroll = hookstate(false);

export const systemMaintainData = hookstate({
    start_time: '',
    end_time: ''
})
export const isShowNavBack = hookstate(false)
