
import { Router } from "react-router";

import {
    // StaticRouter,
    // BrowserRouter,
    Switch,
    Route,
} from "react-router-dom";
import { createBrowserHistory } from "history";

import Login from "../Pages/Auth/Login";
import Registration from "../Pages/Auth/Registration";
import RegistrationProcessing from "../Pages/Auth/Registration/Processing";
import RegistrationComplete from "../Pages/Auth/Registration/Successful";
import Verification from "../Pages/Auth/Registration/Verification";
import ResendVerifyEmail from '../Pages/Auth/Registration/ResendVerifyEmail';
import ForgotPassword from '../Pages/Auth/Registration/ForgotPassword';
import PasswordRecovery from '../Pages/Auth/Registration/PasswordRecovery';
import UserVerification from '../Pages/Auth/Registration/UserVerification';
import DocumentSign from "../Pages/Documents/DocumentSign";
import AppCertUpload from "../Pages/Settings/AppCert/AppCertUpload";
import GuestLinkage from "../Pages/Settings/GuestLinkage";
import DriveLayout from "../drive-feature/components/drive-layout/drive-layout";
import DriveShared from "../drive-feature/pages/drive-shared/drive-shared";
import { bhistory } from ".";
import { Suspense } from "react";
import Loading from "../Pages/Loading";
import DocumentPreviewForPrinting from "../Pages/Documents/DocumentPreviewForPrinting";

// const history = createBrowserHistory();
export default function Index() {


    return (
        <Router history={bhistory}>
            <Switch>
                <Route path="/login" component={Login} />
                <Route exact path="/registration" component={Registration} />
                <Route exact path="/registration/processing" component={RegistrationProcessing} />
                <Route exact path="/registration/complete" component={RegistrationComplete} />
                <Route path="/registration/verification/:token" component={Verification} />
                <Route exact path="/registration/resend-email" component={ResendVerifyEmail} />
                <Route exact path="/guest/document/:token" component={DocumentSign} />
                <Route exact path="/guest/documents/preview/:token" component={DocumentPreviewForPrinting} />
                <Route exact path="/guest/linkage/:token" component={GuestLinkage} />
                <Route exact path="/account/recovery/reset-password/:token" component={PasswordRecovery} />
                <Route exact path="/user/verification/:token" component={UserVerification} />
                <Route exact path="/forgot-password" component={ForgotPassword} />
                <Route exact path="/guest/appcert/upload/:token" component={AppCertUpload} />
                <Route key={3} exact path="/shared-folder/delivery/detail/:token">
                    <Suspense
                      fallback={
                        <div className="main-body-loading">
                          <Loading />
                        </div>
                      }
                    >
                      <DriveLayout isCompany={false} children={<DriveShared />} isGuest={true} fromRoot={false} />
                    </Suspense>
                  </Route>
                <Route path="*" component={Login} />
            </Switch>
        </Router>
    );
}