// @ts-nocheck
import { hookstate, useHookstate } from "@hookstate/core";
import { Chip, Tooltip } from "@mui/material";
// import { groupMembersGuestState } from "src/app/data-access/guest/guest.state";
// import { groupMembersState } from "src/app/data-access/user/user.state";
import styles from "./group-member-chip.module.css";
export const groupMembersGuestState = hookstate([]);
export const groupMembersState = hookstate([]);

export default function GroupMemberChip(props) {
  const groupMember = useHookstate(groupMembersState);
  const groupMemberGuest = useHookstate(groupMembersGuestState);

  return (
    <div className={styles["container"]}>
      {props.type === "guest"
        ? groupMemberGuest.get().map((a, i) => (
            <Tooltip key={i} title={`${a.user_id} - ${a.name}`}>
              <Chip
                label={a.name}
                color={a.isActive ? "secondary" : "default"}
                className={`role_box_item ${a.isActive ? styles["active_chip"] : ""}`}
                onClick={() => {
                  groupMemberGuest[i].merge({ isActive: !a.isActive });
                }}
              />
            </Tooltip>
          ))
        : groupMember.get().map((a, i) => (
            <Tooltip key={i} title={`${a.user_id} - ${a.name}`}>
              <Chip
                label={a.name}
                color={a.isActive ? "secondary" : "default"}
                className={`role_box_item ${a.isActive ? styles["active_chip"] : ""}`}
                onClick={() => {
                  groupMember[i].merge({ isActive: !a.isActive });
                }}
              />
            </Tooltip>
          ))}
    </div>
  );
}
