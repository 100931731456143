import React from "react";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import DraggableListItem from "./FileItemDragDrop";
import './FileDragDrop.css';
import '../../../Pages/Assets/css/layout.css';
import PlaceIcon from '../../../Pages/Assets/img/icons/place.svg';
export function FileDragDrop(
    props,
) {
    const {
        isDraggable,
        documentSection,
        pdfFileObject,
        fileOrder,
        onChangeObject,
        selectedFile,
        FileListRemove,
        onClickDocumentDetail,
        onClickDocumentPrepare,
        onRemoveDocumentPrepare,
        isDisabled,
        isShowViewStatus,
        viewStatus,
    } = props;

    const [draggedIndex, setDraggedIndex] = React.useState(-1);

    const onDragStart = (index) => setDraggedIndex(index)

    // update list when item dropped
    const onDrop = (dropIndex) => {
        // get draged item
        const dragItem = fileOrder[draggedIndex]

        // delete draged item in list
        let list = [...fileOrder]
        list.splice(draggedIndex, 1)

        // update list
        if (draggedIndex < dropIndex) {
            onChangeObject([
                ...list.slice(0, dropIndex - 1),
                dragItem,
                ...list.slice(dropIndex - 1, list.length)
            ])
        } else {
            onChangeObject([
                ...list.slice(0, dropIndex),
                dragItem,
                ...list.slice(dropIndex, list.length)
            ])
        }
    }

    const renderItemContent = (file, key) => {
        const status = isShowViewStatus && viewStatus[key]?.status != 1;
        return (
            <>
                {isDraggable && <DragIndicatorIcon/>}
                {isShowViewStatus && (
                    <div>
                        {status ? <img src={PlaceIcon} alt="" className='view-status-icon'/> : <div className='view-status-icon'/>}        
                    </div>
                )}
                
                {documentSection === "DocumentDetail" ? (
                    <>
                        <span
                            className="file-name"
                            title={file?.name}
                            onClick={(ev) => {
                                if(!onClickDocumentDetail) return;
                                onClickDocumentDetail(ev, key);
                            }}
                        >
                            {file?.name}
                        </span>
                        <FileListRemove hash={key} />
                    </>
                ) : documentSection === "DocumentPrepare" ? (
                    <>
                        <span
                            className="file-name"
                            title={file?.name}
                            onClick={onClickDocumentPrepare}
                        >
                            {file?.name}
                        </span>
                        <button
                            type="button"
                            className="btn-icon file-remove"
                            onClick={onRemoveDocumentPrepare}
                        >
                            <CancelOutlinedIcon name="delete"/>
                        </button>
                    </>
                ) : (
                    <></>
                )}
            </>
        );
    }

    return (
        <>
            {fileOrder?.length > 0 && (
                <ul>
                    {fileOrder?.map((key, index) => {
                        let file = pdfFileObject[key];
                        return (
                            <DraggableListItem
                                key={index}
                                index={index}
                                isDisabled={isDisabled}
                                onDragStart={(index) => onDragStart(index)}
                                onDrop={(index) => onDrop(index)}
                                draggable={isDraggable}
                                dataFile={key}
                                selected={key === selectedFile}
                            >
                                {renderItemContent(file, key)}
                            </DraggableListItem>
                        )
                    })}
                    <DraggableListItem
                        key={fileOrder.length}
                        index={fileOrder.length}
                        isDisabled={isDisabled}
                        draggable={false}
                        selected={true}
                        onDrop={(index) => onDrop(index)}
                    />
                </ul>
            )}
        </>
    );

}

FileDragDrop.defaultProps = {
    //
    isDraggable: false,
    isShowViewStatus: false,
};

export default FileDragDrop;

