import { createTheme } from '@mui/material/styles';

export const MuiCustomTheme = createTheme({
	palette: {
		primary: {
			main: '#1976d2',
			dark:'#0a63bc'
		},
		secondary: {
			main: '#2694ef',
			dark:'#097bd8'
		},
		success: {
			main: '#17a845',
			dark:'#078c07'
		},
		warning: {
			main: '#da6517',
			dark:'#dd5900'
		},
		error: {
			main: '#e0463b',
			dark:'#ce2d29'
		},
		info: {
			main: '#f2f2f8',
			dark:'#e2e6e9',
			contrastText:"#1069d1"
		},
		
		
		text: { 
			primary: '#062b60' ,
			secondary:'#535963',
			disabled: '#cacbd5',
		},
		
		action: {
			disabled: '#cacbd5',
		},

		divider:"#e2e2e8",
    
    
    
	},


  
});

