import { hookstate } from "@hookstate/core";

export const deliveryListState = hookstate({
    data: [],
    page_number: 1,
    page_size: 50,
    total: 0
});

export const deliverySearchState = hookstate({
    page_size: 50,
    page_number: 1,
    search: ''
})

export const deliveryDetailState = hookstate({
    "drive_delivery_id": 0,
    "sender_full_name": "",
    "company_name": "",
    "subject": "",
    "content": "",
    "type": 1,
    "status": 1,
    "download_count_setting": 0,
    "downloaded_number": 0,
    "start_date": "",
    "end_date": "",
    "total_folder_size": 0,
    "total_file_size": 0,
    "files": [],
    "folders": [],
    "users": []
})