
import Main from "../Pages/Main";
import Documents from "../Pages/Documents";
import DocumentSign from "../Pages/Documents/DocumentSign";

import Dashboard from '../Pages/Main/Dashboard';
import Signer from "../Pages/Main/Signer";
import Sender from "../Pages/Main/Sender";
import Verification from "../Pages/Main/Verification";
import Workflow from "../Pages/Main/Workflow";
import FileBox from "../Pages/Main/FileBox";
import Search from "../Pages/Main/Search";
import Evaluation from "../Pages/Main/Evaluation";
import Circle from "../Pages/Main/Circle";

/* Settings Pages */
import SettingsProfile from "../Pages/Settings/Profile";
import SettingsTenant from "../Pages/Settings/Tenant";
import SettingsOrganization from "../Pages/Settings/Organization";
import SettingsUser from "../Pages/Settings/User";
import SettingsGuest from "../Pages/Settings/Guest";
import SettingsStamp from "../Pages/Settings/Stamp";
import SettingsNotification from "../Pages/Settings/Notification";
import SettingsAppCert from "../Pages/Settings/AppCert";
import SettingsCounterParty from "../Pages/Settings/CounterParty";
import SettingsSso from "../Pages/Settings/Sso";
import SettingRestrictIp from "../Pages/Settings/RestrictIP";
import SettingBatchDownload from "../Pages/Settings/BatchDownload";
import GuestLinkage from '../Pages/Settings/GuestLinkage';
import GuestApprove from "../Pages/Settings/GuestApprove";
import SettingsAuditLog from "../Pages/Settings/AuditLog";
import SettingsExpirationDate from "../Pages/Settings/ExpirationNotification";

import SearchResult from "../Pages/Search/SearchResult";

import PDFFullPreview from "../Pages/Fragments/PDFViewer/FullPreview";
import AppCertUpload from "../Pages/Settings/AppCert/AppCertUpload";

import TermsOfService from "../Pages/TermsOfService";

import SiteMap from "../Pages/Index";
import Test from "../Test";
import Error from "../Pages/Error";
import DocumentDetailPage from "../Pages/Documents/DocumentDetailPage";
import DocumentPreviewForPrinting from "../Pages/Documents/DocumentPreviewForPrinting";
import BusinessNumberSearch from "../Pages/Main/BusinessNumberSearch";
import GuestCollaboration from "../Pages/Settings/GuestCollaboration";
import TenantCollaborationApprove from "../Pages/Settings/TenantCollaborationApprove";

const Pages = {
    Documents,
    DocumentSign,
    Main,
    Dashboard,
    Signer,
    Sender,
    Verification,
    Workflow,
    Circle,
    FileBox,
    Search,
    SearchResult,
    Evaluation,
    PDFFullPreview,
    SiteMap,
    Test,
    Error,
    SettingsProfile,
    SettingsTenant,
    SettingsOrganization,
    SettingsUser,
    SettingsGuest,
    SettingsStamp,
    SettingsNotification,
    SettingsAppCert,
    AppCertUpload,
    TermsOfService,
    SettingsCounterParty,
    SettingsSso,
    SettingRestrictIp,
    GuestLinkage,
    GuestApprove,
    GuestCollaboration,
    TenantCollaborationApprove,
    DocumentPreviewForPrinting,
    DocumentDetailPage,
    SettingBatchDownload,
    BusinessNumberSearch,
    SettingsAuditLog,
    SettingsExpirationDate
}

export default Pages;
