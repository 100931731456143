import LoadingImage from '../../Assets/img/loading.gif'
import LinearProgress from '@mui/material/LinearProgress';

export default function LoadingProgress(props) {
	let newProps = {};

	if (props && props.hasOwnProperty("className")) {
		newProps.className = `${props.className} loading`;
	} else {
		newProps.className = "loading";
	}

	return (
		<div {...newProps}>
			<div className="img-container-progress">
				<img src={LoadingImage} alt="loading" />
				
				{props.showProgress && (
                	<LinearProgress variant="determinate" value={props.progress} className='progress'/>
            	)}
			</div>
			
		</div>
	);
}
