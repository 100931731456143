import axios from "axios";
import i18next from "i18next";
import API from "../../../Config/API";
import { API_ROUTES, driveApiCall, ec2DriveApiCall } from "../../config/api.config";
import { notificationAlertState } from "../state";

/**
 * @param {string} search
 */
export function searchUserDelivery(search) {
    return driveApiCall.get(API_ROUTES.delivery.searchUser, {
        params: {
            search,
            page_number: 1,
            page_size: 100
        }
    })
}

/**
 * @param {{ user_id_list: any; 
 * drive_file_id_list: any; 
 * drive_folder_id_list: any; 
 * download_count_setting: any; 
 * subject: any; content: any; 
 * start_date: any; 
 * end_date: any; 
 * document_id_list: any;
 * type: any; 
 * email_list: any;
 * password: any; }} data
 */
export function sendDeliveryPackage(data) {
    const {
        user_id_list,
        email_list,
        drive_file_id_list,
        drive_folder_id_list,
        document_id_list,
        download_count_setting,
        subject,
        content,
        start_date,
        end_date,
        type,
        password,
    } = data
    return driveApiCall.post(API_ROUTES.delivery.sendPackage, {
        email_list,
        drive_file_id_list,
        drive_folder_id_list,
        document_id_list,
        download_count_setting: +download_count_setting,
        subject,
        content,
        start_date,
        end_date,
        type,
        password,
    }).catch(e => {
        notificationAlertState.set({
            show: true,
            type: "error",
            message: `${i18next.t(
                "common:drive.message.error.send_delivery",
                "Send delivery fail please try again!"
            )} ${e?.response?.data?.message || e
                }`,
        });
    })
}

/**
 * @param {{ page_number: any; page_size: any; search: string; }} params
 */
export function getListDelivery(params) {
    const { page_number, page_size, search } = params;
    return driveApiCall.get(API_ROUTES.delivery.list, {
        params: {
            page_number, page_size, search
        }
    })
}

/**
 * @param {any} drive_delivery_id_list
 */
export function deleteDelivery(drive_delivery_id_list) {
    return driveApiCall.patch(API_ROUTES.delivery.deleteDelivery, {
        drive_delivery_id_list
    }).catch(e => {

        notificationAlertState.set({
            show: true,
            type: "error",
            message: `${i18next.t(
                "common:drive.message.error.delete_delivery",
                "Delete delivery error please try again!"
            )} ${e?.response?.data?.message || e
                }`,
        });
    })
}

/**
 * @param {any} drive_delivery_id_list
 */
export function cancelDelivery(drive_delivery_id_list) {
    return driveApiCall.patch(API_ROUTES.delivery.cancelDelivery, {
        drive_delivery_id_list
    }).catch(e => {
        notificationAlertState.set({
            show: true,
            type: "error",
            message: `${i18next.t(
                "common:drive.message.error.cancel_delivery",
                "Cancel delivery error please try again!"
            )} ${e?.response?.data?.message || e
                }`,
        });
    })
}


/**
 * @param {{ password: any; drive_delivery_id: any;token:string; }} params
 */
export function getDetailDeliveryGuest(params) {
    const { password, drive_delivery_id, token } = params
    return axios.get(API.url + API_ROUTES.delivery.deliveryDetailGuest, {
        params: {
            password: password || '', drive_delivery_id
        },
        headers: {
            Authorization: token,
        }
    })
}

/**
 * @param {any} drive_delivery_id
 */
export function getDetailDeliveryHost(drive_delivery_id) {
    return driveApiCall.get(API_ROUTES.delivery.deliveryDetailHost, {
        params: {
            drive_delivery_id
        }
    })
}

/**
 * @param {any} params
 */
export function downloadFilesGuest({ drive_file_id_list, drive_folder_id_list, token }) {
    return axios.post(API.url + API_ROUTES.delivery.guestDownload, {
        drive_file_id_list,
        drive_folder_id_list
    }, {
        headers: {
            Authorization: token
        }
    })
}

/**
 * @param {any} params
 */
export function downloadFilesGuestEc2({ document_id_list,
    drive_file_id_list,
    drive_folder_id_list, token }) {
    return axios.post(API.ec2Url + API_ROUTES.delivery.ec2GuestDownload, {
        document_id_list,
        drive_file_id_list,
        drive_folder_id_list,
        // drive_delivery_id,
    }, {
        headers: {
            Authorization: token
        }
    })
}

/**
 * @param {{ drive_delivery_id: any; download_count_setting: any; start_date: any; end_date: any; status: any; }} data
 */
export function updateDelivery(data) {
    const { drive_delivery_id, download_count_setting, start_date, end_date, status } = data;
    return driveApiCall.patch(API_ROUTES.delivery.updateDelivery, {
        download_count_setting, start_date, end_date, status
    }, {
        params: {
            drive_delivery_id
        }
    })
}