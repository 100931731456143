import {withTranslation} from 'react-i18next';
import Layout from '../../Layout';
import Button from '@mui/material/Button';
import "../Assets/css/confirm-guest.css";
import Loading from "../Loading";
import Service from "../../Service";
import Error from "../Error";


class GuestApprove extends Layout {

    constructor(props) {
        super(props)
        this.state.token = props.match.params.token;
        this.state.approver = null;
        this.state.requester = null;
        this.state.guest = null;
        this.state.loading = true;
        this.state.approveStatus = null;
    }

    NavLeft = () => {
        const { LogoImage } = this;

        return (
            <div className="logo-horizontal" style={{ width: "200px" }}>
                <LogoImage />
            </div>
        );
    }

    componentDidMount() {
        super.componentDidMount();

        Service.GuestApproverInfo(this.state.token)
            .then((result) => {
                const {payload, ok} = result;

                if (ok) {
                    const {approver, guest, requester, approveStatus} = payload;
                    this.setState({approver, requester, guest, loading: false, approveStatus});
                }
            })
            .catch((error) => {
                this.setState({ loading: false});
                console.log(error)
            });
    }

    handleApproveReject = (approve) => {
        let {t} = this.props;
        const {approver, guest, requester, token} = this.state;
        let data = {
            approver_id: approver.user_id,
            guest_id: guest.user_id,
            requester_id: requester.user_id,
            tenant_id: guest.tenant_id,
            approve: approve,
            token: token,
        };
        Service.GuestRegistrationApproveReject(data).then((resp) => {
            let { modal } = this.state;

            modal.body = approve ? t('common:settings.guest.approve-success') : t('common:settings.guest.reject-success');

            if (resp.ok) {
                this.setState({approveStatus: resp.payload.approveStatus})
            }

            this.setState({modal});
        }).catch((err) => {
            let { modal } = this.state;
            modal.body = approve ? t('common:settings.guest.approve-fail') : t('common:settings.guest.reject-fail');
            if (err.response.data.payload.hasOwnProperty("error")) {
                modal.body = err.response.data.payload.error;
            } else if (err.response.data.payload.hasOwnProperty("message")) {
                modal.body = err.response.data.payload.message;
            }
            modal.action = [
                <Button key="ok" variant="contained" onClick={(ev) => {
                    modal.body = Loading;
                    modal.action = [];
                    this.setState({ modal }, () => {
                        this.handleApproveReject(approve);
                    });
                }}>
                    <span>{t("common:general.try-again")}</span>
                </Button>
            ];

            this.setState({ modal });
        });
    }

    handleOpenModal = (approve) => {
        let {t} = this.props;
        let { modal, formData } = this.state;

        modal.title = "common:settings.guest.guest-registration-approve";
        modal.body = Loading;
        modal.action = [];

        modal.props.show = true;
        modal.props.size = "xl";
        modal.props.className = "layout modal-responsive";

        modal.props.centered = true;

        this.setState({ modal, formData }, () => {
            this.handleApproveReject(approve)
        });
    };

    Main = (props) => {
        let { loading } = this.state;
        const { Nav, Body, MainLoading } = this;

        if (loading) {
            return <MainLoading />;
        }

        return (
            <div className="main contrainer-wrapper">
                <div className="layout no-menu">

                    <div className="layout-right">

                        <Nav />

                        <Body {...props} />

                        <div className="right-bottom">
                            <span className="licensce-info">© {new Date().getFullYear()} paperlogic co., ltd.</span>
                        </div>
                    </div>
                </div>
            </div >
        );
    }

    ConfirmBody = () => {
        let { t } = this.props;
        const {guest, requester, approveStatus} = this.state;
        return (
            <div className="confirm-guest container-wrapper">
                    <div className="container-block">
                        <div className="form-wrapper">
                            <div className={"container-info"}>
                                <p className={"title"}>{t("common:settings.guest.please-approve")}</p>

                                <p className={"subtitle"}>{requester?.family_name} {requester?.first_name} {t("common:settings.guest.have-sent-approval-request")}</p>

                                <p className={"guest-info"}><p className={"item-label"}>{t('common:settings.guest.guest-name')}</p> {guest?.family_name} {guest?.first_name} </p>
                                <p className={"guest-info"}><p className={"item-label"}>{t('common:settings.guest.company_name')}</p> {guest?.additional_info?.company_name}  </p>
                                <p className={"guest-info"}><p className={"item-label"}>{t('common:settings.guest.email')}</p> {guest?.email}</p>
                            </div>

                            {approveStatus === null ? (
                                <div className={"container-button"}>
                                    <Button onClick={() => this.handleOpenModal(false)} className={"button button-reject"}>{t('common:settings.guest.reject')} </Button>
                                    <Button onClick={() => this.handleOpenModal(true)} className={"button button-approve"}>{t('common:settings.guest.approve')} </Button>
                                </div>
                            ) : (
                                <div>
                                    <p>{approveStatus ? t('common:settings.guest.approve-success') : t('common:settings.guest.reject-success')}</p>
                                </div>
                            )}


                        </div>
                    </div>
            </div >
        );
    }

    render() {
        let { Main, ConfirmBody } = this;
        const {GeneralModal, FormModal} = this;

        const {loading, approver} = this.state;

        if (loading) {
            return <Loading className="full-screen" />;
        }

        if (!approver) {
            return <Error code={404} msg={"Current User is not Approver"} layout={false} />;
        }

        return (
            <Main>
                <div className="page-error">
                    <ConfirmBody />
                </div>

                <GeneralModal/>

                <FormModal/>
            </Main>
        );
    }
}

export default withTranslation()(GuestApprove);

