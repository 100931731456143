import React from 'react';
import { Link } from "react-router-dom";
import logo from '../../Assets/img/Logo2.svg';
import '../../Assets/css/auth.css';

import { withTranslation } from "react-i18next";

class Successful extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        };

    }

    componentDidMount() {
        document.title = this.props.t("common:auth.registration.success.title");
    }

    componentDidUpdate(prevProps) {
        // document.title = this.props.t("common:auth.register.title");
        // console.log("componentDidUpdate", prevProps);
    }

    render() {

        let { t } = this.props;

        return (
          <div className="auth-contrainer">
                <div className="auth contrainer-wrapper horizontal">
                    <div className="contrainer-block">

                        <div className="form-wrapper registration" style={{ "width": "600px" }}>

                            <div className="logo-horizontal">
                                <img src={logo} className="App-logo" alt="paperlogic" />
                            </div>

                            <div className="form-header">
                                <div className="header-info">
                                    <h3>{t('common:auth.registration.success.header')}</h3>
                                </div>
                            </div>


                            <div className="form-container">

                                <div className="fields-container">

                                    <p>{t('common:auth.registration.success.message')}</p>

                                </div>

                                <div className="form-bottom">
                                    <div className="wrapper-button-cancel">

                                    </div>
                                    <div className="wrapper-button-submit">
                                        <Link to='/login' style={{ width: '100%' }}>{t('common:auth.registration.success.goto-login')}</Link>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>

                    <div className="footer">
                        <div>© {new Date().getFullYear()} paperlogic.co.jp</div>
                    </div>
                </div>

            </div >
        );
    }

}
export default withTranslation()(Successful);