// @ts-nocheck
import { useHookstate } from "@hookstate/core";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { mappedTypeToQuery } from "../../utils/mappedTypeToQuery";

import {
  Button,
  Checkbox,
  CircularProgress,
  Link,
  Menu,
  Tooltip,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { TableVirtuoso } from "react-virtuoso";
import Loading from "../../../Pages/Loading";
import {
  breabCrumbState,
  hoveredRows,
  userInfoState,
} from "../../data-access/state";
import { formatBytes } from "../../utils/format-file-size";
import { mappedTableIconByType } from "../../utils/mappedTableIconByFileType";
import DriveBreadcrumb from "../drive-breadcrumb/drive-breadcrumb";
import MaterialSymbolIcon from "../../../Pages/Components/material-symbol/material-symbol";
import { useLocation } from "react-router-dom";
import { useEffect, useRef } from "react";

const VirtuosoTableComponents = {
  Scroller: React.forwardRef((props, ref) => (
    <TableContainer component={Paper} {...props} ref={ref} />
  )),
  Table: (props) => {
    const paperRef = useRef(null);
    const { pathname } = useLocation();
    useEffect(() => {
      if(paperRef.current) paperRef.current.scrollIntoView();
    }, [pathname])

    return <Table
      {...props}
      ref={paperRef}
      stickyHeader
      className="relative list_table delivery-table"
      sx={{ minWidth: 500 }}
    />}
  ,
  TableHead: React.forwardRef((props, ref) => (
    <TableHead {...props} className={"list_table_th"} />
  )),
  Footer: () => {
    return (
      <div
        style={{
          padding: "1rem",
          textAlign: "center",
        }}
      >
        end reached
      </div>
    );
  },
  TableRow: ({ item: _item, ...props }) => {
    const {
      onClickRowHandle,
      onDblClickRowHandle,
      rowClasses,
      selectedRows,
      selectRowKey,
      handleContextMenu,
      onHoldShiftMultiSelect,
      onHoldControlMultiSelect,
      previousRowIndexClick,
      dropTarget,
      dragTarget,
      dragAndDropRow,
      // drag,
      // dragPreview,
    } = props.context;
    return (
      <>
        <TableRow
          {...props}
          id={`tbRow${_item.id}`}
          hover
          draggable
          onDragStart={
            dragAndDropRow?.enable
              ? (e) =>
                  dragAndDropRow?.handleDragStart(e, _item, `tbRow${_item.id}`)
              : undefined
          }
          onDragLeave={
            dragAndDropRow?.enable
              ? (e) =>
                  dragAndDropRow?.handleDragLeave(e, _item, `tbRow${_item.id}`)
              : undefined
          }
          onDragOver={
            dragAndDropRow?.enable
              ? (e) =>
                  dragAndDropRow?.handleDragOver(e, _item, `tbRow${_item.id}`)
              : undefined
          }
          onDragEnd={
            dragAndDropRow?.enable
              ? (e) =>
                  dragAndDropRow?.handleDragEnd(e, _item, `tbRow${_item.id}`)
              : undefined
          }
          onDrop={
            dragAndDropRow?.enable
              ? (e) => dragAndDropRow?.handleDrop(e, _item, `tbRow${_item.id}`)
              : undefined
          }
          key={props["data-item-index"]}
          onMouseOver={(e) => hoveredRows.set(props["data-item-index"])}
          onMouseOut={(e) => hoveredRows.set(-1)}
          className={`${rowClasses} ${
            selectedRows &&
            selectedRows.some((a) => _item[selectRowKey] === a[selectRowKey])
              ? "isSelected"
              : null
          } ${
            dropTarget && dropTarget?.get() === _item.id ? " dropTarget" : ""
          }`}
          onDoubleClick={
            onDblClickRowHandle
              ? (ev) => onDblClickRowHandle(ev, _item)
              : undefined
          }
          onClick={(ev) => {
            if (ev.shiftKey && onHoldShiftMultiSelect) {
              if (selectedRows?.length === 0) {
                previousRowIndexClick.set(-1);
              }
              if (previousRowIndexClick.get({ stealth: true }) > -1) {
                onHoldShiftMultiSelect(
                  _item,
                  previousRowIndexClick.get({ stealth: true }),
                  props["data-item-index"]
                );
                previousRowIndexClick.set(props["data-item-index"]);
                return;
              }
            }
            previousRowIndexClick.set(props["data-item-index"]);
            if ((ev.ctrlKey || ev.metaKey) && onHoldControlMultiSelect) {
              return onHoldControlMultiSelect(_item);
            }
            return onClickRowHandle ? onClickRowHandle(ev, _item) : undefined;
          }}
          onContextMenu={
            handleContextMenu
              ? (e) => {
                  e.preventDefault();
                  return handleContextMenu(e, _item);
                }
              : undefined
          }
        />
        {props.context?.tableRows?.length - 1 === props["data-item-index"] &&
        props.context?.scrollLoading ? (
          <TableRow>
            <TableCell
              className="text-center"
              colSpan={
                props.context.tableHeader.length +
                (props.context.enableSelectable ? 1 : 0)
              }
            >
              <CircularProgress />
            </TableCell>
          </TableRow>
        ) : null}
      </>
    );
  },
  TableBody: React.forwardRef((props, ref) => {
    // console.log(props)
    return (
      <TableBody
        {...props}
        ref={ref}
        onDragEnter={props.context.onFileDragEnter}
        onDragLeave={props.context.onFileDragLeave}
        onDragOver={props.context.onFileDragOver}
        onDrop={props.context.onFileDrop}
      />
    );
  }),
};
export default function VirtualizedTable(props) {
  const {
    tableHeader,
    tableRows,
    onClickRowHandle,
    loading,
    onDblClickRowHandle,
    isCompleteFolder,
    rowClasses,
    headerActionsComponent,
    order,
    enableSelectable,
    selectedRows,
    selectRowKey,
    onCheckSelectedAll,
    keyHoverEdit,
    onEditClick,
    handleContextMenu,
    onHoldShiftMultiSelect,
    onHoldControlMultiSelect,
    onFileDragEnter,
    onFileDragLeave,
    onFileDragOver,
    onFileDrop,
    endReached,
    overscan,
    totalRecord,
    scrollLoading,
    dragAndDropRow,
    isCompanyFolder,
    paperRef
  } = props;
  //   const ref = useRef(null);
  const previousRowIndexClick = useHookstate(-1);
  //   const { mouseOver, mouseOut, hovered } = useHover();
  const hovered = useHookstate(hoveredRows);
  function EmptyRow() {
    return (
      <>
        <TableCell colSpan={tableHeader.length}>NODATA</TableCell>
      </>
    );
  }
  function RowContent(_index, row) {
    if (!row)
      return (
        <>
          <TableCell></TableCell>
        </>
      );
    return (
      <React.Fragment>
        {enableSelectable ? (
          <TableCell
            sx={{ width: "5%" }}
            className="checkbox-base-table"
            padding="checkbox"
          >
            <Checkbox
              color="primary"
              checked={
                selectRowKey &&
                selectedRows?.length > 0 &&
                row &&
                selectedRows?.some(
                  (a) =>
                    row[selectRowKey] === a[selectRowKey] &&
                    (row?.url ? a?.url === row.url : !a.url && !row?.url)
                )
              }
              inputProps={{
                "aria-label": "select all desserts",
              }}
            />
          </TableCell>
        ) : null}
        {tableHeader.map((key, i2) => {
          return (
            <TableCellMapped
              row={row}
              key={i2}
              keyHeader={key}
              fromDocumentSelect={isCompanyFolder}
              i2={i2}
              isCompleteFolder={isCompleteFolder}
              keyHoverEdit={keyHoverEdit}
              onEditClick={onEditClick}
              hovered={hovered}
              _index={_index}
            />
          );
        })}
      </React.Fragment>
    );
  }
  const handleDragStart = (e, item) => {
    // console.log(e.dataTransfer, item);
    e.dataTransfer.effectAllowed = "move";
    e.dataTransfer.dropEffect = "move";
    // e.dataTransfer.setData("text/plain", '1');
    // e.dataTransfer.setData("text", JSON.stringify(item));
  };
  return (
    <Paper
      elevation={0}
      className="virtual_table_wrapper"
      sx={{
        height: isCompanyFolder ? "calc(100vh - 130px)" : "calc(100vh - 70px)",
        width: "100%",
        borderRadius: 0,
        maxWidth: props.loading ? "100vw" : "auto",
        overflowY: props.loading ? "hidden" : "auto",
        overflowX: "hidden",
      }}
    >
      {props.loading ? (
        <div
          style={{
            width: "100%",
            position: "absolute",
            top: !isCompanyFolder
              ? selectRowKey === "drive_delivery_id"
                ? 100
                : 160
              : 165,
            zIndex: 99999,
            left: 0,
            height: "calc(100% - 150px)",
            backgroundColor: "white",
            display: "flex",
            justifyContent: "start",
          }}
          className="main-body-loading"
        >
          <Loading />
        </div>
      ) : null}

      <TableVirtuoso
        context={{
          tableHeader,
          tableRows,
          onClickRowHandle,
          loading,
          onDblClickRowHandle,
          dropTarget: dragAndDropRow?.dropTarget,
          rowClasses,
          headerActionsComponent,
          order,
          onFileDragEnter,
          onFileDragLeave,
          onFileDragOver,
          onFileDrop,
          enableSelectable,
          selectedRows,
          selectRowKey,
          onCheckSelectedAll,
          keyHoverEdit,
          onEditClick,
          handleContextMenu,
          onHoldShiftMultiSelect,
          onHoldControlMultiSelect,
          previousRowIndexClick,
          scrollLoading,
          handleDragStart,
          dragTarget: dragAndDropRow?.dragTarget,
          dragAndDropRow,
          paperRef
        }}
        endReached={endReached}
        overscan={overscan}
        data={tableRows || []}
        totalCount={totalRecord || tableRows?.length || 0}
        components={VirtuosoTableComponents}
        fixedHeaderContent={headerActionsComponent}
        itemContent={RowContent}
      />
    </Paper>
  );
}

export function TableCellMapped({
  keyHeader,
  row,
  isCompleteFolder,
  i2,
  fromDocumentSelect,
  keyHoverEdit,
  hovered,
  _index,
  onEditClick,
}) {
  const { t } = useTranslation();
  const openPop = useHookstate({
    open: false,
    anchorEl: null,
    currentRowId: -1,
  });
  if (!row || !(keyHeader.key in { ...row })) <></>;
  //   console.log(row, key.key);
  if (keyHeader.type === "format_type") {
    return (
      <TableCell
        className="table_cell"
        key={i2}
        // className={row[key]['class']}
        component="td"
        scope="row"
      >
        <Tooltip
          disableInteractive={true}
          placement="top"
          title={row[keyHeader.type]}
        >
          <span
            style={{
              position: "relative",
            }}
          >
            {row[keyHeader.type]}
          </span>
        </Tooltip>
      </TableCell>
    );
  }
  if (keyHeader.type === "dateIso") {
    return (
      <TableCell
        className="table_cell"
        key={i2}
        // className={row[key]['class']}
        component="td"
        scope="row"
        onClick={
          keyHeader?.callback ? () => keyHeader?.callback(row) : undefined
        }
      >
        <Tooltip
          disableInteractive={true}
          placement="top"
          title={
            row[keyHeader.key]
              ? row[keyHeader.key].substring(0, 19).replaceAll(/[T,Z]/g, " ")
              : ""
          }
        >
          <span>
            {row[keyHeader.key]
              ? row[keyHeader.key].substring(0, 19).replaceAll(/[T,Z]/g, " ")
              : ""}
          </span>
        </Tooltip>
      </TableCell>
    );
    // new Date().toISOString()
  }

  if (keyHeader.type === "actions") {
    return (
      <TableCell
        className="table_cell"
        key={i2}
        // className={row[key]['class']}
        component="td"
        scope="row"
        onClick={
          keyHeader?.callback ? () => keyHeader?.callback(row) : undefined
        }
      >
        <keyHeader.icon />
      </TableCell>
    );
  }

  if (keyHeader.type === "parseJson") {
    const bread = breabCrumbState.get({ stealth: true });
    let dataRow = [
      ...JSON.parse(row[keyHeader.key]),
      !fromDocumentSelect ? bread[bread.length - 1] : bread[0],
    ].reduce((arr, a) => {
      if (a.type == 6) {
        a.name = userInfoState.get({ noproxy: true })?.tenant_name || a.name;
      }
      if (!arr.find((b) => b.drive_folder_id === a.drive_folder_id)) {
        arr.push(a);
      }
      return arr;
    }, []);
    if (isCompleteFolder) {
      dataRow = dataRow.reverse();
    }
    // console.log(dataRow)
    const checkLastRow =
      dataRow.length > 1 ? dataRow[1] : bread[bread.length - 1] || dataRow[0];
    // console.log(dataRow);
    // console.log(checkLastRow, dataRow);
    // console.log(bread[bread.length - 1]);
    // console.log(checkLastRow, row.id, dataRow);
    return (
      <TableCell
        className="table_cell"
        key={i2}
        // className={row[key]['class']}
        component="td"
        scope="row"
        onClick={(e) => e.stopPropagation()}
      >
        <Menu
          open={openPop.open.get()}
          anchorEl={openPop.anchorEl.get()}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          slotProps={{
            backdrop: {
              onMouseOver: () => {
                openPop.open.set(false);
                openPop.anchorEl.set(null);
              },
            },
          }}
          transitionDuration={0}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          sx={{
            zIndex: 1,
            cursor: !!fromDocumentSelect ? "default" : "pointer",
            color: !!fromDocumentSelect ? "#062b60" : "",
          }}
          disablePortal={true}
          MenuListProps={{
            onMouseLeave: () => {
              openPop.open.set(false);
              openPop.anchorEl.set(null);
            },
            sx: {
              cursor: !!fromDocumentSelect ? "default" : "pointer",
              color: !!fromDocumentSelect ? "#062b60" : "",
            },
          }}
          onClose={() => {
            openPop.open.set(false);
            openPop.anchorEl.set(null);
            // openPop.currentRowId.set(-1);
          }}
        >
          {/* <MenuItem onClick={e=>{
            e.preventDefault();
          }}> */}
          <DriveBreadcrumb
            fromDocumentSelect={fromDocumentSelect}
            typeHover={row.type}
            isCompleteFolder={isCompleteFolder}
            breadCrumbCustom={dataRow}
          />
          {/* </MenuItem> */}
        </Menu>
        <Button
          onMouseOver={(e) => {
            if (dataRow.length === 1) return;
            openPop.open.set(true);
            openPop.currentRowId.set(row.id);
            openPop.anchorEl.set(e.currentTarget);
          }}
        >
          <Link
            component={NavLink}
            sx={{
              pointerEvents: !!fromDocumentSelect ? "none" : "auto",
            }}
            disabled={!!fromDocumentSelect}
            to={`${
              checkLastRow?.drive_folder_id === "drive"
                ? `/drive`
                : checkLastRow?.drive_folder_id === "delivery"
                ? "/delivery"
                : checkLastRow?.drive_folder_id === "delivery/list"
                ? "/delivery"
                : `/folders/${checkLastRow?.drive_folder_id}?type=${
                    isCompleteFolder
                      ? "complete"
                      : mappedTypeToQuery(checkLastRow.type)
                  }${
                    [1, 2, 3, 4].includes(checkLastRow.type)
                      ? `&function_type=${checkLastRow.type}`
                      : ""
                  }`
            }`}
            color="secondary"
            className="crumb_item"
          >
            <MaterialSymbolIcon iconName={"folder_open"} />
            &nbsp;
            <Tooltip
              disableInteractive={true}
              placement="top"
              title={t(checkLastRow?.name)}
            >
              <span className="location_parents"> {t(checkLastRow?.name)}</span>
            </Tooltip>
          </Link>

          {/* {row[keyHeader.key]} */}
        </Button>
      </TableCell>
    );
  }

  if (keyHeader.type === "statusShared") {
    return (
      <TableCell
        className="table_cell"
        key={i2}
        // className={row[key]['class']}
        component="td"
        scope="row"
      >
        <Tooltip
          disableInteractive={true}
          placement="top"
          title={
            row[keyHeader.key] == 0
              ? t(`common:drive.shared.status.undownloaded`)
              : row[keyHeader.key] == 1
              ? t(`common:drive.shared.status.downloaded`)
              : ""
          }
        >
          <span>
            {row[keyHeader.key] == 0
              ? t(`common:drive.shared.status.undownloaded`)
              : row[keyHeader.key] == 1
              ? t(`common:drive.shared.status.downloaded`)
              : ""}
          </span>
        </Tooltip>
      </TableCell>
    );
  }

  if (keyHeader.type === "statusDelivery") {
    // console.log(new Date(row?.end_date) < new Date(), row);
    const date = new Date(row?.end_date);
    const now = new Date();
    date.setHours(0, 0, 0, 0);
    now.setHours(0, 0, 0, 0);
    return (
      <TableCell
        className="table_cell"
        key={i2}
        // className={row[key]['class']}
        component="td"
        scope="row"
      >
        <Tooltip
          disableInteractive={true}
          placement="top"
          title={row[keyHeader.key]}
        >
          <span>
            {row[keyHeader.key] == 1
              ? date < now
                ? t(
                    "common:drive.share_dialog.end_date_expired",
                    "配信期間終了"
                  )
                : t("common:drive.share_dialog.during_delivery", "配信中")
              : row[keyHeader.key] == 2
              ? t(
                  "common:drive.share_dialog.delivery_cancelled",
                  "キャンセル済み"
                )
              : t(
                  "common:drive.share_dialog.delivery_disconntinued",
                  "配信中止"
                )}
          </span>
        </Tooltip>
      </TableCell>
    );
  }

  if (keyHeader.type === "anchor") {
    return (
      <TableCell
        className="table_cell"
        key={i2}
        // className={row[key]['class']}
        component="td"
        scope="row"
      >
        <Tooltip
          disableInteractive={true}
          placement="top"
          title={row[keyHeader.key]}
        >
          <Link
            onClick={(e) => {
              e.preventDefault();
              return keyHeader?.onClickCallback(row);
            }}
          >
            {row[keyHeader.key]}
          </Link>
        </Tooltip>
      </TableCell>
    );
  }

  if (keyHeader.type === "icon") {
    const mapped = row[keyHeader.key]?.split(".");
    return (
      <TableCell
        className="table_cell"
        key={i2}
        // className={row[key]['class']}
        component="td"
        scope="row"
      >
        <div className={"folder-file_list_file_name"}>
          {mappedTableIconByType(
            keyHeader?.iconMapped
              ? {
                  ...row,
                  type: 8,
                  fileType: mapped[mapped.length - 1] || "folder",
                  format_type: mapped[mapped.length - 1] || "folder",
                }
              : { ...row }
          )}
          &nbsp;
          <Tooltip
            disableInteractive={true}
            placement="top"
            title={t(row[keyHeader.key])}
          >
            <span
              style={{
                position: "relative",
                zIndex: 1,
              }}
            >
              {t(row[keyHeader.key])}
            </span>
          </Tooltip>
          <span className="table_edit_icon_hover">
            {keyHoverEdit &&
            keyHeader.key === keyHoverEdit &&
            !row.document_id ? (
              hovered.get() === _index &&
              (![1, 2, 3, 4, 5, 6, 7, 11].includes(row?.type) ||
                row?.extension !== "folder") ? (
                <Tooltip
                  disableInteractive={true}
                  placement="top"
                  title={t("common:drive.button.edit")}
                >
                  <BorderColorIcon
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      return onEditClick(row);
                    }}
                    fontSize="small"
                  />
                </Tooltip>
              ) : null
            ) : null}
          </span>
        </div>
      </TableCell>
    );
  }

  if (keyHeader.type === "size") {
    return (
      <TableCell
        className="table_cell"
        key={i2}
        // className={row[key]['class']}
        component="td"
        scope="row"
      >
        <Tooltip
          disableInteractive={true}
          placement="top"
          title={formatBytes(+row[keyHeader.key], 2, row?.format_type)}
        >
          <span
            style={{
              position: "relative",
            }}
          >
            {formatBytes(+row[keyHeader.key], 2, row?.format_type)}
          </span>
        </Tooltip>
      </TableCell>
    );
  }

  return !row?.isIgnore ? (
    <TableCell
      className="table_cell"
      key={i2}
      // className={row[key]['class']}
      component="td"
      scope="row"
    >
      <Tooltip
        disableInteractive={true}
        placement="top"
        title={row[keyHeader.key]}
      >
        <span
          style={{
            position: "relative",
          }}
        >
          {row[keyHeader.key]}
        </span>
      </Tooltip>
      <span className="table_edit_icon_hover">
        {keyHoverEdit && keyHeader.key === keyHoverEdit && !row.document_id ? (
          hovered.get() === _index &&
          (![1, 2, 3, 4, 5, 6, 7, 11].includes(row?.type) ||
            row?.extension !== "folder") ? (
            <Tooltip placement="top" title="グループ">
              <BorderColorIcon
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  return onEditClick(row);
                }}
                fontSize="small"
              />
            </Tooltip>
          ) : null
        ) : null}
      </span>
    </TableCell>
  ) : null;
}
