// @ts-nocheck
export default function downloadFileUrl(url, type) {
    let fileNameUrl = url?.split('?')[0]?.split('/');
    console.log(fileNameUrl);
    let fileName = fileNameUrl[fileNameUrl.length - 1];
    let urlNew = new URL(url);
    let params = urlNew?.searchParams;
    let contentDisposition = params.get('response-content-disposition');
    let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    let matches = filenameRegex.exec(contentDisposition);
    if (matches != null && matches[1]) {
        fileName = matches[1].replace(/['"]/g, '');
    }
    return new Promise((resolve, reject) => {
        fetch(url).then(r => r.blob()).then(r => {
            // console.log(r);
            const blob = new Blob([r], { type:  r.type });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.style.display = 'none';
            link.download = decodeURI(fileName).trim();
            link.click();
            window.URL.revokeObjectURL(link);
            resolve(true);
        });
    })

}