// @ts-nocheck
import { progessUploadFileGlobal } from "../data-access/state";

export function progessCallback(progressEvent) {
    const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
    );
    if (percentCompleted > 0)
        progessUploadFileGlobal.progess.set(percentCompleted);
    if (percentCompleted === 100) {
        progessUploadFileGlobal.complete.set((v) => v + 1);
    }
    // progessUploadFileGlobal.progess.set?
}