// @ts-nocheck
import { StateFragment } from "@hookstate/core";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { Col, Container, Form, InputGroup } from 'react-bootstrap';
export function GuestSelect(props){
	let { t } = props;
	let { AvailableUser, SelectedUsers, SearchInputChange } = props.objMain;
	let { searchGuestsText, formData } = props.state;
	let guests = formData.guests
	let valueSearchGuest = '';
	let SelectedUserProps = {
		users: guests ?? {},
		user_type: "guest",
	}
	let AvailableUserProps = {
		users: guests ?? {},
		user_type: "guest",
		single_select: true
	}
	return (
		<>
			<div className="layout guest-select" style={{minHeight:"50vh",height:"60vh",width:"1000px"}}>
				<Container fluid="md" className={"guest-select-container form-wrapper container-fluid"} >
					<Form
						id={"form_GuestSelect"}
						noValidate
						className="container-fluid full-form"
					>
						<div className="form-body flex-column">
							<Form.Row className="flex-full">
								<Form.Group xs={12} md={6} as={Col} controlId="user_list" className="signer-list-wrapper">
									<Form.Label>{t("common:documents.guest-setting.guests-list")}</Form.Label>
									<SelectedUsers {...SelectedUserProps} />
								</Form.Group>
								<Form.Group xs={12} md={6} as={Col} controlId="all_user_list" className="user-selection-container">
									<Form.Label>{t("common:documents.guest-setting.available-user-list")}</Form.Label>
									<InputGroup className="form-control">
										<div className="search-input-container">
										<StateFragment state={valueSearchGuest}>
											{s=>{
												return <TextField
												className="search-input-field"
												placeholder={t('common:documents.general.guest-search-input-placeholder')}
												onChange={(e) => {
													s.set(e.target.value || '');
													return SearchInputChange(AvailableUserProps, e);
													}
												}
												onKeyDown={(e) => {
													if (e.key === 'Enter'){
														e.preventDefault();
														e.stopPropagation();
													}
												}}
												value={s.get()}
												defaultValue={searchGuestsText}
												sx={{
													"& fieldset": { border: 'none' },
												}}
												InputProps={{
													startAdornment: (
														<InputAdornment>
															<IconButton>
																<SearchIcon />
															</IconButton>
														</InputAdornment>
													),
													style: {
														height: 40,
														paddingLeft: 5,
														fontSize: 15,
													},
												}}
											/>
											}}
											</StateFragment>
										</div>
										<AvailableUser {...AvailableUserProps} />
									</InputGroup >
								</Form.Group>

							</Form.Row>
						</div>
					</Form>
				</Container>
			</div>
		</>
	)
}
export default GuestSelect;