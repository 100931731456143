//import React, { useState }  from 'react';
import Layout from '../../Layout';
import Menu from './Menu';
import Loading from '../Loading';
import LoadingList from '../LoadingList';
import Service from '../../Service';
import { withTranslation } from "react-i18next";
import '../Assets/css/settings.css';
import { Form, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faSync,
} from '@fortawesome/free-solid-svg-icons';
import SVGIcons from '../Assets/SVGIcons';
import CryptoJS from 'crypto-js';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import IconButton from '@mui/material/IconButton';
import { tenantDetailState } from '../../data-access/tenants/tenants.state';
import TenantDetailPages from './Tenants/TenantDetail';
import { Suspense } from 'react';
import {StateFragment} from "@hookstate/core";
import {shedOnScroll} from "../../data-access/app.state";
import {ResizableTableWithCache} from "../../Components/resizeable-table-with-cache/ResizableTableWithCache";

class Tenant extends Layout {

	/** */
	constructor(props) {

		/* */
		super(props);
		this.state.page = "tenant";
		this.state.pagination = {
			"page": 1,
			"take": 100,
			"total": 0,
		};
		this.state.showTenantDetail = false;
		this.state.datalist = null;
		this.state.userlist = null;
		this.state.grouplist = null;
		this.state.stamp_users = null;
		this.state.tenantDetail = null;
		this.state.stamp_detail = null;
		this.state.keyFields = {
			"tenant_id": "tenant_id",
			"company_id": "company_id",
			"company_name": "company_name",
			"name": "name",
			"status": "status",
			"contract_start_date": "contract_start_date",
			"limit_contract_person": "limit_contract_person",
			"function_type_id": "function_type_id",
			"api_token": "api_token",
			"enableRestrictIp": "enableRestrictIp",
			"restrictIp": "restrictIp"
		};
		this.state.activeTab = 0;
		this.state.tabs = [
			{ "status": 0, "label": "common:settings.user.active" },
			{ "status": 1, "label": "common:settings.user.inactive" },
		];
		/* */
		this.state.create = {
			"users": [],
		};
		this.state.datalist = null;
		this.state.tenant_service_list = null;
		this.state.tenantFunctionTypeList = null;
		this.state.dialog_type = null;
		this.state.company_id = null;
		this.state.company_name = null;
	}

	/** */
	Menu = () => {

		/* Load Menu from Pages/Settings/Menu.jsx */
		return (<Menu {...this.props} page={this.state.page} />);

	}

	/** [Init] HTTP-Request API */
	// componentDidMountExtends = () => {
	// 	//
	// }

	componentDidUpdate() {

		/* */
		let { datalist, tenantFunctionTypeList, pagination, company_id, company_name } = this.state;

		/* */
		if (datalist === null) {

			/* */
			let tab = this.state.activeTab;
			let page = pagination.page;
			let take = pagination.take;
			let skip = (page - 1) * take;

			/* */
			let data = {
				"tenant_id": '',
				"status": this.state.tabs[tab]['status'],
				"sorting_code": 1,
				"skip": skip,
				"take": take,
			}

			Service.getTenantList(data).then((response) => {

				/* */
				datalist = response.tenantList;
				//console.log(datalist);
				pagination.total = response.total;
				tenantFunctionTypeList = response.tenantFunctionTypeList;
				company_id = response.company_id;
				company_name = response.company_name;
				/* */
				this.setState({ datalist, tenantFunctionTypeList, pagination, company_id, company_name });

			}).catch((error) => {
				console.log(error.response);
			});
		}
	}

	setStateFields = (cb) => {
		let { t } = this.props;
		let { keyFields, fields, formData } = this.state;
		// this.state.fields = {};
		for (let key in keyFields) {
			let label = keyFields[key];
			fields[key] = {
				name: key,
				value: "",
				label: t(`common:settings.tenant.${label}`),
			};
			if (formData[key]) {
				fields[key].value = formData[key];
			}
		}

		this.setState({ fields }, cb);
	}

	formSubmitCallback = (ev) => {
		const form = ev.target;

		if (form.action_name.value === "create") {
			this.StampSave(form);
		} else {
			this.StampUpdate(form);
		}
	}

	/** */
	render() {

		/* */
		const { Main, CompanyName, CreateButton, Pagination, DataRows, FilterTabs,  } = this;

		/* */
		return (
			<Main>

				<div id="page-settings-stamp" className="page-settings">
					<Suspense fallback={<></>}>
						<TenantDetailPages showDetail={this?.state?.showTenantDetail} main={this} company_name={this.state.company_name}
						tenant_service_list={this.state.tenant_service_list} tenantDetail={this.state.tenantDetail} />

					</Suspense>
					{!this.state.showTenantDetail ?<>
						<div className="lz-flex">
								<div className="lz-flex-1">
									<div className="lz-m-10 lz-mb-0">
										<CompanyName />
									</div>
								</div>

								<div className="lz-flex-0">

									<div className="lz-m-10 lz-mb-0">
										<FilterTabs />
									</div>

								</div>

								</div>

								{/* SearchForm & Paginate */}
								<div className="lz-flex stickyHeader">

								<div className="lz-flex-1">

									<div className="lz-m-10">

										{/* SearchForm */}
										<div className="lz-flex">

											<CreateButton />

										</div>

									</div>

								</div>

								<div className="lz-flex-0">
									<div className="lz-m-10">
										<Pagination />
									</div>
								</div>

								</div>

								{/* DataTable */}
								<div className="">
								<DataRows />
								</div>

								{/* BottomPaginate */}
								<div className="lz-flex" hidden>

								<div className="lz-flex-1"></div>

								<div className="lz-flex-0">
									<div className="lz-m-10">
										<Pagination />
									</div>
								</div>

						</div>{/* Tabs */}
					</>:null 
				}
					
					

				</div>

			</Main>
		);
	}

	/** [RENDER] */
	CompanyName = () => {

		/* */
		let { company_id, company_name } = this.state;

		/* */
		if (company_id === null) {
			return false;
		}

		/* */
		return (
			<div className="title">
				<h2>
					{/* <span className='company_id_tag'>会社ID：{company_id}</span> */}
					{company_name} 
				</h2>
			</div>
		);

	}
	
	/** [Element] */
	FilterTabs = () => {
		
		/* Translate Function */
		let { t } = this.props;

		/* */
		let tabs = [];
		
		/* */
		for (let i = 0; i < this.state.tabs.length; i++) {

			/* */
			let c = "select-tab-item";

			/* */
			if (i === this.state.activeTab) {
				c = "select-tab-item active";
			}

			/* */
			let clickEvent = () => {

				/* */
				let { activeTab, pagination, datalist } = this.state;

				/* */
				activeTab = i
				pagination.page = 1;
				datalist = null;

				/* */
				this.setState({ activeTab, pagination, datalist });

			};

			/* */
			tabs.push(
				<div key={i} className={c} onClick={clickEvent}>
					<span>{t(this.state.tabs[i]['label'])}</span>
				</div>
			);
		}

		/* */
		return (
			<div className="select-tab">{tabs}</div>
		);

	}

	/** [RENDER] */
	DataRows = () => {

		/* Search Function */
		let { t } = this.props;

		/* */
		let { datalist } = this.state;

		/* */
		if (datalist === null) {
			//return <Loading />;
			return <LoadingList/>
		}
		/* */
		let rows = [];

		/* */
		for (let i = 0; i < datalist.length; i++) {

			/* */
			let item = datalist[i];

			/* */
			let row = (
				
				<tr key={i} className="document-row-list clickable" onClick={() => { 
					// this.props.history.push(`/settings/tenant/${item.tenant_id}`);
					// this.openDetailFormDialog(item)
				
					this.setState({showTenantDetail: true, tenantDetail: item}, ()=>{
						tenantDetailState.set(item);
					});
				 }} >
					
					<td>
						<div>{item["tenant_id"]}</div>
					</td>
					<td>
						<div>{item["name"]}</div>
					</td>
					<td>
						<div>{item["contract_start_date"]}</div>
					</td>
					<td>
						<div>{item["limit_contract_person"]}</div>
					</td>
					<td>
						<input type="hidden" name="company_id" defaultValue={item["company_id"]} />
					</td>
					{/* <td className="stamp-delete">
						<FontAwesomeIcon icon={faTrash} className="action-button" onClick={(ev) => {
							this.DeleteTenantConfirmation(ev, item["tenant_id"], item["company_id"]);
						}} />
					</td> */}
				</tr>
			);

			/* */
			rows.push(row);

		}

		/* */
		if (rows.length < 1) {
			rows.push(
				<tr key="empty">
					<td colSpan="4">
						<div className="lz-txt-c lz-p-40">
							<span>{t('common:settings.tenant.empty')}</span>
						</div>
					</td>
				</tr>
			);
		}

		/* */
		return (
			<ResizableTableWithCache id="datalist" className='tenant-setting-list' storageKey={`colWidths_Tenant`}>
				<thead>
					<StateFragment state={shedOnScroll}>
						{s => (
							<tr className={`${s.get({noproxy: true}) && "shed"}`}>
								<th className="tenant-id" id="tenant-id">
									<div>{t("common:settings.tenant.list-tenant-id")}</div>
								</th>
								<th className="tenant-name" id="tenant-name">
									<div>{t("common:settings.tenant.list-tenant-name")}</div>
								</th>
								<th className="contract-start-date" id="contract-start-date">
									<div>{t("common:settings.tenant.list-contract-start-date")}</div>
								</th>
								<th className="limit-contract-person" id="limit-contract-person">
									<div>{t("common:settings.tenant.list-limit-contract-person")}</div>
								</th>
								<th className='tenant-company-id'>
								</th>
							</tr>
						)}
					</StateFragment>

				</thead>
				<tbody>{rows}</tbody>
			</ResizableTableWithCache>
		);

	}

	DeleteTenantConfirmation = (ev, tenant_id, company_id) => {

		/* Prepare Modal */
		let { modal } = this.state;

		/* Translate function */
		let { t } = this.props;

		/* Show Modal */
		modal.props = {
			"show": true,
			"dialogClassName": "tenant-dialog",
			"centered": true,
		}

		/* */
		modal.title = "common:settings.tenant.delete.comfirm.header";

		/* */
		modal.body = () => {
			return (
				<div>{t("common:settings.tenant.delete.comfirm.message")}</div>
			);
		};

		modal.form = {};

		/* Clear DialogFooter */
		modal.action = [];

		/* */
		modal.action.push(
			<Button key="ok" variant="primary" onClick={(ev) => {
				this.TenantDelete(tenant_id, company_id);
			}}>
				<span>{t("common:general.yes-delete")}</span>
			</Button>
		);

		/* */
		this.setState({ modal });

	}

	/** [RENDER] */
	CreateButton = () => {

		/* Search Function */
		let { t } = this.props;

		/* */
		let { datalist } = this.state;

		
		/* */
		return (
			<div key="avairable" >
				<Button
					sx={{ borderRadius: 19 }}
					key="create"
					title="Action"
					disabled = { !datalist }
					variant="contained"
					startIcon={<AddIcon />}
					onClick={() => { this.openCreateFormDialog() }}
				>
					{t("common:settings.stamp.button-create")}
				</Button>
			</div>
		);

	}

	/** [RENDER] */
	SelectTab = () => {

		/* Search Function */
		let { t } = this.props;

		/* */
		let tabs = [];

		/* */
		for (let i = 0; i < this.state.tabs.length; i++) {

			/* */
			let c = "select-tab-item";

			/* */
			if (i === this.state.activeTab) {
				c = "select-tab-item active";
			}

			/* */
			let clickEvent = () => {
				this.updateTab(i);
			};

			/* */
			let tab = (
				<div key={i} className={c} onClick={clickEvent}>
					{/* <div key={i} className={c} onClick={ clickEvent }> */}
					<span>{t("common:" + this.state.tabs[i])}</span>
				</div>
			);

			/* */
			tabs.push(tab);

		}

		/* */
		return (
			<div className="select-tab">{tabs}</div>
		);

	}

	/** [Element] */
	Pagination = () => {

		let { pagination, datalist } = this.state;

		/* clear */
		datalist = null;

		/* Calculate MaxPage */
		let max = Math.ceil(pagination.total / pagination.take);

		/* */
		let prev = {
			
			"onClick": () => {
				pagination.page = (pagination.page - 1);
				this.setState({ pagination, datalist });
			},
		};

		/* */
		let next = {
			
			"onClick": () => {
				pagination.page = (pagination.page + 1);
				this.setState({ pagination, datalist });
			},
		}

		/* */
		if (pagination.page === 1) {
			prev["disabled"] = true;
		}

		/* */
		if (pagination.page === max || max === 0) {
			next["disabled"] = true;
		}
		
		/* */
		let start_list = 0;
		let last_list = 0;
		if(pagination.total > 0){
			start_list = 1 + ( ( pagination.page - 1 ) * pagination.take );
			if(pagination.page === max){
				last_list = pagination.total
			}else{
				last_list = start_list + (pagination.take -1);
			}
		}
		
		/* */
		return (
			<div className="paginate">
				<div className="paginate-info">
					<span>{start_list}</span>
					<span>{last_list}</span>
					<span>{pagination.total}</span>
				</div>
				<div className="paginate-nav">
					<IconButton key="prev" {...prev}>
						<ChevronLeftIcon />
					</IconButton>
					<IconButton key="next" {...next}>
						<ChevronRightIcon />
					</IconButton>
				</div>
			</div>
		);
	}

	formPrepareBeforeSubmit = (ev) => {
		let form = ev.target;
		form['name'].setCustomValidity("");

		this.formSubmitHandler(ev, null, this.formSubmitCallback)
	}

	formSubmitCallback = (ev) => {
		const form = ev.target;

		if (this.additionalValidity(form) === false) {
			ev.stopPropagation();
		} else {
			if (form.action_name.value === "create") {
				this.TenantSave(form);
			} else {
				this.TenantUpdate(form);
			}
		}
	}

	additionalValidity = (form) => {

		/* Translate function */
		let { t } = this.props;

		// console.dir(form);
		let { fieldValidate } = this.state;

		if (form['name']['value'] === '') {
			this.setValidity(form, 'name', fieldValidate, t('common:message.input.required', { field_name: t("common:settings.tenant.name") }));
		}

		this.setState({ fieldValidate });

		if (Object.keys(fieldValidate).length > 0) {
			return false;
		} else {
			return true;
		}
	}

	setValidity = (form, item_name, fieldValidate, message) => {
		form[item_name].setCustomValidity(message);
		fieldValidate[item_name] = message;
	}

	/** [Action] */
	openCreateFormDialog = () => {

		/* Prepare Modal */
		let { modal } = this.state;

		/* Translate function */
		let { t } = this.props;

		/* Show Modal */
		modal.props = {
			"show": true,
			"dialogClassName": "tenant-create-dialog",
			"centered": true,
		}

		/* */
		modal.title = "common:settings.tenant.dialog.create.header";

		modal.form = {
			id: "tenant-form",
			onSubmit: this.formPrepareBeforeSubmit,
			noValidate: true,
		};

		/* Clear DialogFooter */
		modal.action = [];

		/* */
		modal.action.push(
			<Button key="ok" type="submit" variant="contained"  color="primary" >
				<span>{t("common:general.save")}</span>
			</Button>
		);

		/* */
		this.setState({ modal });
		this.setStateFields();
		this.createFormDialog_DataForm();
	}

	/** [Action] */
	createFormDialog_DataForm = () => {

		/* Prepare Modal */
		let { modal, company_id } = this.state;
		let { FieldControl } = this;

		/* */
		modal.body = () => {
			return (
				<div>
					<div className="lz-m-10">
						<Form.Row>
							<FieldControl name={`name`} />
						</Form.Row>
						<input type="hidden" name="action_name" defaultValue="create" />
						<input type="hidden" name="company_id" defaultValue={company_id} />
					</div>
				</div>
			);
		}

		/* */
		this.setState({ modal });

	}

	/** [Action] */
	openDetailFormDialog = (data) => {

		/* Prepare Modal */
		let { modal } = this.state;

		/* Translate function */
		let { t } = this.props;

		/* Show Modal */
		modal.props = {
			"show": true,
			"dialogClassName": "tenants-detail-dialog",
			"centered": true,
		}

		/* */
		modal.title = "common:settings.tenant.dialog.detail.header";

		modal.form = {
			id: "tenant-form",
			onSubmit: this.formPrepareBeforeSubmit,
			noValidate: true,
		};

		/* */
		modal.body = Loading;

		/* Clear DialogFooter */
		modal.action = [];

		/* */
		modal.action.push(
			<Button key="ok" type="submit" variant="contained"  color="primary">
				<span>{t("common:general.save")}</span>
			</Button>
		);

		/* */
		this.setState({ modal });
		this.setFormData(data);
	}

	setFormData = (data) => {

		let { formData, company_name } = this.state;

		formData = {
			"tenant_id": data.tenant_id,
			"company_id": data.company_id,
			"company_name": company_name,
			"name": data.name,
			"status": data.status,
			"contract_start_date": data.contract_start_date,
			"limit_contract_person": data.limit_contract_person,
			"function_type_id": data.function_type_id,
			"api_token": data.api_token,
			"enableRestrictIp": data.enableRestrictIp,
			"restrictIp": data.restrictIp,
			"enable_guest_transfer": data.enable_guest_transfer,
			"enable_host_transfer": data.enable_host_transfer,
		};
		this.setState({ formData });
		this.setStateFields();
		this.getUpdateTenantServicelist(data);
	}

	getUpdateTenantServicelist = (data) => {

		/* */
		Service.getTenantServiceList(data).then((response) => {

			/* */
			let { tenant_service_list } = this.state;
			
			/* */
			tenant_service_list = response.tenantServiceList;

			/* */
			this.setState({ tenant_service_list });

			this.detailFormDialog_DataForm();
		}).catch((error) => {
			//console.log(error.response);
		});
	}

	/** [Action] */
	detailFormDialog_DataForm = () => {

		/* Prepare Modal */
		let { modal, formData } = this.state;
		let { FieldControl, AllServiceLabel } = this;

		/* Translate function */
		let { t } = this.props;

		/* */
		modal.body = () => {
			return (
				<div id="settings-tenant-detail" className="">

					{/* TextBox section */}
					<div id="tenant-form" className="lz-m-10">
						<input type="hidden" name="action_name" defaultValue="update" />
						<input type="hidden" name="enableRestrictIp" defaultValue={formData.enableRestrictIp} />
						<input type="hidden" name="restrictIp" defaultValue={formData.restrictIp} />
						<Form.Row>
							<FieldControl name={`company_id`} xs={12} md={2} readOnly />
							<FieldControl name={`company_name`} xs={12} md={4} readOnly />
							<FieldControl name={`tenant_id`} xs={12} md={2} readOnly />
							<FieldControl name={`name`} xs={12} md={4} validate="true" />
						</Form.Row>

						<Form.Row>
							<FieldControl name={`api_token`} xs={12} md={12} readOnly
								append={(props) => {
									return (
										<InputGroup.Append>
											<InputGroup.Text style={{ cursor: "pointer" }}>
												<FontAwesomeIcon icon={faSync} className="action-button" onClick={() => {
													// generate tenant token
													let value = new Date().toString();
													let hash = CryptoJS.SHA256(value).toString();
													formData.api_token = hash;
													this.setState({ formData });
												}} />
											</InputGroup.Text>
										</InputGroup.Append>
									);
								}} />
						</Form.Row>

						<Form.Row>
							<div className="tenant-info-item">
								<span className="label">{t("common:general.contract-services")}</span>
								<span className="value services-label">
									<AllServiceLabel />
									{/* {ServiceLabel} */}
								</span>
							</div>
						</Form.Row>

						{/* Active (status) */}

						<Form.Row>
							<Form.Group className="lz-mt-10" controlId="status">
								<Form.Check type="switch" name="status" label={t('common:settings.tenant.active')} defaultChecked={formData.status} />
							</Form.Group>
						</Form.Row>
					</div>
				</div>
			);
		}

		/* */
		this.setState({ modal });

	}

	ServiceIcon = (props) => {

		if (props.code) {
			let Icon = SVGIcons[props.code];
			return <Icon {...props} />;
		}
		return null;
	}

	AllServiceLabel = () => {
		let { t } = this.props;
		let { tenant_service_list } = this.state;
		let { ServiceIcon } = this;
		// let serviceItems = [];
		let ServiceLabel = [];
		for (let service of tenant_service_list) {
			ServiceLabel.push(
				<p key={`service-${service.function_type_id}`} className="label active">
					<ServiceIcon code={service.function_type_code} className="icon" />
					<span>{t(`common:${service.function_type_label}`)}</span>
				</p>
			);
		}
		// ServiceLabel = ServiceLabel.join(", ");

		return ServiceLabel;
	}

	/* */
	async TenantSave(form) {

		/* */
		let { modal, datalist } = this.state;
		/* */
		modal.body = () => {
			return (
				<div className="lz-m-20">
					<Loading />
				</div>
			);
		};

		/* */
		this.setState({ modal });

		/* Make today */
		let dateObj = new Date();
		let year = dateObj.getFullYear();
		let month = ("00" + (dateObj.getMonth() + 1)).slice(-2);
		let day = ("00" + dateObj.getDate()).slice(-2);;
		let today = year + "-" + month + "-" + day;

		// Set api data
		let data = {
			"company_id": form["company_id"]["value"],
			"name": form["name"]["value"],
			"contract_start_date": today,
			"limit_contract_person": 50,
			"function_type": [],
		};

		/* */
		Service.SaveTenant(data).then((response) => {

			/* */
			datalist = null;

			/* */
			modal.props = { "show": false };

			/* */
			this.setState({ modal, datalist });

		}).catch((error) => {

			/* */
			//console.log("error", error);

		});

	}

	/* */
	async TenantUpdate(form) {

		/* */
		let { modal, datalist } = this.state;
		/* */
		modal.body = () => {
			return (
				<div className="lz-m-20">
					<Loading />
				</div>
			);
		};

		/* */
		this.setState({ modal });

		// Set api data
		let data = {
			"tenant_id": form["tenant_id"]["value"],
			"company_id": form["company_id"]["value"],
			"name": form["name"]["value"],
			"api_token": form["api_token"]["value"],
			"status": form.elements["status"]["checked"] ? 0 : 1,
			"enableRestrictIp": form["enableRestrictIp"]["value"],
			"restrictIp": form["restrictIp"]["value"] === "" ? null : form["restrictIp"]["value"],
		};

		/* */
		Service.UpdateTenant(data).then((response) => {

			/* */
			datalist = null;

			/* */
			modal.props = { "show": false };

			/* */
			this.setState({ modal, datalist });

		}).catch((error) => {

			/* */
			//console.log("error", error);

		});

	}

	/* */
	async TenantDelete(tenant_id, company_id) {

		/* */
		let { modal, datalist } = this.state;
		/* */
		modal.body = () => {
			return (
				<div className="lz-m-20">
					<Loading />
				</div>
			);
		};
		modal.action = [];

		/* */
		this.setState({ modal });

		// Set api data
		let data = {
			"tenant_id": tenant_id,
			"company_id": company_id,
		};

		/* */
		Service.DeleteTenant(data).then((response) => {

			/* */
			datalist = null;

			/* */
			modal.props = { "show": false };

			/* */
			this.setState({ modal, datalist });

		}).catch((error) => {

			/* */
			//console.log("error", error);

		});
	}
}

export default withTranslation()(Tenant);
