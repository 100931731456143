import {MenuItem} from "@mui/material";
import {usePopper} from "react-popper";
import {useHookstate} from "@hookstate/core";
import {useEffect, useRef} from "react";
import CircularProgress from "@mui/material/CircularProgress";


/**
 * @param {{ anchorEl: any; className: any; open: any; handleClose: any; items: any; loading: any; onClick: any; offsetTop: any; }} props
 */
export default function BaseTooltipSearch(props) {
    const { anchorEl,className, open, handleClose, items, loading,onClick,offsetTop } = props;
    const popperElement = useHookstate(null);
    const arrowElement = useHookstate(null);
    const popperRef = useRef();
    const arrowRef = useRef();
    const { styles, attributes } = usePopper(anchorEl, popperElement.get(), {
        placement: "auto-start",
    });

    /**
     * @param {import("react").MouseEvent<HTMLLIElement, MouseEvent>} e
     * @param {any} data
     */
    function onMenuClick(e, data) {
        handleClose(e, data);
    }

    useEffect(() => {
        if (popperRef.current) popperElement.set(popperRef.current);
    }, [popperRef]);

    useEffect(() => {
        if (arrowRef.current) arrowElement.set(arrowRef.current);
    }, [arrowRef]);

    function renderMenuItem() {
        return loading ? (
            <CircularProgress size={13} />
        ) : (
            items.map((a) => (
                <MenuItem style={{
                    fontSize: 13,
                    paddingLeft: 0,
                    paddingRight: 0,
                    paddingTop: 4,
                    paddingBottom: 4,
                    flexWrap: "wrap"
                }} key={a.value} onClick={(e) => onMenuClick(e, a)}>
                    {a.value}
                </MenuItem>
            ))
        );
    }

    return (
        <div
            className={`search_popper_suggest ${!open ? "hide_popper" : ""} ${className}`}
            ref={popperRef}
            style={styles.popper}
            {...attributes.popper}
        >
            {renderMenuItem()}
        </div>
    );
}
