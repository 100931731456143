import '../Assets/css/settings.css';
import React from 'react';
import Layout from '../../Layout';
import Menu from './Menu';
import { withTranslation } from "react-i18next";
import { DropdownButton, Dropdown } from 'react-bootstrap';

class Notification extends Layout {

	/** */
	constructor(props) {

		/* */
		super(props);

		/* !! TODO DUMMY DATA !! */
		this.state = {
			"paginate": {
				"page": 0,
				"from": 11,
				"till": 20,
				"total": 40,
			},
			"datalist": [],
		};

		/* */
		for (let i = 1; i <= 20; i++) {

			/* */
			this.state.datalist.push({
				"title": "NotificationTitle",
				"desc": "NotificationDesc",
				"readed": "FALSE",
				"created": "2020-12-31 23:59:59",
			});

		}

	}

	/** [RENDER] */
	Menu = () => {

		/* Load Menu from Pages/Settings/Menu.jsx */
		return (<Menu {...this.props} page={this.state.page} />);

	}

	/** [RENDER] */
	Paginate = () => {

		/* */
		let pagi = this.state.paginate;

		/* */
		return (
			<div className="paginate">
				<div className="paginate-info">
					<span>{pagi.from}</span>
					<span>{pagi.till}</span>
					<span>{pagi.total}</span>
				</div>
				<div className="paginate-nav">
					<button type="button" className="paginate-prev">
						<span>&lt;</span>
					</button>
					<button type="button" className="paginate-next">
						<span>&gt;</span>
					</button>
				</div>
			</div>
		);

	}

	/** [RENDER] */
	NotificationsList = () => {

		/* */
		let notifications = [];

		/* */
		for (let i = 1; i <= 20; i++) {

			/* */
			let read = "unread";

			/* */
			if (i % 5 === 0) {
				read = "read";
			}

			let notification = (
				<div className="notification-item" data-status={read}>
					<div className="notification-head">
						<div className="notification-title">
							<span>notification.title</span>
							<span className={read}></span>
						</div>
						<div className="notification-time">
							<span>notification.time</span>
						</div>
					</div>
					<div className="notification-desc">
						<span>notification.desc</span>
					</div>
					<div className="notification-details">
						{/* <a href="#"> */}
							<span>View detail</span>
						{/* </a> */}
					</div>
				</div>
			);

			/* */
			notifications.push(notification);

		}

		/* */
		return (
			<div className="notification-list">
				{notifications}
			</div>
		)

	}

	/** [RENDER] */
	render() {

		/* */
		// let { t } = this.props;

		/* */
		const { Main } = this;

		/* */
		const action1 = () => { alert("Action-1"); };
		const action2 = () => { alert("Action-2"); };
		const action3 = () => { alert("Action-3"); };

		return (
			<Main>

				<div id="page-settings-notification" className="page-settings">

					{/* */}
					<div className="lz-flex">

						{/* Left */}
						<div className="lz-flex-0">
							<div className="lz-m-20">
								<span>お知らせ管理</span>
							</div>
						</div>

						{/* Center */}
						<div className="lz-flex-1"></div>

						{/* Right */}
						<div className="lz-flex-0">

							<div className="lz-m-20">
								{/* <a href="#"> */}
									<span>すべてのお知らせを既読にする</span>
								{/* </a> */}
							</div>

						</div>

					</div>

					{/* SearchForm & Paginate */}
					<div className="lz-flex">

						<div className="lz-flex-1">

							<div className="lz-m-10">

								{/* SearchForm */}
								<div className="lz-flex">

									<DropdownButton className="lz-btn-mini lz-mr-8" title="Action">
										<Dropdown.Item onClick={action1}>Action</Dropdown.Item>
										<Dropdown.Item onClick={action2}>Another action</Dropdown.Item>
										<Dropdown.Item onClick={action3}>Something else</Dropdown.Item>
									</DropdownButton>

								</div>

							</div>

						</div>

						<div className="lz-flex-0">
							<div className="lz-m-10">
								<this.Paginate />
							</div>
						</div>

					</div>

					{/* */}
					<div className="">

						<div className="">
							<this.NotificationsList />
						</div>

					</div>

					{/* BottomPaginate */}
					<div className="lz-flex" hidden>

						<div className="lz-flex-1"></div>

						<div className="lz-flex-0">
							<div className="lz-m-10">
								<this.Paginate />
							</div>
						</div>

					</div>

				</div>

			</Main>
		);

	}

}

export default withTranslation()(Notification);