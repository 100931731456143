// @ts-nocheck
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import styles from "./drive-dialog.module.css";
export default function BaseDialogDriveCrud({
  dialogContentComponent,
  dialogActionComponent,
  open,
  handleClose,
  title,
  zIndex,
  className,
  maxWidth
}) {
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        sx={{
          zIndex,
        }}
      
        disablePortal={true}
        className={`${styles["drive_base_dialog"]} ${className || ''}`}
        maxWidth={maxWidth || 'xs'}
      >
        <DialogTitle className={styles["drive_base_dialog_title"]}>
          {title}
        </DialogTitle>
        <DialogContent className={styles["drive_base_dialog_content"]}>
          {dialogContentComponent ? dialogContentComponent : null}
        </DialogContent>
        <DialogActions className={styles["drive_base_dialog_actions"]}>
          {dialogActionComponent ? dialogActionComponent : null}
          {/* <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleClose}>Subscribe</Button> */}
        </DialogActions>
      </Dialog>
    </div>
  );
}
