// @ts-nocheck
import { hookstate, useHookstate } from "@hookstate/core";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import LoadingProgress from "../../Fragments/PDFViewer/LoadingProgress";
import { LinearProgress } from "@mui/material";
export const modalUploadProps = hookstate({
	"show": false,
	"centered": true,
	"backdrop": true,
});

export const modalState = hookstate({
	modalTitle: 'Title',
	fileUploadIndex: 0,
	fileLength: 0,
	process: 0
});
export default function ModalUpload() {
	const { t } = useTranslation();
	const modalStateData = useHookstate(modalState)
	const modalUploadPropsData = useHookstate(modalUploadProps)
	const ModalClose = () => {
        modalUploadPropsData.show.set(false)
    };
	return (
		<Modal {...modalUploadPropsData.get()} animation={false} onHide={ModalClose}>
			<Modal.Header closeButton={true}>
				<Modal.Title>{t(modalStateData.modalTitle.get())}</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				<div className='process-block'>
					<span className='title-process'>{t("documents.determine.upload-file-processing")}: </span>
					<span>{`${modalStateData.fileUploadIndex.get()}/${modalStateData.fileLength.get()}`}</span>
				</div>
				<div className='showLoadingProcess'>
					<LoadingProgress progress={modalStateData.process.get()} showProgress={true}/>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="outlined" onClick={ModalClose}>
					<span>{t("common:general.close")}</span>
				</Button>
			</Modal.Footer>
		</Modal>
	)

}