// @ts-nocheck
import { useHookstate } from "@hookstate/core";
import { Box, Checkbox, TableSortLabel } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { visuallyHidden } from "@mui/utils";
import { useEffect, useRef } from "react";
import Loading from "../../../Pages/Loading";
import { shedOnScroll } from "../../data-access/state";
import { useHover } from "../../utils/hooks";
import VirtualizedTable, { TableCellMapped } from "./drive-virtual-table";
import { useLocation } from "react-router-dom";
/**
 * @param {{ tableHeader: { key: string, title: string, type: "icon"};
 *            width: string (by %);
 *            sort: boolean;
 *            sortCallback: functioncallback;
 *            tableRows: any[];
 *            onClickRowHandle: function callback;
 *            loading: boolean;
 *            sort: boolean;
 *            order: {[header.key]: string};
 *            onDblClickRowHandle: function callback;
 *            onFileDragEnter: function callback;
 *            onFileDragLeave: function callback;
 *            onFileDragOver: function callback;
 *            onCheckSelectedAll: function callback;
 *            handleContextMenu: function callback;
 *            onFileDrop: function callback;
 *            isShowTableDragFile: boolean;
 *            enableSelectable: boolean;
 *            selectRowKey?: string (key for mapping value of select row);
 *            rowClasses: any; }} props
 */
export default function BaseTable(props) {
  const {
    tableHeader,
    tableRows,
    onClickRowHandle,
    loading,
    onDblClickRowHandle,
    rowClasses,
    headerActionsComponent,
    order,
    enableSelectable,
    selectedRows,
    isCompanyFolder,
    isCompleteFolder,
    selectRowKey,
    onCheckSelectedAll,
    keyHoverEdit,
    onEditClick,
    handleContextMenu,
    onHoldShiftMultiSelect,
    onHoldControlMultiSelect,
    enableVirtualScrolled,
    totalRecord,
    scrollLoading,
  } = props;
  const shed = useHookstate(shedOnScroll);

  const ref = useRef(null);
  const tableRef = useRef(null);
  const { pathname } = useLocation();

  useEffect(() => {
    if(tableRef.current) tableRef.current.scrollIntoView();
  }, [pathname])

  const previousRowIndexClick = useHookstate(-1);
  const { mouseOver, mouseOut, hovered } = useHover();

  function changeClass() {
    const scrollValue = ref?.current?.scrollTop;
    // scrollHeightOnScroll.set(ref.current);
    // console.log(scrollValue);
    if (scrollValue > 2 && !shed.get({ stealth: true })) {
      shed.set(true);
    } else {
      if (shed.get({ stealth: true }) && scrollValue === 0) shed.set(false);
    }
  }
  useEffect(() => {
    if (ref.current) {
      ref.current.addEventListener("scroll", changeClass, false);
      return () => {
        ref?.current?.removeEventListener("scroll", changeClass, false);
      };
    }
  }, [ref]);
  // console.log(document.body.scrollHeight, document.body.clientHeight);
  // if (document.body.scrollHeight > document.body.clientHeight) {
  // }
  // console.log(loading)
  // if (loading) {
  //   return (
  //   );
  // }
  // console.log("rr");
  function HeaderTableVirtual() {
    return (
      <>
        <TableRow className="row_header_drive_action">
          <TableCell
            sx={{ padding: 0 }}
            colSpan={tableHeader.length + (enableSelectable ? 1 : 0)}
            className={shed.get() ? "shed" : ""}
          >
            {headerActionsComponent ? headerActionsComponent : null}
          </TableCell>
        </TableRow>
        <TableRow>
          {enableSelectable ? (
            <TableCell
              className={
                shed.get()
                  ? "table_header_cell shed_col2 checkbox-base-table"
                  : "table_header_cell checkbox-base-table"
              }
              style={{
                textAlign: "center",
              }}
              sx={{ width: "5%" }}
              // className={row[key]['class']}
              component="td"
              scope="row"
              padding="checkbox"
            >
              <Checkbox
                color="primary"
                onChange={(ev) => onCheckSelectedAll(ev.target.checked)}
                checked={selectedRows?.length === tableRows?.length}
                inputProps={{
                  "aria-label": "select all desserts",
                }}
              />
            </TableCell>
          ) : null}
          {tableHeader
            ? tableHeader.map((header, i) => (
                <TableCell
                  width={header.width ? header.width : "10%"}
                  className={
                    shed.get()
                      ? "table_header_cell shed_col2"
                      : "table_header_cell"
                  }
                  key={header.key}
                  sortDirection={
                    header?.sort && order ? order[header.key] : undefined
                  }
                >
                  {order && header.key in order ? (
                    <TableSortLabel
                      active={!!order[header.key]}
                      direction={
                        order && order[header.key]
                          ? order[header.key]
                          : undefined
                      }
                      sx={{
                        marginLeft: i === 0 ? "14px" : 0,
                      }}
                      hideSortIcon={!!order[header.key]}
                      onClick={header.sortCallback ? header.sortCallback : null}
                    >
                      {header.title}
                      {order && order[header.key] ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order[header.key] === "desc"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  ) : (
                    <span
                      style={{
                        marginLeft: i === 0 ? "14px" : 0,
                      }}
                    >
                      {header.title}
                    </span>
                  )}
                </TableCell>
              ))
            : null}
        </TableRow>
      </>
    );
  }
  if (enableVirtualScrolled && tableRows?.length > 0) {
    return (
      <VirtualizedTable
        {...props}
        headerActionsComponent={HeaderTableVirtual}
      />
    );
  }
  return (
    <TableContainer
      component={Paper}
      elevation={0}
      ref={ref}
      sx={{
        borderRadius: 0,
        maxHeight: "calc(100% - 1px)",
        maxWidth: loading ? "100vw" : "auto",
        overflowY: loading ? "hidden" : "auto",
        overflowX: "hidden",
      }}
    >
      <Table
        ref={tableRef}
        stickyHeader
        sx={{ minWidth: 500 }}
        className="relative list_table drive-table"
        aria-label="custom pagination table"
      >
        <TableHead className={"list_table_th"}>
          <TableRow className="row_header_drive_action">
            <TableCell
              sx={{ padding: 0 }}
              colSpan={tableHeader.length + (enableSelectable ? 1 : 0)}
              className={shed.get() ? "shed" : ""}
            >
              {headerActionsComponent ? headerActionsComponent : null}
            </TableCell>
          </TableRow>
          <TableRow>
            {enableSelectable ? (
              <TableCell
                sx={{ width: "5%" }}
                className={
                  shed.get()
                    ? "table_header_cell shed_col2 checkbox-base-table"
                    : "table_header_cell checkbox-base-table"
                }
                style={{
                  textAlign: "center",
                }}
                // className={row[key]['class']}
                component="td"
                scope="row"
                padding="checkbox"
              >
                <Checkbox
                  color="primary"
                  onChange={(ev) => onCheckSelectedAll(ev.target.checked)}
                  checked={selectedRows?.length === tableRows?.length}
                  inputProps={{
                    "aria-label": "select all desserts",
                  }}
                />
              </TableCell>
            ) : null}
            {tableHeader
              ? tableHeader.map((header, i) => (
                  <TableCell
                    width={header.width ? header.width : "10%"}
                    className={
                      shed.get()
                        ? "table_header_cell shed_col2"
                        : "table_header_cell"
                    }
                    key={header.key + i}
                    sortDirection={header.sort ? order[header.key] : undefined}
                  >
                    {order && header.key in order ? (
                      <TableSortLabel
                        active={!!order[header.key]}
                        direction={
                          order && order[header.key]
                            ? order[header.key]
                            : undefined
                        }
                        hideSortIcon={!!order[header.key]}
                        onClick={
                          header.sortCallback ? header.sortCallback : null
                        }
                      >
                        {header.title}
                        {order && order[header.key] ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order[header.key] === "desc"
                              ? "sorted descending"
                              : "sorted ascending"}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    ) : (
                      <>{header.title}</>
                    )}
                  </TableCell>
                ))
              : null}
          </TableRow>
        </TableHead>
        <TableBody
          onDragEnter={props.onFileDragEnter}
          onDragLeave={props.onFileDragLeave}
          onDragOver={props.onFileDragOver}
          onDrop={props.onFileDrop}
        >
          {loading ? (
            <TableRow>
              <TableCell
                sx={{ padding: 0 }}
                colSpan={tableHeader.length + (enableSelectable ? 1 : 0)}
              >
                <div
                  style={{
                    width: "100%",
                    height: "calc(100vh - 160px)",
                    backgroundColor: "white",
                  }}
                  className="main-body-loading"
                >
                  <Loading />
                </div>
              </TableCell>
            </TableRow>
          ) : null}
          {tableRows && tableRows?.length > 0 ? (
            tableRows.map((row, i) => (
              <TableRow
                hover
                key={i}
                onMouseOver={(e) => mouseOver(e, i)}
                onMouseOut={(e) => mouseOut(e, i)}
                className={`${rowClasses} ${
                  selectedRows &&
                  selectedRows.some(
                    (a) => row[selectRowKey] === a[selectRowKey]
                  )
                    ? "isSelected"
                    : null
                }`}
                onDoubleClick={
                  onDblClickRowHandle
                    ? (ev) => onDblClickRowHandle(ev, row)
                    : undefined
                }
                onClick={(ev) => {
                  if (ev.shiftKey && onHoldShiftMultiSelect) {
                    if (previousRowIndexClick.get({ stealth: true }) > -1) {
                      onHoldShiftMultiSelect(
                        row,
                        previousRowIndexClick.get({ stealth: true }),
                        i
                      );
                      previousRowIndexClick.set(i);
                      return;
                    }
                  }
                  previousRowIndexClick.set(i);
                  if ((ev.ctrlKey || ev.metaKey) && onHoldControlMultiSelect) {
                    return onHoldControlMultiSelect(row);
                  }
                  return onClickRowHandle
                    ? onClickRowHandle(ev, row)
                    : undefined;
                }}
                onContextMenu={
                  handleContextMenu
                    ? (e) => {
                        e.preventDefault();
                        return handleContextMenu(e, row);
                      }
                    : undefined
                }
              >
                {enableSelectable ? (
                  <TableCell
                    sx={{ width: "5%" }}
                    className="checkbox-base-table"
                    padding="checkbox"
                  >
                    <Checkbox
                      color="primary"
                      checked={
                        selectedRows &&
                        selectedRows.some(
                          (a) => row[selectRowKey] === a[selectRowKey]
                        )
                      }
                      inputProps={{
                        "aria-label": "select all desserts",
                      }}
                    />
                  </TableCell>
                ) : null}
                {tableHeader?.map((key, i2) => {
                  // console.log
                  return (
                    <TableCellMapped
                      row={row}
                      key={i2}
                      keyHeader={key}
                      i2={i2}
                      onEditClick={onEditClick}
                      hovered={hovered}
                      _index={i2}
                    />
                  );
                })}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell
                className="text-center"
                colSpan={tableHeader.length + (enableSelectable ? 1 : 0)}
              >
                NO DATA
                <div
                  style={{
                    height: "calc(100vh - 236px)",
                  }}
                ></div>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
