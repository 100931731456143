// @ts-nocheck
import ErrorIcon from "@mui/icons-material/Error";
import { useTranslation } from "react-i18next";

// import PDFViewer from "./index";
// import PDFBGIcon from '../Assets/img/icons/file_pdf.svg';
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHookstate } from "@hookstate/core";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { useEffect, useRef } from "react";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "../../../Pages/Assets/css/pdf-preview.css";
import PDFViewer from "../../../Pages/Fragments/PDFViewer";
import Loading from "../../../Pages/Loading";
import { formatBytes } from "../../utils/format-file-size";

const MyCustomPNGRenderer = ({ mainState: { currentDocument } }) => {
  console.log(currentDocument);
  if (!currentDocument) return null;
  return (
    <div id="my-png-renderer">
      <img id="png-img" src={currentDocument.fileData} />
    </div>
  );
};

MyCustomPNGRenderer.fileTypes = [
  "png",
  "jpg",
  "jpeg",
  "image/jpeg",
  "image/png",
  "pdf",
];
MyCustomPNGRenderer.weight = 1;
// import PDFViewer from "../../../Pages/Fragments/PDFViewer";
export default function DrivePreviewDialog(props) {
  const {
    open,
    previewPdfUrl,
    handleClose,
    files,
    files_sort,
    fileType,
    // rowData,
    fileUrl,
    fileName,
    row,
    fileData,
    loadingFile,
    isPreviewAttachment,
  } = props;
  // console.log(row);
  const { t } = useTranslation();
  // const loading = useHookstate(false);
  // const pdfDocOptions = useHookstate({});
  // const numOfPages = useHookstate(0);
  const currentFileUrl = useHookstate("");
  const viewProps = useHookstate({
    files: files,
    files_sort: files_sort,
    file: {
      file: fileUrl,
      name: fileName,
      size: row.size,
      scale: 0.3,
      url: fileUrl,
    },
    upload: false,
    showAll: true,
    scale: 0.3,
    fit: true,
    controller: true,
    info: true,
    full: true,
  });
  const viewUrl = useHookstate("");
  useEffect(() => {
    // console.log('fileUrl',fileUrl)
    currentFileUrl.set(fileUrl);
    if (fileUrl && fileUrl !== "error")
      viewProps.set({
        files: files,
        files_sort: files_sort,
        file: {
          file: fileUrl,
          name: fileName,
          size: row.size,
          scale: 0.3,
          url: fileUrl,
        },
        upload: false,
        showAll: true,
        scale: 0.3,
        fit: true,
        controller: true,
        info: true,
        full: true,
      });
    if (fileUrl === "error") {
    }
  }, [fileUrl]);

  return (
    <div className="layout">
      <div className="pdf-full-preview-wrapper active">
        <div className="header-wrapper sticky">
          <div className="header-left"></div>
          <div className="header-center document-title"></div>
          <div className="header-right">
            <IconButton className="btn-return" onClick={handleClose}>
              <CloseIcon
                className="fa-flip-horizontal"
                title={t("common:document.close")}
              />
            </IconButton>
          </div>
        </div>
        <div className="body-wrapper drive-zip-preview">
          {loadingFile ? (
            <Loading />
          ) : (
            <>
              {currentFileUrl.get() ? (
                <FileTypeContent
                  fileType={fileType}
                  loading={loadingFile}
                  isPreviewAttachment={isPreviewAttachment}
                  url={currentFileUrl.get()}
                  viewProps={viewProps.get({ noproxy: true })}
                  data={row}
                />
              ) : (
                <FileTypeContent
                  isPreviewAttachment={isPreviewAttachment}
                  fileType={fileType}
                  loading={loadingFile}
                  url={currentFileUrl.get()}
                  viewProps={viewProps.get({ noproxy: true })}
                  data={row}
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
function MappedRenderByType({ type, url, loading }) {
  if (url === "error")
    return (
      <div className="cant_preview">
        <ErrorIcon />
        Server error
      </div>
    );

  switch (type) {
    case "image":
      if (!url || loading) return <Loading />;
      return <img draggable={false} src={url} />;
    case "mp4":
      if (!url || loading) return <Loading />;
      return (
        <video controls autoplay className="preview_video_iframe">
          <source src={url} type="video/mp4" />
        </video>
      );
    case "excel":
      if (!url || loading) return <Loading />;
      // console.log('url', url)
      return (
        <iframe
          src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
            url
          )}`}
          className="preview_excel_iframe"
          frameborder="0"
        >
          This is an embedded{" "}
          <a target="_blank" href="http://office.com">
            Microsoft Office
          </a>{" "}
          document, powered by{" "}
          <a target="_blank" href="http://office.com/webapps">
            Office Online
          </a>
          .
        </iframe>
      );
    default:
      return (
        <div className="cant_preview">
          <img
            width="354.187"
            height="334"
            src="/icons/preview_off.svg"
            alt="img"
          />
          このタイプのファイルはプレビューできません
        </div>
      );
  }
}
function FileTypeContent(props) {
  const { fileType, viewProps, url, data, loading, isPreviewAttachment } =
    props;
  // console.log("viewProps.file", viewProps.file, viewProps.files, url);
  const viewPropsInit = useHookstate({});
  const wrapperRef = useRef();
  const scaleItem = useHookstate(1);
  switch (fileType) {
    case "pdf":
    case "application/pdf":
    case "office":
      // console.log(url, loading)
      if (!url || loading) return <Loading />;
      if (url === "error") {
        return (
          // <div>
          <div className="pdf-fragment pdf-full-preview">
            <div className="pdf-preview-section">
              <div className="pdf-preview-panel">
                <div
                  className="react-pdf__Document"
                  style={{
                    height: "100%",
                    overflow: "auto",
                    transform: `scale(${scaleItem.get()})`,
                  }}
                >
                  <div className="pdf-page-panel active">
                    <div
                      ref={wrapperRef}
                      className="react-pdf__Page pdf-custom-page scale-0.6 active"
                      data-page-number="1"
                      style={{
                        position: "relative",
                      }}
                    >
                      {fileType === "image" && url !== "error" ? (
                        <img draggable={false} src={url} />
                      ) : url === "error" ? (
                        <div className="cant_preview">
                          <ErrorIcon />
                          Server error
                        </div>
                      ) : (
                        <div className="cant_preview">
                          <img
                            width="354.187"
                            height="334"
                            src="/icons/preview_off.svg"
                            alt="img"
                          />
                          このタイプのファイルはプレビューできません
                        </div>
                      )}

                      {/* <img draggable={false} src={url} /> */}
                      <div className="react-pdf__Page__annotations annotationLayer"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pdf-controller-panel">
                {fileType === 'excel' ? null : (
                  <div className="control-container">
                    <div
                      className="view-size-controller"
                      style={{
                        left: "50%",
                        transform: "translateX(-50%)",
                      }}
                    >
                      <div
                        className="btn size-decrease"
                        onClick={() =>
                          scaleItem.set((v) => (v >= 1.2 ? v - 0.2 : 1))
                        }
                      >
                        <FontAwesomeIcon icon={faMinus} />
                      </div>

                      <div
                        className="btn size-increase"
                        onClick={() => scaleItem.set((v) => v + 0.2)}
                      >
                        <FontAwesomeIcon icon={faPlus} />
                      </div>
                    </div>
                    <div className="page-index-info">
                      {/* <span className="info-label">1/2</span> */}
                    </div>
                    <div className="child-element">
                      <form className=""></form>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="pdf-info-section file-panel">
              <div className="pdf-properties active">
                <li>
                  <span className="label">ファイル名</span>
                  <ul>
                    <li className="value" name="fileNameField">
                      {data?.name}
                    </li>
                  </ul>
                </li>
                <li>
                  <span className="label">ファイルサイズ</span>
                  <ul>
                    <li className="value" name="fileNameField">
                      {formatBytes(data?.size)}
                    </li>
                  </ul>
                </li>
                <div className="separator"></div>
              </div>
            </div>
          </div>
          // </div>
        );
      }
      return (
        <PDFViewer
          {...{
            files: [],
            upload: false,
            showAll: true,
            scale: 0.3,
            fit: true,
            controller: true,
            info: true,
            full: true,
          }}
          file={{ ...viewProps.file, url }}
        />
      );
    case "zip":
      if (!url) return <Loading />;

      if (
        !data?.files ||
        (data?.files && Object.keys(data?.files).length === 0)
      ) {
        return (
          <div className="pdf-fragment pdf-full-preview">
            <div className="pdf-preview-section">
              <div className="pdf-preview-panel">
                <div
                  className="react-pdf__Document"
                  style={{
                    height: "100%",
                    overflow: "auto",
                    transform: `scale(${scaleItem.get()})`,
                  }}
                >
                  <div className="pdf-page-panel active">
                    <div
                      ref={wrapperRef}
                      className="react-pdf__Page pdf-custom-page scale-0.6 active"
                      data-page-number="1"
                      style={{
                        position: "relative",
                      }}
                    >
                      <div className="cant_preview">
                        <img
                          width="354.187"
                          height="334"
                          src="/icons/preview_off.svg"
                          alt="img"
                        />
                        このタイプのファイルはプレビューできません
                      </div>

                      {/* <img draggable={false} src={url} /> */}
                      <div className="react-pdf__Page__annotations annotationLayer"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pdf-controller-panel">
                <div className="control-container">
                  <div
                    className="view-size-controller"
                    style={{
                      left: "50%",
                      transform: "translateX(-50%)",
                    }}
                  >
                    <div
                      className="btn size-decrease"
                      onClick={() =>
                        scaleItem.set((v) => (v >= 1.2 ? v - 0.2 : 1))
                      }
                    >
                      <FontAwesomeIcon icon={faMinus} />
                    </div>

                    <div
                      className="btn size-increase"
                      onClick={() => scaleItem.set((v) => v + 0.2)}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </div>
                  </div>
                  <div className="page-index-info">
                    {/* <span className="info-label">1/2</span> */}
                  </div>
                  <div className="child-element">
                    <form className=""></form>
                  </div>
                </div>
              </div>
            </div>
            <div className="pdf-info-section file-panel">
              <div className="pdf-properties active">
                <li>
                  <span className="label">ファイル名</span>
                  <ul>
                    <li className="value" name="fileNameField">
                      {data?.name}
                    </li>
                  </ul>
                </li>
                <li>
                  <span className="label">ファイルサイズ</span>
                  <ul>
                    <li className="value" name="fileNameField">
                      {formatBytes(data?.size)}
                    </li>
                  </ul>
                </li>
                <div className="separator"></div>
              </div>
            </div>
          </div>
        );
      }

      return (
        <PDFViewer
          {...{
            files: data.files,
            filesSort: viewProps.files_sort,
            file: {},
            scale: 0.3,
            upload: false,
            showAll: true,
            fit: true,
            controller: true,
            info: false,
            list: true,
            full: true,
          }}
        />
      );
    default:
      return (
        // <div>
        <div className="pdf-fragment pdf-full-preview">
          <div className="pdf-preview-section">
            <div className="pdf-preview-panel">
              <div
                className="react-pdf__Document"
                style={{
                  height: "100%",
                  overflow: "auto",
                  "transform-origin": "0 0",
                  transform: `scale(${scaleItem.get()}, ${scaleItem.get()})`,
                }}
              >
                <div className="pdf-page-panel active">
                  <div
                    ref={wrapperRef}
                    className="react-pdf__Page pdf-custom-page scale-0.6 active"
                    data-page-number="1"
                    style={{
                      position: "relative",
                    }}
                  >
                    <MappedRenderByType
                      type={fileType}
                      url={url}
                      loading={loading}
                    />

                    {/* <img draggable={false} src={url} /> */}
                    <div className="react-pdf__Page__annotations annotationLayer"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pdf-controller-panel">
              <div className="control-container">
                <div
                  className="view-size-controller"
                  style={{
                    left: "50%",
                    transform: "translateX(-50%)",
                  }}
                >
                  <div
                    className="btn size-decrease"
                    onClick={() =>
                      scaleItem.set((v) => (v >= 1.2 ? v - 0.2 : 1))
                    }
                  >
                    <FontAwesomeIcon icon={faMinus} />
                  </div>

                  <div
                    className="btn size-increase"
                    onClick={() => scaleItem.set((v) => v + 0.2)}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </div>
                </div>
                <div className="page-index-info">
                  {/* <span className="info-label">1/2</span> */}
                </div>
                <div className="child-element">
                  <form className=""></form>
                </div>
              </div>
            </div>
          </div>
          {isPreviewAttachment ? null : (
            <div className="pdf-info-section file-panel">
              <div className="pdf-properties active">
                <li>
                  <span className="label">ファイル名</span>
                  <ul>
                    <li className="value" name="fileNameField">
                      {data?.name}
                    </li>
                  </ul>
                </li>
                <li>
                  <span className="label">ファイルサイズ</span>
                  <ul>
                    <li className="value" name="fileNameField">
                      {formatBytes(data?.size)}
                    </li>
                  </ul>
                </li>
                <div className="separator"></div>
              </div>
            </div>
          )}
        </div>
        // </div>
      );
  }
}
