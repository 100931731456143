// @ts-nocheck
import { useHookstate } from "@hookstate/core";
import { Tree } from "@minoru/react-dnd-treeview";
import { useRef, useState } from "react";
import { useDragLayer } from "react-dnd";
import { NativeTypes } from "react-dnd-html5-backend";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useParams } from "react-router";
import {
  getNewDetailAndSetState,
  uploadFileAction,
} from "../../data-access/actions";
import { getFoldersDetail, moveItem } from "../../data-access/api";
import {
  breabCrumbState,
  driveDetailLoading,
  driveFoldersDetail,
  driveFoldersNav,
  folderDetailSearch,
  isDraggingGlobalState,
  isOpenShareDialog,
  isTableDragging,
  scrollLoadingState,
  searchObjectFolder,
  selectedRowsNav,
} from "../../data-access/state";
import handleUploadOnDrop from "../../utils/handle-upload-drop";
import mappedDataToTreeView from "../../utils/mappedDataToTreeView";
import parseSearchQuery from "../../utils/parse-search-query";
import { progessCallback } from "../../utils/progess-callback";
import searchContentFolderUtils from "../../utils/search-content-folder";
import styles from "./drag-upload.module.css";
function handleFile(files) {
  // alert("Number of files: " + files.length);
}
// const supportsFileSystemAccessAPI =
//   "getAsFileSystemHandle" in DataTransferItem.prototype;
// const supportsWebkitGetAsEntry =
//   "webkitGetAsEntry" in DataTransferItem.prototype;

// drag drop file component
export default function DragDropFile(props) {
  const { t } = useTranslation("common");
  const searchObject = useHookstate(searchObjectFolder);
  const selectedNodes = useHookstate(selectedRowsNav);
  const isTableDrag = useHookstate(isTableDragging);
  const isGlobalDrag = useHookstate(isDraggingGlobalState);
  const openShared = useHookstate(isOpenShareDialog);
  const monitorProps = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    clientOffset: monitor.getClientOffset(),
    isDragging: monitor.isDragging(),
    // isDropped: monitor.
  }));
  const { folderId } = useParams();
  const navigate = useHistory();
  const { search } = useLocation();

  const { isDragging, clientOffset, item } = monitorProps;
  const isDragFile = useHookstate(true);
  const ref = useRef();
  // console.log(isDragging, clientOffset, item);
  // drag state

  const [dragActive, setDragActive] = useState(false);
  // ref
  const inputRef = useRef(null);
  if (
    isTableDrag.get() ||
    isGlobalDrag.get() ||
    openShared.get() ||
    searchObject.isSearching.get()
  ) {
    return <></>;
  }
  // handle drag events
  const handleDrag = function (e) {
    if (item?.dataTransfer) {
      isDragFile.set(true);
    } else {
      isDragFile.set(false);
    }
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
      // props.onDragLeave();
    }
  };

  // triggers when file is dropped
  const handleDrop = async function (e) {
    // console.log( driveFoldersNav
    //   .get({ stealth: true, noproxy: true }))
    // return
    if (
      driveFoldersNav
        .get({ stealth: true, noproxy: true })
        .find((a) => a.id == folderId) &&
      [1, 2, 3, 4, 5].includes(
        driveFoldersNav
          .get({ stealth: true, noproxy: true })
          .find((a) => a.id == folderId).type
      )
    ) {
      return alert(
        `${t(
          "common:drive.message.error.no_complete_upload",
          "Cannot upload to this folder!"
        )}`
      );
    }
    const parseQuery = parseSearchQuery(search);
    const completeFiles = [];
    const selected = selectedNodes.get({ stealth: true });
    const isSame = selected.some((a) => a.id == folderId);
    const isParent = selected.some((a) => a.parent == folderId);
    console.log(selected)
    // console.log(isSame, isParent);
    if ((isSame || isParent) && !item.dataTransfer) return;
    const moveOrCopyList = selected
      .reduce(
        (arr, item) => {
          if (item?.document_id) {
            arr.document_id_list.push(item.document_id);
          } else if (item?.format_type === "folder" || item?.extension === "folder") {
            arr.drive_folder_id_list.push(item.id);
          } else {
            arr.drive_file_id_list.push(item.id);
          }
          return arr;
        },
        {
          document_id_list: [],
          drive_file_id_list: [],
          drive_folder_id_list: [],
        }
      );
    // const moveOrCopyList = {
    //   document_id_list: [],
    //   drive_file_id_list: [],
    //   drive_folder_id_list: selected.map((a) => a.id),
    // };

    if (item.drive_folder_id) {
      moveItem(moveOrCopyList, folderId || null).then((r) => {
        if (!r) return;
        driveFoldersNav.set((v) => {
          const dest = v.find((a) => a.id == (folderId ? folderId : "drive"));
          const returnVal = v.map((a) => {
            if (moveOrCopyList.drive_folder_id_list.includes(a.id)) {
              const oldParent = v.findIndex((b) => b.id === a.parent_id);
              if (oldParent > -1) {
                v[oldParent].size = v[oldParent].size - 1;
                if (v.filter((c) => c.parent_id === a.parent_id)?.length < 2) {
                  v[oldParent]["notShowDropDownIcon"] = 1;
                  v[oldParent]["is_empty"] = 1;
                }
              }

              a.parent = dest.id ? dest.id : "drive";
              a.parent_id = dest.id ? dest.id : "drive";
              a.type = dest.type === 6 || dest.type === 9 ? 9 : 8;
            }
            // console.log(a.id, dest.id);
            if (a.id == dest.id) {
              a["notShowDropDownIcon"] = 0;
            }
            return a;
          });
          return returnVal;
        });

        if (
          !driveFoldersDetail.data[folderId ? folderId : "root"]?.get({
            stealth: true,
          })
        ) {
          driveFoldersDetail.data.merge({
            [folderId ? folderId : "root"]: [],
          });
        }
        if (searchObject.isSearching.get({ stealth: true })) {
          searchObjectFolder.offset_document_number.set(0);
          searchObjectFolder.offset_folder_number.set(0);
          searchObjectFolder.offset_file_number.set(0);
          searchObjectFolder.isStop.set(0);
          folderDetailSearch.offset_folder_number.set((v) => 0);
          folderDetailSearch.offset_file_number.set((v) => 0);
          searchContentFolderUtils(
            undefined,
            searchObjectFolder,
            folderId,
            scrollLoadingState,
            driveDetailLoading,
            breabCrumbState,
            false
          );
          return;
        }

        getNewDetailAndSetState(folderId, parseQuery?.type);
      });

      return;
    }
    // console.log(item.files);
    if (!Array.from(item.files).some((a) => !a.type && !a.webkitRelativePath)) {
      uploadFileAction(
        item.files,
        folderId,
        progessCallback,
        undefined,
        parseQuery?.type
      );
      return;
    }

    if (item?.dataTransfer) {
      handleUploadOnDrop(item, t, folderId, parseQuery?.type);
    }
    isDragFile.set(false);

    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    // console.log(e.dataTransfer);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFile(e.dataTransfer.files);
      props.onDrop();
    }
  };

  // triggers when file is selected with click
  const handleChange = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleFile(e.target.files);
    }
  };

  // triggers the input when the button is clicked
  const onButtonClick = () => {
    inputRef.current.click();
  };
  // console.log(item?.dataTransfer, isDragging);
  if (!!item?.dataTransfer || !isDragging) {
    return (
      <form
        className={
          !clientOffset || (item?.files?.length > 0 && !dragActive)
            ? "hide_drag_area treeRoot_drag_area"
            : "treeRoot_drag_area"
        }
        onDragLeave={handleDrag}
        onDragOver={(e) => {
          return handleDrag(e);
        }}
        onDragEnter={(e) => {
          return handleDrag(e);
        }}
        onDrop={(e) => {
          return handleDrop(e);
        }}
        onSubmit={(e) => e.preventDefault()}
      >
        <input
          ref={inputRef}
          type="file"
          className={styles["drag-file-element"]}
          id="input-file-upload"
          multiple={true}
          onChange={handleChange}
        />
        <label
          id="label-file-upload"
          htmlFor="input-file-upload"
          className={dragActive ? "drag-active" : ""}
        >
          {/* <div>
        <p>Drag and drop your file here or</p>
        <button className="upload-button" onClick={props.callbackOnlick}>
          Upload a file
        </button>
      </div> */}
        </label>
        {/* {dragActive && ( */}
        {/* {isDragFile.get() ? null : (
     
    )} */}

        {/* <div
          id={isDragFile.get() ? "drag-file-element" : ""}
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        ></div> */}
      </form>
    );
  }
  return (
    <Tree
      extraAcceptTypes={[NativeTypes.TEXT]}
      ref={ref}
      rootId={0}
      tree={[]}
      classes={{
        root: "treeRoot_drag_area2",
      }}
      onDrop={handleDrop}
      onDragStart={handleDrag}
      onDragEnd={handleDrag}
    />
  );
  // if (!isDragging) return <></>;
}
