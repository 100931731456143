// @ts-nocheck
import { useHookstate } from "@hookstate/core";
import { useCallback, useEffect, useRef, useState } from "react";
import { Document, pdfjs } from "react-pdf";
import { debounce } from "../../drive-feature/utils/hooks";
import Loading from "../Loading";

export default function DocumentWithObserver(props) {
  const {
    observerCallback,
    observerConfig,
    observerRef,
    currentRangePage,
    showLoading,
    isNeedViewConfirm,
    onShowViewConfirm,
    onLoadSuccess,
    fullPreview,
    fileHash,
    isLastPageRendered,
    ...otherProps
  } = props;
  const documentRef = useRef();
  // const [documentObserver, setDocumentObserver] = useState(null);
  const documentObserver = useHookstate(null);
  // const handleInput = useCallback(debounce(handleInteraction, 100), []);

  function handleInteraction(entries) {
    // console.log(documentObserver.get({stealth:true, noproxy:true}))
    if (documentObserver.get({ stealth: true, noproxy: true })) {
      documentObserver.get({ stealth: true, noproxy: true }).disconnect();
      // console.log(currentRangePage?.get(), entries);
      const obsPage =
        observerRef.current.querySelectorAll("[data-page-number]");
      // console.log(obsPage)
      for (let index = 0; index < obsPage.length; index++) {
        const element = obsPage[index];
        //   console.log(element);
        documentObserver.get({ stealth: true, noproxy: true }).observe(element);
      }
    }
    const currentRange = entries.reduce((arr, item) => {
      if (item.isIntersecting) {
        arr.push(+item.target.dataset.pageNumber);
      }
      // if(entries.length === 1 && currentRangePage?.get() === item.target.dataset.pageNumber && item.target.dataset.pageNumber !== '1' && !item.isIntersecting){
      //   arr.push(+item.target.dataset.pageNumber - 1);
      // }
      return arr;
    }, []);
    // console.log(observerRef.current);
    if (currentRange.length > 0) {
      if (currentRange.length > 7) {
        props?.setCurrentPage(
          currentRange[0]
          // entries[entries.length - 1]?.isIntersecting
        );
      } else {
        props?.setCurrentPage(
          currentRange[currentRange.length - 1]
          // entries[entries.length - 1]?.isIntersecting
        );
      }
    } else {
      // props.setCurrentPage(currentRangePage?.get() ? `${(+currentRangePage.get() - 1) || 1}`: '1')
    }
  }
  useEffect(() => {
    // console.log(documentObserver)
    if (observerRef.current && !documentObserver.value) {
      const observer = new IntersectionObserver(
        observerCallback ? observerCallback : handleInteraction,
        observerConfig || {
          root: observerRef.current,
          // threshold: [0.2, 0.4, 0.6, 0.8, 1]
        }
      );
      // console.log(observer)
      documentObserver.set(observer);
    }
  }, [observerRef, observerCallback, observerConfig, props, documentObserver]);
  useEffect(() => {
    // console.log(documentObserver)
    if (
      !observerRef.current ||
      !documentObserver.value ||
      !("IntersectionObserver" in window)
    ) {
      return undefined;
    }
    const obsPage = observerRef.current.querySelectorAll("[data-page-number]");
    // console.log(obsPage);
    for (let index = 0; index < obsPage.length; index++) {
      const element = obsPage[index];
      //   console.log(element);
      documentObserver.value.observe(element);
    }
    // console.log(observerRef.current.querySelectorAll('[data-page-number]'))
    // console.log(options, page, options)
    // return
    // console.log(observerRef);

    // documentObserver.observe(observerRef.current);

    return () => {
      documentObserver.value.disconnect();
      // documentObserver.set(null);
    };
  }, [documentObserver, observerRef, props]);

  useEffect(() => {
    const handleScroll = () => {
      const { current } = observerRef;
      if (!current) return;

      if (current.scrollTop > 0) {
        const isAtBottom =  Math.ceil(current.scrollHeight - current.scrollTop) - current.clientHeight <= 1;
        if (isAtBottom) {
          console.log(Math.ceil(current.scrollHeight - current.scrollTop))
          // User has scrolled to the end of the PDF file
          onShowViewConfirm?.(true)
          // Implement any action here
        }
      }
      
    };

    const currentViewer = observerRef.current;
    currentViewer.addEventListener('scroll', handleScroll);

    const handleResize = () => {
      const { current } = observerRef;
      if (!observerRef || !current || !isLastPageRendered[fileHash]) return;

      if (current.scrollHeight <= current.clientHeight) {
        onShowViewConfirm?.(true)
      }
    };

    const resizeObserver = new ResizeObserver(handleResize);
    resizeObserver.observe(currentViewer);

    return () => {
      currentViewer.removeEventListener('scroll', handleScroll);
      resizeObserver.unobserve(currentViewer);
    };
  }, [isLastPageRendered, fileHash]);

  return (
    <>
      {showLoading ? (
        <div className="showLoading">
          <Loading />
        </div>
      ) : null}
      <Document
        ref={documentRef}
        file={props.file}
        onLoadSuccess={props.onLoadSuccess}
        onLoadError={props.onLoadError}
        onPassword={props.onPassword}
        options={props.options}
        loading={props?.loading}
        noData={props.noData}
        {...otherProps}
      >
        {props.children}
      </Document>
    </>
  );
}
