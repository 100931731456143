// @ts-nocheck
import { LoadingButton } from "@mui/lab";
import { Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import BaseDialogDriveCrud from "./drive-base-dialog";

export default function DriveConfirmDialog({
  message,
  title,
  onClose,
  open,
  loading,
  closeOnly,
}) {
  const { t } = useTranslation();
  return (
    <BaseDialogDriveCrud
      handleClose={onClose}
      open={open}
      title={title}
      dialogContentComponent={
        <Typography variant="body1">{message}</Typography>
      }
      dialogActionComponent={
        <>
          <Button variant="outlined" onClick={onClose}>
            {t("common:drive.button.close")}
            {/* 閉じる */}
          </Button>
          {!closeOnly ? (
            <LoadingButton
              loading={loading}
              variant="contained"
              onClick={(e) => onClose(e, null, true)}
            >
              {t("common:drive.button.delete_item")}
              {/* フォルダ作成 */}
            </LoadingButton>
          ) : null}
        </>
      }
    />
  );
}
