import Main from "./Main";
import { withTranslation } from "react-i18next";

import { Dropdown, ButtonGroup } from 'react-bootstrap';
import { Link } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import DownloadIcon from "@mui/icons-material/Download";
import IconButton from "@mui/material/IconButton";
import RefreshIcon from "@mui/icons-material/Refresh";
import CircularProgress from "@mui/material/CircularProgress";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import AddIcon from '@mui/icons-material/Add';
class Circle extends Main {

	constructor(props) {
		super(props);
		this.state.page = "Circle";
		this.state.code = "circle";
		this.state.function_type = 3;
		this.state.searchOption.function_type = 3;
	}

	AdditionalButtons = () => {
		let { t } = this.props;
		let { code, loadingDownload } = this.state;
		let ButtonList = [];

		ButtonList.push(
			<Button
				key="document-create"
				sx={{ ml: 1, borderRadius: 19 }}
				variant="contained"
				startIcon={<AddIcon />}
				className="as-link"
				component={Link}
				to={`/${code}/documents/create`}
			>
				{t('common:main.general.create')}
			</Button>
		);

		ButtonList.push(
			<Tooltip key={0} title={t("common:tooltip.common.download-csv")} arrow>
				<Button
					sx={{ mr: 2, ml: 2 }}
					key="list-download"
					variant="outlined"
					size="small"
					startIcon={loadingDownload ? <CircularProgress size={16}/> : <DownloadIcon />}
					
					onClick={loadingDownload ? undefined : this.onListDownloadRequestHandler}
				>
					{/* {"CSVダウンロード"} */}
					{t("common:main.general.list-download")}
				</Button>
			</Tooltip>
		);

		return ButtonList;
	};

	OperationButtons = () => {
		let { t } = this.props;
		let { searchOption, documents, isSelectedAll, selected_documents } = this.state;
		let ButtonList = [];

		ButtonList.push(
			<IconButton
				className="btn-custom"
				color="primary"
				onClick={() => {
					this.handleSelectAll(!isSelectedAll)
				}}
			>
				{isSelectedAll ? <CheckBoxOutlinedIcon /> : <CheckBoxOutlineBlankIcon />}
			</IconButton>
		);

		if (selected_documents.length === 0) {
			ButtonList.push(
				<IconButton
					key="list-reload"
					color="primary"
					disabled={documents ? false : true}
					onClick={(ev) => {
						this.GetDocumentListData({ ...searchOption, function_type: 3 }, true);
					}}
				>
					{documents ? <RefreshIcon /> : <CircularProgress size="1em" />}
				</IconButton>
			);
		} else {
			ButtonList.push(
				<Button
					key="btn-bulk-verify"
					//startIcon={<AddIcon />}
					variant="outlined"
					size="small"
					startIcon={<AccessTimeIcon />}
					onClick={this.BulkSignDocumentConfirmation} >
					{t('common:document.batch.signatures')}
				</Button>
			)
		}

		return ButtonList;
	};
}

export default withTranslation()(Circle);
