// @ts-nocheck

import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import { useHookstate } from "@hookstate/core";
import { useEffect } from "react";
import { Chip, Tooltip } from "@mui/material";
import KeyOff from "@mui/icons-material/KeyOff";

export const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    opacity: "1 !important",
    background: "gray",
    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 16,
    height: 16,
    color: "white",
    margin: 2,
  },
}));

export function SwitchCustomize({
  active,
  onChange,
  disabled,
  setVal,
  name,
  tooltip,
  isWaiting,
}) {
  const isActive = useHookstate(active || false);
  // if (active === 1) {
  //   isActive.set(true);
  // }
  useEffect(() => {
    isActive.set(active);
    // console.log(setVal, active)
    setVal(name, active);
  }, [active]);

  return isWaiting ? (
    <Tooltip disableInteractive title={tooltip}>
      <Chip
        size="small"
        color="warning"
        icon={<KeyOff color="warning" />}
        label={tooltip}
        variant="outlined"
        style={{
          width: 'fit-content',
          padding: '5px',
          fontWeight: 500,
        }}
      />
    </Tooltip>
  ) : (
    <Android12Switch
      color="secondary"
      disabled={disabled}
      checked={isActive.get()}
      onChange={
        onChange
          ? (e, checked) => {
              isActive.set(checked);
              return onChange(e, checked);
            }
          : undefined
      }
    />
  );
}

export default SwitchCustomize;
