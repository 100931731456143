import '../../Assets/css/settings.css';
import React from 'react';
import Layout from '../../../Layout';
// import Menu from '../Menu';
import Loading from '../../Loading';
import Service from '../../../Service';
import { withTranslation } from "react-i18next";
import { Button, Form, Col } from 'react-bootstrap';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import {
// 	faPlus,
// 	faTrash,
// 	faSignature,
// 	faFileSignature,
// 	faUsers,
// 	faCircle,
// 	faChevronRight,
// 	faChevronLeft,
// 	faEdit,
// 	faDownload,
// 	faQuestion,
// 	faShareSquare,
// 	faExclamationCircle,
// 	faInfoCircle,
// 	faCheckCircle,
// 	faMinusCircle,
// 	faTimesCircle,
// 	faStopCircle,
// } from '@fortawesome/free-solid-svg-icons';
// import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';


/** ApplicationCertificate */
class AppCertUpload extends Layout {

	// static get name() {
	// 	return "UserForm";
	// }

	/** Constructor */
	constructor(props) {

		/* Props */
		super(props);

		/* */
		this.state.create = {
			"users": [],
		};
		this.state.datalist = null;
		this.state.certlist = null;
		this.state.identitylist = null;
		this.state.applicationList = null;
		this.state.applicationDate = null;
		this.state.keyFields = {
			user_id: "user-id",
			status_name: "status-name",
			status: "status",
			application_date: "date",
			application_certificate_type: "certificate",
			tax_id: "tax-id",
			company_name: "company-name",
			company_name_romaji: "company-name-roma",
			name: "full-name",
			name_kana: "full-name-kana",
			name_romaji: "full-name-roma",
			branch_and_department: "branch-department",
			email: "email",
			credentials: "qualification",
			identity_verification_documents: "personal-document-type",
			file_name: "file_name",
			upload: "upload_path",
			company_id: "company_id",
			tenant_id: "tenant_id",
		};
		this.state.condition = {
			"application_date": props.match.params.application_date,
		};
		this.state.token = props.match.params.token;
	}

	componentDidMount() {

		let { condition, token } = this.state;
		if (condition.application_date) {
			this.getDetailData(condition);
			this.getDropdownData();
		} else if (token) {
			this.getGuestDetailData(token);
		}

	}

	getDetailData = (data) => {

		/* */
		Service.getAppCertDetail(data, true).then((response) => {

			/* */
			let { applicationList, applicationDate } = this.state;

			/* */
			applicationList = response.records;
			applicationDate = data.application_date;

			/* */
			this.setState({ applicationList, applicationDate });

			this.setStateFields();
		}).catch((error) => {
			//console.log(error.response);
		});

	}

	getGuestDetailData = (token) => {

		/* */
		Service.getGuestAppCertDetail(token).then((response) => {

			/* */
			let { applicationList, certlist, identitylist } = this.state;

			/* */
			applicationList = response.records;
			certlist = response.cert_type;
			identitylist = response.identity_doc_type;

			// console.log(response)
			/* */
			this.setState({ applicationList, certlist, identitylist }, this.setStateFields);

			// this.setStateFields();
		}).catch((error) => {
			//console.log(error.response);
		});

	}

	getDropdownData = () => {

		/* */
		let data = {
			"search": "",
		}

		/* */
		Service.getAppCertUserList(data).then((response) => {

			/* */
			let { certlist, identitylist } = this.state;

			/* */
			certlist = response.cert_type;
			identitylist = response.identity_doc_type;

			/* */
			this.setState({ certlist, identitylist });

		}).catch((error) => {
			//console.log(error.response);
		});
	}

	BodyWrapper = (props) => {
		// let { t } = this.props;
		/* */
		let { certlist } = this.state;
		let { DetailFormDialogUploadTable } = this;

		if (certlist === null) {
			return <Loading />;
		}

		return (
			<div id="settings-app-cert" className="appcert-upload">

				{/* <input type="file" id="upload" accept="image/*" /> */}

				{/*  */}
				<div className="lz-m-10">

					<div className="col-xl-12">
						<fieldset>
							<DetailFormDialogUploadTable />
						</fieldset>
					</div>

				</div>
			</div>
		);
	}

	Nav = () => {
		return null;
	}

	render() {
		let { docInfo, token } = this.state;
		// console.log(docInfo, token);

		let { Main, BodyWrapper } = this;

		return (
			<Main>
				<BodyWrapper docInfo={docInfo} token={token} />
			</Main>
		);

	}

	setStateFields = (cb) => {
		let { t } = this.props;
		let { keyFields, fields, formData, applicationList } = this.state;

		for (let i = 0; i < applicationList.length; i++) {
			let user = applicationList[i];

			for (let key in keyFields) {
				let label = keyFields[key];
				fields[key] = {
					name: key,
					value: user[key],
					label: t(`common:settings.appcert.application-${label}`),
				};

				if (key === "status_name") {
					formData[key] = t(`common:${user[key]}`);
				} else {
					formData[key] = user[key];
				}
			}
		}
		// console.log(fields);

		this.setState({ fields }, cb);
	}

	CertTypeItems = () => {
		// let { t } = this.props;
		let { certlist } = this.state;
		let ItemElements = [];

		for (let i in certlist) {
			let cert_type = certlist[i];
			ItemElements.push(<option key={cert_type.id} value={cert_type.id}>{cert_type.name}</option>);
		}

		return ItemElements;
	}

	IdentityTypeItems = () => {
		let { t } = this.props;
		let { identitylist } = this.state;
		let ItemElements = [];

		for (let i in identitylist) {
			let identity_type = identitylist[i];
			ItemElements.push(<option key={identity_type.id} value={identity_type.id}>{t("common:" + identity_type.label)}</option>);
		}

		return ItemElements;
	}

	setValidity = (form, item_name, fieldValidate, message) => {
		form[item_name].setCustomValidity(message);
		fieldValidate[item_name] = message;
	}

	additionalValidity = (form) => {
		// console.dir(form);
		let { fieldValidate } = this.state;

		// Required check
		if (form['application_date']['value'] === '') {
			this.setValidity(form, 'application_date', fieldValidate, 'message.input.required');
		}

		if (form['company_name_romaji']['value'] === '') {
			this.setValidity(form, 'company_name_romaji', fieldValidate, "message.input.required");
		}

		if (form['name']['value'] === '') {
			this.setValidity(form, 'name', fieldValidate, "message.input.required");
		}

		if (form['name_kana']['value'] === '') {
			this.setValidity(form, 'name_kana', fieldValidate, "message.input.required");
		}

		if (form['name_romaji']['value'] === '') {
			this.setValidity(form, 'name_romaji', fieldValidate, "message.input.required");
		}

		if (form['branch_and_department']['value'] === '') {
			this.setValidity(form, 'branch_and_department', fieldValidate, "message.input.required");
		}

		if (form['company_name_romaji']['value'].match(/^[a-zA-Z0-9-+.,_()/:@\x20]*$/g) === null) {
			this.setValidity(form, 'company_name_romaji', fieldValidate, "message.errors.input.validate.wrong-policy");
		}

		if (form['name_romaji']['value'].match(/^[a-zA-Z0-9-+.,_()/:@\x20]*$/g) === null) {
			this.setValidity(form, 'name_romaji', fieldValidate, "message.errors.input.validate.wrong-policy");
		}

		if (form['credentials']['value'] !== '' && form['credentials']['value'].match(/^[a-zA-Z0-9-+.,_()/:@\x20]*$/g) === null) {
			this.setValidity(form, 'credentials', fieldValidate, "message.errors.input.validate.wrong-policy");
		}

		this.setState({ fieldValidate });

		if (Object.keys(fieldValidate).length > 0) {
			return false;
		} else {
			return true;
		}
	}

	formSubmitCallback = (ev) => {
		// let { t } = this.props;

		const form = ev.target;

		if (this.additionalValidity(form) === false) {
			ev.stopPropagation();
		} else {
			this.AppCertUpdate(form);
		}
	}

	formPrepareBeforeSubmit = (ev) => {
		let form = ev.target;
        // console.log(form);


		form['application_date'].setCustomValidity("");
		form['company_name_romaji'].setCustomValidity("");
		form['name'].setCustomValidity("");
		form['name_kana'].setCustomValidity("");
		form['name_romaji'].setCustomValidity("");
		form['branch_and_department'].setCustomValidity("");
		form['credentials'].setCustomValidity("");

		this.formSubmitHandler(ev, null, this.formSubmitCallback)
	}

	/** [Element] */
	DetailFormDialogUploadTable = () => {

		/* Translate function */
		let { t } = this.props;
		let { FieldControl } = this;

		let certTypeItems = this.CertTypeItems();
		let identityTypeItems = this.IdentityTypeItems();
		/* */
		let { formData } = this.state;
		/* */
		let detail = (
			<Form onSubmit={(ev) => { this.formPrepareBeforeSubmit(ev) }}>
				<Form.Row>
					<FieldControl name={`name`} xs={12} md={4} readOnly className="readonly"/>
					<FieldControl name={`email`} xs={12} md={4} readOnly className="readonly" />
				</Form.Row>
				<Form.Row>
					<FieldControl as="select" name={`application_certificate_type`} xs={12} md={4}>
						{certTypeItems}
					</FieldControl>
				</Form.Row>
				<Form.Row>
					<FieldControl name={`company_name_romaji`} xs={12} md={4} validate="true" placeholder={'paperlogic Co.,Ltd.'} />
          <div className="col-md-8 appcert-caption">{t("common:appcert.caption.company_name_romaji")}<br />{t("common:appcert.caption.available_characters")}</div>
				</Form.Row>
				<Form.Row>
					<FieldControl name={`name_romaji`} xs={12} md={4} validate="true" placeholder={'John Smith'} />
          <div className="col-md-8 appcert-caption">{t("common:appcert.caption.name_romaji")}<br />{t("common:appcert.caption.available_characters")}</div>
				</Form.Row>
				<Form.Row>
					<FieldControl name={`credentials`} xs={12} md={4} validate="true" placeholder={'CPA'} />
          <div className="col-md-8 appcert-caption">{t("common:appcert.caption.credentials")}<br />{t("common:appcert.caption.available_characters")}</div>
				</Form.Row>
				<Form.Row>
					<FieldControl as="select" name={`identity_verification_documents`} xs={12} md={4}>
						{identityTypeItems}
					</FieldControl>
          <div className="col-md-8 appcert-caption">{t("common:appcert.caption.identity_verification_documents")}</div>
				</Form.Row>
				<Form.Row>
				<Form.Group as={Col} md={4} controlId={`file_name`} >
					<Form.Label>{t("common:settings.appcert.application-personal-document-upload")}</Form.Label>
					<Form.File
						id={`file_name`}
						name={`file_name`}
						label={formData['file_name'] || t("common:settings.appcert.application-personal-document-upload")}
            data-browse={'参照'}
						custom
            className={`appcert-upload-file`}
						onChange={(ev) => {
							this.uploadFile(formData, ev)
						}}
						accept=".jpg,.png,.pdf,.zip"
					/>
				</Form.Group>
          <div className="col-md-8 appcert-caption appcert-uoload">{t("common:appcert.caption.host-file-upload1")}<br />{t("common:appcert.caption.file-upload2")}</div>
				</Form.Row>

				<div className="page-action-bottom-relative">
					<div className="wrapper-button-submit">
						<Button key="ok" type="submit" variant="primary">
							<span className="btn-label">{t("common:settings.appcert.upload.regist")}</span>
						</Button>
					</div>
				</div>
			</Form>
		);


		/* */
		return (
        <div className="appcert upload">
          <div id="appcert-form" className="">
            {detail}
          </div>
        </div>
		);

	}

	/** */
	uploadFile = (formData, ev) => {

		let self = this;

		let files = ev.target.files;

		if (files.length < 1) {
			return false;
		}

		formData['file_name'] = files[0].name;
		self.setState({ formData });
	}

	/* */
	AppCertUpdate = (form) => {

		/* */
		/* Prepare Modal */
		let { modal, datalist, condition, token } = this.state;

		/* Show Modal */
		modal.props = {
			"show": true,
			"dialogClassName": "loading-dialog",
		}

		/* */
		modal.body = Loading;

		/* */
		this.setState({ modal });

		/* */
		let insert_data = { "update_data": [] };
		/* */
		let reader = new FileReader();

		insert_data["update_data"].push({
			"company_id": form['company_id']['value'],
			"tenant_id": form['tenant_id']['value'],
			"application_date": form['application_date']['value'],
			"user_id": form['user_id']['value'],
			"application_certificate_type": form['application_certificate_type']['value'],
			"status": form['status']['value'],
			"company_name": form['company_name']['value'],
			"company_name_romaji": form['company_name_romaji']['value'],
			"tax_id": form['tax_id']['value'],
			"name": form['name']['value'],
			"name_kana": form['name_kana']['value'],
			"name_romaji": form['name_romaji']['value'],
			"branch_and_department": form['branch_and_department']['value'],
			"email": form['email']['value'],
			"credentials": form['credentials']['value'],
			"identity_verification_documents": form['identity_verification_documents']['value'],
		});

		new Promise((resolve, reject) => {
			if (form.elements['file_name'].files.length > 0) {

				insert_data["update_data"][0]["file_name"] = form.elements['file_name'].files[0].name;

				/* */
				reader.onload = (event) => {
					insert_data["update_data"][0]["file_image"] = (event.target.result).split(',')[1];
					resolve(insert_data);
				};

				/* */
				reader.readAsDataURL(form.elements['file_name'].files[0]);
			} else {
				resolve(insert_data);
			}

		}).then((result) => {
			if (condition.application_date) {

				let send_mail_user = {
					// "lang": "ja",
					"application_date": form['application_certificate_type']['value'],
					"host_id": form['user_id']['value'],
				};

				/* */
				Service.UpdateAppCert(result).then((response) => {
					/* */
					Service.appcertEmail(send_mail_user).then((response) => {
					}).catch((error) => {
						/* */
						//console.log("error", error);

					});

					/* */
					datalist = null;
					/* */
					modal.props = { "show": false };
					/* */
					this.setState({ modal, datalist });

				}).catch((error) => {
					/* */
					//console.log("error", error);

				});
			} else if (token) {
				/* */
				Service.GuestUpdateAppCert(token, result).then((response) => {
					/* */
					datalist = null;
					/* */
					modal.props = { "show": false };
					/* */
					this.setState({ modal, datalist });

				}).catch((error) => {
					/* */
					//console.log("error", error);

				});
			}
		});

	}

}

export default withTranslation()(AppCertUpload);
