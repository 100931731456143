// @ts-nocheck
import { hookstate, useHookstate } from "@hookstate/core";
import {
  CircularProgress,
  ListItemText,
  MenuItem,
  MenuList,
} from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
export const noDataState = hookstate({
  received_date: {},
  amount: {},
  counter_party_name: {},
});
export default function OcrFields({ data, onClickCb, type, fileKey, objMain }) {
  // console.log("data", data);
  const { t } = useTranslation("common");
  const showNoData = useHookstate(noDataState);
  // useEffect(() => {
  //   if (type) {
  //     setTimeout(() => {
  //       showNoData[type].set(true);
  //     }, 10000);
  //   }
  // }, [type]);
  if (!data || Object.keys(data || {}).length === 0)
    return showNoData[type] &&
      fileKey &&
      showNoData[type].get({ noproxy: true }) &&
      showNoData[type].get({ noproxy: true })[fileKey] ? (
      <>{t("common:general.no_data")}</>
    ) : (
      <CircularProgress />
    );
  return (
    <div className="business_info_data_wrapper">
      <MenuList>
        {data.map((a, i) => (
          <MenuItem
            className={"menu_list_ocr"}
            key={i}
            onClick={
              onClickCb ? (e) => {
                e.stopPropagation();
                return onClickCb(a, i, type, objMain);
              } : undefined
            }
          >
            <ListItemText>{a}</ListItemText>
          </MenuItem>
        ))}
      </MenuList>
    </div>
  );
}
