// @ts-nocheck
export default function formatDateIntlApi(date, options) {
    const dateOptions = options || {
        timeZone: "UTC",
        month: "numeric",
        day: "numeric",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hourCycle: "h23",
    };
    let dateNew = new Date(date);
    const dateFormatter = new Intl.DateTimeFormat("en-US", dateOptions);
    const pStart = dateFormatter
        .formatToParts(dateNew)
        .reduce((acc, part) => {
            acc[part.type] = part.value;
            return acc;
        }, {});
    return pStart
    // let accessDateEnd = `${pStart.month}/${pStart.day}   ${pStart.hour}:${pStart.minute}`;
}