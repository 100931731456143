// @ts-nocheck
import {withTranslation} from 'react-i18next';
import Layout from '../../Layout';
import Button from '@mui/material/Button';
import "../Assets/css/confirm-guest.css";
import Loading from "../Loading";
import Service from "../../Service";
import Error from "../Error";
import { Box, MenuItem, Select } from '@mui/material';
import { userInfoState } from '../../drive-feature/data-access/state';
import { hookstate, useHookstate } from '@hookstate/core';
import { useEffect } from 'react';

class GuestCollaboration extends Layout {

    constructor(props) {
        super(props)
        this.state.token = props.match.params.token;
        this.state.approver = null;
        this.state.requester = null;
        this.state.guest = null;
        this.state.loading = true;
        this.state.approveStatus = null;
        this.state.currentTenant = null;
    }

    NavLeft = () => {
        const { LogoImage } = this;

        return (
            <div className="logo-horizontal" style={{ width: "200px" }}>
                <LogoImage />
            </div>
        );
    }

    componentDidMount() {
        super.componentDidMount();

        Service.GuestCollaborationInfo(this.state.token)
            .then((result) => {
                const {payload, ok} = result;

                if (ok) {
                    let currentTenant = null;
                    const {collaboration_user, guest, requester, collaboration_status } = payload;
                    const tenants = payload?.tenants?.filter((v) => v.tenant_id !== requester.tenant_id)
                    const tenantName = tenants?.filter(tenant => tenant.tenant_id === guest.collaboration_tenant_id)[0]?.tenant_name
                    if(tenantName){
                        currentTenant = {
                            tenant_id: guest.collaboration_tenant_id,
                            tenant_name: tenantName
                        }
                    }
                     
                    this.setState({collaborationUser: collaboration_user, requester, guest, loading: false, approveStatus: collaboration_status, currentTenant, tenants});
                }
            })
            .catch((error) => {
                this.setState({ loading: false, code: error.response.status});
                
                console.log(error)
            });
    }

    handleApproveReject = (approve) => {
        let {t} = this.props;
        const {token, currentTenant} = this.state;
        let data = {
            approve: approve,
            tenant_id: currentTenant.tenant_id,
            token: token,
        };

        Service.GuestRegistrationCollabReject(data).then((resp) => {
            let { modal } = this.state;

            modal.body = approve ? t('common:settings.guest.approve-collaboration-success') : t('common:settings.guest.reject-collaboration-success');

            if (resp.ok) {
                this.setState({approveStatus: resp.payload.collaboration_status})
            }

            this.setState({
                modal,
                approveStatus: approve
            });
        }).catch((err) => {
            let { modal } = this.state;
            modal.body = approve ? t('common:settings.guest.approve-collaboration-fail') : t('common:settings.guest.reject-collaboration-fail');
            if (err.response.data.payload.hasOwnProperty("error")) {
                modal.body = err.response.data.payload.error;
            } else if (err.response.data.payload.hasOwnProperty("message")) {
                modal.body = err.response.data.payload.message;
            }
            modal.action = [
                <Button key="ok" variant="contained" onClick={(ev) => {
                    modal.body = Loading;
                    modal.action = [];
                    this.setState({ modal }, () => {
                        this.handleApproveReject(approve);
                    });
                }}>
                    <span>{t("common:general.try-again")}</span>
                </Button>
            ];

            this.setState({
                modal,
                approveStatus: null
            });
        });
    }

    handleOpenModal = (approve) => {
        let {t} = this.props;
        let { modal, formData } = this.state;

        modal.title = "common:settings.guest.guest-collaboration-approve";
        modal.body = Loading;
        modal.action = [];

        modal.props.show = true;
        modal.props.size = "xl";
        modal.props.className = "layout modal-responsive";

        modal.props.centered = true;

        this.setState({ modal, formData }, () => {
            this.handleApproveReject(approve)
        });
    };

    Main = (props) => {
        let { loading } = this.state;
        const { Nav, Body, MainLoading } = this;

        if (loading) {
            return <MainLoading />;
        }

        return (
            <div className="main contrainer-wrapper">
                <div className="layout no-menu">

                    <div className="layout-right">

                        <Nav />

                        <Body {...props} />

                        <div className="right-bottom">
                            <span className="licensce-info">© {new Date().getFullYear()} paperlogic co., ltd.</span>
                        </div>
                    </div>
                </div>
            </div >
        );
    }

    TenantSelect = ({ userInfo }) => {
        if (!userInfo) {
          return null;
        }
        let { currentTenant, tenants } = this.state;
        if(!currentTenant){
            currentTenant = {
                tenant_id: userInfo.tenant_id,
                tenant_name:  userInfo.tenant_name
            }
            
            if (tenants.length === 1) {  
                currentTenant = {
                    tenant_id: tenants[0]?.tenant_id,
                    tenant_name:  tenants[0]?.tenant_name
                }
            }
            this.updateState({ currentTenant });
        }

        if (tenants.length === 1) {
          return (
            <div className="icon tenant-switch">
              <span>{tenants[0].tenant_name}</span>
            </div>
          );
        }

        // if (tenants.length === 1) {
        //   return (
        //     <div className="icon tenant-switch">
        //       <span>{tenants[0].tenant_name}</span>
        //     </div>
        //   );
        // }
      
        let TenantList = [];
        for (let tenant of tenants) {
          // console.log(tenant);
          TenantList.push(
            <MenuItem value={tenant.tenant_id} key={tenant.tenant_id}>
              {tenant.tenant_name}
            </MenuItem>
          );
        }
      
        return (
          <div className="tenant-select">
            <Box py={1} px={2}>
              <Select
                value={currentTenant.tenant_id}
                style={{ color: "#007BFF" }}
                onChange={(ev) => {
                    const tenantName = tenants.filter(tenant => tenant.tenant_id === ev.target.value)[0].tenant_name;
                    this.updateState({currentTenant: {
                        tenant_id: ev.target.value,
                        tenant_name: tenantName
                    }});
                }}
                renderValue={() => currentTenant.tenant_name}
                variant={"standard"}
              >
                {TenantList}
              </Select>
            </Box>
          </div>
        );
    }

    ConfirmBody = () => {
        let { t } = this.props;
        const { TenantSelect } = this;
        const {guest, requester, approveStatus, currentTenant} = this.state;
        const userInfo = useHookstate(userInfoState);
        return (
            <div className="confirm-guest container-wrapper">
                    <div className="container-block">
                        <div className="form-wrapper">
                            <div className={"container-info"}>
                                <p className={"title"}>{t("common:settings.guest.please-approve-collaboration")}</p>

                                <p className={"subtitle"}>{requester?.family_name} {requester?.first_name} {t("common:settings.guest.have-sent-approval-collaboration-request")}</p>

                                <p className={"guest-info"}><p className={"item-label"}>{t('common:settings.guest.guest-name')}</p> {guest?.family_name} {guest?.first_name} </p>
                                <p className={"guest-info"}><p className={"item-label"}>{t('common:settings.guest.company_name')}</p> {guest?.additional_info?.company_name}  </p>
                                <p className={"guest-info"}><p className={"item-label"}>{t('common:settings.guest.email')}</p> {guest?.email}</p>
                                {approveStatus === null ? (
                                    <p className={"guest-info"}><p className={"item-label"}>{t('common:settings.guest.tenant')}</p> <TenantSelect userInfo={userInfo.get({ noproxy: true })} /></p>
                                ) :(
                                    <>
                                        {approveStatus ? (
                                            <p className={"guest-info"}><p className={"item-label"}>{t('common:settings.guest.tenant')}</p> {currentTenant?.tenant_name}</p>
                                        ) : null}
                                    </>
                                )}
                                
                            </div>

                            {approveStatus === null ? (
                                <div className={"container-button"}>
                                    <Button onClick={() => this.handleOpenModal(false)} className={"button button-reject"}>{t('common:settings.guest.reject')} </Button>
                                    <Button onClick={() => this.handleOpenModal(true)} className={"button button-approve"}>{t('common:settings.guest.approve')} </Button>
                                </div>
                            ) : (
                                <div>
                                    <p>{approveStatus ? t('common:settings.guest.approve-success') : t('common:settings.guest.reject-success')}</p>
                                </div>
                            )}


                        </div>
                    </div>
            </div >
        );
    }

    render() {
        let { Main, ConfirmBody } = this;
        const {GeneralModal, FormModal} = this;

        const {loading, collaborationUser, code} = this.state;

        if (loading) {
            return <Loading className="full-screen" />;
        }
        console.log('code', code);
        if (code === 401) {
            return <Error code={'tenant_access_denied'} msg={""} layout={true} />;
        }
        if (!collaborationUser) {
            return <Error code={404} msg={""} layout={false} />;
        }
        

        return (
            <Main>
                <div className="page-error">
                    <ConfirmBody />
                </div>

                <GeneralModal/>

                <FormModal/>
            </Main>
        );
    }
}

export default withTranslation()(GuestCollaboration);

