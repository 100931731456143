import LoadingImage from './Assets/img/loading.gif'

export default function Loading(props) {
	// console.log(props);
	let newProps = {};
	if (props && props.hasOwnProperty("className")) {
		newProps.className = `${props.className} loading`;
	} else {
		newProps.className = "loading";
	}
	return (
		<div {...newProps}>
			<div className="img-container">
				<img src={LoadingImage} alt="loading" />
			</div>
		</div>
	);
}
