// @ts-nocheck
import { hookstate } from "@hookstate/core";
import { Auth } from "aws-amplify";
import Service from "../../Service";
import mappedDataToTreeView from "../utils/mappedDataToTreeView";
import { getFoldersTree } from "./api";
import { getCompanyFolders } from "./company-folders/company-folders.api";
export const folderDetail = hookstate(null);
export const filesFolder = hookstate([]);
export const shedOnScroll = hookstate(false);
export const scrollHeightOnScroll = hookstate(null);
export const driveFoldersNav = hookstate([]);
Auth.currentAuthenticatedUser({
  bypassCache: false,
}).then((r) => {
  driveLoading.set(true);
  const userCache = localStorage.getItem("user-info");
  let user;
  if (userCache && userCache !== "undefined" && userCache !== "null") {
    user = JSON.parse(userCache || null);
  }
  if (user?.filebox_role == "1") {
    Service.GetPageData("signer");
    getFoldersTree({
      page_number: 1,
      page_size: 50,
      parent_id: "null",
    }).then((r) => {
      // console.log();
      if (r?.data?.payload?.length > 0) {
        const data = r.data.payload.map(mappedDataToTreeView);
        const completeFolder = data.find((a) => a.type === 5);
        const companyFolder = data.find((a) => a.type === 6);
        if (completeFolder) {
          getFoldersTree({
            page_number: 1,
            page_size: 50,
            parent_id:
              completeFolder.id === "drive" ? "null" : completeFolder.id,
          }).then((r) => {
            if (r?.data?.payload?.length > 0) {
              driveFoldersNav.set((v) => {
                const newData = [
                  ...v,
                  ...r.data.payload.map(mappedDataToTreeView),
                ].reduce((arr, item) => {
                  if (!arr.find((b) => b.id == item.id)) {
                    arr.push(item);
                  }
                  return arr;
                }, []);
                return newData;
              });
            }
          });
        }
        const filterData = data.filter((a) => {
          if (a?.type === 6 && userInfoState.get({ stealth: true })) {
            a.name = userInfoState.get({ stealth: true })?.tenant_name || "";
          }
          return true;
        });

        driveFoldersNav.set([
          {
            fileType: "folder",
            id: "drive",
            parent: 0,
            name: "common:drive.tree_nav.root_folder",
            droppable: false,
            draggable: false,
            notShowDropDownIcon: true,
          },
          ...filterData.sort((a, b) => {
            if (a.type === 7) {
              return -1;
            } else if ([1, 2, 3, 4, 5].includes(a.type)) {
              return a.type - b.type;
            } else {
              return 0;
            }
            // return a.type - b.type
          }),
          {
            draggable: false,
            droppable: false,
            id: "delivery",
            name: "common:drive.tree_nav.delivery_main",
            parent: 0,
            notShowDropDownIcon: true,
            fileType: "email",
          },
          {
            draggable: false,
            droppable: false,
            id: "delivery/list",
            name: "common:drive.tree_nav.file_delivery",
            parent: "delivery",
            notShowDropDownIcon: true,
            fileType: "empty",
          },
          {
            drive_folder_id: "trash",
            parent_id: null,
            name: "common:drive.tree_nav.trash",
            title: "",
            type: 11,
            updated_date: "",
            extension: "folder",
            parent: "drive",
            droppable: false,
            draggable: false,
            fileType: "trash",
            id: "trash",
            format_type: "trash",
            notShowDropDownIcon: true,
          },
          // {
          //   fileType: "trash",
          //   drive_folder_id: 'trash',
          //   id: 'trash',
          //   parent: 'drive',
          //   name: "common:drive.tree_nav.trash",
          //   droppable: true,
          //   draggable: false,
          //   type: 11,
          //   notShowDropDownIcon: true,
          // },
        ]);
        return new Promise((resolve) => {
          resolve(true);
        });
      }
    });
  }
});
export const isShowTableDragFile = hookstate(false);
export const driveFoldersDetail = hookstate({
  page_size: 50,
  page_number: 1,
  total: 0,
  data: {},
});

export const driveLoading = hookstate(false);
export const driveDetailLoading = hookstate(false);
export const expandNodeState = hookstate([]);
export const folderDetailSearch = hookstate({
  non_files: 0,
  order_by: "name asc, updated_date desc, size asc, extension asc, memo asc",
  page_size: 50,
  type: null,
  page_number: 1,
  search: "",
  offset_folder_number: 0,
  offset_file_number: 0,
  offset_document_number: 0,
});

export const progessUploadFileGlobal = hookstate({
  progess: 0,
  complete: 0,
  totalFile: 0,
  listUploaded: [{ id: 0, name: "", parent: 0, url: "" }],
});
export const progessCopyState = hookstate({
  total: 0,
  complete: 0,
});
export const progessMoveState = hookstate({
  total: 0,
  complete: 0,
});
export const progessZipState = hookstate({
  total: 0,
  complete: 0,
});
export const hoveredRows = hookstate(-1);

export const selectedRowsNav = hookstate([]);

export const selectedRowsTreeSearch = hookstate([]);

export const notificationAlertState = hookstate({
  show: false,
  type: "success",
  message: "",
});

export const warningAlertState = hookstate({
  show: false,
  type: "warning",
  message: "",
});

export const progessZippingState = hookstate({
  open: false,
  message: "drive.message.zipping",
});

export const progressDownloadingState = hookstate({
  open: false,
  message: "drive.message.downloading",
});

export const isTableDragging = hookstate(false);
export const dragTargetState = hookstate([]);

export const isCompleteFolderState = hookstate(false);
export const userInfoState = hookstate(null);

export const currentFolderTypeState = hookstate(0);

export const breabCrumbState = hookstate([]);

export const notificationInfoState = hookstate({
  show: 1,
  message: "",
});

export const showConfirmOnDelete = hookstate({
  open: false,
  title: "",
  message: "",
  // callback: onClickDelete,
  loading: false,
});

export const globalAnchorContextMenu = hookstate(null);

export const isDraggingGlobalState = hookstate(false);

export const isOpenShareDialog = hookstate(false);

export const treeSearchData = hookstate([]);

export const expandNodeTreeSearchState = hookstate([]);

export const currencyOptionsState = hookstate([]);

export const driveFileDrawer = hookstate({
  open: false,
  dataDetail: {
    memo: "",
    transaction_date: "",
    amount: 0,
    currency: "JPY",
    counterparty_name: "",
  },
});

export const searchObjectFolder = hookstate({
  order_by: "name asc, updated_date desc,  size asc, extension asc, memo asc",
  page_size: 50,
  page_number: 1,
  total: 51,
  search: "",
  offset_folder_number: 0,
  offset_file_number: 0,
  isSearching: 0,
  isStop: 0,
  offset_document_number: 0,
});

export const folderDetailSearchState = hookstate([]);

export const scrollLoadingState = hookstate(false);
