import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import Main from "./Main";
import { withTranslation } from "react-i18next";
import Service from '../../Service';
import LoadingList from '../LoadingList';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import DownloadIcon from '@mui/icons-material/Download';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import {
	Nav,
	NavDropdown,
	// Form,
	Table,
	Dropdown, ButtonGroup
} from 'react-bootstrap';

import {
	faChevronLeft,
	faChevronRight,
	faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Loading from "../Loading";
import Error from '../Error';
import DocumentDetail from '../Documents/DocumentDetail';
import Tooltip from '@mui/material/Tooltip';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import CircularProgress from '@mui/material/CircularProgress';
import RefreshIcon from '@mui/icons-material/Refresh';
import { userInfoState } from "../../drive-feature/data-access/state";
import { ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

class Verification extends Main {

	constructor(props) {
		super(props);
		this.state.page = "Verification";
		this.state.code = "verification";
		this.state.function_type = 4;
		this.state.searchOption.function_type = "";
		this.state.searchOption.status_id_list = [];
		this.state.docSearchResult = null;
		this.state.selectedDocList = {};

		this.state.fields = {};
		this.state.formData = {};
		this.state.keyFields = {
			function_type: "function-type",
			document_type: "document-type",
			doc_title: "doc-title",
			min_amount: "min-amount",
			max_amount: "max-amount",
			currency: "currency",
			display_imprint: "display-imprint",
			contract_date: "contract-date",
			expiry_date: "expiry-date",
			// min_received_date: "min-received-date",
			// max_received_date: "max-received-date",
			min_received_date: "min-deal-date",
			max_received_date: "max-deal-date",
			min_contract_date: "min-contract-date",
			max_contract_date: "max-contract-date",
			min_expiry_date: "min-expiry-date",
			max_expiry_date: "max-expiry-date",
			counter_party_name: "counter-party-name",
			product: "product",
			reference: "reference",
			status_id: "document-status",
			filebox_shortcut: "shortcut-to-filebox",
			doc_attributes: "attributes",
			function_type_list: "function-type-list",
			has_verification: "has-verification",
			verify_user: "verify-user",
		}

		this.state.docSearchOption = {
			status_id: '',
			function_type: '',
			sorting_code: 2,
			current: 1,
			page: 1,
			total: 0,
			page_size: 100,
			page_number: 1,
			status_id_list: [],
		};

		// for (let i of QueryParams.keys()) {
		// console.log(this.state.searchOption);

		this.setStateFields();
	}

	setStateFields = (cb) => {
		let { t } = this.props;
		let { keyFields, fields, formData } = this.state;
		// this.state.fields = {};
		for (let key in keyFields) {
			let label = keyFields[key];
			fields[key] = {
				name: key,
				value: "",
				label: t(`common:documents.document-info.${label}`),
			};
			if (formData[key]) {
				fields[key].value = formData[key];
			} else {
				formData[key] = fields[key].value;
			}
		}

		this.setState({ fields }, cb);
	}

	componentDidMountOptional = async (fnCB) => {
		let { searchOption, PageData, docStatus } = this.state;

		searchOption.status_id_list = [];
		for (let eStatus of Object.values(docStatus)) {
			searchOption.status_id_list.push(eStatus.id);
		}

		searchOption.status_id_list = searchOption.status_id_list.join(",");
		// if (!PageData) {
		// 	await Service.GetPageData("verification").then(resp => {
		// 		PageData = resp;
		// 	});
		// }
		// console.log(PageData);

		this.setState({ searchOption, PageData }, fnCB);
	}

	// CurrencyItems = () => {
	// 	let { t } = this.props;
	// 	let { PageData } = this.state;
	// 	let { currencies } = PageData;
	// 	let OptionItems = [];
	// 	OptionItems.push(<option key={"empty"} value="">{t("common:general.all")}</option>);
	// 	for (let i in currencies) {
	// 		let currency = currencies[i];
	// 		OptionItems.push(<option key={i} value={currency.code}>{currency.label}</option>);
	// 	}
	// 	return OptionItems;
	// }

	// FunctionTypeItems = () => {
	// 	let { t } = this.props;

	// 	let function_type_items = {
	// 		1: "signer",
	// 		2: "sender",
	// 		3: "circle",
	// 		4: "verification",
	// 	}

	// 	let OptionItems = [];
	// 	OptionItems.push(<option key={"empty"} value="">{t("common:document.function-type.all")}</option>);
	// 	for (let i in function_type_items) {
	// 		OptionItems.push(<option key={i} value={i}>{t(`common:document.function-type.${function_type_items[i]}`)}</option>);
	// 	}

	// 	return OptionItems;
	// }

	// DocumentTypeItems = () => {
	// 	let { t } = this.props;
	// 	let { PageData } = this.state;
	// 	let { doc_types } = PageData;

	// 	let OptionItems = [];
	// 	OptionItems.push(<option key={"empty"} value="">{t("common:general.all")}</option>);
	// 	for (let i in doc_types) {
	// 		let doc_type = doc_types[i];
	// 		OptionItems.push(<option key={doc_type.id} value={doc_type.id}>{t("common:" + doc_type.label)}</option>);
	// 	}

	// 	return OptionItems;
	// }

	// StatusItems = () => {
	// 	let { t } = this.props;
	// 	let { docStatus } = this.state;

	// 	let OptionItems = [];
	// 	OptionItems.push(<option key={"empty"} value="">{t("common:general.all")}</option>);
	// 	for (let i in docStatus) {
	// 		let status = docStatus[i];
	// 		OptionItems.push(<option key={status.id} value={status.id}>{t("common:" + status.label)}</option>);
	// 	}

	// 	return OptionItems;
	// }

	// SearchForm = () => {
	// 	// let { t } = this.props;
	// 	let { PageData } = this.state;
	// 	let { FieldControl, FunctionTypeItems, DocumentTypeItems, CurrencyItems, StatusItems } = this;

	// 	if (!PageData) {
	// 		return <Loading />;
	// 	}

	// 	return (
	// 		<div className="form-container">

	// 			<Form.Row>
	// 				<FieldControl name="function_type" as="select" xs={12} md={3}>
	// 					<FunctionTypeItems />
	// 				</FieldControl>
	// 				<FieldControl name="document_type" as="select" xs={12} md={3}>
	// 					<DocumentTypeItems />
	// 				</FieldControl>
	// 				<FieldControl name="doc_title" xs={12} md={6} />
	// 			</Form.Row>

	// 			{/* <span>~</span> */}
	// 			<Form.Row>
	// 				<FieldControl name="min_amount" type="number" step="0.01" xs={6} md={5} />
	// 				<FieldControl name="max_amount" type="number" step="0.01" xs={6} md={5} />
	// 				<FieldControl name="currency" as="select" xs={12} md={2}>
	// 					<CurrencyItems />
	// 				</FieldControl>
	// 			</Form.Row>

	// 			{/* for verification */}
	// 			<Form.Row>
	// 				<FieldControl name="min_contract_date" type="date" xs={6} md={4} />
	// 				<FieldControl name="max_contract_date" type="date" xs={6} md={4} />
	// 				<FieldControl name="status_id" as="select" xs={12} md={4}>
	// 					<StatusItems />
	// 				</FieldControl>
	// 			</Form.Row>

	// 			<Form.Row>
	// 				<FieldControl name="min_expiry_date" type="date" xs={6} md={4} />
	// 				<FieldControl name="max_expiry_date" type="date" xs={6} md={4} />
	// 			</Form.Row>

	// 			<Form.Row>
	// 				<FieldControl name="product" xs={12} md={6} />
	// 				<FieldControl name="reference" xs={12} md={6} />
	// 			</Form.Row>

	// 		</div>
	// 	);
	// }

	// BasicInfoActionButtons = () => {
	// 	let { t } = this.props;
	// 	let { PageData } = this.state;

	// 	let Buttons = [];
	// 	if (PageData) {
	// 		Buttons.push(
	// 			<Button key="ok" type="submit" sx={{ ml: 1 }} variant="contained">
	// 				<span>{t("common:general.ok")}</span>
	// 			</Button>
	// 		);
	// 	}

	// 	return Buttons;
	// }

	HiddenMenuBody = () => {
		let { t } = this.props;
		let { selectedDocList } = this.state;

		let selectedDocs = Object.keys(selectedDocList);

		let DocItems = selectedDocs.map((key) => {
			let doc = selectedDocList[key];

			return (
				<li key={key} className="document-selected-item">
					<div className="document-info" >
						<span>{`${doc.id}: ${doc.title}`}</span>
					</div>
					<div className="document-action" >
						<Tooltip title={t("common:tooltip.common.delete")} arrow>
							<FontAwesomeIcon icon={faTrash} className="btn-remove" onClick={(ev) => {
								let { selectedDocList } = this.state;
								if (selectedDocList[doc.id]) {
									delete selectedDocList[doc.id];
								}
								this.setState({ selectedDocList });
							}} />
						</Tooltip>
					</div>
				</li>
			);
		});

		return [(
			<div key="selected-header" className="document-selected-header">
				{t("common:documents.evaluate.selected-list")}
			</div>
		), (
			<ul key="selected-boby" className="document-selected-list">
				{DocItems}
			</ul>
		)];
	}

	formSubmitCallback = (ev) => {
		// let { t } = this.props;
		let { formData, modal, docSearchOption } = this.state;
		// /* DialogBody to Loading */
		// console.log(formData);
		// console.log(docSearchOption);
		for (let i in formData) {
			docSearchOption[i] = formData[i];
		}
		// modal.body = Loading;
		// modal.action = null;
		modal.props.show = false;
		this.setState({ modal, docSearchOption, showDetail: true, showHiddenMenu: true }, this.SearchDocumentListData);
	}

	// SearchDialog = () => {
	// 	let { t } = this.props;
	// 	let { modal } = this.state;

	// 	modal.props = {
	// 		show: true,
	// 		centered: true,
	// 		size: "lg",
	// 	};

	// 	modal.title = t('common:documents.verification-filter');

	// 	modal.form = {
	// 		id: "user-info-form",
	// 		onSubmit: (ev) => { this.formSubmitHandler(ev, null, this.formSubmitCallback) },
	// 		noValidate: true,
	// 	};
	// 	modal.action = this.BasicInfoActionButtons;

	// 	modal.body = this.SearchForm;

	// 	this.setState({ modal, formValidate: false });
	// }

	SearchDocumentListData = (docSearchOption) => {
		if (!docSearchOption) {
			docSearchOption = this.state.docSearchOption;
		} else {
			docSearchOption = {
				...this.state.docSearchOption,
				...docSearchOption
			}
		}
		docSearchOption = this.PageCalculation(docSearchOption);
		// if (docSearchOption.function_type) {
		// 	docSearchOption.function_type_list = "";
		// } else {
		// 	docSearchOption.function_type_list = "[1,2]";
		// }
		docSearchOption.function_type = 4;
		docSearchOption.has_verification = 0;
		docSearchOption.status_id_list = [9, 10, 12, 14];
		this.setState({ docSearchResult: null, docSearchOption }, () => {
			// console.log(docSearchOption);
			Service.getDocumentList({
				...docSearchOption,
				status_id_list: docSearchOption.status_id_list.join(",")
			}).then(resp => {
				let { docSearchResult } = this.state;
				docSearchResult = resp.payload;
				docSearchOption.total = resp.total;
				docSearchOption = this.PageCalculation(docSearchOption);
				// console.log(docSearchResult);
				this.setState({ docSearchResult, docSearchOption });
			}).catch(err => {
				console.log(err.response);
			});
		});
	}

	onDocumentFunctionTypeChangeHandler = (ev) => {
		let selected = ev;
		// console.log(selected);
		selected = parseInt(ev) || null;

		let { docSearchOption } = this.state;
		if (selected !== docSearchOption.function_type) {
			docSearchOption.function_type = selected;
			this.SearchDocumentListData(docSearchOption);
		}
	}

	onDocumentStatusChangeHandler = (ev) => {
		let status = ev;
		if (status !== "all") {
			status = parseInt(ev);
		} else {
			status = "";
		}
		let { docSearchOption } = this.state;
		if (status !== docSearchOption.status_id) {
			docSearchOption.status_id = status;
			this.SearchDocumentListData(docSearchOption);
		}
	}

	onDocumentSortingHandler = (ev) => {
		let sort = parseInt(ev);
		let { docSearchOption } = this.state;
		if (sort !== docSearchOption.sorting_code) {
			docSearchOption.sorting_code = sort;
			this.SearchDocumentListData(docSearchOption);
		}
	}

	onDocumentListChangeHandler = (ev) => {
		let page = parseInt(ev);
		let { docSearchOption } = this.state;
		let nextSkip = page;
		if (nextSkip !== docSearchOption.page_number) {
			docSearchOption.page_number = nextSkip;
			this.SearchDocumentListData(docSearchOption);
		}
	}

	onDocumentListLimitChangeHandler = (ev) => {
		let limit = parseInt(ev);
		let { docSearchOption } = this.state;
		if (limit !== docSearchOption.page_size) {
			let cursor = (docSearchOption.current - 1) * docSearchOption.page_size;
			docSearchOption.page_number = Math.floor(cursor / limit) === 0 ? 1 : Math.floor(cursor / limit);
			docSearchOption.page_size = limit;
			this.SearchDocumentListData(docSearchOption);
		}
	}

	DocumentFunctionTypeFilter = () => {
		let { t } = this.props;
		// let { docSearchOption } = this.state;

		let function_type_items = {
			0: "all",
			1: "signer",
			2: "sender",
			3: "circle",
			4: "verification",
		}

		let FilterItems = [];
		for (let key in function_type_items) {
			let label = function_type_items[key];
			FilterItems.push(<NavDropdown.Item key={key} eventKey={key}>{t(`common:document.function-type.${label}`)}</NavDropdown.Item>);
		}

		// let active = function_type_items[docSearchOption.function_type || 0];
		let active = function_type_items[0];
		let title = t(`common:document.function-type.${active}`);

		return (
			<Nav activeKey={active} onSelect={this.onDocumentFunctionTypeChangeHandler} className="mr-auto">
				<NavDropdown title={title} id="basic-nav-dropdown">
					{FilterItems}
				</NavDropdown>
			</Nav>
		);
	}

	DocumentStatusListFilter = () => {
		let { t } = this.props;
		let { docSearchOption, docStatus } = this.state;

		if (Object.keys(docStatus).length === 0) {
			return null;
		}
		// console.log(docStatus);

		let statusItems = [];
		statusItems.push(<NavDropdown.Item key="all" eventKey="all">{t("common:document.status.all")}</NavDropdown.Item>);
		for (let i in docStatus) {
			let status = docStatus[i];
			statusItems.push(<NavDropdown.Item key={status.id} eventKey={status.id}>{t(`common:${status.label}`)}</NavDropdown.Item>)
		}

		statusItems.push(
			<NavDropdown.Item key={'99'} eventKey={99}>
				{t("common:document.status.protected-PDF-supported")}
			</NavDropdown.Item>
		);

		let active = docSearchOption.status_id;
		let title = t("common:document.status.all");
		if (isNaN(parseInt(active))) {
			active = "all";
		} else if (active === 99) {
			active = "99";
			title = t("common:document.status.protected-PDF-supported");
		} else {
			let currentStatus = docStatus[active];
			title = t(`common:${currentStatus.label}`);
		}

		return (
			<Nav activeKey={active} onSelect={this.onDocumentStatusChangeHandler} className="mr-auto">
				<NavDropdown title={title} id="basic-nav-dropdown">
					{statusItems}
				</NavDropdown>
			</Nav>
		);
	}

	DocumentListSorting = () => {
		let { t } = this.props;
		let { docSearchOption } = this.state;
		let sortList = {
			2: "common:search.sorting.decending.by-issued-date",
			1: "common:search.sorting.ascending.by-issued-date",
		};
		let sortItems = [];

		for (let i in sortList) {
			sortItems.push(<NavDropdown.Item key={i} eventKey={i}>{t(sortList[i])}</NavDropdown.Item>)
		}

		return (
			<Nav activeKey={docSearchOption.sorting_code} onSelect={this.onDocumentSortingHandler} className="mr-auto">
				<NavDropdown title={t(sortList[docSearchOption.sorting_code])} id="basic-nav-dropdown">
					{sortItems}
				</NavDropdown>
			</Nav>
		);
	}

	docListPrevPageData = (ev) => {
		let { docSearchOption } = this.state;
		let nextPage = docSearchOption.page_number - 1;
		if (nextPage >= 0) {
			docSearchOption.page_number = nextPage;
			this.SearchDocumentListData(docSearchOption);
		}
	}

	docListNextPageData = (ev) => {
		let { docSearchOption } = this.state;
		let nextPage = docSearchOption.page_number + 1;
		if (nextPage <= docSearchOption.page) {
			docSearchOption.page_number = nextPage;
			this.SearchDocumentListData(docSearchOption);
		}
	}

	DocumentListNavigator = () => {
		let { docSearchOption } = this.state;
		let navItems = [];

		for (let i = 0; i < docSearchOption.page; i++) {
			let page = i + 1;
			let from = i * docSearchOption.page_size;
			let to = page * docSearchOption.page_size;
			navItems.push(<NavDropdown.Item key={page} eventKey={page}>{`${page} : ${from}-${to}`}</NavDropdown.Item>)
		}

		return (
			<Nav activeKey={docSearchOption.current} onSelect={this.onDocumentListChangeHandler} className="mr-auto">
				<NavDropdown title={`${docSearchOption.current}/${docSearchOption.page}`} id="basic-nav-dropdown">
					{navItems}
				</NavDropdown>
			</Nav>
		);
	}

	SelectAllHandler = (ev) => {
		let { docSearchResult, selectedDocList } = this.state;
		if (docSearchResult && selectedDocList) {
			for (let doc of docSearchResult) {
				selectedDocList[doc.id] = doc;
			}
			this.setState({ selectedDocList });
		}
	}

	UnselectAllHandler = (ev) => {
		let { docSearchResult, selectedDocList } = this.state;
		if (docSearchResult && selectedDocList) {
			for (let doc of docSearchResult) {
				delete selectedDocList[doc.id];
			}
			this.setState({ selectedDocList });
		}
	}

	// HostUserItems = () => {
	// 	let { t } = this.props;
	// 	let { PageData } = this.state;
	// 	// console.log(PageData);
	// 	let OptionItems = [];
	// 	OptionItems.push(<option key="empty" value="">{t(`common:documents.viewer-setting.verifier`)}</option>);
	// 	for (let user of PageData.hosts) {
	// 		OptionItems.push(<option key={user.user_id} value={user.user_id}>{`${user.family_name} ${user.first_name}`}</option>);
	// 	}

	// 	return OptionItems;
	// }

	BulkRegisterConfirm = () => {
		let { t } = this.props;
		let { modal, selectedDocList } = this.state;
		// let { FieldControl, HostUserItems } = this;

		let selectedDocs = Object.keys(selectedDocList);

		modal.props = {
			show: true,
			centered: true,
		};

		modal.form = {};
		modal.action = [];
		modal.title = t('common:documents.verification.register-warning');
		modal.body = t('common:documents.verification.warning-please-select');
		if (selectedDocs.length > 0) {

			modal.title = t('common:documents.verification.register-confirm');
			modal.form = {
				id: "user-info-form",
				onSubmit: (ev) => {
					this.formSubmitHandler(ev, null, (ev) => {
						let { modal } = this.state;
						modal.title = t('common:documents.verification.register-on-process');
						modal.body = Loading;
						modal.form = {};
						modal.action = [];
						this.setState({ modal }, this.StartBulkRegister);
					});
				},
				noValidate: true,
			};
			modal.body = t('common:documents.verification-confirm-question');
			// modal.body = () => {
			// 	return (
			// 		<div className="form-container">
			// 			<Form.Row>
			// 				<FieldControl name="verify_user" as="select" xs={12} md={12} required validate="true">
			// 					<HostUserItems />
			// 				</FieldControl>
			// 			</Form.Row>
			// 		</div>
			// 	);
			// }
			modal.action = [(
				<Button key="ok" type="submit" sx={{ ml: 1 }} variant="contained">
					<span>{t("common:general.ok")}</span>
				</Button>
			)];
		}

		this.setState({ modal, formValidate: false });
	}

	BulkVerification = () => {
		const { t } = this.props;
		let { selected_documents, documents_statuses } = this.state;

		// filter items from selected documents that have function_type_id = 4 and action = please-confirm
		const filtered_selected_documents = selected_documents.filter(doc => doc.function_type_id === 4 && doc.action === "please-confirm");

		const veriDoc = filtered_selected_documents.map(doc => doc.id);
		if (veriDoc.length > 0) {
			Service.verificationBulkRegister({
				// "documents": Object.keys(selectedDocList),
				"timestamp_documents": [],
				"verify_documents": veriDoc,
				"verify_user": ""
			}).then((resp) => {
				let uniqueIds = new Set();
				const new_documents_statuses = [...documents_statuses, ...filtered_selected_documents].filter(document => {
					if (!uniqueIds.has(document.id)) {
						uniqueIds.add(document.id);
						return true;
					}
					return false;
				});

				this.UpdateModalDialog({
					body: () => {
						return (
							<div>{t("common:" + resp.message)}</div>
						);
					},
					action: [],
				});

				this.setState({
					selected_documents: [],
					isSelectedAll: false,
					documents_statuses: new_documents_statuses,
					batched_documents: filtered_selected_documents,
					init_batched_documents: filtered_selected_documents,
				});
				const userInfoGet = userInfoState.get({ noproxy: true });
				if (userInfoGet?.signed_document_verification > 0) {
					userInfoState.set((v) => ({
						...v,
						signed_document_verification:
						userInfoGet?.signed_document_verification - 1,
					}));
					localStorage.setItem(
						"user-info",
						JSON.stringify(userInfoState.get({ noproxy: true }))
					);
				}
			}).catch(err => {
				console.log("error", err.response);
				this.UpdateModalDialog({
					body: () => {
						return (
							<div>{t("common:" + err.response.data.error)}</div>
						);
					},
					action: [
						(<Button key="ok" sx={{ ml: 1 }} variant="contained" onClick={(ev) => {
							this.BulkVerification();
						}}>
							<span>{t("common:general.try-again")}</span>
						</Button>),
					],
				});
			});
		} else {
			this.modalToggle(false);
		}
	}

	BulkVerificationConfirmation = () => {
		let { t } = this.props;
		this.UpdateModalDialog({
			title: "common:document.batch.timestamp",
			body: () => {
				return (
					<div>{t('common:document.batch.timestamp')}</div>
				);
			},
			action: [
				(
					<Button
						key="ok"
						sx={{ ml: 1 }}
						variant="contained"
						onClick={() => {
							this.BulkVerification();
						}}>
						<span>{t("common:general.ok")}</span>
					</Button>
				),
			],
			props: {
				"show": true,
				"centered": true,
			},
		});
	}

	StartBulkRegister = (ev) => {
		let { t } = this.props;
		let { modal, selectedDocList, formData } = this.state;

		let otherDoc = [];
		let veriDoc = [];

		for (let selectDocKey in selectedDocList) {
			let selectDoc = selectedDocList[selectDocKey];

			if (selectDoc.function_type_id === 4) {
				veriDoc.push(selectDocKey);
			} else {
				otherDoc.push(selectDocKey);
			}
		}

		// console.log(formData);
		Service.verificationBulkRegister({
			// "documents": Object.keys(selectedDocList),
			"timestamp_documents": otherDoc,
			"verify_documents": veriDoc,
			"verify_user": formData["verify_user"],
		}).then(resp => {
			// console.log(resp);
			let { modal, searchOption } = this.state;
			modal.props.show = false;
			this.setState({ modal, showDetail: false, showHiddenMenu: false, selectedDocList: {} }, () => {
				this.GetDocumentListData(searchOption);
			});
		}).catch(err => {
			console.log("error", err.response);

			let errMessage = err?.response?.data?.message;
			if (err?.response?.data?.hasOwnProperty("error")) {
				errMessage = err.response.data.error;
			}
			if (err?.code === 'ECONNABORTED' || (err?.response?.status === 504 && err?.response?.data?.message === 'Endpoint request timed out')) {
				errMessage = t('message.error.request.timed_out', `サーバーが混雑しております。\n しばらく待ってアクセスしてください。`);
				// return;
			}

			modal.body = t(`common:${errMessage || 'message.error.server_unknown_error'}`);
			modal.action = [(
				<Button key="ok" sx={{ ml: 1 }} variant="contained" onClick={(ev) => {
					let { modal } = this.state;
					modal.body = Loading;
					modal.action = [];
					this.setState({ modal }, this.StartBulkRegister);
				}}>
					<span>{t("common:general.try-again")}</span>
				</Button>
			)];

			this.setState({ modal });
		});
	}

	MoreActionButton = () => {
		let { t } = this.props;
		let { showHiddenMenu } = this.state;

		if (!showHiddenMenu) {
			return null;
		}

		return ([(
			// 	<button key="btn-filter" className="btn-action btn-custom" onClick={this.SearchDialog}>
			// 		<FontAwesomeIcon icon={faFilter} title={t("common:document.return-to-list-page")} />
			// 	</button>
			// ), (
			<Tooltip key="btn-unselect-all" title={t("common:tooltip.common.uncheck-all")} arrow>
				<IconButton
					sx={{ ml: 2 }}
					className="btn-custom"
					color="primary"
					onClick={this.UnselectAllHandler}
				//title={t("common:document.unselect-all")}
				>
					<CheckBoxOutlineBlankIcon />
					{/* <span className="label">{t('common:document.unselect-all')}</span> */}
				</IconButton>
			</Tooltip>
		), (
			<Tooltip key="btn-select-all" title={t("common:tooltip.common.uncheck-all")} arrow>
				<IconButton
					className="btn-custom"
					color="primary"
					onClick={this.SelectAllHandler}
				//title={t("common:document.select-all")}
				>
					<CheckBoxOutlinedIcon />
					{/* <span className="label">{t('common:document.select-all')}</span> */}
				</IconButton>
			</Tooltip>
		), (
			<Button
				sx={{ ml: 1 }}
				key="btn-confirm"
				className="btn-custom"
				onClick={this.BulkRegisterConfirm}
				variant="contained"
				startIcon={<AccessTimeOutlinedIcon />}
			>
				<span className="label">{t('common:document.verification-confirm')}</span>
			</Button>
		)]);
	}

	DocumentDetailHeaderRight = () => {
		let { t } = this.props;
		let { docSearchOption, showHiddenMenu } = this.state;
		let { DocumentListNavigator, DocumentStatusListFilter, DocumentListSorting } = this;

		if (!showHiddenMenu) {
			return null;
		}

		return (
			<div className="header-right">

				{/* <DocumentFunctionTypeFilter /> */}

				<DocumentStatusListFilter />

				<DocumentListSorting />

				<Nav activeKey={docSearchOption.page_size} onSelect={this.onDocumentListLimitChangeHandler} className="mr-auto">
					<NavDropdown title={docSearchOption.page_size + " " + t("common:search.view.record-per-page")} id="basic-nav-dropdown">
						<NavDropdown.Item eventKey={10}>{"10 " + t("common:search.view.record-per-page")}</NavDropdown.Item>
						<NavDropdown.Item eventKey={20}>{"20 " + t("common:search.view.record-per-page")}</NavDropdown.Item>
						<NavDropdown.Item eventKey={50}>{"50 " + t("common:search.view.record-per-page")}</NavDropdown.Item>
						<NavDropdown.Item eventKey={100}>{"100 " + t("common:search.view.record-per-page")}</NavDropdown.Item>
					</NavDropdown>
				</Nav>

				<div className="pagination">

					<DocumentListNavigator />

					<button className="btn-pagination" onClick={this.docListPrevPageData}>
						<FontAwesomeIcon icon={faChevronLeft} />
					</button>

					<button className="btn-pagination" onClick={this.docListNextPageData}>
						<FontAwesomeIcon icon={faChevronRight} />
					</button>
				</div>
			</div>);
	}

	DocumentDetailBodyContent = (props) => {
		// let { t } = this.props;
		let { currentDocInfo } = this.state;
		let { ReturnButton } = this;

		if (!currentDocInfo) {
			return <Loading />;
		}

		if (currentDocInfo === false) {
			return <Error />;
		}

		// console.log(currentDocInfo);
		return (
			<DocumentDetail docInfo={currentDocInfo} action={ReturnButton} />
		);
	}

	DocumentListSearchBody = (props) => {
		let { t } = this.props;
		// let { documents } = this.state;
		let { DocumentList, DocumentDetailHeaderRight, MoreActionButton } = this;

		// if (documents === null) {
		// 	return <Loading />;
		// }

		// if (documents.length === 0) {
		// 	return (
		// 		<Table>
		// 			<tbody>
		// 				<tr className="document-row-list">
		// 					<td className="record-not-found">
		// 						<Error msg={t("common:message.record.not-found")} />
		// 					</td>
		// 				</tr>
		// 			</tbody>
		// 		</Table>
		// 	);
		// }

		return (
			<div className="document-detail-wrapper">
				<div className="header-wrapper sticky">
					<div className="header-left">
						<Tooltip title={t("common:document.return-to-list-page")} arrow>
							<IconButton
								onClick={(ev) => {
									this.setState({ showDetail: false, showHiddenMenu: false, docSearchResult: null, selectedDocList: {} });
								}}>
								<ArrowBackOutlinedIcon />
							</IconButton>
						</Tooltip>
						<MoreActionButton />

					</div>

					<div className="header-center document-title"></div>

					<DocumentDetailHeaderRight />

				</div>

				<div className="body-wrapper">
					<DocumentList />
				</div>

			</div>
		);
	}

	DocumentDetailBody = (props) => {
		let { showHiddenMenu } = this.state;
		let { DocumentDetailBodyContent, DocumentListSearchBody } = this;
		if (showHiddenMenu) {
			return <DocumentListSearchBody />;
		} else {
			return <DocumentDetailBodyContent />;
		}
	}

	PDFFilesInDocument = (props) => {
		let { t } = this.props;
		let { files } = props;
		let fileKeys = Object.keys(files);
		if (fileKeys.length > 0) {
			let firstKey = fileKeys.shift();
			let firstFile = files[firstKey];
			let FileLabel = [];

			FileLabel.push(
				<span key="first-file" className="file-name-label">{`${firstFile.name || firstKey}`}</span>
			)

			if (fileKeys.length > 0) {
				let labelMore = t("common:documents.and-one-more-file");
				if (fileKeys.length > 1) {
					labelMore = t("common:documents.and-more-files", { count: fileKeys.length });
				}
				FileLabel.push(
					<div key="more-files" className="more-files">
						<Link to="#" className="more-files-link">
							<span>{labelMore}</span>
						</Link>
						<div className="more-files-block">
							{fileKeys.map(hash => {
								let file = files[hash];
								return (
									<li key={hash} className="file-label">{file.name || hash}</li>
								);
							})}
						</div>
					</div>
				)
			}

			return FileLabel;
		} else {
			return null;
		}
	}

	DocumentList = () => {
		let { t } = this.props;
		let { docSearchResult, selectedDocList } = this.state;
		let { PDFFilesInDocument } = this;

		// console.log(docSearchResult);
		if (docSearchResult === null) {
			return <LoadingList />;
		}

		if (docSearchResult.length === 0) {
			return (
				<Table>
					<tbody>
						<tr className="document-row-list error-tr">
							<td className="record-not-found">
								<Error msg={t("common:message.record.not-found")} />
							</td>
						</tr>
					</tbody>
				</Table>
			);
		}


		let selectedDocs = Object.keys(selectedDocList);
		let ItemsList = [];
		for (let doc of docSearchResult) {
			doc.amount = parseFloat(doc.amount);
			let Amount = (doc.amount || "0").toString();
			// console.log('Amount', Amount);
			Amount = Amount.split(".");
			Amount[0] = Amount[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
			Amount = Amount.join(".") + " " + (doc.currency || "JPY");

			let selectedClass = "";
			if (selectedDocs.includes(doc.id.toString())) {
				selectedClass = " selected";
			}

			// console.log(doc);

			ItemsList.push(
				<tr key={doc.id} className={`document-row-list${selectedClass}`} onClick={(ev) => {
					let { selectedDocList } = this.state;
					if (Object.keys(selectedDocList).includes(doc.id.toString())) {
						delete selectedDocList[doc.id];
					} else {
						selectedDocList[doc.id] = doc;
					}
					this.setState({ selectedDocList });
				}}>
					<td className="sign-status">
						<Tooltip title={t("common:tooltip.common.view-document")} arrow>
							<IconButton color="primary" href={`/documents/detail/${doc.id}`} target="_blank">
								<FileOpenIcon className="action-button" />
							</IconButton>
						</Tooltip>
					</td>
					<td className="creator-info">
						<div className="inner-container">

							<span>{doc.creator_name}</span>
						</div>
					</td>
					<td className="function-type-info">
						<div className="inner-container">

							<span>{t(`common:${doc.function_type}`)}</span>
						</div>
					</td>
					<td className="document-info">
						<div className="inner-container">
							<span>{t(`common:${doc.document_type}`)}</span>
							<div className={"document-progress " + doc.status_code}>
								<span className="status-label">{t(`common:${doc.status_label}`)}</span>
							</div>
						</div>
					</td>
					<td className="title-info">
						<div className="inner-container">
							<span>{doc.id + " - " + doc.title}</span>
							{/* <div className={"document-files"}> */}
							<PDFFilesInDocument files={doc.files} />
							{/* </div> */}
						</div>
					</td>
					<td className="counter-party-info">
						<div className="inner-container">

							<span>{doc.counter_party_name}</span>
						</div>
					</td>
					<td className="amount-info">
						<div className="inner-container">

							<span>{Amount}</span>
						</div>
					</td>
					<td className="document-created-date">
						<div className="inner-container">

							<span>{doc.issued_date}</span>
						</div>
					</td>
				</tr>
			);
		}

		return (
			<Table className="verification-stamp">
				<thead>
					<tr>
						<th className="verification-stamp-detail">
							<div>

							</div>
						</th>
						<th className="verification-stamp-creator">
							<div>
								<span className="text-ellipsis">{t("common:documents.creator")}</span>
							</div>
						</th>
						<th className="verification-stamp-function">
							<div>
								<span className="text-ellipsis">{t("common:documents.document-info.function-type")}</span>
							</div>
						</th>
						<th className="verification-stamp-imprints">
							<div>
								<span className="text-ellipsis">{t("common:documents.document-info.imprints")}</span>
							</div>
						</th>
						<th className="verification-stamp-doctitle">
							<div>
								<span className="text-ellipsis">{t("common:documents.document-info.doc-title")} / {t("common:documents.document-info.file-name")}</span>
							</div>
						</th>
						<th className="verification-stamp-counterparty">
							<div>
								<span className="text-ellipsis">{t("common:documents.document-info.counter-party")}</span>
							</div>
						</th>
						<th className="verification-stamp-amount">
							<div>
								<span className="text-ellipsis">{t("common:documents.document-info.amount")}</span>
							</div>
						</th>
						<th className="verification-stamp-date">
							<div>
								<span className="text-ellipsis">{t("common:documents.issued-date")}</span>
							</div>
						</th>
					</tr>
				</thead>
				<tbody>
					{ItemsList}
				</tbody>
			</Table>
		);
	}


	AdditionalButtons = () => {
		let { t } = this.props;
		let { code, searchOption, documents,loadingDownload, UserInfo } = this.state;
		let ButtonList = [];
		const [open, setOpen] = useState(false);
		const anchorRef = useRef(null);
		const [selectedIndex, setSelectedIndex] = useState(0);
		const handleClose = (event) => {
			if (anchorRef.current && anchorRef.current.contains(event.target)) {
			  return;
			}
		
			setOpen(false);
		  };
		const handleToggle = () => {
			setOpen((prevOpen) => !prevOpen);
		};

		if (UserInfo['archive_mode'] !== 1) {
			ButtonList.push(
				<>
					<ButtonGroup variant="contained" ref={anchorRef}  className="btn-custom-group btn-custom-mui">
						<Tooltip
							placement="right"
							title={t('common:document.bulk.create.tooltip', '複数書類が一度に登録ができます')}
						>
							<Link
								key="0"
								className="btn btn-custom btn-primary"
								to={`/${code}/documents/create/multiple`}
								// style={{borderRadius: '4px 0px 0px 4px'}}
							>
								<AddIcon/>
								{t('common:verification.bulk-new-verification')}
							</Link>
						</Tooltip>
						<Button
							size="small"
							id="document-create-button-spliter"
							className="btn btn-custom btn-primary btn-doropdown-arrow"
							aria-controls={open ? "split-button-menu" : undefined}
							aria-expanded={open ? "true" : undefined}
							aria-label="select merge strategy"
							aria-haspopup="menu"
							onClick={handleToggle}
							// style={{borderRadius: '0px 4px 4px 0px'}}
						>
							<ArrowDropDownIcon />
						</Button>
					</ButtonGroup>
					<Popper
						sx={{
							zIndex: 1,
						}}
						open={open}
						anchorEl={anchorRef.current}
						role={undefined}
						transition
						disablePortal
						>
							{({ TransitionProps, placement }) => (
								<Grow
								{...TransitionProps}
								style={{
									transformOrigin:
									placement === "bottom" ? "center top" : "center bottom",
								}}
								>
								<Paper>
									<ClickAwayListener onClickAway={handleClose}>
									<MenuList id="split-button-menu" autoFocusItem>
										<MenuItem key="1">
										<Link
											key="1"
											to={`/${code}/documents/create/by-template`}
										>
											{t('common:verification.csv-bulk-verification')}
										</Link>
										</MenuItem>
									</MenuList>
									</ClickAwayListener>
								</Paper>
								</Grow>
							)}
					</Popper>
				</>
			)
		}

		// ButtonList.push(
		// 	<Tooltip key={0} title={t("common:tooltip.verification.multiple-files")} arrow>
		// 		<Button
		// 			sx={{ mr: 2, mt: 1 }}
		// 			key="btn-bulk-new-verification"
		// 			startIcon={<AddIcon />}
		// 			variant="outlined"
		// 			size="small"
		// 			component={Link}
		// 			to={`/${code}/documents/create/multiple`}
		// 		>
		// 			{t('common:verification.bulk-new-verification')}
		// 		</Button>
		// 	</Tooltip>
		// )


		ButtonList.push(
			<Tooltip key={2} title={t("common:tooltip.common.download-csv")} arrow>
				<Button
					sx={{ mr: 2, ml: 2 }}
					key="list-download"
					variant="outlined"
					size="small"
					startIcon={loadingDownload ? <CircularProgress size={16}/> : <DownloadIcon />}
					onClick={loadingDownload ? undefined : this.onListDownloadRequestHandler} >
					{/* {"CSVダウンロード"} */}
					{t('common:main.general.list-download')}
				</Button>
			</Tooltip>
		)

		return ButtonList;
	}

	OperationButtons = () => {
		let { t } = this.props;
		let { code, searchOption, documents, isSelectedAll, UserInfo, selected_documents } = this.state;
		let ButtonList = [];

		if (UserInfo['archive_mode'] !== 1) {
			ButtonList.push(
				<IconButton
					className="btn-custom"
					color="primary"
					onClick={() => {
						this.handleSelectAll(!isSelectedAll)
					}}
				>
					{isSelectedAll ? <CheckBoxOutlinedIcon /> : <CheckBoxOutlineBlankIcon />}
				</IconButton>
			);
		}

		if (selected_documents.length === 0) {
			ButtonList.push(
				<IconButton
					key="list-reload"
					color="primary"
					disabled={documents ? false : true}
					onClick={(ev) => {
						this.GetDocumentListData({ ...searchOption }, true);
					}}
				>
					{documents ? <RefreshIcon /> : <CircularProgress size="1em" />}
				</IconButton>
			);
		} else {
			if (UserInfo?.certificate_role == "1" && UserInfo['archive_mode'] !== 1) {
				ButtonList.push(
					<Tooltip key={1} title={t("common:tooltip.verification.batch-approvals")} arrow>
						<Button
							key="btn-bulk-verify"
							//startIcon={<AddIcon />}
							variant="outlined"
							size="small"
							startIcon={<AccessTimeIcon />}
							onClick={this.BulkVerificationConfirmation} >
							{t('common:document.batch.timestamp')}
						</Button>
					</Tooltip>
				)
			}
		}

		return ButtonList;
	}

}

export default withTranslation()(Verification);
