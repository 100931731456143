
/**
 * @param {any} type
 */
export function mappedTypeToQuery(type) {
    switch (type) {
        case 1:
        case 2:
        case 3:
        case 4:
            return "complete";
        case 5:
            return "completep";
        case 11:
            return "trash";
        case 9:
        case 6:
            return "company";
        default:
            return "normal";
    }
}