import axios from "axios";
import API from "../../Config/API";
import { API_ROUTES, driveApiCall } from "../../drive-feature/config/api.config";

/**
 * @param {number} document_id
 */
export function getSignHostDocument(document_id) {
    return driveApiCall.get(API_ROUTES.document.signHost, {
        params: {
            document_id
        }
    })
}


/**
 * @param {string} token
 * @param {string | undefined} [otpCode]
 * @param {number | undefined} [countIncorrect]
 */
export function getSignGuestDocument(token, otpCode, countIncorrect) {
    return axios.get(API.url + API_ROUTES.document.signGuest, {
        params: {
            otpCode,
            countIncorrect
        },
        headers: {
            Authorization: token
        }
    })
}

/**
 * @param {string} token
 * @param {string | undefined} [otpCode]
 */
export async function getSignGuestDocumentStatus(token, otpCode) {
    return await new Promise((resolve, reject) => {
        axios.get(API.url + API_ROUTES.document.signGuestStatus, {
            params: {
                otpCode,
            },
            headers: {
                Authorization: token
            }
        }).then(async resp => {
            resolve(resp.data);
        }).catch(error => {
            reject(error);
        });
    });
}

/**
 * @param {number} document_id
 */
export function getDocumentDetail(document_id) {
    return driveApiCall.get(API_ROUTES.document.detailDocument, {
        params: {
            document_id
        }
    })
}

/**
 * @param {number} document_id
 */
 export function getDocumentSignatureInfo(document_id) {
    return driveApiCall.get(API.domain_new + '/api/document-signatureinfo.json', {
        params: {
            document_id
        }
    })

}


/**
 * @param {string} token
 */
export function reissueSignGuestDocument(token) {
    return new Promise((resolve, reject) => {
        axios.post(
            API.url + API_ROUTES.document.reissueGuest,
            {},
            {
                headers: {
                    Authorization: token
                }
            }).then(async resp => {
            resolve(resp.data);
        }).catch(error => {
            reject(error);
        });
    });
}
