// @ts-nocheck
import { useHookstate } from "@hookstate/core";
import { LoadingButton } from "@mui/lab";
import { Button, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import formatDateIntlApi from "../../../Utils/format_date_intl";
import { createFolder } from "../../data-access/api";
import {
  currentSelectedCompanyFolderState,
  driveNavCompanyTree,
} from "../../data-access/company-folders/company-folders.state";
import { driveFoldersDetail, driveFoldersNav } from "../../data-access/state";
import mappedDataToTreeView from "../../utils/mappedDataToTreeView";
import BaseDialogDriveCrud from "./drive-base-dialog";
import styles from "./drive-dialog.module.css";

export default function DriveCreateFolderDialog({
  open,
  onClose,
  currentParentId,
  folderType,
  currentSelectedCompanyFolder,
  fromDocumentSelect,
}) {
  // console.log(currentSelectedCompanyFolder);

  //   const open = useHookstate(false);
  const { t } = useTranslation("common");
  const pathSelect = useHookstate("");
  const loading = useHookstate(false);
  const methods = useForm({
    defaultValues: {
      name: "",
      // title: ""
    },
  });
  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    watch,
  } = methods;
  // console.log(folderType, currentSelectedCompanyFolder);
  function onSubmit(data) {
    loading.set(true);
    // console.log(folderType);

    const defaultId =
      driveFoldersNav.get({ stealth: true }).find((a) => a.type === 6)?.id ||
      driveNavCompanyTree.get({ noproxy: true }).find((a) => a.type === 6)?.id;
    // console.log(currentParentId || currentSelectedCompanyFolder || defaultId, driveNavCompanyTree.get({noproxy:true}))
    // currentSelectedCompanyFolderState.set(defaultId || 0);
    // return
    createFolder({
      ...data,
      drive_folder_id:
        currentParentId || currentSelectedCompanyFolder || defaultId,
      type:
        folderType === 6 ||
        folderType === 9 ||
        currentSelectedCompanyFolderState.get({ stealth: true }) === defaultId
          ? 9
          : undefined,
    })
      .then((r) => {
        setValue("name", "");
        // console.log(r);
        const formatedVal = formatDateIntlApi(new Date().toISOString(), {
          timeZone: "UTC",
          month: "2-digit",
          day: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hourCycle: "h23",
        });
        // console.log(formatedVal);
        if (!fromDocumentSelect) {
          driveFoldersNav.set((v) => [
            ...[
              {
                ...data,
                id: r.data.payload,
                parent_id: +currentParentId,
                is_empty: 1,
                extension: "folder",
                size: 0,
                type:
                  folderType === 6 ||
                  folderType === 9 ||
                  currentSelectedCompanyFolderState.get({ stealth: true }) ===
                    defaultId
                    ? 9
                    : 8,
                updated_date: `${formatedVal.year}-${formatedVal.month}-${formatedVal.day} ${formatedVal.hour}:${formatedVal.minute}:${formatedVal.second}`,
              },
            ].map(mappedDataToTreeView),
            ...v,
          ]);
        }
        if (folderType !== 6) {
          driveFoldersNav[
            driveFoldersNav
              .get({ noproxy: true, stealth: true })
              .findIndex(
                (a) => a.id == (currentParentId || currentSelectedCompanyFolder)
              )
          ]?.set((v) => {
            if (v) {
              return { ...v, notShowDropDownIcon: 0, is_empty: 0 };
            }
            return v;
          });
        }
        if (!fromDocumentSelect) {
          driveFoldersDetail.data[
            currentParentId === "drive" || !currentParentId
              ? "root"
              : currentParentId
          ].set((v) => [
            ...[
              {
                ...data,
                id: r.data.payload,
                parent_id: +currentParentId,
                is_empty: true,
                size: 0,
                extension: "folder",
                type:
                  folderType === 6 ||
                  folderType === 9 ||
                  currentSelectedCompanyFolderState.get({ stealth: true }) ===
                    defaultId
                    ? 9
                    : 8,
                updated_date: `${formatedVal.year}-${formatedVal.month}-${formatedVal.day} ${formatedVal.hour}:${formatedVal.minute}:${formatedVal.second}`,
              },
            ].map(mappedDataToTreeView),
            ...v,
          ]);
        } else {
          driveNavCompanyTree.set((v) => {
            return [
              ...[
                {
                  ...data,
                  id: r.data.payload,
                  parent_id: +currentParentId || "company",
                  is_empty: 1,
                  size: 0,
                  extension: "folder",
                  updated_date: `${formatedVal.year}-${formatedVal.month}-${formatedVal.day} ${formatedVal.hour}:${formatedVal.minute}:${formatedVal.second}`,
                },
              ].map(mappedDataToTreeView),
              ...v,
            ];
          });
          driveFoldersDetail?.data["company"].set((v) => [
            ...[
              {
                ...data,
                id: r.data.payload,
                parent_id: +currentParentId || "company",
                is_empty: 1,
                size: 0,
                type: 9,
                extension: "folder",
                updated_date: `${formatedVal.year}-${formatedVal.month}-${formatedVal.day} ${formatedVal.hour}:${formatedVal.minute}:${formatedVal.second}`,
              },
            ].map(mappedDataToTreeView),
            ...v,
          ]);
        }
        onClose();
      })
      .finally(() => loading.set(false));
  }
  // const inputRef = useRef(null);
  return (
    <div>
      <BaseDialogDriveCrud
        handleClose={(e) => {
          pathSelect.set("");
          setValue("name", "");
          setValue("title", "");
          return onClose(e);
        }}
        open={open}
        title={t("common:drive.button.menu.create_folder")}
        // フォルダ作成
        dialogContentComponent={
          <>
            <Controller
              name="name"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  size="small"
                  fullWidth
                  error={errors.name?.type === "required"}
                  helperText={
                    errors.name?.type === "required" &&
                    t("common:drive.form.validate_name")
                  }
                  placeholder={t("common:drive.form.name_folder")}
                  // フォルダ名
                  className={styles["form_input"]}
                  {...field}
                />
              )}
            />
            &nbsp;
            {/* <br />
            <Controller
              name="title"
              control={control}
              rules={{ required: false }}
              render={({ field }) => (
                <TextField
                  size="small"
                  fullWidth
                  // error={errors.name?.type === 'required' || !!isError.get()}
                  // helperText={
                  //   (errors.name?.type === 'required' && 'Name is required') ||
                  //   isError.get()
                  // }
                  placeholder={t("common:drive.form.name_folder")}
                  // フォルダメモ
                  className={styles["form_input"]}
                  {...field}
                />
              )}
            /> */}
          </>
        }
        dialogActionComponent={
          <>
            <Button variant="outlined" onClick={onClose}>
              {t("common:drive.button.close")}
              {/* 閉じる */}
            </Button>
            <LoadingButton
              loading={loading.get()}
              variant="contained"
              onClick={handleSubmit(onSubmit)}
            >
              {t("common:drive.button.create_folder")}
              {/* フォルダ作成 */}
            </LoadingButton>
          </>
        }
      />
    </div>
  );
}
