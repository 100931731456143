import '../Assets/css/settings.css';
import Layout from '../../Layout';
import Menu from './Menu';
import LoadingList from '../LoadingList';
import {withTranslation} from "react-i18next";
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import IconButton from '@mui/material/IconButton';
import React, {Suspense} from "react";
import {
	notificationAlertState,
	progessZippingState,
	progressDownloadingState,
	userInfoState
} from '../../drive-feature/data-access/state';
import {Form} from "react-bootstrap";
import Service from "../../Service";
import formatDateIntlApi from "../../Utils/format_date_intl";
import BatchDownloadDetail from "./BatchDownloads/BatchDownloadDetail";
import Tooltip from "@mui/material/Tooltip";
import {StateFragment} from "@hookstate/core";
import {shedOnScroll} from "../../data-access/app.state";
import {ResizableTableWithCache} from "../../Components/resizeable-table-with-cache/ResizableTableWithCache";


class BatchDownload extends Layout {

	/** Constructor */
	constructor(props) {
		super(props);
		this.state.page = "batch-download";

		/* For Panigation */
		this.state.pagination = {
			total: 0,
			page_size: 10,
			page_number: 1,
		};

		this.state.datalist = [];
		this.state.datalistLoading = false;

		this.state.user_info = null;

		this.state.dateState = [
			{
				startDate: new Date(),
				endDate: new Date(),
				key: 'selection'
			}
		];

		this.state.datePopover = false;
		this.state.anchorEl = null;

		this.state.fields = {};
		this.state.keyFields = {
			"start_date": "start_date",
			"end_date": "end_date",
		}

		this.state.showDetail = false;
		this.state.batchDownloadDetail = null;
		this.state.enable_create = false;
	}

	Menu = () => {
		/* Load Menu from Pages/Settings/Menu.jsx */
		return (<Menu {...this.props} page={this.state.page} />);

	}

	componentDidMountExtends = () => {
		const user_info = userInfoState.get({noproxy:true});
		this.setState({ user_info });

		if (this.props.match.params.batchId) {
			Service.getBatchDownloadDetail({
				batch_id: this.props.match.params.batchId,
			}).then((response) => {
				if (response.ok) {
					this.setState({
						showDetail: true,
						batchDownloadDetail: response.payload
					})
				}

			}).catch((error) => {
				notificationAlertState.set({
					show: true,
					type: 'error',
					message: error.response.data.message,
				})
				console.log(error.response);
				this.setState({
					showDetail: false,
				})
			}).finally(() => {
				//
			});
		}

		this.handleGetBatchDownloadList();
	}

	setStateFields = (cb) => {
		let { t } = this.props;
		let { keyFields, fields, formData } = this.state;
		for (let key in keyFields) {
			let label = keyFields[key];
			fields[key] = {
				name: key,
				value: "",
				label: t(`common:settings.batch-download.${label}`),
			};
			if (formData[key]) {
				fields[key].value = formData[key];
			}
		}

		this.setState({ fields }, cb);
	}

	handleGetBatchDownloadList = () => {
		this.setState({
			datalistLoading: true,
		});

		let {page_size, page_number} = this.state.pagination;

		let data = {
			"page_size": page_size,
			"page_number": page_number,
		}

		Service.getBatchDownloadList(data).then((response) => {
			let { datalist, pagination, enable_create } = this.state;

			datalist = response.payload;

			pagination.total = response.total;

			enable_create = response.enable_create;

			this.setState({ datalist, pagination, enable_create });

		}).catch((error) => {
			console.log(error.response);
		}).finally(() => {
			this.setState({
				datalistLoading: false,
			});
		});
	};

	Pagination = () => {

		let { pagination, datalist } = this.state;

		datalist = null;

		let max = Math.ceil(pagination.total / pagination.page_size);

		let prev = {
			"type": "button",
			"className": "paginate-prev",
			"onClick": () => {
				pagination.page_number = (pagination.page_number - 1);
				this.setState({ pagination, datalist }, () => this.handleGetBatchDownloadList());
			},
		};

		let next = {
			"type": "button",
			"className": "paginate-next",
			"onClick": () => {
				pagination.page_number = (pagination.page_number + 1);
				this.setState({ pagination, datalist }, () => this.handleGetBatchDownloadList());
			},
		}

		/* */
		if (pagination.page_number === 1) {
			prev["disabled"] = true;
		}

		/* */
		if (pagination.page_number === max || max === 0) {
			next["disabled"] = true;
		}

		/* */
		let start_list = 0;
		let last_list = 0;
		if (pagination.total > 0) {
			start_list = 1 + ((pagination.page_number - 1) * pagination.page_size);
			if (pagination.page_number === max) {
				last_list = pagination.total
			} else {
				last_list = start_list + (pagination.page_size - 1);
			}
		}

		/* */
		return (
			<div className="paginate">
				<div className="paginate-info">
					<span>{start_list}</span>
					<span>{last_list}</span>
					<span>{pagination.total}</span>
				</div>
				<div className="paginate-nav">
					<IconButton key="prev" {...prev}>
						<ChevronLeftIcon />
					</IconButton>
					<IconButton key="next" {...next}>
						<ChevronRightIcon />
					</IconButton>
				</div>
			</div>
		);
	}

	BatchDownloadForm = () => {
		let { FieldControl } = this;

		return (
			<div className="form-container">
				<Form.Control type="hidden" name="batch_download_id" />

				<Form.Row>
					<FieldControl
						name="start_date"
						type="date"
						required
						validate="true"
					/>

					<FieldControl
						name="end_date"
						type="date"
						required
						validate="true"
					/>
				</Form.Row>
			</div>
		);
	}

	BatchDownloadModalAction = () => {
		let { t } = this.props;

		return [
			(
				<Button
					key="ok"
					type="submit"
					sx={{ml: 1}}
					variant="contained"
				>
					<span>{t("common:general.ok")}</span>
				</Button>
			)
		];
	}

	handleCreateBatchDownload = () => {
		let { t } = this.props;
		let { formData, datalist, modal, user_info } = this.state;

		let body = {
			"start_date": formData["start_date"],
			"end_date": formData["end_date"],
		};

		modal.body = () => {
			return (
				<div className="lz-m-20">
					<div className="lz-m-20">
						{t(`common:settings.batch-download.preparing`)}
					</div>
				</div>
			);
		};
		modal.action = null;
		modal.close = true;
		modal.props.show = true;

		this.setState((prevState) => ({
			dateState: [
				{
					startDate: new Date(),
					endDate: new Date(),
					key: 'selection'
				}
			],
			datalist: [
				{
					download_date: new Date(),
					download_key: '',
					end_date: formData["end_date"],
					start_date: formData["start_date"],
					status: 1,
					user_family_name: user_info.family_name,
					user_first_name: user_info.first_name,
				},
				...prevState.datalist
			]
		}))

		/* */
		this.setState({ modal }, () => {
			let { modal } = this.state;

			Service.createBatchDownload(body).catch(err => {
				console.log("err", err);
				if (err.code === "ERR_NETWORK") {
					return;
				}
				modal.body = () => {
					if (err.response?.data) {
						return (
							<div className="lz-m-20">
								<div className="lz-m-20">
									{t(`common:${err.response.data.message_code}`)}
								</div>
								<div className="lz-m-20">
									{err.response.data.detail}
								</div>
							</div>
						);
					} else {
						return (
							<div className="lz-m-20">
								<div className="lz-m-20">
									Error
								</div>
							</div>
						)
					}
				};
				modal.props.show = true;
				modal.action = this.batchDownloadRetry;
				modal.close = true;

				this.setState({ modal });
			}).finally(() => {
				progessZippingState.open.set(() => false);
			});
		});
	}

	batchDownloadSubmit = () => {
		this.handleCreateBatchDownload()
	}

	batchDownloadRetry = () => {
		let { t } = this.props;

		return [
			(
				<Button
					key="ok"
					sx={{ml: 1}}
					variant="contained"
					onClick={() => {
						let {modal} = this.state;
						modal.body = this.BatchDownloadForm;
						modal.action = this.BatchDownloadModalAction;
						this.setState({modal});
					}}
				>
					<span>{t("common:general.try-again")}</span>
				</Button>
			)
		];
	}
	
	openCreateBatchDownloadModal = () => {
		let { modal } = this.state;

		modal = {
			props: {
				show: true,
				dialogClassName: "batch-download-dialog",
				centered: true,
			},
			title: "common:settings.batch-download.batch-download",
			body: this.BatchDownloadForm,
			action: this.BatchDownloadModalAction,
			form: {
				id: "settings-counter-party-form",
				onSubmit: (ev) => { this.formSubmitHandler(ev, null, this.batchDownloadSubmit) },
				noValidate: true,
			},
			onClose: () => {
				this.setState({
					dateState: [
						{
							startDate: new Date(),
							endDate: new Date(),
							key: 'selection'
						}
					]
				})
			}
		};

		/* Default structure */
		let { keyFields } = this.state;
		let formData = {};
		formData["start_date"] = null;
		formData["end_date"] = null;

		for (let key in keyFields) {
			formData[key] = "";
		}

		this.setState({ formData }, () => this.setStateFields(() => {
			this.setState({ modal, formValidate: false });
		}));
	}

	downloadStatus = (status, is_downloaded) => {
		let { t } = this.props;

		if (is_downloaded === 1) {
			return (
				<div className={"batch-download-status-icon"}>
					<div className={"status-downloaded-label"}>
						{t(`common:settings.batch-download.download-status-downloaded`)}
					</div>
				</div>
			);
		}

		switch (status) {
			case 1:
				return (
          <div className={"batch-download-status-icon"}>
						<div className={"status-processing-label"}>
							{t(`common:settings.batch-download.download-status-processing`)}
						</div>
					</div>
				);
			case 2:
				return (
          <div className={"batch-download-status-icon"}>
						<div className={"status-processing-label"}>
							{t(`common:settings.batch-download.download-status-processing`)}
						</div>
					</div>
				);
			case 3:
				return (
          <div className={"batch-download-status-icon"}>
						<div className={"status-ready-label"}>
							{t(`common:settings.batch-download.download-status-ready`)}
						</div>
					</div>
				);
			case 4:
				return (
          <div className={"batch-download-status-icon"}>
						<div className={"status-error-label"}>
							{t(`common:settings.batch-download.download-status-error`)}
						</div>
					</div>
				);
		}
	}

	IndexTable = () => {
		let { t } = this.props;

		let { datalist, datalistLoading } = this.state;

		if (datalistLoading || datalist === null) {
			return <LoadingList />;
		}

		let rows = [];

		const convertDate = (date) => {
			return formatDateIntlApi(new Date(date), {
				day: "2-digit",
				hour: "2-digit",
				hourCycle: "h23",
				minute: "2-digit",
				month: "2-digit",
				second: "2-digit",
				timeZone: "UTC",
				year: "numeric",
			}) ;
		}

		for (let i = 0; i < datalist.length; i++) {
			let batch = datalist[i];

			let download_date = convertDate(batch['download_date'])
				, start_date = convertDate(batch['start_date'])
				, end_date = convertDate(batch['end_date']);

			rows.push(
				<tr
					className="batch-download-item item document-row-list clickable"
					key={i}
					onClick={() => {
						this.setState({
							showDetail: true,
							batchDownloadDetail: batch,
						})
					}}
				>
					<td>
						<div className="text-ellipsis">
							{`${download_date.year}.${download_date.month}.${download_date.day}`}
						</div>
					</td>

					<td>
						<div className="text-ellipsis">
							{batch['user_family_name']} {batch['user_first_name']}
						</div>
					</td>

					<td>
						<div className="text-ellipsis">
							{`${start_date.year}.${start_date.month}.${start_date.day}`} - {`${end_date.year}.${end_date.month}.${end_date.day}`}
						</div>
					</td>

					<td>
						<div className="text-ellipsis">
							{this.downloadStatus(batch['status'], batch['is_downloaded'])}
						</div>
					</td>
				</tr>
			)

		}

		/* */
		if (rows.length < 1) {
			rows.push(
				<tr key="empty">
					<td colSpan="4">
						<div className="lz-txt-c lz-p-40">
							<span>{t("common:settings.batch-download.download-not-exist")}</span>
						</div>
					</td>
				</tr>
			);
		}

		/* */
		return (
			<ResizableTableWithCache id="datalist" className='batch-download-list ' storageKey={`colWidths_BatchDownload`}>
				<thead>
				<StateFragment state={shedOnScroll}>
					{s => (
						<tr className={`${s.get({noproxy: true}) && "shed"}`}>
						<th className='batch-download-date' id='download-date'>
							<div>{t(`common:settings.batch-download.download-date`)}</div>
						</th>
						<th className='batch-download-user-name' id='user-name'>
							<div>{t(`common:settings.batch-download.user-name`)}</div>
						</th>
						<th className='batch-download-range' id='download-range'>
							<div>{t(`common:settings.batch-download.download-range`)}</div>
						</th>
						<th className='batch-download-status' id='download-status'>
							<div>{t(`common:settings.batch-download.download-status`)}</div>
						</th>
					</tr>
					)}
				</StateFragment>
				</thead>
				<tbody>
					{rows}
				</tbody>
			</ResizableTableWithCache>
		)
	}

	render() {

		/* Search Function */
		let { t } = this.props;

		const user_info = userInfoState.get({noproxy:true});

		/* */
		const { Main } = this;

		/* */
		return (
			<Main>

				<div id="page-settings-batch-download" className="page-settings">
					<Suspense fallback={<></>}>
						<BatchDownloadDetail
							showDetail={this.state.showDetail}
							main={this}
              className="btn-batch-download"
							batchDownloadDetail={this.state.batchDownloadDetail}
						/>
					</Suspense>

					{this.state.showDetail ? null : (
						<>
							<div className="lz-flex stickyHeader">

								<div className="lz-flex-1">

									<div className="lz-m-10">
										<Tooltip
											title={(!this.state.datalistLoading && !this.state.enable_create)
												&& t("common:settings.batch-download.reach-one-month-limit")}
											placement="bottom-start"
										>
											<div className="lz-flex">
												<Button
													sx={{ borderRadius: 19 }}
													title="Action"
													variant="contained"
													className="btn-addGuest"
													startIcon={<AddIcon />}
													disabled={this.state.datalistLoading || !this.state.enable_create}
													onClick={this.openCreateBatchDownloadModal}
												>
													<span>{t("common:settings.batch-download.batch-download")}</span>
												</Button>
											</div>
										</Tooltip>
									</div>
								</div>
								<div className="ki-flex-0">
									<div className="lz-m-10">
										<this.Pagination />
									</div>
								</div>
							</div>

							<div className="body-wrapper-scroll">
								<this.IndexTable />
							</div>


							<div className="lz-flex" hidden>

								<div className="lz-flex-1"></div>

								<div className="lz-flex-0">
									<div className="lz-m-10">
										<this.Pagination />
									</div>
								</div>
							</div>
						</>
					)}
				</div>
			</Main>
		);

	}
}

export default withTranslation()(BatchDownload);
