// @ts-nocheck
import i18n from "i18next";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import EnvParams from "./Config/EnvParams";
import SystemService from "./Service/System";
import { currentLang, errProps } from "./data-access/app.state";

// SystemService.GetLanguageResources('ja');
// SystemService.GetLanguageResources('en');

const loadResources = async (locale) => {
  return SystemService.GetLanguageResources(currentLang.get({ stealth: true }));
  // .then((response) => {
  //     console.log('data', response.data)
  //     return { common: response.data[currentLang.get({ stealth: true })] };
  // })
  // .catch((error) => {
  //     console.log(error);
  // });
};
const userCache = localStorage.getItem("user-info");
const userInfo = JSON.parse(
  userCache && userCache !== "undefined" && userCache !== "null"
    ? userCache || null
    : null
);
if (userCache && userCache !== "undefined" && userCache !== "null") {
  currentLang.set(userInfo.language || "ja");
}
const backendOptions = {
  loadPath: EnvParams.api.domain + EnvParams.api.path,
  request: (options, url, payload, callback) => {
    try {
      // if (i18n.options.ns.length === 1) {
      // //     return i18n.loadNamespaces('common', (err, t) => { /* ... */ });
      // // }
      // if(userInfo?.language)
      if (i18n.options.ns.length === 1) {
        loadResources(userInfo?.language || currentLang.get({ stealth: true }))
          .then((response) => {
            // console.log('response', response)
            callback(null, {
              data: response,
              status: 200,
            });
          })
          .catch((err) => {
            console.error("error này", err);
            if (err.hasOwnProperty("data")) {
              errProps.set({
                msg: err.response.data.message,
                code: err.response.status,
                show: true,
              });
            } else {
              errProps.set({
                msg: "Connection error, please try to reload.",
                code: 400,
                show: true,
              });
            }
          });
      } else {
        callback(null, {
          data: {},
          status: 200,
        });
      }
    } catch (err) {
      console.error("error này", err);
      if (err.hasOwnProperty("data")) {
        errProps.set({
          msg: err.response.data.message,
          code: err.response.status,
          show: true,
        });
      } else {
        errProps.set({
          msg: "Connection error, please try to reload.",
          code: 400,
          show: true,
        });
      }
      callback(null, {
        status: 500,
      });
    }
  },
};

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    backend: backendOptions,
    ns: ["common"],
    defaultNS: "common",
    load: "currentOnly",
    interpolation: { escapeValue: false },
    // debug: process.env.REACT_APP_ENV_FILE !== 'PROD',
    lng: currentLang.get({ stealth: true }) || "ja", // if you're using a language detector, do not define the lng option
    fallbackLng: false,
  });
// i18n.loadNamespaces('common', (err, t) => { /* ... */ });
// loadResources('en').then(r => {
//     i18n.addResourceBundle('en', 'common', r);
// })
// i18n
//     .use(ChainedBackend)
//     .use(initReactI18next)
//     .init({
//         backend: backendOptions,
//         load: "languageOnly",
//         interpolation: { escapeValue: false },
//         debug: true,
//         lng: currentLang.get({ stealth: true }), // if you're using a language detector, do not define the lng option
//         fallbackLng: currentLang.get({ stealth: true }),
//     });
export default i18n;
