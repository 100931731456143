// import React, { Suspense } from 'react';
import { ReactComponent as home } from "./img/icons/home.svg";
import { ReactComponent as signer } from "./img/icons/SiGNER.svg";
import { ReactComponent as sender } from "./img/icons/SeNDER.svg";
import { ReactComponent as verification } from "./img/icons/VeRIFICATION.svg";
import { ReactComponent as filebox } from "./img/icons/FiLEBOX.svg";
import { ReactComponent as circle } from "./img/icons/CiRCLE.svg";
import { ReactComponent as evaluation } from "./img/icons/EVaLUATION.svg";
import { ReactComponent as search } from "./img/icons/search.svg";
import { ReactComponent as notification } from "./img/icons/notification.svg";
import { ReactComponent as help } from "./img/icons/help.svg";
import { ReactComponent as profile } from "./img/icons/profile.svg";
import { ReactComponent as file_pdf } from "./img/icons/file_pdf.svg";
import { ReactComponent as upload_file } from "./img/icons/upload_file.svg";
import { ReactComponent as imprint } from "./img/icons/imprint.svg";
import { ReactComponent as imprint_text } from "./img/icons/imprint-text.svg";
import { ReactComponent as imprint_check } from "./img/icons/imprint-check.svg";
import { ReactComponent as no_avatar } from "./img/icons/no_avatar.svg";
import { ReactComponent as close } from "./img/icons/close_x.svg";
import { ReactComponent as loading } from "./img/icons/loading.svg";
import { ReactComponent as workflow } from "./img/icons/device_hub_black_24dp.svg";

var SVGIcons = {
    home,
    signer,
    sender,
    verification,
    filebox,
    circle,
    evaluation,
    search,
    notification,
    help,
    profile,
    file_pdf,
    upload_file,
    imprint,
    imprint_text,
    imprint_check,
    no_avatar,
    close,
    loading,
    workflow,
};

// function Index() {

//     const requireComponent = require.context('./', false, /\w+\.(svg)$/);

//     icons.files = {};

//     requireComponent.keys().forEach((filePath) => {
//         let keyName = filePath.replace(/\.\/|\.svg/gi, '');
//         keyName = keyName.replace(/-/g, "_").toLowerCase();
//         icons.files[keyName] = filePath;
//     });

//     return icons;
// }

export default SVGIcons;
export {
    home,
    signer,
    sender,
    verification,
    filebox,
    circle,
    evaluation,
    search,
    notification,
    help,
    profile,
    file_pdf,
    upload_file,
    imprint,
    imprint_text,
    imprint_check,
    no_avatar,
    close,
    loading,
};