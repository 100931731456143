// import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Index() {

    const { t } = useTranslation('common');

    return (
        <div className="App">
            <span>{t('welcome.title')}</span>

            <div>
                <ul>
                    <li>
                        <a href="/">{t('menu.home')}</a>
                    </li>
                    <li>
                        <a href="/login">{t('menu.login')}</a>
                    </li>
                    <li>
                        <a href="/dashboard">{t('menu.dashboard')}</a>
                    </li>
                    <li>
                        <a href="/test">{t('menu.test')}</a>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default Index;
