import { withTranslation } from "react-i18next";

import { getSignGuestDocument, getSignHostDocument } from '../../data-access/documents/document.api';
import Layout from '../../Layout';
import Service from '../../Service';
import OtpDialog from '../Auth/OtpDialog';
import Error from '../Error';
import DocumentDetail from './DocumentDetail';

class DocumentSign extends Layout {

	constructor(props) {
		super(props);

		this.state.loading = true;
		this.state.isVerifiedGuest = true;
		this.state.document_id = props.match.params.document_id;
		this.state.token = props.match.params.token;
		this.state.docInfo = null;
		this.state.openOtpDialog = false;
		this.state.verified = true;
		this.state.msgPageError = null;
		this.state.codeErrorStatus = '';
		this.state.isTimeout = false;
	}

	componentDidMount() {
		console.log("DocumentSign->componentDidMount");
		let { document_id, token } = this.state;
		if (document_id) {
			this.GetDocumentInfo(document_id);

		} else if (token) {
			// Service.GetGuestUserInfo(token).then(resp => {
			// 	if (resp.is_applying_guest_mfa) {
			// 		this.setState({ loading: false, verified: true, UserInfo: resp, openOtpDialog: true, isVerifiedGuest: false }, () => { });
			// 	} else {
			// 		this.setState({ UserInfo: resp, verified: true }, () => {
			// 			this.GetGuestDocumentInfo(token);
			// 		});
			// 	}
			// }).catch(err => {
			// 	// console.log(err.response);
			// 	if (err?.code === 'ECONNABORTED' || (err?.response?.status === 504 && err?.response?.data?.message === 'Endpoint request timed out')) {
			// 		this.setState({ isTimeout: true, loading: false, msgPageError: `サーバーが混雑しております。\n
			// 																		しばらく待ってアクセスしてください。` });
			// 		return;
			// 	}
			// 	let msgPageError = "auth.general.expired-token-url";
			// 	if (err?.response?.data?.hasOwnProperty("error")) {
			// 		msgPageError = err.response.data.error;
			// 	}
			// 	this.setState({ verified: false, loading: false, msgPageError });
			// });

			getSignGuestDocument(token).then(resp => {
				this.setState({ loading: false, docInfo: resp.data.payload });
			}).catch(err => {
				console.log(err);
				if (err.response.data.payload && err.response.data.payload.is_applying_guest_mfa) {
					console.log('is_applying_guest_mfa');
					this.setState({ loading: false, verified: true, openOtpDialog: true, isVerifiedGuest: false }, () => {
						// this.GetGuestDocumentInfo(token);
					});
				}
				else {
					let { t } = this.props;
					if (err?.code === 'ECONNABORTED' || (err?.response?.status === 504 && err?.response?.data?.message === 'Endpoint request timed out')) {
						this.setState({ isTimeout: true, loading: false, msgPageError: t('message.error.request.timed_out',  `サーバーが混雑しております。\n しばらく待ってアクセスしてください。`) });
						return;
					}
					let msgPageError = "message.errors.document-not-found";
					if (err?.response?.data?.hasOwnProperty("error")) {
						msgPageError = err.response.data.error;
					} else if (err.response.data.hasOwnProperty("message")) {
						msgPageError = err.response.data.message;
					}
					// let { t } = this.props;
					if (err?.code === 'ECONNABORTED' || (err?.response?.status === 504 && err?.response?.data?.message === 'Endpoint request timed out')) {
						msgPageError = t('message.error.request.timed_out',  `サーバーが混雑しております。\n しばらく待ってアクセスしてください。`) ;
						// return;
					}
					if(err?.response?.status === 401) {
						msgPageError = t('auth.general.expired-token-url')
						this.setState({
							isTokenExpired: true,
							isTokenExpiredDate: err?.response?.data?.payload?.expired_date,
						})
					}
					this.setState({ loading: false, msgPageError });
				}
				this.setState({
					codeErrorStatus: err?.response?.status || err?.code || ""
				})
			});
		}
	}

	GetDocumentInfo = (document_id) => {
        // console.log('o21412412414k', this.props)
		getSignHostDocument(document_id).then(resp => {
            console.log('resp document sign', resp);
			this.setState({ loading: false, docInfo: resp.data.payload }, () => {
				// Service.GetUserInfo().then(resp => {
				// 	this.setState({ UserInfo: resp }, () => {
				// 	});
				// }).catch((error) => {
				// 	//console.log("error", error.response);
				// });
			});
		}).catch(err => {
			console.log(err.response);
			let {t} = this.props;
			let msgPageError = "message.errors.document-not-found";
			if (err?.response?.data?.hasOwnProperty("error")) {
				msgPageError = err.response.data.error;
			} else if (err.response.data.hasOwnProperty("message")) {
				msgPageError = err.response.data.message;
			}
			if (err?.code === 'ECONNABORTED' || (err?.response?.status === 504 && err?.response?.data?.message === 'Endpoint request timed out')) {
				msgPageError = t('message.error.request.timed_out',  `サーバーが混雑しております。\n しばらく待ってアクセスしてください。`) ;
				// return;
			}
			this.setState({ loading: false, msgPageError });
		});
	}

	GetGuestDocumentInfo = (token) => {
		getSignGuestDocument(token).then(resp => {
			this.setState({ loading: false, docInfo: resp.data.payload });
		}).catch(err => {
			console.log(err.response);
			let msgPageError = "message.errors.document-not-found";
			if (err?.response?.data?.hasOwnProperty("error")) {
				msgPageError = err.response.data.error;
			} else if (err.response.data.hasOwnProperty("message")) {
				msgPageError = err.response.data.message;
			}
			let { t } = this.props;
			if (err?.code === 'ECONNABORTED' || (err?.response?.status === 504 && err?.response?.data?.message === 'Endpoint request timed out')) {
				msgPageError = t('message.error.request.timed_out',  `サーバーが混雑しております。\n しばらく待ってアクセスしてください。`) ;
				// return;
			}
			this.setState({ loading: false, msgPageError });
		});
	}

	BodyWrapper = (props) => {
		// let { t } = this.props;
		if (!props.docInfo) {
			return null;
		}
		return (
			<div className={"document-detail static is-sign"}>
				{props.docInfo && <DocumentDetail {...props} />}
			</div>
		);
	}

	Nav = (props) => {
		return null;
	}

	handleConfirmOtpGuest(isDocumentVerifyEmail, resp) {
		if (!isDocumentVerifyEmail) {
			return this.setState({
				// openOtpDialog: true,
				isVerifiedGuest: false,
			});
		}
		// let token = this.state.token;
		this.setState({ loading: false, docInfo: resp.data.payload, isVerifiedGuest: true, openOtpDialog: false });

	}

	onResendUserMail() {
		// Service.GetGuestUserInfo(this.state.token).bind(this);
		getSignGuestDocument(this.state.token).bind(this);
	}

	render() {
		let { t } = this.props;
		let { docInfo, token, isTimeout, loading, verified, isVerifiedGuest, openOtpDialog, msgPageError, isTokenExpired, isTokenExpiredDate } = this.state;
		let { MainLoading, Main, BodyWrapper } = this;

		if (loading) {
			return <MainLoading />;
		}

		if (isTokenExpired === true) {
			return (
				<Error
					code={'guest_sign_expiration'}
					codeStatus={this.state.codeErrorStatus}
					msg={t(`common:${msgPageError}`)}
					isCustom={false}
					layout={false}
					token={token}
					reissueButton={isTokenExpiredDate}
				/>
			)
		}

		if (!isVerifiedGuest) {
			return <OtpDialog resendEmailCode={this.onResendUserMail.bind(this)} tokenGuest={token}
				openOtpDialog={openOtpDialog} isVerifyGuestDocument={true} mfaChallengeName={''}
				mfaUser={''} callback={this.handleConfirmOtpGuest.bind(this)} />;
		}
		if (isTimeout) {
			return <Error code={''} msg={t(`common:${msgPageError || 'message.error.server_unknown_error'}`)} layout={!token} />;
		}

		if (!verified) {
			return <Error code={404} msg={t(`common:${msgPageError || 'message.error.server_unknown_error'}`)} layout={!token} />;
		}

		if (!docInfo) {
			return <Error code={404}
				msg={t('common:general.error-404.signed-in-with-another-account')}
				isCustom={true} layout={true} />
		}
		if (!docInfo) {
			return <Error code={404}
			msg={t('common:general.error-404.signed-in-with-another-account')}
			isCustom={true} layout={true} />
		}

		return (
			<>
				<div className="d-sm-block">
					<Main>
						<BodyWrapper docInfo={docInfo} token={token} />
					</Main>
				</div>
				<div className="main contrainer-wrapper d-block d-sm-none">
					<div className="layout">
						<BodyWrapper docInfo={docInfo} token={token} />
					</div>
				</div>
			</>
		);

	}

}

export default withTranslation()(DocumentSign);
