/* eslint-disable no-undef */
// @ts-nocheck
import { hookstate } from '@hookstate/core';
import { pdfjs } from 'react-pdf';
import { Subject, takeUntil } from 'rxjs';
import CryptoJS from 'crypto-js';
export const processedFiles = hookstate({});
export const newSubjectProcess = new Subject();
export const destroyCompress$ = new Subject();
export const pdfCompressSubject = newSubjectProcess.asObservable().pipe(takeUntil(destroyCompress$))
export default async function getFileHash(file,
    {
        // CryptoJS = null,
        cbProgress = null,
        algo = "MD5",
        encoding = "Base64url",
        chunkSize = 1 * 1024 * 1024
    } = {}) {

    if (!CryptoJS) {
        return Promise.reject("crypto-js module not provided")
    }
    if (!CryptoJS.algo.hasOwnProperty(algo)) {
        return Promise.reject("Given hash algo not supported")
    }
    if (!["Base64", "Base64url", "Hex"].includes(encoding)) {
        return Promise.reject(
            `Given hash encoding not supported. Supported encodings are "Base64", "Base64url", "Hex"`
        )
    }

    return new Promise((resolve, reject) => {
        var hashObj = CryptoJS.algo[algo].create()
        var fileSize = file.size;
        var offset = 0;

        var reader = new FileReader();
        reader.onload = function () {
            if (reader.error) {
                return reject(reader.error);
            }
            hashObj.update(CryptoJS.enc.Latin1.parse(reader.result));

            offset += reader.result.length;
            if (cbProgress) {
                cbProgress(offset / fileSize);
            }

            if (offset >= fileSize) {
                var hash = hashObj.finalize();
                var hashHex = hash.toString();
                return resolve(hashHex);
            }
            readNext();
        };

        reader.onerror = function (err) {
            reject(err);
        };

        function readNext() {
            var fileSlice = file.slice(offset, offset + chunkSize);
            reader.readAsBinaryString(fileSlice);
        }
        readNext();
    });
}