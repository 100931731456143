import React, {useEffect} from "react";


export default function GetStatusInterval(
    props,
) {
    const {
        onGetStatus,
        timeout
    } = props;

    useEffect(() => {
        // This effect will run when the component mounts
        const interval = setInterval(() => {
            // Update the count every 1000ms (1 second)
            onGetStatus()
        }, timeout);

        // Clear the interval when the component unmounts or changes
        return () => clearInterval(interval);
    }, []); // Passing an empty dependency array ensures this effect runs only once, like componentDidMount


    return (
        <></>
    );

}

GetStatusInterval.defaultProps = {

};
