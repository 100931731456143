import React, {createRef} from 'react';
import {withTranslation} from "react-i18next";
import {pdfjs} from 'react-pdf';
import Service from '../../../Service';
import {file_pdf as IconPDFFile} from '../../Assets/SVGIcons';
import {jsPDF} from "jspdf";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import '../../Assets/css/pdf-preview.css';
import FileDragDrop from "../FileDragDrop/FileDragDrop";
import getFileHash from '../../../Utils/compress-pdf';
import {noDataState} from '../VerificationOCR/OcrFields';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
// Create our observer
class VerificationFileUploadComponent extends React.Component {
    lastClientY = null;
    lastClientX = null;
    constructor(props) {
        super(props);
        this.containerRef = createRef();
        this.state = {
            objMain: props.objMain,
            // active: true,
            draggingPreview: false,
            showAll: props.showAll || false,
            doc_id: props.doc_id || null,
            docInfo: props.docInfo || null,
            hash: props.hash || null,
            scale: props.scale || 1,
            fileLength: 0,
            loadedPDF: [],
            fileListHash: [],
            PDFFile: props.file || {},
            PDFFiles: props.files || {},
            showLoadingPDFSelect: false,
            PDFLoadSuccess: false,
            noFile: false,
            multiple: props.multiple || false,
            allowUpload: props.upload || false,
            autoFit: props.fit || false,
            controller: props.controller || false,
            infoPanel: props.info || false,
            listPanel: props.list || false,
            fileChangeCallback: props.fileChangeCB,
            fileRemoveCallback: props.fileRemoveCB,
            fullPreview: props.full || false,
            showFullPreview: false,
            modalPassword: false,
            tempPassword: null,
            isFileLoading: props.isFileLoading,
            incorrectPassword: "",
            multipleFilesQueue: [],
            updateInfo: props.updateInfo,
            filesSort: props.filesSort,
            pagesRendered: 0,
            ocrFound: {},
            isCsvVerification: props.isCsvVerification || false,
            preShowFiles: {},
        }
        this.state.showLoadingPDFLoad = false;

        if (!props.files && props.file) {
            let file = this.state.PDFFile;
            this.state.PDFFiles[file.hash] = file;
        }

        if (props.filesSort) {
            this.state.filesSort = props.filesSort;
        }

        this.pdfDocOptions = {
            cMapUrl: `https://cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`,
            cMapPacked: true,
        };
        // this.state.observer = initObserver(currentRangePage);
        this.handleChangeFileOrder = props.handleChangeFileOrder;
        this.csvVerificationListComponent = props.csvVerificationListComponent;
    }


    componentWillUnmount() {
        window.removeEventListener('mouseup', this.mouseUpHandle.bind(this));
        window.removeEventListener('mousemove', this.mouseMoveHandle.bind(this));
    }
    mouseUpHandle(e) {
        if (this.state.dragging) {
            //   this.state.dragging = false;
            this.setState({dragging: false});
        }
    }

    mouseDownHandle(e) {
        // console.log(e)
        if (!this.state.dragging) {
            //   this.state.dragging = true;
            this.setState({dragging: true});
            //   this.setState(this.state);
            this.lastClientX = e.clientX;
            this.lastClientY = e.clientY;
            e.preventDefault();
        }
    }

    mouseMoveHandle(e) {
        if (this.state.dragging) {
            // console.log(this.containerRef)
            this.containerRef.current.scrollLeft -=
                (-this.lastClientX + (this.lastClientX = e.clientX));
            this.containerRef.current.scrollTop -=
                (-this.lastClientY + (this.lastClientY = e.clientY));
        }
    }

    static getDerivedStateFromProps(props, state) {
        // console.log(props);
        // console.log(state);
        if (state.hash !== props.hash) {
            state.hash = props.hash;
            state.doc_id = props.doc_id || null;
            state.docInfo = props.docInfo || null;
            state.scale = props.scale || 1;
            state.PDFFile = props.file || {};
            // state.PDFFile.password = props.password || null;
            // console.log("getDerivedStateFromProps", state.PDFFile.initialDone);
            state.PDFFile.initialDone = false;
            state.PDFLoadSuccess = false;
        }

        if (state.filesSort !== props.filesSort) {
            state.filesSort = props.filesSort;
        }

        return { state };
    }

    componentDidMount() {
        let { doc_id, hash, PDFFile, PDFFiles, filesSort } = this.state;

        window.addEventListener('mouseup', this.mouseUpHandle.bind(this));
        window.addEventListener('mousemove', this.mouseMoveHandle.bind(this));
        if (!PDFFile.file) {
            if (Object.values(PDFFiles).length > 0) {
                if (filesSort !== null && filesSort?.length > 0) {
                    PDFFile = PDFFiles[filesSort[0]];
                } else {
                    PDFFile = Object.values(PDFFiles)[0];
                }
                if (PDFFile.url) {
                    PDFFile.file = PDFFile.url;
                }
                PDFFile.initialDone = false;
                this.setState({ PDFFile });
            } else if (doc_id && hash) {
                Service.getDocumentFile(doc_id, hash).then(resp => {
                    // console.log(resp);
                    // SelectedFile = "9a192d8b1a7dc652a19835f6f08098bd";
                    PDFFile.file = resp;
                    PDFFile.initialDone = false;
                    this.setState({ PDFFile });
                }).catch(err => {
                    //console.log(err.resonse);
                });
            } else {
                this.setState({ noFile: true });
            }
        } else if (PDFFile.url) {
            PDFFile.file = PDFFile.url;
            PDFFile.initialDone = false;
            this.setState({ PDFFile });
        }
    }

    attachmentExtensionBlock = () => {
		return [
			".ade", ".adp", ".app", ".asp", ".bas", ".bat", ".bz", ".bz2", ".cer", ".chm",
			".class", ".cmd", ".com", ".command", ".cpl", ".crt", ".csh", ".exe", ".fxp", ".gz", 
            ".hex", ".hlp", ".hqx", ".hta", ".inf", ".ini", ".ins", ".isp", ".its", ".jar", 
            ".job", ".js", ".jse", ".ksh", ".lnk", ".lzh", ".mad", ".maf", ".mag", ".mam", 
            ".maq", ".mar", ".mas", ".mat", ".mau", ".mav", ".maw", ".mda", ".mde", ".mdt", 
            ".mdw", ".mdz", ".msc", ".msi", ".msp", ".mst", ".ocx", ".ops", ".pcd", ".pkg", 
            ".pif", ".prf", ".prg", ".pst", ".rar", ".reg", ".scf", ".scr", ".sct", ".sea", 
            ".shb", ".shs", ".sit", ".tar", ".tgz", ".tmp", ".url", ".vb", ".vbe", ".vbs", 
            ".vsmacros", ".vss", ".vst", ".vsw", ".webloc", ".ws", ".wsc", ".wsf", ".wsh", ".zip",
            ".zlo", ".zoo"
		];
	}

    displayModal = (title, body) => {
        const { modal } = this.state.objMain.state;
        modal.props = { show: true, centered: true };
        modal.title = title;
        modal.body = body;
        modal.close = true;
        this.state.objMain.setState({ modal });
    };

    genLoadingFile = async (file) => {
        const fileKey = await getFileHash(file);
        
        const PDFFile = {
            hash: fileKey,
            file: file,
            name: file.name,
            type: file.type,
            size: file.size,
            lastModified: file.lastModified,
            lastModifiedDate: file.lastModifiedDate,
        };
        this.setState(prevState => ({
            preShowFiles: {
                ...prevState.preShowFiles, 
                [fileKey]: PDFFile
            },
        }));

        return fileKey
    }

    preparePdfFiles = async (inputFiles) => {
        let { t } = this.props;
        try {
            let files = [];
            const allowedImageTypes = ['.png', '.jpeg', '.gif', '.jpg'];
            let imageFiles = [];
            let filesToEmbed = [];
            
            for (let file of inputFiles) {
                const fileExtension = file.name.includes('.') ? '.' + file.name.split('.').pop().toLowerCase() : '';
    
                if (this.attachmentExtensionBlock().includes(fileExtension)) {
                    this.displayModal(
                        t(`common:documents.verification.title.ivalid_file_extension`), 
                        t(`common:documents.verification.body.ivalid_file_extension`)
                    );
                    return [];
                }
    
                if (fileExtension === '.pdf') {
                    files.push(file);
                } else {
                    const fileKey = await this.genLoadingFile(file);
                    file.originalKey = fileKey;
                    if (allowedImageTypes.includes(fileExtension)) {
                        imageFiles.push(file);
                    } else {
                        filesToEmbed.push(file);
                    }
                }
            }
    
            const totalRequests = imageFiles.length * 2 + filesToEmbed.length;
            console.log('totalRequests', totalRequests);
            
            for (let i = 0; i < imageFiles.length; i++) {
                const file = imageFiles[i];
                const formDataFile = new FormData();
                formDataFile.append('file', file, file.name);
    
                const response = await Service.convertFileToPdf(formDataFile);
    
                const fileName = file.name.slice(0, file.name.lastIndexOf(".")) + ".pdf";
                const url = response?.payload?.url;
                const temp = response?.payload?.temp;
                const responseBlob = await Service.getDocumentBlob(url);
                const pdfFile = new File([responseBlob], fileName, { type: 'application/pdf' });
                pdfFile.originalName = file.name
                pdfFile.temp = temp;
    
                files.push(pdfFile);
            }
            
            if (filesToEmbed.length > 0) {
                const payload = filesToEmbed.map(file => ({ key: file.originalKey, name: file.name }));
                const responses = await Service.embedFileToPdf({files: payload});
                
                for (let i = 0; i < filesToEmbed.length; i++) {
                    const file = filesToEmbed[i];
                    const response = responses.payload.find(res => res.key === file.originalKey);

                    if (response) {
                        const fileName = file.name.slice(0, file.name.lastIndexOf(".")) + ".pdf";
                        const url = response.url;
                        const temp = response.temp;
                        const responseBlob = await Service.getDocumentBlob(url);
                        const pdfFile = new File([responseBlob], fileName, { type: 'application/pdf' });
                        pdfFile.originalName = file.name
                        pdfFile.temp = temp;
                        pdfFile.attachments = [file]
                        pdfFile.regenerate_cover = true

                        files.push(pdfFile);
                    }
                }
            }
    
            return files;
        } catch (error) {
            console.error("Prepare files error:", error);
            this.displayModal(
                t(`common:documents.verification.title.embed_file_error`),
                t(`common:documents.verification.body.embed_file_error`)
            );
    
            return [];
        } finally {
            this.setState({ preShowFiles: {}});
        }
    }

    RegisterFiles = (files, fnCallback) => {
        let { multiple, fileLength, PDFFiles, fileListHash, loadedPDF } = this.state;
        this.state.objMain.setState({
            formData: {...this.state.objMain?.state.formData, invoice_number: ''},
        });

        fileLength = files.length + Object.keys(PDFFiles).length;
        loadedPDF = Object.keys(PDFFiles);

        fileListHash = Object.keys(PDFFiles);
        this.setState({fileLength})
        for (let file of files) {
            getFileHash(file).then(async (key) => {
                const fileKey = file.name + key 
                this.setState({showLoadingPDFSelect: false});
                // console.log(fileKey);
                let { suggestionData,isSuggestionLoading,formData } = this.state.objMain?.state;
                let { PDFFiles, PDFFile, multipleFilesQueue,  } = this.state;
                if (!multiple) {
                    PDFFiles = {};
                }
                if (!PDFFiles.hasOwnProperty(fileKey)) {
                    PDFFiles[fileKey] = {
                        hash: fileKey,
                        file: file,
                        name: this.IsExistExtension(file.name) ? file.originalName : file.name,
                        originalName: file.originalName,
                        type: file.type,
                        size: file.size,
                        lastModified: file.lastModified,
                        lastModifiedDate: file.lastModifiedDate,
                        fileNameExist: true,
                        temp: file.temp,
                        regenerate_cover: file.regenerate_cover,
                    };
                }
                multipleFilesQueue.push(fileKey);
                fileListHash.push(fileKey);
                PDFFile = PDFFiles[fileKey];
                if(fileListHash.length < fileLength){
                    this.setState({showLoadingPDFSelect: true});
                    this.state.objMain.setState({isFileLoading: true});
                }

                if(fileListHash.length === fileLength){
                    this.setState({showLoadingPDFSelect: false});
                }
                if(fileListHash.length === fileLength){
                    loadedPDF.push(fileKey);
                }
                if (file.attachments) {
                    const attachments = [];
                    for (const attachment of file.attachments) {
                        const fileKey = await getFileHash(attachment)
                        attachments.push({
                            hash: fileKey,
                            file: attachment,
                            name: attachment.name,
                            type: attachment.type,
                            size: attachment.size,
                            temp: attachment.temp,
                            lastModified: attachment.lastModified,
                            lastModifiedDate: attachment.lastModifiedDate,
                        })
                    }
                    PDFFiles[fileKey].attachments = attachments;
                }
                this.state.objMain.setState({PDFFiles});
                this.setState({
                    PDFFiles,
                    PDFFile,
                    noFile: false,
                    fileListHash,
                    loadedPDF
                }, async () => {
                    if (this.props.fileChangeCB && typeof this.props.fileChangeCB === "function") {
                        this.props.fileChangeCB(this.state.PDFFiles);
                    }
                });

                document.getElementById('pdf-verification-upload').value = '';
            });
            if (!multiple) {
                break;
            }
        }

        document.getElementById('pdf-verification-upload').value = '';

    }
    IsFileNameExists = (files, fileName) => {
        let check = false
        Object.keys(files).forEach((key) => {
            let file = files[key];
            const existingFileName = file.name;
            const existingFileNameWithoutExt = existingFileName.split('.').slice(0, -1).join('.');
            const fileNameWithoutExt = fileName.split('.').slice(0, -1).join('.');
            console.log('existingFileNameWithoutExt', existingFileNameWithoutExt, fileNameWithoutExt);
            if (existingFileNameWithoutExt === fileNameWithoutExt) {
                check = true;
            }
        })
        return check;
    }
    IsFileNameExistsWithExt = (files, fileName) => {
        let check = false
        Object.keys(files).forEach((key) => {
            let file = files[key];
            if (file.name === fileName) {
                check = true;
            }
        })
        return check;
    }

    IsExistExtension = (fileName) => {
        let check = false;
        this.csvVerificationListComponent.map((item) => {
            const isAnyExtFile = /\.[^.]+$/.test(item.pdf_name);
            if(!isAnyExtFile){
                const fileNameWithoutExt = fileName.substring(0, fileName.lastIndexOf("."));
                if (item.pdf_name === fileNameWithoutExt) check = true
            }
        })
        return check
    }

    CheckIsExistFileNameInList = (fileName) => {
        let check = false;
        this.csvVerificationListComponent.map((item) => {
            const isAnyExtFile = /\.[^.]+$/.test(item.pdf_name);
            if(!isAnyExtFile){
                const fileNameWithoutExt = fileName.substring(0, fileName.lastIndexOf("."));
                if (item.pdf_name === fileNameWithoutExt) check = true
            }else{
                if (item.pdf_name === fileName) check = true
            }
        })
        return check
    }
    

    InputFileUpload = () => {
        let { multiple} = this.state;        

        let { creationMode, creationType } = this.props;
        let extensionsAccept = undefined;

        if(creationMode === 'batch' && creationType === 'sender') extensionsAccept = '.pdf, .PDF'
        

        return (
            <input type="file" name="pdf-verification-upload" id="pdf-verification-upload" accept={extensionsAccept}
                   multiple={multiple}
                   style={{ display: "none" }}
                   onChange={async (ev) => {
                       if(ev.target?.files?.length > 500){
                           let { t } = this.props;
                           let { modal } = this.state.objMain.state;
                           modal.props = {
                               show: true,
                               dialogClassName: "loading-dialog",
                               centered: true,
                           };
                           modal.title = t(`common:documents.verification.title.excess_file_error`);
                           modal.body = t(`common:documents.verification.body.excess_file_error`);
                           modal.close = true;

                           this.state.objMain.setState({ modal });
                           return
                       }
                        if( ev.target.files?.length > 0){
                            if(creationMode === 'batch' && creationType === 'sender'){
                                let checkPDF = true;
                                let { t } = this.props;
                                let { modal } = this.state.objMain.state;
                                for (let file of ev.target.files){
                                    if(file.type != 'application/pdf') checkPDF = false;
                                }
                                if(!checkPDF){
                                    modal.props = {
                                        show: true,
                                        dialogClassName: "loading-dialog",
                                        centered: true,
                                    };
                                    modal.title = t(`common:documents.sender.title.accept_file_error`);
                                    modal.body = t(`common:documents.sender.body.accept_file_error`);
                                    modal.close = true;
                                    this.state.objMain.setState({ modal });
                                    return
                                }
                            }
                          for (let file of ev.target.files) {
                            file.originalName = String(file.name)
                            if(this.CheckIsExistFileNameInList(file.name)){
                                if(
                                    (this.IsExistExtension(file.name) && this.IsFileNameExists(this.state.PDFFiles, file.name)) || 
                                    (!this.IsExistExtension(file.name) && this.IsFileNameExistsWithExt(this.state.PDFFiles, file.name))){
                                    this.state.objMain.setState({ 
                                        showAlertDuplicateUpload: true
                                    })
                                }
                            }else{
                                let { t } = this.props;
                                let { modal } = this.state.objMain.state;
                                modal.props = {
                                    show: true,
                                    dialogClassName: "loading-dialog",
                                    centered: true,
                                };
                                modal.title = t(`common:documents.verification.title.file_name_dose_not_match`);
                                modal.body = t(`common:documents.verification.body.file_name_dose_not_match`);
                                modal.close = true;
     
                                this.state.objMain.setState({ modal });
                                return
                            }
                          }
                           // return
                       }

                       

                        if(this.state?.objMain?.state?.activePage?.name === "VerificationUpload") {
                            this.setState({showLoadingPDFSelect: true, isFileLoading: true}, () => {
                                if( ev.target.files?.length){
                                    this.preparePdfFiles(ev.target.files).then((files) => {
                                        console.log("==> InputFileUpload", {files});
                                        this.RegisterFiles(files);
                                    });
                                }
                            });
                        }
                        
                        this.state.objMain.setState({isFileLoading: true}, () => {
                            if( ev.target.files?.length){
                                this.preparePdfFiles(ev.target.files).then((files) => {
                                    console.log("==> InputFileUpload", {files});
                                    this.RegisterFiles(files);
                                });
                            }
                        });
                        
                   }}
            />
        );
    }

    FileUploadDropZone = (props) => {
        let { allowUpload } = this.state;
        let { InputFileUpload } = this;
        let { creationMode, creationType } = this.props;

        if (!allowUpload) {
            return null;
        }

        let className = `drop-zone-panel ${props.className || ""}`;

        return (
            <div className={className}>

                <label
                    htmlFor="pdf-verification-upload"
                   className="dropbox-area file-dropbox"
                   onDragOver={(ev) => {
                       ev.preventDefault();
                   }}
                   onDrop={async (ev) => {
                        ev.preventDefault();
                        let files = [];

                        if(ev.dataTransfer.files?.length > 500){
                            let { t } = this.props;
                            let { modal } = this.state.objMain.state;
                            modal.props = {
                                show: true,
                                dialogClassName: "loading-dialog",
                                centered: true,
                            };
                            modal.title = t(`common:documents.verification.title.excess_file_error`);
                            modal.body = t(`common:documents.verification.body.excess_file_error`);
                            modal.close = true;

                            this.state.objMain.setState({ modal });
                            return
                        }
                        if( ev.dataTransfer.files?.length > 0){
                            if(creationMode === 'batch' && creationType === 'sender'){
                                let checkPDF = true;
                                let { t } = this.props;
                                let { modal } = this.state.objMain.state;
                                for (let file of ev.dataTransfer.files){
                                    if(file.type != 'application/pdf') checkPDF = false;
                                }
                                if(!checkPDF){
                                    modal.props = {
                                        show: true,
                                        dialogClassName: "loading-dialog",
                                        centered: true,
                                    };
                                    modal.title = t(`common:documents.sender.title.accept_file_error`);
                                    modal.body = t(`common:documents.sender.body.accept_file_error`);
                                    modal.close = true;
                                    this.state.objMain.setState({ modal });
                                    return
                                }
                            }
                            for (let file of ev.dataTransfer.files) {
                                file.originalName = String(file.name)
                                if(this.CheckIsExistFileNameInList(file.name)){
                                    if(
                                        (this.IsExistExtension(file.name) && this.IsFileNameExists(this.state.PDFFiles, file.name)) || 
                                        (!this.IsExistExtension(file.name) && this.IsFileNameExistsWithExt(this.state.PDFFiles, file.name))){
                                        this.state.objMain.setState({ 
                                            showAlertDuplicateUpload: true
                                        })
                                    }
                                }else{
                                    let { t } = this.props;
                                    let { modal } = this.state.objMain.state;
                                    modal.props = {
                                        show: true,
                                        dialogClassName: "loading-dialog",
                                        centered: true,
                                    };
                                    modal.title = t(`common:documents.verification.title.file_name_dose_not_match`);
                                    modal.body = t(`common:documents.verification.body.file_name_dose_not_match`);
                                    modal.close = true;
            
                                    this.state.objMain.setState({ modal });
                                    return
                                }
                            }
                            // return
                        }

                        

                        if(this.state?.objMain?.state?.activePage?.name === "VerificationUpload") {
                            this.setState({showLoadingPDFSelect: true, isFileLoading: true}, () => {
                                if(ev.dataTransfer.files?.length){
                                    this.preparePdfFiles(ev.dataTransfer.files).then((files) => {
                                        console.log("==> preparePdfFiles", {files});
                                        this.RegisterFiles(files);
                                    });
                                }
                            });
                        }
                        this.state.objMain.setState({isFileLoading: true}, () => {
                            if(ev.dataTransfer.files?.length){
                                this.preparePdfFiles(ev.dataTransfer.files).then((files) => {
                                    console.log("==> preparePdfFiles", {files});
                                    this.RegisterFiles(files);
                                });
                            }
                        });
                        
                   }}
                   onDragLeave={(ev) => {
                       ev.preventDefault();
                    //    let body = ev.target.closest(".pdf-fragment");
                    //    body.classList.remove("drag-over");
                    //    let dropPanel = body.querySelector(".file-upload-panel");
                    //    dropPanel.classList.remove("allow-drop");
                   }}
                >
                    <div className="drop-area-inner">
                        <IconPDFFile />
                    </div>
                    <InputFileUpload />
                </label>
                <label className="info">PDFをアップロード</label>

            </div>
        );
    }

    FileListRemove = (props) => {
        // let { key } = props;
        let { allowUpload } = this.state;

        if (!allowUpload) {
            return null;
        }

        return (
            <button
                type="button"
                className="btn-icon file-remove"
                onClick={(ev) => {
                    let {PDFFiles, PDFFile, noFile, fileChangeCallback, fileRemoveCallback} = this.state;
                    let key = ev.target.closest(".file-item").dataset.file;

                    if (this.props.fileRemoveCB && typeof this.props.fileRemoveCB === "function") {
                        this.props.fileRemoveCB(PDFFiles[key]);
                    }

                    if (PDFFiles[key]) delete PDFFiles[key];

                    this.setState({PDFFiles}, () => {

                    });
                }}
            >
                <CancelOutlinedIcon name="delete"/>
            </button>
        );
    }

    FileListSelector = () => {
        let { PDFFiles, PDFFile, listPanel, updateInfo, filesSort,objMain, preShowFiles } = this.state;
        let { FileListRemove } = this;
        PDFFiles = {...PDFFiles, ...preShowFiles};
        let fileKeys = Object.keys(PDFFiles);

        if (filesSort?.length === 0 || !filesSort) {
            filesSort = Object.keys(PDFFiles);
        }

        return (
            <div className="file-upload-list">
                <FileDragDrop
                    fileOrder={filesSort}
                    isDraggable={updateInfo}
                    documentSection={"DocumentDetail"}
                    pdfFileObject={PDFFiles}
                    onChangeObject={(keyArray) => {
                        this.setState({
                            filesSort: keyArray,
                        }, () => {
                            if (this.handleChangeFileOrder) {
                                this.handleChangeFileOrder(keyArray);
                            }
                        })
                    }}
                    selectedFile={PDFFile?.hash}
                    FileListRemove={FileListRemove}
                    isDisabled={objMain?.state?.isFileLoading}
                    onClickDocumentDetail={objMain?.state?.isFileLoading ? undefined:(ev, key) => {                        
                        let {PDFFiles, PDFFile, fileChangeCallback} = this.state;
                        if (key !== PDFFile?.hash) {
                            PDFFile = PDFFiles[key];
                            PDFFile.initialDone = false;
                            // console.log(PDFFile)
                            if (PDFFile.url) {
                                PDFFile.file = PDFFile.url;
                            }
                            this.setState({PDFFile, showLoadingPDFLoad: true}, () => {
                                if (fileChangeCallback && typeof fileChangeCallback === "function") {
                                    fileChangeCallback(PDFFile.hash);
                                }
                            });
                        }
                    }}
                />
            </div>
        );
    }

    allowDropHandler = (ev) => {
        let { allowUpload } = this.state;

        if (allowUpload) {
            let body = ev.target.closest(".pdf-fragment");
            body.classList.add("drag-over");
            let dropPanel = body.querySelector(".file-upload-panel");
            dropPanel.classList.add("allow-drop");
        }
    };

    render() {
        let { t } = this.props;
        let {
            fullPreview,
        } = this.state;
        let {
            FileUploadDropZone,
            FileListSelector
        } = this;

        let pdfMainProps = {};
        if (!fullPreview) {
            pdfMainProps.id = "pdf_main_preview";
        }

        return (
            <div
                key="main-preview"
                {...pdfMainProps}
                className={`pdf-fragment pdf-full-preview verification-file-upload-component`}
                onDragEnter={this.allowDropHandler}
            >
                <div className={`pdf-info-section file-panel`}>
                    <FileUploadDropZone className="file-drop-zone"/>
                    <FileListSelector/>
                </div>
            </div>
        );
    }
}

export default withTranslation()(VerificationFileUploadComponent);
