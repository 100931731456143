// @ts-nocheck

import EnvParams from "../../Config/EnvParams";
import { userInfoState } from "../data-access/state";

/**
 * @param {{ parent_id: any; parent: string | number; id: number; type: number; notShowDropDownIcon: boolean; droppable: boolean; draggable: boolean; fileType: string; drive_folder_id: any; }} a
 */
export default function mappedDataToTreeView(a, fromCompany, mapCompany) {
    if (!a.parent_id && a.parent !== 0) {
        a.parent = "drive";
    } else {
        // console.log(a.parent, a.parent_id)
        if (a.parent !== 0) a.parent = a.parent_id;
    }
    if (a.type === 6) {
        a.name = userInfoState?.get()?.tenant_name || a.name;
    }
    if (a.id !== -1) {
        switch (a.type) {
            case 1:
            case 2:
            case 3:
            case 4:
            case 5: //complete
                if (a.type !== 5) {
                    a.notShowDropDownIcon = true;
                }
                a.droppable = false;
                a.draggable = false;
                break;
            case 7: //private
                a.droppable = true;
                a.draggable = false;
                break;
            case 6: //company
            case 9: //company
                a.droppable = true;
                if (a.type === 9) { a.draggable = true; }
                else {
                    a.draggable = false;
                }
                // a.id = 'company';
                if (fromCompany === true) {
                    a.parent = 'company';
                    a.parent_id = 'company';
                };
                break;
            default:
                a.droppable = true;
                a.draggable = true;
                break;
        }
    }
    a.fileType = a?.extension;
    if (!a.id) a.id = a.drive_folder_id;
    if (a?.is_empty === 1) {
        a.notShowDropDownIcon = 1;
    } else {
        a.is_empty = 0;
        a.notShowDropDownIcon = 0;
    }

    if (a.format_type === 'trash') {
        a.fileType = "trash";
        a.format_type = 'trash';
        return {
            drive_folder_id: 'trash',
            "parent_id": null,
            name: "common:drive.tree_nav.trash",
            "title": "",
            type: 11,
            "updated_date": "",
            "parent": "drive",
            extension: 'folder',
            droppable: false,
            draggable: false,
            fileType: "trash",
            id: 'trash',
            "format_type": "trash",
            notShowDropDownIcon: true
        };
    }
    if (a.fileType !== 'folder') {

        a.format_type = mappedFormatType(a.fileType);
    } else {
        a.format_type = 'folder';
    }
    if (mapCompany === true) {
        if (!a.id)
            a.id = a.document_id;
        // if (!a.url && a.document_id) {
        //     let aSplit = a.name.split('.');
        //     a.fileType = aSplit[aSplit.length - 1];
        //     // console.log(parseUrl)
        //     a.format_type = mappedFormatType(a.fileType);
        // }
    }
    if(a?.url && a?.url.includes('?')) a.url = a?.url?.split('?')[0];
    return a;
}

export function mappedFormatType(type) {
    switch (type) {
        case 'png':
        case 'jpg':
        case 'jpeg':
        case 'svg':
            return 'image';
        case 'pdf':
        case 'zip':
            return type;
        case 'doc':
        case 'docx':
            return 'word';
        case 'csv':
        case 'xlsx':
        case 'xls':
            return 'excel';
        default:
            return 'file';
    }
}

export function parseUrlToObjectKey(url) {
    try {
        console.log(url, EnvParams.replace_s3);
        // const parseUrl = new URL(url).pathname;
        // const splitUrl = url.replace(EnvParams.replace_s3, '');
        return {
            key: url.replace(EnvParams.replace_s3, ''),
            // fileType: url.replace(EnvParams.replace_s3, '').split('.')[1],
            // pathname: parseUrl,
            // file_name: splitUrl[splitUrl.length - 1]
        }
    } catch (error) {
        return {
            key: null,
            // fileType: null,
            // pathname: null,
            // file_name: null
        }
    }
}