import { useTranslation } from "react-i18next";
import PDFViewer from "./index";
// import PDFBGIcon from '../Assets/img/icons/file_pdf.svg';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
// .txt
// .doc
// .docx
// .ppt
// .pptx
// .xls
// .xlsx
// .gif
// .jpg
// .jpeg
// .png
// .mp4
// .pdf
// import {
//     Form,
//     Button,
// } from 'react-bootstrap';
// import CryptoJS from 'crypto-js';
// import Layout from "../../../Layout";


function FullPreview(props) {
    const { t } = useTranslation('common');
    let { files, file, active, onClose, filesSort, docInfo, token, onConfirmView, view_status, isGuest, showInfoSectionMobile, onClickMenuMobile } = props;

    if (!files || !active) {
        return <div className="pdf-full-preview-wrapper"></div>;
    }

    let viewProps = {
        files: files,
        file: file,
        upload: false,
        showAll: true,
        fit: true,
        controller: true,
        info: true,
        full: true,
        filesSort,
        docInfo,
        token,
        isGuest,
        view_status,
        onConfirmView
    };
    // console.log(files, file)

    if (Object.keys(files).length > 1) {
        viewProps.list = true;
        viewProps.info = false;
    }

    return (
        <div className={`pdf-full-preview-wrapper active ${showInfoSectionMobile ? 'active-mobile': ''}`}>
            <div className="header-wrapper sticky mobile-top">
                <div className="header-left">
                    { viewProps.onConfirmView ?  <IconButton className="btn-return" onClick={onClickMenuMobile} >
                        <MenuIcon
                            className="fa-flip-horizontal d-block d-sm-none"
                        />
                    </IconButton> : null}
                    {/* <button className="btn-return" onClick={onClose}>
                        <FontAwesomeIcon icon={faShareSquare} className="fa-flip-horizontal" title={t("common:document.return")} />
                    </button> */}
                </div>
                <div className="header-center document-title">
                    {/* {`${docInfo.id}: ${docInfo.title}`} */}
                </div>
                <div className="header-right">
                    <IconButton className="btn-return" onClick={onClose}>
                        <CloseIcon className="fa-flip-horizontal" title={t("common:document.close")} />
                    </IconButton>
                </div>
            </div>
            <div className="body-wrapper">
                <PDFViewer {...viewProps} />
            </div>
        </div>
    );

}

export default FullPreview;
