import SVGIcons from '../Pages/Assets/SVGIcons';
// import Workflow from '../Pages/Main/Workflow';

export const MenuConfig = {
    dashboard: {
        icon: SVGIcons["home"],
        text: 'common:menu.dashboard',
        link: '/dashboard',
        name: "Dashboard",
        // page: Dashboard,
    },
    signer: {
        icon: SVGIcons["signer"],
        text: 'common:menu.signer',
        link: '/signer',
        name: "Signer",
        // page: Signer,
    },
    sender: {
        icon: SVGIcons["sender"],
        text: 'common:menu.sender',
        link: '/sender',
        name: "Sender",
        // page: Sender,
    },
    circle: {
        icon: SVGIcons["circle"],
        text: 'common:menu.circle',
        link: '/circle',
        name: "Circle",
        // page: Circle,
    },
    verification: {
        icon: SVGIcons["verification"],
        text: 'common:menu.verification',
        link: '/verification',
        name: "Verification",
        // page: Verification,
    },
    // workflow: {
    //     icon: SVGIcons["workflow"],
    //     text: 'common:menu.workflow',
    //     link: '/workflow',
    //     name: "Workflow",
    //     page: Workflow,
    // },
    evaluation: {
        icon: SVGIcons["evaluation"],
        text: 'common:menu.evaluation',
        link: '/evaluation',
        name: "Evaluation",
        // page: Evaluation,
    },
    drive: {
        icon: SVGIcons["filebox"],
        text: 'common:menu.shared_folder',
        link: '/drive',
        name: "Drive",
        // page: DriveLayout,
    },
    // filebox: {
    //     icon: SVGIcons["filebox"],
    //     text: 'common:menu.filebox',
    //     link: '/filebox',
    //     name: "FileBox",
    //     // page: FileBox,
    // },
    search: {
        icon: SVGIcons["search"],
        text: 'common:menu.search',
        link: '/search',
        name: "Search",
        // page: Search,
    },
    business_number: {
        icon: SVGIcons["search"],
        text: 'common:menu.business_search',
        link: '/business-search',
        name: "Business",
        // page: Search,
    },
};

// export default MenuConfig;