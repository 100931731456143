// @ts-nocheck
import { faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHookstate } from "@hookstate/core";
import { ArrowBack } from "@mui/icons-material";
import AccountCircle from "@mui/icons-material/AccountCircle";
import Save from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Divider,
  FormControl,
  IconButton,
  Snackbar,
  Tooltip,
  Typography,
} from "@mui/material";
import CryptoJS from "crypto-js";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import BaseCard from "../../../Components/base-card/base-card";
import BaseTextField from "../../../Components/base-text-field/base-text-field";
import SwitchCustomize from "../../../Components/switch-customize/switch-customize";
import { notificationAlertState } from "../../../drive-feature/data-access/state";
import Service from "../../../Service";
import SVGIcons from "../../Assets/SVGIcons";
import MaterialSymbolIcon from "../../Components/material-symbol/material-symbol";
import styles from "./tenant-detail.module.css";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

export default function TenantDetailPages(props) {
  const { t } = useTranslation("common");
  // const { tenantId } = useParams();
  const methods = useForm({
    defaultValues: {
      // enableRestrictIp: false,
      // restrictIp: "",
      company_id: null,
      company_name: "",
      tenant_id: null,
      name: "",
      api_token: "",
      counter_party: null,
      enable_guest_transfer: true,
      enable_host_transfer: true,
      status: 0,
      login_expired_minutes: 4320,
      email_company_name_format_id: 1,
    },
  });
  const loadingBtn = useHookstate(false);
  const showAlert = useHookstate(notificationAlertState);
  const services = useHookstate([]);
  const emailCompanyNameFormatList = useHookstate([]);
  // const location = useLocation();
  // const history = useHistory();
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    watch,
  } = methods;

  useEffect(() => {
    if (props.tenantDetail && props.showDetail) {
      Service.getTenantServiceList(props.tenantDetail).then((response) => {
        services.set(response.tenantServiceList);
        emailCompanyNameFormatList.set(response.emailCompanyNameFormatList);
      });
      reset({ ...props.tenantDetail, company_name: props?.company_name || "" });
    } else {
      reset({
        company_id: null,
        company_name: "",
        tenant_id: null,
        name: "",
        api_token: "",
        counter_party: null,
        enable_guest_transfer: true,
        enable_host_transfer: true,
        status: 0,
        login_expired_minutes: 4320,
      });
    }
  }, [props.showDetail, props.tenantDetail]);
  function tenantUpdate(data) {
    // const {
    //   tenant_id,
    //   company_id,
    //   name,
    //   api_token,
    //   status,
    //   enableRestrictIp,
    //   restrictIp,
    // } = data;
    console.log(data.status);
    data.status =
      data.status === true || data.status === 0 || data.status === "0"
      || data.status === 'checked' ? 0 : 1;
    data.enable_guest_transfer =
      data.enable_guest_transfer === true ||
      data.enable_guest_transfer === 1 ||
      data.enable_guest_transfer === "1"
        ? 1
        : 0;
    data.enable_host_transfer =
      data.enable_host_transfer === true ||
      data.enable_host_transfer === 1 ||
      data.enable_host_transfer === "1"
        ? 1
        : 0;

    data.login_expired_minutes = parseInt(data.login_expired_minutes, 10) || 4320;
    loadingBtn.set(true);
    Service.UpdateTenant({
      ...props.tenantDetail,
      status: data.status,
      name: data.name,
      enable_guest_transfer: data.enable_guest_transfer,
      enable_host_transfer: data.enable_host_transfer,
      api_token: data.api_token,
      login_expired_minutes: data.login_expired_minutes,
      email_company_name_format_id: data.email_company_name_format_id,
    })
      .then((response) => {
        /* */
        // datalist = null;

        let { modal } = props.main.state;
        modal.title = "common:settings.tenant.update.success";
        modal.body = () => {
          return (
            <div className="lz-m-20">
              <p>{t(`common:message.tenant.update.success`)}</p>
            </div>
          );
        };

        modal.props = {
          show: true,
          centered: true,
          backdrop: true,
        };
        modal.close = true;
        props.main.setState({ modal, modalClose: true });
        /* */
        // modal.props = { show: false };
        loadingBtn.set(false);
        // notificationAlertState.set({
        //   show: true,
        //   message: `${t(
        //     `common:message.tenant.success`,
        //     "Update tenant success !"
        //   )}`,
        //   type: "success",
        // });
        // console.log(newList, data.tenant_id);
        props.main.setState({
          pagination: {
            page: 1,
            take: 100,
          },
          tenantDetail: null,
          showTenantDetail: false,
          datalist: null,
        });
      })
      .catch((error) => {
        loadingBtn.set(false);
        let { modal } = props.main.state;
        let msgErr = error?.response?.data?.message;

        if (
          error?.code === "ECONNABORTED" ||
          (error?.response?.status === 504 &&
            error?.response?.data?.message === "Endpoint request timed out")
        ) {
          msgErr = t(
            "message.error.request.timed_out",
            `サーバーが混雑しております。\n しばらく待ってアクセスしてください。`
          );
          // return;
        }
        modal.body = () => {
          return (
            <div className="lz-m-20">
              <p>
                {t(`common:${msgErr || "message.error.server_unknown_error"}`)}
              </p>
            </div>
          );
        };

        modal.props = {
          show: true,
          centered: true,
          backdrop: true,
        };
        modal.close = true;

        props.main.setState({ modal, modalClose: true });
        /* */
        //console.log("error", error);
      });
  }
  function handleClose(event, reason) {
    if (reason === "clickaway") {
      return;
    }

    showAlert.show.set(false);
  }
  if (!props.showDetail)
    return (
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={showAlert.show.get()}
        autoHideDuration={3500}
        onClose={handleClose}
      >
        <Alert severity={showAlert.type.get()}>{showAlert.message.get()}</Alert>
      </Snackbar>
    );

  // console.log(location, history, match)
  // console.log('breadcrumbs', breadcrumbs)
  return (
    <div className="p-3">
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={showAlert.show.get()}
        autoHideDuration={3500}
        onClose={handleClose}
      >
        <Alert severity={showAlert.type.get()}>{showAlert.message.get()}</Alert>
      </Snackbar>

      <div className="go_back_wrapper">
        <Tooltip
          placement="top"
          disableInteractive
          title={t("common:tenant.detail.pages.go_back", "テナント")}
        >
          <IconButton
            onClick={(e) => {
              props.main.setState({
                tenantDetail: null,
                showTenantDetail: false,
              });
            }}
          >
            <ArrowBack className="go_back_icon" />
          </IconButton>
        </Tooltip>

        {/* <MaterialSymbolIcon
          disableInteractive={true}

          iconName={"arrow_back"}
        /> */}
        <span>{t("common:tenant.detail.pages.go_back", "テナント")}</span>
      </div>
      {/* <Divider /> */}
      <BaseCard
        className={styles["base_tenant_card"]}
        content={
          <div className="base_content_grid">
            <div className={`${styles["button_action"]} button-right-shoulder`}>
              <LoadingButton
                loading={loadingBtn.get()}
                color="secondary"
                size="small"
                aria-label="SaveIcon"
                key="ec-save"
                startIcon={<Save />}
                variant="contained"
                onClick={handleSubmit(tenantUpdate)}
              >
                {t("general.save")}
              </LoadingButton>
            </div>
            <div className="field_wrapper">
              <Typography
                color={"#062b60"}
                className={styles["form_text"]}
                variant="subtitle2"
                component="p"
              >
                有効 / 無効
              </Typography>
              <div className="col-span-4">
                <FormControl fullWidth>
                  <Controller
                    name={"status"}
                    control={control}
                    // rules={rules}
                    render={({ field: { onChange, value }, formState }) => (
                      <SwitchCustomize
                        setVal={setValue}
                        name={"status"}
                        active={
                          value === true || value === 0 || value === "checked"
                        }
                        onChange={onChange}
                      />
                    )}
                  />
                </FormControl>
              </div>
            </div>
            {[
              {
                title: t(`common:settings.tenant.company_id`),
                controlName: "company_id",
                enable: false,
              },
              {
                title: t(`common:settings.tenant.company_name`),
                controlName: "company_name",
                enable: false,
              },
              {
                title: t(`common:settings.tenant.tenant_id`),
                controlName: "tenant_id",
                enable: false,
              },
              {
                title: t(`common:settings.tenant.name`),
                controlName: "name",
                enable: true,
              },
            ].map((a, i) => (
              <div className="field_wrapper" key={i}>
                <Typography
                  color={"#062b60"}
                  className={styles["form_text"]}
                  variant="subtitle2"
                  component="p"
                >
                  {a.title}
                </Typography>
                <div className="col-span-4">
                  <FormControl fullWidth>
                    <Controller
                      name={a.controlName}
                      control={control}
                      // rules={rules}
                      render={({ field: { onChange, value }, formState }) =>
                        !a.enable ? (
                          <>{value}</>
                        ) : (
                          <BaseTextField
                            className="bg-white"
                            onChange={onChange}
                            size="small"
                            disabled={!a.enable}
                            value={value}
                            InputLabelProps={{ style: { fontSize: 14 } }}
                            // value={textInputValue}
                            // placeholder={textInputPlaceHolder}
                            // label={textFieldLabel || 'Filled'}
                            variant="outlined"
                          />
                        )
                      }
                    />
                  </FormControl>
                </div>
              </div>
            ))}
            <div>
              <div className="field_wrapper">
                <Typography
                  color={"#062b60"}
                  className={styles["form_text"]}
                  variant="subtitle2"
                  component="p"
                >
                  {t("common:general.contract-services")}
                </Typography>
                {/* {JSON.stringify(props.tenant_service_list)} */}
                <div
                  style={{
                    display: "flex",
                    gap: "5px",
                    gridColumn: "span 6/ span 6",
                    width: "100%",
                  }}
                >
                  {services.get()?.length > 0
                    ? services.get({ noproxy: true }).map((service, i) => {
                        let Icon = SVGIcons[service.function_type_code];
                        if (!Icon) return <></>;
                        return (
                          <p
                            key={`service-${service.function_type_id}-${i}`}
                            className="label active"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              margin: 0,
                              width: "100%",
                            }}
                          >
                            <Icon
                              code={service.function_type_code}
                              className="icon"
                              style={{ width: "1.2rem" }}
                            />
                            <span>
                              {t(`common:${service.function_type_label}`)}
                            </span>
                          </p>
                        );
                      })
                    : null}
                </div>

                {/* <span className="value services-label">
                  <props.main.AllServiceLabel />
                  {props.main.AllServiceLabel.bind(props.main)}
                </span> */}
              </div>
            </div>
            <Divider />
            <div className="field_wrapper">
              <Typography
                color={"#062b60"}
                className={styles["form_text"]}
                variant="subtitle2"
                component="p"
              >
                {t("common:settings.tenant.api_token")}
              </Typography>
              <div className="col-span-4 ">
                <FormControl fullWidth>
                  <Controller
                    name={"api_token"}
                    control={control}
                    // rules={rules}
                    render={({ field: { onChange, value }, formState }) => (
                      <BaseTextField
                        className="bg-white"
                        onChange={onChange}
                        size="small"
                        disabled={true}
                        value={value || ""}
                        InputLabelProps={{ style: { fontSize: 14 } }}
                        // value={textInputValue}
                        // placeholder={textInputPlaceHolder}
                        // label={textFieldLabel || 'Filled'}
                        variant="outlined"
                      />
                    )}
                  />
                  <div
                    className={styles["fa_sync"]}
                    onClick={() => {
                      // generate tenant token
                      let value = new Date().toString();
                      let hash = CryptoJS.SHA256(value).toString();
                      setValue("api_token", hash);
                      // formData.api_token = hash;
                      // this.setState({ formData });
                    }}
                  >
                    <FontAwesomeIcon icon={faSync} className="action-button" />
                  </div>
                </FormControl>
              </div>

              {/* <SyncProblemIcon
                className="cursor-pointer"
                // onClick={openConfirmDialog}
                color={"secondary"}
              /> */}
            </div>
            <Divider />
            <div className="field_wrapper">
              <Typography
                color={"#062b60"}
                className={styles["form_text"]}
                variant="subtitle2"
                component="p"
              >
                {t("common:settings.tenant.group_number")}
              </Typography>
              <div className="col-span-4 additional_info_count">
                {props?.tenantDetail?.group_number || 0}
              </div>
            </div>
            <div className="field_wrapper">
              <Typography
                color={"#062b60"}
                className={styles["form_text"]}
                variant="subtitle2"
                component="p"
              >
                {t("common:settings.tenant.user_number")}
              </Typography>
              <div className="col-span-4 additional_info_count">
                <div className="settings_tenant_user_active">
                  <AccountCircle color="success" />
                  <span>{props?.tenantDetail?.activated_user_number || 0}</span>
                </div>
                <div className="settings_tenant_user_inactive">
                  <AccountCircle color="disabled" />
                  <span>
                    {(props?.tenantDetail?.user_number || 0) -
                      (props?.tenantDetail?.activated_user_number || 0)}
                  </span>
                </div>
              </div>
            </div>
            <div className="field_wrapper">
              <Typography
                color={"#062b60"}
                className={styles["form_text"]}
                variant="subtitle2"
                component="p"
              >
                {t("common:settings.tenant.guest_number")}
              </Typography>
              <div className="col-span-4 additional_info_count">
                <div className="settings_tenant_guest_active">
                  <AccountCircle color="success" />
                  <span>
                    {props?.tenantDetail?.activated_guest_number || 0}
                  </span>
                </div>
                <div className="settings_tenant_guest_inactive">
                  <AccountCircle color="disabled" />
                  <span>
                    {(props?.tenantDetail?.guest_number || 0) -
                      (props?.tenantDetail?.activated_guest_number || 0)}
                  </span>
                </div>
              </div>
            </div>
            <Divider />
            <div className="field_wrapper">
              <Typography
                color={"#062b60"}
                className={styles["form_text"]}
                variant="subtitle2"
                component="p"
              >
                {t(`common:settings.tenant.login_expried_time`)}
              </Typography>
              <div className="col-span-4 additional_info_count">
                <FormControl error={!!errors.login_expired_minutes}>
                  <Controller
                    name={"login_expired_minutes"}
                    control={control}
                    rules={{
                      min: { value: 1, message: 'Minimum value is 1' },
                      max: { value: 10080, message: 'Maximum value is 10080' }
                    }}
                    render={({ field: { onChange, value }, formState }) =>
                      <BaseTextField
                        className="bg-white"
                        onChange={onChange}
                        size="small"
                        value={value}
                        InputLabelProps={{ style: { fontSize: 14 } }}
                        variant="outlined"
                        customStyles={{ textAlign: "right" }}
                        type="number"
                        inputProps={{ min: 1, max: 10080 }}
                        error={!!errors.login_expired_minutes}
                        onKeyDown={(e) => {
                          if (['e', 'E', '+', '-', ',', '.'].includes(e.key)) {
                            e.preventDefault();
                          }
                        }}
                      />
                    }
                  />
                </FormControl>
                <div className="x" style={{fontSize: 12}}>
                  {t(`common:settings.tenant.login_expried_time_unit`)}
                </div>
              </div>
            </div>
          </div>
        }
      />

      <BaseCard
        className={styles["base_tenant_card"]}
        content={
          <div className="base_content_grid">
            <Typography
                color={"#062b60"}
                className={styles["form_text"]}
                variant="h6"
                component="p"
            >
              {t("common:settings.tenant.change_of_signatory")}
              {/*署名者変更*/}
            </Typography>
            <div className="field_wrapper">
              <Typography
                  color={"#062b60"}
                  className={styles["form_text"]}
                  variant="subtitle2"
                  component="p"
              >
                {t("common:settings.tenant.enable_guest_transfer")}
              </Typography>
              <div className="col-span-4 ">
                <FormControl fullWidth>
                  <Controller
                      name={"enable_guest_transfer"}
                      control={control}
                      // rules={rules}
                      render={({field: {onChange, value}, formState}) => (
                          <SwitchCustomize
                              setVal={setValue}
                              name={"enable_guest_transfer"}
                              // disabled={props.guestDetail?.status === 2}
                              active={value}
                              onChange={onChange}
                          />
                      )}
                  />
                </FormControl>
              </div>
            </div>
            <div className="field_wrapper">
              <Typography
                  color={"#062b60"}
                  className={styles["form_text"]}
                  variant="subtitle2"
                  component="p"
              >
                {t("common:settings.tenant.enable_host_transfer")}
              </Typography>
              <div className="col-span-4 ">
                <FormControl fullWidth>
                  <Controller
                      name={"enable_host_transfer"}
                      control={control}
                      // rules={rules}
                      render={({field: {onChange, value}, formState}) => (
                          <SwitchCustomize
                              setVal={setValue}
                              name={"enable_host_transfer"}
                              // disabled={props.guestDetail?.status === 2}
                              active={value}
                              onChange={onChange}
                          />
                      )}
                  />
                </FormControl>
              </div>
            </div>
          </div>
        }
      />

      <BaseCard
        className={styles["base_tenant_card"]}
        content={
          <div className="base_content_grid">
            <Typography
                color={"#062b60"}
                className={styles["form_text"]}
                variant="h6"
                component="p"
            >
              {t("common:settings.tenant.email_company_name_format")}
              {/*送信メールの件名表示*/}
            </Typography>
            <div className={"field_wrapper"}>
              <div className="col-span-4 ">
                <FormControl fullWidth>
                  <Controller
                      name={"email_company_name_format_id"}
                      control={control}
                      render={({field: {onChange, value}, formState}) => (
                          <Select
                              name={"email_company_name_format_id"}
                              style={{
                                backgroundColor: "#fff",
                              }}
                              onChange={onChange}
                              value={parseInt(value, 10)}
                          >
                            {emailCompanyNameFormatList.get({noproxy: true}).map((item, index) => (
                                <MenuItem
                                    key={item.id}
                                    value={item.id}
                                >
                                  {t(`common:settings.tenant.email_company_name_format_${item.label}`)}
                                </MenuItem>
                            ))}
                          </Select>
                      )}
                  />
                </FormControl>
              </div>
            </div>
          </div>
        }
      />
    </div>
  );
}
