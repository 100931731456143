/**
 * @param {number} bytes
 * @param {any} [format_type]
 */
export function formatBytes(bytes, decimals = 2, format_type) {
    if (isNaN(bytes)) return '';
    switch (format_type) {
        case 'folder':
            return bytes;
        default:
            if (!+bytes && format_type !== 'dashboard') return '0 Bytes';
            if (!+bytes && format_type === 'dashboard') return '0.00 MB';

            const k = 1024
            const dm = decimals < 0 ? 0 : decimals
            const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

            const i = Math.floor(Math.log(bytes) / Math.log(k))

            return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    }
}
