import React from "react";
import "material-design-icons/iconfont/material-icons.css";
import classnames from "classnames";

function MaterialIcons(props) {
    const iconName = props.name;

    return (
        <span {...props} className={classnames("material-icons", props.className)} {...props.attribute}>
            {iconName}
        </span>
    );
}

export default MaterialIcons;
