import LoadingButton from "@mui/lab/LoadingButton";
import { useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Service from "../../Service";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import { Auth } from "aws-amplify";
import axios from "axios";
import { useState } from "react";
import { withTranslation } from "react-i18next";
import API from "../../Config/API";
import AuthService from "../../Service/Auth";
import mappingTranslateDefault from "../Main/MappingTranslateCommon";
import InputAdornment from "@mui/material/InputAdornment";
import { getSignGuestDocument } from "../../data-access/documents/document.api";
/**
 * @param isVerifySoftware - type boolean, check is this dialog verify software
 * @param isVerifyEmail - type boolean, check is this dialog verify email
 * @param isVerifyEmailInDocument - type boolean, check is this dialog verify document signing
 * @param isVerifyGuestDocument - type boolean, check is this dialog verify guest viewership
 * @param mfaUser - type CognitoUser, passing the default user instance
 * @param mfaChallengeName - type string, passing the default cognito challengeName
 * @param openOtpDialog - type boolean, open the dialog
 * @param isFromSigned - type boolean, from signed screen
 * @param resendEmailCode - type Function,callback that trigger resend OTP code
 * @param callback - type Function, callback function on submit click}
 * @param tokenGuest - type string, token of guest in params (Guest viewership flow)
 */
export default withTranslation()(function OtpDialog(props) {
  const { t } = props;
  //PIN counter
  const [timeLeft, setTimeLeft] = useState(120);
  // show loading
  const [open, setOpen] = useState(false);

  // count fail otp input
  const [countIncorrect, setCountIncorrect] = useState(5);

  // error message
  const [message, setMessage] = useState(
    mappingTranslateDefault(
      t,
      "common:general.mfa-auth.invalid-otp",
      "無効なPINコードです"
    )
  );

  // Input otp
  const [otp, setOtp] = useState("");

  // is show error message
  const [isVerify, setIsVerify] = useState(false);

  const [isSessionExp, setIsSessionExp] = useState(false);
  // on input change
  const handleChange = (event) => {
    if (isVerify) setIsVerify(false);
    setOtp(event.target.value);
  };
  // on submit click
  const handleClose = async (e, reason) => {
    if (reason === "backdropClick" && !props.isFromSigned) {
      return;
    }

    if (reason === "backdropClick" && props.isFromSigned) {
      props.callback(false);
      return;
    }
    setIsVerify(false);
    setOpen(true);
    // Condition 1: Login Flow
    if (
      !props.isVerifySoftware &&
      !props.isVerifyEmail &&
      !props.isVerifyEmailInDocument &&
      !props.isVerifyGuestDocument
    ) {
      const loggedUser = await Auth.confirmSignIn(
        props.mfaUser, // Return object from Auth.signIn()
        otp, // Confirmation code
        props.mfaChallengeName // MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA
      ).catch((e) => {
        setIsVerify(true);
        setOpen(false);
      });
      if (loggedUser) {
        props.callback(otp);
      }
    } else {
      // Condition 2: Login and document verify with TOTP application
      if (props.isVerifySoftware) {
        const user = await AuthService.GetCurrentLogin();
        let verify = await Auth.verifyTotpToken(user, otp).catch((e) => {
          console.log(e.code, "pl", e.message);
          setIsVerify(true);
          setOpen(false);
          if (
            e.code === "EnableSoftwareTokenMFAException" &&
            e.message === "Code mismatch"
          ) {
            setMessage(
              mappingTranslateDefault(
                t,
                "common:general.mfa-auth.invalid-otp",
                "無効なPINコードです"
              )
            );
          } else if (e.code === "InvalidParameterException") {
            setMessage(
              mappingTranslateDefault(
                t,
                "common:general.mfa-auth.invalid-otp",
                "無効なPINコードです"
              )
            );
          } else {
            setMessage(e.message);
          }
          // Token is not verified
        });
        if (verify) {
          props.callback(true);
        }
      }

      // Condition 3: Login and document verify with Email
      if (props?.isVerifyEmail && !props?.isVerifyEmailInDocument) {
        setCountIncorrect(countIncorrect - 1);
        let verify = await Auth.sendCustomChallengeAnswer(
          props.mfaUser,
          otp
        ).catch((err) => {
          console.log(err.message, err.code);
          if (err.code === "NotAuthorizedException") {
            setMessage(
              mappingTranslateDefault(
                t,
                "common:general.mfa-auth.otp-session-expire",
                "PINコードの入力を5回失敗しました。下記のボタンからPINコードを再送してください。"
              )
            );
            setIsSessionExp(true);
            setIsVerify(false);
          } else {
            setMessage(err.message);
          }
          setIsVerify(true);
          setOpen(false);
        });
        if (verify.authenticationFlowType === "CUSTOM_AUTH") {
          setIsVerify(true);
          setOpen(false);
          setMessage(
            mappingTranslateDefault(
              t,
              "common:general.mfa-auth.invalid-otp",
              "無効なPINコードです"
            )
          );
        } else {
          setIsVerify(false);
          setCountIncorrect(5);
          props.callback();
        }
      }

      // Condition 4: Document viewership of Guest
      if (props.isVerifyGuestDocument) {
        setCountIncorrect(countIncorrect - 1);
        let resp = await getSignGuestDocument(
          props.tokenGuest,
          otp,
          countIncorrect
        ).catch((e) => {
          console.log(e);
          setIsVerify(true);
          setOpen(false);
          if (countIncorrect - 1 <= 0) {
            setMessage(
              mappingTranslateDefault(
                t,
                "common:general.mfa-auth.otp-session-expire",
                "PINコードの入力を5回失敗しました。下記のボタンからPINコードを再送してください。"
              )
            );
            setIsSessionExp(true);
          } else {
            setMessage(
              mappingTranslateDefault(
                t,
                "common:general.mfa-auth.invalid-otp",
                "無効なPINコードです"
              )
            );
          }
          // reject(error);
        });
        if (!resp) {
          setIsVerify(true);
          setOpen(false);
          // setMessage(`Invalid OTP`);
          if (countIncorrect - 1 <= 0) {
            setMessage(
              mappingTranslateDefault(
                t,
                "common:general.mfa-auth.otp-session-expire",
                "PINコードの入力を5回失敗しました。下記のボタンからPINコードを再送してください。"
              )
            );
            setIsSessionExp(true);
          } else {
            setMessage(
              mappingTranslateDefault(
                t,
                "common:general.mfa-auth.invalid-otp",
                "無効なPINコードです"
              )
            );
          }
        } else {
          setIsVerify(false);
          setCountIncorrect(5);
          props.callback(true, resp);
        }
        // props.callback(true, otp, countIncorrect);
      }

      // Condition 4: Document signing flow
      if (props.isVerifyEmailInDocument) {
        setCountIncorrect(countIncorrect - 1);
        const uri = API.url + "/mfa-otp/verify.json";
        const tokenID = await AuthService.GetTokenID();
        const resp = await axios
          .post(
            uri,
            {
              body: {
                otpCode: otp,
                countIncorrect: countIncorrect - 1,
              },
            },
            {
              headers: {
                Authorization: tokenID,
              },
            }
          )
          .catch((e) => {
            console.log(e);
            setIsVerify(true);
            setOpen(false);
            if (countIncorrect - 1 <= 0) {
              setMessage(
                mappingTranslateDefault(
                  t,
                  "common:general.mfa-auth.otp-session-expire",
                  "PINコードの入力を5回失敗しました。下記のボタンからPINコードを再送してください。"
                )
              );
              setIsSessionExp(true);
            } else {
              setMessage(
                mappingTranslateDefault(
                  t,
                  "common:general.mfa-auth.invalid-otp",
                  "無効なPINコードです"
                )
              );
            }
            // reject(error);
          });
        if (!resp) {
          setIsVerify(true);
          setOpen(false);
          // setMessage(`Invalid OTP`);
          if (countIncorrect - 1 <= 0) {
            setMessage(
              mappingTranslateDefault(
                t,
                "common:general.mfa-auth.otp-session-expire",
                "PINコードの入力を5回失敗しました。下記のボタンからPINコードを再送してください。"
              )
            );
            setIsSessionExp(true);
          } else {
            setMessage(
              mappingTranslateDefault(
                t,
                "common:general.mfa-auth.invalid-otp",
                "無効なPINコードです"
              )
            );
          }
        } else {
          setIsVerify(false);
          setCountIncorrect(5);
          props.callback(true);
        }
      }
    }
    setOpen(false);
    setOtp("");
  };

  useEffect(() => {
    if (timeLeft === 0) {
      // console.log("TIME LEFT IS 0");
      setTimeLeft(0);
      setIsVerify(true);
      setMessage(
        mappingTranslateDefault(
          t,
          "common:general.mfa-auth.otp-expire",
          "PINコードの有効期限が切れています。下記のボタンから再送してください。"
        )
      );
      setIsSessionExp(true);
    }

    // exit early when we reach 0
    if (!timeLeft) return;

    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [timeLeft]);
  //   setTimeout(()=>{
  //     setOpen(true);
  //   }, 5000)
  return (
    <div>
      <Dialog
        disableEscapeKeyDown={true}
        fullWidth={true}
        className="otp_dialog"
        open={props.openOtpDialog}
        onClose={handleClose}
      >
        <DialogTitle className="otp_dialog_header">
          {mappingTranslateDefault(
            t,
            "common:general.mfa-auth.otp-dialog-title",
            "PINコードを入力してください"
          )}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {props.isVerifyEmail ||
            props.isVerifyEmailInDocument ||
            props.isVerifyGuestDocument
              ? mappingTranslateDefault(
                  t,
                  "common:general.mfa-auth.send-otp",
                  "ご登録のメールにPINコードを送付しました"
                )
              : mappingTranslateDefault(
                  t,
                  "common:general.mfa-auth.send-otp-software",
                  "アプリでPINコードを確認してください"
                )}
          </DialogContentText>
          <TextField
            margin="dense"
            id="name"
            inputProps={{ maxLength: 6 }}
            label="PIN"
            type="email"
            fullWidth={true}
            variant="outlined"
            error={isVerify}
            InputProps={
              props.isVerifyEmail ||
              props.isVerifyEmailInDocument ||
              props.isVerifyGuestDocument
                ? {
                    endAdornment: (
                      <InputAdornment position="start">
                        {timeLeft}
                      </InputAdornment>
                    ),
                  }
                : undefined
            }
            helperText={isVerify ? message : ""}
            value={otp}
            onChange={handleChange}
          />
          <div className="otp_dialog_actions">
            <LoadingButton
              loading={open}
              color="primary"
              disabled={timeLeft === 0 || isSessionExp}
              variant="contained"
              onClick={handleClose}
            >
              {mappingTranslateDefault(t, "common:general.mfa-auth.ok", "OK")}
            </LoadingButton>
            {props.isVerifyEmail ||
            props.isVerifyEmailInDocument ||
            props.isVerifyGuestDocument ? (
              <Link
                href="#"
                underline="none"
                onClick={() => {
                  setTimeLeft(120);
                  setIsSessionExp(false);
                  setOtp("");
                  setIsVerify(false);
                  return props.resendEmailCode();
                }}
              >
                {mappingTranslateDefault(
                  t,
                  "common:general.mfa-auth.resend-email",
                  "PINコードを再送する"
                )}
              </Link>
            ) : null}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
});
