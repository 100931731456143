// import { Link } from "react-router-dom";
// import { useTranslation } from "react-i18next";
import { withTranslation } from 'react-i18next';
import Layout from '../Layout';
import bg403 from './Assets/img/403.png';
import bg404 from './Assets/img/404.png';
import Button from '@mui/material/Button';
import AuthService from '../Service/Auth';
import { driveFoldersNav, userInfoState } from '../drive-feature/data-access/state';
import {reissueSignGuestDocument} from "../data-access/documents/document.api";
import LoadingButton from "@mui/lab/LoadingButton";
import React from "react";


class Error extends Layout {

    constructor(props) {
        super(props)
        this.state.code = props.code;
        this.state.reissueLoading = false;
        this.state.reissueButtonVisible = props.reissueButton;
    }

    NavLeft = () => {
        const { LogoImage } = this;

        return (
            <div className="logo-horizontal" style={{ width: "200px" }}>
                <LogoImage />
            </div>
        );
    }

    Main = (props) => {
        let { loading } = this.state;
        const { Nav, Body, MainLoading } = this;

        if (loading) {
            return <MainLoading />;
        }

        return (
            <div className="main contrainer-wrapper">
                <div className="layout no-menu">

                    <div className="layout-right">

                        <Nav />

                        <Body {...props} />

                        <div className="right-bottom">
                            <span className="licensce-info">© {new Date().getFullYear()} paperlogic co., ltd.</span>
                        </div>

                    </div>
                </div>
            </div >
        );
    }

    Logout = async () => {
        AuthService.Logout();
        localStorage.removeItem("user-info");
        userInfoState.set(null);
    }

    ErrorBody = () => {
        let { code, msg, title, isCustom, t, token} = this.props;

        if (code === 404) {
            if (isCustom) {
                return (
                    <div
                        className="errorPageElements"
                        style={{ textAlign: 'center' }}
                    >
                        <h1
                            style={{ textAlign: 'center' }}
                        >{code}</h1>
                        {
                            msg && <h5 style={{ whiteSpace: 'pre-line' }}>{msg}</h5>
                        }
                        <Button style={{ marginTop: 30 }} variant="contained" onClick={this.Logout}>
                            <span>ログアウト</span>
                        </Button>
                    </div>
                );
            } else {
                return (
                    <div
                        className="errorPageElements"
                        style={{ textAlign: 'center' }}
                    >
                        <h1
                            style={{ textAlign: 'center' }}
                        >{code}</h1>
                        <h5>Page not found.</h5>
                        {
                            msg && <div style={{ whiteSpace: 'pre-line' }}>{msg}</div>
                        }
                        <img
                            src={bg404} alt="Page not found."
                            className="errorPageElementsimg"
                        />
                    </div>
                );
            }
        } else if (code === 403) {
            return (
                <div
                    className="errorPageElements"
                    style={{ textAlign: 'center' }}
                >
                    <h1>{code}</h1>
                    <h5>Forbidden.</h5>
                    <img
                        src={bg403} alt="Forbidden."
                        style={{ width: '450px' }}
                    />
                </div>
            );
        } else if (code === 401) {
            const errTitle = title
            const errMessages = msg // array
            return (
                <div
                    className="errorPageElements"
                    style={{
                        textAlign: 'center',
                        padding: '20px 0'
                    }}
                >
                    <h2>{errTitle}</h2>
                    {errMessages.map((msg, i) => <p key={i}>{msg}</p>)}
                    <img
                        src={bg404} alt="Unauthorized."
                        style={{ width: '450px' }}
                    />
                </div>
            );
        } else if (code === 'tenant_access_denied') {
            return (
                <div
                    className="errorPageElements"
                    style={{
                        textAlign: 'center',
                        padding: '20px 0'
                    }}
                >
                    <h2>{t(`common:message.errors.tenant-access-denied`)}</h2>
                </div>
            );
        } else if (code === 'guest_sign_expiration') {
            let errorMessage = msg;
            const handleReissueGuestDocumentUrl = () => {
                this.setState({
                    reissueLoading: true
                })

                reissueSignGuestDocument(token).then((resp) => {
                    if (resp.ok) {
                        this.setState({
                            modal: {
                                props: {
                                    show: true,
                                    className: "layout modal-responsive",
                                    size: "xl",
                                    centered: true,
                                },
                                title: t(`common:document.guest-reissue-url`),
                                body: t(`common:document.guest-reissue-url-label`),
                                action: null,
                            },
                            reissueButtonVisible: true,
                        });
                    }
                }).catch((error) => {
                    if (error?.response) {
                        errorMessage = error?.response?.data?.message || error?.response?.data?.error || "";
                    }
                }).finally(() => {
                    this.setState({
                        reissueLoading: false
                    })
                })
            }

            return (
                <div
                    className="errorPageElements"
                    style={{ textAlign: 'center' }}
                >
                    {this.state.reissueButtonVisible ? (
                        <h3 style={{ textAlign: 'center' }}>{t(`common:message.errors.guest-sign-expiration`)}</h3>
                    ) : (
                        <>
                            <h1
                                style={{textAlign: 'center'}}
                            >{this.props?.codeStatus}</h1>
                            <h5>Page not found.</h5>
                        </>
                    )}

                    {
                        errorMessage && <div style={{whiteSpace: 'pre-line'}}>{errorMessage}</div>
                    }
                    {this.state.reissueButtonVisible && (
                        <LoadingButton
                            className={"mt-4"}
                            onClick={handleReissueGuestDocumentUrl}
                            loading={this.state?.reissueLoading}
                            variant="contained"
                        >{t(`common:document.guest-reissue-url`)}</LoadingButton>
                    )}
                    <img
                        src={bg404} alt="Page not found."
                        className="errorPageElementsimg"
                    />
                </div>
            );
        } else {
            var errMessage = `${msg || "Error "} ${code || ""}`;
            let children = <span style={{whiteSpace: 'pre-line'}}>{errMessage}</span>;
            if (this.props.hasOwnProperty("children")) {
                children = this.props.children;
            }

            // console.log(props);
            return (
                <div
                    style={{
                        textAlign: 'center',
                        width: '100%',
                        padding: '20px 0'
                    }}
                >
                    <p
                        style={{
                            fontSize: '1rem',
                            color: "#828688"
                        }}
                    >{children}</p>
                    <img
                        src={bg404} alt="Page not found."
                        style={{ width: '45%' }}
                    />
                </div>
            );
        }
    }

    render() {
        let { Main, ErrorBody, FormModal } = this;
        let { layout } = this.props;
        // console.log(layout);
        if (layout) {
            return (
                <Main>
                    <div className="page-error">
                        <FormModal />
                        <ErrorBody />
                    </div>
                </Main>
            );
        } else {
            return (
                <div className="error">
                    <FormModal />
                    <ErrorBody />
                </div>
            );
        }
    }
}

export default withTranslation()(Error);
