import React, { useEffect, useRef, useMemo } from 'react';
import {Table} from "react-bootstrap";
import "./ResizableTableWithCache.css";

export const ResizableTableWithCache = (props) => {
    const {children, className, id, storageKey, widthOffset, ...restProps} = props;
    const tableRef = useRef(null);
    const isFirstMount = useRef(true);
    let pageX, curCol, curColWidth, tableWidth, colWidths = {};

    const cachedKey = useMemo(() => {
        if (!storageKey) return undefined;

        const userCache = localStorage.getItem("user-info");
        const userInfo = userCache && userCache !== "undefined" && userCache !== "null" 
            ? JSON.parse(userCache) 
            : null;
        const userId = userInfo?.user_id ?? '';

        return `${storageKey}_${userId}`;
    }, [storageKey]);

    const handleMouseDown = (e) => {
        pageX = e.pageX;
        curCol = e.target.parentElement;
        curColWidth = curCol.offsetWidth;
        tableWidth = tableRef.current.offsetWidth;
    }

    const handleMouseOver = (e) => {
        e.target.style.borderRight = '1px solid #dee2e6';
    }

    const handleMouseOut = (e) => {
        e.target.style.borderRight = '';
    }

    const handleMouseMove = (e) => {
        if (!curCol) return;
        const diffX = e.pageX - pageX;
        if (curColWidth < 50 || curColWidth + diffX >= 50) {
            curCol.style.width = (curColWidth + diffX) + 'px';
            tableRef.current.style.width = tableWidth + diffX + "px";
        }
    }

    const handleMouseUp = () => {
        if (cachedKey && curCol?.id) {
            const newColWidth = {...colWidths, [curCol.id]: curCol.offsetWidth};
            colWidths = newColWidth;
            console.log("==> newColWidth", newColWidth);
        
            localStorage.setItem(cachedKey, JSON.stringify(newColWidth));
        }

        curCol = null;
        pageX = null;
        curColWidth = null;
    }

    useEffect(() => {
        const table = tableRef.current;
        const row = table.getElementsByTagName('tr')[0],
            cols = row ? row.children : undefined;
        if (!cols) return;

        table.style.overflow = 'hidden';

        if (isFirstMount.current) {
            console.log("==> cachedKey", cachedKey);
            if (cachedKey) {
                const storedWidths = JSON.parse(localStorage.getItem(cachedKey)) ?? {};
                console.log("==> storedWidths", storedWidths);
                let cachedTableWidth = widthOffset ?? 0;

                const tableHeight = table.offsetHeight;

                for (let i = 0; i < cols.length; i++) {
                    const col = cols[i];
                    if (col.id) {
                        let width = storedWidths[col.id] ?? col.offsetWidth;
                        col.style.width = width + 'px';
                        col.style.position = 'relative';

                        cachedTableWidth += width;
                        colWidths[col.id] = width;

                        const div = document.createElement('div');
                        div.style.top = 0;
                        div.style.right = 0;
                        div.style.width = '5px';
                        div.style.position = 'absolute';
                        div.style.cursor = 'col-resize';
                        div.style.userSelect = 'none';
                        div.style.height = tableHeight + 'px';
                        col.appendChild(div);
                        
                        div.addEventListener('mousedown', handleMouseDown);
                        div.addEventListener('mouseover', handleMouseOver);
                        div.addEventListener('mouseout', handleMouseOut);
                    }
                    
                }

                console.log("==> cachedTableWidth", cachedTableWidth);
                
                if (cachedTableWidth > 0) {
                    tableRef.current.style.width = cachedTableWidth + "px";
                }
            }
        }

        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);

        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
            isFirstMount.current = false;
        }
    }, []);

    return (
        <div className={"resizable-container"}>
            <Table id={id ?? "tableId"} className={`${className ?? ''} table resizable`} ref={tableRef}>
                {children}
            </Table>
        </div>
    );
}