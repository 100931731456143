// @ts-nocheck
// @ts-ignore

import {
  faFileAlt,
  faFileArchive,
  faFileExcel,
  faFileImage,
  faFilePdf,
  faFileWord,
  faFolderOpen,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import FolderSharedOutlinedIcon from "@mui/icons-material/FolderSharedOutlined";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Email } from "@mui/icons-material";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import SVGIcons from "../../../Pages/Assets/SVGIcons";
import MaterialSymbolIcon from "../../../Pages/Components/material-symbol/material-symbol";

/**
 * @param {{ fileType: string; droppable: any; isOpen?: boolean, node?: any }} props
 */
export default function TypeIcon(props) {
  if (props?.node?.id === "drive") {
    // console.log(props.node);
    const IconFilebox = SVGIcons["filebox"];
    return (
      <div className="custom_drive_folder_icon">
        <IconFilebox />
      </div>
    );
  }

  if (props?.node?.id === "delivery/list") {
    return <MaterialSymbolIcon iconName={"send"} />;
  }

  if (props?.fileType === "empty") {
    return <></>;
  }
  if (props?.fileType === "email") {
    return <Email />;
  }
  if (props?.fileType === "trash") {
    return <MaterialSymbolIcon iconName={"delete"} />;
  }
  switch (props?.node?.type) {
    case 1:
    case 2:
    case 3:
    case 4:
    case 5:
      return <MaterialSymbolIcon iconName={"folder_special"} />;
    case 6:
      return <MaterialSymbolIcon iconName={"folder_shared"} />;
    case 7:
      if (props?.isOpen) {
        return <MaterialSymbolIcon iconName={"folder_open"} />;
      }
      return <MaterialSymbolIcon iconName={"folder"} />;
    case 11:

    default:
      break;
  }
  if (!props.node?.type && !props.url) {
    switch (props?.type) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
        return <LockOutlinedIcon />;

      default:
        break;
    }
  }

  if (
    props?.droppable ||
    props?.fileType === "folder" ||
    props.extension === "folder"
  ) {
    if (props?.isOpen) {
      return <MaterialSymbolIcon iconName={"folder_open"} />;
    }

    return <MaterialSymbolIcon iconName={"folder"} />;
  }
  const fileType = props.extension;
  switch (fileType) {
    case "pdf":
      return (
        <FontAwesomeIcon
          className={`drive_file_icon icon ${fileType}`}
          icon={faFilePdf}
        />
      );
    case "zip":
    case "rar":
    case "7z":
    case "tar":
    case "gz":
      return (
        <FontAwesomeIcon
          className={`drive_file_icon icon ${fileType}`}
          icon={faFileArchive}
        />
      );
    case "image":
    case "png":
    case "jpg":
    case "jpeg":
    case "svg":
    case "gif":
      return (
        <FontAwesomeIcon
          className={`drive_file_icon icon ${fileType}`}
          icon={faFileImage}
        />
      );
    case "csv":
    case "xls":
    case "xlsx":
      return (
        <FontAwesomeIcon
          className={`drive_file_icon icon ${fileType}`}
          icon={faFileExcel}
        />
      );
    case "text":
      return (
        <FontAwesomeIcon
          className={`drive_file_icon icon ${fileType}`}
          icon={faFileWord}
        />
      );
    default:
      return (
        <FontAwesomeIcon
          className={`drive_file_icon icon ${fileType}`}
          icon={faFileAlt}
        />
      );
  }
}
