// @ts-nocheck
import { useHookstate } from "@hookstate/core";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useCallback, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { copyItem, getFoldersDetail, getFoldersTree, moveItem } from "../../data-access/api";
import {
  breabCrumbState,
  driveDetailLoading,
  driveFoldersDetail,
  driveFoldersNav,
  folderDetailSearch,
  notificationAlertState,
  progessCopyState,
  progessMoveState,
  scrollLoadingState,
  searchObjectFolder,
  selectedRowsTreeSearch,
  treeSearchData,
  userInfoState,
} from "../../data-access/state";
import { throttle } from "../../utils/hooks";
import mappedDataToTreeView from "../../utils/mappedDataToTreeView";
import { mappedTableIconByType } from "../../utils/mappedTableIconByFileType";
import searchContentFolderUtils from "../../utils/search-content-folder";
import TreeSearchAutocomplete from "../tree-search-autocomplete/tree-search-autocomplete";
import BaseDialogDriveCrud from "./drive-base-dialog";

const controller = new AbortController();
export default function DriveMoveCopyDialog({
  open,
  onClose,
  title,
  selectedList,
  folderId,
  isCompleteFolder,
  folderType,
}) {
  //   const open = useHookstate(false);
  // const { folderId } = useParams();
  const { t } = useTranslation("common");
  const pathSelect = useHookstate("");
  const methods = useForm({
    defaultValues: { path: "" },
  });
  const moveLoading = useHookstate(false);
  const copyLoading = useHookstate(false);
  const selectedNodes = useHookstate(selectedRowsTreeSearch);
  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    watch,
  } = methods;
  // console.log('isCompleteFolder', isCompleteFolder)
  const inputRef = useRef(null);
  const loading = useHookstate(false);
  const inputValue = useHookstate("");
  const selectedFolder = useHookstate({});
  const folderSearch = useHookstate([]);
  const handleInput = useCallback(
    throttle(searchFolder, 1000, { leading: false }),
    []
  );
  function checkContractCapacity() {
    loading.set(true);
    if (userInfoState.get({ noproxy: true })["over_contract_capacity"]) {
      notificationAlertState.set({
        show: true,
        type: "warning",
        message: `${t("common:tenant.over-contract-capacity")}`,
      });
    }
  }
  function onSubmit(type) {
    const folderIds = selectedList.map((a) => a.id);
    let dest = selectedFolder.get({ noproxy: true, stealth: true });
    if (
      (!dest || !dest?.id) &&
      selectedRowsTreeSearch.get({ noproxy: true }).length > 0
    ) {
      dest = selectedRowsTreeSearch.get({ noproxy: true })[0];
    }
    // console.log(dest, selectedRowsTreeSearch.get());
    const moveOrCopyList = selectedList.reduce(
      (arr, item) => {
        if (item?.document_id) {
          arr.document_id_list.push(item.document_id);
        } else if (item.extension === "folder") {
          arr.drive_folder_id_list.push(item.id);
        } else {
          // console.log(item);
          arr.drive_file_id_list.push(item.id);
        }
        return arr;
      },
      { document_id_list: [], drive_file_id_list: [], drive_folder_id_list: [] }
    );
    // console.log(
    //   moveOrCopyList.drive_folder_id_list,
    //   selectedList.some((a) => [1, 2, 3, 4, 5, 6, 7, 11].includes(a.type))
    // );
    if (
      selectedList.some(
        (a) =>
          [1, 2, 3, 4, 5, 6, 7, 11].includes(a.type) && a.extension === "folder"
      )
    ) {
      return alert(
        "Cannot move or copy default folder (company folder, private folder, complete folders)"
      );
    }
    if (type === "move") {
      // console.log(moveOrCopyList, selectedList);
      moveLoading.set(true);
      progessMoveState.set({
        complete: 0,
        total: selectedList.length,
      });
      moveItem(moveOrCopyList, dest.id === "drive" ? null : dest.id)
        .then((r) => {
          setTimeout(() => {
            progessMoveState.total.set(0);
            progessMoveState.complete.set(0);
          }, 100);
          if (!r) return;
          if (
            !driveFoldersDetail?.data[folderId ? folderId : "root"]?.get({
              stealth: true,
            })
          ) {
            driveFoldersDetail?.data?.merge({
              [folderId ? folderId : "root"]: [],
            });
          }
          driveFoldersDetail?.data[folderId ? folderId : "root"]?.set((v) => {
            return v.filter((a) => {
              return !folderIds.includes(a.id);
            });
          });
          // console.log("runnnnnn");
          driveFoldersNav.set((v) => {
            return v.map((a) => {
              if (moveOrCopyList.drive_folder_id_list.includes(a.id)) {
                const oldParent = v.findIndex((b) => b.id === a.parent_id);
                if (oldParent > -1) {
                  v[oldParent].size = v[oldParent].size - 1;
                  if (
                    v.filter((c) => c.parent_id === a.parent_id)?.length < 2
                  ) {
                    v[oldParent]["notShowDropDownIcon"] = 1;
                    v[oldParent]["is_empty"] = 1;
                  }
                }
                a.parent = dest.id ? dest.id : "drive";
                a.parent_id = dest.id ? dest.id : "drive";
                a.type = dest.type === 6 || dest.type === 9 ? 9 : 8;
              }
              // console.log(a.id, dest.id);
              if (a.id == dest.id) {
                a["notShowDropDownIcon"] = 0;
                a["is_empty"] = 0;
              }
              return a;
            });
          });
          if (searchObjectFolder.isSearching.get({ stealth: true })) {
            searchObjectFolder.offset_document_number.set(0);
            searchObjectFolder.offset_folder_number.set(0);
            searchObjectFolder.offset_file_number.set(0);
            searchObjectFolder.isStop.set(0);
            folderDetailSearch.offset_folder_number.set((v) => 0);
            folderDetailSearch.offset_file_number.set((v) => 0);
            searchContentFolderUtils(
              undefined,
              searchObjectFolder,
              folderId,
              scrollLoadingState,
              driveDetailLoading,
              breabCrumbState,
              false
            );
          }

          // const tree = driveFoldersNav.get({
          //   stealth: true,
          //   noproxy: true,
          // });
          // driveFoldersNav.set((v) => {
          //   return v.map((a) => {
          //     return a;
          //   });
          // });
        })
        .finally(() => {
          pathSelect.set("");
          inputValue.set("");
          selectedFolder.set({});
          folderSearch.set([]);
          moveLoading.set(false);
          handleInput("");
          onClose();
        });
      return;
    }

    if (type === "copy") {
      checkContractCapacity();
      copyLoading.set(true);
      copyItem(moveOrCopyList, dest.id).then((r) => {
        if(dest.id == folderId){
          getFoldersDetail(
            dest.id,
            folderDetailSearch.get({
              stealth: true,
              noproxy: true,
            })
          ).then((r) =>
            driveFoldersDetail.data[
              dest.id ? dest.id : "root"
            ].set((v) =>
              [
                ...(r?.data?.payload?.child?.folders.map(mappedDataToTreeView) ||
                  []),
                ...(r?.data?.payload?.child?.files.map(mappedDataToTreeView) ||
                  []),
                ...v,
              ].reduce((arr, item) => {
                if (!arr.find((a) => a.id === item.id)) {
                  arr.push(item);
                }
                return arr;
              }, [])
            )
          );
        }
        getFoldersTree({
          page_number: 1,
          page_size: 100,
          parent_id: dest?.id || folderId || null,
        }).then((r2) => {
          setTimeout(() => {
            progessCopyState.total.set(0);
            progessCopyState.complete.set(0);
          }, 100);
          if (r2.data.payload) {
            driveFoldersNav.set((v) => {
              const newData = [
                ...v,
                ...r2.data.payload.map(mappedDataToTreeView),
              ].reduce((arr, item) => {
                if (item.id === dest.id) {
                  item = {
                    ...item,
                    is_empty: 0,
                    notShowDropDownIcon: 0,
                  };
                }
                if (!arr.find((b) => b.id == item.id)) {
                  arr.push(item);
                }
                return arr;
              }, []);
              return newData;
            });
          }

          // const tree = driveFoldersNav.get({
          //   stealth: true,
          //   noproxy: true,
          // });
        });
        copyLoading.set(false);
        pathSelect.set("");
        inputValue.set("");
        selectedFolder.set({});
        folderSearch.set([]);
        handleInput("");
        onClose();
      });
    }
  }

  function searchFolder(e) {
    loading.set(true);

    const moveOrCopyList = selectedList.reduce(
      (arr, item) => {
        if (item?.document_id) {
          arr.document_id_list.push(item.document_id);
        } else if (item.extension === "folder") {
          arr.drive_folder_id_list.push(item.id);
        } else {
          arr.drive_file_id_list.push(item.id);
        }
        return arr;
      },
      { document_id_list: [], drive_file_id_list: [], drive_folder_id_list: [] }
    );
    // console.log('moveOrCopyList', moveOrCopyList, selectedList);
    return getFoldersTree(
      {
        search: e,
        page_number: 1,
        page_size: 50,
        nameSearch: 1,
        parent_id: "null",
        current_folder_id_list:
          moveOrCopyList.drive_folder_id_list.length > 0
            ? moveOrCopyList.drive_folder_id_list.toString()
            : "",
      },
      controller.signal
    )
      .then((r) => {
        // console.log(r.data.payload);
        treeSearchData.set([...r.data.payload.map(mappedDataToTreeView)]);
        if (!r?.data?.payload || r?.data?.payload?.length === 0) {
          folderSearch.set([]);
          return;
        }
        // userListSearch.set([...r.data.payload]);
        folderSearch.set(
          [...r.data.payload.map(mappedDataToTreeView)].reduce((arr, item) => {
            if (
              item.type === 6 &&
              userInfoState.get({ noproxy: true })?.tenant_name
            ) {
              item.name = userInfoState.get({ noproxy: true }).tenant_name;
            }
            // console.log(r.data.payload.find((a) => a.id === item.parent_id), selectedList.find((b) => b.id == item.id));
            if (!r.data.payload.find((a) => a.id === item.parent_id)) {
              item.parent_id = "drive";
              item.parent = "drive";
            }
            if (!selectedList.find((b) => b.id == item.id)) {
              arr.push(item);
            }
            // return ;
            return arr.sort((a, b) => {
              if (a.type === 7) {
                return -1;
              } else {
                return 0;
              }
            });
          }, [])
        );
        // console.log(r);
      })
      .finally(() => loading.set(false));
  }
  // const openAutocompleteTree = useHookstate({
  //   anchorEl: null,
  //   open: false,
  // });
  // function handleClose() {
  //   openAutocompleteTree.open.set(false);
  // }
  useEffect(() => {
    if (open) searchFolder("");
  }, [open]);

  // useEffect(() => {
  //   console.log(selectedNodes.value);
  // }, [selectedNodes]);
  // console.log(selectedList);
  return (
    <div>
      <BaseDialogDriveCrud
        handleClose={(e) => {
          pathSelect.set("");
          inputValue.set("");
          selectedFolder.set({});
          folderSearch.set([]);
          // handleInput("");
          return onClose(e);
        }}
        open={open}
        title={t("common:drive.button.menu.move_copy")}
        // フォルダ作成
        dialogContentComponent={
          <>
            {t("common:drive.move_copy_dialog.select_dest")}
            {/* 移動またはコピー先のフォルダを選択します */}

            {/* <Menu
              id="lock-menu"
              anchorEl={openAutocompleteTree.anchorEl.get({ noproxy: true })}
              open={openAutocompleteTree.open.get()}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "lock-button",
                role: "listbox",
              }}
            >
            </Menu> */}
            {selectedList && selectedList.length > 0
              ? selectedList.map((a) => (
                  <div key={a.id}>
                    <List>
                      <ListItem disablePadding>
                        <ListItemButton>
                          <ListItemIcon className="move_dialog_item_icon">
                            {mappedTableIconByType(a)}
                          </ListItemIcon>
                          <ListItemText primary={a?.name} />
                        </ListItemButton>
                      </ListItem>
                    </List>
                    {/* <Divider /> */}
                  </div>
                ))
              : null}
            <TreeSearchAutocomplete
              treeData={folderSearch}
              loading={loading}
              defaultTree={folderSearch.get({ noproxy: true })}
              searchInputCb={handleInput}
            />

            {/* <DriveBreadcrumb noLink={true} /> */}
          </>
        }
        dialogActionComponent={
          <>
            <Button
              variant="outlined"
              onClick={(e) => {
                inputValue.set("");
                pathSelect.set("");
                selectedFolder.set({});
                folderSearch.set([]);
                // handleInput("");
                return onClose(e);
              }}
            >
              {t("common:drive.move_copy_dialog.cancel_btn", "キャンセル")}
            </Button>
            <LoadingButton
              loading={copyLoading.get()}
              variant="contained"
              onClick={() => onSubmit("copy")}
            >
              {t("common:drive.move_copy_dialog.copy_btn", "コピー")}
            </LoadingButton>
            {!isCompleteFolder ? (
              <LoadingButton
                loading={moveLoading.get()}
                variant="contained"
                onClick={() => onSubmit("move")}
              >
                {t("common:drive.move_copy_dialog.move_btn", "移動")}
              </LoadingButton>
            ) : null}
          </>
        }
      />
    </div>
  );
}
