import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import { Fragment } from "react";

export function BaseCard(props) {
  return (
    <Box sx={{ minWidth: 275 }} className={`base_card_box ${props?.className || ''}`}>
      <Card variant="outlined" color={props.color ? props.color : "primary"}>
        <Fragment>
          <CardContent>{props.content}</CardContent>
          {props.actions ? <CardActions>{props.actions}</CardActions> : null}
        </Fragment>
      </Card>
    </Box>
  );
}

export default BaseCard;
