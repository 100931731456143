import React from 'react';
import {withTranslation} from "react-i18next";

import OtpDialog from '../Auth/OtpDialog';
import Layout from '../../Layout';
import Service from '../../Service';
import DocumentDetail from './DocumentDetail';
import Error from '../Error';
import Main from "../../Main";
import Menu from '../Main/Menu';
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import Loading from "../Loading";
import { getDocumentDetail, getSignGuestDocument, getDocumentSignatureInfo } from '../../data-access/documents/document.api';

class DocumentDetailPage extends Layout {

    constructor(props) {
        super(props);
        this.state.page = null;
        this.state.loading = false;
        this.state.documentLoading = true;
        this.state.isVerifiedGuest = true;
        this.state.document_id = props.match.params.document_id;
        this.state.token = props.match.params.token;
        this.state.docInfo = null;
        this.state.openOtpDialog = false;
        this.state.verified = true;
        this.state.msgPageError = null;
        this.state.loadingSignatureInfo = false;
    }

    Menu = () => {
        return (<Menu {...this.props} page={this.state.page} />);
    }

    componentDidMount() {
        super.componentDidMount();
        let { document_id, token } = this.state;
        // console.log('o21412412414k', this.props)
        if (document_id) {
            this.GetDocumentInfo(document_id);
        } else if (token) {
            // Service.GetGuestUserInfo(token).then(resp => {
            //     if (resp.is_applying_guest_mfa) {
            //         this.setState({ verified: true, UserInfo: resp, openOtpDialog: true, isVerifiedGuest: false }, () => { });
            //     } else {
            //         this.setState({ UserInfo: resp, verified: true }, () => {
            //             this.GetGuestDocumentInfo(token);
            //         });
            //     }
            // }).catch(err => {
            //     console.log(err.response);
            //     let msgPageError = "auth.general.expired-token-url";
            //     if (err?.response?.data?.hasOwnProperty("error")) {
            //         msgPageError = err.response.data.error;
            //     }
            //     this.setState({ verified: false, msgPageError });
            // }).finally(() => {
            //     this.setState({ documentLoading: false});
            // });

            getSignGuestDocument(token).then(resp => {
				this.setState({ loading: false, docInfo: resp.data.payload });
			}).catch(err => {
				console.log(err);
				if (err.response.data.payload.is_applying_guest_mfa) {
					console.log('is_applying_guest_mfa');
					this.setState({ loading: false, verified: true, openOtpDialog: true, isVerifiedGuest: false }, () => {
						this.GetGuestDocumentInfo(token);
					});
				}
				else {
					let msgPageError = "message.errors.document-not-found";
					if (err?.response?.data?.hasOwnProperty("error")) {
						msgPageError = err.response.data.error;
					} else if (err.response.data.hasOwnProperty("message")) {
						msgPageError = err.response.data.message;
					}
                    let { t } = this.props;
                    if (err?.code === 'ECONNABORTED' || (err?.response?.status === 504 && err?.response?.data?.message === 'Endpoint request timed out')) {
                        msgPageError = t('message.error.request.timed_out',  `サーバーが混雑しております。\n しばらく待ってアクセスしてください。`) ;
                        // return;
                    } 
					this.setState({ loading: false, msgPageError });
				}
			});
        }
    }

    GetDocumentInfo = (document_id) => {
        const { history } = this.props;
        let previousPage = null;
        // console.log('o21412412414k', this.props)
        if (history.location.state?.from) {
            previousPage = history.location.state.from;
            this.setState({page: history.location.state.from});
        }
        getDocumentDetail(document_id).then(r => {
            const resp = r.data.payload;
            let docDetail = resp;
            // console.log('resp detail page', resp);
            const pageType = resp.function_code;
            if (resp?.received_date) {
                resp.received_date = resp.received_date.substring(0, 10);
            }
            this.setState({ docInfo: resp, loadingSignatureInfo: true, page: previousPage || pageType.charAt(0).toUpperCase() + pageType.slice(1) }, () => {});

            getDocumentSignatureInfo(document_id).then(r1 => {
                const respSig = r1.data.payload;
                console.log('resp signature info', respSig);
                docDetail.signaturesInfo = respSig.signaturesInfo;
                docDetail.timestampsInfo = respSig.timestampsInfo;
                this.setState({ docInfo: docDetail }, () => {});
                console.log('after setstate', this.state.docInfo);
            }).finally(()=> {
                this.setState({loadingSignatureInfo: false});
            })

        }).catch(err => {
            let msgPageError = "message.errors.document-not-found";
            if (err?.response?.data?.hasOwnProperty("error")) {
                msgPageError = err.response.data.error;
            } else if (err.response.data.hasOwnProperty("message")) {
                msgPageError = err.response.data.message;
            }
            let { t } = this.props;
            if (err?.code === 'ECONNABORTED' || (err?.response?.status === 504 && err?.response?.data?.message === 'Endpoint request timed out')) {
                msgPageError = t('message.error.request.timed_out',  `サーバーが混雑しております。\n しばらく待ってアクセスしてください。`) ;
                // return;
            } 
            this.setState({ msgPageError });
        }).finally(() => {
            this.setState({ documentLoading: false});
        });
    }

    GetGuestDocumentInfo = (token) => {
        getSignGuestDocument(token).then(resp => {
            this.setState({ loading: false, docInfo: resp.data.payload });
        }).catch(err => {
            let msgPageError = "message.errors.document-not-found";
            if (err?.response?.data?.hasOwnProperty("error")) {
                msgPageError = err.response.data.error;
            } else if (err.response.data.hasOwnProperty("message")) {
                msgPageError = err.response.data.message;
            }
			let { t } = this.props;
			if (err?.code === 'ECONNABORTED' || (err?.response?.status === 504 && err?.response?.data?.message === 'Endpoint request timed out')) {
				msgPageError = t('message.error.request.timed_out',  `サーバーが混雑しております。\n しばらく待ってアクセスしてください。`) ;
				// return;
			} 
            this.setState({ documentLoading: false, msgPageError });
        });
    }

    ReturnButton = (props) => {
        let { t } = this.props;
        let { docInfo } = props;
        const pageType = docInfo.function_code;
        return (
            <div onClick={() => {
                const { history } = this.props;
                if(!history?.location?.state?.from){
                    switch (docInfo?.function_type_id) {
                      case 1:
                        history.push(`/signer`);
                        return;
                      case 2:
                        history.push(`/sender`);
                        return;
                      case 3:
                        history.push(`/circle`);
                        return;
                      case 4:
                        history.push(`/verification`);
                        return;
                      default:
                        break;
                    }
                }
                if (history?.location?.state?.from) {
                    history.push(`/${history.location.state.from.toLowerCase()}`);
                } else {
                    history.push(`/${pageType.charAt(0).toUpperCase() + pageType.slice(1)}`);
                }

            }}>
                <Tooltip title={t("common:document.return-to-list-page")} arrow>
                    <IconButton className='btn-action'>
                        <ArrowBackOutlinedIcon fontSize='inherit' />
                    </IconButton>
                </Tooltip>
            </div>
        );
    }

    BodyWrapper = (props) => {
        const {ReturnButton} = this;
        if (!props.docInfo) {
            return null;
        }
        return (
            <div className={"document-detail static"}>
                {props.docInfo && <DocumentDetail {...props} action={ReturnButton}  />}
            </div>
        );
    }

    AccessDenyMessage = () => {
        let { t } = this.props;
        return (
            <div className={"document-detail error-layout"}>
                <p className={"access-deny-title"}>{t(`common:message.errors.document-access-denied`)}</p>
                {(t(`common:message.errors.document-access-denied-description`)).split("\\n").map(item => (
                    <p>{item}</p>
                ))}
            </div>
        );
    }

    handleConfirmOtpGuest(isDocumentVerifyEmail, resp) {
        if (!isDocumentVerifyEmail) {
            return this.setState({
                isVerifiedGuest: false,
            });
        }
        this.setState({ loading: false, docInfo: resp, isVerifiedGuest: true, openOtpDialog: false });

    }

    onResendUserMail() {
        // Service.GetGuestUserInfo(this.state.token).bind(this);
		getSignGuestDocument(this.state.token).bind(this);
    }

    render() {
        let { t } = this.props;
        let { docInfo, token, documentLoading, verified, isVerifiedGuest, openOtpDialog, msgPageError, loadingSignatureInfo } = this.state;
        let { Main, BodyWrapper, AccessDenyMessage } = this;

        if (!isVerifiedGuest) {
            return <OtpDialog resendEmailCode={this.onResendUserMail.bind(this)} tokenGuest={token}
                              openOtpDialog={openOtpDialog} isVerifyGuestDocument={true} mfaChallengeName={''}
                              mfaUser={''} callback={this.handleConfirmOtpGuest.bind(this)} />;
        }

        if (!verified) {
            return <Error code={404} msg={t(`common:${msgPageError || 'message.error.server_unknown_error'}`)} layout={!token} />;
        }

        return (
            <>
                <div className="d-sm-block">
                    <Main>
                        {documentLoading ? (
                            <Loading/>
                        ) : (
                            <BodyWrapper docInfo={docInfo} token={token} loadingSignatureInfo={loadingSignatureInfo} />
                        )}

                        {msgPageError && msgPageError === "message.errors.document-not-found-or-access-denied" && (
                            <AccessDenyMessage />
                        )}
                    </Main>
                </div>
            </>
        );

    }

}

export default withTranslation()(DocumentDetailPage);
