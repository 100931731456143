// @ts-nocheck
import { useHookstate } from "@hookstate/core";
import DeleteIcon from "@mui/icons-material/Delete";
import { Tooltip } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import BaseTable from "../../components/drive-base-table/drive-base-table";
import DriveSearch from "../../components/drive-search/drive-search";
import {
  cancelDelivery,
  deleteDelivery,
  getDetailDeliveryHost,
  getListDelivery,
} from "../../data-access/delivery/delivery.api";
import StopCircleOutlinedIcon from "@mui/icons-material/StopCircleOutlined";
import {
  deliveryListState,
  deliverySearchState,
} from "../../data-access/delivery/delivery.state";
import styles from "./drive-delivery.module.css";
import DeliveryConfirmDialog from "../../components/drive-dialogs/drive-delivery-confirm";
import DriveShareDialog from "../../components/drive-dialogs/drive-share-dialog";
import MaterialSymbolIcon from "../../../Pages/Components/material-symbol/material-symbol";
export default function DriveDelivery() {
  const { t } = useTranslation();
  const tableDeliveryHeader = [
    {
      key: "subject",
      title: t("common:drive.delivery.table_header.title", "タイトル"),
      type: "anchor",
      width: "50%",
      onClickCallback: onClickTitleCb,
    },
    {
      key: "status",
      title: t("common:drive.delivery.table_header.status", "配信状況"),
      type: "statusDelivery",
      width: "15%",
    },
    {
      key: "total_emails",
      title: t(
        "common:drive.delivery.table_header.destination",
        "配信先(件数)"
      ),
      width: "10%",
    },
    {
      key: "created_date",
      title: t("common:drive.delivery.table_header.date_time", "配信日時"),
      type: "dateIso",
      width: "15%",
    },
  ];
  const loading = useHookstate(false);
  const confirmDialog = useHookstate({
    open: false,
    loading: false,
    type: "",
    message: "",
    title: "",
  });
  const openShareDialog = useHookstate({
    open: false,
    detail: null,
    loadingDetail: false,
  });
  const scrollLoading = useHookstate(false);
  const deliveryList = useHookstate(deliveryListState);
  const selectedList = useHookstate([]);
  const deliverySearch = useHookstate(deliverySearchState);
  // const isEnableSelectAble = u;
  function onEndReached() {}
  function onClickTitleCb(row) {
    // console.log(row);
    openShareDialog.open.set(true);
    openShareDialog.loadingDetail.set(true);
    getDetailDeliveryHost(row.drive_delivery_id).then((r) => {
      // console.log(r);
      // openShareDialog.open.set(true);
      openShareDialog.detail.set(r.data.payload);
      openShareDialog.loadingDetail.set(false);
    });
    // openShareDialog.open.set(true);
  }

  function onCheckSelectedAll(checked) {
    if (checked) {
      selectedList.set([...deliveryList.data.value]);
    } else {
      selectedList.set([]);
    }
  }

  function onHoldShiftMultiSelect(row, previousIndex, currentIndex) {
    const checkedList = [];
    const list = deliveryList.data.get({ stealth: true, noproxy: true });
    const currentIndexRow = list[previousIndex];
    const checkedCurrent = !!selectedList
      .get({ stealth: true })
      .find((a) => a.drive_delivery_id === row.drive_delivery_id);
    list.forEach((a, i) => {
      if (previousIndex < currentIndex) {
        if (i > previousIndex && i < currentIndex) {
          // const findIdx = selectedList
          //   .get({ stealth: true })
          //   .findIndex((b) => b.drive_delivery_id === a.drive_delivery_id);
          if (checkedCurrent) {
            checkedList.push(a.drive_delivery_id);
          } else {
            selectedList.merge([a]);
          }
        }
      } else {
        if (i > currentIndex && i < previousIndex) {
          // const findIdx = selectedList
          //   .get({ stealth: true })
          //   .findIndex((b) => b.drive_delivery_id === a.drive_delivery_id);
          if (checkedCurrent) {
            checkedList.push(a.drive_delivery_id);
          } else {
            selectedList.merge([a]);
          }
        }
      }
    });
    selectedList.set((v) => {
      return v.filter((a) => !checkedList.includes(a.drive_delivery_id));
    });
    const newIndex = selectedList
      .get({ stealth: true })
      .findIndex((a) => a.drive_delivery_id === row.drive_delivery_id);
    if (newIndex > -1) {
      selectedList.set((v) => {
        v.splice(newIndex, 1);
        const selectedIndexCurrentSelectRow = v.findIndex(
          (a) => a.drive_delivery_id === currentIndexRow.drive_delivery_id
        );
        if (selectedIndexCurrentSelectRow > -1) {
          v.splice(selectedIndexCurrentSelectRow, 1);
        }
        return v;
      });
    } else {
      const selectedIndexCurrentSelectRow = selectedList
        .get({ stealth: true })
        .findIndex(
          (a) => a.drive_delivery_id === currentIndexRow.drive_delivery_id
        );
      if (selectedIndexCurrentSelectRow === -1) {
        selectedList.merge([{ ...currentIndexRow }]);
      }
      selectedList.merge([{ ...row }]);
    }
  }

  function onHoldControlMultiSelect(row) {
    selectedList.set((v) => {
      const index = v.findIndex(
        (a) => a.drive_delivery_id === row.drive_delivery_id
      );
      if (index > -1) {
        v.splice(index, 1);
      } else {
        v.push(row);
      }
      return v;
    });
  }

  function handleSearchKeyEnter() {
    submitSearchDelivery();
  }

  function handleChangeSearch(e) {
    deliverySearch.search.set(e.target.value);
  }

  function handleClearSearch() {
    deliverySearch.search.set("");
    submitSearchDelivery();
  }

  function onClickRow(/** @type {any} */ ev, /** @type {any} */ row) {
    // console.log(row, selectedListFile);
    if (
      selectedList
        .get({ stealth: true, noproxy: true })
        .find((a) => a.drive_delivery_id == row.drive_delivery_id) &&
      selectedList.get({ stealth: true }).length === 1
    ) {
      selectedList.set([]);
    } else {
      selectedList.set([{ ...row }]);
    }
  }

  function deleteButtonCallback(e, isCallback, isOpen) {
    // console.log(e, isCallback, isOpen);
    if (isCallback === "backdropClick") {
      confirmDialog.set({
        open: false,
        loading: false,
        type: "",
        message: "",
        title: "",
      });
      return;
    }
    if (isCallback) {
      confirmDialog.loading.set(true);
      const mapDeli = selectedList
        .get({ stealth: true, noproxy: true })
        .map((a) => a.drive_delivery_id);
      deleteDelivery(mapDeli)
        .then((r) => {
          deliveryList.total.set((v) => v - selectedList.length);
          deliveryList.data.set((v) => {
            return v.filter((a) => !mapDeli.includes(a.drive_delivery_id));
          });
          selectedList.set([]);
          confirmDialog.set({
            open: false,
            loading: false,
            type: "",
            message: "",
            title: "",
          });
        })
        .finally(() => {
          confirmDialog.loading.set(false);
        });
      return;
    }
    if (isOpen && !isCallback) {
      confirmDialog.set({
        open: true,
        loading: false,
        type: "delete",
        message: t("common:drive.delete_confirm.message"),
        title: t("common:drive.delete_confirm.title"),
      });
    }

    if (!isCallback && !isOpen) {
      confirmDialog.set({
        open: false,
        loading: false,
        type: "",
        message: "",
        title: "",
      });
    }
  }

  function cancelDeliveryCallback(e, isCallback, isOpen) {
    if (isCallback === "backdropClick") {
      confirmDialog.set({
        open: false,
        loading: false,
        type: "",
        message: "",
        title: "",
      });
      return;
    }
    if (isCallback) {
      confirmDialog.loading.set(true);
      const mapDeli = selectedList
        .get({ stealth: true, noproxy: true })
        .map((a) => a.drive_delivery_id);
      cancelDelivery(mapDeli)
        .then((r) => {
          selectedList.set([]);
          deliveryList.data.set((v) => {
            return v.filter((a) => {
              if (mapDeli.includes(a.drive_delivery_id)) {
                a.status = 2;
              }
              return a;
            });
          });
          confirmDialog.set({
            open: false,
            loading: false,
            type: "",
            message: "",
            title: "",
          });
        })
        .finally(() => {
          confirmDialog.loading.set(false);
        });
      return;
    }

    if (isOpen) {
      confirmDialog.set({
        open: true,
        loading: false,
        type: "cancel",
        message: t("common:drive.button.delivery.cancel_all"),
        title: t("common:drive.confirm.title.cancel_all"),
      });
    }

    if (!isCallback && !isOpen) {
      confirmDialog.set({
        open: false,
        loading: false,
        type: "",
        message: "",
        title: "",
      });
    }
  }

  function submitSearchDelivery() {
    loading.set(true);
    getListDelivery(deliverySearch.get({ stealth: true, noproxy: true }))
      .then((r) => {
        deliveryList.data.set(r.data.payload);
        deliveryList.total.set(r.data.total);
      })
      .finally(() => loading.set(false));
  }

  useEffect(() => {
    submitSearchDelivery();
  }, []);

  return (
    <div>
      <DriveShareDialog
        loadingDetail={openShareDialog.loadingDetail.get()}
        open={openShareDialog.open.get({ noproxy: true })}
        onClose={() => openShareDialog.open.set(false)}
        dataDetail={openShareDialog.detail.get({ noproxy: true })}
        selectedItems={[]}
        stateDialog={openShareDialog}
      />
      <DeliveryConfirmDialog
        title={confirmDialog.title.get()}
        onClose={
          confirmDialog.type.get() === "delete"
            ? deleteButtonCallback
            : cancelDeliveryCallback
        }
        open={confirmDialog.open.get()}
        loading={confirmDialog.loading.get()}
        message={confirmDialog.message.get()}
      />
      <BaseTable
        loading={loading.get({ noproxy: true })}
        enableSelectable={true}
        selectRowKey={"drive_delivery_id"}
        enableVirtualScrolled={true}
        endReached={onEndReached}
        overscan={200}
        scrollLoading={scrollLoading.get()}
        totalRecord={deliveryList.total.get()}
        selectedRows={selectedList.get({ noproxy: true })}
        onCheckSelectedAll={onCheckSelectedAll}
        onHoldShiftMultiSelect={onHoldShiftMultiSelect}
        onHoldControlMultiSelect={onHoldControlMultiSelect}
        headerActionsComponent={
          <div className="drive_table_header_action">
            <div className={styles["delivery_header"]}>
              <div className={styles["text_header"]}>
                {t("common:drive.delivery.search.header", "全ての配信")}
              </div>
              <div className={styles["delivery_header_action"]}>
                <DriveSearch
                  className="search-round-container-drive"
                  handleSearchKeyEnter={handleSearchKeyEnter}
                  searchValue={deliverySearch.search.get({ noproxy: true })}
                  handleChangeSearch={handleChangeSearch}
                  handleClearSearch={handleClearSearch}
                  placeholder={t("common:drive.search.delivery.placeholder")}
                  // 配信を検索
                />
                {selectedList.get().length > 0 ? (
                  <div className={styles["icon_actions"]}>
                    <MaterialSymbolIcon
                      tooltip={t(
                        "common:drive.delivery.button.cancel_delivery"
                      )}
                      onClick={() =>
                        cancelDeliveryCallback(undefined, undefined, true)
                      }
                      iconName={"stop_circle"}
                    />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        }
        // order={[]}
        tableHeader={tableDeliveryHeader}
        tableRows={deliveryList.data.get({ noproxy: true })}
        onDblClickRowHandle={(
          /** @type {any} */ ev,
          /** @type {any} */ rowSelect
        ) => {
          const row = { ...rowSelect };
        }}
        onClickRowHandle={onClickRow}
      />
    </div>
  );
}
