import { TextField } from "@mui/material";
import styles from "./base-text-field.module.css";
export default function BaseTextField(props) {
  const { customStyles = {}, ...restProps } = props;

  return (
    <TextField
      {...restProps}
      className={`${styles["bg-white"]} `}
      size="small"
      classes={{disabled: styles['disabled_input']}}
      sx={{
        "& .MuiInputBase-input": customStyles,
        "& .MuiInputBase-input.Mui-disabled": {
          WebkitTextFillColor: "#9398ab",
          background:"#e2e2e8",
        },
      }}
      InputLabelProps={{ style: { fontSize: 14 } }}
      // value={textInputValue}
      // placeholder={textInputPlaceHolder}
      // label={textFieldLabel || 'Filled'}
      variant={props.variant || "outlined"}
    />
  );
}
