// @ts-nocheck
import { useHookstate } from "@hookstate/core";
import DownloadIcon from "@mui/icons-material/Download";
import { useTranslation } from "react-i18next";
import BaseTable from "../../components/drive-base-table/drive-base-table";

import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import { LoadingButton } from "@mui/lab";
import { useParams } from "react-router";
import DriveShareDialog from "../../components/drive-dialogs/drive-share-dialog";
import { downloadFileActions } from "../../data-access/actions";
import {
  downloadFilesGuest,
  downloadFilesGuestEc2,
} from "../../data-access/delivery/delivery.api";
import { deliveryDetailState } from "../../data-access/delivery/delivery.state";
import {
  notificationAlertState,
  progessZippingState,
} from "../../data-access/state";
import { progessCallback } from "../../utils/progess-callback";
import styles from "./drive-shared.module.css";
import DeliveryConfirmDialog from "../../components/drive-dialogs/drive-delivery-confirm";
import DriveConfirmDialog from "../../components/drive-dialogs/drive-confirm-base";
export default function DriveShared() {
  const { t } = useTranslation();
  const { token } = useParams();
  const blockDownloadDialog = useHookstate({
    open: false,
    title: t("common:drive.alert.shared_folder.excess_download.title", `Alert`),
    message: t(
      "common:drive.alert.shared_folder.excess_download.message",
      `ダウンロード回数の制限を超えました。\n
      ダウンロードできません。`
    ),
    loading: false,
  });
  const tableDeliveryHeader = [
    {
      key: "spacer",
      width: "40px",
    },
    {
      key: "name",
      title: t("common:drive.table_header.name", "名前"),
      type: "icon",
      width: "auto",
      iconMapped: true,
      // sort: true,
      // sortCallback: () => {
      //   // orderBy.set("name");
      //   return onSort("name");
      // },
    },
    {
      key: "size",
      title: t("common:drive.table_header.size", "サイズ"),
      type: "size",
      width: "15%",
    },
    {
      key: "download_status",
      title: t("common:drive.delivery.table_header.status", "ステータス"),
      type: "statusShared",
      width: "15%",
    },
    // {
    //   key: "",
    //   title: t("common:drive.delivery.table_header.download", "ダウンロード"),
    //   width: "35%",
    //   type: "actions",
    //   icon: CloudDownloadOutlinedIcon,
    //   callback: download,
    // },
  ];
  const order = useHookstate({
    name: "asc",
  });
  const loading = useHookstate(false);
  // const isEnableSelectAble = u;
  const openShareDialog = useHookstate({
    open: false,
    detail: null,
    loadingDetail: false,
  });
  const scrollLoading = useHookstate(false);
  const deliveryList = useHookstate(deliveryDetailState);
  const selectedList = useHookstate([]);
  const loadingDownload = useHookstate(false);

  // function onSort() {}
  // function download(row) {
  //   if (
  //     deliveryList.downloaded_number.get({
  //       stealth: true,
  //       noproxy: true,
  //     }) ===
  //     deliveryList.download_count_setting.get({
  //       stealth: true,
  //       noproxy: true,
  //     })
  //   ) {
  //     blockDownloadDialog.open.set(true);
  //     return;
  //   }
  //   if (row?.extension === "folder") {
  //     progessZippingState.open.set(true);
  //     downloadFilesGuestEc2({
  //       drive_file_id_list: [],
  //       document_id_list: row.document_id ? [row.document_id] : [],
  //       // drive_delivery_id: deliveryList.drive_delivery_id.get({
  //       //   stealth: true,
  //       // }),
  //       drive_folder_id_list: !row.document_id
  //         ? [row.id || row.drive_folder_id]
  //         : [],
  //       token,
  //     }).then((r) => {
  //       progessZippingState.open.set(false);
  //       deliveryList.folders.set((v) => {
  //         return v.map((a) => {
  //           return {
  //             ...a,
  //             download_status:
  //               row.drive_folder_id === a.drive_folder_id
  //                 ? 1
  //                 : a.download_status,
  //           };
  //         });
  //       });
  //       deliveryList.downloaded_number.set((v) => (v || 0) + 1);
  //       downloadFileActions(
  //         r.data.payload.url,
  //         `download_${row.name}-${new Date().toISOString()}.zip`.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '.'),
  //         progessCallback,
  //         token
  //       ).catch((e) => {
  //         notificationAlertState.set({
  //           show: true,
  //           type: "error",
  //           message: `${t(
  //             "common:drive.message.error.download",
  //             "Download error please try again!"
  //           )} ${e?.response?.data?.message || e}`,
  //         });
  //       });
  //     });
  //   } else {
  //     progessZippingState.open.set(true);
  //     downloadFilesGuestEc2({
  //       drive_file_id_list: !row.document_id ? [row.id] : [],
  //       drive_folder_id_list: [],
  //       document_id_list: row.document_id ? [row.document_id] : [],
  //       // drive_delivery_id: deliveryList.drive_delivery_id.get({
  //       //   stealth: true,
  //       // }),
  //       token,
  //     }).then((r) => {
  //       progessZippingState.open.set(false);
  //       deliveryList.files.set((v) => {
  //         return v
  //           .map((a) => {
  //             return {
  //               ...a,
  //               download_status: row.id === a.id ? 1 : row.download_status,
  //             };
  //           })
  //           .sort((a, b) => a.id - b.id);
  //       });
  //       deliveryList.downloaded_number.set((v) => (v || 0) + 1);
  //       downloadFileActions(
  //         r.data.payload.url,
  //         `download_${row.name}-${new Date().toISOString()}.zip`.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '.'),
  //         progessCallback,
  //         token
  //       ).catch((e) => {
  //         notificationAlertState.set({
  //           show: true,
  //           type: "error",
  //           message: `${t(
  //             "common:drive.message.error.download",
  //             "Download error please try again!"
  //           )} ${e?.response?.data?.message || e}`,
  //         });
  //       });
  //     });
  //   }
  // }
  // console.log(deliveryList.drive_delivery_id.get());
  return (
    <div>
      {deliveryList.drive_delivery_id.get() === 0 ? (
        <div
          style={{
            width: "100%",
            position: "absolute",
            top: "-59px",
            left: "0",
            height: "100vh",
            backgroundColor: "white",
            zIndex: "500",
          }}
          className="main-body-loading"
        >
          {/* <Loading /> */}
        </div>
      ) : null}
      <DriveConfirmDialog
        {...blockDownloadDialog.get({ noproxy: true })}
        closeOnly={true}
        onClose={() => blockDownloadDialog.open.set(false)}
      />
      <DriveShareDialog
        loadingDetail={openShareDialog.loadingDetail.get()}
        open={openShareDialog.open.get({ noproxy: true })}
        onClose={() => openShareDialog.open.set(false)}
        dataDetail={openShareDialog.detail.get({ noproxy: true })}
        selectedItems={[]}
      />
      <BaseTable
        loading={loading.get({ noproxy: true })}
        // enableSelectable={true}
        selectRowKey={"drive_delivery_id"}
        // enableVirtualScrolled={true}
        // endReached={onEndReached}
        // overscan={200}
        // order={order.get({ noproxy: true })}
        scrollLoading={scrollLoading.get()}
        totalRecord={
          deliveryList.files.get().length + deliveryList.folders.get().length
        }
        selectedRows={selectedList.get({ noproxy: true })}
        // onCheckSelectedAll={onCheckSelectedAll}
        // onHoldShiftMultiSelect={onHoldShiftMultiSelect}
        // onHoldControlMultiSelect={onHoldControlMultiSelect}
        headerActionsComponent={
          <div className="drive_table_header_action">
            <div className={styles["delivery_header"]}>
              {/* <div className={styles["text_header"]}>全ての配信</div> */}
              <LoadingButton
                loading={loadingDownload.get()}
                onClick={() => {
                  if (
                    deliveryList.downloaded_number.get({
                      stealth: true,
                      noproxy: true,
                    }) ===
                    deliveryList.download_count_setting.get({
                      stealth: true,
                      noproxy: true,
                    })
                  ) {
                    blockDownloadDialog.open.set(true);
                    return;
                  }
                  loadingDownload.set(true);
                  progessZippingState.open.set(true);
                  downloadFilesGuestEc2({
                    drive_file_id_list: deliveryList.files
                      .get({ stealth: true, noproxy: true })
                      .reduce((arr, item) => {
                        console.log(item);
                        if (!item?.document_id) {
                          arr.push(item.id);
                        }
                        return arr;
                      }, []),
                    drive_folder_id_list: deliveryList.folders
                      .get({ stealth: true, noproxy: true })
                      .map((a) => a.drive_folder_id),
                    document_id_list: deliveryList.files
                      .get({ stealth: true, noproxy: true })
                      .reduce((arr, item) => {
                        if (item.document_id) {
                          arr.push(item.document_id);
                        }
                        return arr;
                      }, []),
                    // drive_delivery_id: deliveryList.drive_delivery_id.get(),
                    token,
                  })
                    .then((r) => {
                      progessZippingState.open.set(false);
                      deliveryList.folders.set((v) => {
                        return v.map((a) => {
                          return { ...a, download_status: 1 };
                        });
                      });
                      deliveryList.files.set((v) => {
                        return v.map((a) => {
                          return { ...a, download_status: 1 };
                        });
                      });
                      deliveryList.downloaded_number.set((v) => (v || 0) + 1);
                      let urlNew = new URL(r?.data?.payload?.url);
                      let fileName = new URLSearchParams(urlNew?.search);
                      downloadFileActions(
                        r.data.payload.url,
                        fileName?.get('file') ? fileName?.get('file') : `${new Date().toISOString()}.zip`.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '.'),
                        progessCallback,
                        token
                      ).catch((e) => {
                        notificationAlertState.set({
                          show: true,
                          type: "error",
                          message: `${t(
                            "common:drive.message.error.download",
                            "Download error please try again!"
                          )} ${e?.response?.data?.message || e}`,
                        });
                      });
                    })
                    .finally(() => {
                      loadingDownload.set(false);
                      progessZippingState.open.set(false);
                    });
                }}
                startIcon={<DownloadIcon />}
                variant="outlined"
                size="small"
                color="secondary"
                className={styles["container"]}
              >
                {t("common:drive.shared_delivery.download_all")}
                {/* すべてダウンロード */}
              </LoadingButton>
            </div>
          </div>
        }
        // order={[]}
        tableHeader={tableDeliveryHeader}
        tableRows={[
          ...deliveryList.files.get(),
          ...deliveryList.folders
            .get()
            .map((a) => ({ ...a, format_type: "folder" })),
        ]}
        onDblClickRowHandle={(
          /** @type {any} */ ev,
          /** @type {any} */ rowSelect
        ) => {}}
        // onClickRowHandle={onClickRow}
      />
    </div>
  );
}
