import React from "react";
import Layout from "../../Layout";
import MainMenu from "../Main/Menu";
import {withTranslation} from "react-i18next";
import Service from "../../Service";
import Loading from "../Loading";
import SVGIcons from "../Assets/SVGIcons";
import {Table,} from "react-bootstrap";
import Error from "../Error";
import DocumentDetail from "../Documents/DocumentDetail";
import BodyDetail from "../Main/BodyDetail";
import Search from "../Main/Search";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Tooltip from "@mui/material/Tooltip";
import DownloadIcon from "@mui/icons-material/Download";
import Button from "@mui/material/Button";
import {getDocumentDetail} from "../../data-access/documents/document.api";
import {Box, MenuItem, Select} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {shedOnScroll} from "../../data-access/app.state";
import {StateFragment} from "@hookstate/core";
import '../Assets/css/layout.css';
import downloadFileUrl from "../../Utils/download-file";
import {ResizableTableWithCache} from "../../Components/resizeable-table-with-cache/ResizableTableWithCache";

class SearchResult extends Layout {
  constructor(props) {
    super(props);
    this.state.page = "SearchResult";
    // this.state.loading = true;
    this.state.documents = null;
    this.state.data = null;
    this.state.info = null;
    this.state.services = null;
    this.state.accounts = null;
    this.state.allServiceLabel = "";
    this.state.action = props.action || null;
    // this.state.formData = {};
    this.state.Currencies = [];
    this.state.PageData = {};
    // this.state.formData.meta_attributes = [];
    this.state.searchOption = {
      ...this.props.searchOption,
      current: 1,
      page: 1,
      total: 0,
    };
    this.state.searchConditionTrue = false;
    this.state.PossiblePrevPage = true;
    this.state.csvLoading = false;
  }

  Menu = () => {
    return <MainMenu {...this.props} page={this.state.page} />;
  };

  componentDidMountExtends = () => {
    // Service.GetUserList("user_type_name", ["hosts", "guests", "groups"]).then((resp) => {
    
    let { searchOption } = this.state;
    this.GetDocumentListData(searchOption);
    // }).catch((err) => {
    //   console.log(err.response);
    // });
  };

  componentDidUpdate = () => {
    // this.GetSearchDataAllItems();
  };

  ServiceIcon = (props) => {
    // console.log(props)
    if (props.code) {
      let Icon = SVGIcons[props.code];
      return <Icon {...props} />;
    }
    return null;
  };

  formSubmitCallback = (ev) => {
    this.setFormData(this.state.formData);
    this.formSubmitSearch();
  };

  PageCalculation = (searchOption) => {
    searchOption.current = searchOption.page_number;
    searchOption.page = Math.ceil(searchOption.total / searchOption.page_size);
    return searchOption;
  }

  GetDocumentListData = (searchOption) => {
    searchOption = this.PageCalculation(searchOption);
    this.setState({ documents: null, searchOption });
    Service.getSearchDocumentList(searchOption)
      .then((resp) => {
        if (resp.ok) {
          let { documents, link_url } = this.state;
          documents = resp.payload.records;
          link_url = resp.payload.link_url;
          searchOption.total = resp.payload.total_records;
          searchOption = this.PageCalculation(searchOption);

          this.setState({ documents, searchOption, link_url });
        }
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  onPageChangeHandler = (ev) => {
    let page = parseInt(ev.target.value);
    let { searchOption } = this.state;
    let nextPage = page;
    if (nextPage !== searchOption.page_number) {
      searchOption.page_number = nextPage;
      this.GetDocumentListData(searchOption);
    }
  }

  onRecordLimitChangeHandler = (ev) => {
    let limit = parseInt(ev.target.value);
    let { searchOption } = this.state;
    if (limit !== searchOption.page_size) {
      let cursor = (searchOption.current - 1) * searchOption.page_size;
      searchOption.page_number = Math.floor(cursor / limit) === 0 ? 1 : Math.floor(cursor / limit);
      searchOption.page_size = limit;
      this.GetDocumentListData(searchOption);
    }
  }

  onSortingHandler = (ev) => {
    let sort = parseInt(ev.target.value);
    let { searchOption } = this.state;
    if (sort !== searchOption.sorting_code) {
      searchOption.sorting_code = sort;
      this.GetDocumentListData(searchOption);
    }
  };

  prevPageData = (ev) => {
    let { searchOption } = this.state;
    let nextPage = searchOption.page_number - 1;
    if (nextPage >= 0) {
      searchOption.page_number = nextPage;
      this.GetDocumentListData(searchOption);
    }
  }

  nextPageData = (ev) => {
    let { searchOption } = this.state;
    let nextPage = searchOption.page_number + 1;
    if (nextPage <= searchOption.page) {
      searchOption.page_number = nextPage;
      this.GetDocumentListData(searchOption);
    }
  }

  ClearSeletedDoc = (ev) => {
    this.setState({ showDetail: false });
    this.setState({ currentDocInfo: null });
  };

  DocumentDetailBody = (props) => {
    // let { t } = this.props;
    let { currentDocInfo, users } = this.state;
    let { ReturnButton } = this;

    if (!currentDocInfo) {
      return <Loading />;
    }
    if (currentDocInfo === false) {
      return <Error />;
    }
    // console.log(currentDocInfo);
    return (
      <DocumentDetail
        docInfo={currentDocInfo}
        users={users}
        action={ReturnButton}
      />
    );
  };

  showDetailPage = (ev, docInfo) => {
    // console.log(docInfo);
    this.setState({ showDetail: true, currentDocInfo: null });
    getDocumentDetail(docInfo.document_id).then((resp) => {
      // console.log(resp);
      if (resp.data.payload?.received_date) {
        resp.data.payload.received_date = resp.data.payload.received_date.substring(0, 10);
    }
      this.setState({ currentDocInfo: resp.data.payload });
    }).catch((err) => {
      console.log(err.response);
    });
  };

  close = (ev) => {
    ev.target.closest(".document-detail").classList.remove("active");
    this.ClearSeletedDoc();
  };

  ReturnButton = () => {
    // let { t } = this.props;
    return (
      <IconButton onClick={this.close}>
        <ArrowBackIcon />
      </IconButton>
    );
  };

  ReturnSearchConditionButton = () => {
    // let { t } = this.props;
    return (
      <IconButton onClick={this.returnSearchCondition}>
        <ArrowBackIcon />
      </IconButton>
    );
  };

  /** [Event] */
  returnSearchCondition = () => {
    let searchConditionTrue = true;
    this.setState({ searchConditionTrue });
  };

  DocumentDetailPage = (props) => {
    let { showDetail } = this.state;
    let { DocumentDetailBody } = this;
    return (
        <BodyDetail active={showDetail}>
          <DocumentDetailBody />
        </BodyDetail>
    );
  };

  RecordSorting = () => {
    let { t } = this.props;
    let { searchOption } = this.state;
    let sortList = {
      2: "common:search.sorting.decending.by-issued-date",
      1: "common:search.sorting.ascending.by-issued-date",
    };
    let sortItems = [];

    for (let i in sortList) {
      sortItems.push(
        <MenuItem value={i} key={i} >{t(sortList[i])}</MenuItem>
      );
    }

    return (
      <Box py={1} px={2}>
        <Select
            value={searchOption.sorting_code}
            onChange={this.onSortingHandler}
            renderValue={() => t(sortList[searchOption.sorting_code])}
            variant={"standard"}
            style={{ color: "#007BFF" }}
        >
          {sortItems}
        </Select>
      </Box>
    );
  };

  AdditionalButtons = () => {
    let { t } = this.props;
    let { searchOption, csvLoading } = this.state;
    let ButtonList = [];

    ButtonList.push(
      <Tooltip key={0} title={t('common:tooltip.common.download-csv')} arrow>
        <Button
          sx={{ mr: 2, mt: 1 }}
          key="list-download"
          variant="outlined"
          size="small"
          startIcon={csvLoading ? <CircularProgress size={16}/> : <DownloadIcon />}
          disabled={csvLoading}
          onClick={this.onListDownloadRequestHandler}
        >
          {t('common:main.general.list-download')}
        </Button>
      </Tooltip>
    );
    if (searchOption.total === 0) {
      ButtonList = [];
    }

    return ButtonList;
  };

  onListDownloadRequestHandler = () => {
    const {searchOption} = this.state;

    this.setState({
      csvLoading: true
    })

    Service.exportSearchDocument(searchOption)
        .then((resp) => {
          if (resp.ok && resp.payload && resp.payload.length > 0) {
            downloadFileUrl(resp.payload);
            // let aLink = document.createElement("a");
            // aLink.href = resp.payload;
            // aLink.style.display = 'none';
            // document.body.appendChild(aLink);
            // aLink.click();
            // document.body.removeChild(aLink);
          }
        }).catch((err) => {
          console.log(err.response);
        }).finally(() => {
          this.setState({
            csvLoading: false
          })
        });
  };

  PageListNavigator = () => {
    let { searchOption } = this.state;
    let navItems = [];
    let currentFrom = 0;
    let pageTo = 0;
    let pageFrom = 0;
    let currentTo = 0;
    let total = searchOption.total;
    let page_number = searchOption.page_number;
    let page_size = searchOption.page_size;
    let current = searchOption.current;
    if (!searchOption.total) { return <CircularProgress size="1rem" /> }
    for (let i = 0; i < searchOption.page; i++) {
      let page = i + 1;
      if (total === 0) {
        pageFrom = 0;
      } else {
        pageFrom = ((page - 1) * page_size) + 1;
      }
      if (total < page * page_size) {
        pageTo = total
      } else {
        pageTo = page * page_size;

      }
      navItems.push(
          <MenuItem value={page} key={page}>{`${pageFrom}-${pageTo}`}</MenuItem>
      );
    }
    if (total === 0) {
      currentFrom = 0;
    } else {
      currentFrom = ((current - 1) * page_size) + 1;
    }
    if (total < current * page_size) {
      currentTo = total
    } else {
      currentTo = current * page_size;
    }

    return (
        <Box py={1} px={2}>
          <Select
              value={current}
              onChange={this.onPageChangeHandler}
              renderValue={() => `${currentFrom} - ${currentTo} / ${searchOption.total}`}
              variant={"standard"}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 250,
                    minWidth: 130
                  },
                },
              }}
              style={{ color: "#007BFF" }}>
            {navItems}
          </Select>
        </Box>
    );
  }

  ItemsList = () => {
    let { t } = this.props;
    let { documents } = this.state;

    if (documents === null) {
      return (
          <Loading />
      );
    }

    if (documents.length === 0) {
      return (
        <Table>
          <tbody>
            <tr className="document-row-list error-tr">
              <td className="record-not-found">
                <Error msg={t("common:message.record.not-found")} />
              </td>
            </tr>
          </tbody>
        </Table>
      );
    }

    let ItemsList = [];
    for (let doc of documents) {
      let btnDetailClass = ["sign-status"];

      if (doc.sign_permission === 1) {
        if (doc.signed === 1) {
          btnDetailClass.push("signed");
        } else {
          btnDetailClass.push("wait-sign");
        }
      }
      if (doc.function_type_id === 4 && doc.status_id === 14) {
        // doc.status_label = "document.status.cannot-timestamp";
        // doc.status_code = "error";
        doc.status_label = "document.status.protected-PDF-supported";
        doc.status_code = "confirmed";
      }
      if (doc.remarks === "protected" && [1, 2, 3].includes(doc.function_type_id)) {
        // if (doc.status_id === 14 && [1, 2, 3].includes(doc.function_type_id)) {
        doc.status_code = "error";
      }

      let Amount = (doc.amount || "0").toString();
      Amount = Amount.split(".");
      Amount[0] = Amount[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      Amount = Amount.join(".") + " " + (doc.currency || "JPY");

      let CounterParty = () => {
        if (doc.function_type_id === 4) {
          return (
            <td className="counter-party-info">
              <div className="inner-container">
                <span>{doc.counter_party_name}</span>
              </div>
            </td>
          );
        } else {
          return (
            <td className="counter-party-info">
              <div className="inner-container"></div>
            </td>
          );
        }
      };

      let PreservationRequire = () => {
        let item_preservation_require = "";

        if (parseInt(doc.preservation_require) === 1) {
          item_preservation_require = "common:documents.document-info.other";
        } else if (parseInt(doc.preservation_require) === 2) {
          item_preservation_require = "common:documents.document-info.new-article-seven";
        } else if (parseInt(doc.preservation_require) === 3) {
          item_preservation_require = "common:documents.document-info.scanner-storage";
        }

        return [
          <td
            key={9}
            className="function-type-info clickable"
            onClick={(ev) => {
              this.showDetailPage(ev, doc);
            }}
          >
            <div className="inner-container">
              <span>{t(item_preservation_require)}</span>
            </div>
          </td>,
        ];
      };

			let linkageClass = "";
			if (doc.master_document_id) {
				linkageClass = " has-linkage";
			}

      ItemsList.push(
        <tr
          key={doc.id}
          className={`document-row-list clickable${linkageClass}`}
          onClick={(ev) => {
            this.showDetailPage(ev, doc);
          }}
        >
          <td className="creator-info">
            <div className="inner-container">
              <span>{doc.full_name}</span>
            </div>
          </td>
          <td className="function-type-info">
            <div className="inner-container">
              <span>{t(`common:${doc.function_type}`)}</span>
            </div>
          </td>
          <PreservationRequire />
          <td className="title-info">
            <div className="inner-container">
              <span>{doc.id + " - " + doc.title}</span>
              <div className={"document-progress " + doc.status_code}>
                <span className="status-label">{t(`common:${doc.status_label}`)}</span>
              </div>
            </div>
          </td>
          {/* <td className="title-info">
						<div className="inner-container pdf_name">
							
							<div className={"document-files"}>
								<PDFFilesInDocument files={doc.files} />
							</div>
						</div>
					</td> */}
          <CounterParty />
          <td className="amount-info">
            <div className="inner-container">
              <span>{Amount}</span>
            </div>
          </td>
          <td className="document-created-date">
            <div className="inner-container">
              <span>{doc.issued_date}</span>
            </div>
          </td>
          {/* <td className="document-delete doc-row-action">
						<DeleteAction />
					</td> */}
        </tr>
      );
    }

    return (
      <ResizableTableWithCache storageKey={`colWidths_SearchResult`}>
        <thead>
        {<StateFragment state={shedOnScroll}>
          {s => (
              <tr className={`${s.get({noproxy: true}) ? "shed" : ""}`}>
                <th className="search-creator" id="search-creator">
                  <div>
                    <span>{t("common:documents.creator")}</span>
                  </div>
                </th>
                <th className="search-function" id="search-function">
                  <div>
                    <span>{t("common:documents.document-info.function-type")}</span>
                  </div>
                </th>
                <th className="search-data" id="search-data">
                  <div>
                <span>
                  {t("common:documents.document-info.preservation-require")}
                </span>
                  </div>
                </th>
                <th className="search-document" id="search-document">
                  <div>
                    <span>{t("common:documents.document-info.imprints")}</span>
                  </div>
                </th>
                {/* <th>
						<div>
						<span>{t("common:documents.document-info.file-name")}</span>
						</div>
					</th> */}
                <th className="search-counter-party" id="search-counter-party">
                  <div>
                    <span>{t("common:documents.document-info.counter-party")}</span>
                  </div>
                </th>
                <th className="search-amount" id="search-amount">
                  <div>
                    <span>{t("common:documents.document-info.amount")}</span>
                  </div>
                </th>
                <th className="search-date" id="search-date">
                  <div>
                    <span>{t("common:documents.issued-date")}</span>
                  </div>
                </th>
              </tr>
          )}
        </StateFragment>}

        </thead>
        <tbody>{ItemsList}</tbody>
      </ResizableTableWithCache>
    );
  };

  render() {
    let { t } = this.props;
    let { searchConditionTrue, searchOption, documents } = this.state;
    const {
      Main,
      ItemsList,
      DocumentDetailPage,
      PageListNavigator,
      RecordSorting,
      ReturnSearchConditionButton,
      AdditionalButtons,
    } = this;

    if (searchConditionTrue) {
      return <Search formData={searchOption} />;
    }

    return (
      <Main>
        <DocumentDetailPage />

        <div
            className="search-container"
            style={documents === null ? {
              height: "100%"
            } : {}}
        >
          <div className="header-wrapper">
            <div className="header-left">
              <ReturnSearchConditionButton/>
              <AdditionalButtons/>
            </div>

            <div className="header-right">
              <RecordSorting/>

              <Box py={1} px={2}>
                <Select
                    value={searchOption.page_size}
                    onChange={this.onRecordLimitChangeHandler}
                    renderValue={() => searchOption.page_size + " " + t("common:search.view.record-per-page")}
                    variant={"standard"}
                    style={{color: "#007BFF"}}
                >
                  {[100, 200, 500, 1000].map((count, index) =>
                      <MenuItem value={count}
                                key={index}>{count + " " + t("common:search.view.record-per-page")}</MenuItem>
                  )}
                </Select>
              </Box>

              <div className="pagination">
                <PageListNavigator/>

                <IconButton
                    className={"btn-pagination"}
                    onClick={this.prevPageData}
                    disabled={searchOption.current === 1}>
                  <ChevronLeftIcon/>
                </IconButton>

                <IconButton
                    className={"btn-pagination"}
                    onClick={this.nextPageData}
                    disabled={searchOption.current === searchOption.page}>
                  <ChevronRightIcon/>
                </IconButton>
              </div>
            </div>
          </div>

          <div className="body-wrapper">
            <ItemsList/>
          </div>
        </div>
      </Main>
    );
  }
}

export default withTranslation()(SearchResult);
