// @ts-nocheck
import { useHookstate } from "@hookstate/core";
import { Typography } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { errProps } from "../../../data-access/app.state";
import Loading from "../../../Pages/Loading";
import { getDetailDeliveryGuest } from "../../data-access/delivery/delivery.api";
import { deliveryDetailState } from "../../data-access/delivery/delivery.state";
import { notificationAlertState } from "../../data-access/state";
import { formatBytes } from "../../utils/format-file-size";
import DeliveryConfirmDialog from "../drive-dialogs/drive-delivery-confirm";
import DrivePasswordDialog from "../drive-dialogs/drive-password-dialog";
import styles from "./drive-layout.module.css";
export default function DriveGuestNav() {
  const { t } = useTranslation();
  const { token } = useParams();
  const openPassword = useHookstate(false);
  const delivery = useHookstate(deliveryDetailState);
  useEffect(() => {
    if (token) {
      getDetailDeliveryGuest({
        token,
        password: null,
      })
        .then((r) => {
          if (
            r?.data?.message_code &&
            r?.data?.message_code ===
              "shared-folders.delivery.guest.password-require"
          ) {
            openPassword.set(true);
          } else {
            if (!r?.data.ok) {
              notificationAlertState.set({
                show: true,
                type: "error",
                message: `${t(
                  "common:drive.message.error.cant_view",
                  "Cannot view this shared folder!"
                )} ${
                  r?.data?.message_code
                    ? t(`common:${r?.data?.message_code}`)
                    : ""
                }`,
              });
              errProps.set({
                msg: `${
                  r?.data?.message_code &&
                  (r?.data?.message_code ===
                    "shared-folders.delivery.guest.discontinue" ||
                    r?.data?.message_code ===
                      "shared-folders.delivery.guest.deleted")
                    ? t(`common:${r?.data?.message_code}`)
                    : "ファイルの配信期間が終了しました"
                }`,
                code: "",
                show: true,
              });
            } else {
              delivery.set(r.data.payload);
            }
          }
          // console.log(r);
        })
        .catch((e) => {
          notificationAlertState.set({
            show: true,
            type: "error",
            message: `${t(
              "common:drive.message.error.cant_view",
              "Cannot view this shared folder!"
            )} ${
              e?.response?.data?.message_code
                ? t(`common:${e?.response?.data?.message_code}`)
                : e?.response?.data?.message || e
            }`,
          });
          errProps.set({
            msg: `ファイルの配信期間が終了しました`,
            code: "",
            show: true,
          });
        });
    }
    return () => {
      delivery.set({
        drive_delivery_id: 0,
        sender_full_name: "",
        company_name: "",
        subject: "",
        content: "",
        type: 1,
        status: 1,
        download_count_setting: 0,
        downloaded_number: 0,
        start_date: "",
        end_date: "",
        total_folder_size: 0,
        total_file_size: 0,
        files: [],
        folders: [],
        users: [],
      });
    };
  }, [token]);
  return (
    <div className={styles["nav_wrap"]}>
      {delivery.drive_delivery_id.get() === 0 ? (
        <div
          style={{
            width: "100%",
            height: "calc(100vh - 160px)",
            backgroundColor: "white",
          }}
          className="main-body-loading"
        >
          {/* <Loading /> */}
        </div>
      ) : null}
      <DrivePasswordDialog
        onClose={() => {
          openPassword.set(false);
        }}
        open={openPassword.get()}
      />
      <div className={styles["infomation_area"]}>
        <Typography variant="body2" color={"gray"}>
          {t("common:drive.form.sender_name")}
          {/* 配信者 */}
        </Typography>
        <Typography>{delivery.sender_full_name.get()}</Typography>
      </div>

      <div className={styles["infomation_area"]}>
        <Typography variant="body2" color={"gray"}>
          {t("common:drive.form.company_name")}
          {/* 企業 */}
        </Typography>
        <Typography>{delivery.company_name.get()}</Typography>
      </div>

      <div className={styles["infomation_area"]}>
        <Typography variant="body2" color={"gray"}>
          {t("common:drive.form.name_folder")}
          {/* フォルダ名 */}
        </Typography>
        <Typography>{delivery.subject.get()}</Typography>
      </div>

      <div className={styles["infomation_area"]}>
        <Typography variant="body2" color={"gray"}>
          {t("common:drive.share_guest.download_deadline")}
          {/* ダウンロード期限 */}
        </Typography>
        <Typography>
          {(delivery?.end_date?.get() || "").substring(0, 10)}
        </Typography>
      </div>

      <div className={styles["infomation_area"]}>
        <Typography variant="body2" color={"gray"}>
          {t("common:drive.share_dialog.download_count_setting")}
          {/* ダウンロード回数設定 */}
        </Typography>
        <Typography>
          {delivery.downloaded_number.get()}/
          {delivery.download_count_setting.get()}
        </Typography>
      </div>

      <div className={styles["infomation_area"]}>
        <Typography variant="body2" color={"gray"}>
          {t("common:drive.table_header.size")}
          {/* サイズ */}
        </Typography>
        <Typography>
          {formatBytes(
            +delivery.total_file_size.get() + +delivery.total_folder_size.get()
          )}
        </Typography>
      </div>
    </div>
  );
}
