// @ts-nocheck
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faUser } from '@fortawesome/free-solid-svg-icons';
import { Close, Search } from "@mui/icons-material";
import { InputAdornment, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function DriveSearch({
  handleClearSearch,
  handleChangeSearch,
  searchValue,
  handleSearchKeyEnter,
  className,
  placeholder,
}) {
  // const searchInput = useHookstate("");
  const { t } = useTranslation("common");

  return (
    <div className={`search-round-container ${className}`}>
      <TextField
        placeholder={placeholder}
        className={"search-round"}
        variant="outlined"
        sx={{
          "& fieldset": {
            border: "none",
          },
        }}
        value={searchValue}
        onChange={handleChangeSearch}
        onKeyDown={handleSearchKeyEnter}
        style={{
          borderRadius: 100,
        }}
        InputProps={{
          style: {
            height: 34,
            fontSize: 14,
          },
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
          endAdornment: searchValue.length > 0 && (
            <InputAdornment
              className="header-search-close"
              position="end"
              onClick={handleClearSearch}
            >
              <Close />
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
}
