// import React from 'react';
import axios from 'axios';
import API from '../Config/API';
// import { Auth } from 'aws-amplify';

// const host_origin = "https://coni1zkirk.execute-api.ap-northeast-1.amazonaws.com/test";

const SystemService = class {

	static GetVersionSystem = async () => {
		const uri = API.url + "/system-versions/list.json"
		return await new Promise((resolve, reject) => {
			axios.get(uri, {
				params: {
					page_size: 1,
					page_number: 1
				}
			}).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static GetDictionary = async (lang = "ja") => {
		// console.log(user, pass);
		const uri = API.url + "/dictionary.json?lang=" + lang;

		return await new Promise((resolve, reject) => {
			axios.get(uri).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static GetLanguageResources = async (lang = "ja") => {
		let dic = await SystemService.GetDictionary(lang);
		return dic[lang];
	}

	static GetCurrenciesList = async () => {
		return await new Promise((resolve, reject) => {
			resolve([
				{ code: 'JPY', label: 'JPY' },
				{ code: 'USD', label: 'USD' },
				{ code: 'EUR', label: 'EUR' },
			]);
		});
	}

}

export default SystemService;