import { useHookstate } from "@hookstate/core";
import { systemMaintainData } from "../../data-access/app.state";
import styles from "./system-maintain.module.css";

export default function SystemMaintain() {
  const sysData = useHookstate(systemMaintainData);
  return (
    <div className={styles["site-wrapper"]}>
      <div className={styles["main"]}>
        <div className={styles["logo"]}>
          <img width="354.187" height="334" src="/icons/paperlogic.svg" alt="logo" />
        </div>
        <p className={"p1"}>Under Maintenance</p>
        <p className={styles["p2"]}>メンテナンス中</p>
        <div className={styles["maintenance_img"]}>
          <img
            width="354.187"
            height="334"
            src="/icons/system_maintain.svg"
            alt="img"
          />
        </div>
        <div className={styles["guidance"]}>
          <p className={styles["guidance_head"]}>定期メンテナンス</p>
          <dl className={styles["guidance_inner"]}>
            <dt>開始日時</dt>
            <dd>{sysData.get().start_time}</dd>
            <dt>終了日時</dt>
            <dd>{sysData.get().end_time}</dd>
          </dl>
        </div>
      </div>
    </div>
  );
}
