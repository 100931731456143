// @ts-nocheck
import { CircularProgress, Menu, MenuItem, Popper } from "@mui/material";
import Loading from "../../Pages/Loading";
import { usePopper } from "react-popper";
import { useHookstate } from "@hookstate/core";
import { createElement, useEffect, useRef } from "react";
import { noDataState } from "../../Pages/Components/VerificationOCR/OcrFields";
import { useTranslation } from "react-i18next";

/**
 * @param {{ anchorEl: any; open: any; handleClose: any; items: any; loading: any; menuContent:any; }} props
 */
export default function BaseSuggestionPopover(props) {
  const {
    anchorEl,
    open,
    handleClose,
    items,
    type,
    objMain,
    loading,
    menuContent,
    onClickCb,
    offsetTop,
    className,
    fileKey,
    offsetLeft
  } = props;
  const showNoData = useHookstate(noDataState);
  const { t } = useTranslation("common");
  const popperElement = useHookstate(null);
  const arrowElement = useHookstate(null);
  const popperRef = useRef();
  const arrowRef = useRef();
  const { styles, attributes } = usePopper(anchorEl, popperElement.get(), {
    placement: "right",
    strategy: "absolute",
     
    modifiers: [
      { name: 'preventOverflow', enabled: true },
      { name: "arrow", options: { element: arrowElement.get() } },
      {
        name: "offset",
        options: {
          offset: [offsetTop || 0, offsetLeft || 8],
        },
      },
    ],
  });

  useEffect(() => {
    if (popperRef.current) popperElement.set(popperRef.current);
  }, [popperRef]);

  useEffect(() => {
    if (arrowRef.current) arrowElement.set(arrowRef.current);
  }, [arrowRef]);
  // useEffect(() => {
  //   if (type) {
  //     setTimeout(() => {
  //       showNoData[type].set(true);
  //     }, 10000);
  //   }
  // }, [type]);
  if(showNoData[type] &&
    fileKey &&
    showNoData[type].get({ noproxy: true }) &&
    showNoData[type].get({ noproxy: true })[fileKey] === true && type !== 'invoice_number') return <></>
  if (!(menuContent && items?.length)) {
    return (
      <div
        className={`${className ? className : "popper_suggest"} ${
          !open ? "hide_popper" : ""
        }`}
        ref={popperRef}
        style={{...styles.popper, overflow: 'hidden'}}
        {...attributes.popper}
      >
        {showNoData[type] &&
        fileKey &&
        showNoData[type].get({ noproxy: true }) &&
        showNoData[type].get({ noproxy: true })[fileKey] === true ? (
          <> {t("common:general.no_data")}</>
        ) : (
          <CircularProgress />
        )}
        {/* NO DATA */}
        <div className="popper_arrow" ref={arrowRef} style={styles.arrow} />
      </div>
    );
  }
  /**
   * @param {import("react").MouseEvent<HTMLLIElement, MouseEvent>} e
   * @param {any} data
   */
  function onMenuClick(e, data) {
    handleClose(e, data);
  }

  function renderMenuItem() {
    return loading ? (
      <Loading />
    ) : (
      items.map((a) => (
        <MenuItem key={a.name} onClick={(e) => onMenuClick(e, a)}>
          {a.name}
        </MenuItem>
      ))
    );
  }
  // createElement
  const Comp = createElement(menuContent, {
    data: items[0],
    onClickCb,
    type,
    fileKey,
    objMain,
  });
  // console.log(Comp)
  return (
    <div
      className={`${className ? className : "popper_suggest"} popper_suggest_${type} ${
        !open ? "hide_popper" : ""
      }`}
      ref={popperRef}
      style={styles.popper}
      {...attributes.popper}
    >
      {menuContent && items?.length ? Comp : renderMenuItem()}
      <div className="popper_arrow" ref={arrowRef} style={styles.arrow} />
    </div>
  );
}
