// @ts-nocheck
import { useHookstate } from "@hookstate/core";
import { ArrowBack } from "@mui/icons-material";
import Save from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Button,
  Checkbox,
  Divider,
  FormControl,
  IconButton,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import BaseCard from "../../../Components/base-card/base-card";
import SwitchCustomize from "../../../Components/switch-customize/switch-customize";
import { GetUserInfoNew } from "../../../data-access/api";
import {
  notificationAlertState,
  userInfoState,
} from "../../../drive-feature/data-access/state";
import Service from "../../../Service";
import MaterialSymbolIcon from "../../Components/material-symbol/material-symbol";
import GroupMemberChip, { groupMembersState } from "./GroupMemberChip";
import RolesChip, { userRoles } from "./RolesChip";
import styles from "./user-detail.module.css";
import Loading from "../../Loading";

export default function UserDetailPages(props) {
  const { t } = useTranslation("common");
  // const { tenantId } = useParams();
  const methods = useForm({
    mode: 'all',
    defaultValues: {
      first_name: "",
      first_name_kana: "",
      family_name: "",
      family_name_kana: "",
      email: "",
      mobile_number: "",
      groups: [],
      status: true,
    },
  });
  const loadingBtn = useHookstate(false);
  const showAlert = useHookstate(notificationAlertState);
  const userInfo = useHookstate(userInfoState);
  const services = useHookstate([]);
  const listCollaborationTenant = useHookstate([]);
  const loading = useHookstate(false);
  // const location = useLocation();
  // const history = useHistory();
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    watch,
  } = methods;

  useEffect(() => {
    if (props.userDetail && props.showDetail) {
      loading.set(true);
      Service.getTenantServiceList(props.userDetail).then((response) => {
        services.set(response.tenantServiceList);
      });
      Service.GetListTenantCollaboration({ user_id: props.userDetail.user_id}).then((response) => {
        listCollaborationTenant.set(response.payload)
        loading.set(false);
        console.log(listCollaborationTenant);
      }).catch((e) => {
        loading.set(false);
      });
      reset({ ...props.userDetail });
    } else {
      userRoles.set({
          signer_role: 1,
          sender_role: 1,
          circle_role: 1,
          verification_role: 1,
          evaluation_role: 0,
          filebox_role: 1,
          administrator_role: 0,
          certificate_role: 0,
      })
      reset({
        first_name: "",
        first_name_kana: "",
        family_name: "",
        family_name_kana: "",
        email: "",
        mobile_number: "",
        groups: [],
        status: true,
      });
    }
  }, [props.showDetail, props.userDetail]);
  // console.log(props.userDetail);
  const submitHostUpdateForm = (data) => {
    /* Translate function */
    // let { t } = this.props;

    /* */
    // let { datalist, ModalBody, modalProps, ModalAction, UserInfo } = this.state;

    /* */
    // let form = document.querySelector("#settings-user-form");
    // let fields = form.elements;
    // let email = fields["email"]["value"];
    // console.log(fields);

    /* Preprae RequestBody */
    let body = {
      user_id: props?.userDetail?.user_id?.toString(),
      status: `${
        !!(data.status === true || data.status === 0 || data.status === 'checked')
          ? 0
          : data.status === 2
          ? 2
          : 1
      }`,
      organizations: groupMembersState
        .get({ stealth: true })
        .reduce((arr, a) => {
          if (a.isActive) {
            arr.push(a.user_id.toString());
          }
          return arr;
        }, []),
      signer_role:
        userInfo.get({ stealth: true }).partner_id !== "1"
          ? userRoles.signer_role.get().toString()
          : "0",
      sender_role:
        userInfo.get({ stealth: true }).partner_id !== "1"
          ? userRoles.sender_role.get().toString()
          : "0",
      circle_role:
        userInfo.get({ stealth: true }).partner_id !== "1"
          ? userRoles.circle_role.get().toString()
          : "0",
      verification_role: userRoles.verification_role.get().toString(),
      evaluation_role: userRoles.evaluation_role.get().toString(),
      filebox_role: userRoles.filebox_role.get().toString(),
      admin_role: userRoles.administrator_role.get().toString(),
      // "super_admin_role": "0",
      certificate_role: userRoles.certificate_role.get().toString(),
      family_name: data.family_name,
      first_name: data.first_name,
      family_name_kana: data.family_name_kana,
      first_name_kana: data.first_name_kana,
      mobile: data.mobile_number,
    };
    loadingBtn.set(true);
    /* */
    if (props.fromCreate) {
      body.email = data.email;
      Service.setUserHost(body)
        .then(async (response) => {
          loadingBtn.set(false);
          let { ModalBody, ModalAction, modalTitle } = props.main.state;
          modalTitle = t("common:settings.user.dialog.registration.header");
          ModalBody = () => {
            return (
              <div className="">
                <p>{t(`common:settings.user.dialog.registration.body`)}</p>
              </div>
            );
          };

          /* Clear DialogFooter */
          ModalAction = [];

          /* */
          props.main.setState({
            ModalBody,
            ModalAction,
            modalClose: true,
            modalTitle,
          });
          // console.log(props.activeTab, body.status)

          props.main.setState(
            {
              pagination: {
                page: 1,
                take: 100,
              },
              userDetail: null,
              showUserDetail: false,
              datalist: null,
            },
            () => props.main.fetchDataNew()
          );
          // console.log(newList, data.tenant_id);
        })
        .catch((error) => {
          loadingBtn.set(false);
          let { ModalBody, ModalAction, modalTitle } = props.main.state;
          modalTitle = t("common:settings.user.dialog.registration.header");
          let msgErr = error?.response?.data?.error;
          if(!msgErr) msgErr = error?.response?.data?.message;

          if (
            error?.code === "ECONNABORTED" ||
            (error?.response?.status === 504 &&
              error?.response?.data?.message === "Endpoint request timed out")
          ) {
            msgErr = t(
              "message.error.request.timed_out",
              `サーバーが混雑しております。\n しばらく待ってアクセスしてください。`
            );
            // return;
          }
          /* Update DialogBody */
          props.main.state.ModalBody = () => {
            return (
              <div className="">
                <p>
                  {t(
                    `common:${msgErr || "message.error.server_unknown_error"}`
                  )}
                </p>
              </div>
            );
          };

          /* Clear DialogFooter */
          props.main.state.ModalAction = [];

          /* */
          props.main.state.modalProps = {
            show: true,
            dialogClassName: "profile-dialog",
            centered: true,
          };
          /* */
          props.main.setState({
            modalClose: true,
            ModalBody: props.main.state.ModalBody,
            ModalAction: props.main.state.ModalAction,
            modalProps: props.main.state.modalProps,
            modalTitle,
          });
          loadingBtn.set(false);
        })
        .finally(() => loadingBtn.set(false));
    }
    if (!props.fromCreate)
      Service.updateUserHost(body)
        .then(async (response) => {
          loadingBtn.set(false);
          /* */
          let { ModalBody, ModalAction, modalTitle } = props.main.state;
          modalTitle = t("common:settings.user.dialog.update.header");
          ModalBody = () => {
            return (
              <div className="">
                <p>{t(`common:settings.user.dialog.update.body`)}</p>
              </div>
            );
          };

          /* Clear DialogFooter */
          ModalAction = [];

          props.main.state.modalProps = {
            show: true,
            dialogClassName: "profile-dialog",
            centered: true,
          };
          /* */
          // props.main.setState({});
          // console.log(newList, data.tenant_id);
          props.main.setState(
            {
              ModalBody,
              modalTitle,
              ModalAction,
              modalClose: true,
              pagination: {
                page: 1,
                take: 100,
              },
              userDetail: null,
              showUserDetail: false,
              datalist: null,
            },
            () => props.main.fetchDataNew()
          );
          // }).catch((error) => {
          // 	//console.log("CATCH", error);
          // });
          GetUserInfoNew();
          // await this.context.getUserInfo();
        })
        .catch((error) => {
          let msgErr = error?.response?.data?.error;
          if(!msgErr) msgErr = error?.response?.data?.message;
          let { ModalBody, ModalAction, modalTitle } = props.main.state;
          modalTitle = t("common:settings.user.dialog.update.header");
          if (
            error?.code === "ECONNABORTED" ||
            (error?.response?.status === 504 &&
              error?.response?.data?.message === "Endpoint request timed out")
          ) {
            msgErr = t(
              "message.error.request.timed_out",
              `サーバーが混雑しております。\n しばらく待ってアクセスしてください。`
            );
            // return;
          }
          /* Update DialogBody */
          props.main.state.ModalBody = () => {
            return (
              <div className="">
                <p>
                  {t(
                    `common:${msgErr || "message.error.server_unknown_error"}`
                  )}
                </p>
              </div>
            );
          };

          /* Clear DialogFooter */
          props.main.state.ModalAction = [];
          props.main.state.modalProps = {
            show: true,
            dialogClassName: "profile-dialog",
            centered: true,
          };
          /* */
          props.main.setState({
            modalClose: true,
            ModalBody: props.main.state.ModalBody,
            ModalAction: props.main.state.ModalAction,
            modalProps: props.main.state.modalProps,
            modalTitle,
          });
          loadingBtn.set(false);
        });

    /* */
    // this.updateState({ modalClose: false, ModalBody });
  };

  if (!props.showDetail)
    return (
      <>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={showAlert.show.get()}
          autoHideDuration={3500}
          onClose={handleClose}
        >
          <Alert severity={showAlert.type.get()}>
            {showAlert.message.get()}
          </Alert>
        </Snackbar>
      </>
    );

  function handleClose(event, reason) {
    if (reason === "clickaway") {
      return;
    }

    showAlert.show.set(false);
  }
  // console.log(location, history, match)
  // console.log('breadcrumbs', breadcrumbs)
  if(loading.get() == true){
    return <div
    style={{
      width: "100%",
      height: "calc(100vh - 160px)",
      backgroundColor: "white",
    }}
    className="main-body-loading"
  >
    <Loading />
  </div> ;
  }
  return (
    <div className="p-3">
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={showAlert.show.get()}
        autoHideDuration={3500}
        onClose={handleClose}
      >
        <Alert severity={showAlert.type.get()}>{showAlert.message.get()}</Alert>
      </Snackbar>

      <div className="go_back_wrapper">
        <Tooltip
          placement="top"
          disableInteractive
          title={t("common:user.detail.pages.go_back", "ユーザー")}
        >
          <IconButton
            onClick={(e) => {
              props.main.setState({
                userDetail: null,
                showUserDetail: false,
              });
            }}
          >
            <ArrowBack className="go_back_icon" />
          </IconButton>
        </Tooltip>

        <span>{t("common:user.detail.pages.go_back", "ユーザー")}</span>
      </div>
      {/* <Divider /> */}
      <BaseCard
        className={styles["base_tenant_card"]}
        content={
          <div className="base_content_grid">
            <div className={`${styles["button_action"]} button-right-shoulder`}>
              <LoadingButton
                loading={loadingBtn.get()}
                color="secondary"
                size="small"
                aria-label="SaveIcon"
                key="ec-save"
                startIcon={<Save />}
                variant="contained"
                onClick={handleSubmit(submitHostUpdateForm)}
              >
                {t("general.save")}
              </LoadingButton>
            </div>
            <div className="field_wrapper">
              <Typography
                color={"#062b60"}
                className={styles["form_text"]}
                variant="subtitle2"
                component="p"
              >
                {t("common:settings.guest.enable_disable")}
              </Typography>
              <div className="col-span-4">
                <FormControl fullWidth>
                  <Controller
                    name={"status"}
                    control={control}
                    // rules={rules}
                    render={({ field: { onChange, value }, formState }) => (
                      <SwitchCustomize
                        setVal={setValue}
                        tooltip={
                          value === 0 || value === true
                            ? t("common:settings.user.active")
                            : props.userDetail?.status === 2
                            ? t("common:settings.user.pending")
                            : t("common:settings.user.disabled")
                        }
                        name={"status"}
                        isWaiting={props.userDetail?.status === 2}
                        // disabled={props.userDetail?.status === 2}
                        active={value === 0 || value === true}
                        onChange={onChange}
                      />
                    )}
                  />
                </FormControl>
              </div>
            </div>
            <div className="field_wrapper">
              <Typography
                color={"#062b60"}
                className={styles["form_text"]}
                variant="subtitle2"
                component="p"
              >
                {/* 姓名 */}
                {t("common:settings.user.first_last_name", "姓名")}
              </Typography>
              <div className="flex items-center col-span-2">
                <Typography
                  color={"#062b60"}
                  variant="subtitle2"
                  className="pr-4 w-14 text-end"
                >
                  {/* 姓 */}
                  {t("common:settings.user.family_name")}
                </Typography>
                <FormControl fullWidth>
                  <Controller
                    name={"family_name"}
                    control={control}
                    // rules={rules}
                    rules={{
                      required: t("common:settings.validate.user.family_name"),
                    }}
                    render={({ field: { onChange, value }, formState }) => {
                      // console.log(formState);
                      return (
                        <TextField
                          className={
                            "bg-white required-form" +
                            ` ${
                              !formState?.errors["family_name"] && value
                                ? "valid-form"
                                : ""
                            } ${
                              formState.errors["family_name"]
                                ? "errors-form"
                                : ""
                            }`
                          }
                          onChange={onChange}
                          size="small"
                          value={value}
                          error={formState.errors["family_name"]}
                          helperText={formState.errors["family_name"]?.message}
                          InputLabelProps={{ style: { fontSize: 14 } }}
                          // value={textInputValue}
                          // placeholder={textInputPlaceHolder}
                          // label={textFieldLabel || 'Filled'}
                          variant="outlined"
                        />
                      );
                    }}
                  />
                </FormControl>
              </div>
              <div className="flex items-center col-span-2">
                <Typography
                  color={"#062b60"}
                  variant="subtitle2"
                  className="pr-4 w-14 text-end"
                >
                  {/* 名 */}
                  {t("common:settings.user.first_name")}
                </Typography>
                <FormControl fullWidth>
                  <Controller
                    name={"first_name"}
                    control={control}
                    // rules={rules}
                    rules={{
                      required: t("common:settings.validate.user.first_name"),
                    }}
                    render={({ field: { onChange, value }, formState }) => (
                      <TextField
                        className={
                          "bg-white required-form" +
                          ` ${
                            !formState?.errors["first_name"] && value
                              ? "valid-form"
                              : ""
                          } ${
                            formState.errors["first_name"] ? "errors-form" : ""
                          }`
                        }
                        error={formState.errors["first_name"]}
                        helperText={formState.errors["first_name"]?.message}
                        onChange={onChange}
                        size="small"
                        value={value}
                        InputLabelProps={{ style: { fontSize: 14 } }}
                        // value={textInputValue}
                        // placeholder={textInputPlaceHolder}
                        // label={textFieldLabel || 'Filled'}
                        variant="outlined"
                      />
                    )}
                  />
                </FormControl>
              </div>
            </div>
            <div className="field_wrapper">
              <Typography
                color={"#062b60"}
                className={styles["form_text"]}
                variant="subtitle2"
                component="p"
              >
                {/* カナ */}
                {t("common:settings.user.family_last_kana", "カナ")}
              </Typography>
              <div className="flex items-center col-span-2">
                <Typography
                  color={"#062b60"}
                  variant="subtitle2"
                  className="pr-4 w-14 text-end"
                >
                  {t("common:settings.user.family_name_kana")}
                </Typography>
                <FormControl fullWidth>
                  <Controller
                    name={"family_name_kana"}
                    control={control}
                    // rules={rules}
                    render={({ field: { onChange, value }, formState }) => (
                      <TextField
                        className="bg-white"
                        onChange={onChange}
                        size="small"
                        value={value}
                        InputLabelProps={{ style: { fontSize: 14 } }}
                        // value={textInputValue}
                        // placeholder={textInputPlaceHolder}
                        // label={textFieldLabel || 'Filled'}
                        variant="outlined"
                      />
                    )}
                  />
                </FormControl>
              </div>
              <div className="flex items-center col-span-2">
                <Typography
                  color={"#062b60"}
                  variant="subtitle2"
                  className="pr-4 w-14 text-end"
                >
                  {/* メイ */}
                  {t("common:settings.user.first_name_kana")}
                </Typography>
                <FormControl fullWidth>
                  <Controller
                    name={"first_name_kana"}
                    control={control}
                    // rules={rules}
                    render={({ field: { onChange, value }, formState }) => (
                      <TextField
                        className="bg-white"
                        onChange={onChange}
                        size="small"
                        value={value}
                        InputLabelProps={{ style: { fontSize: 14 } }}
                        // value={textInputValue}
                        // placeholder={textInputPlaceHolder}
                        // label={textFieldLabel || 'Filled'}
                        variant="outlined"
                      />
                    )}
                  />
                </FormControl>
              </div>
            </div>
            <div className="field_wrapper">
              <Typography
                color={"#062b60"}
                className={styles["form_text"]}
                variant="subtitle2"
                component="p"
              >
                {t("common:settings.user.email")}
                {/* メールアドレス */}
              </Typography>
              <div className="col-span-6 col-span-6-sm">
                <FormControl fullWidth>
                  <Controller
                    name={"email"}
                    control={control}
                    rules={{
                      required: t("common:settings.validate.user.email"),
                      pattern: {
                        value: /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/g,
                        message: t(
                          "common:settings.validate.user.email_pattern"
                        ),
                      },
                    }}
                    render={({ field: { onChange, value }, formState }) => (
                      <TextField
                        onChange={onChange}
                        size="small"
                        className={
                          "bg-white required-form mypage-email-input" +
                          ` ${
                            !formState?.errors["email"] && value
                              ? "valid-form"
                              : ""
                          } ${formState.errors["email"] ? "errors-form" : ""}`
                        }
                        error={formState.errors["email"]}
                        helperText={formState.errors["email"]?.message}
                        disabled={!!props.userDetail}
                        value={value}
                        InputLabelProps={{ style: { fontSize: 14 } }}
                        // value={textInputValue}
                        // placeholder={textInputPlaceHolder}
                        // label={textFieldLabel || 'Filled'}
                        variant="outlined"
                      />
                    )}
                  />
                </FormControl>
              </div>
            </div>
            <div className="field_wrapper">
              <Typography
                color={"#062b60"}
                className={styles["form_text"]}
                variant="subtitle2"
                component="p"
              >
                {/* 携帯電話番号 */}
                {t("common:settings.user.mobile")}
              </Typography>
              <div className="col-span-3">
                <FormControl fullWidth>
                  <Controller
                    name={"mobile_number"}
                    control={control}
                    // rules={rules}
                    render={({ field: { onChange, value }, formState }) => (
                      <TextField
                        className="bg-white"
                        onChange={onChange}
                        size="small"
                        inputProps={{ maxLength: 15 }}
                        value={value}
                        InputLabelProps={{ style: { fontSize: 14 } }}
                        // value={textInputValue}
                        // placeholder={textInputPlaceHolder}
                        // label={textFieldLabel || 'Filled'}
                        variant="outlined"
                      />
                    )}
                  />
                </FormControl>
              </div>
            </div>
            <div className="field_wrapper">
              <Typography
                color={"#062b60"}
                className={styles["form_text"] + " h-full align-top"}
                variant="subtitle2"
                component="p"
              >
                {t("common:settings.user.authority")}
              </Typography>
              <div className="col-span-4 col-span-6-sm">
                <RolesChip />
              </div>
            </div>
            <Divider />
            <div className="field_wrapper">
              <Typography
                color={"#062b60"}
                className={styles["form_text"] + " h-full align-top"}
                variant="subtitle2"
                component="p"
              >
                {t("common:settings.user.groups")}
              </Typography>
              <div className="col-span-4">
                <GroupMemberChip />
              </div>
            </div>
            {/* <Divider /> */}
          </div>
        }
      />
      { listCollaborationTenant.get().length ? 
        <BaseCard
          className={styles["base_tenant_card"]}
          content={
          <div className="base_content_grid">
            <h5>{t("common:user.detail.list-tenant-collab")}</h5>
            {listCollaborationTenant.get().map((value) => <p style={{marginBottom: 0}}>{value.name}</p>)}
          </div>}
        />
        : <></>}

      {!props.fromCreate ? (
        <BaseCard
          className={styles["base_tenant_card"]}
          content={
            <div className="base_content_grid">
              {props?.userDetail?.status === 0 &&
              props?.userDetail?.has_login === 1 ? (
                <div className="field_wrapper">
                  <Typography
                    color={"#062b60"}
                    className={styles["form_text"] + " h-full align-top"}
                    variant="subtitle2"
                    component="p"
                  >
                    {t("common:settings.user.reset-password")}
                  </Typography>
                  <div className="col-span-4">
                    <Button
                      variant="outlined"
                      onClick={() => {
                        props.main.sumitHostResetPassword(props.userDetail);
                      }}
                    >
                      <span>
                        {t("common:settings.user.execute", "実行する")}
                      </span>
                    </Button>
                  </div>
                </div>
              ) : (
                <div className="field_wrapper">
                  <Typography
                    color={"#062b60"}
                    className={styles["form_text"] + " h-full align-top"}
                    variant="subtitle2"
                    component="p"
                  >
                    {t("common:settings.user.resend_mail")}
                  </Typography>
                  <div className="col-span-4">
                    <Button
                      variant="outlined"
                      onClick={() => {
                        props.main.sumitHostResendMail(props.userDetail);
                      }}
                    >
                      <span>
                        {t("common:settings.user.execute", "実行する")}
                      </span>
                    </Button>
                  </div>
                </div>
              )}

              {userInfo.get().administrator_role == "1" ||
              userInfo.get().super_administrator_role == "1" ? (
                <div className="field_wrapper">
                  <Typography
                    color={"#062b60"}
                    className={styles["form_text"] + " h-full align-top"}
                    variant="subtitle2"
                    component="p"
                  >
                    {t(
                      "common:settings.user.reset-mfa",
                      "二要素認証を無効化する"
                    )}
                  </Typography>
                  <div className="col-span-4">
                    <Button
                      variant="outlined"
                      onClick={() => {
                        props.main.submitResetMfa(props.userDetail);
                      }}
                    >
                      <span>
                        {t("common:settings.user.execute", "実行する")}
                      </span>
                    </Button>
                  </div>
                </div>
              ) : null}
            </div>
          }
        />
      ) : null}

      {/* <BaseCard
        className={styles["base_tenant_card"]}
        content={<UserDetailEmailSettings />}
      /> */}
    </div>
  );
}

export function emailSettingsMapping(email_category, email_control) {
  console.log(email_category, email_control);
  const emailSettingParsed = email_category.reduce((array, item) => {
    const targetLevel = email_control[1] || {}; // use for company level only
    const targetCate = targetLevel[item.id];
    if (targetCate) {
      array.push({
        allow: targetCate.allow,
        label: item.label,
        name: item.name,
        id: item.id,
      });
    } else {
      array.push({ ...item, allow: null });
    }
    return array;
    // console.log('ok', targetLevel, targetCate, v, targetCate?.allow);
  }, []);
  return emailSettingParsed;
}
export function UserDetailEmailSettings() {
  const userInfo = useHookstate(userInfoState);
  const user = userInfo.get({ noproxy: true });
  // const emailStr = JSON.parse(JSON.stringify(userInfo.get()?.email_control));
  const str = user?.email_control;
  const emailSettingParsed = emailSettingsMapping(
    user.email_category,
    str || ""
  );
  const emailSettings = emailSettingParsed;
  console.log(emailSettings);
  return (
    <div className="cert_container grid gap-1 p-8 relative">
      <Typography color={"#062b60"} fontWeight={"bold"}>
        配信メール希望
      </Typography>
      {emailSettings.map((a, i) => (
        <div key={i}>
          <div className="field_wrapper flex items-center grid-template-columns-3">
            <Typography
              color={"#062b60"}
              className={styles["form_text"] + " col-span-5"}
              variant="subtitle2"
              component="p"
            >
              {a.label}
            </Typography>
            <div>
              <FormControl>
                <Checkbox
                  color="secondary"
                  onChange={() => {
                    emailSettings[i].merge({ allow: !a.allow });
                  }}
                  size="small"
                  checked={a.allow}
                />
              </FormControl>
            </div>
          </div>
          <Divider />
        </div>
      ))}
    </div>
  );
}
